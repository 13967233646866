import {
  all,
  put,
  delay,
  takeLatest,
  takeEvery,
  select,
  race,
  take,
} from 'redux-saga/effects'
import {addSnackbarMessage, increaseOfflineCounter, setOfflineRobots, setSiteId} from './actions'
import {AnyAction} from 'redux'
import {getKeyForRobot, getResponseBody, getSelectedSiteAndRobotId, httpGet, isRobotOnline} from 'utils'
import {Files, Storage} from 'platform'
import * as constants from './constants'
import {sagas as bugReport} from './bugReport'
import {setPermissions, setSelectedRobot} from './actions'
import {Robot, Store} from '../types'
import i18next from '../locale/i18n'
import {SITE_ID_LOCAL_STORAGE_KEY} from './constants'

let siteIdSetForOnlineRobot = false
let isRobotSet = false

function* checkOfflineRobots() {
  while (true) {
    yield race({
      timeout: delay(1000),
      robotUpdated: take(constants.ROBOT_UPDATED),
    })
    const {robots, offlineRobots} = (yield select((state: Store) => ({
      robots: state.app.robots,
      offlineRobots: state.app.offlineRobots,
    }))) as {robots: Robot[], offlineRobots: string[]}

    const nextOfflineCandidates = robots.filter(robot => !isRobotOnline(robot)).map(getKeyForRobot)
    let hasChanges = offlineRobots.length !== nextOfflineCandidates.length
    if (!hasChanges) {
      // same length? let's check if content changed
      hasChanges = nextOfflineCandidates.some(robotKey => !offlineRobots.includes(robotKey))
    }

    if (hasChanges) {
      yield put(setOfflineRobots(nextOfflineCandidates))
    }

    if (nextOfflineCandidates.length > 0) {
      yield put(increaseOfflineCounter())
    }
  }
}

function* fetchPermissions() {
  yield takeLatest<AnyAction>(constants.FETCH_PERMISSIONS, function* (action) {
    let {siteId} = yield getSelectedSiteAndRobotId()
    while (!siteId) {
      yield delay(300)
      siteId = (yield getSelectedSiteAndRobotId()).siteId
    }
    const response: Response = yield httpGet(`${siteId}/user/permissions`)
    const {status} = response
    if (status < 300) {
      const permissions = yield getResponseBody(response)
      yield put(setPermissions(permissions))
    }
  })
}

function* storeSiteIdInLocalStorage() {
  yield takeEvery<AnyAction>(constants.SET_SITE_ID, function* (action) {
    const {siteId} = action
    yield Storage.setItem(SITE_ID_LOCAL_STORAGE_KEY, siteId)
    try {
      if (i18next.language) {
        yield i18next.reloadResources()
      }
    } catch (error) {
      console.error(error)
    }
  })
}

function* initializeSiteId() {
  yield takeLatest<AnyAction>(constants.INITIALIZE_SITE_ID, function* () {
    const siteId = yield Storage.getItem(SITE_ID_LOCAL_STORAGE_KEY)
    yield put(setSiteId(siteId))
  })
}

function* checkIfSiteIdShouldBeUpdated() {
  yield takeEvery<AnyAction>(constants.ROBOT_UPDATED, function* (action) {
    if (siteIdSetForOnlineRobot) {
      return
    }

    const {robot} = action
    if (isRobotOnline(robot)) {
      yield put(setSiteId(robot.siteId))
      siteIdSetForOnlineRobot = true
      yield put(setSelectedRobot(robot))
    }
  })
}

function* checkIfSelectedRobotShouldBeSet() {
  yield takeEvery<AnyAction>(constants.ROBOT_UPDATED, function* (action) {
    if (isRobotSet) {
      return
    }

    isRobotSet = true
    const selectedRobot = yield select((state: Store) => state.app.selectedRobot)
    if (selectedRobot) {
      return
    }

    const {robot} = action
    yield put(setSelectedRobot(robot))
    yield put(setSiteId(robot.siteId))
  })
}

function* setSelectedRobotSaga() {
  yield takeEvery<AnyAction>(constants.SET_SELECTED_ROBOT_ID, function* (action) {
    const {robotId} = action
    const robot = yield select((state: Store) => state.app.robots.find(robot => robot.id === robotId))
    if (robot) {
      yield put(setSelectedRobot(robot))
    }
  })
}

function* onRobotsFetchSuccess() {
  yield takeEvery<AnyAction>(constants.FETCH_ROBOTS_SUCCESS, function* (action) {
    const urlParams = new URLSearchParams(window.location.search)
    const paramsSiteId = urlParams.get('site')
    const paramsRobotId = urlParams.get('robot')
    const robots = action.robots || []
    const getRobotByParams = () => (paramsSiteId && paramsRobotId) ?
      robots.find((robot: Robot) => robot.siteId === paramsSiteId && robot.id === paramsRobotId) :
      null
    const getDefaultRobot = () => robots
      .sort((robot1: Robot, robot2: Robot) => robot1.name.localeCompare(robot2.name))
      .find(isRobotOnline)
    const robotFromParams = getRobotByParams()
    const selectedRobot = robotFromParams || getDefaultRobot() || null
    yield put(setSelectedRobot(selectedRobot))
    yield put(setSiteId(selectedRobot?.siteId || null))
    if (selectedRobot) {
      siteIdSetForOnlineRobot = true
    }

    if (robotFromParams) {
      isRobotSet = true
    }
  })
}

function* downloadFile() {
  yield takeEvery<AnyAction>(constants.DOWNLOAD_FILE, function* (action) {
    const {url, fileName, errorMessage, onSuccess, onError} = action
    try {
      const response = yield httpGet(url)
      if (response.status >= 400) {
        if (onError) {
          onError()
        }
        throw new Error(`status: ${response.status}`)
      }

      yield Files.downloadFileFromResponse(response, fileName)
      if (onSuccess) {
        onSuccess()
      }
    } catch (e) {
      if (onError) {
        onError()
      }
      yield put(addSnackbarMessage(errorMessage || 'Common error while downloading file'))
    }
  })
}

function* appSagas() {
  yield all([
    initializeSiteId(),
    checkIfSelectedRobotShouldBeSet(),
    checkIfSiteIdShouldBeUpdated(),
    checkOfflineRobots(),
    downloadFile(),
    fetchPermissions(),
    onRobotsFetchSuccess(),
    setSelectedRobotSaga(),
    storeSiteIdInLocalStorage(),
    bugReport(),
  ])
}

export default appSagas
