import React from 'react'
import {connect} from 'react-redux'
import {RouteChildrenProps, withRouter} from 'react-router'
import {compose} from 'redux'
import {Store} from 'types'
import {selectors} from 'shared'
import {cancelLimitFinding} from 'limitFindingWizard/actions'
import {changePatrolState} from '../actions'
import {getStateMachine, isPatrolOngoing} from '../selectors'
import PausePatrolAction from './PausePatrolAction'
import {PausePatrolDispatchActions} from './types'
import {getPauseAction} from './selectors'
import {idleStates, movementStateGroups} from '../types'

const mapStateToProps = (state: Store) => {
  const robotId = state.app.selectedRobot?.id
  const stateGroup = getStateMachine(state)?.stateGroup
  return {
    isPaused: (
      stateGroup !== undefined &&
      !idleStates.includes(stateGroup) &&
      !movementStateGroups.includes(stateGroup)
    ),
    robotId,
    isPatrolIdle: !isPatrolOngoing(state),
    robot: state.app.selectedRobot,
    robotState: state.app.selectedRobot?.healthStatus.robotState,
    isRunningLimitFinding: state.limitFindingWizard.isRunningLimitFinding,
    isConnected: state.app.isConnectedToServer && selectors.isCurrentRobotOnline(state),
    isActionInProgress: robotId !== undefined && state.nsp.robotsWithPendingChanges.includes(robotId),
  }
}

const mergeProps = (
  {robot, ...stateProps}: ReturnType<typeof mapStateToProps>,
  dispatchProps: PausePatrolDispatchActions,
  ownProps: RouteChildrenProps
) => ({
  ...stateProps,
  action: getPauseAction({...stateProps, robot}, dispatchProps, ownProps),
})

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      changePatrolState,
      cancelLimitFinding,
    },
    mergeProps
  )
)(PausePatrolAction) as React.FC
