/* eslint-disable @typescript-eslint/camelcase */
import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SimpleSwitch,
  TimePicker,
  Loader,
} from 'components'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from '../types'
import {areObjectsTheSame, httpGet, httpPost} from '../utils'
import {AudioSettings} from './types'
import {blueButton, white} from '../colors'
import {addSnackbarMessage} from '../app'
import {useNormalTranslation} from 'utils'
import {selectors} from 'shared'

const Content = styled.div`
  margin: 16px 0;
`

const TimeWrapperLabel = styled.div`
  font-weight: bolder;
  margin: 32px 0 8px 0;
  text-align: center;
`

const TimePickerLabel = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
`

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  > div {
    flex: 1;
    &:first-child {
      margin-right: 12px;
    }
    &:last-child {
      margin-left: 12px;
    }
  }
`

const FormItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 24px 0;
  
  > :first-child {
    width: 180px;
    margin-right: 16px;
    font-weight: bolder;
    flex-shrink: 0;
    text-align: right;
  }
`

const StyledInput = styled(Input)`
  max-width: 75px;
`

interface Props {
  isModalOpen: boolean
  closeModal: () => void
}

const AudioSettingsModal = ({
  isModalOpen,
  closeModal,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [isFetchingData, setFetchingData] = useState(false)
  const [audioSettings, setAudioSettings] = useState<AudioSettings | undefined>()
  const [changedAudioSettings, setChangedAudioSettings] = useState<AudioSettings | undefined>()
  const [regularVolume, setRegularVolume] = useState('')
  const [quietVolume, setQuietVolume] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const siteId = useSelector<Store, string | null>(state => state.app.siteId)
  const dispatch = useDispatch()

  useEffect(() => {
    if (siteId || (isModalOpen && !audioSettings && !isFetchingData)) {
      setFetchingData(true)
      const abortController = new AbortController()

      const fetchData = async () => {
        try {
          const response = await httpGet(`${siteId}/site/audio-settings`, false, abortController)
          if (response.status < 300) {
            const data = await response.json()
            setAudioSettings(data)
            setChangedAudioSettings(data)
            if (data) {
              setRegularVolume(`${Math.round(data.regular_volume * 100)}`)
              setQuietVolume(`${Math.round(data.quiet_volume * 100)}`)
            }
          }
        } catch (e) {
          console.error(e)
        }
        setFetchingData(false)
      }

      fetchData()

      return () => {
        abortController.abort()
      }
    }
  }, [siteId, isModalOpen])

  const onClose = useCallback(() => {
    setChangedAudioSettings(audioSettings)
    closeModal()
  }, [closeModal, audioSettings])

  const save = useCallback(async () => {
    if (!changedAudioSettings || areObjectsTheSame(changedAudioSettings, audioSettings)) {
      onClose()
    } else {
      setIsSaving(true)
      const response = await httpPost(`${siteId}/site/audio-settings`, changedAudioSettings)
      setIsSaving(false)
      if (response.status < 300) {
        onClose()
        dispatch(addSnackbarMessage('Dashboard audio settings update success'))
      } else {
        dispatch(addSnackbarMessage('Dashboard audio settings update error'))
      }
    }
  }, [siteId, dispatch, audioSettings, changedAudioSettings, onClose])


  const handleRegularVolumeChange = (field: string, valueSetter: (val: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    // keep only digits
    let inputValue = event.target.value.replace(/\D+/g, '')
    let numericValue = parseInt(inputValue || '0') / 100
    if (numericValue < 0) {
      numericValue = 0
      inputValue = ''
    } else if (numericValue > 1) {
      numericValue = 1
      inputValue = '100'
    }
    valueSetter(inputValue)
    setChangedAudioSettings(data => data ? ({
      ...data,
      [field]: numericValue,
    }) : data)
  }

  const changeQuietModeEnabled = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setChangedAudioSettings(data => data ? ({
      ...data,
      quiet_mode_enabled: checked,
    }) : data)
  }
  const changeFromDate = (date: Date) => {
    setChangedAudioSettings(data => data ? ({
      ...data,
      quiet_time_range: {
        ...data.quiet_time_range,
        from_hour: date.getUTCHours(),
        from_minute: date.getUTCMinutes(),
      },
    }) : data)
  }
  const changeToDate = (date: Date) => {
    setChangedAudioSettings(data => data ? ({
      ...data,
      quiet_time_range: {
        ...data.quiet_time_range,
        to_hour: date.getUTCHours(),
        to_minute: date.getUTCMinutes(),
      },
    }) : data)
  }

  const fromDate = new Date()
  if (changedAudioSettings) {
    fromDate.setUTCHours(changedAudioSettings.quiet_time_range.from_hour, changedAudioSettings.quiet_time_range.from_minute)
  }
  const toDate = new Date()
  if (changedAudioSettings) {
    toDate.setUTCHours(changedAudioSettings.quiet_time_range.to_hour, changedAudioSettings.quiet_time_range.to_minute)
  }

  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <DialogTitle onClose={onClose}>
          {t('Dashboard audio settings title')}
        </DialogTitle>
        <Content>
          {changedAudioSettings ? (
            <div>
              <FormItem>
                <div>
                  {t('Dashboard audio settings regular volume')}
                </div>
                <StyledInput
                  value={regularVolume}
                  onChange={handleRegularVolumeChange('regular_volume', setRegularVolume)}
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  type="number"
                />
              </FormItem>
              <FormItem>
                <div>
                  {t('Dashboard audio settings quiet mode enabled')}
                </div>
                <SimpleSwitch
                  checked={changedAudioSettings.quiet_mode_enabled}
                  onChange={changeQuietModeEnabled}
                  color="primary"
                />
              </FormItem>
              <FormItem>
                <div>
                  {t('Dashboard audio settings quiet volume')}
                </div>
                <StyledInput
                  value={quietVolume}
                  onChange={handleRegularVolumeChange('quiet_volume', setQuietVolume)}
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  type="number"
                  disabled={!changedAudioSettings.quiet_mode_enabled}
                />
              </FormItem>
              <TimeWrapperLabel>
                {t('Dashboard audio settings quiet time range')}
              </TimeWrapperLabel>
              <TimeWrapper>
                <div>
                  <TimePickerLabel>{t('Dashboard audio settings quiet time range from')}</TimePickerLabel>
                  <TimePicker
                    date={fromDate}
                    onDateChange={changeFromDate}
                    disabled={!changedAudioSettings.quiet_mode_enabled}
                  />
                </div>
                <div>
                  <TimePickerLabel>{t('Dashboard audio settings quiet time range to')}</TimePickerLabel>
                  <TimePicker
                    date={toDate}
                    onDateChange={changeToDate}
                    disabled={!changedAudioSettings.quiet_mode_enabled}
                  />
                </div>
              </TimeWrapper>
            </div>
          ) : <Loader centered />}
        </Content>
        <DialogActions $noHorizontalPadding>
          <Button onClick={closeModal}>
            {t('Common button cancel')}
          </Button>
          {changedAudioSettings && !isFetchingData && (
            <Button
              type="submit"
              $backgroundColor={blueButton}
              $textColor={white}
              onClick={save}
              disabled={isSaving}
            >
              {t('Common button save')}
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default AudioSettingsModal
