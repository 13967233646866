import {ThunkDispatch} from 'redux-thunk'
import {HOOK_LABELS, registerHook, types} from 'framework'
import {reducerRegistry, sagaMiddleware} from 'store'
import {isPathnameMatchingRoute} from 'utils'
import getNavigationModule from 'modules/navigation'
import {NAV_HOOK_LABELS, ROUTES as NAV_ROUTES} from 'modules/navigation/constants'
import {
  ExtendEventTitleHookContext,
  GetOperationFinishedHookContext,
  ExtendReportDetailsTabsParams,
  GetPatrolImageHookContext,
  HistoryListTabsParams,
  idleStates,
  SkippedEventImageHookContext,
} from 'modules/navigation/types'
import {getLocationTranslation} from 'modules/navigation/utils'
import {isUvAction} from 'modules/navigation/report/shared/utils'
import AbstractModule from 'modules/AbstractModule'
import {adjustTranslationKeys} from './translations'
import reducer from './reducer'
import sagas from './sagas'
import Routes from './Routes'
import {SanitizationLevel} from './types'
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined'
import {ReportDetailsObjectsTab, SanitizeOperationListContainer} from './report'
import {moduleName, ROUTES} from './constants'

const defaultHandlerLabel = 'uv'

const deepPaths = [ROUTES.POINT_REPORT, NAV_ROUTES.SETTINGS]

// noinspection JSMethodCanBeStatic
class UvModule extends AbstractModule {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  installModule(dispatch: ThunkDispatch<any, any, any>) {
    getNavigationModule().run(dispatch)
    reducerRegistry.register('uv', reducer)
    sagaMiddleware.run(sagas)
    registerHook(HOOK_LABELS.ROUTING.REGISTER_DEEP_PATHS, defaultHandlerLabel, this.registerDeepPathsCallback.bind(this))
    registerHook(HOOK_LABELS.TRANSLATIONS.GENERIC_KEY_ADJUSTMENTS, defaultHandlerLabel, adjustTranslationKeys)
    registerHook(NAV_HOOK_LABELS.ROUTING.REGISTER_ROUTES, defaultHandlerLabel, this.registerRouteCallback.bind(this))
    registerHook(NAV_HOOK_LABELS.EVENTS.EXTEND_TITLE_OPTIONS, defaultHandlerLabel, this.extendEventTitleCallback.bind(this))
    registerHook(NAV_HOOK_LABELS.PATROL.FINISHED_IMAGE, defaultHandlerLabel, this.getOperationFinishedImage.bind(this))
    registerHook(NAV_HOOK_LABELS.EVENTS.EXTEND_REPORT_SUMMARY_TEXT_OPTIONS, defaultHandlerLabel,
      this.extendEventSummaryTextOptionsCallback.bind(this))
    registerHook(NAV_HOOK_LABELS.REPORT.EXTEND_DETAILS_TABS, defaultHandlerLabel, this.extendReportTabs.bind(this))
    registerHook(NAV_HOOK_LABELS.REPORT.GET_HISTORY_TABS, defaultHandlerLabel, this.extendHistoryTabs.bind(this), 50)
    registerHook(NAV_HOOK_LABELS.EVENTS.GET_SKIPPED_EVENT_IMAGE, defaultHandlerLabel, this.getSkippedEventUrl.bind(this))
    registerHook(HOOK_LABELS.ROUTING.SELECTED_NAV_ITEM, defaultHandlerLabel, this.setSelectedNavItemCallback.bind(this))
    registerHook(NAV_HOOK_LABELS.PATROL.IMAGE, defaultHandlerLabel, this.getOperationImage.bind(this))
  }

  private getOperationImage(context: GetPatrolImageHookContext) {
    const module = context.robot?.modules?.current
    if (module === moduleName && idleStates.includes(context.stateGroup)) {
      return {image: '/sanitization/uv_idle.png'}
    }
  }

  private getOperationFinishedImage(context: GetOperationFinishedHookContext) {
    if (this.shouldExecuteBasedOnModule([context.currentModule])) {
      return {image: '/sanitization/operation_finished.png'}
    }
  }

  private registerDeepPathsCallback() {
    return {
      uv: deepPaths,
    }
  }

  private setSelectedNavItemCallback(context: types.GetSelectedMenuItemHookContext) {
    if (isPathnameMatchingRoute(context.pathname, ROUTES.POINT_REPORT)) {
      context.selectedValue = 'history'
    }
  }

  private getSkippedEventUrl(context: SkippedEventImageHookContext) {
    if (this.shouldExecuteBasedOnModule(context.modules)) {
      context.url = '/sanitization/skipped.svg'
    }
  }

  private registerRouteCallback(context: types.RegisterRouteParams) {
    if (context.baseRoute !== NAV_ROUTES.INDEX) {
      return undefined
    }

    return ({
      uv: Routes,
    })
  }

  private extendEventTitleCallback(context: ExtendEventTitleHookContext) {
    const {
      diagnosticEvent,
      locations,
      options,
      t,
    } = context

    let sanitizationLevel: SanitizationLevel | undefined = undefined
    let location: string | string[] | undefined = undefined

    if (isUvAction(diagnosticEvent)) {
      sanitizationLevel = diagnosticEvent?.patrolPointConfig?.uv_target_fluence || SanitizationLevel.LOW
      location = diagnosticEvent?.patrolPointConfig?.patrolPoint.uid
    }

    if (sanitizationLevel) {
      options.level = t(`uv_SanitizingObjects general ${sanitizationLevel} uvc`)
      options.object = getLocationTranslation(locations, location) ||
        (Array.isArray(location) ? location.join(', ') : location) ||
        ''
    }
  }

  private extendEventSummaryTextOptionsCallback(context: ExtendEventTitleHookContext) {
    const {
      diagnosticEvent,
      options,
      t,
    } = context

    if (isUvAction(diagnosticEvent)) {
      const level = diagnosticEvent?.patrolPointConfig?.uv_target_fluence || SanitizationLevel.LOW
      // @ts-ignore
      options.level = t(`uv_SanitizingObjects general ${level} uvc`)
    }
  }

  private extendReportTabs(context: ExtendReportDetailsTabsParams) {
    if (this.shouldExecuteBasedOnModule(context.modules)) {
      context.tabs.push({
        titleKey: 'uv_patrol report tabs objects',
        iconComponent: CategoryOutlinedIcon,
        component: ReportDetailsObjectsTab,
      })
    }
  }

  /*
   * Needs to be implemented by child modules (like UV)
   */
  private extendHistoryTabs(context: HistoryListTabsParams) {
    if (context.configModules.includes(moduleName)) {
      context.tabs.push({
        titleKey: `use-case ${moduleName}`,
        component: SanitizeOperationListContainer,
      })
    }
  }
}

let mod: UvModule | undefined
const getModule = () => {
  if (!mod) {
    mod = new UvModule(moduleName)
  }

  return mod
}

export default getModule
