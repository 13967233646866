import React from 'react'
import styled from 'styled-components'
import BatteryLevelContainer from './BatteryLevelContainer'
import ConnectionIndicatorContainer from './ConnectionIndicatorContainer'
// import ProtectiveStopContainer from './ProtectiveStopContainer'

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const Icons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -5px;

    > * {
        margin-left: 8px;

        &:first-child {
            margin-left: 0;
        }
    }
`

const StatusBar = () => {
  return (
    <Wrapper>
      <Icons>
        <BatteryLevelContainer />
        <ConnectionIndicatorContainer />
      </Icons>
      {/* <ProtectiveStopContainer />*/}
    </Wrapper>
  )
}

export default StatusBar
