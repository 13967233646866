import React from 'react'
import styled from 'styled-components'
import {i18n} from 'locale'
import {getSeverity, GoToElementIcon, Severity} from 'components'
import {
  Locations,
  PatrolDetails,
  DiagnosticEvent,
  PatrolPointAction,
  DiagnosticReason,
  PatrolStartFinishReasons,
} from '../../navigation/types'
import {cautionBorder, errorBorder, grayText, lightestGray, lightGray, success, white} from '../../../colors'
import {getEventsForLocation, groupRooms} from '../../navigation/report/details/utils'
import {hasFaceId} from '../../navigation/utils'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const LocationName = styled.div`
  font-weight: 500;
  padding: 18px 0;
`

const Empty = styled.div`
  font-weight: 500;
  padding: 18px 0;
  text-align: center;
`

const RoomRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid ${lightGray};
  justify-content: space-between;
  ${(props: {onClick?: Function}) => props.onClick ? `
    cursor: pointer;
    &:hover {
      background-color: ${lightestGray};
    }
  ` : ''}
`

const RoomName = styled.div`
  width: 140px;
  min-height: 50px;
  align-self: stretch;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 6px;
  border: 2px solid black;
  margin-bottom: -1px;
  position: relative;
  font-weight: 500;
  color: black;
  
  ${(props: {enabled: boolean, severity?: Severity}) => {
    if (props.severity === 'normal') {
      return `background-color: ${success};`
    }

    if (props.severity === 'warning') {
      return `background-color: ${cautionBorder};`
    }

    if (props.severity === 'error') {
      return `background-color: ${errorBorder}; color: ${white};`
    }

    if (!props.enabled) {
      return 'background-color: #D9D6D6;'
    }

    return ''
  }}
  
  &:after {
    content: ' ';
    position: absolute;
    background-color: ${white};
    height: 50%;
    width: 2px;
    top: 25%;
    right: -2px;
  }
`

const RoomEvent = styled.div`
  ${(props: {notChecked: boolean}) => props.notChecked ? `color: ${grayText};` : ''}
  padding: 4px 16px;
  flex: 1;
`

interface Props {
  details: PatrolDetails
  locations: Locations
  setSelectedEvent: (eventId: string, events?: DiagnosticEvent[]) => void
}

const ReportDetailsRooms = ({
  details,
  locations,
  setSelectedEvent,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const {
    events,
    finishedAt,
    finishReason,
    roomSettings,
  } = details
  if (!roomSettings) {
    return (
      <Empty>
        {t('Nav_ReportRoomView content empty list from robot')}
      </Empty>
    )
  }

  const isFinished = Boolean(finishedAt)
  const {rooms} = roomSettings
  const groupedRooms = groupRooms(locations, rooms)
  const eventsByRoom = {} as {[roomId: string]: DiagnosticEvent[]}
  const allEvents = [] as DiagnosticEvent[]
  groupedRooms.forEach(group => group.rooms.forEach(room => {
    const eventsForRoom = getEventsForLocation(room.id, events, [PatrolPointAction.OPEN_PEEK_CLOSE, PatrolPointAction.NAVIGATE_TO])
    allEvents.push(...eventsForRoom)
    eventsByRoom[room.id] = eventsForRoom
  }))

  return (
    <>
      {groupedRooms.map(groupedRoom => (
        <div
          key={groupedRoom.location}
        >
          <LocationName>{groupedRoom.location}</LocationName>
          <div>
            {groupedRoom.rooms.map(room => {
              const roomEvents = eventsByRoom[room.id]
              let mainEvent = roomEvents.find(event => getSeverity(event.reason) === 'error')
              if (!mainEvent) {
                mainEvent = roomEvents.find(event => getSeverity(event.reason) === 'warning' &&
                  event.reason !== DiagnosticReason.POINT_SKIPPED)
              }
              if (!mainEvent) {
                mainEvent = roomEvents[0]
              }

              const extraEventReason = (
                finishReason === PatrolStartFinishReasons.SKIPPED_DUE_TO_ONGOING_PATROL &&
                rooms.some(patrolRoom => patrolRoom.roomId === room.id && patrolRoom.enabled)
              ) ? DiagnosticReason.POINT_SKIPPED : undefined

              const mainEventReason = mainEvent?.reason || extraEventReason
              const locationSeverity = getSeverity(mainEventReason)

              const onClick = mainEvent && mainEventReason !== DiagnosticReason.POINT_SKIPPED ?
                () => setSelectedEvent((mainEvent as DiagnosticEvent).notificationUid, allEvents) :
                undefined

              const disabled = rooms.find(({roomId}) => roomId === room.id)?.enabled === false || (isFinished && !mainEvent)
              let translationKey = mainEventReason ?
                `Nav_ReportRoomView content ${mainEventReason}` :
                (disabled ? 'Nav_ReportRoomView content not selected' : 'Nav_ReportRoomView content not checked yet')
              if (mainEventReason && hasFaceId(mainEvent?.faceId) && i18n.exists(`Nav_ReportRoomView content ${mainEventReason} face_id`)) {
                translationKey = `Nav_ReportRoomView content ${mainEventReason} face_id`
              }

              const translation = translationKey && i18n.exists(translationKey) ?
                t(translationKey, {name: mainEvent?.faceId}) :
                ''

              const notChecked = !mainEvent && !disabled

              return (
                <RoomRow
                  key={room.id}
                  data-testid={`event-${mainEventReason}`}
                  onClick={onClick}
                >
                  <RoomName
                    enabled={!disabled}
                    severity={locationSeverity}
                  >
                    {room.translation || room.id}
                  </RoomName>
                  <RoomEvent notChecked={notChecked}>
                    {translation}
                  </RoomEvent>
                  {onClick && <GoToElementIcon />}
                </RoomRow>
              )
            })}
          </div>
        </div>
      ))}
    </>
  )
}

export default ReportDetailsRooms
