export enum STEPS {
  STEP_1 = '1',
  STEP_2 = '2',
  STEP_3 = '3',
  CHECK_JOINTS = 'joints',
  FINAL_STEP = 'final',
  DONE = 'done',
}

export const ORDERED_STEPS = [
  STEPS.STEP_1,
  STEPS.STEP_2,
  STEPS.STEP_3,
  STEPS.CHECK_JOINTS,
  STEPS.FINAL_STEP,
  STEPS.DONE,
]

const ACTION_PREFIX = 'limitFindingWizard/'

export const NEXT_STEP = `${ACTION_PREFIX}NEXT_STEP`
export const PREVIOUS_STEP = `${ACTION_PREFIX}PREVIOUS_STEP`

export const CLEAR = `${ACTION_PREFIX}CLEAR`

export const OPEN_MODAL_FOR_STEP = `${ACTION_PREFIX}OPEN_MODAL_FOR_STEP`
export const CLOSE_MODAL_FOR_STEP = `${ACTION_PREFIX}CLOSE_MODAL_FOR_STEP`

export const CANCEL_LIMIT_FINDING = `${ACTION_PREFIX}CANCEL_LIMIT_FINDING`

export const RUN_LIMIT_FINDING = `${ACTION_PREFIX}RUN_LIMIT_FINDING`
export const RUN_LIMIT_FINDING_SUCCESS = `${RUN_LIMIT_FINDING}_SUCCESS`
export const RUN_LIMIT_FINDING_ERROR = `${RUN_LIMIT_FINDING}_ERROR`
