import {FC} from 'react'
import {connect} from 'react-redux'
import {Store} from 'types'
import ScheduleEditPage from './ScheduleEditPage'
import {setEditedStep, setEditedItem} from './actions'
import {getCurrentSchedule, getConfigurations} from '../selectors'
import {canEdit} from './selectors'

interface OwnProps {
  match?: {
    params?: {
      scheduleId?: string
    }
  }
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => {
  const currentSchedule = getCurrentSchedule(state)
  const configurations = getConfigurations(state)
  return {
    disabled: !canEdit(state),
    step: state.nsp.schedule.editStep,
    editedScheduleItem: state.nsp.schedule.editedItem,
    configurations,
    scheduleItem: currentSchedule?.scheduleItems.find(item => item.id === ownProps.match?.params?.scheduleId),
  }
}

export default connect(mapStateToProps, {
  setStep: setEditedStep,
  setEditedItem,
})(ScheduleEditPage) as FC<OwnProps>
