import React from 'react'
import {EngineeringTableWrapper, FeatureToggle, LimitFindingDetailedErrorsTableRows} from 'components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import {PERMISSIONS} from '../../constants'

const LimitFindingDetailedErrors = () => (
  <FeatureToggle permissionName={PERMISSIONS.DEBUG_CONTENT}>
    <EngineeringTableWrapper>
      <Table size="small">
        <TableBody>
          <LimitFindingDetailedErrorsTableRows />
        </TableBody>
      </Table>
    </EngineeringTableWrapper>
  </FeatureToggle>
)

export default LimitFindingDetailedErrors
