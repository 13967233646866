import {connect} from 'react-redux'
import {Store} from 'types'
import {setOngoingPatrolEventsType} from './actions'
import {showOngoingPatrolEvents} from './selectors'
import OngoingPatrolEventsToggle from './OngoingPatrolEventsToggle'

const mapStateToProps = (state: Store) => ({
  shouldShow: showOngoingPatrolEvents(state),
  type: state.nsp.patrolEvents.ongoingPatrolEventsType,
})

export default connect(mapStateToProps, {setType: setOngoingPatrolEventsType})(OngoingPatrolEventsToggle)
