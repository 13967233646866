import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {withStyles} from '@material-ui/core/styles'
import MaterialSlider, {Mark} from '@material-ui/core/Slider'
import {primary, lightText, darkText} from '../colors'

const Wrapper = styled.div`
  width: 100%;
`

const ActiveMark = styled.span`
  color: ${({isActive}: {isActive: boolean}) => isActive ? darkText : 'inherit'};
`

const StyledSlider = withStyles({
  root: {
    'color': '#E7E7E7',
    'height': 6,
    '&.Mui-disabled': {
      'opacity': 0.35,
      '& > .MuiSlider-track': {
        color: lightText,
      },
    },
  },
  thumb: {
    'height': 18,
    'width': 18,
    'backgroundColor': primary,
    'border': `2px solid ${primary}`,
    'marginTop': -6,
    'marginLeft': -9,
    '&.Mui-disabled': {
      'height': 18,
      'width': 18,
      'marginTop': -6,
      'marginLeft': -9,
      'backgroundColor': lightText,
      'borderColor': lightText,
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    color: primary,
    height: 6,
    borderRadius: 3,
  },
  rail: {
    height: 6,
    borderRadius: 3,
    opacity: 1,
  },
  markActive: {
    display: 'none',
  },
  markLabel: {
    'color': lightText,
    'fontWeight': 500,
  },
})(MaterialSlider)

interface Props {
  defaultValue?: number
  disabled?: boolean
  onChange?: (value: number) => void
  marks?: boolean | Mark[]
  step?: number | null
  value?: number
}

const Slider = ({
  defaultValue,
  disabled,
  onChange,
  marks,
  step,
  value,
}: Props) => {
  const [internalValue, setInternalValue] = useState(defaultValue)
  const onValueChanged = useCallback((event: React.ChangeEvent<{}>, value: number | number[]) => {
    if (typeof value === 'number') {
      if (onChange) {
        onChange(value)
      } else {
        setInternalValue(value)
      }
    }
  }, [onChange, setInternalValue])

  const sliderValue = value !== undefined ? value : internalValue

  return (
    <Wrapper>
      <StyledSlider
        onChange={onValueChanged}
        defaultValue={defaultValue}
        disabled={disabled}
        marks={(
          Array.isArray(marks) ?
            marks.map(mark => ({
              value: mark.value,
              label: typeof mark.label === 'string' ? (
                <ActiveMark isActive={mark.value === sliderValue}>
                  {mark.label}
                </ActiveMark>
              ) : mark.label,
            })) :
            marks
        )}
        step={step}
        valueLabelDisplay="off"
        max={2}
        value={sliderValue}
      />
    </Wrapper>
  )
}

export default Slider
