import styled from 'styled-components'
import MaterialCard from '@material-ui/core/Card'

export const LoaderWrapper = styled.div`
    display: flex;
    margin: 16px;
    justify-content: center;
    align-items: center;
`

export const ActionsWrapper = styled.div`
    margin: 0;
`

export const NoActions = styled.div`
    margin-top: 16px;
    font-size: 1rem;
`

export const Offline = styled.div`
    display: flex;
    align-items: center;
`

export const OfflineMessage = styled.div`
    margin-left: 16px;
    font-size: 1rem;
`

export const Card = styled(MaterialCard)`
  margin-top: 16px;
  
  &:first-child {
    margin-top: 0;
  }
`
