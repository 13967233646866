import {types} from 'framework'
import {moduleName} from './constants'

export const adjustTranslationKeys: types.GenericKeyAdjustmentsCallback = (
  context
) => {
  const uvDefaultKey = `uv_${context.defaultKey}`
  if (context.currentModule === moduleName && context.i18n && context.i18n.exists(uvDefaultKey)) {
    context.key = uvDefaultKey
  }
}
