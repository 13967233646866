import {buildAction} from 'utils'
import {FETCH_SITE_SETTINGS} from '../../../constants'
import * as constants from './constants'

export const fetchSiteSettings = buildAction(FETCH_SITE_SETTINGS)
export const fetchLocations = buildAction(constants.FETCH_LOCATIONS)
export const fetchLocationsFinished = buildAction(constants.FETCH_LOCATIONS_FINISHED, 'locations')
export const fetchLocationsConfigurationSuccess = buildAction(constants.FETCH_LOCATIONS_CONFIG_SUCCESS, 'configuration')

export const startLocationEdit = buildAction(constants.LOCATION_EDIT_START, 'locationId')
export const locationEditEnd = buildAction(constants.LOCATION_EDIT_END, 'locationId', 'translation', 'language')
export const confirmLocationEdit = buildAction(constants.LOCATION_EDIT_CONFIRM, 'locationId', 'translation')

export const toggleGetStatisticsModal = buildAction(constants.TOGGLE_GET_STATISTICS)
