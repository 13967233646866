import React, {useEffect} from 'react'
import {useModuleAwareTranslation} from 'utils'
import {List, Loader} from 'components'
import {Settings} from '../types'
import {ListEmptyText, PageMainContent} from '../NSPCommonStyled'
import SettingsNameEditContainer from './SettingsNameEditContainer'
import SettingsHomeEditContainer from './SettingsHomeEditContainer'
import SettingsListItemContainer from './SettingsListItemContainer'
import {SettingsListItemConfig} from './types'

export interface Props {
  canSendRobotCommand: boolean
  isLoading: boolean
  settings?: Settings
  settingsConfig: SettingsListItemConfig[]
  robotId?: string
  getItems: () => void
  settingsItemToggle: (roomIds: string[], isOn: boolean) => void
}

const SettingsList = ({
  canSendRobotCommand,
  isLoading,
  robotId,
  getItems,
  settings,
  settingsConfig,
  settingsItemToggle,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  useEffect(() => {
    getItems()
  }, [robotId])

  if (isLoading || !robotId) {
    return <Loader centered withTopMargin />
  }

  if (!settings || !settings.rooms || settings.rooms.length === 0) {
    return <ListEmptyText>{t('Nav_CommonSetting content Settings List Empty')}</ListEmptyText>
  }

  const renderSetting = (setting: SettingsListItemConfig) => (
    <React.Fragment key={setting.id}>
      <SettingsListItemContainer
        canSendRobotCommand={canSendRobotCommand}
        config={setting}
        settingsItemToggle={settingsItemToggle}
        isParent={setting.children.length > 0}
      />
      {setting.children.length > 0 && (
        <List isNested>
          {setting.children.map(renderSetting)}
        </List>
      )}
    </React.Fragment>
  )

  return (
    <PageMainContent>
      <SettingsNameEditContainer isFullWidth />
      <SettingsHomeEditContainer isFullWidth />
      <List>
        {settingsConfig.map(renderSetting)}
      </List>
    </PageMainContent>
  )
}

export default SettingsList
