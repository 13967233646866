import React from 'react'
import {defaultMemoize} from 'reselect'
import moment from 'moment'
import {selectors as authSelectors} from 'auth'
import {shouldUse12HoursFormat} from 'utils'
import {PickerItem, SinglePicker, MultiPicker} from './picker'
import {Value} from './picker/types'
import {useSelector} from 'react-redux'
import {Store} from '../types'

interface Props {
  className?: string
  date?: Date
  disabled?: boolean
  use12Hours?: boolean
  onDateChange: (date: Date) => void
}

const ONE_DAY = 24 * 60 * 60 * 1000

const getLocale = defaultMemoize((language: string) => {
  const date = new Date()
  date.setHours(1)
  let formattedDate = moment(date).format('H A')
  const am = formattedDate.split(' ')[1]
  date.setHours(20)
  formattedDate = moment(date).format('H A')
  const pm = formattedDate.split(' ')[1]
  return {
    am,
    pm,
  }
})

const getNewDate = (date: Date | undefined, newElementValue: number | string, elementIndex: number, use12Hours: boolean) => {
  const newDate = date ? new Date(date.getTime()) : new Date()
  switch (elementIndex) {
    case 0:
      if (use12Hours) {
        const currentHour = newDate.getHours()
        let hour = parseInt(String(newElementValue), 10)
        if (currentHour >= 12) {
          hour += 12
        }
        if (hour >= 24) {
          hour = 0
        }
        newDate.setHours(hour)
      } else {
        newDate.setHours(parseInt(String(newElementValue), 10))
      }
      break
    case 1:
      newDate.setMinutes(parseInt(String(newElementValue), 10))
      break
    case 2:
      if (newElementValue === 'am') {
        newDate.setTime(newDate.getTime() - ONE_DAY / 2)
      } else {
        newDate.setTime(newDate.getTime() + ONE_DAY / 2)
      }
      break
    default:
      break
  }

  return newDate
}

type ColumnItem = {value: Value, label: string}

const prepareColumns = (use12Hours: boolean, locale: ReturnType<typeof getLocale>) => {
  const columns: Array<{key: string, items: ColumnItem[]}> = []
  const hourItems: ColumnItem[] = []
  if (use12Hours) {
    for (let i = 0; i < 12; i++) {
      hourItems.push({
        value: i,
        label: i === 0 ? '12' : String(i),
      })
    }
  } else {
    for (let i = 0; i < 24; i++) {
      hourItems.push({value: i, label: String(i)})
    }
  }

  columns.push({key: 'hours', items: hourItems})

  const minuteItems = []
  for (let i = 0; i < 60; i++) {
    minuteItems.push({value: i, label: String(i)})
  }
  columns.push({key: 'minutes', items: minuteItems})

  if (use12Hours) {
    columns.push({
      key: 'ampm',
      items: [
        {value: 'am', label: locale.am},
        {value: 'pm', label: locale.pm},
      ],
    })
  }

  return columns
}

const getAmPm = (date: Date) => date.getHours() >= 12 ? 'pm' : 'am'

const prepareValue = (date: Date, use12Hours: boolean): (string | number)[] => {
  let hour = date.getHours()
  const minute = date.getMinutes()
  if (use12Hours) {
    hour = hour >= 12 ? hour - 12 : hour
  }

  const value: (string | number)[] = [hour, minute]
  if (use12Hours) {
    value.push(getAmPm(date))
  }
  return value
}

const TimePicker: React.FC<Props> = ({
  className,
  date,
  disabled,
  onDateChange,
  use12Hours: force12Hours,
}: Props) => {
  const userId = useSelector<Store, string | undefined>(state => authSelectors.getUser(state)?.userId)
  const dateToUse = date || new Date()
  const momentLocale = moment.locale()
  const locale = getLocale(momentLocale)
  const use12Hours = typeof force12Hours !== 'undefined' ? force12Hours : shouldUse12HoursFormat(momentLocale, userId)

  const onValueChange = (index: number, value: string | number) => {
    const newValue = getNewDate(date, value, index, use12Hours)
    onDateChange(newValue)
  }

  const columns = prepareColumns(use12Hours, locale)
  const value = prepareValue(dateToUse, use12Hours)

  return (
    <MultiPicker
      className={`time-picker ${className || ''}`}
      onValueChange={onValueChange}
      value={value}
    >
      {columns.map(column => (
        <SinglePicker
          key={column.key}
          disabled={disabled}
          loop={column.key !== 'ampm'}
        >
          {column.items.map(item => (
            <PickerItem key={item.value} value={item.value}>
              {item.label}
            </PickerItem>
          ))}
        </SinglePicker>
      ))}
    </MultiPicker>
  )
}

export default TimePicker
