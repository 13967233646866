import {connect} from 'react-redux'
import {Store} from 'types'
import {getPatrolMonitor, getStateMachine} from '../selectors'
import PatrolCounters from './PatrolCounters'

const mapStateToProps = (state: Store) => ({
  stateGroup: getStateMachine(state)?.stateGroup,
  checkedPoints: getPatrolMonitor(state)?.checkedPoints || 0,
  skippedPoints: getPatrolMonitor(state)?.skippedPoints || 0,
  totalPoints: getPatrolMonitor(state)?.totalPoints || 0,
})

export default connect(mapStateToProps)(PatrolCounters)
