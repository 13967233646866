import {connect} from 'react-redux'
import {Store} from 'types'
import ReportList from './ReportList'
import {setHistoryTabIndex} from '../actions'
import {getConfigModules} from './selectors'
import {getConfigurations} from '../../selectors'

const mapStateToProps = (state: Store) => {
  return {
    hasConfigs: getConfigurations(state).length > 0,
    configModules: getConfigModules(state),
    tabIndex: state.nsp.report.historyTabIndex,
  }
}

export default connect(mapStateToProps, {setHistoryTabIndex})(ReportList)
