import moment from 'moment'
import {DiagnosticEvent, DiagnosticReason, Locations} from '../types'
import {getFromLocation} from '../utils'

type GroupedEvent = {
  event: DiagnosticEvent
  location: string[]
}

export const groupSkippedEvents = (events: DiagnosticEvent[], locations: Locations): GroupedEvent[] => {
  const result: GroupedEvent[] = []
  for (const event of events) {
    const location = getFromLocation(event)
    const eventLocation = location ? [location] : []
    const eventWithSameDateAndParent = result.find(ev => ev.event.reason === event.reason &&
      ev.event.skip_reason === event.skip_reason &&
      event.reason === DiagnosticReason.POINT_SKIPPED &&
      locations[getFromLocation(ev.event) || '']?.parentId === locations[location || '']?.parentId &&
      moment(event.date).isSame(moment(ev.event.date), 'minute')
    )

    const groupedEvent: GroupedEvent = eventWithSameDateAndParent ? eventWithSameDateAndParent : {
      event,
      location: [],
    }
    groupedEvent.location.push(...eventLocation)
    if (!eventWithSameDateAndParent) {
      result.push(groupedEvent)
    }
  }

  return result
}
