import {connect} from 'react-redux'
import {Store} from 'types'
import {ConnectionIndicator} from 'components'
import {selectors} from 'shared'

const mapStateToProps = (state: Store) => {
  const {isConnectedToServer} = state.app
  return {
    isConnected: isConnectedToServer && selectors.isCurrentRobotOnline(state),
  }
}

export default connect(mapStateToProps)(ConnectionIndicator)
