import React, {useCallback, useContext, useEffect, useState} from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import moment from 'moment'
import {EmptyList, List, Loader} from 'components'
import {
  DiagnosticEvent,
  Locations,
  PatrolStartFinishReasons,
  SimplePatrol,
} from 'modules/navigation/types'
import {ReportListRow} from 'modules/navigation/report/shared'
import {ROUTES} from 'modules/navigation/constants'
import ReportEventSummary from 'modules/navigation/report/shared/ReportEventSummaryContainer'
import {getFromLocation} from 'modules/navigation/utils'
import {MultipleEventsModal} from 'modules/navigation/patrolEvents'
import {RobotModulesContext} from 'app/types'
import {moduleName} from '../constants'
import {getDateAndTimeFormatForMoment} from '../../../utils'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  clearPatrolList: () => void
  fetchPatrolList: (page: number, module: string) => void
  hasMoreReports: boolean
  reports: SimplePatrol[]
  robotId?: string
  userId?: string
  showOngoingReportLink: boolean
  locations: Locations
}

const HistoryListContainer = ({
  clearPatrolList,
  fetchPatrolList,
  hasMoreReports,
  reports,
  robotId,
  showOngoingReportLink,
  userId,
  locations,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const {currentModule} = useContext(RobotModulesContext)
  const [selectedEvent, setSelectedEvent] = useState<DiagnosticEvent[] | undefined>()
  const setCurrentEvent = useCallback((eventId: string) => {
    const event = reports.find(report => report?.standaloneEvent?.notificationUid === eventId)?.standaloneEvent
    setSelectedEvent(event ? [event] : undefined)
  }, [reports])

  useEffect(() => {
    return () => {
      clearPatrolList()
    }
  }, [clearPatrolList])

  const getPatrolText = (patrol: SimplePatrol) => {
    const dateFormat = getDateAndTimeFormatForMoment(userId)
    const finishedDate = moment(patrol.finishedAt).format(dateFormat)
    if (patrol.finishReason === PatrolStartFinishReasons.SKIPPED_DUE_TO_ONGOING_PATROL) {
      return `${finishedDate}\n${t('Nav_History content patrol history list item skipped')}`
    }

    return finishedDate
  }
  const showOngoingOperationRowItem = showOngoingReportLink && currentModule === moduleName

  const fetchList = useCallback((page: number) => {
    fetchPatrolList(page, moduleName)
  }, [fetchPatrolList])

  return (
    <>
      {robotId && !hasMoreReports && reports.length === 0 && (
        <EmptyList>
          {t('Nav_History content patrol history empty')}
        </EmptyList>
      )}
      {robotId && (
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchList}
          hasMore={hasMoreReports}
          loader={<Loader key={0} centered />}
        >
          <List>
            {showOngoingOperationRowItem && (
              <ReportListRow
                link={ROUTES.INDEX}
                text={t('Nav_History content Ongoing patrol')}
              />
            )}
            {reports.map(patrol => patrol.standaloneEvent ? (
              <ReportEventSummary
                key={patrol.standaloneEvent.notificationUid}
                event={patrol.standaloneEvent}
                eventLocation={getFromLocation(patrol.standaloneEvent)}
                locations={locations}
                setSelectedEvent={setCurrentEvent}
              />
            ) : (
              <ReportListRow
                key={patrol.id}
                level={patrol.status}
                link={`/patrol/report/${patrol.id}`}
                text={getPatrolText(patrol)}
              />
            ))}
          </List>
        </InfiniteScroll>
      )}
      {selectedEvent?.length && (
        <MultipleEventsModal
          close={() => setSelectedEvent(undefined)}
          events={selectedEvent}
          locations={locations}
          selectedEventId={selectedEvent[0].notificationUid}
          robotId={robotId as string}
          userId={userId}
        />
      )}
    </>
  )
}

export default HistoryListContainer
