import moment from 'moment'
import {getPath, isPatrolConfigRunning, processForBestLanguage} from 'utils'
import {selectors} from 'shared'
import {
  DiagnosticEvent,
  Locations,
  PatrolPointAction,
} from './types'
import {getLanguageCodes, i18n} from 'locale'
import {RobotState, Store} from 'types'
import {isPatrolOngoing} from './selectors'
import {scenarioToModule} from '../utils'
import {ROUTES} from './constants'

export const hasFaceId = (faceId?: string) => Boolean(faceId) && faceId !== 'unknown' && faceId !== 'pending'

export const getLocationTranslation = (locations?: Locations, locationIdElement?: string | string[]): string => {
  const locationId = Array.isArray(locationIdElement) ? locationIdElement[0] : locationIdElement
  if (!locations || !locationId || !locations[locationId]) {
    return ''
  }

  const translations = locations[locationId]
  const languageCodes = getLanguageCodes(i18n.language)
  return (processForBestLanguage(languageCodes, language => translations[language], '') || '') as string
}

export const getLocationParts = (
  locations: Locations,
  locationIdElement?: string | string[],
  currentParts?: string[]
): string[] => {
  const locationId = Array.isArray(locationIdElement) ? locationIdElement[0] : locationIdElement
  if (!locationId || !locations[locationId]) {
    return currentParts || []
  }

  const {parentId, ...translations} = locations[locationId]
  const languageCodes = getLanguageCodes(i18n.language)
  const translation = processForBestLanguage(languageCodes, language => translations[language], '')
  return getLocationParts(
    locations,
    parentId,
        [translation, ...(currentParts || [])].filter(Boolean) as string[]
  )
}

export const getLocationPath = (
  locations: Locations,
  locationIdElement?: string | string[],
  currentParts?: string[]
): string[] => {
  const locationId = Array.isArray(locationIdElement) ? locationIdElement[0] : locationIdElement
  if (!locationId || !locations[locationId]) {
    return currentParts || []
  }

  const {parentId} = locations[locationId]
  return getLocationPath(
    locations,
    parentId,
    [locationId, ...(currentParts || [])].filter(Boolean)
  )
}

export const getEventPhotoUrl = (siteId: string, robotId: string, eventId: string) =>
  getPath(`v1/nsp/robot/${siteId}/${robotId}/patrol/event/${eventId}/photo`)

export const getPdfWithReportUrl = (siteId: string, robotId: string, patrolId: string) => {
  const lang = getLanguageCodes(i18n.language)[0]
  return `v1/nsp/robot/${siteId}/${robotId}/patrol/pdf/${patrolId}?language=${lang}&utcOffset=${moment().utcOffset()}`
}

export const settingsMapStateToProps = (state: Store) => {
  const isInitializing = state.app.selectedRobot?.healthStatus.robotState === RobotState.INITIALIZING
  const isInCriticalState = state.app.selectedRobot?.healthStatus.robotState === RobotState.CRITICAL_ERROR
  const isPatrolConfigUp = isPatrolConfigRunning(state.app.selectedRobot)
  return {
    robotLoaded: state.app.selectedRobot !== null,
    canSendRobotCommand: (
      state.app.isConnectedToServer &&
      selectors.isCurrentRobotOnline(state) &&
      !isInitializing &&
      !isInCriticalState &&
      isPatrolConfigUp
    ),
    patrolling: isPatrolOngoing(state),
    isInitializing,
    isInCriticalState,
    isPatrolConfigUp,
  }
}

export const isIdleAtHomeFromRawState = (state?: string) => state?.toLowerCase().includes('_idle_') &&
  !state?.toLowerCase().includes('_spot_')

export const isGoingHomeFromRawState = (state?: string) => state?.toLowerCase().includes('_going_home_')

export const isAfterStopped = (state?: string) => state?.toLowerCase().endsWith('_unscheduled')

export const getFromLocation = (event: DiagnosticEvent) =>
  event.patrolPointConfig?.patrolPoint?.uid || event.fromLocation

export const getToLocation = (event: DiagnosticEvent) =>
  event.patrolPointConfig?.patrolPoint?.uid || event.toLocation

export const getSettingsRouteForScenario = (scenario?: string) => {
  const module = scenarioToModule(scenario)
  if (module === 'uv' || module === 'wauv') {
    return ROUTES.OBJECT_SETTINGS
  }

  return ROUTES.SETTINGS
}

export function filterOutSettingsForClients<T extends {action?: PatrolPointAction}>(
  settings: T[],
  module?: string,
  canEditNavigateToActions?: boolean
): T[] {
  const isUvOrWauv = module === 'uv' || module === 'wauv'
  if (canEditNavigateToActions || !isUvOrWauv) {
    return settings
  }

  return settings.filter(item => item.action !== PatrolPointAction.NAVIGATE_TO)
}
