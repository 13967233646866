import {connect} from 'react-redux'
import {Store} from 'types'
import {getEvent} from '../selectors'
import PatrolFinished from './PatrolFinished'
import {getCurrentModules} from 'app/selectors'

const mapStateToProps = (state: Store) => ({
  currentModule: getCurrentModules(state)[0] || '',
  hasCurrentEvent: getEvent(state) !== undefined,
  finishedPatrolId: state.nsp.finishedPatrolId,
})

export default connect(mapStateToProps)(PatrolFinished)
