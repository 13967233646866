import {connect} from 'react-redux'
import {Store} from 'types'
import {selectors as authSelectors} from 'auth'
import SanitizationObjectDetails from './SanitizationObjectDetails'
import {fetchPointDetails} from '../actions'

const mapStateToProps = (state: Store) => ({
  details: state.uv.report.pointDetails,
  isLoadingDetails: state.uv.report.isLoadingDetails,
  locations: state.nsp.siteSettings.locations,
  robotId: state.app.selectedRobot?.id,
  userId: authSelectors.getUser(state)?.userId,
})

export default connect(
  mapStateToProps,
  {fetchPointDetails}
)(SanitizationObjectDetails)
