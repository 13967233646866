import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import {TransitionProps} from '@material-ui/core/transitions'
import {makeStyles} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import {ToolbarTitle} from './Toolbar'
import {Button} from './BasicElementsStyled'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  children: React.ReactNode
  isOpen: boolean
  title: string
  close: () => void
  save?: () => void
}

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    paddingLeft: '14px',
    paddingRight: '16px',
  },
  content: {
    marginTop: '56px',
  },
}))

export const FullScreenModalTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
}) as React.ComponentType<TransitionProps>

const FullScreenModal: React.FC<Props> = ({
  title,
  isOpen,
  close,
  save,
  children,
}: Props) => {
  const classes = useStyles()
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  return (
    <Dialog
      open={isOpen}
      fullScreen
      TransitionComponent={FullScreenModalTransition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start" color="inherit" onClick={close}>
            <CloseIcon />
          </IconButton>
          <ToolbarTitle>
            {title}
          </ToolbarTitle>
          {save && (
            <Button autoFocus color="inherit" onClick={save}>
              {t('Common button save')}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        {children}
      </div>
    </Dialog>
  )
}

export default FullScreenModal
