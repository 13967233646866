import React, {useState, useEffect} from 'react'
import TimePicker from './TimePicker'
import {Button, TextButton} from './BasicElementsStyled'
import {Dialog, DialogContent, DialogActions} from './Dialog'
import {blueButton, white} from '../colors'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  cancel: () => void
  initialDate?: Date
  isOpen: boolean
  save: (date: Date) => void
}

const TimePickerModal = ({
  cancel,
  initialDate,
  isOpen,
  save,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [date, setDate] = useState(initialDate || new Date())
  useEffect(() => {
    setDate(initialDate || new Date())
  }, [initialDate])

  const setHiddenDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maybeDateTimestamp = event.target.value
    if (maybeDateTimestamp) {
      setDate(new Date(parseInt(maybeDateTimestamp, 10)))
    }
  }

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={cancel}
      maxWidth="xs"
    >
      <DialogContent>
        <TimePicker
          date={date}
          onDateChange={setDate}
        />
        {/* input added for tests as updating TimePicker value from tests proved too time-consuming */}
        <input
          style={{display: 'none'}}
          data-testid="schedule-picker-date-input"
          type="text"
          onChange={setHiddenDate}
        />
      </DialogContent>
      <DialogActions>
        <TextButton
          data-testid="schedule-cancel-button"
          onClick={cancel}
        >
          {t('Common button cancel')}
        </TextButton>
        <Button
          data-testid="schedule-save-button"
          onClick={() => save(date)}
          $backgroundColor={blueButton}
          $textColor={white}
        >
          {t('Common button save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TimePickerModal
