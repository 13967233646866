import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {remindToUnplugBeforeStart} from 'config'
import {Alarm, Button, ConfirmationModal, isClientSideEvent, Loader, showEventsBasedOnState} from 'components'
import {useModuleAwareTranslation} from 'utils'
import {i18n as i18nType} from 'i18next'
import {shouldAutoCloseModal, shouldAutoCloseModalKey} from '../selectors'
import {blueButton, white} from '../../../colors'
import {ROUTES as APP_ROUTES} from '../../../constants'
import {ActionsWrapper} from '../shared'
import SelectConfigurationModal from './SelectConfigurationModal'
import {
  DiagnosticEvent,
  DiagnosticReason,
  goingHomeStateGroups,
  MainActionButtonConfig,
  PATROL_COMMAND,
  personRelatedEvents,
  SettingsConfigurationElement,
  StateMachineGroup,
  statesForContinueToHomeAction,
  statesForEstopRecoveryAction,
  statesForResumeOperationAction,
  statesForResumeToHomeAction,
  statesForReturnHomeAction,
  statesForStartAction,
} from '../types'
import {soundAlarmOff} from '../../../config'

export const PatrolActionButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    align-self: stretch;

    button {
        max-width: 75vw;
        margin: 0 12px;
        padding: 12px 18px;
        white-space: pre-line;
        line-height: 1.4em;
        flex: 1;
    }
`

export interface Props {
  configurations: SettingsConfigurationElement[]
  shouldDisableLimitFinding: boolean
  stateGroup?: StateMachineGroup
  resumingAbortedPatrol?: boolean
  currentEvent?: DiagnosticEvent
  disabled: boolean
  hasEventWithAlarm: boolean
  siteId?: string
  robotId?: string
  changeState: (command: PATROL_COMMAND, configurationUid?: string) => void
  markEventAsViewed: (siteId: string, robotId: string, eventId: string) => void
  hasMultipleEvents: boolean
  loadingEvent: boolean
  isClientEvent: boolean
  autoOpenSelectConfiguration: boolean
  setAutoOpenSelectConfig: (shouldAutoOpen: boolean) => void
}

const reasonsWithoutAnyActions = [
  DiagnosticReason.DISCONNECTED,
  DiagnosticReason.DISCONNECTED_LONG_TIME,
  DiagnosticReason.INTERNET_CONNECTION_LOST,
  DiagnosticReason.ROBOT_IN_CRITICAL_STATE,
]

const getButtonsConfig = (
  i18n: i18nType,
  isClientEvent: boolean,
  loadingEvent: boolean,
  hasAnyConfiguration: boolean,
  hasMultipleConfigurations: boolean,
  hasMultipleEvents: boolean,
  runLimitFinding: () => void,
  stateGroup?: StateMachineGroup,
  resumingAbortedPatrol?: boolean,
  eventReason?: DiagnosticReason,
  robotId?: string
): MainActionButtonConfig[] => {
  if (
    !robotId ||
    !stateGroup ||
    loadingEvent ||
    stateGroup === StateMachineGroup.CRITICAL_BATTERY ||
    stateGroup === StateMachineGroup.CRITICAL_ERROR ||
    stateGroup === StateMachineGroup.CHECKING_PRECONDITIONS ||
    (eventReason && reasonsWithoutAnyActions.includes(eventReason))
  ) {
    return []
  }

  if (isClientEvent) {
    return [
      {
        command: PATROL_COMMAND.DISMISS_CLIENT_EVENT,
        titleKey: 'Nav_Status button Dismiss',
      },
    ]
  }

  if (stateGroup == StateMachineGroup.ASSISTANCE_PAUSED) {
    return [{
      command: PATROL_COMMAND.RESUME,
      titleKey: 'Common resume',
    }]
  }

  // don't show start button when we are resuming an operation
  if (
    hasAnyConfiguration &&
    statesForStartAction.includes(stateGroup) &&
    !resumingAbortedPatrol
  ) {
    const commands = [
      {
        command: PATROL_COMMAND.START,
        titleKey: hasMultipleConfigurations ?
          'Nav_Status button Start patrol select' :
          'Nav_Status button Start patrol',
      },
    ]

    if (statesForReturnHomeAction.includes(stateGroup)) {
      commands.push({
        command: PATROL_COMMAND.HOME,
        titleKey: 'Nav_Status button Return home',
      })
    }

    return commands
  }

  if (
    statesForEstopRecoveryAction.includes(stateGroup) ||
    // temporary special case as robot cannot detect yet that ESTOP was released
    (stateGroup === StateMachineGroup.STOPPED && eventReason === DiagnosticReason.ESTOP)
  ) {
    const titleKey = eventReason && i18n.exists(`Nav_Status button stopped ${eventReason}`) ?
      `Nav_Status button stopped ${eventReason}` :
      'Nav_Status button Recover from emergency stop'
    return [
      {
        command: PATROL_COMMAND.RESUME,
        titleKey,
      },
    ]
  }

  const canResumeOperation = statesForResumeOperationAction.includes(stateGroup)
  const canContinueToHome = statesForContinueToHomeAction.includes(stateGroup)
  const canResumeToHome = statesForResumeToHomeAction.includes(stateGroup)

  if (hasMultipleEvents && (canResumeToHome || canContinueToHome || canResumeOperation)) {
    return [{
      command: PATROL_COMMAND.RESUME,
      titleKey: 'Common ok',
    }]
  }

  const canHaveResume = canResumeOperation || resumingAbortedPatrol
  const canHaveHome = canHaveResume || canContinueToHome
  const commands: MainActionButtonConfig[] = []

  if (canHaveResume) {
    commands.push({
      command: PATROL_COMMAND.RESUME,
      titleKey: 'Nav_Status button Resume patrol',
    })
  }

  if (canHaveHome) {
    commands.push({
      command: PATROL_COMMAND.HOME,
      titleKey: canContinueToHome ?
        'Nav_Status button Return home' :
        'Nav_Status button Cancel patrol',
    })
  }

  if (canResumeToHome) {
    commands.push({
      command: PATROL_COMMAND.RESUME,
      titleKey: 'Nav_Status button Return home',
    })
  }

  return commands
}

const PatrolAction: React.FC<Props> = ({
  isClientEvent,
  configurations,
  currentEvent,
  disabled,
  siteId,
  robotId,
  changeState,
  hasEventWithAlarm,
  markEventAsViewed,
  stateGroup,
  resumingAbortedPatrol,
  hasMultipleEvents,
  loadingEvent,
  setAutoOpenSelectConfig,
  autoOpenSelectConfiguration,
}: Props) => {
  const history = useHistory()
  const {t, i18n} = useModuleAwareTranslation()
  const hasMultipleConfigurations = configurations.length > 1
  const [alarmHandled, setAlarmHandled] = useState(Boolean(currentEvent?.viewed))
  const [showConfirmationCommand, setShowConfirmationCommand] = useState('')
  const [showSelectConfigurationsModal, setShowSelectConfigurationModal] = useState(false)

  let setAlarmHandledInterval: number
  let clearAutoOpenConfigSelectTimeout: number
  const currentEventId = currentEvent?.notificationUid
  const viewed = currentEvent?.viewed
  useEffect(() => {
    if (hasEventWithAlarm && !viewed) {
      clearTimeout(setAlarmHandledInterval)
      setAlarmHandled(false)
    } else if (viewed) {
      setAlarmHandled(true)
    }
  }, [currentEventId, viewed])
  useEffect(() => {
    return () => {
      clearTimeout(setAlarmHandledInterval)
      clearTimeout(clearAutoOpenConfigSelectTimeout)
    }
  }, [])
  useEffect(() => {
    if (configurations.length === 0) {
      return
    }

    if (
      configurations.length > 1 &&
      autoOpenSelectConfiguration &&
      (stateGroup === StateMachineGroup.IDLE || stateGroup === StateMachineGroup.IDLE_AWAY)
    ) {
      setShowSelectConfigurationModal(true)
    } else if (autoOpenSelectConfiguration) {
      setAutoOpenSelectConfig(false)
    }

    clearAutoOpenConfigSelectTimeout = window.setTimeout(() => {
      setAutoOpenSelectConfig(false)
    }, 500)

    return () => {
      clearTimeout(clearAutoOpenConfigSelectTimeout)
    }
  }, [configurations.length])
  useEffect(() => {
    if (stateGroup !== StateMachineGroup.IDLE && stateGroup !== StateMachineGroup.IDLE_AWAY && showSelectConfigurationsModal) {
      setShowSelectConfigurationModal(false)
    }
  }, [stateGroup, showSelectConfigurationsModal])

  const handleAlarm = (isPlaying: boolean) => {
    if (!isPlaying && !alarmHandled) {
      setAlarmHandledInterval = setTimeout(() => {
        setAlarmHandled(true)
        if (currentEvent) {
          markEventAsViewed(siteId as string, robotId as string, currentEvent.notificationUid)
        }
      }, 250)
    }
  }

  useEffect(() => {
    if (soundAlarmOff && !alarmHandled && currentEventId) {
      markEventAsViewed(siteId as string, robotId as string, currentEventId)
    }
  }, [markEventAsViewed, alarmHandled, currentEventId, siteId, robotId])

  const runLimitFinding = () => history.push(APP_ROUTES.LIMIT_FINDING_WIZARD, {autoManualMode: true})
  const hasAnyConfiguration = configurations.length > 0
  const buttonsConfig = getButtonsConfig(
    i18n,
    isClientEvent,
    loadingEvent,
    hasAnyConfiguration,
    hasMultipleConfigurations,
    hasMultipleEvents,
    runLimitFinding,
    stateGroup,
    resumingAbortedPatrol,
    currentEvent?.reason,
    robotId
  )
  const showAlarm = (
    hasEventWithAlarm &&
    !alarmHandled &&
    !soundAlarmOff &&
    (showEventsBasedOnState(stateGroup) || isClientSideEvent(currentEvent?.reason, undefined))
  )
  const isOnWayHome = goingHomeStateGroups.includes(stateGroup)

  const isShowingConfirmationForStart = showConfirmationCommand === PATROL_COMMAND.START
  return (
    <ActionsWrapper>
      {!showAlarm && buttonsConfig.length === 0 && !stateGroup && (
        <Loader centered />
      )}
      {showAlarm ? (
        <Alarm
          onChange={handleAlarm}
          switchProps={{
            $offColor: blueButton,
          }}
          alarmType={personRelatedEvents.includes(currentEvent?.reason) ? 'continuous' : 'once'}
        />
      ) : buttonsConfig.map((buttonConfig, index) => {
        const isDisabled = disabled || Boolean(buttonConfig.route) || (!buttonConfig.command && !buttonConfig.action)
        return (
          <PatrolActionButtonWrapper key={buttonConfig.command || buttonConfig.titleKey}>
            <Button
              data-testid="patrol-action-button"
              disabled={isDisabled}
              $backgroundColor={index === 0 ? blueButton : undefined}
              $textColor={index === 0 ? white : blueButton}
              $bold={index > 0}
              onClick={() => {
                const isStartCommand = buttonConfig.command === PATROL_COMMAND.START
                if (buttonConfig.action) {
                  buttonConfig.action()
                } else if (buttonConfig.command) {
                  if (isStartCommand && remindToUnplugBeforeStart) {
                    setShowConfirmationCommand(PATROL_COMMAND.START)
                  } else if (isStartCommand && hasMultipleConfigurations) {
                    setShowSelectConfigurationModal(true)
                  } else if (buttonConfig.command === PATROL_COMMAND.HOME && !isOnWayHome) {
                    setShowConfirmationCommand(PATROL_COMMAND.HOME)
                  } else {
                    changeState(buttonConfig.command, isStartCommand ? configurations[0]?.id : undefined)
                  }
                } else if (buttonConfig.route) {
                  history.push(buttonConfig.route)
                }
              }}
            >
              {t(buttonConfig.titleKey)}
            </Button>
          </PatrolActionButtonWrapper>
        )
      })}
      {showConfirmationCommand && (
        <ConfirmationModal
          leftText={isShowingConfirmationForStart}
          autoCloseSelector={shouldAutoCloseModal}
          autoCloseKey={shouldAutoCloseModalKey}
          title={isShowingConfirmationForStart ?
            t('Nav_Status confirm cable disconnected title') :
            t('Nav_Status title cancel patrol title')
          }
          description={isShowingConfirmationForStart ?
            t('Nav_Status confirm cable disconnected message') :
            t('Nav_Status content cancel patrol message')
          }
          confirmText={isShowingConfirmationForStart ?
            t('Common button confirm') :
            t('Common button yes')
          }
          cancelText={isShowingConfirmationForStart ?
            t('Common button cancel') :
            t('Common button no')
          }
          cancel={() => setShowConfirmationCommand('')}
          confirm={() => {
            const isStartCommand = showConfirmationCommand === PATROL_COMMAND.START
            setShowConfirmationCommand('')

            if (isStartCommand && hasMultipleConfigurations) {
              setShowSelectConfigurationModal(true)
            } else {
              const configurationUid = isStartCommand ? configurations[0]?.id : undefined
              changeState(showConfirmationCommand as PATROL_COMMAND, configurationUid)
            }
          }}
        />
      )}
      {showSelectConfigurationsModal && (
        <SelectConfigurationModal
          setAutoOpenSelectConfig={setAutoOpenSelectConfig}
          configurations={configurations}
          close={() => setShowSelectConfigurationModal(false)}
          run={configurationId => {
            setShowSelectConfigurationModal(false)
            changeState(PATROL_COMMAND.START, configurationId)
          }}
        />
      )}
    </ActionsWrapper>
  )
}

export default PatrolAction
