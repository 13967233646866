import {connect} from 'react-redux'
import {SimplePatrol} from '../../../navigation/types'
import {Store} from 'types'
import ReportObjectListRow from './ReportObjectListRow'

const mapStateToProps = (state: Store, ownProps: {patrol: SimplePatrol}) => ({
  locations: state.nsp.siteSettings.locations,
  sanitizedObject: (state.uv.settings.objects[0]?.objects || [])
    .find(object => object.pointId === ownProps.patrol.id),
})

export default connect(mapStateToProps)(ReportObjectListRow)
