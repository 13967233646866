import {DiagnosticReason, eventStateGroups, StateMachineGroup} from 'modules/navigation/types'
import {Severity} from './types'

export const getSeverity = (reason?: DiagnosticReason): Severity | undefined => {
  if (!reason) {
    return undefined
  }

  if (reason === DiagnosticReason.POINT_DONE) {
    return 'normal'
  }

  if (
    reason === DiagnosticReason.PERSON_AT_RISK ||
    reason === DiagnosticReason.PERSON_NOT_IN_BED ||
    reason === DiagnosticReason.PERSON_IN_HALLWAY ||
    reason === DiagnosticReason.PERSON_IN_DISINFECTING_AREA
  ) {
    return 'error'
  }

  return 'warning'
}

export const isClientSideEvent = (reason: DiagnosticReason | undefined, initializationFailedMessage: string | undefined) => (
  reason === DiagnosticReason.DISCONNECTED_LONG_TIME ||
  reason === DiagnosticReason.DISCONNECTED ||
  reason === DiagnosticReason.INTERNET_CONNECTION_LOST ||
  (reason === DiagnosticReason.ROBOT_IN_CRITICAL_STATE && !initializationFailedMessage)
)

export const showEventsBasedOnState = (stateGroup?: StateMachineGroup): boolean => eventStateGroups.includes(stateGroup)
