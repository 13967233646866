const ACTION_PREFIX = 'uv/settings/'

export const FETCH_OBJECTS = `${ACTION_PREFIX}FETCH_OBJECTS`
export const FETCH_OBJECTS_SUCCESS = `${FETCH_OBJECTS}_SUCCESS`
export const FETCH_OBJECTS_ERROR = `${FETCH_OBJECTS}_ERROR`

export const CLEAR_EDITED_OBJECTS = `${ACTION_PREFIX}CLEAR_EDITED_OBJECTS`
export const SET_EDITED_OBJECTS = `${ACTION_PREFIX}SET_EDITED_OBJECTS`

export const SET_NAME = `${ACTION_PREFIX}SET_NAME`
export const SET_HOME_UID = `${ACTION_PREFIX}SET_HOME_UID`

export const SAVE_OBJECT_SETTINGS = `${ACTION_PREFIX}SAVE_OBJECT_SETTINGS`
export const SAVE_OBJECT_SETTINGS_SUCCESS = `${SAVE_OBJECT_SETTINGS}_SUCCESS`
