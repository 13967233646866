import {connect} from 'react-redux'
import {Robot, Store} from 'types'
import {actions} from 'robotOperations'
import LimitFindingWizard from './LimitFindingWizard'
import {Location, History} from 'history'
import {STEPS} from './constants'
import {PERMISSIONS} from '../constants'

const mapStateToProps = (state: Store) => ({
  selectedRobot: state.app.selectedRobot,
  isChangingManualMode: state.app.selectedRobot ?
    Boolean(state.robotOperations.runningManualControlOperations[state.app.selectedRobot.id]) :
    false,
  limitFindingInProgress: state.limitFindingWizard.isRunningLimitFinding,
  defaultRedirectUrl: state.app.permissions?.find(permission =>
    permission.name === PERMISSIONS.ENGINEERING_DASHBOARD
  )?.options?.redirectUrl || '/',
  limitFindingFinished: Boolean(state.limitFindingWizard.limitFindingError) ||
    state.limitFindingWizard.limitFindingSuccessful ||
    state.limitFindingWizard.currentStep === STEPS.DONE,
})

const mapDispatchToProps = {
  setManualControl: actions.setManualControl,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: {setManualControl: (
    robot: Robot,
    enabled: boolean,
    isFromLimitFinding?: boolean,
    from?: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    history?: History<any>
  ) => void},
  ownProps: {location: Location<{autoManualMode?: boolean, from?: string}>}
) => ({
  ...ownProps,
  fromPage: ownProps.location.state?.from,
  defaultRedirectUrl: stateProps.defaultRedirectUrl,
  limitFindingInProgress: stateProps.limitFindingInProgress,
  isChangingManualMode: stateProps.isChangingManualMode,
  limitFindingFinished: stateProps.limitFindingFinished,
  limitFindingStatus: stateProps.selectedRobot?.healthStatus.limitFindingStatus,
  robotState: stateProps.selectedRobot?.healthStatus.robotState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setManualMode: (enabled: boolean, history: History<any>) => {
    if (stateProps.selectedRobot) {
      dispatchProps.setManualControl(
        stateProps.selectedRobot,
        enabled,
        true,
        ownProps.location.state.from,
        history
      )
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LimitFindingWizard)
