import React from 'react'
import {useModuleAwareTranslation} from 'utils'
import {schedulesDisabled} from 'config'
import {executeHooks} from 'framework'
import {SettingsSubtitleContainer} from '../shared'
import ScheduleListContainer from './ScheduleListContainer'
import {PageMainContent, PageTitle} from '../NSPCommonStyled'
import {NAV_HOOK_LABELS} from '../constants'
import {ExtendScheduleSectionsHookContext} from '../types'
import {ScheduleSettingsContainer} from '../settings'

const Schedule = () => {
  const {t} = useModuleAwareTranslation()
  const hookContext: ExtendScheduleSectionsHookContext = {
    sections: [],
  }
  executeHooks(NAV_HOOK_LABELS.SCHEDULE.EXTEND_SECTIONS, hookContext)
  return (
    <>
      <PageTitle>{t(schedulesDisabled ? 'Nav_Settings title' : 'Nav_Schedule title')}</PageTitle>
      <SettingsSubtitleContainer
        localeKeys={{
          default: 'Nav_Schedule subtitle',
          initializing: 'Nav_CommonSetting content patrol robot initializing',
          criticalState: 'Nav_CommonSetting content patrol robot critical',
          patrolConfigDown: 'Nav_CommonSetting content patrol robot limit finding not run',
          cannotSendNavCommand: 'Nav_Schedule content patrol no robot for schedule update',
          patrolling: schedulesDisabled ? '' : 'Nav_Schedule content patrol schedule description patrol',
        }}
      />
      <PageMainContent>
        {!schedulesDisabled && <ScheduleListContainer />}
        <ScheduleSettingsContainer />
        {hookContext.sections
          .sort(({order: orderA}, {order: orderB}) => orderA - orderB)
          .map(({module, Component}) => (
            <Component key={module} />
          ))}
      </PageMainContent>
    </>
  )
}

export default Schedule
