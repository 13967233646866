/* tslint:disable:interface-name */
import React from 'react'
import {Action} from 'redux'
import {NSPReducer} from 'modules/navigation/types'
import {Announcement} from '../components/types'
import {UvReducer} from 'modules/uv/types'

export interface ReminderDisplayItem {
  siteId: string
  robotId: string
  reminderName: string
}

export interface ReminderSettings {
  enabled: boolean
  [reminder: string]: Date | boolean | undefined | string
}

export interface User {
  userId: string
  reminderSettings?: {
    [siteId: string]: {
      [robotId: string]: ReminderSettings
    }
  }
}

export interface Permission {
  enabled: boolean
  name: string
  options?: {
    redirectUrl?: string
  }
}

export interface ActionWithRobot extends Action {
  robot: Robot
}

export enum Status {
  NOT_RUN_YET = 'not_run_yet',
  FAILED = 'failed',
  IN_PROGRESS = 'in_progress',
  OK = 'ok',
}

export enum LimitFindingStatus {
  NOT_RUN_YET = 'not_run_yet',
  FAILED = 'failed', // deprecated
  LIMIT_FINDING_FAILED = 'limit_finding_failed',
  GRIPPER_AUTOCAL_FAILED = 'gripper_autocal_failed',
  IN_PROGRESS = 'in_progress', // deprecated
  LIMIT_FINDING_IN_PROGRESS = 'limit_finding_in_progress',
  GRIPPER_AUTOCAL_IN_PROGRESS = 'gripper_autocal_in_progress',
  OK = 'ok',
}

export enum RobotState {
  INITIALIZING = 'system_initializing',
  MANUAL_CONTROL = 'manual_control',
  AUTONOMOUS_CONTROL = 'autonomous_control',
  EMERGENCY_STOP = 'emergency_stop',
  CRITICAL_ERROR = 'critical_error',
}

export interface RobotModules {
  enabled: string[]
  current: string
}

export interface Battery {
  isRising: boolean
  isCritical: boolean
  isInsufficient: boolean
  isLow: boolean
  level: number
}

export interface HealthStatus {
  chargingCableConnected: boolean
  limitFindingStatus: LimitFindingStatus
  powerOnSelfTestStatus: Status
  robotState: RobotState
  previousRobotState?: RobotState
  batteryLevel?: number
  emergencyStop?: boolean
  postOutcomeSuccess?: boolean
  postOutcomeCheck?: string[]
  postOutcomeFailure?: string[]
  limitFindingOutcomeRerunnable?: boolean
  limitFindingOutcomeSuccess?: boolean
  limitFindingOutcomeCheck?: string[]
  limitFindingOutcomeFailure?: string[]
  gripperAutocalOutcomeSuccess?: boolean
  gripperAutocalOutcomeCheck?: string[]
  gripperAutocalOutcomeFailure?: string[]
}

export interface Robot {
  id: string
  name: string
  siteId: string
  healthStatus: HealthStatus
  lastKeepAliveReceivedAt?: Date
  joints: string[]
  files: RobotFiles
  config?: {
    lfBatteryThreshold?: number
    battery?: {
      segments?: number
      thresholds?: number[]
    }
  }
  battery?: Battery
  modules?: RobotModules
  diagnosticData?: {
    [field: string]: any
  }
}

export interface RobotFiles {
  joints: {[jointName: string]: string}
  cube: string
}

export interface LogMessage {
  date: Date
  message: string
}

export interface SnackbarMessage {
  id: string
  message: string
  autoHideDuration?: number
  params?: object
  action?: React.ReactNode
}

export interface Lock {
  id: string
}

export interface SoundPreferences {
  isMuted: boolean
}

// TODO: split to root and type in NSP
export interface Store {
  auth: {
    user?: User
  }
  app: {
    areLogsOpen: boolean
    announcements: Announcement[]
    apiData: {
      version: string
      hash?: string
      LINEInviteLink?: string
      envs?: {[name: string]: string}
    } | null
    isSecondaryNavOpen: boolean
    isSavingBugReport: boolean
    bugReportUid: string
    bugReportData: {[field: string]: string | boolean}
    bugReportFiles: {[form: string]: File[]}
    offlineCounter: number
    offlineRobots: string[]
    connectionToServerChangedAt: Date
    isConnectedToServer: boolean
    isRobotEditOpen: boolean
    isRobotSelectorOpen: boolean
    deviceOfflineSince: Date | null
    logs: LogMessage[]
    pageOptions: {
      hideBottomNavBar?: boolean
    }
    selectedRobot: Robot | null
    siteId: string | null
    newestEventDateByRobot: {[robotId: string]: Date | undefined}
    childModules: {[module: string]: string[]}
    permissions: Permission[] | null
    supportedLanguages: string[]
    robots: Robot[]
    messages: SnackbarMessage[]
    triedToFetchRobots: boolean
    updateAvailable: boolean
    windowWidth: number
    windowHeight: number
    guiUseCase: string
  }
  limitFindingWizard: {
    currentStep: string
    isModalForStepOpen: boolean
    isRunningLimitFinding: boolean
    limitFindingError: string | null
    limitFindingSuccessful: boolean
  }
  robotOperations: {
    runningManualControlOperations: {
      [robotId: string]: boolean
    }
    isChangingSoundPreferences: boolean
  }
  dashboard: {
    locks: Lock[]
    isFetchingLocks: boolean
    isLocksModalOpen: boolean
    isGripperAutocalModalOpen: boolean
  }
  nsp: NSPReducer
  uv: UvReducer
}

export interface RobotModuleHookContext {
  modules: string[]
}
