import {AnyAction, combineReducers} from 'redux'
import * as constants from './constants'
import {OngoingPatrolEventType} from './types'
import {CurrentEventsForPreview, DiagnosticEvent} from '../types'
import {ADD_EVENT} from '../constants'

const ongoingPatrolEventsType = (state = OngoingPatrolEventType.IMAGES, action: AnyAction): OngoingPatrolEventType => {
  switch (action.type) {
    case constants.SET_ONGOING_PATROL_EVENT_TYPE:
      return action.eventType
    default:
      return state
  }
}

const currentEventsForPreview = (state: CurrentEventsForPreview | null = null, action: AnyAction): CurrentEventsForPreview | null => {
  switch (action.type) {
    case ADD_EVENT:
      return state &&
      state.siteId === action.siteId &&
      state.robotId === action.robotId &&
      (state.events || []).every(event => event.notificationUid !== action.event.notificationUid) ? {
          ...state,
          events: [...(state.events || []), {
            ...action.event,
            hasPhoto: Boolean(action.event.hasPhoto) || Boolean(action.event.photo),
          }],
        } : state
    case constants.SET_CURRENT_PATROL_EVENTS:
      return {
        siteId: action.siteId,
        robotId: action.robotId,
        patrolId: action.patrolId,
        events: action.events,
      }
    case constants.SET_CURRENT_PATROL_EVENTS_AFTER_REFETCH: {
      if (!state || state.patrolId !== action.patrolId) {
        return {
          siteId: action.siteId,
          robotId: action.robotId,
          patrolId: action.patrolId,
          events: action.events,
        }
      }

      const newEvents = action.events.filter((newEvent: DiagnosticEvent) =>
        state.events.every(existingEvent => existingEvent.notificationUid !== newEvent.notificationUid))
      if (newEvents.length === 0) {
        return state
      }

      return {
        ...state,
        events: [
          ...state.events,
          ...newEvents,
        ],
      }
    }
    default:
      return state
  }
}

export default combineReducers({
  ongoingPatrolEventsType,
  currentEventsForPreview,
})
