import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {usePrevious} from 'utils'
import RobotNameEditModalContent from './RobotNameEditModalContent'
import {Dialog} from '../components'

interface Props {
  addSnackbarMessage: (text: string) => void
  isOpen: boolean
  robotId?: string
  siteId?: string
  initialName?: string
  close: () => void
}

const RobotSelector = ({
  addSnackbarMessage,
  close,
  robotId,
  siteId,
  isOpen,
  initialName,
}: Props) => {
  const {pathname} = useLocation()
  const previousPathname = usePrevious(pathname)

  useEffect(() => {
    if (previousPathname && pathname !== previousPathname) {
      close()
    }
  }, [close, previousPathname, pathname])

  if (!isOpen || !robotId || !siteId) {
    return null
  }

  return (
    <Dialog
      fullWidth
      open
      maxWidth="sm"
      onClose={close}
    >
      <RobotNameEditModalContent
        addSnackbarMessage={addSnackbarMessage}
        initialName={initialName}
        close={close}
        isOpen
        robotId={robotId}
        siteId={siteId}
      />
    </Dialog>
  )
}

export default RobotSelector
