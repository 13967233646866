import {Title} from 'components'
import React from 'react'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const PageNotFound = () => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  return (
    <Title>
      {t('PageNotFound title page not found')}
    </Title>
  )
}

export default PageNotFound
