import {buildAction} from 'utils'
import * as constants from './constants'

export const addPatrolEvent = buildAction(constants.ADD_EVENT, 'siteId', 'robotId', 'event')
export const markEventAsViewed = buildAction(constants.MARK_EVENT_AS_VIEWED, 'siteId', 'robotId', 'eventId')

export const setAutoOpenSelectConfig = buildAction(constants.SET_AUTO_OPEN_SELECT_CONFIGURATION, 'autoOpenSelectConfiguration')

export const patrolFinished = buildAction(constants.PATROL_FINISHED, 'siteId', 'robotId', 'patrolId')
export const setPatrolFinished = buildAction(constants.SET_PATROL_FINISHED, 'patrolId')

// To be used by actions altering patrol commands
export const startPatrolStateChange = buildAction(constants.CHANGE_STATE_START, 'robotId', 'command')

export const changePatrolState = buildAction(constants.CHANGE_STATE, 'robotId', 'command', 'configurationUid', 'notificationUid')
export const changePatrolStateFinished = buildAction(constants.CHANGE_STATE_FINISHED, 'robotId')

export const configListUpdated = buildAction(constants.CONFIG_LIST_UPDATED, 'siteId', 'robotId', 'configurationUids')

export const setSettingsFromListener = buildAction(
  constants.SET_SETTINGS_LISTENER,
  'siteId',
  'robotId',
  'configurationUid',
  'settings',
  'name',
  'fullSettings')
export const setSettings = buildAction(constants.SET_SETTINGS, 'siteId', 'robotId', 'configurationUid', 'settings', 'name', 'fullSettings')
export const setSettingName = buildAction(constants.SET_SETTING_NAME, 'name')
export const setSettingHomeUid = buildAction(constants.SET_SETTING_HOME_UID, 'homeUid')
export const settingsItemToggle = buildAction(constants.SETTINGS_ITEM_TOGGLE, 'robotId', 'roomIds', 'enabled')
export const settingsItemToggleFinished = buildAction(constants.SETTINGS_ITEM_TOGGLE_FINISHED,
  'robotId', 'configurationUid', 'settings', 'name', 'homeUid')
export const settingsGetItems = buildAction(constants.SETTINGS_GET_ITEMS)
export const settingsGetItemsFinished = buildAction(constants.SETTINGS_GET_ITEMS_FINISHED, 'robotId', 'settings')

export const setSchedule = buildAction(constants.SET_SCHEDULE, 'robotId', 'schedule')
export const scheduleItemUpdate = buildAction(constants.SCHEDULE_ITEM_UPDATE, 'robotId', 'scheduleItemId',
  'enabled', 'startDate', 'cronExpression', 'rruleExpression', 'weeklyRepetitions')
export const scheduleOrderUpdate = buildAction(constants.SCHEDULE_ORDER_UPDATE, 'newOrder')
export const scheduleItemUpdateFinished = buildAction(constants.SCHEDULE_ITEM_UPDATE_FINISHED, 'robotId', 'schedule')
export const scheduleGetItems = buildAction(constants.SCHEDULE_GET_ITEMS, 'robotId')
export const scheduleGetItemsFinished = buildAction(constants.SCHEDULE_GET_ITEMS_FINISHED, 'robotId', 'schedule')

export const startScheduleUpdate = buildAction(constants.SCHEDULE_UPDATE_REQUEST_START)
export const finishScheduleUpdate = buildAction(constants.SCHEDULE_UPDATE_REQUEST_FINISH)

export const saveEventNote = buildAction(constants.SAVE_EVENT_NOTE, 'eventId', 'note', 'noteSelectedActions')
export const saveEventNoteSuccess = buildAction(constants.SAVE_EVENT_NOTE_SUCCESS, 'robotId', 'event')
export const saveEventNoteFail = buildAction(constants.SAVE_EVENT_NOTE_FAILED, 'eventId')

export const setIsShowingPreconditions = buildAction(constants.SET_IS_SHOWING_PRECONDITIONS, 'isShowingPreconditions')

export const refetchSettings = buildAction(constants.REFETCH_SETTINGS)

export const patrolSkipped = buildAction(constants.PATROL_SKIPPED, 'siteId', 'robotId', 'scheduleDate')
export const patrolAboutToStart = buildAction(constants.PATROL_ABOUT_TO_START, 'siteId', 'robotId', 'scheduleDate')

export const setVideoUrl = buildAction(constants.SET_VIDEO_URL, 'url', 'title')
