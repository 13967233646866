import React from 'react'
import * as Styled from './StepsStyled'
import {StepProps} from './types'
import {STEPS} from '../constants'
import StepTexts from './StepTexts'

const stepsWithImages = [
  STEPS.STEP_1,
  STEPS.STEP_2,
  STEPS.STEP_3,
]

const GenericStepContent = ({
  step,
}: StepProps) => {
  return (
    <Styled.Step data-testid={`lf-step-content-${step}`}>
      <StepTexts step={step} />
      {stepsWithImages.includes(step) && (
        <Styled.StepImage src={`/calibration/${step}.gif`} />
      )}
    </Styled.Step>
  )
}

export default GenericStepContent
