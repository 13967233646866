/* eslint-disable @typescript-eslint/no-explicit-any */
import {History} from 'history'

export const exitLimitFinding = (history: History<any>, from?: string) => {
  if (from) {
    history.replace(from)
  } else {
    history.goBack()
  }
}
