import {ThunkDispatch} from 'redux-thunk'
import {History} from 'history'
import {AnyAction} from 'redux'
import {types} from 'framework'
import {BackendScheduleItem, DiagnosticEvent, Room} from './types'
import {
  addPatrolEvent,
  setSchedule,
  configListUpdated,
  setSettingsFromListener,
  refetchSettings,
  patrolFinished,
  patrolSkipped,
  patrolAboutToStart,
} from './actions'
import {fetchLocations} from './siteSettings'
import {backendScheduleItemToScheduleItem} from './dataMappers'
import {EVENT_TYPES} from './constants'
import {ROUTES} from './constants'
import {patrolReminderViewed, showPatrolReminder} from './reminders'
import {ROUTES as APP_ROUTES} from '../../constants'

interface EventWithData extends Event {
  data: string
}

const handlePatrolEvent = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  eventData: {patrolEvent: DiagnosticEvent, robotId: string, siteId: string},
  getHistory: () => History
) => {
  dispatch(addPatrolEvent(eventData.siteId, eventData.robotId, eventData.patrolEvent))

  if (
    eventData.patrolEvent.isAssistance &&
        window.location.pathname !== ROUTES.INDEX &&
        window.location.pathname !== APP_ROUTES.LOGIN
  ) {
    if (window.location.pathname.startsWith('/patrol/finished')) {
      getHistory().replace(ROUTES.INDEX)
    } else if (window.location.pathname !== ROUTES.INDEX) {
      getHistory().push(ROUTES.INDEX)
    }
  }
}

const handleSettingsUpdate = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  eventData: {settings: Room[], robotId: string, siteId: string, configurationUid: string, name: string | undefined, fullSettings: object}
) => {
  dispatch(setSettingsFromListener(
    eventData.siteId,
    eventData.robotId,
    eventData.configurationUid,
    {rooms: eventData.settings},
    eventData.name,
    eventData.fullSettings
  ))
}

const handleConfigListUpdate = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  eventData: {robotId: string, siteId: string, configurationUids: string[]}
) => {
  dispatch(configListUpdated(eventData.siteId, eventData.robotId, eventData.configurationUids))
}

const handleScheduleUpdate = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  eventData: {schedule: BackendScheduleItem[], robotId: string, siteId: string}
) => {
  dispatch(setSchedule(eventData.robotId, {scheduleItems: eventData.schedule.map(backendScheduleItemToScheduleItem)}))
}

const handlePatrolSkipped = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  eventData: {scheduleDate: Date, robotId: string, siteId: string}
) => {
  dispatch(patrolSkipped(eventData.siteId, eventData.robotId, eventData.scheduleDate))
}

const handlePatrolFinished = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  eventData: {patrolId: string, robotId: string, siteId: string}
) => {
  dispatch(patrolFinished(eventData.siteId, eventData.robotId, eventData.patrolId))
}

const handlePatrolReminder = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  eventData: {reminderName: string, robotId: string, siteId: string}
) => {
  dispatch(showPatrolReminder(eventData.siteId, eventData.robotId, eventData.reminderName))
}

const handlePatrolReminderViewed = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  eventData: {reminderName: string, robotId: string, siteId: string}
) => {
  dispatch(patrolReminderViewed(eventData.siteId, eventData.robotId, eventData.reminderName))
}

const handlePatrolAboutToStart = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  eventData: {scheduleDate: Date, robotId: string, siteId: string}
) => {
  dispatch(patrolAboutToStart(eventData.siteId, eventData.robotId, eventData.scheduleDate))
}

const registerEventListeners: types.RegisterSseEventListenerCallback = (params) => {
  const {
    eventSource,
    dispatch,
    getHistory,
  } = params

  eventSource.addEventListener(EVENT_TYPES.PATROL.EVENT, (event) => {
    handlePatrolEvent(dispatch, JSON.parse((event as EventWithData).data), getHistory)
  })
  eventSource.addEventListener(EVENT_TYPES.PATROL.SCHEDULE, (event) => {
    handleScheduleUpdate(dispatch, JSON.parse((event as EventWithData).data))
  })
  eventSource.addEventListener(EVENT_TYPES.PATROL.SETTINGS, (event) => {
    handleSettingsUpdate(dispatch, JSON.parse((event as EventWithData).data))
  })
  eventSource.addEventListener(EVENT_TYPES.PATROL.CONFIG_LIST_UPDATE, (event) => {
    handleConfigListUpdate(dispatch, JSON.parse((event as EventWithData).data))
  })
  eventSource.addEventListener(EVENT_TYPES.PATROL.SKIPPED_PATROL, (event) => {
    handlePatrolSkipped(dispatch, JSON.parse((event as EventWithData).data))
  })
  eventSource.addEventListener(EVENT_TYPES.PATROL.PATROL_ABOUT_TO_START, (event) => {
    handlePatrolAboutToStart(dispatch, JSON.parse((event as EventWithData).data))
  })
  eventSource.addEventListener(EVENT_TYPES.PATROL.PATROL_FINISHED, (event) => {
    handlePatrolFinished(dispatch, JSON.parse((event as EventWithData).data))
  })
  eventSource.addEventListener(EVENT_TYPES.PATROL.REMINDER, (event) => {
    handlePatrolReminder(dispatch, JSON.parse((event as EventWithData).data))
  })
  eventSource.addEventListener(EVENT_TYPES.PATROL.REMINDER_VIEWED, (event) => {
    handlePatrolReminderViewed(dispatch, JSON.parse((event as EventWithData).data))
  })
}

const onEventsInitCallback: types.OnEventsInitCallback = (params) => {
  const {dispatch} = params
  dispatch(fetchLocations())
  dispatch(refetchSettings())
}

export {
  onEventsInitCallback,
  registerEventListeners,
}
