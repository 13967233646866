const env = (window as unknown as {_env_: {[variable: string]: string}})._env_ || {}

const envServer = env.SERVER_URL || 'http://127.0.0.1:3005'
export const serverUrl = (!envServer || envServer === '/') ?
  `${window.location.protocol}//${window.location.host}` :
  envServer

export const maxLogsEntries = env.MAX_LOGS_ENTRIES ?
  Number(env.MAX_LOGS_ENTRIES) :
  50

export const publicVapidKey = env.PUBLIC_VAPID_KEY ||
    'BKnvDOZBWL8UpZSzb5OeccArqsU30ySJ29edHq8KIe-UzwcAcX6A4G8KQHyrVfvR4S4yqg7hoFu7NhbvnXZylGs'

export const firebaseVapidKey = 'BNUyX4KcdMBd3p1ZbW-kWHfVF3eFguhmwfNCYl3fVEZg4E4cIoFJVPS3WArm06RAfZ2HrMeNLoae6qZ5lWb3unw'

export const disconnectedTimeoutSeconds = env.DISCONNECTED_TIMEOUT_SECONDS ?
  Number(env.DISCONNECTED_TIMEOUT_SECONDS) :
  (5 * 1)

export const longDisconnectedTimeoutSeconds = env.LONG_DISCONNECTED_TIMEOUT_SECONDS ?
  Number(env.LONG_DISCONNECTED_TIMEOUT_SECONDS) :
  (60 * 20)

export const robotRebootTimeoutSeconds = env.ROBOT_REBOOT_TIMEOUT_SECONDS ?
  Number(env.ROBOT_REBOOT_TIMEOUT_SECONDS) :
  (60 * 15) // 15 minutes

export const commitId = env.GIT_WEB_COMMIT || '#webCommitId'
export const commitDate = env.GIT_WEB_DATE || '#webCommitDate'

// default: 3 minutes
export const limitFindingRunningTimeout = env.LIMIT_FINDING_RUNNING_ERROR_TIMEOUT ?
  Number(env.LIMIT_FINDING_RUNNING_ERROR_TIMEOUT) :
  (1000 * 60 * 3)

// default: 5 seconds
export const refreshPageOnUpdateTimeout = env.REFRESH_PAGE_ON_UPDATE_TIMEOUT ?
  Number(env.REFRESH_PAGE_ON_UPDATE_TIMEOUT) :
  (1000 * 5)

export const roomSanitizationWarningThresholdHours = env.ROOM_SANITIZATION_WARNING_THRESHOLD_HOURS ?
  Number(env.ROOM_SANITIZATION_WARNING_THRESHOLD_HOURS) :
  48

export const soundAlarmOff = process.env.SOUND_ALARM_OFF === 'true'

export const schedulesDisabled = env.SCHEDULES_DISABLED?.toLowerCase() === 'true'

export const remindersEnabled = env.REMINDERS_ENABLED?.toLowerCase() === 'true'

export const remindToUnplugBeforeStart = env.REMIND_ABOUT_UNPLUG_BEFORE_START?.toLowerCase() === 'true'

export const reminderSyncTimeoutInSeconds = env.REMINDER_SYNC_TIMEOUT_SECONDS ?
  Number(env.REMINDER_SYNC_TIMEOUT_SECONDS) :
  (60 * 60 * 24) // 24hours - basically no sync

export const shouldUseFirebaseForPush = env.USE_FIREBASE_FOR_PUSH?.toLowerCase() === 'true'

// TODO: fill that const to have PCR-2615 ready
export const videoListUrl = env.VIDEO_LIST_URL // || 'https://youtube.com/playlist?list=PL4cUxeGkcC9jERUGvbudErNCeSZHWUVlb'


