import styled from 'styled-components'
import BottomNavigationComponent from '@material-ui/core/BottomNavigation'

export const BottomNavigation = styled(BottomNavigationComponent)`&&&{
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  background: white;
  box-shadow: 0px 1px 4px -1px rgba(0,0,0,0.2), 0px 2px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  
  .MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly {
    padding-top: 6px;
  }
  
  .MuiBottomNavigationAction-label.MuiBottomNavigationAction-iconOnly {
    opacity: 1;
    transition-delay: 0.1s;
  }
  
  .MuiBottomNavigationAction-label {
    position: relative;
    top: 2px;
  }
}`
