import React from 'react'
import {useModuleAwareTranslation} from 'utils'
import {PageTitle} from '../NSPCommonStyled'
import {SettingsSubtitleContainer} from '../shared'
import SettingsListContainer from './SettingsListContainer'

const Settings = () => {
  const {t} = useModuleAwareTranslation()

  return (
    <>
      <PageTitle>{t('Nav_Settings title Patrol Rooms')}</PageTitle>
      <SettingsSubtitleContainer
        localeKeys={{
          default: 'Nav_Rooms content patrol settings description normal',
          initializing: 'Nav_CommonSetting content patrol robot initializing',
          criticalState: 'Nav_CommonSetting content patrol robot critical',
          patrolConfigDown: 'Nav_CommonSetting content patrol robot limit finding not run',
          cannotSendNavCommand: 'Nav_Rooms content patrol no robot for setting update',
          patrolling: 'Nav_Rooms content patrol settings description patrol',
        }}
      />
      <SettingsListContainer />
    </>
  )
}

export default Settings
