import React, {useContext, useEffect, useRef, useState, useCallback} from 'react'
import useDimensions from 'react-use-dimensions'
import styled from 'styled-components'
import {BellActiveIcon} from './icons'
import Switch, {Props as SwitchProps} from './Switch'
import {AudioContext} from './types'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

export interface Props {
  className?: string
  disabled?: boolean
  onChange?: (isPlayingSound: boolean) => void
  switchProps?: Omit<SwitchProps, 'checked' | 'onChange'>
  alarmType: 'once' | 'continuous'
}

export const Wrapper = styled.div`
    width: 100%;

    > div {
        margin: 0 auto;
        font-weight: bolder;
    }
`

export const AlarmIcon = styled(BellActiveIcon)`
&.MuiSvgIcon-root {
    width: 32px;
    height: 32px;
}
`

const Alarm = ({onChange, switchProps, className, disabled, alarmType}: Props) => {
  const {audioOnce, audioContinuous} = useContext(AudioContext)
  const audio = alarmType == 'once' ? audioOnce : audioContinuous
  const [isTurnedOff, setIsPlayingSound] = useState(false)
  const [useDimensionsRef, dimensions] = useDimensions()
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const audioElement = useRef<HTMLAudioElement>(null)

  const width = dimensions.width ?
    Math.min(dimensions.width * 0.8) :
    null

  const onChangeCallback = useCallback((isOff: boolean) => {
    setIsPlayingSound(isOff)
    if (onChange) {
      onChange(!isOff)
    }
  }, [onChange])

  const handleAudioFinished = useCallback(() => {
    onChangeCallback(true)
  }, [onChangeCallback])

  useEffect(() => {
    const current = audioElement.current
    if (current && !isTurnedOff) {
      current.currentTime = 0
      current.volume = 0.5
      current.play()

      current.addEventListener('ended', handleAudioFinished)
    }

    return () => {
      if (current) {
        current.removeEventListener('ended', handleAudioFinished)
        current.pause()
      }
    }
  }, [audioElement.current, alarmType, isTurnedOff])

  useEffect(() => {
    if (audio && !isTurnedOff) {
      audio.currentTime = 0
      audio.play()
      audio.addEventListener('ended', handleAudioFinished)
    } else if (audio) {
      audio.removeEventListener('ended', handleAudioFinished)
      audio.pause()
      audio.currentTime = 0
    }
  }, [isTurnedOff, audio, alarmType])

  useEffect(() => {
    return () => {
      if (audio) {
        audio.removeEventListener('ended', handleAudioFinished)
        audio.pause()
        audio.currentTime = 0
      }
    }
  }, [audio])

  // if no audio available fallback to solution with iframe that might not work on some devices
  // (for example on iOS autoplay is not supported without user interaction)
  const addSoundControl = !isTurnedOff && !audio

  return (
    <Wrapper
      ref={useDimensionsRef}
      className={className}
    >
      {width && (
        <Switch
          disabled={disabled}
          checked={isTurnedOff}
          onChange={onChangeCallback}
          $onLabel={t('Common button slide to turn on')}
          $offLabel={t('Common button slide to turn off')}
          $onIcon={<AlarmIcon />}
          $offIcon={<AlarmIcon />}
          $height={60}
          $width={width}
          {...switchProps}
        />
      )}
      {addSoundControl && (
        <audio
          key={alarmType}
          ref={audioElement}
          autoPlay={false}
          loop={alarmType == 'continuous'}
        >
          <source src={alarmType == 'once' ? '/sound_once.mp3' : '/sound_continuous.mp3'} type="audio/mp3" />
        </audio>
      )}
    </Wrapper>
  )
}

export default Alarm
