import {connect} from 'react-redux'
import {Store} from 'types'
import {setNotificationsEnabled} from './actions'
import NotificationSettingsLinks from './NotificationSettingsLinks'

const mapStateToProps = (state: Store) => ({
  enabled: state.nsp.siteSettings.notifications.notificationsEnabled,
  LINEInviteLink: state.app.apiData?.LINEInviteLink,
})

export default connect(mapStateToProps, {
  setEnabled: setNotificationsEnabled,
})(NotificationSettingsLinks)
