import styled from 'styled-components'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {success} from '../../colors'

const SuccessIcon = styled(CheckCircleIcon)`
    color: ${success};
    flex-shrink: 0;
`

export default SuccessIcon
