import React from 'react'
import styled from 'styled-components'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import useDimensions from 'react-use-dimensions'
import {TFunction} from 'i18next'
import {defaultText} from '../../../colors'
import {RobotStoppedIcon} from 'components'
import {REVERSE_ROOM_ORDER_KEY, REVERSE_ROOM_ORDER_VALUE} from 'utils'
import {movementStateGroups, StateMachineGroup} from '../types'
import {LocationContent, LocationText, LocationWrapper, LocationWrapperForMeasure} from '../shared'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface IconProps {
  fontSize?: string
  color?: string
  customHorizontalMargin?: string
}

const Icon = styled.i`
    margin: 0 ${(props: IconProps) => props.customHorizontalMargin || '12px'};
    font-size: ${(props: IconProps) => props.fontSize || '1rem'};
    color: ${(props: IconProps) => props.color || 'inherit'};
`

const StyledRoomIcon = styled(RoomOutlinedIcon)`&&&{
  margin-right: 4px;
  position: relative;
  top: 4px;
}`

const StyledArrowIcon = styled(ArrowForwardIcon)`&&&{
  margin: 0 12px;
}`

const RobotStoppedIconStyled = styled(RobotStoppedIcon)`
    margin: 0 ${(props: {customHorizontalMargin?: string}) => props.customHorizontalMargin || '12px'};
`

const LocationLabel = styled.div`
margin-bottom: 4px;
text-align: center;
`

export interface Props {
  className?: string
  isLocationUnknown: boolean
  isGoingHome?: boolean
  lastLocation?: string
  lastLocationsAsString: string
  nextLocation?: string
  nextLocationsAsString: string
  showHome: boolean
  shouldHide: boolean
  stateMachineGroup?: StateMachineGroup
}

const robotLocationToText = (isReverseOrder: boolean, robotLocation = '', originalLocation?: string, label?: string) => {
  if (robotLocation.length > 0) {
    const locationParts = robotLocation.split(';')
    const partsToUse = isReverseOrder ? locationParts.reverse() : locationParts
    const [firstItem, ...rest] = partsToUse
    return (
      <>
        {label && <LocationText>{label}</LocationText>}
        <LocationText bold>{firstItem}</LocationText>
        <LocationText>{rest.join(', ')}</LocationText>
      </>
    )
  }

  return (
    <LocationText bold unknownLocation={Boolean(originalLocation)}>
      {originalLocation}
    </LocationText>
  )
}

const isRobotMoving = (stateMachineGroup?: StateMachineGroup) => movementStateGroups.includes(stateMachineGroup)

const hasMultipleLocations = (
  lastLocation?: string,
  lastLocationsAsString?: string,
  nextLocation?: string,
  nextLocationsAsString?: string) => {
  const last = lastLocationsAsString || lastLocation
  const next = nextLocationsAsString || nextLocation
  return last && next && next !== last
}

const getContent = (
  props: Props,
  t: TFunction,
  customIconSize?: string,
  customIconHorizontalMargin?: string
) => {
  const {
    isLocationUnknown,
    isGoingHome,
    lastLocation,
    lastLocationsAsString,
    nextLocation,
    nextLocationsAsString,
    showHome,
    stateMachineGroup,
  } = props
  const isReverseOrder = t(REVERSE_ROOM_ORDER_KEY) === REVERSE_ROOM_ORDER_VALUE
  const hasLocation = lastLocationsAsString || nextLocationsAsString || lastLocation || nextLocation
  const isMoving = isRobotMoving(stateMachineGroup)
  const hideNextLocation = !nextLocation || nextLocation === lastLocation
  let icon
  if (hasLocation) {
    icon = isMoving ? (
      <StyledArrowIcon />
    ) : <RobotStoppedIconStyled customHorizontalMargin={customIconHorizontalMargin} />
    if (isLocationUnknown) {
      icon = (
        <Icon
          className="fa fa-question"
          fontSize="3rem"
          color={defaultText}
          customHorizontalMargin={customIconHorizontalMargin}
        />
      )
    }
  }

  if (hasLocation && hideNextLocation) {
    return (
      <LocationContent center>
        {robotLocationToText(isReverseOrder, lastLocationsAsString, lastLocation)}
      </LocationContent>
    )
  }

  // if robot sends any location always show location from the robot
  if (!hasLocation && showHome) {
    return (
      <LocationContent center>
        <LocationText bold><StyledRoomIcon />{t('Nav_Titlebar location home')}</LocationText>
      </LocationContent>
    )
  }

  const fromLabel = isMoving ? `${t('Nav_Location from')}:` : undefined
  const toLabel = isMoving ? `${t('Nav_Location to')}:` : undefined
  return (
    <>
      {hasLocation && (
        <LocationContent>
          {robotLocationToText(isReverseOrder, lastLocationsAsString, lastLocation, fromLabel)}
        </LocationContent>
      )}
      {icon}
      <LocationContent>
        {/* show hardcoded home text only if we don't have location from the robot, otherwise always show what robot provides */}
        {isGoingHome && !nextLocation ? (
          <>
            {toLabel && <LocationText>{toLabel}</LocationText>}
            <LocationText bold>{t('Nav_Titlebar location home')}</LocationText>
          </>
        ) : robotLocationToText(isReverseOrder, nextLocationsAsString, nextLocation, toLabel)}
      </LocationContent>
    </>
  )
}

const PatrolLocationFromTo: React.FC<Props> = (props: Props) => {
  const {
    isLocationUnknown,
    shouldHide,
    className,
    stateMachineGroup,
  } = props
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [parentRef, parentDimensions] = useDimensions()
  const [baseRef, baseDimensions] = useDimensions()

  const baseWidth = baseDimensions.width
  const parentWidth = parentDimensions.width
  const customIconSize = baseWidth && parentWidth && (baseWidth + 30 < parentWidth) ?
    '2rem' :
    undefined
  const customIconHorizontalMargin = baseWidth && parentWidth && (baseWidth + 60 < parentWidth) ?
    `${Math.min(32, Math.floor((parentWidth - baseWidth - 40) / 2))}px` :
    undefined

  // IDLE_AWAY is special case, show special label together with location
  const isIdleAway = stateMachineGroup === StateMachineGroup.IDLE_AWAY
  const showLastLocationText = !isRobotMoving(props.stateMachineGroup) &&
    hasMultipleLocations(props.lastLocation, props.lastLocationsAsString, props.nextLocation, props.nextLocationsAsString) &&
    !isIdleAway

  return !shouldHide ? (
    <div className={className}>
      {showLastLocationText && (
        <LocationLabel>{t('Nav_Location last known')}</LocationLabel>
      )}
      {isIdleAway && (
        <LocationLabel>{t('Nav_Status content patrol status description idle away')}</LocationLabel>
      )}
      <LocationWrapper
        ref={parentRef}
        isUnknown={isLocationUnknown}
      >
        <LocationWrapperForMeasure ref={baseRef}>
          {getContent(props, t)}
        </LocationWrapperForMeasure>
        {getContent(props, t, customIconSize, customIconHorizontalMargin)}
      </LocationWrapper>
    </div>
  ) : <div />
}

export default PatrolLocationFromTo
