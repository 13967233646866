import {createSelector} from 'reselect'
import {Store} from 'types'
import {processForBestLanguage} from 'utils'
import {i18n, getLanguageCodes} from 'locale'
import {SettingsListItemConfig} from './types'
import {getCurrentSettings} from '../selectors'
import {LocationDescription, Locations} from '../types'

const settingsItemSorter = (itemA: SettingsListItemConfig, itemB: SettingsListItemConfig): number => {
  if (itemA.isUnknown && !itemB.isUnknown) {
    return -1
  }

  if (!itemA.isUnknown && itemB.isUnknown) {
    return 1
  }

  return itemA.translation.localeCompare(itemB.translation)
}

export const getSettingsConfig = createSelector(
  (state: Store) => state.nsp.siteSettings.locations,
  getCurrentSettings,
  (locations, currentSettings) => {
    if (!currentSettings) {
      return []
    }

    const languageCodes = getLanguageCodes(i18n.language)
    const locationsToUse: Locations = {}
    currentSettings.rooms.forEach(room => {
      let locationId: string | undefined = room.roomId
      while (locationId) {
        let location: LocationDescription | undefined = locations[locationId]
        if (!location) {
          location = {
            [languageCodes[0]]: locationId,
            isUnknown: true,
          }
        }

        locationsToUse[locationId] = location
        locationId = location?.parentId
      }
    })

    const topLevelItemIds: string[] = []
    const idsByParent: {[parentId: string]: string[]} = {}
    Object.keys(locationsToUse).forEach(locationId => {
      const location = locationsToUse[locationId]
      if (!location.parentId) {
        topLevelItemIds.push(locationId)
      } else {
        idsByParent[location.parentId] = [
          ...(idsByParent[location.parentId] || []),
          locationId,
        ]
      }
    })

    const mapLocation = (locationId: string): SettingsListItemConfig => {
      const translation = processForBestLanguage(languageCodes, language => locationsToUse[locationId][language], '')
      return {
        children: (idsByParent[locationId] || [])
          .map(mapLocation)
          .sort(settingsItemSorter),
        id: locationId,
        isUnknown: !translation || locationsToUse[locationId].isUnknown,
        translation: (translation || locationId) as string,
      }
    }

    return (topLevelItemIds.length > 0 ? topLevelItemIds : Object.keys(locationsToUse))
      .map(mapLocation)
      .sort(settingsItemSorter)
  }
)
