import React, {useState, useCallback, useEffect} from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import SwipeableViews from 'react-swipeable-views'
// ts file is missing for 'react-swipeable-views-utils'
// tslint:disable-next-line:ban-ts-ignore
// @ts-ignore
import {bindKeyboard, virtualize} from 'react-swipeable-views-utils'
import useDimensions from 'react-use-dimensions'
import {isTouchDevice} from 'utils'
import {serverUrl} from 'config'
import styled from 'styled-components'
import MaterialCard from '@material-ui/core/Card'
import MaterialIconButton from '@material-ui/core/IconButton'
import CardContent from '@material-ui/core/CardContent'
import {Robot} from 'types'
import {groupJoints, getBaseJointName} from './selectors'
import JointMoveComponent from './JointMoveControl'
import {primary} from '../../colors'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const BindKeyboardSwipeableViews = bindKeyboard(virtualize(SwipeableViews))
const IMAGE_RATIO = 636 / 800

const getJointIndex = (index: number, allJointsSize: number): number => {
  const mod = index % allJointsSize
  return mod < 0 ? mod + allJointsSize : mod
}

const SliderCard = styled(MaterialCard)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: ${(props: {backgroundImage: string}): string => `url(${props.backgroundImage})`};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

const StepJointSliderRobotName = styled(CardContent)`
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    word-break: break-all;
    background: rgba(255, 255, 255, 0.5);
`

// || 'div' added for shallow tests as they cannot use JointMoveComponent which is connected to store
const JointMoveControl = styled(JointMoveComponent || 'div')`
    margin: 16px;
    justify-content: space-between;

    button {
        flex-shrink: 0;
        width: 48px!important;
        height: 48px!important;
        line-height: 48px!important;
    }

    @media (min-width: 720px) {
        justify-content: flex-end;
    }
`

const IconButton = styled(MaterialIconButton)`
    position: absolute!important;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255,82,0,0.12)!important;
    ${(props: {isRight?: boolean}): string => {
    if (props.isRight) {
      return 'right: 1rem;'
    }

    return 'left: 1rem;'
  }}

    &:hover {
        background-color: rgba(255,82,0,0.3)!important;
    }

    .MuiSvgIcon-root {
        font-size: 7rem;
        color: ${primary};
    }
`

const StepJointControls = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: 16px 0 20px 0;
    text-align: left;

    > :last-child {
        min-width: 150px;
    }
`

const StepSlider = styled.div`
    max-width: 100%;
    width: calc(100vw - 50px);
    margin: 0 auto;
    flex: 1;
    position: relative;
    flex-shrink: 0;
    min-height: 350px;

    > div {
        min-height: 100%;
        height: 100%;
        padding-left: ${(props: {imageWidth: number | null}): string => (
    props.imageWidth ?
      `calc((100% - ${props.imageWidth}px) / 2)` :
      '20%')};
        padding-right: ${(props: {imageWidth: number | null}): string => (
    props.imageWidth ?
      `calc((100% - ${props.imageWidth}px) / 2)` :
      '20%')};
        > div {
            min-height: 100%;
            > div {
                min-height: 100%;
                padding: 10px;

                .MuiCard-root {
                    min-height: 100%;
                }
            }
        }
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`

interface Props {
  selectedRobot: Robot
  wrapper?: React.ElementType
  titleSelectorWrapper?: React.ElementType
  title?: JSX.Element | string
  info?: string
}

const ControlJoints = ({
  selectedRobot,
  info,
  title,
  titleSelectorWrapper,
  wrapper,
}: Props) => {
  const groupedJoints = groupJoints(selectedRobot.joints)
  const allJoints = groupedJoints.reduce((joints, group) => [...joints, ...group], [])

  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [selectedJoint, selectJoint] = useState('')
  const [slideIndex, setSlideIndex] = useState(0)
  const [useDimensionsRef, dimensions] = useDimensions()
  const imageWidth = dimensions.height ?
    dimensions.height * IMAGE_RATIO :
    null
  const jointsLength = allJoints.length

  useEffect(() => {
    if (selectedJoint) {
      const jointIndex = allJoints.findIndex((joint) => joint.endsWith(selectedJoint))
      const currentJoint = allJoints[getJointIndex(slideIndex, jointsLength)]
      const isNotCurrent = !currentJoint.endsWith(selectedJoint)
      if (jointIndex !== -1 && isNotCurrent) {
        setSlideIndex(jointIndex)
      }
    }
  }, [selectedJoint, setSlideIndex, jointsLength])

  useEffect(() => {
    if (groupedJoints && groupedJoints.length > 0) {
      selectJoint(getBaseJointName(groupedJoints[0][0]))
    }
  }, [groupedJoints])

  const onSlideChange = useCallback((index: number): void => {
    setSlideIndex(index)
    selectJoint(getBaseJointName(allJoints[getJointIndex(index, jointsLength)]))
  }, [jointsLength])

  const handleJointChange = (event: React.ChangeEvent<{value: unknown}>) => {
    selectJoint(event.target.value as string)
  }

  const showArrows = !isTouchDevice()

  const Texts = titleSelectorWrapper || 'div'
  const WrapperComponent = wrapper || Wrapper

  return (
    <WrapperComponent>
      <Texts>
        {title}
        <div>
          {info}
          <StepJointControls>
            <FormControl>
              <InputLabel id="limit-finding-joint-selector-label">
                {t('PowerOn_LimitFindingWizard Selected joint')}
              </InputLabel>
              <Select
                labelId="limit-finding-joint-selector-label"
                id="limit-finding-joint-selector"
                value={selectedJoint}
                onChange={handleJointChange}
              >
                {groupedJoints.map(joints => (
                  <MenuItem
                    key={joints[0]}
                    value={getBaseJointName(joints[0])}
                  >
                    {getBaseJointName(joints[0])}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StepJointControls>
        </div>
      </Texts>
      <StepSlider
        ref={useDimensionsRef}
        imageWidth={imageWidth}
      >
        <BindKeyboardSwipeableViews
          enableMouseEvents
          index={slideIndex}
          onChangeIndex={onSlideChange}
          slideRenderer={(params: {key: string, index: number}): JSX.Element => {
            const joint = allJoints[getJointIndex(params.index, jointsLength)]
            return (
              <SliderCard
                key={params.key}
                backgroundImage={serverUrl + selectedRobot.files.joints[joint]}
              >
                <StepJointSliderRobotName>
                  {joint}
                </StepJointSliderRobotName>
                <div>
                  <JointMoveControl
                    joint={joint}
                    robot={selectedRobot}
                    skipTextDisplay
                  />
                </div>
              </SliderCard>
            )
          }}
        />
        {showArrows && (
          <>
            <IconButton onClick={() => onSlideChange(slideIndex - 1)}>
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton isRight onClick={() => onSlideChange(slideIndex + 1)}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </>
        )}
      </StepSlider>
    </WrapperComponent>
  )
}

export default ControlJoints
