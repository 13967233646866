import React from 'react'
import {
  List,
  ListHeader,
} from 'components'
import {useModuleAwareTranslation} from 'utils'
import TimeFormatSettingContainer from './TimeFormatSettingContainer'
import ScheduleOrderSetting from './ScheduleOrderSetting'
import GetStatisticsModal from './GetStatisticsModal'
import ExportHistorySetting from './ExportHistorySetting'

const OtherSettings = () => {
  const {t} = useModuleAwareTranslation()
  return (
    <List>
      <ListHeader $withTopMargin>
        {t('Nav_SiteSettings other section title')}
      </ListHeader>
      <TimeFormatSettingContainer />
      <ScheduleOrderSetting />
      <ExportHistorySetting />
      <GetStatisticsModal />
    </List>
  )
}

export default OtherSettings
