import {combineReducers, AnyAction} from 'redux'
import * as constants from './constants'
import {ScheduleItem} from '../types'
import {Steps} from './types'

const editStep = (state = Steps.TIME, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_EDIT_STEP:
      return action.step
    default:
      return state
  }
}

const editedItem = (state: ScheduleItem | null = null, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_EDITED_ITEM:
      return action.item
    default:
      return state
  }
}

const isSavingItem = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.SAVE_EDITED_ITEM:
      return true
    case constants.SAVE_EDITED_ITEM_FINISHED:
      return false
    default:
      return state
  }
}

export default combineReducers({
  editStep,
  editedItem,
  isSavingItem,
})
