const ACTION_PREFIX = 'nsp/siteSettings/'

export const FETCH_LOCATIONS = `${ACTION_PREFIX}FETCH_LOCATIONS`
export const FETCH_LOCATIONS_FINISHED = `${ACTION_PREFIX}FETCH_LOCATIONS_FINISHED`
export const FETCH_LOCATIONS_CONFIG_SUCCESS = `${ACTION_PREFIX}FETCH_LOCATIONS_CONFIG_SUCCESS`

export const LOCATION_EDIT_START = `${ACTION_PREFIX}LOCATION_EDIT_START`
export const LOCATION_EDIT_END = `${ACTION_PREFIX}LOCATION_EDIT_END`
export const LOCATION_EDIT_CONFIRM = `${ACTION_PREFIX}LOCATION_EDIT_CONFIRM`

export const TOGGLE_GET_STATISTICS = `${ACTION_PREFIX}TOGGLE_GET_STATISTICS`
