import React, {useCallback, useEffect} from 'react'
import {useModuleAwareTranslation, usePrevious} from 'utils'
import styled from 'styled-components'
import {ReminderDisplayItem} from 'types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components'
import {REMINDERS} from '../constants'
import {blueButton, white} from '../../../colors'

const Content = styled.div`
  margin: 16px 0;
  text-align: left;
  
  li {
    margin-top: 8px;
  }
`

interface Props {
  showReminders: boolean
  stateKey?: string
  remindersToShow: ReminderDisplayItem[]
  markReminderAsViewed: (siteId: string, robotId: string, reminderName: string) => void
  closePatrolReminder: (siteId: string, robotId: string, reminderName: string) => void
}

const ReminderModal = ({
  stateKey,
  showReminders,
  remindersToShow,
  closePatrolReminder,
  markReminderAsViewed,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const previousStateKey = usePrevious(stateKey)
  const close = useCallback(() => {
    remindersToShow.forEach(reminder => closePatrolReminder(reminder.siteId, reminder.robotId, reminder.reminderName))
  }, [remindersToShow, closePatrolReminder])
  const confirm = useCallback(() => {
    remindersToShow.forEach(reminder => markReminderAsViewed(reminder.siteId, reminder.robotId, reminder.reminderName))
    close()
  }, [remindersToShow, markReminderAsViewed, close])
  useEffect(() => {
    if (previousStateKey && stateKey !== previousStateKey) {
      close()
    }
  }, [close, stateKey, previousStateKey])
  return showReminders && remindersToShow.length > 0 ? (
    <Dialog
      open
      onClose={close}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <DialogTitle>
          {t('Common reminders title')}
        </DialogTitle>
        <Content>
          {remindersToShow.length > 1 ? (
            <ol>
              {remindersToShow.map(reminder => (
                <li key={reminder.reminderName}>
                  {t(`Reminder ${REMINDERS.find(({key}) => key === reminder.reminderName)?.translationKey} text`)}
                </li>
              ))}
            </ol>
          ) : (
            <div>
              {t(`Reminder ${REMINDERS.find(({key}) => key === remindersToShow[0].reminderName)?.translationKey} text`)}
            </div>
          )}
        </Content>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="confirmation-confirm"
          onClick={confirm}
          $backgroundColor={blueButton}
          $textColor={white}
        >
          {t('Common button confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null
}

export default ReminderModal
