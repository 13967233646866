import {Reducer} from 'react'
import {reducers} from './reducers'
import {Reducers} from './types'

class ReducerRegistry {
  constructor() {
    this.emitChange = null
    this.reducers = reducers
  }

  emitChange: ((reducers: Reducers) => void) | null
  reducers: Reducers

  getReducers() {
    return {
      ...this.reducers,
    }
  }

  register(name: string, reducer: Reducer<any, any>) {
    this.reducers = {
      ...this.reducers,
      [name]: reducer,
    }

    if (this.emitChange) {
      this.emitChange(this.getReducers())
    }
  }

  setChangeListener(listener: (reducers: Reducers) => void) {
    this.emitChange = listener
  }
}

const reducerRegistry = new ReducerRegistry()

export default reducerRegistry
