import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {RouteChildrenProps} from 'react-router'
import {setPageOptions} from './actions'

interface Props {
    setPageOptions: (options: {}) => void
}

// tslint:disable-next-line:no-any
const withPageOptionsHOC = (pageOptions: {}) => (WrappedComponent: React.ComponentType<RouteChildrenProps | any>) => {
  // tslint:disable-next-line:no-shadowed-variable
  const HOC = ({setPageOptions, ...props}: Props) => {
    useEffect(() => {
      setPageOptions(pageOptions)
    }, [pageOptions])

    return <WrappedComponent {...props} />
  }

  return connect(null, {setPageOptions})(HOC)
}

export default withPageOptionsHOC
