import React from 'react'
import moment from 'moment'
import {getTimeFormatForMoment, processForBestLanguage, useModuleAwareTranslation} from 'utils'
import {executeHooks} from 'framework'
import {getLanguageCodes} from 'locale'
import {NAV_HOOK_LABELS} from '../../constants'
import {DiagnosticEvent, ExtendEventTitleHookContext, Locations} from '../../types'
import {ReportListRow} from '../shared'
import {getLocationParts, hasFaceId} from '../../utils'
import {ListHeader} from 'components'
import styled from 'styled-components'
import {lightGray} from '../../../../colors'
import {getDetailedLocation} from './utils'

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`

const Header = styled(ListHeader)`
  grid-column: 1 / span 3;
  margin-top: 24px;
  border-bottom: 1px solid ${lightGray};
`

const ListItem = styled.div`
`

export interface Props {
  setSelectedEvent?: (eventId: string) => void
  event: DiagnosticEvent
  eventLocation: string | string[] | undefined
  locations: Locations
  dateFormat?: string
  hideDate?: boolean
  extraTranslationOptions?: {
    [key: string]: string | undefined
  }
  modules?: string[]
  userId?: string
}

const ReportEventSummary = ({
  event,
  locations,
  setSelectedEvent,
  extraTranslationOptions,
  dateFormat,
  modules,
  eventLocation,
  hideDate,
  userId,
}: Props) => {
  const {
    notificationUid,
    date,
    reason,
  } = event

  const location = eventLocation
  const nextLocation = event.toLocation
  const {t, i18n} = useModuleAwareTranslation(modules)
  const languageCodes = getLanguageCodes(i18n.language)
  const multipleTranslationKey = `Nav_ReportEventView content ${reason} multiple`
  const multipleTranslationMoreKey = `${multipleTranslationKey} more`
  const multipleTranslationMoreTitleKey = `${multipleTranslationMoreKey} title`
  const hasMultipleTranslationKey = i18n.exists(multipleTranslationKey)
  const hasMultipleTranslationMoreKey = i18n.exists(multipleTranslationMoreKey)
  const hasMultipleLocations = Array.isArray(location) && location.length > 1
  const shouldShowOnlyFirst2Locations = hasMultipleLocations && hasMultipleTranslationMoreKey && location!.length > 5
  const multipleLocationsLimit = shouldShowOnlyFirst2Locations ? 2 : undefined
  const validLocations = (Array.isArray(location) ? location : [location]).filter(Boolean) as string[]

  const roomName = validLocations
    .slice(0, hasMultipleTranslationKey ? multipleLocationsLimit : 1)
    .map((locationId: string) => locationId && locations[locationId] ?
      processForBestLanguage(languageCodes, language => locations[locationId][language], '') :
      locationId
    )
    .join(', ')
  const roomNameNext = nextLocation && locations[nextLocation] ?
    processForBestLanguage(languageCodes, language => locations[nextLocation][language], '') :
    nextLocation
  let translationKey = 'Nav_ReportEventView content unknown'
  if (hasFaceId(event.faceId) && i18n.exists(`Nav_ReportEventView content ${reason} face_id`)) {
    translationKey = `Nav_ReportEventView content ${reason} face_id`
  } else if (i18n.exists(`Nav_ReportEventView content ${reason}`)) {
    translationKey = `Nav_ReportEventView content ${reason}`
  }

  if (hasMultipleLocations && hasMultipleTranslationKey) {
    translationKey = multipleTranslationKey
  }

  if (shouldShowOnlyFirst2Locations) {
    translationKey = multipleTranslationMoreKey
  }

  const translationOptions = {
    roomName,
    roomNameNext,
    name: event.faceId,
    count: shouldShowOnlyFirst2Locations ? (location!.length - 2) : undefined,
    detailedLocation: getDetailedLocation(
      event,
      locations,
      event.patrolPointConfig?.patrolPoint?.uid || ''
    ) || t('Nav_ReportEventView content unknown point').toLowerCase(),
    ...(extraTranslationOptions || {}),
  }

  const hookContext: ExtendEventTitleHookContext = {
    diagnosticEvent: event,
    options: translationOptions,
    locations,
    t,
  }
  executeHooks(NAV_HOOK_LABELS.EVENTS.EXTEND_REPORT_SUMMARY_TEXT_OPTIONS, hookContext)

  const timeFormat = getTimeFormatForMoment(userId)
  const title = extraTranslationOptions?.title || t(translationKey, translationOptions)
  const dateSeparator = dateFormat ? '\n' : ' '
  const dateDisplay = hideDate ? '' : `(${moment(date).format(dateFormat || timeFormat)})`
  const dateString = hideDate ? '' : (dateSeparator + dateDisplay)
  const locationString = title && !title.includes(translationOptions.detailedLocation) ?
    `\n${translationOptions.detailedLocation}` :
    ''

  const link = setSelectedEvent ? () => setSelectedEvent(notificationUid) : undefined
  let previousParent = ''
  return (
    <ReportListRow
      link={link}
      text={`${title}${dateString}${locationString}`}
      reason={reason}
      extendedInformation={shouldShowOnlyFirst2Locations && i18n.exists(multipleTranslationMoreTitleKey) ? {
        modalTitleKey: t(multipleTranslationMoreTitleKey),
        content: (
          <List>
            {validLocations
              .sort((locationIdA: string, locationIdB: string) => {
                const locationAFullText = getLocationParts(locations, locationIdA).join(', ')
                const locationBFullText = getLocationParts(locations, locationIdB).join(', ')

                return locationAFullText.localeCompare(locationBFullText)
              })
              .map((locationId: string) => {
                const location = locationId && locations[locationId] ?
                  processForBestLanguage(languageCodes, language => locations[locationId][language], '') :
                  locationId

                const parentId = locations[locationId]?.parentId
                const parent = parentId && locations[parentId] ?
                  getLocationParts(locations, parentId).join(', ') :
                  ''
                const renderHeader = previousParent !== parent && parent
                previousParent = parent
                return (
                  <React.Fragment key={locationId}>
                    {renderHeader && <Header>{parent}</Header>}
                    <ListItem>
                      {location}
                    </ListItem>
                  </React.Fragment>
                )
              })
            }
          </List>
        ),
      } : undefined}
    />
  )
}

export default ReportEventSummary
