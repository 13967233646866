import {BugReportContainer, withPageOptionsHOC} from 'app'
import React, {useContext} from 'react'
import {
  Route,
  Switch,
} from 'react-router-dom'
import {PageNotFound} from 'notFound'
import {executeHooks, types} from 'framework'
import {RobotModulesContext} from 'app/types'
import PatrolManual from './PatrolManual'
import {Schedule, ScheduleEditPage, ScheduleEditPageNavBarContainer} from './schedule'
import {SiteLocations, SiteSettings, NotificationSettings} from './siteSettings'
import {Settings} from './settings'
import {PatrolFinishedContainer} from './finished'
import {Patrol} from './status'
import {ReportDetailsContainer, ReportList} from './report'
import NSPWrapperContainer from './NSPWrapperContainer'
import {NAV_HOOK_LABELS, ROUTES} from './constants'

const pageOptions = {}

const scheduleUpdatePageOptions = {
  ...pageOptions,
  bottomNavBar: ScheduleEditPageNavBarContainer,
}

const bugReportPageOptions = {
  ...pageOptions,
  hideBottomNavBar: true,
}

const NavigationRoutes = () => {
  const {currentModule} = useContext(RobotModulesContext)
  const hookContext: types.RegisterRouteParams = {
    baseRoute: ROUTES.INDEX,
    currentModule,
  }
  const result = executeHooks(NAV_HOOK_LABELS.ROUTING.REGISTER_ROUTES, hookContext)
  const extraRoutes: React.ReactElement[] = Object.keys(result).length === 0 ?
    [] :
    Object.values(result).filter(Boolean).reduce((acc: React.ReactElement[], routes) => [
      ...acc,
      ...(Array.isArray(routes) ? routes : [routes]),
    ], [])

  return (
    <NSPWrapperContainer>
      <Switch>
        <Route
          component={withPageOptionsHOC(pageOptions)(Patrol)}
          path={ROUTES.INDEX}
          exact
        />
        <Route
          component={withPageOptionsHOC(pageOptions)(PatrolFinishedContainer)}
          path={ROUTES.FINISHED_PATROL}
        />
        {/* before other routes as modules can override for example report list page */}
        {extraRoutes}
        <Route
          component={withPageOptionsHOC(pageOptions)(ReportList)}
          path={ROUTES.REPORTS}
        />
        <Route
          component={withPageOptionsHOC(pageOptions)(ReportDetailsContainer)}
          path={ROUTES.REPORT}
        />
        <Route
          component={withPageOptionsHOC(pageOptions)(ReportDetailsContainer)}
          path={ROUTES.REPORT_CURRENT}
        />
        <Route
          component={withPageOptionsHOC(pageOptions)(Settings)}
          path={ROUTES.SETTINGS}
        />
        <Route
          component={withPageOptionsHOC(scheduleUpdatePageOptions)(ScheduleEditPage)}
          path={ROUTES.SCHEDULE_UPDATE}
        />
        <Route
          component={withPageOptionsHOC(pageOptions)(Schedule)}
          path={ROUTES.SCHEDULE}
        />
        <Route
          component={withPageOptionsHOC(bugReportPageOptions)(BugReportContainer)}
          path={ROUTES.BUG_REPORT}
        />
        <Route
          component={withPageOptionsHOC(pageOptions)(PatrolManual)}
          path={ROUTES.MANUAL}
        />
        <Route
          component={withPageOptionsHOC(pageOptions)(SiteSettings)}
          path={ROUTES.SITE_SETTINGS}
        />
        <Route
          component={withPageOptionsHOC(pageOptions)(SiteLocations)}
          path={ROUTES.SITE_SETTINGS_LOCATIONS}
        />
        <Route
          component={withPageOptionsHOC(pageOptions)(NotificationSettings)}
          path={ROUTES.SITE_SETTINGS_NOTIFICATIONS}
        />
        <Route
          component={withPageOptionsHOC({hideBottomNavBar: true})(PageNotFound)}
          path="*"
        />
      </Switch>
    </NSPWrapperContainer>
  )
}

const Routes = () => (
  <Route
    component={NavigationRoutes}
    path={ROUTES.INDEX}
  />
)

export default Routes
