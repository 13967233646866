import {isNative} from './utils'

async function webFileDownload(response: Response, fileName: string) {
  const blob = await response.blob()
  const blobURL = window.URL.createObjectURL(blob)
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = blobURL
  tempLink.setAttribute('download', fileName)
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL)
  }, 100)
}

export async function downloadFileFromResponse(response: Response, fileName: string) {
  if (isNative()) {
    // when moving to capacitor see commit
    // https://github.com/aeolusbot/user-infra-web/commit/2729c652edf53432597adf8c7ccc237cafcdf54e
    console.error('not implemented')
  } else {
    return webFileDownload(response, fileName)
  }
}
