const ACTION_PREFIX = 'navigation/schedule/'

export const SET_EDIT_STEP = `${ACTION_PREFIX}SET_EDIT_STEP`

export const SET_EDITED_ITEM = `${ACTION_PREFIX}SET_EDITED_ITEM`

export const SAVE_EDITED_ITEM = `${ACTION_PREFIX}SAVE_EDITED_ITEM`
export const SAVE_EDITED_ITEM_FINISHED = `${ACTION_PREFIX}SAVE_EDITED_ITEM_FINISHED`

export const DEFAULT_CRON = '0 0 0 * * *'

export const DEFAULT_RRULE = 'DTSTART:20220401T000000Z\nRRULE:FREQ=DAILY;WKST=MO'


export const SCHEDULE_REPEAT_OPTIONS = [
  {
    type: 'every_day',
    cronExpressionCreator: () => '0 0 0 * * *',
    regex: new RegExp('\\d+ \\d+ \\d+ \\*(/\\d+)? \\* \\*'),
  },
  {
    type: 'monday_friday',
    cronExpressionCreator: () => '0 0 0 * * 1-5',
    regex: new RegExp('\\d+ \\d+ \\d+ \\* \\* 1-5$'),
  },
  {
    type: 'weekends',
    cronExpressionCreator: () => '0 0 0 * * 0,6',
    regex: new RegExp('\\d+ \\d+ \\d+ \\* \\* 0,6$'),
  },
  {
    type: 'every_week',
    cronExpressionCreator: (date?: Date) => {
      const dateToUse = date || new Date()
      // both in cron and in getUTCDay Sunday is represented by 0
      return `0 0 0 * * ${dateToUse.getUTCDay()}`
    },
    // every week on Mon, Tue, Thu and Fri => '0 0 0 * * 1,2,4,5'
    regex: new RegExp('^\\d+ \\d+ \\d+ \\* \\* \\d(,\\d)*$'),
  },
  {
    type: 'every_month',
    cronExpressionCreator: (date?: Date) => {
      const dateToUse = date || new Date()
      // run every month on specified day of the month
      return `0 0 0 ${dateToUse.getUTCDate()} * *`
    },
    // '12th day of the month"  0 0 0 12 * * | 'Last Wed of the month"  0 0 0 * * 3L | '2nd Wed of the month' 0 0 0 * * 3#2
    regex: new RegExp('(\\d+ \\d+ \\d+ \\d \\*(/\\d+)? \\*)|(0 0 0 \\* \\*(/\\d+)? \\d(L|#\\d))'),
  },
  // {
  //   type: 'every_year',
  //   cronExpressionCreator: (date?: Date) => {
  //     const dateToUse = date || new Date()
  //     // run every year on specified day of the month
  //     return `0 0 0 ${dateToUse.getUTCDate()} ${dateToUse.getUTCMonth()} *`
  //   },
  //   // 'every year on April 26th' '0 0 0 26 4 *' | 'every year on the 2nd Monday of April' -> '0 0 0 * 4 1#2'
  //   // 'every year on the last Monday of April' -> '0 0 0 * 4 1L'
  //   regex: new RegExp('(\\d+ \\d+ \\d+ \\d \\d \\*)|(0 0 0 \\* \\d \\d(L|#\\d))'),
  // },
  {
    type: 'custom',
    cronExpressionCreator: () => '0 0 0 * * *',
  },
]

export const SCHEDULE_TYPES_FOR_MODAL = ['every_day', 'every_week', 'every_month'/* , 'every_year' */, 'custom']

export const REPEAT_OPTIONS = ['hour', 'day', 'week', 'month'/* , 'year' */]

export const MAX_COUNT_BY_OPTION: {[key: string]: number} = {
  hour: 23,
  day: 31,
  week: 51,
  month: 11,
}
