/* tslint:disable:no-console */
import firebase from 'firebase/app'
import 'firebase/messaging'
import {publicVapidKey, firebaseVapidKey, serverUrl, shouldUseFirebaseForPush} from 'config'
import {urlBase64ToUint8Array, httpPost, logInfoToServer} from 'utils'
import {fallbackLanguage, i18n} from '../locale'
import {routeChangedEvent} from '../events/customEvents'
import {isNative} from './utils'
import firebaseConfig from '../firebase-config.json'

let messaging: firebase.messaging.Messaging | undefined = undefined

const initFirebaseApp = () => {
  const firebaseApp = firebase.initializeApp(firebaseConfig)

  messaging = firebase.messaging(firebaseApp)
  // if tab is focused no need to handle push
  // messaging.onMessage( (payload) => {
  // })
}

const registerForFirebasePush = (registration: ServiceWorkerRegistration) => {
  if (!messaging && firebaseConfig.projectId) {
    initFirebaseApp()
  }

  if (shouldUseFirebaseForPush && messaging) {
    messaging.getToken({
      vapidKey: firebaseVapidKey,
      serviceWorkerRegistration: registration,
    })
      .then(token => {
        const registerFcmToken = async () => {
          try {
            await httpPost(`events/fcm-subscribe`, {token})
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(() => registerFcmToken(), 500)
          }
        }

        registerFcmToken()
      })
      .catch(error => logInfoToServer(`error when getting FCM token: ${error.message || JSON.stringify(error)}`))
  }
}

// when moving to capacitor check commit
// https://github.com/aeolusbot/user-infra-web/commit/fa4140d28e4e89a91dfbd45f4121252fc7981b71
// and adjust the code accordingly
const setSubscription = (subscription: PushSubscription, registration: ServiceWorkerRegistration) => {
  registerForFirebasePush(registration)
  const register = async () => {
    try {
      await httpPost(`events/init`, subscription)
    } catch (error) {
      logInfoToServer(`push registration - ${JSON.stringify(error)}`)
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(() => register(), 500)
    }
  }

  register()
}

const subscribePushNotifications = (registration: ServiceWorkerRegistration) => {
  registration.pushManager.getSubscription().then((existingSubscription) => {
    if (existingSubscription === null) {
      registration.pushManager.subscribe({
        applicationServerKey: urlBase64ToUint8Array(shouldUseFirebaseForPush ? firebaseVapidKey : publicVapidKey),
        userVisibleOnly: true,
      }).then((newSubscription) => {
        setSubscription(newSubscription, registration)
      }).catch((e) => {
        console.error(e)
        if (Notification.permission !== 'granted') {
          console.log('Permission was not granted.')
        } else {
          console.log('An error ocurred during the subscription process.' + JSON.stringify(e))
        }
      })
    } else {
      setSubscription(existingSubscription, registration)
    }
  })
}

export function initNotifications(onUpdateAvailable: () => void) {
  if (isNative()) {
    console.error('not supported')
    return
  }

  if ('serviceWorker' in navigator) {
    const isProduction = process.env.NODE_ENV === 'production'
    let subscribedToPush = false
    navigator.serviceWorker
      // sw file is generated on build
      .register(isProduction ? '/sw.js' : '/sw-custom.js', {scope: '/'})
      .then((registration) => {
        let serviceWorker
        if (registration.installing) serviceWorker = registration.installing
        else if (registration.waiting) serviceWorker = registration.waiting
        else if (registration.active) serviceWorker = registration.active

        if (serviceWorker) {
          console.log('sw current state', serviceWorker.state)
          if (serviceWorker.state === 'activated' && !subscribedToPush) {
            subscribePushNotifications(registration)
            subscribedToPush = true
          }

          serviceWorker.addEventListener('statechange', function(event) {
            // @ts-ignore
            const state = event && event.target ? event.target.state : undefined
            console.log('sw statechange :', state)
            if (state === 'activated' && !subscribedToPush) {
              // use pushManger for subscribing here.
              console.log('Just now activated. now we can subscribe for push notification')
              subscribePushNotifications(registration)
              subscribedToPush = true
            }
          })
        }

        registration.onupdatefound = () => {
          const installingWorker = registration.installing
          if (installingWorker == null) {
            return
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // At this point, the updated precached content has been fetched,
                // but the previous service worker will still serve the older
                // content until all client tabs are closed.
                console.log(
                  'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
                )

                if (isProduction) {
                  onUpdateAvailable()
                }
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.
                console.log('Content is cached for offline use.', installingWorker.state)
              }
            }
          }
        }

        navigator.serviceWorker.ready.then(() => {
          if (registration.active) {
            if (shouldUseFirebaseForPush) {
              registration.active.postMessage({
                firebaseConfig,
              })
            }

            registration.active.postMessage({
              currentLanguage: i18n.language,
              serverPublicUrl: serverUrl,
              fallbackLanguage,
            })

            const checkCurrentResources = () => {
              const stringifiedResources = window.localStorage.getItem('translations')
              if (!stringifiedResources || !registration.active) {
                return
              }
              const currentResources = JSON.parse(stringifiedResources)
              registration.active.postMessage({
                currentResources,
              })

              // @ts-ignore
              if (window.isLoadingLanguage) {
                setTimeout(checkCurrentResources, 250)
              }
            }

            const checkModules = () => {
              // @ts-ignore
              if (!window.modulesInitialized) {
                setTimeout(checkModules, 250)
              }

              const stringifiedModules = window.localStorage.getItem('enabledModules')
              if (!stringifiedModules || !registration.active) {
                return
              }
              const enabledModules = JSON.parse(stringifiedModules)
              registration.active.postMessage({
                mainModule: enabledModules[0],
              })
            }

            checkModules()
            checkCurrentResources()
          }

          // @ts-ignore
          window.addEventListener(routeChangedEvent.type, function() {
            registration.update()
              .then(() => {
                // updated
              })
          })
        })
      })
      .catch((error) => {
        console.log('Error during service worker registration:' + JSON.stringify(error))
        console.error(error)
      })
  } else {
    console.error('Service workers are not supported in this browser')
  }
}
