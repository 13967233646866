import React from 'react'
import styled from 'styled-components'
import {useModuleAwareTranslation} from 'utils'
import {warning, success, secondaryHeader, white} from '../../../colors'
import {stateGroupsForPatrolCounters, StateMachineGroup} from '../types'

const PatrolPoints = styled.div`
  font-size: 1rem;
  background-color: ${secondaryHeader};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  
  strong {
    font-size: 1.25rem;
  }
`

const PatrolPoint = styled.div`
  padding: 0 6px;
  text-align: center;
  flex: 1;
  flex-shrink: 0;
  line-height: 32px;
  height: 32px;
`

export interface Props {
  stateGroup?: StateMachineGroup
  checkedPoints: number
  skippedPoints: number
  totalPoints: number
}

const PatrolCounters: React.FC<Props> = ({
  stateGroup,
  totalPoints,
  checkedPoints,
  skippedPoints,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const pointsLeft = totalPoints - checkedPoints - skippedPoints
  return stateGroupsForPatrolCounters.includes(stateGroup) && totalPoints > 0 ? (
    <PatrolPoints>
      <PatrolPoint
        style={{backgroundColor: success, color: white}}
        dangerouslySetInnerHTML={{__html: t('Nav_Status content patrol status counters done', {number: checkedPoints})}}
      />
      {skippedPoints ? (
        <PatrolPoint
          style={{backgroundColor: warning}}
          dangerouslySetInnerHTML={{__html: t('Nav_Status content patrol status counters skipped', {number: skippedPoints})}}
        />
      ) : null}
      <PatrolPoint
        style={{color: white}}
        dangerouslySetInnerHTML={{__html: t('Nav_Status content patrol status counters to be done', {number: pointsLeft})}}
      />
    </PatrolPoints>
  ) : null
}

export default PatrolCounters
