import {buildAction} from 'utils'
import * as constants from './constants'

export const moveJoint = buildAction(constants.MOVE_JOINT, 'robot', 'joint', 'isPositiveDirection')

export const setManualControlRaw = buildAction(constants.SET_MANUAL_CONTROL_RAW, 'robot', 'enable', 'isFromLimitFinding')
export const setManualControl = buildAction(constants.SET_MANUAL_CONTROL, 'robot', 'enable', 'isFromLimitFinding', 'from', 'history')
export const finishManualControl = buildAction(constants.FINISH_MANUAL_CONTROL, 'robot')

export const setSoundPreferences = buildAction(constants.SET_SOUND, 'shouldMute')
export const setSoundPreferencesFinished = buildAction(constants.SET_SOUND_FINISHED)
