import {isNative} from './utils'

interface Storage {
  setItem(key: string, value: string): Promise<void>
  getItem(key: string): Promise<string | null>
  removeItem(key: string): Promise<void>
}

// when moving to capacitor use
// https://capacitorjs.com/docs/guides/storage
const NativeStorage: Storage = {
  getItem(key: string): Promise<string | null> {
    throw new Error('not implemented')
  },
  // eslint-disable-next-line @typescript-eslint/require-await
  async removeItem(key: string): Promise<void> {
    throw new Error('not implemented')
  },
  // eslint-disable-next-line @typescript-eslint/require-await
  async setItem(key: string, value: string): Promise<void> {
    throw new Error('not implemented')
  },
}

const WebStorage: Storage = {
  getItem(key: string): Promise<string | null> {
    return Promise.resolve(localStorage.getItem(key))
  },
  // eslint-disable-next-line @typescript-eslint/require-await
  async removeItem(key: string): Promise<void> {
    localStorage.removeItem(key)
  },
  // eslint-disable-next-line @typescript-eslint/require-await
  async setItem(key: string, value: string): Promise<void> {
    localStorage.setItem(key, value)
  },
}

const AppStorage = isNative() ? NativeStorage : WebStorage

export default AppStorage
