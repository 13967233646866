import {buildAction} from '../utils'
import * as constants from './constants'

export const fetchLocks = buildAction(constants.FETCH_LOCKS)
export const fetchLocksDone = buildAction(constants.FETCH_LOCKS_DONE, 'locks')

export const setLock = buildAction(constants.SET_LOCK, 'lockId', 'shouldLock')

export const openLocksModal = buildAction(constants.OPEN_LOCKS_MODAL, 'shouldClose')

export const openGripperAutocalModal = buildAction(constants.OPEN_GRIPPER_AUTOCAL_MODAL, 'shouldClose')
