import {connect} from 'react-redux'
import {Store} from 'types'
import ToolbarContent from './ToolbarContent'
import {openSecondaryNav} from './actions'

const mapStateToProps = (state: Store) => ({
  robotName: state.app.selectedRobot?.name,
  currentModule: state.app.selectedRobot?.modules?.current,
})

export default connect(mapStateToProps, {openDrawer: openSecondaryNav})(ToolbarContent)
