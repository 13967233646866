import moment from 'moment'
import {Robot, RobotState, Status} from 'types'
import {disconnectedTimeoutSeconds, robotRebootTimeoutSeconds} from '../config'
import {select} from 'redux-saga/effects'
import {StateMachineGroup} from '../modules/navigation/types'

export const getKeyForRobot = (data: {id: string, siteId: string}) => `${data.siteId}__${data.id}`

export const getRobotDataFromKey = (robotKey: string) => {
  const [siteId, robotId] = robotKey.split('__')
  return {
    siteId: siteId || '',
    id: robotId || '',
  }
}

export function isRobotOnline<T extends {lastKeepAliveReceivedAt?: Date, id: string, diagnosticData?: any}>(robot?: T | null) {
  if (!robot) {
    return false
  }

  let defaultTimeoutSeconds = disconnectedTimeoutSeconds
  if (robot?.diagnosticData?.stateMachine?.stateGroup === StateMachineGroup.RESTARTING) {
    defaultTimeoutSeconds += robotRebootTimeoutSeconds
  }

  const timeoutInSeconds = robot.id === 'r2v2.sim' ? Math.min(20, defaultTimeoutSeconds) : defaultTimeoutSeconds
  const timeoutDate = moment().add(-1 * timeoutInSeconds, 'seconds')
  return Boolean(robot.lastKeepAliveReceivedAt) && moment(robot.lastKeepAliveReceivedAt).isAfter(timeoutDate)
}

export const getStatusLocalizationKey = (status: Status): string => {
  switch (status) {
    case Status.NOT_RUN_YET:
    case Status.FAILED:
      return 'Common nok'
    case Status.IN_PROGRESS:
      return 'Common in progress'
    case Status.OK:
      return 'Common ok'
    default:
      return ''
  }
}

export function* getSelectedSiteAndRobotId() {
  const selectedRobot = yield select(state => state.app.selectedRobot)
  return {siteId: selectedRobot?.siteId, robotId: selectedRobot?.id}
}

export const isPatrolConfigRunning = (robot: Robot | null | undefined): boolean => {
  return Boolean(robot?.diagnosticData?.patrolConfig)
}

export function* isRobotInManualMode() {
  const selectedRobot: Robot | null = yield select(state => state.app.selectedRobot)
  return selectedRobot?.healthStatus.robotState === RobotState.MANUAL_CONTROL
}
