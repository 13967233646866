import React from 'react'
import styled from 'styled-components'
import {getScheduleTranslationKeyAndParams} from './utils'
import {grayText} from '../../../colors'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const Info = styled.div`
  color: ${grayText}
`

interface Props {
  className?: string
  cronExpression: string
  rruleExpression: string
  weeklyRepetitions: number
}

const ScheduleRepeatInfo = ({
  className,
  weeklyRepetitions,
  cronExpression,
  rruleExpression,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const {title, params} = getScheduleTranslationKeyAndParams(rruleExpression ? rruleExpression : cronExpression, weeklyRepetitions)
  return (
    <Info className={className}>
      {t(title, params)}
    </Info>
  )
}

export default ScheduleRepeatInfo
