import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {usePrevious} from 'utils'
import {executeHooks} from 'framework'
import {Locations, DiagnosticEvent, SkippedEventImageHookContext} from '../types'
import MultipleEventsModal from './MultipleEventsModal'
import {OngoingPatrolEventType} from './types'
import OngoingPatrolEventsImageDisplay from './OngoingPatrolEventsImageDisplay'
import {NAV_HOOK_LABELS} from '../constants'

interface Props {
  newCheckedPointEventId?: string
  type: OngoingPatrolEventType
  events: DiagnosticEvent[]
  locations: Locations
  siteId?: string
  robotId?: string
  userId?: string
  showControl: boolean
  modules: string[]
  refetchOngoingEvents: () => void
}

const Wrapper = styled.div``

const ImageWrapper = styled.div`
  padding: 0 8px;
  
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
  max-width: 1400px;
  margin: 0 auto -16px 0;
  
  @media (min-width: 375px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media (min-width: 550px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 700px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 850px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1050px) {
    grid-template-columns: repeat(7, 1fr);
  }

  @media (min-width: 1250px) {
    grid-template-columns: repeat(8, 1fr);
  }
`

const OngoingPatrolEvents = ({
  events,
  robotId,
  siteId,
  userId,
  locations,
  newCheckedPointEventId,
  showControl,
  modules,
  refetchOngoingEvents,
}: Props) => {
  useEffect(() => {
    refetchOngoingEvents()
  }, [])

  const previousCheckedPointEventId = usePrevious(newCheckedPointEventId)
  const [selectedEvent, setSelectedEvent] = useState('')

  useEffect(() => {
    if (selectedEvent && (
      !showControl ||
      (newCheckedPointEventId && newCheckedPointEventId !== previousCheckedPointEventId) ||
      events.every(event => event.notificationUid !== selectedEvent)
    )) {
      setSelectedEvent('')
    }
  }, [events, selectedEvent, newCheckedPointEventId, previousCheckedPointEventId, showControl])

  if (!showControl || events.length === 0 || !robotId || !siteId) {
    return null
  }

  const skippedImageHookContext: SkippedEventImageHookContext = {
    url: '/nsp/skipped.svg',
    modules,
  }
  executeHooks(NAV_HOOK_LABELS.EVENTS.GET_SKIPPED_EVENT_IMAGE, skippedImageHookContext)

  return (
    <Wrapper>
      <ImageWrapper>
        {events.map(event => (
          <OngoingPatrolEventsImageDisplay
            key={event.notificationUid}
            event={event}
            setSelectedEvent={setSelectedEvent}
            locations={locations}
            robotId={robotId}
            siteId={siteId}
            skippedImageUrl={skippedImageHookContext.url}
          />
        ))}
      </ImageWrapper>
      {selectedEvent && (
        <MultipleEventsModal
          close={() => setSelectedEvent('')}
          events={events}
          locations={locations}
          selectedEventId={selectedEvent}
          robotId={robotId}
          userId={userId}
          withCloseButton
        />
      )}
    </Wrapper>
  )
}

export default OngoingPatrolEvents
