import styled from 'styled-components'
import {StatusDescription, StatusMainContent, StatusTextsWrapper, StatusTitle} from '../NavigationElements'
import {cautionBorder, errorBorder, errorTitle, primary, primaryLight, lightGray, gray} from '../../colors'
import {Severity} from './types'
import DebugContent from '../DebugContent'

export const DebugInfo = styled(DebugContent)`
  margin: 12px 0;
  padding: 6px 12px;
  background: ${lightGray};
  border: 1px solid ${gray};
  border-radius: 2px;
`

export const EventWrapper = styled(StatusMainContent)`
    ${(props: {severity?: Severity}) => {
    if (props.severity === 'error') {
      return `border: 10px solid ${errorBorder};`
    }

    if (props.severity === 'warning') {
      return `border: 10px solid ${cautionBorder};`
    }

    return ''
  }}
`

export const EventsTextWithActions = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    ${(props: {fullImage?: boolean}) => props.fullImage ? 'flex: 0;' : ''};
`

export const EventTexts = styled(StatusTextsWrapper)`
    justify-content: center;
`

export const EventDescription = styled(StatusDescription)`
  white-space: pre-line;  
`

interface EventTitleProps {
    severity?: Severity
    noTitleColor?: boolean
}

export const EventTitle = styled(StatusTitle)`
    ${(props: EventTitleProps) => (!props.noTitleColor && props.severity !== 'normal' ?
    `color: ${errorTitle};` :
    ''
  )}
`

interface EventImageProps {
    isLoading?: boolean
    image?: string
    fullImage?: boolean
    maxHeight?: number
}

export const EventImage = styled.div`
    height: calc(75vw - 20px);
    flex-shrink: 1;
    max-height: 40vh;
    background-position: ${(props: EventImageProps) => props.fullImage ? 'top center' : 'center center'};
    background-repeat: no-repeat;
    background-size: contain;
    ${(props: EventImageProps) => props.image ?
    `background-image: url("${props.image}");` :
    ''
}
    ${(props: EventImageProps) => props.fullImage ?
    `
        flex: 1;
        width: 100%;
        height: auto;
        padding-bottom: ${props.maxHeight ? `${props.maxHeight}px` : '75%'};
        min-height: none;
        max-height: none;
    ` :
    ''
}

    ${(props: EventImageProps) => props.fullImage && !props.image ?
    `
        position: relative;
        
        > * {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
             
            &.fa-rotate-270 {
              transform: translate(-50%, -50%) rotate(270deg);
            }
        }
    ` :
    ''
}
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (min-height: 600px) {
        max-height: ${(props: EventImageProps) => props.fullImage ? 'none' : '50vh'};
    }

    @media (min-height: 800px) {
        max-height: ${(props: EventImageProps) => props.fullImage ? 'none' : '60vh'};
    }

    ${(props: EventImageProps) => props.isLoading ? `
      position: relative;
      &::before {
        max-height: 100%;
        max-width: 100%;
        position: absolute;
        content: "";
        box-sizing: border-box;
        transform: rotateZ(360deg);
        border-radius: 50%;
          
        border: 5px solid ${primaryLight};
        border-left-color: ${primary};
    
        height: 70px;
        width: 70px;
        top: 50%;
        left: 50%;
        margin-left: -40px;

        animation: defaultAnimation 750ms infinite linear;
      }
      
      @keyframes defaultAnimation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      ` : ''}
`
