import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextButton} from 'components'
import {Store} from 'types'
import {useDispatch, useSelector} from 'react-redux'
import {toggleGetStatisticsModal} from '../actions'
import {blueButton, white} from '../../../../colors'
import {DatePicker} from '@material-ui/pickers'
import moment from 'moment'
import {downloadFile} from 'app'
import {useNormalTranslation} from 'utils'
import {selectors} from 'shared'

const Content = styled.div`
  margin: 16px 0;
`

const Info = styled.div`
  text-align: center;
  margin: -8px 0 16px 0;
`

const Dates = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  > span {
    margin: 0 12px;
  }
`

const GetStatisticsModal = () => {
  const today = new Date()
  const [isDownloading, setIsDownloading] = useState(false)
  const [fromDate, handleFromDate] = useState(today)
  const [toDate, handleToDate] = useState(today)
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const {i18n} = useTranslation()
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const language = i18n.language
  const dispatch = useDispatch()
  const isStatsModalOpen = useSelector<Store, boolean>(state => state.nsp.siteSettings.isStatsModalOpen)
  const robotId = useSelector<Store, string | undefined>(state => state.app.selectedRobot?.id)
  const siteId = useSelector<Store, string | undefined>(state => state.app.selectedRobot?.siteId)
  const isOpen = Boolean(isStatsModalOpen && robotId && siteId)

  const onClose = useCallback(() => {
    if (!isDownloading && !isDatePickerOpen) {
      dispatch(toggleGetStatisticsModal())
    }
  }, [isDownloading, dispatch, isDatePickerOpen])

  const format = moment.localeData().longDateFormat('L')

  const download = useCallback(() => {
    setIsDownloading(true)
    const startDate = new Date(fromDate)
    startDate.setHours(0, 0, 0, 0)
    const endDate = new Date(toDate)
    endDate.setHours(23, 59, 59, 999)

    const utcOffset = moment().utcOffset()

    try {
      const query = `language=${language}&utcOffset=${utcOffset}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
      const url = `/v1/statistics/${siteId}/${robotId}/operation?${query}`
      dispatch(downloadFile(
        `statistics_${moment(startDate).format('YYYYMMDD')}-${moment(endDate).format('YYYYMMDD')}.zip`,
        url,
        undefined,
        () => {
          setIsDownloading(false)
          onClose()
        },
        () => {
          setIsDownloading(false)
        }
      ))
    } catch (error) {
      console.error('error')
    }
  }, [robotId, siteId, fromDate, toDate, language, dispatch, onClose])

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <DialogTitle onClose={onClose}>
          {t('Dashboard export operation history')}
        </DialogTitle>
        <Content>
          <Info>{t('Dashboard export operation history info')}</Info>
          <Dates>
            <DatePicker
              animateYearScrolling
              inputVariant="outlined"
              format={format}
              maxDate={today}
              onOpen={() => setIsDatePickerOpen(true)}
              onClose={() => setIsDatePickerOpen(false)}
              onChange={(date) => {
                const nextDate = date?.toDate() || new Date()
                handleFromDate(nextDate)
                if (nextDate.getTime() > toDate.getTime()) {
                  handleToDate(nextDate)
                }
              }}
              value={fromDate}
            />
            <span>{t('Dashboard export operation history to')}</span>
            <DatePicker
              animateYearScrolling
              inputVariant="outlined"
              format={format}
              onOpen={() => setIsDatePickerOpen(true)}
              onClose={() => setIsDatePickerOpen(false)}
              onChange={(date) => {
                const nextDate = date?.toDate() || new Date()
                handleToDate(nextDate)
                if (nextDate.getTime() < fromDate.getTime()) {
                  handleFromDate(nextDate)
                }
              }}
              value={toDate}
              maxDate={today}
            />
          </Dates>
        </Content>
      </DialogContent>
      <DialogActions>
        <TextButton
          disabled={isDownloading}
          onClick={onClose}
        >
          {t('Common button cancel')}
        </TextButton>
        <Button
          onClick={download}
          disabled={isDownloading}
          $backgroundColor={blueButton}
          $textColor={white}
        >
          {t('Common download')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GetStatisticsModal
