import {connect} from 'react-redux'
import {LimitFindingStatus, RobotState, Store} from 'types'
import {selectors} from 'shared'
import {getLastLocation, getNextLocation, getStateMachine} from '../selectors'
import {getLocationParts, isGoingHomeFromRawState, isIdleAtHomeFromRawState} from '../utils'
import PatrolLocationFromTo, {Props} from './PatrolLocationFromTo'

const mapStateToProps = (state: Store): Props => {
  const {locations} = state.nsp.siteSettings
  const limitFindingStatus = state.app.selectedRobot?.healthStatus.limitFindingStatus
  const robotState = state.app.selectedRobot?.healthStatus.robotState
  const shouldHide = limitFindingStatus === undefined
  const lfRun = (limitFindingStatus === LimitFindingStatus.OK && robotState !== RobotState.MANUAL_CONTROL)

  const lastLocation = getLastLocation(state)
  const nextLocation = getNextLocation(state)
  const stateMachine = getStateMachine(state)
  const showHome = (
    !lfRun ||
    Boolean(isIdleAtHomeFromRawState(stateMachine?.rawState))
  )

  return {
    isLocationUnknown: (
      !state.app.isConnectedToServer ||
      !selectors.isCurrentRobotOnline(state) ||
      robotState === RobotState.CRITICAL_ERROR ||
      robotState === RobotState.INITIALIZING
    ),
    lastLocation,
    lastLocationsAsString: getLocationParts(locations, lastLocation).join(';'),
    nextLocation,
    nextLocationsAsString: getLocationParts(locations, nextLocation).join(';'),
    showHome,
    shouldHide,
    stateMachineGroup: stateMachine?.stateGroup,
    isGoingHome: isGoingHomeFromRawState(stateMachine?.rawState),
  }
}

export default connect(mapStateToProps)(PatrolLocationFromTo)
