import {connect} from 'react-redux'
import {Store} from 'types'
import {navigationTriggered, openRobotEdit, closeSecondaryNav} from './actions'
import SecondaryNavigation from './SecondaryNavigation'

const mapStateToProps = (state: Store) => ({
  isOpen: state.app.isSecondaryNavOpen,
  robotId: state.app.selectedRobot?.id,
  version: state.app.apiData?.version,
})

export default connect(mapStateToProps, {
  closeDrawer: closeSecondaryNav,
  navigationTriggered,
  openRobotEdit,
})(SecondaryNavigation)
