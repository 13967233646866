import React, {useCallback, useEffect, useState} from 'react'
import {CardContent} from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import {CardTitle, CardAction, FeatureToggle} from 'components'
import {i18n} from 'locale'
import * as Styled from './DashboardStyled'
import {PERMISSIONS} from '../constants'
import AudioSettingsModal from './AudioSettingsModal'
import NetworkErrorsModal from './NetworkErrosModal'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const StyledFormControl = styled(FormControl)`&&& {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px;

  > div:first-child {
    margin-right: 14px;
    text-transform: uppercase;
    font-weight: 500;
  }
}`

interface Props {
  downloadSslCert: () => void
  languages: string[]
}

const SiteActionsCard = ({downloadSslCert, languages}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [isAudioSettingsModalOpen, setIsAudioSettingsModalOpen] = useState(false)
  const [showNetworkErrors, setShowNetworkErrors] = useState(false)
  const [languageCode, setLanguageCode] = useState(i18n.language)
  useEffect(() => {
    const languageChanged = (language: string) => {
      setLanguageCode(language)
    }
    i18n.on('languageChanged', languageChanged)
    return () => {
      i18n.off('languageChanged', languageChanged)
    }
  }, [])

  const handleLanguageChange = (event: React.ChangeEvent<{value: unknown}>) => {
    i18n.changeLanguage(event.target.value as string)
  }

  const setAudioSettings = useCallback(() => {
    setIsAudioSettingsModalOpen(true)
  }, [])

  const closeAudioSettingsModal = useCallback(() => {
    setIsAudioSettingsModalOpen(false)
  }, [])

  const viewNetworkErrors = useCallback(() => {
    setShowNetworkErrors(true)
  }, [])

  const closeNetworkErrors = useCallback(() => {
    setShowNetworkErrors(false)
  }, [])

  return (
    <Styled.Card>
      <CardContent>
        <CardTitle>{t('Dashboard Site Actions')}</CardTitle>
        <Styled.ActionsWrapper>
          <FeatureToggle permissionName={PERMISSIONS.SSL_CERT_DOWNLOAD}>
            <CardAction
              action={{
                actionKey: 'downloadSslCert',
                localeKey: 'Dashboard download ssl cert',
                main: true,
                icon: <GetAppIcon />,
              }}
              availableActions={{
                downloadSslCert,
              }}
            />
          </FeatureToggle>
          <FeatureToggle permissionName={PERMISSIONS.ROBOT.SET_SOUND}>
            <CardAction
              action={{
                actionKey: 'setAudioSettings',
                localeKey: 'Dashboard audio settings',
                textButtonLocaleKey: 'Dashboard audio settings view edit',
              }}
              availableActions={{
                setAudioSettings,
              }}
            />
          </FeatureToggle>
          <CardAction
            action={{
              actionKey: 'viewNetworkErrors',
              localeKey: 'Dashboard network errors',
              textButtonLocaleKey: 'Dashboard network errors view',
            }}
            availableActions={{
              viewNetworkErrors,
            }}
          />
          {languages.length > 0 && (
            <StyledFormControl>
              <div>
                {t('Dashboard language select label')}
              </div>
              <Select
                onChange={handleLanguageChange}
                value={languageCode}
              >
                {languages.map(language => (
                  <MenuItem
                    key={language}
                    value={language}
                  >
                    {language}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          )}
        </Styled.ActionsWrapper>
      </CardContent>
      <AudioSettingsModal isModalOpen={isAudioSettingsModalOpen} closeModal={closeAudioSettingsModal} />
      <NetworkErrorsModal isModalOpen={showNetworkErrors} closeModal={closeNetworkErrors} />
    </Styled.Card>
  )
}

export default SiteActionsCard
