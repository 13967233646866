import {combineReducers, AnyAction} from 'redux'
import * as constants from './constants'

const notificationsEnabled = (state = true, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.SET_NOTIFICATIONS_ENABLED:
      return action.enabled
    default:
      return state
  }
}

const notificationSettings = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_NOTIFICATION_SETTINGS:
      return action.settings
    default:
      return state
  }
}

export default combineReducers({
  notificationsEnabled,
  notificationSettings,
})
