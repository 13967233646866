import {CurrentEventsForPreview} from '../types'

export enum OngoingPatrolEventType {
  IMAGES = 'images',
  LIST = 'list',
}

export interface PatrolEventsStore {
  ongoingPatrolEventsType: OngoingPatrolEventType
  currentEventsForPreview: CurrentEventsForPreview | null
}
