import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'
import PlayArrayIcon from '@material-ui/icons/PlayArrow'
import CloseIcon from '@material-ui/icons/Close'
import Fab from '@material-ui/core/Fab'
import {CardActionProps} from './types'
import {TextButton} from './BasicElementsStyled'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

export const ActionWrapper = styled.div`
    padding: 8px 4px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #868686;

    &:last-child {
        border-bottom: none;
    }
`

const Text = styled.div`
    text-transform: uppercase;
    font-weight: 500;
    padding-top: 7px;
`

const ConditionsWrapper = styled.div`
  margin-top: 4px;
  
  .MuiTypography-root {
    text-transform: none;
    font-weight: 400;
    font-size: 11px;
  }
`

const StyledFab = styled(Fab)`
    box-shadow: none;
    flex-shrink: 0;
`

const CardAction = ({action, availableActions}: CardActionProps) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const history = useHistory()
  const onClick = action.actionKey && availableActions[action.actionKey] ?
    () => availableActions[action.actionKey as string](history) :
    action.action
  const shouldCheckConditions = Boolean(onClick && action.conditions && action.conditions.length > 0)
  const [conditionsChecked, setConditionsChecked] = useState(!shouldCheckConditions)
  useEffect(() => {
    setConditionsChecked(!shouldCheckConditions)
  }, [shouldCheckConditions])

  let icon = action.icon
  if (!icon) {
    icon = action.isCancel ? <CloseIcon /> : <PlayArrayIcon />
  }

  return (
    <ActionWrapper title={action.disabledReason && t(action.disabledReason)}>
      <Text>
        {t(action.localeKey)}
        {shouldCheckConditions && (
          <ConditionsWrapper>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={conditionsChecked}
                  color="primary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                    setConditionsChecked(checked)
                  }
                  name="conditions"
                />
              )}
              label={t('Dashboard content engineering lf preconditions', {
                conditions: action.conditions?.map(t).join(', '),
              })}
            />
          </ConditionsWrapper>
        )}
      </Text>
      {action.textButtonLocaleKey ? (
        <TextButton onClick={onClick}>
          {t(action.textButtonLocaleKey)}
        </TextButton>
      ) : (
        <StyledFab
          color={action.main ? 'primary' : 'default'}
          disabled={!action.actionKey || Boolean(action.disabledReason) || !conditionsChecked}
          size="small"
          onClick={onClick}
        >
          {icon}
        </StyledFab>
      )}
    </ActionWrapper>
  )
}

export default CardAction
