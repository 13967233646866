import {AnyAction, combineReducers} from 'redux'
import {User} from 'types'
import * as constants from './constants'

const user = (state: User | null = null, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_USER:
      return action.user
    default:
      return state
  }
}

export default combineReducers({
  user,
})
