import {connect} from 'react-redux'
import {Store} from 'types'
import {settingsMapStateToProps} from 'modules/navigation/utils'
import ScheduleSettings from './ScheduleSettings'
import {getConfigurations} from '../selectors'

const mapStateToProps = (state: Store) => {
  const {canSendRobotCommand, robotLoaded} = settingsMapStateToProps(state)
  return {
    configurations: getConfigurations(state),
    showContentBasedOnState: robotLoaded && canSendRobotCommand,
  }
}

export default connect(mapStateToProps)(ScheduleSettings)
