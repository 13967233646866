import React, {useEffect, useState} from 'react'

interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  placeholderSrc?: string
}

const Image = ({placeholderSrc, ...props}: Props) => {
  const [src, setImageSrc] = useState(props.src)
  useEffect(() => {
    setImageSrc(props.src)
  }, [props.src])

  const onError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (props.onError) {
      props.onError(event)
    }

    if (placeholderSrc) {
      setImageSrc(placeholderSrc)
    }
  }

  return (
    <img
      {...props}
      src={src}
      onError={props.onError || onError}
    />
  )
}

export default Image
