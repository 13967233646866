import {Store} from 'types'
import {createSelector} from 'reselect'

export const getRemindersToShow = createSelector(
  (state: Store) => state.app.selectedRobot?.siteId,
  (state: Store) => state.app.selectedRobot?.id,
  (state: Store) => state.nsp.reminders.remindersToShow,
  (siteId, robotId, remindersToShow) => {
    return remindersToShow.filter(reminder => reminder.robotId === robotId && reminder.siteId === siteId)
  }
)
