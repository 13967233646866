import {ScheduleItem, SettingsConfigurationElement} from '../types'

export interface ScheduleListElement extends ScheduleItem {
  configuration?: SettingsConfigurationElement
}

export enum Steps {
  TIME,
  CONFIGURATION,
}

export interface ScheduleStore {
  editStep: Steps
  editedItem: ScheduleItem | null
  isSavingItem: boolean
}
