import React from 'react'
import {useHistory} from 'react-router-dom'
import {MultipleConfigurationsList} from 'modules/navigation/settings'
import {SettingsConfigurationElement} from 'modules/navigation/types'
import {useModuleAwareTranslation} from 'utils'
import {GoToElementIcon, List, ListHeader, ListItem, ListItemTextRight} from 'components'
import {getSettingsRouteForScenario} from '../utils'
import ConfigTooltip from './ConfigTooltip'

interface Props {
  configurations: SettingsConfigurationElement[]
  showContentBasedOnState: boolean
}

const ScheduleSettings = ({
  configurations,
  showContentBasedOnState,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const {push} = useHistory()

  if (!showContentBasedOnState) {
    return null
  }

  const hasMultipleConfigurations = configurations.length > 1
  const hasConfigurations = configurations.length > 0

  return (
    <>
      {hasMultipleConfigurations && (
        <MultipleConfigurationsList configurations={configurations} />
      )}
      {!hasMultipleConfigurations && hasConfigurations && (
        <List>
          <ListHeader $withTopMargin>
            {t('Nav_Schedule subtitle room settings')}
          </ListHeader>
          <ListItem
            data-testid="link-room-config"
            fullWidth
            clickable
            bolder
            onClick={() => push(getSettingsRouteForScenario(configurations[0].scenario))}
          >
            <ListItemTextRight>
              {t('Nav_Schedule content link room configuration')}
              <ConfigTooltip configUid={configurations[0].id} />
            </ListItemTextRight>
            <GoToElementIcon />
          </ListItem>
        </List>
      )}
    </>
  )
}

export default ScheduleSettings
