import React, {useEffect} from 'react'
import {CardContent} from '@material-ui/core'
import CardActions from '@material-ui/core/CardActions'
import WifiOffIcon from '@material-ui/icons/WifiOff'
import {CardTitle, CardAction, types, OpenBugReportButton} from 'components'
import {executeHooks, HOOK_LABELS} from 'framework'
import LocksModalContainer from './LocksModalContainer'
import * as Styled from './DashboardStyled'
import {RobotActionsCardProps} from './types'
import GripperAutocalModal from './GripperAutocalModal'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const RobotStatusCard = ({
  robot,
  actions,
  availableActions,
  isRobotOnline,
  fetchLocks,
  dispatch,
}: RobotActionsCardProps) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)

  useEffect(() => {
    fetchLocks()
  }, [])

  const allActions = [...actions]

  const extraActions = executeHooks(HOOK_LABELS.DASHBOARD.EXTRA_ACTIONS, {}) as {[module: string]: types.CardAction[]}
  for (const moduleActions of Object.values(extraActions)) {
    allActions.push(...moduleActions.map(actionConfig => ({
      ...actionConfig,
      action: () => {
        if (actionConfig.moduleAction) {
          dispatch(actionConfig.moduleAction())
        }
      },
    })))
  }

  const actionItems = allActions.length > 0 ?
    allActions.map(action => (
      <CardAction
        action={action}
        availableActions={availableActions}
        key={action.localeKey}
      />
    )) : (
      <Styled.NoActions>
        {t('Dashboard No actions to perform')}
      </Styled.NoActions>
    )

  return robot ? (
    <Styled.Card>
      <CardContent>
        <CardTitle>{t('Dashboard Actions')}</CardTitle>
        {isRobotOnline ? (
          <Styled.ActionsWrapper>
            {actionItems}
          </Styled.ActionsWrapper>
        ) : (
          <>
            <Styled.Offline>
              <WifiOffIcon color="disabled" fontSize="large" />
              <Styled.OfflineMessage>
                {t('Dashboard Robot is offline, cannot perform any actions')}
              </Styled.OfflineMessage>
            </Styled.Offline>
            {allActions.some(action => action.isAvailableWhenRobotOffline) ? (
              <Styled.ActionsWrapper>
                {allActions
                  .filter(action => action.isAvailableWhenRobotOffline)
                  .map(action => (
                    <CardAction
                      action={action}
                      availableActions={availableActions}
                      key={action.localeKey}
                    />
                  ))}
              </Styled.ActionsWrapper>
            ) : ''}
          </>
        )}
      </CardContent>
      <CardActions>
        <OpenBugReportButton robotId={robot.id} />
      </CardActions>
      <LocksModalContainer />
      <GripperAutocalModal />
    </Styled.Card>
  ) : null
}

export default RobotStatusCard
