import {LimitFindingStatus} from 'types'

let uniqueCount = 0
const uniqueStartTime = new Date().getTime()

export const uniqueId = (prefix?: string): string => {
  const timeDiff = new Date().getTime() - uniqueStartTime
  const someNumber = Math.floor(Math.random() * 1000)
  const uniqueString = `${prefix || ''}-${uniqueCount}-${timeDiff}-${someNumber}`
  uniqueCount++
  return uniqueString
}

export const isLimitFindingInProgress = (limitFindingStatus?: LimitFindingStatus): boolean => (
  limitFindingStatus === LimitFindingStatus.IN_PROGRESS ||
  limitFindingStatus === LimitFindingStatus.GRIPPER_AUTOCAL_IN_PROGRESS ||
  limitFindingStatus === LimitFindingStatus.LIMIT_FINDING_IN_PROGRESS
)

export const hasLimitFindingFailed = (limitFindingStatus?: LimitFindingStatus): boolean => (
  limitFindingStatus === LimitFindingStatus.FAILED ||
  limitFindingStatus === LimitFindingStatus.LIMIT_FINDING_FAILED ||
  limitFindingStatus === LimitFindingStatus.GRIPPER_AUTOCAL_FAILED
)
