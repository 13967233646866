import React, {useEffect, useState} from 'react'
import PauseIcon from '@material-ui/icons/Pause'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import styled from 'styled-components'
import {Button, WarningTooltip} from 'components'
import {errorIcon, errorIconLight, white} from '../../../colors'
import {RobotState} from 'types'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;

    button {
        width: 44px;
        height: 44px;
        padding: 0;
        min-width: 44px;
    }
`

interface Props {
  action: () => void
  isActionInProgress: boolean
  isPaused: boolean
  isConnected: boolean
  robotId?: string
  robotState?: RobotState
}

const PatrolAction: React.FC<Props> = ({
  action,
  isConnected,
  isPaused,
  isActionInProgress,
  robotId,
  robotState,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const isInitializing = robotState === RobotState.INITIALIZING
  let clickTimeout = -1

  const openTooltip = () => {
    setTooltipOpen(true)
    clearTimeout(clickTimeout)
    clickTimeout = setTimeout(() => setTooltipOpen(false), 1000 * 10)
  }
  const closeTooltip = () => {
    clearTimeout(clickTimeout)
    setTooltipOpen(false)
  }

  useEffect(() => {
    return () => {
      clearTimeout(clickTimeout)
    }
  }, [])

  useEffect(() => {
    if (isConnected && !isInitializing) {
      closeTooltip()
    }
  }, [isConnected, isInitializing])

  const disabled = !isConnected || isActionInProgress || isInitializing
  let disabledBackgroundColor: string | undefined
  if (isConnected && !isPaused) {
    disabledBackgroundColor = errorIcon
  } else if (isConnected) {
    disabledBackgroundColor = errorIconLight
  }
  const textColor = !isPaused || !isConnected ? white : errorIcon

  if (!robotId) {
    return null
  }

  const showTooltip = !isConnected || isInitializing
  const content = (
    <ButtonWrapper onClick={showTooltip ? openTooltip : undefined}>
      <Button
        data-testid="stop-button"
        disabled={disabled}
        $isRound
        $border={!isPaused || !isConnected ? undefined :`3px solid ${errorIcon};`}
        $backgroundColor={!isPaused ? errorIcon : errorIconLight}
        $disabledBackgroundColor={disabledBackgroundColor}
        $textColor={textColor}
        $disabledTextColor={textColor}
        $opacity={disabled && isConnected ? 0.6 : undefined}
        $hoverShade={isPaused ? 15 : 210}
        onClick={action}
      >
        <PauseIcon />
      </Button>
    </ButtonWrapper>
  )

  return showTooltip ? (
    <ClickAwayListener onClickAway={closeTooltip}>
      <WarningTooltip
        title={t(isInitializing ?
          'Nav_Titlebar content pause icon tooltip initializing' :
          'Nav_Titlebar content pause icon tooltip disconnected') || ''}
        open={tooltipOpen}
        onClose={closeTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        PopperProps={{
          disablePortal: true,
        }}
        arrow
        interactive
        leaveDelay={1000 * 10}
        placement="left-start"
      >
        {content}
      </WarningTooltip>
    </ClickAwayListener>
  ) : content
}

export default PatrolAction
