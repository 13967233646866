export const ROUTES = {
  HOME: '/',
  LIMIT_FINDING_WIZARD: '/limitFinding',
  PATROL: '/patrol',
  TOOLS: '/tools',
  LOGIN: '/login',
}

export const TOKEN_REFRESH_INTERVAL = 1000 * 60 * 30 // 30 min

export const FETCH_SITE_SETTINGS = `FETCH_SITE_SETTINGS`

export const PERMISSIONS = {
  ROBOT: {
    GET_DEVICES: 'ROBOT_GET_DEVICES',
    CHANGE_MANUAL_CONTROL: 'ROBOT_CHANGE_MANUAL_CONTROL',
    MOVE_JOINT: 'ROBOT_MOVE_JOINT',
    UPDATE: 'ROBOT_UPDATE',
    RUN_LIMIT_FINDING: 'ROBOT_RUN_LIMIT_FINDING',
    VIEW_DETAILS: 'ROBOT_VIEW_DETAILS',
    SET_SOUND: 'ROBOT_SET_SOUND',
  },
  PATROL: {
    GET_ROBOTS: 'PATROL_GET_ROBOTS',
    VIEW: 'PATROL_VIEW',
    CONTROL: 'PATROL_CONTROL',
    VIEW_PRECONDITIONS: 'PATROL_VIEW_PRECONDITIONS',
    PDF_REPORT: 'PATROL_PDF_REPORT',
    GET_STATS: 'PATROL_GET_STATS',
    UPDATE_NAVIGATE_LOCATIONS_FOR_UV: 'PATROL_UPDATE_NAVIGATE_LOCATIONS_FOR_UV',
  },
  PUSH_NOTIFICATIONS: 'PUSH_NOTIFICATIONS',
  ENGINEERING_GUI: 'ENGINEERING_GUI',
  ENGINEERING_DASHBOARD: 'ENGINEERING_DASHBOARD',
  DEBUG_CONTENT: 'DEBUG_CONTENT',
  SSL_CERT_DOWNLOAD: 'SSL_CERT_DOWNLOAD',
  SEND_REPORT: 'SEND_REPORT',
}

export const MAX_ROBOT_LATIN_CHARACTERS = 24
export const MAX_LOCATION_NAME_LATIN_CHARACTERS = 16
