import React from 'react'
import styled from 'styled-components'
import {SuccessIcon} from './icons'
import {primary} from '../colors'
import {MouseEventHandler} from 'react'

export const Grid = styled.div`
  margin: 0 auto;
  max-width: 900px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${(props: {gap?: number}) => `${props.gap || 10}px`};
  grid-row-gap: ${(props: {gap?: number}) => `${props.gap || 10}px`};
  
  @media (min-width: 375px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media (min-width: 550px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 700px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 850px) {
    grid-template-columns: repeat(6, 1fr);
  }
`

const GridHeaderWrapper = styled.div`
  grid-column: 1 / span 2;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 16px 0 4px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${(props: {onClick?: MouseEventHandler<HTMLDivElement>}) => props.onClick ? 'pointer' : 'default'};
  
  @media (min-width: 375px) {
    grid-column: 1 / span 3;
  }
  
  @media (min-width: 550px) {
    grid-column: 1 / span 4;
  }

  @media (min-width: 700px) {
    grid-column: 1 / span 5;
  }

  @media (min-width: 850px) {
    grid-column: 1 / span 6;
  }
`

const GridSelectedIcon = styled(SuccessIcon)`
  flex-shrink: 0;
  color: ${(props: {disabled?: boolean}) => props.disabled ? '#6f6f6f': primary};
  margin-right: 4px;
  position: relative;
  left: -2px;
`

const GridUnselectedIcon = styled.div`
  border: 2px solid ${primary};
  flex-shrink: 0;
  border-color: ${(props: {disabled?: boolean}) => props.disabled ? '#6f6f6f': primary};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 2px 8px 2px 0;
  box-sizing: border-box;
`

interface GridHeaderProps {
  children: React.ReactNode
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
  selected?: boolean
  selectable?: boolean
}

export const GridHeader = ({
  onClick,
  selected,
  selectable,
  disabled,
  children,
}: GridHeaderProps) => (
  <GridHeaderWrapper onClick={disabled ? undefined : onClick}>
    {selectable && (
      selected ? <GridSelectedIcon disabled={disabled} /> : <GridUnselectedIcon disabled={disabled} />
    )}
    {children}
  </GridHeaderWrapper>
)
