import {ThunkDispatch} from 'redux-thunk'
import {HOOK_LABELS, registerHook} from 'framework'
import AbstractModule from 'modules/AbstractModule'
import getUvModule from 'modules/uv'
import {NAV_HOOK_LABELS} from 'modules/navigation/constants'
import {
  DiagnosticReason,
  GetPatrolImageHookContext,
  GetReportMainLocationParams,
  HistoryListTabsParams,
  idleStates,
} from 'modules/navigation/types'
import {adjustTranslationKeys} from './translations'
import {moduleName} from './constants'
import {getParentIfSameForAllLocations, isSanitizingRoom} from './utils'
import {SanitizeOperationListContainer} from './report'
import {getLocationInterfaceFromRobot, getStateMachineFromRobot} from '../navigation/selectors'
import {getTitleOverrides} from './selectors'

const defaultHandlerLabel = 'wauv'

class WauvModule extends AbstractModule {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  installModule(dispatch: ThunkDispatch<any, any, any>) {
    const uvModule = getUvModule()
    uvModule.run(dispatch)
    uvModule.setChildModule(moduleName, dispatch)
    registerHook(HOOK_LABELS.TRANSLATIONS.GENERIC_KEY_ADJUSTMENTS, defaultHandlerLabel, adjustTranslationKeys)
    registerHook(NAV_HOOK_LABELS.REPORT.GET_MAIN_LOCATION, defaultHandlerLabel, this.getMainReportLocation.bind(this))
    registerHook(NAV_HOOK_LABELS.PATROL.IMAGE, defaultHandlerLabel, this.getOperationImage.bind(this))
    registerHook(NAV_HOOK_LABELS.PATROL.TITLE_OVERRIDES, defaultHandlerLabel, this.getTitleOverridesSelector.bind(this))
    registerHook(NAV_HOOK_LABELS.REPORT.GET_HISTORY_TABS, defaultHandlerLabel, this.extendHistoryTabs.bind(this), 100)
  }

  private extendHistoryTabs(context: HistoryListTabsParams) {
    if (context.configModules.includes(moduleName)) {
      context.tabs.push({
        titleKey: `use-case ${moduleName}`,
        component: SanitizeOperationListContainer,
      })
    }
  }

  private getTitleOverridesSelector(context: {selectors: Function[]}) {
    context.selectors.push(getTitleOverrides)
  }

  private getOperationImage(context: GetPatrolImageHookContext) {
    const module = context.robot?.modules?.current
    if (module === moduleName && idleStates.includes(context.stateGroup)) {
      return {image: '/sanitization/wauv_idle.png'}
    }

    const isSanitizing = isSanitizingRoom(
      module,
      getStateMachineFromRobot(context.robot)?.stateGroup,
      getLocationInterfaceFromRobot(context.robot)?.nextLocationUid,
      context.robot?.diagnosticData?.wauvRoomOperation?.locationsForCurrentPatrol?.join(';')
    )
    if (isSanitizing) {
      return {image: '/sanitization/sanitizing_wide_area.gif'}
    }
  }

  private getMainReportLocation(context: GetReportMainLocationParams) {
    const locations: string[] = context.details?.events
      .filter(event => event.reason === DiagnosticReason.POINT_SKIPPED || event.reason === DiagnosticReason.POINT_DONE)
      .map(event => event.patrolPointConfig?.patrolPoint.uid || '')
      .filter(Boolean) || []
    const mainLocation = getParentIfSameForAllLocations(context.locations, locations)
    if (mainLocation) {
      context.mainLocation = mainLocation
    }
  }
}

let mod: WauvModule | undefined
const getModule = () => {
  if (!mod) {
    mod = new WauvModule(moduleName)
  }

  return mod
}

export default getModule
