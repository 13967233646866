import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import {getTimeFormatForMoment, useModuleAwareTranslation} from 'utils'
import {ScheduleListElement} from './types'
import {SimpleSwitch, ListItem, ListItemTextColumn, ListItemSubText} from 'components'
import {scenarioToModule} from '../../utils'
import ScheduleRepeatInfo from './ScheduleRepeatInfo'
import {getHoursAndMinutesFromScheduleExpression} from './utils'

export interface Props {
  disabled: boolean
  isSelected: boolean
  hasDifferentScenarios: boolean
  userId?: string
  scheduleItem: ScheduleListElement
  onChangeEnabled: () => void
  selectItem: () => void
}

export const CurrentTime = styled.div`
`

export const StyledScheduleRepeatInfo = styled(ScheduleRepeatInfo)`
  display: inline-block;
`

const ScheduleListItem = ({
  disabled,
  isSelected,
  scheduleItem,
  onChangeEnabled,
  selectItem,
  userId,
  hasDifferentScenarios,
}: Props) => {
  const module = scenarioToModule(scheduleItem.configuration?.scenario)
  const {t} = useModuleAwareTranslation(module ? [module] : undefined)
  const expr = scheduleItem.task.rruleExpression ? scheduleItem.task.rruleExpression : scheduleItem.task.cronExpression
  const {hours, minutes} = getHoursAndMinutesFromScheduleExpression(expr)
  const date = new Date()
  date.setHours(hours, minutes, 0, 0)
  const timeFormat = getTimeFormatForMoment(userId)

  return (
    <ListItem
      data-testid="schedule-list-item"
      onClick={selectItem}
      disabled={disabled}
      isSelected={isSelected}
      clickable
      fullWidth
    >
      <ListItemTextColumn>
        <CurrentTime>
          {moment(date).format(timeFormat)}
        </CurrentTime>
        <ListItemSubText>
          {scheduleItem.configuration && hasDifferentScenarios && (
            <div>{t(`use-case ${scenarioToModule(scheduleItem.configuration.scenario)}`).toUpperCase()}</div>
          )}
          <StyledScheduleRepeatInfo
            cronExpression={scheduleItem.task.cronExpression}
            rruleExpression={scheduleItem.task.rruleExpression}
            weeklyRepetitions={scheduleItem.task.weeklyRepetitions}
          />
          {scheduleItem.configuration && (
            <>
              <span> | </span>
              {t('Nav_Schedule item configuration', {
                name: scheduleItem.configuration.name || t('Nav_Schedule subtitle configurations default name', {
                  index: scheduleItem.configuration.index + 1,
                }),
                total: scheduleItem.configuration.total,
                count: scheduleItem.configuration.selected,
              })}
            </>
          )}
        </ListItemSubText>
      </ListItemTextColumn>
      <SimpleSwitch
        data-testid="schedule-list-item-switch"
        disabled={disabled}
        checked={scheduleItem.enabled}
        onClick={(event: React.SyntheticEvent) => {
          event.preventDefault()
          event.stopPropagation()
          onChangeEnabled()
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          event.preventDefault()
          event.stopPropagation()
        }}
      />
    </ListItem>
  )
}

export default ScheduleListItem
