import {AnyAction, combineReducers} from 'redux'
import * as constants from './constants'
import {
  SanitizedObject, SanitizedObjects,
} from '../types'

const objects = (state: SanitizedObjects[] = [], action: AnyAction): SanitizedObjects[] => {
  switch (action.type) {
    case constants.FETCH_OBJECTS_SUCCESS:
      return action.objects
    case constants.SAVE_OBJECT_SETTINGS_SUCCESS:
      return state.map(sanitizedObjectSettings => {
        if (sanitizedObjectSettings.configurationUid === action.configurationUid) {
          return action.objects
        } else {
          return sanitizedObjectSettings
        }
      })
    default:
      return state
  }
}

const editedObjects = (state: SanitizedObject[] = [], action: AnyAction): SanitizedObject[] => {
  switch (action.type) {
    case constants.SET_EDITED_OBJECTS:
      return [
        ...state.filter(object => !action.objects.some((edited: SanitizedObject) => edited.pointId === object.pointId)),
        ...action.objects,
      ]
    case constants.CLEAR_EDITED_OBJECTS:
    case constants.SAVE_OBJECT_SETTINGS_SUCCESS:
      return []
    default:
      return state
  }
}

const isFetchingObjects = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.FETCH_OBJECTS:
      return true
    case constants.FETCH_OBJECTS_SUCCESS:
    case constants.FETCH_OBJECTS_ERROR:
      return false
    default:
      return state
  }
}

export default combineReducers({
  objects,
  editedObjects,
  isFetchingObjects,
})
