import {Locations, StateMachineGroup} from 'modules/navigation/types'
import {moduleName} from './constants'

export const getParentIfSameForAllLocations = (locations: Locations, patrolLocations: string[]) => {
  if (patrolLocations.length === 0) {
    return
  }
  const firstParent = locations[patrolLocations[0]]?.parentId
  const sameForAll = patrolLocations.every(parentLocation => locations[parentLocation]?.parentId === firstParent)
  return sameForAll ? firstParent : undefined
}

export const isSanitizingRoom = (
  currentModule?: string,
  stateGroup?: StateMachineGroup,
  nextLocationUid?: string,
  locationsForCurrentPatrol?: string // joined with ;
) => {
  if (currentModule !== moduleName || !nextLocationUid || !locationsForCurrentPatrol) {
    return false
  }

  if (stateGroup !== StateMachineGroup.PATROLLING) {
    return false
  }

  return locationsForCurrentPatrol.split(';').includes(nextLocationUid)
}
