import React, {useEffect} from 'react'
import {createSelector} from 'reselect'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Permission, Store} from 'types'

interface ContainerProps {
  permissionName: string | string[]
  onFailedPermission?: () => void
}

interface Props extends ContainerProps {
  permissionsFetched: boolean
  disabledPermissions: Permission[]
}

export const FeatureToggle: React.FC<Props> = ({
  children,
  disabledPermissions,
  permissionsFetched,
  onFailedPermission,
}) => {
  const history = useHistory()
  const enabled = disabledPermissions.length === 0
  useEffect(() => {
    if (permissionsFetched && !enabled && disabledPermissions.some(permission => permission.options?.redirectUrl)) {
      const disabledPermission = disabledPermissions.find(permission => permission.options?.redirectUrl)
      history.replace(disabledPermission?.options?.redirectUrl as string)
    } else if (permissionsFetched && !enabled && onFailedPermission) {
      onFailedPermission()
    }
  }, [permissionsFetched, enabled, disabledPermissions, onFailedPermission])

  if (!permissionsFetched || !children) {
    return null
  }

  if (typeof children === 'function') {
    return children(enabled)
  }

  return enabled ? children : null
}

// memoize by permission names
const disabledPermissionsSelectors: {[key: string]: (state: Store, permissionName: string | string[]) => Permission[]} = {}

const selectorGenerator = () => createSelector(
  (state: Store) => state.app.permissions,
  (_: Store, permissionName: string | string []) => permissionName,
  (userPermissions, permissionName) => {
    const requestedPermissions = Array.isArray(permissionName) ? permissionName : [permissionName]
    return userPermissions ?
      [...new Set([
        ...userPermissions
          .filter(permission => requestedPermissions.includes(permission.name) && !permission.enabled),
        ...requestedPermissions
          .filter(permissionName => !userPermissions.some(permission => permission.name === permissionName))
          .map(permissionName => ({name: permissionName, enabled: false})),
      ])] :
      []
  }
)

const getDisabledPermissionsSelector = (permissionName: string | string[]) => {
  const permissions = Array.isArray(permissionName) ? permissionName : [permissionName]
  const memoizeKey = permissions.join('_')
  if (!disabledPermissionsSelectors[memoizeKey]) {
    disabledPermissionsSelectors[memoizeKey] = selectorGenerator()
  }

  return (state: Store) => disabledPermissionsSelectors[memoizeKey](state, permissionName)
}

export const hasPermission = (state: Store, permissionName: string | string[]) => {
  const permissions = Array.isArray(permissionName) ? permissionName : [permissionName]
  const memoizeKey = permissions.join('_')
  if (!disabledPermissionsSelectors[memoizeKey]) {
    disabledPermissionsSelectors[memoizeKey] = selectorGenerator()
  }

  const permissionsFetched = state.app.permissions !== null
  if (!permissionsFetched) {
    return undefined
  }

  const disabledPermissions = disabledPermissionsSelectors[memoizeKey](state, permissionName)
  return disabledPermissions.length === 0
}

const mapStateToProps = (state: Store, ownProps: ContainerProps) => {
  const permissionSelector = getDisabledPermissionsSelector(ownProps.permissionName)
  const permissionsFetched = state.app.permissions !== null
  return {
    disabledPermissions: permissionSelector(state),
    permissionsFetched,
  }
}

export default connect(mapStateToProps)(FeatureToggle) as React.ComponentType<ContainerProps>
