import React from 'react'
import {Title} from 'components'

const PatrolManual = () => {
  return (
    <Title>Future patrol manual</Title>
  )
}

export default PatrolManual
