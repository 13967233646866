import {FC} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {ThunkDispatch} from 'redux-thunk'
import {Store} from 'types'
import {utils} from 'app'
import {changePatrolState, markEventAsViewed, setAutoOpenSelectConfig} from '../actions'
import {
  getConfigurations,
  getEvent,
  isResumingAbortedPatrol,
  getStateMachine,
  hasMultipleEvents,
} from '../selectors'
import {checkIfHasEventWithAlarm, areActionsDisabled} from './selectors'
import PatrolAction, {Props} from './PatrolAction'
import {PATROL_COMMAND} from '../types'

interface ContainerProps {
  isClientEvent: boolean
  loadingEvent: boolean
}

const mapStateToProps = (state: Store) => {
  const robotId = state.app.selectedRobot?.id
  const siteId = state.app.selectedRobot?.siteId

  return {
    configurations: getConfigurations(state),
    autoOpenSelectConfiguration: state.nsp.autoOpenSelectConfiguration,
    currentEvent: getEvent(state),
    disabled: areActionsDisabled(state),
    stateGroup: getStateMachine(state)?.stateGroup,
    resumingAbortedPatrol: isResumingAbortedPatrol(state),
    hasEventWithAlarm: checkIfHasEventWithAlarm(state),
    siteId,
    robotId,
    shouldDisableLimitFinding: utils.shouldDisableLimitFinding(state.app.selectedRobot),
    hasMultipleEvents: hasMultipleEvents(state),
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  dispatch,
  changePatrolState: compose(dispatch, changePatrolState),
  markEventAsViewed: compose(dispatch, markEventAsViewed),
  setAutoOpenSelectConfig: compose(dispatch, setAutoOpenSelectConfig),
})

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: ContainerProps
): Props => ({
  ...stateProps,
  changeState: (command: PATROL_COMMAND, configurationUid?: string) => {
    if (stateProps.robotId) {
      dispatchProps.changePatrolState(stateProps.robotId, command, configurationUid, stateProps.currentEvent?.notificationUid)
    }
  },
  setAutoOpenSelectConfig: dispatchProps.setAutoOpenSelectConfig,
  markEventAsViewed: dispatchProps.markEventAsViewed,
  loadingEvent: ownProps.loadingEvent,
  isClientEvent: ownProps.isClientEvent,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PatrolAction) as FC<ContainerProps>
