import React from 'react'
import {DragDropContext, Droppable, Draggable, DropResult, ResponderProvided} from 'react-beautiful-dnd'
import {grayBackground, primaryLight} from '../colors'

export type Props = {
  onDragEnd(result: DropResult, provided: ResponderProvided): void
  droppableId: string
  items: Array<{id: string, component: React.ReactNode}>
}

const getItemStyle = (isDragging: boolean, draggableStyle: {} | undefined) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // change background colour if dragging
  background: isDragging ? grayBackground : 'transparent',
  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? primaryLight : 'transparent',
  padding: '8px 16px',
  margin: '-4px -16px',
})

const DragAndDropList = ({
  onDragEnd,
  droppableId,
  items,
}: Props) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {items
            .map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    ) as object}
                  >
                    {item.component}
                  </div>
                )}
              </Draggable>
            ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
)

export default DragAndDropList
