import {
  all,
  takeLatest,
  takeEvery,
  put,
  select,
  debounce,
  delay,
} from 'redux-saga/effects'
import {AnyAction} from 'redux'
import moment from 'moment'
import {
  httpPost,
  httpGet,
  getResponseBody,
  getSelectedSiteAndRobotId,
  isRobotInManualMode,
  emptyArray,
  getTimeFormatForMoment,
} from 'utils'
import {selectors as authSelectors} from 'auth'
import {addSnackbarMessage, constants as appConstants, setSelectedRobot} from 'app'
import {i18n} from 'locale'
import {Robot, Store} from 'types'
import {
  changePatrolStateFinished,
  settingsItemToggleFinished,
  settingsGetItemsFinished,
  setSchedule,
  scheduleGetItems as scheduleGetItemsAction,
  scheduleItemUpdateFinished,
  scheduleGetItemsFinished,
  saveEventNoteSuccess,
  saveEventNoteFail,
  setPatrolFinished,
  setSettings,
  startScheduleUpdate,
  finishScheduleUpdate,
} from './actions'
import {getCurrentSchedule, getCurrentSettings, isPatrolOngoingFromRobot} from './selectors'
import * as constants from './constants'
import {backendScheduleItemToScheduleItem, scheduleItemToBackendItem} from './dataMappers'
import {DiagnosticEvent, Schedule, Settings} from './types'
import {sagas as report} from './report'
import {fetchSiteSettings, sagas as siteSettings} from './siteSettings'
import {sagas as patrolEvents} from './patrolEvents'
import {sagas as reminders} from './reminders'
import {sagas as scheduleSagas} from './schedule'
import {FETCH_SITE_SETTINGS} from '../../constants'

function* changePatrolState() {
  yield takeLatest<AnyAction>(constants.CHANGE_STATE, function* (action) {
    const {command, notificationUid, configurationUid} = action
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    const requestParams = {
      command,
      notificationUid,
      configurationUid,
    }
    const response: Response = yield httpPost(`v1/nsp/robot/${siteId}/${robotId}/patrol`, requestParams)
    const {status} = response
    yield put(changePatrolStateFinished(robotId))
    if (status >= 300) {
      yield put(addSnackbarMessage('Nav_Status content Error when changing patrol command'))
    }
  })
}

function* checkFinishedPatrol() {
  yield takeLatest<AnyAction>([constants.PATROL_FINISHED, appConstants.ROBOT_UPDATED], function* (action) {
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    if (action.type === constants.PATROL_FINISHED) {
      const {patrolId} = action
      if (siteId === action.siteId && robotId === action.robotId) {
        yield put(setPatrolFinished(patrolId))
      }
    } else if (action.type === appConstants.ROBOT_UPDATED) {
      const {robot} = action
      const finishedPatrolId = yield select((state: Store) => state.nsp.finishedPatrolId)
      if (finishedPatrolId && robot.siteId === siteId && robot.id === robotId && isPatrolOngoingFromRobot(robot)) {
        yield put(setPatrolFinished(null))
      }
    }
  })
}

function* handleSiteIdChange() {
  yield takeLatest<AnyAction>(appConstants.SET_SITE_ID, function* () {
    yield put(fetchSiteSettings())
  })
}

const DEBOUNCED_SETTINGS_UPDATE_REQUEST = 'NAV_SAGAS_DEBOUNCED_SETTINGS_UPDATE_REQUEST'
let lastRequestSettingsUpdateTimestamp = 0

function* handleSettingsFromListener() {
  yield takeLatest<AnyAction>(constants.SET_SETTINGS_LISTENER, function* (action) {
    if (lastRequestSettingsUpdateTimestamp) {
      return
    }

    yield put(setSettings(
      action.siteId,
      action.robotId,
      action.configurationUid,
      action.settings,
      action.name,
      action.fullSettings
    ))
  })
}

function* debouncedSettingsUpdateMakeRequest() {
  // keep it for clicking enable/disable multiple times
  yield debounce(700, DEBOUNCED_SETTINGS_UPDATE_REQUEST, settingsMakeRequest)
}

function* settingsMakeRequest(action: AnyAction) {
  const {siteId, robotId, configurationUid, rooms, initialRooms, name, requestTimestamp, homeUid} = action

  const request = {
    roomSettings: rooms,
    name,
    homeUid,
  }

  const response: Response = yield httpPost(
    `v1/nsp/robot/${siteId}/${robotId}/settings/${configurationUid}`,
    request)
  const {status} = response

  if (requestTimestamp !== lastRequestSettingsUpdateTimestamp) {
    return
  }

  // always clear it after request
  lastRequestSettingsUpdateTimestamp = 0

  function* showError() {
    const isInManualMode = yield isRobotInManualMode()
    // set previous settings after failed request
    yield put(settingsItemToggleFinished(robotId, configurationUid, {rooms: initialRooms}, undefined, undefined))
    const message = isInManualMode ?
      'Nav_CommonSetting content Error when updating item manual mode' :
      'Nav_CommonSetting content Error when updating item Please try again'
    yield put(addSnackbarMessage(message))
  }

  if (status >= 300) {
    yield showError()
  } else {
    const rooms = yield getResponseBody(response)
    if (!rooms || rooms.length === 0) {
      yield showError()
    } else {
      yield put(settingsItemToggleFinished(robotId, configurationUid, {rooms}, name, homeUid))
    }
  }
}

function* settingsItemToggle() {
  yield takeEvery<AnyAction>(
    [constants.SETTINGS_ITEM_TOGGLE, constants.SET_SETTING_NAME, constants.SET_SETTING_HOME_UID],
    function* (action) {
      const {roomIds, enabled, name, homeUid} = action
      const {siteId, robotId} = yield getSelectedSiteAndRobotId()
      const settings: Settings | undefined = yield select(getCurrentSettings)
      if (!settings) {
        yield put(scheduleItemUpdateFinished(robotId))
        return
      }

      const requestTimestamp = new Date().getTime()
      lastRequestSettingsUpdateTimestamp = requestTimestamp

      const initialRooms = settings.rooms

      const changedRoomIds = roomIds || []
      if (changedRoomIds.length === 0 && !name && !homeUid) {
        return
      }

      const updatedRooms = settings.rooms.map(room => ({
        enabled: changedRoomIds.includes(room.roomId) ? enabled : room.enabled,
        roomId: room.roomId,
        action: room.action,
      }))

      // do not save all settings disabled
      if (updatedRooms.every(room => !room.enabled)) {
        yield put(addSnackbarMessage('Nav_Schedule cannot save empty settings'))
        yield put(scheduleItemUpdateFinished(robotId))
        return
      }

      // immediately set updated data in the store
      yield put(settingsItemToggleFinished(robotId, settings.configurationUid, {rooms: updatedRooms},
        name || settings.name, homeUid || settings.homeUid))

      yield put({
        robotId,
        siteId,
        requestTimestamp,
        initialRooms,
        rooms: updatedRooms,
        name: name || settings.name,
        homeUid: homeUid || settings.homeUid,
        configurationUid: settings.configurationUid,
        type: DEBOUNCED_SETTINGS_UPDATE_REQUEST,
      })
    })
}

function* settingsGetItems() {
  yield takeLatest<AnyAction>([constants.SETTINGS_GET_ITEMS, constants.REFETCH_SETTINGS, FETCH_SITE_SETTINGS], function* () {
    let {siteId, robotId} = yield getSelectedSiteAndRobotId()
    while (!robotId) {
      yield delay(300)
      const {siteId: currentSiteId, robotId: currentRobotId} = yield getSelectedSiteAndRobotId()
      if (currentRobotId) {
        robotId = currentRobotId
        siteId = currentSiteId
      }
    }
    const response: Response = yield httpGet(`v1/nsp/robot/${siteId}/${robotId}/settings`)
    const {status} = response
    if (status >= 300) {
      yield put(addSnackbarMessage('Nav_CommonSetting content Error when getting item Please try again'))
      yield put(settingsGetItemsFinished(robotId))
    } else {
      const settings = yield getResponseBody(response)
      yield put(settingsGetItemsFinished(robotId, settings))
    }
  })
}

function* scheduleItemOrder() {
  yield takeLatest<AnyAction & {newOrder: Array<{id: string, newIndex?: number}>}>(constants.SCHEDULE_ORDER_UPDATE, function* (action) {
    const {newOrder} = action
    const schedule: Schedule | undefined = yield select(getCurrentSchedule)
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    if (!schedule || !siteId || !robotId) {
      return
    }

    const orderRequest = schedule.scheduleItems
      .sort((itemA, itemB) => itemA.id.localeCompare(itemB.id))
      .map(item => newOrder.find(i => i.id === item.id)?.newIndex)
    yield put(startScheduleUpdate())
    yield httpPost(
      `v1/nsp/robot/${siteId}/${robotId}/schedule/reorder`,
      orderRequest)
    yield put(finishScheduleUpdate())
  })
}

function* scheduleItemUpdate() {
  yield takeLatest<AnyAction>(constants.SCHEDULE_ITEM_UPDATE, function* (action) {
    const {
      robotId,
      scheduleItemId,
      enabled,
      startDate,
      cronExpression,
      rruleExpression,
      weeklyRepetitions,
    } = action
    const schedule: Schedule | undefined = yield select(getCurrentSchedule)
    if (!schedule) {
      yield put(scheduleItemUpdateFinished(robotId))
      return
    }

    const updatedItems = schedule.scheduleItems.map(item => {
      if (item.id !== scheduleItemId) {
        return item
      }

      // trigger just enabled/disabled change
      if (!startDate && (!cronExpression || !rruleExpression)) {
        return {
          ...item,
          enabled,
        }
      }

      const newStartDate = new Date()
      newStartDate.setUTCMonth(startDate.getUTCMonth(), startDate.getUTCDate())
      newStartDate.setUTCHours(startDate.getUTCHours(), startDate.getUTCMinutes(), 0, 0)
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const [cronSeconds, cronMinutes, cronHours, ...restOfCron] = cronExpression.split(' ')
      const minutes = newStartDate.getUTCMinutes()
      const hours = cronHours === '*' ? cronHours : newStartDate.getUTCHours()
      const hoursInterval = cronHours.includes('/') ? `/${cronHours.split('/')[1]}` : ''
      const newCronExpression = `0 ${minutes} ${hours}${hoursInterval || ''} ${restOfCron.join(' ')}`
      return {
        ...item,
        enabled,
        task: {
          cronExpression: newCronExpression,
          rruleExpression: rruleExpression,
          weeklyRepetitions,
          startDate: newStartDate,
        },
      }
    })

    yield put(setSchedule(robotId, {scheduleItems: updatedItems}))

    yield put({
      robotId,
      scheduleItemsWithChanges: updatedItems,
      type: constants.SCHEDULE_ITEM_UPDATE_REQUEST,
    })
  })
}

function* scheduleItemUpdateMakeRequest(action: AnyAction) {
  const {scheduleItemsWithChanges} = action
  const {siteId, robotId} = yield getSelectedSiteAndRobotId()
  yield put(startScheduleUpdate())
  const response: Response = yield httpPost(
    `v1/nsp/robot/${siteId}/${robotId}/schedule`,
    scheduleItemsWithChanges.map(scheduleItemToBackendItem))
  const {status} = response
  function* showError() {
    const isInManualMode = yield isRobotInManualMode()
    const message = isInManualMode ?
      'Nav_CommonSetting content Error when updating item manual mode' :
      'Nav_CommonSetting content Error when updating item Please try again'
    yield put(addSnackbarMessage(message))
    yield put(scheduleGetItemsAction(robotId))
    yield put(scheduleItemUpdateFinished(robotId))
  }
  if (status >= 300) {
    yield showError()
  } else {
    const items = yield getResponseBody(response)
    if (!items || items.length === 0) {
      yield showError()
    } else {
      yield put(scheduleItemUpdateFinished(
        robotId,
        {scheduleItems: items.map(backendScheduleItemToScheduleItem)}
      ))
    }
  }
  yield put(finishScheduleUpdate())
}

function* debouncedScheduleItemUpdateMakeRequest() {
  // keep it only for clicking enable/disable multiple times
  yield debounce(1000, constants.SCHEDULE_ITEM_UPDATE_REQUEST, scheduleItemUpdateMakeRequest)
}

function* scheduleGetItems() {
  yield takeLatest<AnyAction>([
    constants.SCHEDULE_GET_ITEMS,
    appConstants.SET_SELECTED_ROBOT,
    appConstants.FETCH_ROBOTS_SUCCESS,
    constants.REFETCH_SETTINGS,
  ], function* () {
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    if (!robotId) {
      return
    }

    const response: Response = yield httpGet(`v1/nsp/robot/${siteId}/${robotId}/schedule`)
    const {status} = response
    if (status >= 300) {
      yield put(addSnackbarMessage('Nav_CommonSetting content Error when getting item Please try again'))
      yield put(scheduleGetItemsFinished(robotId))
    } else {
      const items = yield getResponseBody(response)
      yield put(scheduleGetItemsFinished(robotId, {scheduleItems: items.map(backendScheduleItemToScheduleItem)}))
    }
  })
}

function* markEventAsViewed() {
  yield takeEvery<AnyAction>(constants.MARK_EVENT_AS_VIEWED, function* (action) {
    const {eventId} = action
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    if (!eventId || eventId.indexOf('web-generated') !== -1) {
      return
    }

    yield httpPost(
      `v1/nsp/robot/${siteId}/${robotId}/event`,
      {viewedEventId: eventId})
  })
}

function* saveEventNote() {
  yield takeLatest<AnyAction>(constants.SAVE_EVENT_NOTE, function* (action) {
    const {eventId, note, noteSelectedActions} = action
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    if (!robotId) {
      return
    }

    const response: Response = yield httpPost(
      `v1/nsp/robot/${siteId}/${robotId}/patrol/event/${eventId}/note`,
      {
        note: note || '',
        noteSelectedActions: noteSelectedActions || [],
      })
    const {status} = response
    if (status >= 300) {
      yield put(addSnackbarMessage('Nav_EventNote content event note save error'))
      yield put(saveEventNoteFail(eventId))
    } else {
      yield put(addSnackbarMessage('Nav_EventNote content event note saved'))
      const event = yield getResponseBody(response)
      yield put(saveEventNoteSuccess(robotId, event))
    }
  })
}

const viewedEvents: {[notificationUid: string]: boolean} = {}
function* checkIfRobotHasError() {
  yield takeEvery<AnyAction>(appConstants.ROBOT_UPDATED, function* (action) {
    const {robot} = action
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    if (robot.id === robotId && robot.siteId === siteId) {
      // same robot as current one? we don't need to update selected robot
      return
    }

    const notViewedEvents = ((robot as Robot).diagnosticData?.events || emptyArray)
      .flatMap((eventsData: {events: DiagnosticEvent[]}) => eventsData.events)
      .filter((event: DiagnosticEvent) => event.isAssistance && !event.viewed && !viewedEvents[event.notificationUid])
    const hasNewEvent = notViewedEvents.length > 0
    if (hasNewEvent) {
      yield put(setSelectedRobot(robot))
    }

    for (const event of notViewedEvents) {
      viewedEvents[event.notificationUid] = true
    }
  })
}

function* handlePatrolAboutToStart() {
  yield takeEvery<AnyAction>(constants.PATROL_ABOUT_TO_START, function* (action) {
    const {siteId, robotId, scheduleDate} = action
    const allRobots = yield select((state: Store) => state.app.robots)
    let messageKey = 'Nav_Status content patrol about to start info'
    const robotName = allRobots.find((robot: Robot) => robot.id === robotId && robot.siteId === siteId)?.name
    if (allRobots.length > 1 && robotName && i18n.exists(`${messageKey}_robot name`)) {
      messageKey = `${messageKey}_robot name`
    }

    const userId = yield select((state: Store) => authSelectors.getUser(state)?.userId)
    const timeFormat = getTimeFormatForMoment(userId)
    yield put(addSnackbarMessage(messageKey, {
      robotName,
      scheduledDate: moment(scheduleDate).format(timeFormat),
    }))
  })
}

function* handlePatrolSkipped() {
  yield takeEvery<AnyAction>(constants.PATROL_SKIPPED, function* (action) {
    const {siteId, robotId, scheduleDate} = action
    const allRobots = yield select((state: Store) => state.app.robots)
    const robotName = allRobots.find((robot: Robot) => robot.id === robotId && robot.siteId === siteId)?.name
    let messageKey = 'Nav_Status content patrol skipped info'
    if (allRobots.length > 1 && robotName && i18n.exists(`${messageKey}_robot name`)) {
      messageKey = `${messageKey}_robot name`
    }

    const userId = yield select((state: Store) => authSelectors.getUser(state)?.userId)
    const timeFormat = getTimeFormatForMoment(userId)
    yield put(addSnackbarMessage(messageKey, {
      robotName,
      scheduledDate: moment(scheduleDate).format(timeFormat),
    }))
  })
}

function* patrolSagas() {
  yield all([
    handleSettingsFromListener(),
    handlePatrolAboutToStart(),
    handlePatrolSkipped(),
    checkIfRobotHasError(),
    checkFinishedPatrol(),
    changePatrolState(),
    debouncedSettingsUpdateMakeRequest(),
    settingsItemToggle(),
    settingsGetItems(),
    scheduleItemUpdate(),
    debouncedScheduleItemUpdateMakeRequest(),
    scheduleGetItems(),
    markEventAsViewed(),
    report(),
    handleSiteIdChange(),
    siteSettings(),
    saveEventNote(),
    patrolEvents(),
    reminders(),
    scheduleSagas(),
    scheduleItemOrder(),
  ])
}

export default patrolSagas
