import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Loader} from 'components'
import {httpGet, httpPost} from 'utils'
import {blueButton, white} from '../../../colors'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const Content = styled.div`
  margin: 16px 0;
`

const StyledFormControl = styled(FormControl)`
    display: flex!important;
    flex-direction: row!important;
    align-items: center;
    justify-content: flex-end;
    
    > div:first-child {
      margin-right: 14px;
    }
`

const StyledSelect = styled(Select)`
  min-width: 300px;
`

interface Props {
  addSnackbarMessage: (message: string) => void
  robotId?: string
  siteId?: string
  isModalOpen: boolean
  closeModal: () => void
  currentModule?: string
}

interface Preset {
  name: string
  value: string
}

const emptyOption = {value: '', name: 'none'}

const PresetsModal = ({
  isModalOpen,
  closeModal,
  robotId,
  siteId,
  addSnackbarMessage,
  currentModule,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [presets, setPresets] = useState([emptyOption])
  const [isFetchingPresets, setIsFetchingPresets] = useState(false)
  const [selectedPreset, setSelectedPreset] = useState('')

  const handlePresetChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSelectedPreset(event.target.value as string)
    event.stopPropagation()
    event.preventDefault()
  }

  useEffect(() => {
    if (robotId && siteId) {
      setIsFetchingPresets(true)
      setPresets([emptyOption])
      httpGet(`v1/nsp/robot/${siteId}/${robotId}/settings/preset`)
        .then(response => {
          if (response.status < 400) {
            return response.json()
          }

          throw new Error('error when fetching files')
        })
        .then((responsePresets: Preset[]) => {
          setSelectedPreset('')
          setPresets([
            emptyOption,
            ...responsePresets.sort((presetA, presetB) => presetA.name.localeCompare(presetB.name)),
          ])
        })
        .catch(error => console.log(error))
        .finally(() => setIsFetchingPresets(false))
    } else {
      setPresets([emptyOption])
    }
  }, [robotId, siteId, currentModule])

  const applyPreset = useCallback(() => {
    if (selectedPreset) {
      httpPost(`v1/nsp/robot/${siteId}/${robotId}/settings/preset`, {value: selectedPreset})
        .then(response => {
          if (response.status < 400) {
            addSnackbarMessage(t('Nav_Dashboard presets modal apply success'))
          } else {
            addSnackbarMessage(t('Nav_Dashboard presets modal apply failed'))
          }
        })
        .catch(() => {
          addSnackbarMessage(t('Nav_Dashboard presets modal apply failed'))
        })
    }
  }, [selectedPreset, siteId, robotId])

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => {}}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <DialogTitle onClose={closeModal}>
          {t('Nav_Dashboard presets modal title')}
        </DialogTitle>
        <Content>
          {isFetchingPresets ? <Loader centered /> : (
            <StyledFormControl>
              <div>
                {t('Nav_Dashboard presets modal select label')}
              </div>
              <StyledSelect
                id="uv-presets-modal-select"
                value={selectedPreset}
                onChange={handlePresetChange}
              >
                {presets.map(preset => (
                  <MenuItem
                    key={preset.value}
                    value={preset.value}
                  >
                    {preset.name}
                  </MenuItem>
                ))}
              </StyledSelect>
            </StyledFormControl>
          )}
        </Content>
        <DialogActions $noHorizontalPadding>
          <Button onClick={closeModal}>
            {t('Common button close')}
          </Button>
          {!isFetchingPresets && (
            <Button
              type="submit"
              $backgroundColor={blueButton}
              $textColor={white}
              onClick={applyPreset}
              disabled={!selectedPreset}
            >
              {t('Common button apply')}
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default PresetsModal
