import React from 'react'
import {History} from 'history'

export interface PageOptionsProps {
    pageOptions: {
        hideBottomNavBar?: boolean
        title?: string
        noContent?: boolean
        bottomNavBar?: React.FC
    }
}

export interface AppProps {
    currentModule?: string
    children?: React.ReactNode
    initApp: (
        getHistory: () => History
    ) => void
    setDeviceOnlineStatus: (isOnline: boolean) => void
    setWindowDimensions: (width: number, height: number) => void
}

export interface AppToolbarProps extends PageOptionsProps {
    isMenuDisabled: boolean
    openRobotSelector: () => void
    navigationTriggered: () => void
    showRobotSelector: boolean
    robotName?: string
}

export interface SnackbarProps {
    id?: string
    action?: React.ReactNode
    params?: object
    autoHideDuration?: number
    message?: string
    removeSnackbarMessage: () => void
}

export interface RobotModulesContextType {
    currentModule?: string
}

export const RobotModulesContext = React.createContext<RobotModulesContextType>({})
