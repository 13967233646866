import {connect} from 'react-redux'
import {Store} from 'types'
import {selectors as authSelectors} from 'auth'
import {getCurrentModules} from 'app/selectors'
import {refetchOngoingEvents} from './actions'
import OngoingPatrolEvents from './OngoingPatrolEvents'
import {getCurrentCheckedEvent, getOngoingEvents, showOngoingPatrolEvents} from './selectors'

const mapStateToProps = (state: Store) => {
  const robot = state.app.selectedRobot
  return {
    newCheckedPointEventId: getCurrentCheckedEvent(state)?.notificationUid,
    events: getOngoingEvents(state),
    locations: state.nsp.siteSettings.locations,
    type: state.nsp.patrolEvents.ongoingPatrolEventsType,
    robotId: robot?.id,
    siteId: robot?.siteId,
    showControl: showOngoingPatrolEvents(state),
    modules: getCurrentModules(state),
    userId: authSelectors.getUser(state)?.userId,
  }
}

export default connect(mapStateToProps, {refetchOngoingEvents})(OngoingPatrolEvents)
