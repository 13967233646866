/* eslint-disable max-len */
import React from 'react'
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon'

const RobotHeadIcon = (props: SvgIconProps & {$secondaryColor?: string}) => {
  const {$secondaryColor, ...svgProps} = props
  const eyeColor = $secondaryColor || '#F29821'
  return (
    <SvgIcon {...svgProps} viewBox="0 0 390.1 420">
      <polygon points="302.4,153.7 302.1,154 302.1,154.4 301.9,154.4 301.6,154.6 300.4,154.6 299.8,154.4 298.9,153.4 298,152.5 297.2,151.7 296.3,151.1 294.6,149.7 292.6,148.3 289.7,146.2 286.5,144.2 282.8,142.4 279.3,140.7 277.5,139.9 275.5,139 271.8,137.6 269.5,136.7 267.2,135.9 264.8,135 262.6,134.4 260.3,133.6 257.7,133 255.3,132 252.7,131.5 247.6,130.3 242.3,129.2 236.9,128 231.4,127.2 226.2,126.3 221,125.7 215.8,125.2 210.6,124.9 205.1,124.6 199.9,124.3 194.4,124 188.9,124 183.8,124 180.9,124.3 175.6,124.3 173.1,124.6 170.2,124.6 167.5,124.9 162.4,125.2 157.1,125.7 152,126.3 146.7,127.2 141.3,128 136.1,129.2 130.9,130.3 125.7,131.5 122.2,132.3 119.1,133.3 115.9,134.4 112.7,135.3 109.5,136.4 106.8,137.5 104.7,138.1 102.9,139 100.9,139.9 98.9,140.7 95.1,142.4 91.6,144.2 89.6,145.4 87.6,146.8 86.7,147.4 85.6,148.3 84.5,148.8 83.6,149.7 82.1,150.8 80.6,151.7 79.5,152.8 78.1,154.4 77.8,154.6 76.6,154.6 76.3,154.4 76.1,154.4 75.8,154 75.5,153.1 75.8,144.8 76.3,136.4 76.6,128.3 76.9,119.9 77.5,112.2 77.8,104.1 78.4,96.5 78.6,89 78.9,86.4 79.2,85.3 79.5,83.6 80.4,81.9 81.2,80.4 82.1,79.3 82.6,78.4 83.6,77.5 84.5,76.6 86.2,74.6 87.3,73.5 88.5,72.3 89.6,71.5 91,70.3 92.2,69.2 93.3,68.3 94.9,67.1 97.4,65.1 100,63.4 102.9,61.6 105.8,59.9 111.5,56.4 117.9,53.8 124.5,51 131.2,48.9 145,45.1 159.5,42.8 163,42.6 166.7,42.3 170.2,42 173.9,41.7 177.7,41.4 181.5,41.4 185.2,41.1 188.9,41.1 194.2,41.1 196.7,41.4 201.6,41.4 204.3,41.7 207.7,42 211.4,42.3 215.2,42.6 218.7,42.8 233.1,45.1 247.3,48.9 253.9,51 260.5,53.8 266.6,56.4 272.7,59.9 275.5,61.6 278.2,63.4 280.8,65.1 283.3,67.1 285.9,69.2 288.2,71.5 290.9,73.5 293.4,76 294.6,77.5 295.5,78.4 296,79.3 296.9,80.4 297.7,81.9 298.3,83.6 298.9,85.3 299.2,86.4 299.2,89 299.8,96.5 300.4,104.1 300.7,112.2 301.3,119.9 301.6,128.3 301.9,136.4 302.1,144.8 302.7,153.1"/>
      <g>
        <rect x="188.9" y="189.3" fill="#010101" width="0.3" height="0.3"/>
        <path d="M303,226.3l-0.3,0.3v1.8l-0.3,0.9v1.5l-0.3,1.2l-0.3,0.9v0.6l-0.3,0.4v0.9l-0.3,0.3l-0.3,1.2l-0.3,1.5L300,239 l-0.6,1.5l-0.6,1.2l-0.6,1.5c-0.6,1.1-3.6,6.1-3.6,6.1l-0.6,0.9l-0.7,0.6l-0.6,0.9l-0.6,0.9l-0.6,0.6l-0.6,0.9l-1.2,0.9l-0.9,0.9 l-0.9,1l-1.2,1.2l-1.8,1.5l-2.1,1.2l-1.9,1.5l-2.1,1.2l-2.1,0.9l-2.1,1.2l-2.1,0.6l-2.5,0.9l-2.1,0.6l-2.1,0.4l-2.4,0.3l-2.1,0.3 l-2.8,0.3l-2.7,0.3h-2.7l-2.4,0.3l-2.8,0.3h-2.7l-2.4,0.3h-2.4l-2.7,0.3h-4.9l-2.4,0.3h-4.8l-6.1,0.3l-6.4,0.3H201l-9,0.2l-3.1,0.1 h-5.7l-3-0.3H165l-3-0.3h-6.4l-3.3-0.3h-5.4l-5.8-0.3l-5.7-0.3l-5.8-0.6l-6.3-0.3l-3.7-0.3l-3.6-0.3l-1.2-0.3h-0.9l-1.2-0.3 l-1.3-0.4h-0.9l-1.2-0.3l-0.9-0.3l-2.4-0.9l-2.1-0.6l-2.1-1.2l-2.2-0.9l-2.1-1.2l-2.1-1.5l-2.1-1.2l-1.8-1.5l-1.2-1.2l-0.9-1 l-1.3-0.9l-0.9-0.9l-0.9-1.2l-0.9-1.2l-2.1-2.4l-0.6-0.9l-0.6-0.9l-0.6-1.2l-0.6-0.9l-1.2-2.5l-0.6-1.2l-0.6-0.9l-0.3-1.2l-0.7-1.2 l-0.3-1.2l-0.3-0.9l-0.3-1.2l-0.3-1.2l-0.3-1.3l-0.3-1.2l-0.3-1.5l-0.3-1.8v-3v-4.6v-0.6v-0.3l0.3-0.3v-2.1l0.3-1.8l0.3-1.5 l0.3-0.9v-1.2l0.3-0.3v-0.7l0.3-0.3l0.3-1.5l0.7-1.8l0.6-1.5l0.6-1.5l0.6-1.5l0.6-1.2l0.9-1.5l0.9-1.6l0.6-1.2l0.9-1.5l0.6-0.6 l0.6-0.6l0.3-0.6l0.6-0.6l0.9-1.2l1.2-1.2l1.3-1.2l0.9-1.2l1.2-1l1.5-1.2l0.6-0.3l0.6-0.6l0.3-0.3h0.3l0.3-0.3l0.3-0.3l1.5-0.9 l1.2-0.9l1.5-0.9l1.6-0.6l1.5-0.6l1.5-0.9l3.9-1.5l1.2-0.3l1.2-0.6l0.9-0.3l1.3-0.4l2.4-0.9l2.4-0.6l2.4-0.6l2.7-0.9l5.5-1.2 l5.7-1.2l5.5-1.2l6-0.9l5.2-0.6l5.1-0.3l5.5-0.6l5.4-0.3l5.8-0.6h5.4l5.8-0.3h5.7h8.8l2.7,0.3h5.8l3,0.3l2.7,0.3l5.5,0.3l5.4,0.6 l5.2,0.3l5.1,0.6l6.1,0.9l5.7,1.2l5.8,1.2l5.4,1.2l2.4,0.9l2.8,0.6l2.4,0.6l2.4,0.9l2.1,0.7l2.1,0.9l2.2,0.6l2.1,0.9l0.3,0.3h0.3 l0.3,0.3h0.3l0.9,0.3l0.6,0.6l1.5,0.6l1.5,0.9l1.2,0.6l1.3,0.9l1.2,0.9l1.2,1.2l0.6,0.3l0.6,0.6l0.6,0.6l0.6,0.3l0.6,0.7l0.6,0.6 l0.3,0.3l0.3,0.3l0.3,0.3l0.3,0.3l0.9,1.2l1.2,1.2l0.6,0.6l0.3,0.6l0.3,0.3l0.3,0.3l0.4,0.3v0.3l1.2,1.2l0.6,1.5l1.5,2.5 c5.3,7.9,5.7,19.9,5.7,19.9V226.3L303,226.3z"/>
        <g>
          <g>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="126.4" cy="193.2" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="137.6" cy="193.2" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="115.2" cy="204.5" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="126.4" cy="204.5" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="137.6" cy="204.5" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="148.9" cy="204.5" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="103.8" cy="215.7" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="115.2" cy="215.7" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="126.4" cy="215.7" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="137.6" cy="215.7" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="148.9" cy="215.7" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="160.1" cy="215.7" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="104" cy="226.9" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="115.2" cy="226.9" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="126.4" cy="226.9" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="137.6" cy="226.9" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="148.9" cy="226.9" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="160.1" cy="226.9" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="115.2" cy="238.1" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="126.4" cy="238.1" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="137.6" cy="238.1" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="148.9" cy="238.1" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="126.4" cy="249.4" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="137.6" cy="249.4" r="3.2"/>
          </g>
          <g>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="240.6" cy="193.2" r="3.2"/>

            <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 20.7604 410.886)" fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="251.8" cy="193.2" rx="3.2" ry="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="229.3" cy="204.5" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="240.6" cy="204.5" r="3.2"/>

            <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 9.6063 420.3759)" fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="251.8" cy="204.5" rx="3.2" ry="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="263.1" cy="204.5" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="218" cy="215.7" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="229.3" cy="215.7" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="240.6" cy="215.7" r="3.2"/>

            <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 -1.4491 429.7819)" fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="251.8" cy="215.7" rx="3.2" ry="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="263.1" cy="215.7" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="274.3" cy="215.7" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="218.2" cy="226.9" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="229.3" cy="226.9" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="240.6" cy="226.9" r="3.2"/>

            <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 -12.5045 439.1878)" fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="251.8" cy="226.9" rx="3.2" ry="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="263.1" cy="226.9" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="274.3" cy="226.9" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="229.3" cy="238.1" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="240.6" cy="238.1" r="3.2"/>

            <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 -23.5599 448.5938)" fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="251.8" cy="238.1" rx="3.2" ry="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="263.1" cy="238.1" r="3.2"/>
            <circle fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="240.6" cy="249.4" r="3.2"/>

            <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 -34.714 458.0837)" fill={eyeColor} strokeMiterlimit="10" stroke={eyeColor} cx="251.8" cy="249.4" rx="3.2" ry="3.2"/>
          </g>
        </g>
        <path fill="none" stroke="currentColor" strokeWidth="15" strokeMiterlimit="10" d="M358.3,167l-5.1-7.9l-4.7-5.1l-1.4-2.5v-1.2c0-2-2.1-4.2-2.1-4.2l-6.1-4.9l-6.3-5.1l-9.7-8.8l-0.3-2.7 l-0.3-2.7l-0.3-2.8l-0.3-2.4l-0.3-2.7l-0.3-2.7v-1.8l-0.3-1.9l-0.3-2.1l-0.3-1.8l-0.3-1.8l-0.3-2.1l-0.3-1.8l-0.3-2.2l-0.3-1.5 l-0.3-1.5l-0.6-1.5l-0.3-1.5l-0.3-1.5l-0.3-1.5l-1-3.1l-0.3-1.5l-0.3-1.2l-0.3-0.9l-0.3-0.9v-0.6l-0.3-0.9l-0.3-0.6l-0.3-0.6 l-0.3-0.9l-0.3-1.3l-0.6-1.2l-0.3-1.5l-4.5-8.1l-0.6-1.3l-0.9-1.2l-0.6-0.6l-0.6-0.9l-0.4-0.6l-0.6-0.6l-0.3-0.3l-0.6-0.6l-0.6-0.6 l-0.6-0.6l-0.6-0.9l-0.9-0.9l-1.2-0.9l-1.2-1.2l-1.2-1.3l-1.5-1.2l-1.9-1.5l-2.4-1.5l-3.6-2.7l-3.6-2.4l-3.1-1.9l-3.3-2.1l-3-1.5 l-3-1.5l-2.5-0.9l-2.4-1.2l-2.1-0.9l-1.8-0.6l-1.8-0.9l-1.6-0.6l-1.8-0.7l-2.1-0.6l-2.1-0.9l-2.4-0.6l-2.4-0.6l-2.2-0.9L231.5,22 h-0.3l-0.6-0.1l-0.6-0.2l-0.6-0.3c-18.8-4-40.6-3.3-40.6-3.3h-5.4l-5.5,0.3l-5.4,0.3L167,19l-5.4,0.6l-5.5,0.6l-1.8,0.3 c0,0-44.8,8.7-68.1,26.6l-1.8,1.2l-1.8,1.5L81.4,51l-1.5,1.3l-1.2,0.9l-0.9,0.9l-0.7,0.6l-0.9,0.9l-0.3,0.3l-0.6,0.6l-0.6,0.9 l-0.9,0.9l-0.6,0.9l-0.9,0.9l-0.9,1.2l-0.6,1.2l-0.9,1l-4.3,8.7l-0.3,0.4L65,73.4L64.7,74l-0.2,0.5L64.4,75l-0.2,0.4l-0.1,0.5 l-0.3,0.6l-0.2,0.7L63.5,78l-0.3,0.9l-0.3,1.2L62.6,81l-0.3,1.2L62,83.7l-0.6,1.4l-1.2,4.7l-0.3,1.5l-0.3,1.8l-0.3,1.5L59,96.1 l-0.3,1.6l-0.3,1.8l-0.3,1.8l-0.3,2.1l-0.3,2.1l-0.3,1.8l-0.3,1.9l-0.3,2.1l-0.3,3.6l-0.3,3.3l-0.3,3.7l-0.3,3.6l-0.3,1.8v1.8 l-0.4,1.8l-9,5.2l-6.1,5.1l-6.3,4.9c0,0-2.5,2.5-2.5,6l-6,7l-5.2,7.9v111.6l4.9,7.9l6.3,7l2.8,6.3l6,4.9l6.1,4.8l1.7,1.3l1.3,0.8 l0.6,0.3l0.6,0.3l0.9,0.6l0.9,0.7l1.2,0.9l1.2,0.9l1.9,0.9l1.8,1.2c0,0,10.9,32.8,70.2,59.6c43.7,19.8,90.3,13.5,120.2,2.1l1.9-0.6 c59.3-26.8,70.2-59.6,70.2-59.6l1.8-1.2l1.9-0.9l1.2-0.9l1.2-0.9l0.9-0.7l0.9-0.6l0.6-0.3l0.6-0.3l1.3-0.8l1.7-1.3l6.1-4.8l6-4.9 l2.8-6.3l6.3-7l4.9-7.9V168.5L358.3,167z" />
        <line fill="none" stroke="currentColor" strokeWidth="15" strokeMiterlimit="10" x1="325.8" y1="129.9" x2="325.8" y2="315.6"/>
        <line fill="none" stroke="currentColor" strokeWidth="15" strokeMiterlimit="10" x1="52.1" y1="132" x2="52.1" y2="314.2"/>
      </g>
    </SvgIcon>
  )
}

export default RobotHeadIcon
