export function processForBestLanguage<T>(
  languages: string[],
  callback: (language: string) => T | undefined,
  defaultValue?: T | undefined
): T | undefined {
  for (const language of languages) {
    const result = callback(language)
    // no check for undefined as if callback returns false we want to process next language too
    if (result) {
      return result
    }
  }

  return defaultValue
}
