import React, {useCallback, useEffect} from 'react'
import {useModuleAwareTranslation} from 'utils'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from 'components'
import {useDispatch, useSelector} from 'react-redux'
// if we want to support more players (increases bundle size) then change the line to just
// import ReactPlayer from 'react-player'
// more info at https://github.com/CookPete/react-player
import ReactPlayer from 'react-player/youtube'
import {Store} from '../../../types'
import {blueButton, white} from '../../../colors'
import {setVideoUrl} from '../actions'
import styled from 'styled-components'

const PlayerWrapper = styled.div`
  margin-top: 12px;
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const TutorialVideo = () => {
  const {t} = useModuleAwareTranslation()
  const videoData = useSelector((state: Store) => state.nsp.videoData)
  const dispatch = useDispatch()
  const close = useCallback(() => {
    dispatch(setVideoUrl(''))
  }, [dispatch])
  useEffect(() => {
    return () => {
      close()
    }
  }, [close])

  return videoData ? (
    <Dialog
      open
      onClose={close}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <DialogTitle onClose={close}>
          {t(videoData.title)}
        </DialogTitle>
        <PlayerWrapper>
          <ReactPlayer
            className="react-player"
            url={videoData.url}
            width="100%"
            height="100%"
          />
        </PlayerWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          style={{marginRight: '16px'}}
          onClick={close}
          $backgroundColor={blueButton}
          $textColor={white}
        >
          {t('Common button close')}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null
}

export default TutorialVideo
