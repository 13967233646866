import React, {Suspense, useEffect, useState} from 'react'
import {AppContainer, withPageOptionsHOC} from 'app'
import {DashboardContainer} from 'dashboard'
import {PageNotFound} from 'notFound'
import {Tools} from 'tools'
import {Login} from 'login'
import {LimitFindingWizard} from 'limitFindingWizard'
import {Loader} from 'components'
import initModules from 'modules'
import {HOOK_LABELS, executeHooks, types} from 'framework'
import {initLanguages} from 'locale'
import {initializeSiteId} from 'app/actions'
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
import {ROUTES} from '../constants'
import {ThunkDispatch} from 'redux-thunk'
import {connect} from 'react-redux'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<any, any, any>
  currentModule?: string
}

const Routes = (props: Props) => {
  const [modulesInitialized, setModulesInitialized] = useState(false)
  useEffect(() => {
    props.dispatch(initializeSiteId())
    Promise.all([initModules(props.dispatch), initLanguages(props.dispatch)])
      .then(() => {
        setModulesInitialized(true)
      })
      .catch(() => {
        setModulesInitialized(true)
      })
  }, [])

  const hookContext: types.RegisterRouteParams = {
    baseRoute: ROUTES.HOME,
    currentModule: props.currentModule,
  }
  const result = executeHooks(HOOK_LABELS.ROUTING.REGISTER_ROUTES, hookContext)
  const extraRoutes: React.FC[] = Object.keys(result).length === 0 ?
    [] :
    Object.values(result).filter(Boolean).reduce((acc: React.FC[], routes) => [
      ...acc,
      ...(Array.isArray(routes) ? routes : [routes]),
    ], [])

  return !modulesInitialized ? <Loader centered /> : (
    <Suspense fallback={<Loader centered />}>
      <Router>
        <AppContainer>
          <Switch>
            <Route
              component={withPageOptionsHOC({})(DashboardContainer)}
              exact
              path={ROUTES.HOME}
            />
            <Route
              component={withPageOptionsHOC({})(Tools)}
              path={ROUTES.TOOLS}
            />
            <Route
              component={withPageOptionsHOC({
                hideBottomNavBar: true,
                noContent: true,
                title: 'Login title login title',
              })(Login)}
              path={ROUTES.LOGIN}
            />
            <Route
              component={withPageOptionsHOC({
                hideBottomNavBar: true,
              })(LimitFindingWizard)}
              path={ROUTES.LIMIT_FINDING_WIZARD}
            />
            {extraRoutes.map((Item: React.FC, index: number) => <Item key={index} />)}
            <Route
              component={withPageOptionsHOC({hideBottomNavBar: true})(PageNotFound)}
              path="*"
            />
          </Switch>
        </AppContainer>
      </Router>
    </Suspense>
  )
}

export default connect(null, dispatch => ({
  dispatch,
}))(Routes)
