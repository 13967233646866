import {AnyAction, combineReducers} from 'redux'
import * as constants from './constants'
import {reducer as notifications} from './notifications'
import {
  Locations,
  LocationsConfiguration,
} from '../types'

const locations = (state: Locations = {}, action: AnyAction): Locations => {
  switch (action.type) {
    case constants.FETCH_LOCATIONS_FINISHED:
      return action.locations
    case constants.LOCATION_EDIT_END:
      return action.locationId && action.translation && action.language ? {
        ...state,
        [action.locationId]: {
          ...(state[action.locationId] || {}),
          [action.language]: action.translation,
        },
      } : state
    default:
      return state
  }
}

const configuration = (state: LocationsConfiguration = {}, action: AnyAction): LocationsConfiguration => {
  switch (action.type) {
    case constants.FETCH_LOCATIONS_CONFIG_SUCCESS:
      return action.configuration
    default:
      return state
  }
}

const isLoadingLocations = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.FETCH_LOCATIONS:
      return true
    case constants.FETCH_LOCATIONS_FINISHED:
      return false
    default:
      return state
  }
}

const locationForEdit = (state: string | null = null, action: AnyAction): string | null => {
  switch (action.type) {
    case constants.LOCATION_EDIT_START:
      return action.locationId
    case constants.LOCATION_EDIT_END:
      return null
    default:
      return state
  }
}

const isStatsModalOpen = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.TOGGLE_GET_STATISTICS:
      return !state
    default:
      return state
  }
}

export default combineReducers({
  configuration,
  isLoadingLocations,
  locations,
  locationForEdit,
  notifications,
  isStatsModalOpen,
})
