import {FC} from 'react'
import {connect} from 'react-redux'
import {AnyAction} from 'redux'
import {ThunkDispatch} from 'redux-thunk'
import {Store} from 'types'
import {Preconditions} from 'components'
import {getLimitFindingPreconditions} from './selectors'
import {setIsShowingPreconditions} from '../actions'

const mapStateToProps = (state: Store) => {
  const conditions = getLimitFindingPreconditions(state)
  return {
    conditions,
    marginBottom: '66px',
    flex: state.nsp.isShowingPreconditions ? '1' : undefined,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<Store, undefined, AnyAction>) => ({
  onEnter: () => {
    dispatch(setIsShowingPreconditions(true))
  },
  onLastRemoved: () => {
    dispatch(setIsShowingPreconditions(false))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Preconditions) as FC
