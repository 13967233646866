import React from 'react'
import styled from 'styled-components'
import {i18n} from 'locale'
import {executeHooks} from 'framework'
import {NAV_HOOK_LABELS} from 'modules/navigation/constants'
import {getSeverity, GoToElementIcon, Image, List, ListItem, StatusIcon} from 'components'
import {
  DiagnosticEvent,
  DiagnosticReason,
  GetReportMainLocationParams,
  Locations,
  PatrolDetails,
  PatrolPointAction,
  PatrolStartFinishReasons,
} from '../../../navigation/types'
import {getEventsForLocation, groupRooms} from '../../../navigation/report/details/utils'
import {SanitizationLevel, SanitizedObject} from '../../types'
import {serverUrl} from '../../../../config'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const LocationName = styled.div`
  font-weight: 500;
  padding: 18px 0;
`

const Empty = styled.div`
  font-weight: 500;
  padding: 18px 0;
  text-align: center;
`

const Name = styled.div`
    font-weight: 600;
`

const ImageWrapper = styled.div`
    width: 67px;
    position: relative;
    
    svg {
      position: absolute;
      bottom: 0;
      right: -4px;
      width: 30px;
      height: 30px;
      z-index: 6;
    }
      
    ${(props: {withLevel: boolean}) => props.withLevel ? `
      &:after {
        z-index: 4;
        content: ' ';
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 0px;
        right: -4px;
        background: white;
        border-radius: 50%;
      }
    ` : ''}
`

const StyledImage = styled(Image)`
    width: 67px;
    height: 50px;
    margin: 8px 0;
`

const MainText = styled.div`
    flex: 1;
    margin: 0 12px;
    line-height: 1.2em;
    white-space: pre-line;
`

interface Props {
  objects: SanitizedObject[]
  details: PatrolDetails
  locations: Locations
  setSelectedEvent: (eventId: string, events?: DiagnosticEvent[]) => void
}

const ReportDetailsObjectsTab = ({
  details,
  locations,
  setSelectedEvent,
  objects,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const {
    events,
    finishedAt,
    finishReason,
    roomSettings,
  } = details
  if (!roomSettings) {
    return (
      <Empty>
        {t('uv patrol report info objects empty list from robot')}
      </Empty>
    )
  }

  const getMainLocationHookContext: GetReportMainLocationParams = {
    details,
    locations,
    mainLocation: '',
  }
  executeHooks(NAV_HOOK_LABELS.REPORT.GET_MAIN_LOCATION, getMainLocationHookContext)

  const isFinished = Boolean(finishedAt)
  const {rooms} = roomSettings
  const groupedRooms = groupRooms(locations, rooms)
  const eventsByRoom = {} as {[roomId: string]: DiagnosticEvent[]}
  const allEvents = [] as DiagnosticEvent[]
  groupedRooms.forEach(group => group.rooms.forEach(room => {
    const eventsForRoom = getEventsForLocation(
      room.id,
      events,
      [PatrolPointAction.FIND_AND_DISINFECT, PatrolPointAction.FIND_AND_DISINFECT_WITH_LAMP])
    allEvents.push(...eventsForRoom)
    eventsByRoom[room.id] = eventsForRoom
  }))

  return (
    <>
      {groupedRooms
        .filter(groupedRoom => !getMainLocationHookContext.mainLocation ||
          getMainLocationHookContext.mainLocation === groupedRoom.locationId
        )
        .map(groupedRoom => (
          <div
            key={groupedRoom.location}
          >
            {getMainLocationHookContext.mainLocation !== groupedRoom.locationId && (
              <LocationName>{groupedRoom.location}</LocationName>
            )}
            <List>
              {groupedRoom.rooms.map(room => {
                const object = objects.find(obj => obj.pointId === room.id)
                const roomEvents = eventsByRoom[room.id]
                let mainEvent = roomEvents.find(event => getSeverity(event.reason) === 'error')
                if (!mainEvent) {
                  mainEvent = roomEvents.find(event => getSeverity(event.reason) === 'warning' &&
                    event.reason !== DiagnosticReason.POINT_SKIPPED)
                }
                if (!mainEvent) {
                  mainEvent = roomEvents[0]
                }
                const extraEventReason = (
                  finishReason === PatrolStartFinishReasons.SKIPPED_DUE_TO_ONGOING_PATROL &&
                  rooms.some(patrolRoom => patrolRoom.roomId === room.id && patrolRoom.enabled)
                ) ? DiagnosticReason.POINT_SKIPPED : undefined

                const mainEventReason = mainEvent?.reason || extraEventReason

                const locationSeverity = getSeverity(mainEventReason)

                const onClick = mainEventReason && mainEventReason !== DiagnosticReason.POINT_SKIPPED ?
                  () => setSelectedEvent((mainEvent as DiagnosticEvent).notificationUid, allEvents) :
                  undefined

                const disabled = rooms.find(({roomId}) => roomId === room.id)?.enabled === false || (isFinished && !mainEvent)
                const translationKey = mainEventReason ?
                  `uv_patrol report info objects ${mainEventReason}` :
                  (disabled ? 'Nav_ReportRoomView content not selected' : 'uv patrol report info objects not checked yet')
                const level = mainEvent?.patrolPointConfig?.uv_target_fluence || SanitizationLevel.LOW
                const translation = translationKey && i18n.exists(translationKey) ?
                  t(translationKey, {level: mainEvent && t(`uv_SanitizingObjects general ${level} uvc`)}) :
                  ''

                return (
                  <ListItem
                    key={room.id}
                    clickable
                    disabled={!onClick}
                    onClick={onClick}
                  >
                    <ImageWrapper withLevel={Boolean(locationSeverity)}>
                      {object?.photo && (
                        <StyledImage
                          src={serverUrl + object.photo}
                          placeholderSrc="/sanitization/no_photo.svg"
                        />
                      )}
                      {locationSeverity && <StatusIcon severity={locationSeverity} />}
                    </ImageWrapper>
                    <MainText>
                      <Name>{room.translation || room.id}</Name>
                      {translation}
                    </MainText>
                    {onClick && <GoToElementIcon />}
                  </ListItem>
                )
              })}
            </List>
          </div>
        ))}
    </>
  )
}

export default ReportDetailsObjectsTab
