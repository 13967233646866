import React from 'react'
import MaterialSnackbar from '@material-ui/core/Snackbar'
import styled from 'styled-components'
import {SnackbarProps} from './types'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const StyledSnackbar = styled(MaterialSnackbar)`&&&{
  bottom: 64px;
}`

const Snackbar = ({
  id,
  autoHideDuration,
  message,
  removeSnackbarMessage,
  action,
  params,
}: SnackbarProps) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  if (!message) {
    return null
  }

  // add key to component to reset it on each new message, otherwise when 1 message arrives before previous disappears
  //   autoHide feature doesn't work
  return (
    <StyledSnackbar
      key={`snackbar-${id}`}
      disableWindowBlurListener
      autoHideDuration={autoHideDuration || 3000}
      onClose={removeSnackbarMessage}
      open
      message={t(message, params)}
      action={action}
    />
  )
}

export default Snackbar
