import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useModuleAwareTranslation} from 'utils'
import {Button} from 'components'
import {blueButton, white} from '../../../colors'
import {PatrolPointCounts} from '../types'
import * as Styled from '../NSPCommonStyled'
import {PatrolTitle, PatrolActionButtonWrapper} from '../status'
import {ActionsWrapper} from '../shared'
import {executeHooks} from 'framework'
import {NAV_HOOK_LABELS} from '../constants'

interface Props {
  currentModule: string
  hasCurrentEvent: boolean
  finishedPatrolId: string | null
}

const pointCounts: PatrolPointCounts = {
  checkedPoints: 0,
  skippedPoints: 0,
  totalPoints: 0,
}

const PatrolFinished = ({hasCurrentEvent, finishedPatrolId, currentModule}: Props) => {
  const {t} = useModuleAwareTranslation()
  const history = useHistory()
  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      if (finishedPatrolId === null || hasCurrentEvent) {
        // delay goBack a bit as maybe someone else triggered back action. Then timeout will be cancelled later
        history.goBack()
      }
    }, 300)

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId)
      }
    }
  }, [hasCurrentEvent, finishedPatrolId])

  let image = '/nsp/patrol_finished.png'
  const hookResult = executeHooks(NAV_HOOK_LABELS.PATROL.FINISHED_IMAGE, {currentModule})
  if (hookResult.image) {
    image = hookResult.image
  }

  return finishedPatrolId ? (
    <Styled.PatrolStatus>
      <Styled.StatusMainContent>
        <Styled.StatusImage
          data-testid="patrol-image"
          data-testimage={image}
          image={image}
        />
        <PatrolTitle
          pointCounts={pointCounts}
          isFinished
        />
      </Styled.StatusMainContent>
      <ActionsWrapper>
        <PatrolActionButtonWrapper>
          <Button
            $backgroundColor={blueButton}
            $textColor={white}
            onClick={() => history.replace(`/patrol/report/${finishedPatrolId}`)}
          >
            {t('Nav_Status button button Patrol Report')}
          </Button>
        </PatrolActionButtonWrapper>
      </ActionsWrapper>
    </Styled.PatrolStatus>
  ) : null
}

export default PatrolFinished
