import {createSelector} from 'reselect'
import {Robot, RobotState, Store} from 'types'
import {getSelectedRobot} from 'app'
import {isLimitFindingInProgress} from 'utils'
import {selectors} from 'shared'

export const getActions = createSelector(
  getSelectedRobot,
  selectors.isCurrentRobotOnline,
  (state: Store) => state.app.isConnectedToServer,
  (state: Store) => state.dashboard.locks.length > 0,
  (robot: Robot | null, isCurrentRobotOnline, isConnectedToServer, hasLocks) => {
    const isOnline = isConnectedToServer && isCurrentRobotOnline
    if (!isOnline) {
      return []
    }

    const actions = []
    if (isLimitFindingInProgress(robot?.healthStatus.limitFindingStatus)) {
      actions.push({
        actionKey: 'cancelLimitFinding',
        isCancel: true,
        localeKey: 'Dashboard Stop limit finding',
        main: true,
      })
    } else {
      const conditions = []
      if (robot?.config?.lfBatteryThreshold && (robot.healthStatus.batteryLevel || 0) < robot?.config?.lfBatteryThreshold) {
        conditions.push('PowerOn_CalibrationWizard content power on calibration battery too low')
      }

      const isInManualControl = robot?.healthStatus.robotState === RobotState.MANUAL_CONTROL

      actions.push({
        actionKey: 'startLimitFinding',
        disabledReason: isInManualControl ?
          undefined :
          'Dashboard Robot is not in manual control mode',
        localeKey: 'Dashboard Start Limit Finding',
        main: true,
        conditions: isInManualControl ? conditions : undefined,
      })

      actions.push({
        actionKey: 'startGripperAutocal',
        disabledReason: isInManualControl ?
          undefined :
          'Dashboard Robot is not in manual control mode',
        localeKey: 'PowerOn_GripperAutocal button',
        main: true,
      })
    }

    if (hasLocks) {
      actions.push({
        actionKey: 'openLocksModal',
        localeKey: 'Dashboard dashboard show locks',
        main: false,
        textButtonLocaleKey: 'Dashboard dashboard show',
      })
    }

    return actions
  }
)
