import {connect} from 'react-redux'
import {LimitFindingStatus, RobotState, Store} from 'types'
import {selectors} from 'shared'
import {Props} from './SettingsList'
import SettingsList from './SettingsList'
import {settingsItemToggle, settingsGetItems} from '../actions'
import {getSettingsConfig} from './selectors'
import {getCurrentSettings} from '../selectors'
import {isPatrolOngoing} from '../selectors'

const mapStateToProps = (state: Store): Omit<Props, 'settingsItemToggle' | 'getItems'> => {
  const isLimitFindingOk = state.app.selectedRobot?.healthStatus.limitFindingStatus === LimitFindingStatus.OK
  return {
    canSendRobotCommand: (
      state.app.isConnectedToServer &&
      selectors.isCurrentRobotOnline(state) &&
      !isPatrolOngoing(state) &&
      state.app.selectedRobot?.healthStatus.robotState !== RobotState.INITIALIZING &&
      state.app.selectedRobot?.healthStatus.robotState !== RobotState.CRITICAL_ERROR &&
      isLimitFindingOk
    ),
    isLoading: state.nsp.isLoadingSettings,
    robotId: state.app.selectedRobot?.id,
    settings: getCurrentSettings(state),
    settingsConfig: getSettingsConfig(state),
  }
}

const mergeProps = (
  stateProps: Omit<Props, 'settingsItemToggle' | 'getItems'>,
  dispatchProps: {
        settingsGetItems: (robotId: string) => void
        settingsItemToggle: (robotId: string, roomIds: string[], isOn: boolean) => void
    }
): Props => ({
  ...stateProps,
  getItems: () => {
    if (stateProps.robotId) {
      dispatchProps.settingsGetItems(stateProps.robotId)
    }
  },
  settingsItemToggle: (roomIds: string[], isOn: boolean) => {
    if (stateProps.robotId) {
      const robotId = stateProps.robotId
      dispatchProps.settingsItemToggle(robotId, roomIds, isOn)
    }
  },
})

export default connect(
  mapStateToProps,
  {settingsItemToggle, settingsGetItems},
  mergeProps
)(SettingsList)
