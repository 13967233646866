import React, {useCallback} from 'react'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined'
import {useHistory, useLocation} from 'react-router-dom'
import {BottomNavigation} from 'components'
import {executeHooks, HOOK_LABELS, types} from 'framework'
import {PageOptionsProps} from './types'
import {ROUTES} from '../constants'
import {useModuleAwareTranslation} from '../utils'

interface Props extends PageOptionsProps {
  selectedRobotId?: string
  isPatrolConfigUp: boolean
  canAccessEngineeringGUI?: boolean
  canAccessSchedule: boolean
  navigationTriggered: () => void
}

const BottomNavigationBar = (props: Props) => {
  const {
    selectedRobotId,
    isPatrolConfigUp,
    canAccessEngineeringGUI,
    canAccessSchedule,
    pageOptions,
    navigationTriggered,
  } = props
  const {t} = useModuleAwareTranslation()
  const history = useHistory()
  const location = useLocation()

  const goToPageCreator = useCallback((route: string) => () => {
    if (location.pathname !== route) {
      history.push(route)
      navigationTriggered()
    }
  }, [location.pathname])

  if (pageOptions.hideBottomNavBar) {
    return null
  }

  const extraLinksHookContext: types.AddMenuLinksHookContext = {links: []}
  executeHooks(HOOK_LABELS.ROUTING.ADD_MENU_LINKS, extraLinksHookContext)
  const selectedItemHookContext: types.GetSelectedMenuItemHookContext = {
    pathname: location.pathname,
    selectedValue: location.pathname === ROUTES.HOME ? 'engineeringGUI' : undefined,
  }
  executeHooks(HOOK_LABELS.ROUTING.SELECTED_NAV_ITEM, selectedItemHookContext)

  const query = new URLSearchParams(location.search)
  const shouldHideNavBar = query.get('hideNavBar') === 'true'
  if (shouldHideNavBar) {
    return null
  }

  return pageOptions.bottomNavBar ? <pageOptions.bottomNavBar /> : (
    <>
      <BottomNavigation
        data-testid="bottom-nav-bar"
        showLabels
        value={selectedItemHookContext.selectedValue}
      >
        {extraLinksHookContext.links
          .sort(({order: orderA}, {order: orderB}) => orderA - orderB)
          .filter(item => !item.hidden(isPatrolConfigUp, selectedRobotId, canAccessSchedule))
          .map((item) => (
            <BottomNavigationAction
              data-testid={`menu-nav-${item.value}`}
              key={item.value}
              icon={<item.icon $secondaryColor={item.value === selectedItemHookContext.selectedValue ? 'white' : undefined} />}
              label={t(item.labelKey)}
              onClick={goToPageCreator(item.link)}
              value={item.value}
            />
          ))}
        {canAccessEngineeringGUI && (
          <BottomNavigationAction
            label="FAE"
            icon={<BuildOutlinedIcon />}
            onClick={goToPageCreator(ROUTES.HOME)}
            value="engineeringGUI"
          />
        )}
      </BottomNavigation>
    </>
  )
}

export default BottomNavigationBar
