import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import CircularProgress, {CircularProgressProps} from '@material-ui/core/CircularProgress'
import {blueButton} from '../colors'

const useStyles = makeStyles({
  bottom: {
    animationDuration: '550ms',
    color: blueButton,
    left: 0,
    position: 'absolute',
  },
  root: {
    position: 'relative',
    lineHeight: '0',
  },
  centeredParent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexParent: {
    flex: 1,
  },
  topMargin: {
    marginTop: '32px',
  },
  top: {
    color: '#eef3fd',
  },
})

interface Props extends CircularProgressProps {
  centered?: boolean
  className?: string
  withTopMargin?: boolean
  flexParent?: boolean
}

export default function Loader(props: Props) {
  const classes = useStyles()
  const {centered, className, withTopMargin, flexParent, ...loaderProps} = props
  const content = (
    <div data-testid="loader" className={`${classes.root} ${className || ''}`}>
      <CircularProgress
        variant="determinate"
        value={100}
        className={classes.top}
        size={50}
        thickness={4}
        {...loaderProps}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.bottom}
        size={50}
        thickness={4}
        {...loaderProps}
      />
    </div>
  )

  const extraClasses = [] as string[]
  if (centered) {
    extraClasses.push(classes.centeredParent)
  }
  if (flexParent) {
    extraClasses.push(classes.flexParent)
  }
  if (withTopMargin) {
    extraClasses.push(classes.topMargin)
  }

  return extraClasses.length > 0 ? (
    <div className={extraClasses.join(' ')}>
      {content}
    </div>
  ) : content
}
