import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {getTimeDifferenceString} from 'utils'
import {Loader} from 'components'
import {limitFindingRunningTimeout} from 'config'
import * as Styled from './StepsStyled'
import StepTexts from './StepTexts'
import LimitFindingErrorModalContainer from './LimitFindingErrorModalContainer'
import {StepProps} from './types'
import {exitLimitFinding} from '../utils'

const RunLimitFindingStep: React.FC<StepProps> = ({
  step,
  goToNextStep,
  runLimitFinding,
  isRunningLimitFinding,
  limitFindingError,
  limitFindingSuccessful,
}) => {
  const history = useHistory()
  const location = useLocation<{from?: string}>()
  const [startTime, setStartTime] = useState(new Date())
  const [, setCounter] = useState(0)
  useEffect(() => {
    runLimitFinding()
  }, [])
  useEffect(() => {
    if (limitFindingSuccessful) {
      goToNextStep()
    }
  }, [limitFindingSuccessful])

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter => counter + 1)
    }, 1000)
    return function clear() {
      clearInterval(interval)
    }
  }, [])

  const timeDiff = getTimeDifferenceString(startTime)
  const showError = !limitFindingSuccessful && (
    Boolean(limitFindingError) ||
    (new Date().getTime() - startTime.getTime()) > limitFindingRunningTimeout
  )

  const rerunLimitFinding = () => {
    runLimitFinding()
    setStartTime(new Date())
  }

  const onDone = () => {
    exitLimitFinding(history, location.state.from)
  }

  return (
    <Styled.Step data-testid="lf-step-content-run">
      <StepTexts step={step} />
      {isRunningLimitFinding && (
        <>
          <Styled.LoaderWrapper>
            <Loader centered size={75} />
          </Styled.LoaderWrapper>
          <Styled.StepTimer>
            {timeDiff}
          </Styled.StepTimer>
        </>
      )}
      <LimitFindingErrorModalContainer
        open={showError}
        runLimitFinding={rerunLimitFinding}
        close={onDone}
      />
    </Styled.Step>
  )
}

export default RunLimitFindingStep
