import {AnyAction, compose} from 'redux'
import {connect} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'
import {Store} from 'types'
import {hasPermission} from 'components'
import {cancelLimitFinding, clear, goToNextStep, openModalForStep} from './actions'
import {ORDERED_STEPS, STEPS} from './constants'
import LimitFindingWizardFooter from './LimitFindingWizardFooter'
import {PERMISSIONS} from '../constants'

const checkJointsStepIndex = ORDERED_STEPS.indexOf(STEPS.CHECK_JOINTS)

const mapStateToProps = (state: Store) => {
  const {currentStep} = state.limitFindingWizard
  const isDone = currentStep === ORDERED_STEPS[ORDERED_STEPS.length - 1]
  const currentStepIndex = ORDERED_STEPS.indexOf(currentStep as STEPS)
  const nextJointIndex = currentStepIndex + 1
  const hasMoveJointPermission = hasPermission(state, PERMISSIONS.ROBOT.MOVE_JOINT)
  const nextStep = nextJointIndex === checkJointsStepIndex && !hasMoveJointPermission ?
    ORDERED_STEPS[checkJointsStepIndex + 1] :
    undefined
  // if should skipStep (is before CHECK_JOINTS and no permission to move joints) => go to nextStep(AFTER check joints)
  return {
    isDone,
    selectedRobot: state.app.selectedRobot,
    currentStep: currentStep as STEPS,
    nextStep,
    hasMoveJointPermission,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => ({
  cancelLimitFinding: compose(dispatch, cancelLimitFinding),
  clear: compose(dispatch, clear),
  goToNextStep: compose(dispatch, goToNextStep),
  openModalForStep: compose(dispatch, openModalForStep),
})

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>
) => ({
  ...stateProps,
  ...dispatchProps,
  goToNextStep: stateProps.nextStep ?
    () => dispatchProps.goToNextStep(stateProps.nextStep) :
    dispatchProps.goToNextStep,
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LimitFindingWizardFooter)
