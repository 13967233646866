import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {ToolbarTitle} from 'components/Toolbar'
import {useHistory} from 'react-router-dom'
import ToolbarContentContainer from './ToolbarContentContainer'
import {AppToolbarProps} from './types'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {Store} from 'types'
import {selectors} from 'shared'

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    zIndex: theme.zIndex.drawer + 1,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  toolbar: {
    paddingLeft: '14px',
    paddingRight: '16px',
  },
}))

const AppToolbar = ({
  pageOptions,
  isMenuDisabled,
  showRobotSelector,
  openRobotSelector,
  navigationTriggered,
}: AppToolbarProps) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const classes = useStyles()
  const history = useHistory()

  const backButton = (
    <IconButton
      data-testid="menu-back-button"
      edge="start"
      className={classes.backButton}
      color="inherit"
      disabled={isMenuDisabled}
      onClick={() => {
        history.goBack()
        navigationTriggered()
      }}
    >
      <ArrowBackIcon />
    </IconButton>
  )

  const robotSelectorButton: JSX.Element | undefined = showRobotSelector ? (
    <ExpandMoreIcon />
  ) : undefined
  const robotSelectorAction = showRobotSelector ? openRobotSelector : undefined

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      id="global-app-bar"
    >
      {/* if modal should not cover toolbar add class .dialog-aware-toolbar to <Toolbar /> */}
      <Toolbar className={`${classes.toolbar}`}>
        {pageOptions.title && (
          <>
            {pageOptions.title && (
              <ToolbarTitle centered>
                {t(pageOptions.title)}
              </ToolbarTitle>
            )}
          </>
        )}
        {!pageOptions.noContent && (
          <ToolbarContentContainer
            backButton={backButton}
            robotSelectorButton={robotSelectorButton}
            robotSelectorAction={robotSelectorAction}
          />
        )}
      </Toolbar>
    </AppBar>
  )
}

export default AppToolbar
