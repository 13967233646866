import React from 'react'
import {STEPS} from '../constants'
import CheckJointsStepSlider from './CheckJointsStepSlider'
import GenericStepContent from './GenericStepContent'
import RunLimitFindingStep from './RunLimitFindingStep'
import DoneStep from './DoneStep'
import {StepContentProps, StepProps} from './types'

const getComponentToDisplay = (stepProps: StepProps): React.ReactElement | undefined => {
  switch (stepProps.step) {
    case STEPS.STEP_1:
    case STEPS.STEP_2:
    case STEPS.STEP_3:
      return <GenericStepContent {...stepProps} />
    case STEPS.CHECK_JOINTS:
      return <CheckJointsStepSlider {...stepProps} />
    case STEPS.FINAL_STEP:
      return <RunLimitFindingStep {...stepProps} />
    case STEPS.DONE:
      return <DoneStep {...stepProps} />
    default:
      return undefined
  }
}

const StepContent: React.FC<StepContentProps> = ({
  currentStep,
  goToNextStep,
  isRunningLimitFinding,
  limitFindingError,
  limitFindingSuccessful,
  runLimitFinding,
  selectedRobot,
}: StepContentProps) => {
  if (selectedRobot === null) {
    return null
  }

  const stepProps = {
    step: currentStep as STEPS,
    goToNextStep,
    limitFindingError,
    isRunningLimitFinding,
    limitFindingSuccessful,
    runLimitFinding: () => runLimitFinding(selectedRobot),
    selectedRobot,
  }

  const content = getComponentToDisplay(stepProps)
  return content || null
}

export default StepContent
