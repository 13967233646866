import {
  all,
  takeLatest,
  put,
  select,
  takeEvery,
  race,
  delay,
  take,
} from 'redux-saga/effects'
import {AnyAction} from 'redux'
import {Robot} from 'types'
import {addSnackbarMessage} from 'app'
import {setSelectedRobot} from 'app/actions'
import {getSelectedSiteAndRobotId, httpPost} from 'utils'
import {reminderSyncTimeoutInSeconds} from 'config'
import * as constants from './constants'
import {saveReminderSettingsDone, closePatrolReminder} from './actions'

function* saveReminderSettings() {
  yield takeLatest<AnyAction>(constants.SAVE_REMINDER_SETTINGS, function* (action) {
    const {reminderName, reminderValue} = action
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    if (!robotId) {
      return
    }

    const endpoint = `${siteId}/robot/${robotId}/reminder`
    const response: Response = yield httpPost(endpoint, {
      [reminderName]: reminderValue,
    })
    const {status} = response
    if (status >= 300) {
      yield put(addSnackbarMessage('Nav_Settings reminders update error'))
    }

    yield put(saveReminderSettingsDone())
  })
}

function* switchRobotForReminder() {
  yield takeLatest<AnyAction>(constants.SHOW_PATROL_REMINDER, function* (action) {
    const {siteId, robotId} = action
    const selectedRobot = yield getSelectedSiteAndRobotId()
    if (!selectedRobot.robotId || (selectedRobot.robotId === robotId && selectedRobot.siteId === siteId)) {
      return
    }

    const allRobots: Robot[] = yield select(state => state.app.robots)
    const nextRobot = allRobots.find(robot => robot.id === robotId && robot.siteId === siteId)
    if (nextRobot) {
      yield put(setSelectedRobot(nextRobot))
    }
  })
}

function* markReminderAsViewed() {
  yield takeLatest<AnyAction>(constants.MARK_REMINDER_AS_VIEWED, function* (action) {
    const {siteId, robotId, reminderName} = action
    const endpoint = `${siteId}/robot/${robotId}/reminder/viewed`
    yield httpPost(endpoint, {
      reminderName,
    })
  })
}

function* handleViewedReminder() {
  yield takeEvery<AnyAction>(constants.PATROL_REMINDER_VIEWED, function* (action) {
    const {siteId, robotId, reminderName} = action
    if (!reminderSyncTimeoutInSeconds) {
      yield put(closePatrolReminder(siteId, robotId, reminderName))
    }

    const {timeout} = yield race({
      newReminder: take<AnyAction>((action: AnyAction) => (
        action.type === constants.SHOW_PATROL_REMINDER &&
        action.siteId === siteId &&
        action.robotId === robotId &&
        action.reminderName === reminderName
      )),
      timeout: delay(reminderSyncTimeoutInSeconds * 1000),
    })

    if (timeout) {
      yield put(closePatrolReminder(siteId, robotId, reminderName))
    }
  })
}


function* remindersSagas() {
  yield all([
    saveReminderSettings(),
    switchRobotForReminder(),
    markReminderAsViewed(),
    handleViewedReminder(),
  ])
}

export default remindersSagas
