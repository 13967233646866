import React, {useEffect, useState} from 'react'
import {AudioContext} from './types'

interface Props {
  children?: React.ReactNode
}

const AudioContextProvider = ({
  children,
}: Props) => {
  const [audioOnce, setAudioOnce] = useState<HTMLAudioElement | undefined>()
  const [audioContinuous, setAudioContinuous] = useState<HTMLAudioElement | undefined>()
  useEffect(() => {
    function initAudio() {
      const audioContinuous = new Audio('/sound_continuous.mp3')
      audioContinuous.volume = 0.01
      audioContinuous.loop = true
      audioContinuous.play().then(() => audioContinuous.pause())
      audioContinuous.volume = 0.5
      setAudioContinuous(audioContinuous)

      const audioOnce = new Audio('/sound_once.mp3')
      audioOnce.volume = 0.01
      audioOnce.loop = false
      audioOnce.play().then(() => audioOnce.pause())
      audioOnce.volume = 0.5
      setAudioOnce(audioOnce)

      document.body.removeEventListener('touchstart', initAudio)
      document.body.removeEventListener('click', initAudio)
    }

    document.body.addEventListener('touchstart', initAudio)
    document.body.addEventListener('click', initAudio)

    return () => {
      document.body.removeEventListener('touchstart', initAudio)
      document.body.removeEventListener('click', initAudio)
    }
  }, [])

  return (
    <AudioContext.Provider value={{audioContinuous, audioOnce}}>
      {children}
    </AudioContext.Provider>
  )
}

export default AudioContextProvider
