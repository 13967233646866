import React from 'react'
import {PageSubtitle, PageTitle} from 'components'
import {PageMainContent} from '../NSPCommonStyled'
import SiteLocationsListContainer from './SiteLocationsListContainer'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const SiteLocations = () => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  return (
    <>
      <PageTitle>{t('Nav_SiteConfiguration title site configuration locations title')}</PageTitle>
      <PageSubtitle>{t('Nav_SiteConfiguration title site configuration locations subtitle')}</PageSubtitle>
      <PageMainContent>
        <SiteLocationsListContainer />
      </PageMainContent>
    </>
  )
}

export default SiteLocations
