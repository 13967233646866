import React from 'react'

export interface Props {
  children: JSX.Element | string
  value: string | number
}

const PickerItem = ({children, value}: Props) => (
  <div>
    {children}
  </div>
)

export default PickerItem
