import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import Radio from '@material-ui/core/Radio'
import {useLocation} from 'react-router-dom'
import {useModuleAwareTranslation, usePrevious} from 'utils'
import {Button, ConnectionIndicator, Dialog, DialogActions, DialogContent, DialogFormTitle} from '../components'
import {blueButton, grayText, lightGray, white} from '../colors'

const UseCase = styled.div`
  text-transform: uppercase;
  font-size: 0.75rem;
  color: ${grayText};
  margin-top: -3px;
`

const ListItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: flex-start;
  cursor: pointer;
  border-bottom: 1px solid ${lightGray};
`

const ListItemText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  margin: 0 8px;  
`

const RobotName = styled.div`
  margin-right: 6px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.125rem;
`

interface Props {
  canUpdateRobot?: boolean
  selectedRobotId?: string
  isOpen: boolean
  close: () => void
  setSelectedRobotId: (robotId: string) => void
  robots: {id: string, siteId: string, name: string, isConnected: boolean, module?: string}[]
}

const RobotSelector = ({
  selectedRobotId,
  isOpen,
  robots,
  close,
  setSelectedRobotId,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const {pathname} = useLocation()
  const previousPathname = usePrevious(pathname)
  const [selectedRobotEditId, setSelectedRobotEditId] = useState(selectedRobotId)

  useEffect(() => {
    if (selectedRobotId && !selectedRobotEditId) {
      setSelectedRobotEditId(selectedRobotId)
    }
  }, [selectedRobotId, selectedRobotEditId])

  useEffect(() => {
    if (isOpen) {
      setSelectedRobotEditId(selectedRobotId)
    }
  }, [isOpen])

  const confirm = useCallback(() => {
    if (selectedRobotEditId && selectedRobotId !== selectedRobotEditId) {
      setSelectedRobotId(selectedRobotEditId)
    }

    close()
  }, [selectedRobotId, selectedRobotEditId])

  useEffect(() => {
    if (previousPathname && pathname !== previousPathname) {
      close()
    }
  }, [close, previousPathname, pathname])

  if (!isOpen || robots.length < 2) {
    return null
  }

  return (
    <Dialog
      fullWidth
      open
      maxWidth="sm"
      onClose={close}
    >
      <DialogContent>
        <DialogFormTitle>
          {t('SwitchRobot Robots')}
        </DialogFormTitle>
        <List>
          {robots.map(robot => (
            <ListItem
              key={robot.id}
              onClick={() => setSelectedRobotEditId(robot.id)}
            >
              <Radio checked={robot.id === selectedRobotEditId} />
              <ListItemText>
                <RobotName>
                  {robot.name}
                  {robot.module && (
                    <UseCase>
                      {t(`use-case ${robot.module}`)}
                    </UseCase>
                  )}
                </RobotName>
              </ListItemText>
              <ConnectionIndicator
                isConnected={robot.isConnected}
              />
            </ListItem>
          ))}
        </List>
        <DialogActions $noHorizontalPadding>
          <Button onClick={close}>
            {t('Common button cancel')}
          </Button>
          <Button
            type="submit"
            $backgroundColor={blueButton}
            $textColor={white}
            onClick={confirm}
          >
            {t('Common button confirm')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default RobotSelector
