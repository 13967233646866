import {DiagnosticEvent, PatrolPointAction} from '../navigation/types'

export enum SanitizationLevel {
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high',
}

export interface SanitizedObject {
    pointId: string
    enabled: boolean
    action?: PatrolPointAction
    photo?: string
    level?: SanitizationLevel
    type?: string
}

export interface SanitizedObjects {
    objects: SanitizedObject[]
    name?: string
    scenario?: string
    configurationUid: string
    homeUid: string
    algorithm?: string
}

export interface SanitizedObjectDetails {
    pointId: string
    lastOperationDate?: Date
    events: DiagnosticEvent[]
}

export interface UvReducer {
    settings: {
        objects: SanitizedObjects[]
        editedObjects: SanitizedObject[]
        isFetchingObjects: boolean
    }
    report: {
        historyType: string
        isLoadingDetails: boolean
        pointDetails: SanitizedObjectDetails | null
    }
}
