const hooksMap = {} as {
  [hookLabel: string]: Array<{
    handler: Function
    handlerLabel: string
    priority: number
  }>
}

const getHooksForLabel = (hookLabel: string) => {
  return hooksMap[hookLabel] || []
}

/**
 * Register hook for given label
 * @param {string} hookLabel
 * @param {string} handlerLabel
 * @param {Function} handler synchronous function due to nature of react components
 * @param {number} priority
 */
export const registerHook = (hookLabel: string, handlerLabel: string, handler: Function, priority = 20) => {
  const hooksArray = getHooksForLabel(hookLabel)
  hooksArray.push({handler, handlerLabel, priority})
  hooksMap[hookLabel] = hooksArray.sort((hookA, hookB) => hookA.priority - hookB.priority)
}

/**
 * Execute hook for given label
 * @param {string} hookLabel
 * @param {any} context data that will be passed to hook handlers
 * @return {any}
 */
export const executeHooks = (hookLabel: string, context: any) => {
  const hooksArray = getHooksForLabel(hookLabel)
  const hookResults = hooksArray.map(hook => hook.handler(context))

  return hookResults ? hookResults.reduce((result, item) => ({
    ...result,
    ...(item || {}),
  }), {}) : {}
}
