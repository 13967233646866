import React from 'react'
import FeatureToggle from './FeatureToggle'
import {PERMISSIONS} from '../constants'
import styled from 'styled-components'

interface Props {
  className?: string
  children: React.ReactNode
}

const Text = styled.pre`white-space: pre-wrap`

export const DebugContent = ({children, className}: Props) => {
  return (
    <FeatureToggle permissionName={PERMISSIONS.DEBUG_CONTENT}>
      <Text className={className}>
        {children}
      </Text>
    </FeatureToggle>
  )
}

export default DebugContent
