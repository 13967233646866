import React from 'react'
import styled from 'styled-components'
import {Value} from './types'

const StyledMultiPicker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`

interface Props {
  className?: string
  children: React.ReactElement[]
  onValueChange: (index: number, value: Value) => void
  onScrollChange?: (index: number, value: Value) => void
  value: Value[]
}

const MultiPicker = (props: Props) => {
  const columns = React.Children.map(props.children, (child, index) => React.cloneElement(child, {
    value: props.value[index],
    onValueChange: (value: string | number) => props.onValueChange(index, value),
    onScrollChange: props.onScrollChange ? (
      (value: Value) => props.onScrollChange && props.onScrollChange(index, value)
    ) : undefined,
  }))
  return (
    <StyledMultiPicker className={props.className}>
      {columns}
    </StyledMultiPicker>
  )
}

export default MultiPicker
