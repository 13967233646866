import {FC} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {Store} from 'types'
import {getLimitFindingPreconditions} from './selectors'
import {StatusImage} from '../NSPCommonStyled'

const StatusImageStyled = styled(StatusImage)`
  margin-top: 12px;
`

const mapStateToProps = (state: Store) => {
  const conditions = getLimitFindingPreconditions(state)
  return {
    hasPreconditions: conditions.filter(({isFulfilled}) => !isFulfilled).length > 0,
    staticImage: true,
    forceFlex: !state.nsp.isShowingPreconditions,
  }
}

export default connect(mapStateToProps)(StatusImageStyled) as FC<{image: string}>
