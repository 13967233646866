import React from 'react'
import styled from 'styled-components'
import {SimpleSwitch, ListItem} from 'components'
import {SettingsListItemConfig} from './types'
import {warning} from '../../../colors'

const LocationName = styled.div`
    font-weight: 500;
    color: ${(props: {unknownLocation?: boolean}) => props.unknownLocation ? warning : 'inherit'};
`

export interface Props {
  canSendRobotCommand: boolean
  childrenIds: string
  config: SettingsListItemConfig
  enabled: boolean
  isParent: boolean
  settingsItemToggle: (roomIds: string[], enabled: boolean) => void
}

const SettingsListItem = ({
  canSendRobotCommand,
  childrenIds,
  config,
  enabled,
  settingsItemToggle,
  isParent,
}: Props) => {
  const roomIds = childrenIds.length > 0 ? childrenIds.split(';') : [config.id]
  return (
    <ListItem
      fullWidth
      data-testid={`settings-list-item${isParent ? '-parent' : ''}`}
    >
      <LocationName unknownLocation={config.isUnknown}>
        {config.translation}
      </LocationName>
      <SimpleSwitch
        data-testid="settings-list-item-switch"
        disabled={!canSendRobotCommand}
        checked={enabled}
        onChange={() => settingsItemToggle(roomIds, !enabled)}
      />
    </ListItem>
  )
}

export default SettingsListItem
