import {ThunkDispatch} from 'redux-thunk'
import {getResponseBody, httpGet} from 'utils'
import {Storage} from 'platform'

const modulesStorageKey = 'enabledModules'

// eslint-disable-next-line @typescript-eslint/require-await
export default async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    const modulesResponse = await httpGet('config/modules')
    let enabledModules: string[] = []
    if (modulesResponse.status < 300) {
      const responseBody = await getResponseBody(modulesResponse)
      enabledModules = responseBody.enabledModules || []
      await Storage.setItem(modulesStorageKey, JSON.stringify(enabledModules))
    } else {
      const storedModules = await Storage.getItem(modulesStorageKey)
      enabledModules = storedModules ? JSON.parse(storedModules) : []
    }

    enabledModules
      .forEach(moduleName => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          const {default: getModule} = require(`./${moduleName}`)
          if (!getModule) {
            return
          }

          getModule().run(dispatch)
        } catch (error) {
          // ignore error - maybe module is not supported yet on the web
          console.error(error)
        }
      })
  } catch (error) {
    console.error(error)
  }

  // @ts-ignore
  window.modulesInitialized = true
}
