/* eslint-disable max-len */
import React from 'react'
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon'
import {warning, black} from '../../colors'

const WarningIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="12" r="9" fill={black} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM11 13H13V7.00001H11V13Z"
      fill={warning}
    />
  </SvgIcon>
)

export default WarningIcon
