import {connect} from 'react-redux'
import {addSnackbarMessage} from 'app'
import {Store} from 'types'
import {closeSetPositionModal} from './actions'
import RobotPositionModal from './RobotPositionModal'

const mapStateToProps = (state: Store) => ({
  isModalOpen: state.nsp.dashboard.isRobotPositionModalOpen,
  locations: state.nsp.siteSettings.locations,
  robotId: state.app.selectedRobot?.id,
  siteId: state.app.selectedRobot?.siteId,
})

export default connect(mapStateToProps, {
  closeModal: closeSetPositionModal,
  addSnackbarMessage,
})(RobotPositionModal)
