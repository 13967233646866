import {connect} from 'react-redux'
import {Store} from 'types'
import {ThunkDispatch} from 'redux-thunk'
import {AnyAction} from 'redux'
import {getStateMachine} from '../selectors'
import PatrolPreconditions from './PatrolPreconditions'
import {getPreconditions} from './selectors'
import {idleStates} from '../types'
import {setIsShowingPreconditions} from '../actions'

const mapStateToProps = (state: Store) => {
  const stateGroup = getStateMachine(state)?.stateGroup
  const preconditions = getPreconditions(state)
  const shouldDisplay = idleStates.includes(stateGroup)
  return {
    isBatteryRising: Boolean(state.app.selectedRobot?.battery?.isRising),
    isChargingCableConnected: Boolean(state.app.selectedRobot?.healthStatus.chargingCableConnected),
    shouldDisplay,
    preconditions,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<Store, undefined, AnyAction>) => ({
  onEnter: () => {
    dispatch(setIsShowingPreconditions(true))
  },
  onLastRemoved: () => {
    dispatch(setIsShowingPreconditions(false))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PatrolPreconditions)
