const ACTION_PREFIX = 'actions/'

export const MOVE_JOINT = `${ACTION_PREFIX}MOVE_JOINT`

export const SET_MANUAL_CONTROL = `${ACTION_PREFIX}SET_MANUAL_CONTROL`
export const SET_MANUAL_CONTROL_RAW = `${ACTION_PREFIX}SET_MANUAL_CONTROL_RAW`
export const FINISH_MANUAL_CONTROL = `${ACTION_PREFIX}FINISH_MANUAL_CONTROL`

export const SET_SOUND = `${ACTION_PREFIX}SET_SOUND`
export const SET_SOUND_FINISHED = `${ACTION_PREFIX}SET_SOUND_FINISHED`
