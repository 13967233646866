export const HOOK_LABELS = {
  ROUTING: {
    ADD_MENU_LINKS: 'ROUTING_ADD_MENU_LINKS',
    SELECTED_NAV_ITEM: 'ROUTING_SELECTED_NAV_ITEM',
    REGISTER_ROUTES: 'ROUTING_REGISTER_ROUTES',
    REGISTER_DEEP_PATHS: 'ROUTING_REGISTER_DEEP_PATHS',
  },
  EVENTS: {
    REGISTER_SSE_EVENT_LISTENER: 'EVENTS_REGISTER_SSE_EVENT_LISTENER',
    ON_EVENTS_INIT: 'EVENTS_ON_EVENTS_INIT',
  },
  DASHBOARD: {
    EXTRA_ACTIONS: 'DASHBOARD_EXTRA_ACTIONS',
    EXTRA_RIGHT_BLOCKS: 'DASHBOARD_EXTRA_RIGHT_BLOCKS',
  },
  TRANSLATIONS: {
    GENERIC_KEY_ADJUSTMENTS: 'TRANSLATION_GENERIC_KEY_ADJUSTMENTS',
  },
}
