import React from 'react'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import MaterialCancelIcon from '@material-ui/icons/Cancel'
import {errorIcon, grayText} from '../colors'

export interface Props {
    isConnected: boolean
}

const StyledIcon = styled(Icon)`
  transform: rotate(45deg);
  width: 1.15em!important;
  position: relative;
  top: 0.15em;
  left: -0.3em;
  font-size: 1.675rem!important;
`

const CancelIcon = styled(MaterialCancelIcon)`
  color: ${errorIcon};
  position: absolute;
  transform: rotate(-45deg);
  bottom: 0.75em;
  right: 0.35em;
  font-size: 0.45em!important;
  background: white;
  border-radius: 50%;
`

const ConnectionIndicator = ({isConnected}: Props) => (
  <StyledIcon
    className="fa fa-wifi"
    style={isConnected ? undefined : {color: grayText}}
  >
    {!isConnected && <CancelIcon />}
  </StyledIcon>
)

export default ConnectionIndicator
