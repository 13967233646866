import React from 'react'
import styled from 'styled-components'
import {errorIcon} from '../colors'
import {LightningIcon} from './icons'

export interface Props {
  batteryLevel?: number
  segments?: number
  isCharging?: boolean
  isLow?: boolean
  thresholds?: number[]
}

const batteryAreaHeight = 21

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${batteryAreaHeight + 4}px;
    width: 14px;
    border: 2px solid ${(props: Props) => props.isLow ? errorIcon : 'black'};
    border-radius: 2px;
    position: relative;
    margin-top: 2px;
    
    &:after {
      content: ' ';
      position: absolute;
      width: calc(50% + 2px);
      top: -4px;
      left: 50%;
      background: ${(props: Props) => props.isLow ? errorIcon : 'black'};
      transform: translateX(-50%);
      height: 2px;
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
    }
    
    &:before {
      z-index: 5;
      content: ' ';
      position: absolute;
      height: 3px;
      width: calc(50% - 1px);
      top: -2px;
      left: 50%;
      background: white;
      transform: translateX(-50%);
    }
`

const SegmentsWrapper = styled.div`
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 1px;
  width: calc(100% - 2px);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`

interface SegmentProps {
  hidden: boolean
  isLow: boolean
  height: number
  isCharging: boolean
}

const Segment = styled.div`
  height: ${(props: SegmentProps) => props.height}px;
  margin-bottom: 1px;
  width: 100%;
  ${(props: SegmentProps) => props.hidden ? `visibility: hidden;` : ''}
  background: ${(props: SegmentProps) => props.isLow ? errorIcon : 'black'};
  opacity: ${(props: SegmentProps) => props.isCharging ? 0.5 : '1'};
`

const ChargingIcon = styled(LightningIcon)`&&&{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  z-index: 100;
  height: ${batteryAreaHeight + 2}px;
}`

const ContinuousIndicator = styled(SegmentsWrapper)`
  bottom: 1px;
  ${(props: {level: number, isLow: boolean}) => `height: ${Math.min(
    batteryAreaHeight - 2,
    (batteryAreaHeight - 2) * props.level / 100
  )}px;`}
  ${(props: {level: number, isLow: boolean}) => `background: ${props.isLow ? errorIcon : 'black'};`}
`

const BatteryIndicator = ({
  batteryLevel,
  segments,
  isCharging,
  isLow,
  thresholds,
}: Props) => {
  const segmentHeight = segments && segments > 0 ?
    // 1px around each segment
    (batteryAreaHeight - (segments + 1)) / segments :
    1
  const isSegmentHidden = (batteryLevel: number, segmentIndex: number) => {
    if (thresholds && thresholds.length > 0) {
      if (segmentIndex >= thresholds.length) {
        return true
      }

      return batteryLevel < thresholds[segmentIndex]
    }

    return batteryLevel < (100 / (segments as number)) * segmentIndex
  }
  return batteryLevel !== undefined && batteryLevel !== -1 ? (
    <Wrapper batteryLevel={batteryLevel} isLow={Boolean(isLow)}>
      {segments && segments > 1 ? (
        <SegmentsWrapper>
          {Array.from({length: segments}, (_, index) => (
            <Segment
              key={index}
              hidden={isSegmentHidden(batteryLevel, index)}
              isLow={Boolean(isLow)}
              height={segmentHeight}
              isCharging={Boolean(isCharging)}
            />
          ))}
        </SegmentsWrapper>
      ) : (
        <ContinuousIndicator
          level={batteryLevel}
          isLow={Boolean(isLow)}
        />
      )}
      {isCharging && (
        <ChargingIcon />
      )}
    </Wrapper>
  ) : null
}

export default BatteryIndicator
