import {createSelector} from 'reselect'
import {LimitFindingStatus, Store} from 'types'
import {i18n, getLanguageCodes} from 'locale'
import {processForBestLanguage} from 'utils'
import {SettingsListItemConfig} from './types'
import {getCurrentSettings} from '../selectors'

const settingsItemSorter = (itemA: SettingsListItemConfig, itemB: SettingsListItemConfig): number => {
  return itemA.translation.localeCompare(itemB.translation)
}

export const getSettingsConfig = createSelector(
  (state: Store) => state.nsp.siteSettings.locations,
  getCurrentSettings,
  (state: Store) => state.app.selectedRobot?.healthStatus.limitFindingStatus === LimitFindingStatus.OK,
  (locations, robotSettings, isAfterLimitFinding) => {
    if (!isAfterLimitFinding) {
      return []
    }

    const languageCodes = getLanguageCodes(i18n.language)

    const topLevelItemIds: string[] = []
    const idsByParent: {[parentId: string]: string[]} = {}
    const validLocations = new Set<string>()
    const lowestLevelLocationsToUse = robotSettings?.rooms.map(room => room.roomId) || []
    for (const lowLocationId of lowestLevelLocationsToUse) {
      let itemId: string | undefined = lowLocationId
      while (itemId) {
        if (itemId && locations[itemId]) {
          validLocations.add(itemId)
          itemId = locations[itemId].parentId
        } else {
          itemId = undefined
        }
      }
    }

    if (locations.Home && !validLocations.has('Home')) {
      validLocations.add('Home')
    }

    for (const locationId of validLocations) {
      const location = locations[locationId]
      if (!location.parentId) {
        topLevelItemIds.push(locationId)
      } else {
        idsByParent[location.parentId] = [
          ...(idsByParent[location.parentId] || []),
          locationId,
        ]
      }
    }

    const mapLocation = (locationId: string): SettingsListItemConfig => {
      return {
        children: (idsByParent[locationId] || [])
          .map(mapLocation)
          .sort(settingsItemSorter),
        id: locationId,
        translation: processForBestLanguage(languageCodes, language => locations[locationId][language], '') as string,
      }
    }

    return (topLevelItemIds.length > 0 ? topLevelItemIds : Object.keys(locations))
      .map(mapLocation)
      .sort(settingsItemSorter)
  }
)
