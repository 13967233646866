import {createSelector} from 'reselect'

const prefixesToCheck = ['left_arm/', 'right_arm/', 'center/']

export const getBaseJointName = (joint: string): string => {
  for (const prefix of prefixesToCheck) {
    if (joint.startsWith(prefix)) {
      return joint.substring(prefix.length)
    }
  }

  return joint
}

const groupJointItemSeparator = '_|_'

export const groupJoints = createSelector(
  // convert joints to string as otherwise when new joint array arrives from server it's treated as different array
  (joints: string[]) => joints.join(groupJointItemSeparator),
  (joints: string): string[][] => {
    const groupedJoints: {[jointName: string]: string[]} = {}
    joints.split(groupJointItemSeparator).forEach(joint => {
      const baseJointName = getBaseJointName(joint)
      if (!groupedJoints[baseJointName]) {
        groupedJoints[baseJointName] = []
      }

      groupedJoints[baseJointName].push(joint)
    })
    return Object
      .keys(groupedJoints)
      .sort()
      .map(jointName => groupedJoints[jointName].sort((nameA, nameB) => {
        if (nameA.startsWith('left') && !nameB.startsWith('left')) {
          return -1
        }

        if (nameA.startsWith('right') && !nameB.startsWith('right')) {
          return 1
        }

        if (nameB.startsWith('right')) {
          return -1
        }

        if (nameB.startsWith('left')) {
          return 1
        }

        return 0
      }))
  }
)
