import {connect} from 'react-redux'
import {Store} from 'types'
import {settingsMapStateToProps} from '../../navigation/utils'
import {fetchObjects, setEditedObjects, clearEditedObjects, saveObjects} from './actions'
import SettingsPage from './SettingsPage'
import {getCurrentObjects, getObjectGroups, willAutoRedirect} from './selectors'

const mapStateToProps = (state: Store) => {
  const {canSendRobotCommand, patrolling, robotLoaded} = settingsMapStateToProps(state)
  return {
    robotLoaded,
    locations: state.nsp.siteSettings.locations,
    isFetchingObjects: state.uv.settings.isFetchingObjects,
    objects: getCurrentObjects(state),
    editedObjects: state.uv.settings.editedObjects,
    canSendRobotCommand: canSendRobotCommand && !patrolling,
    objectGroups: getObjectGroups(state),
    willAutoRedirect: willAutoRedirect(state),
  }
}

export default connect(mapStateToProps, {
  fetchObjects,
  setEditedObjects,
  clearEditedObjects,
  saveObjects,
})(SettingsPage)
