import {DiagnosticEvent, Locations, PatrolPointAction} from '../../types'
import {getLocationParts} from '../../utils'

export const getDetailedLocation = (event: DiagnosticEvent, locations: Locations, unknownPointTranslation: string) => {
  const location = event.patrolPointConfig?.patrolPoint?.uid || event.fromLocation
  const parts = getLocationParts(locations, location)
  if (parts.length < 1) {
    return unknownPointTranslation
  }

  return parts.slice(0, parts.length - 1).join(', ')
}

export const isUvAction = (event?: DiagnosticEvent) => {
  const action = event?.patrolPointConfig?.patrolPoint?.action
  return action === PatrolPointAction.FIND_AND_DISINFECT_WITH_LAMP || action === PatrolPointAction.FIND_AND_DISINFECT
}
