/* eslint-disable max-len */
import React from 'react'
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon'
import {errorIcon, white} from '../../colors'

const ErrorIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
      fill={errorIcon}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1 14.6375V11.3875C16.1 9.39199 15.0405 7.72149 13.175 7.27949V6.83749C13.175 6.29799 12.7395 5.86249 12.2 5.86249C11.6605 5.86249 11.225 6.29799 11.225 6.83749V7.27949C9.36602 7.72149 8.30002 9.38549 8.30002 11.3875V14.6375L7.00002 15.9375V16.5875H17.4V15.9375L16.1 14.6375ZM12.2 18.5375C12.915 18.5375 13.5 17.9525 13.5 17.2375H10.9C10.9 17.9525 11.485 18.5375 12.2 18.5375ZM9.60001 15.2875H14.8V11.3875C14.8 9.77548 13.8185 8.46248 12.2 8.46248C10.5815 8.46248 9.60001 9.77548 9.60001 11.3875V15.2875ZM9.32701 6.88949L8.39751 5.95999C6.83751 7.14949 5.81051 8.98249 5.71951 11.0625H7.01951C7.11701 9.33999 8.00101 7.83199 9.32701 6.88949ZM18.6805 11.0625H17.3805C17.283 9.33999 16.3925 7.83199 15.0795 6.88949L16.0025 5.95999C17.556 7.14949 18.583 8.98249 18.6805 11.0625Z"
      fill={white}
    />
  </SvgIcon>
)

export default ErrorIcon
