import React, {useState, useEffect, useCallback, SyntheticEvent} from 'react'
import TextField from '@material-ui/core/TextField'
import {Button, DialogContent, DialogActions, DialogFormTitle} from 'components'
import {httpPost, isLatinCharacter, usePrevious} from 'utils'
import {useLocation} from 'react-router-dom'
import {blueButton, white} from '../colors'
import {MAX_ROBOT_LATIN_CHARACTERS} from '../constants'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  addSnackbarMessage: (text: string) => void
  close: () => void
  initialName?: string
  isOpen: boolean
  robotId: string
  siteId: string
}

const RobotNameEditModalContent = ({
  addSnackbarMessage,
  close,
  initialName,
  isOpen,
  robotId,
  siteId,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const {pathname} = useLocation()
  const previousPathname = usePrevious(pathname)
  const [name, setName] = useState(initialName || '')
  const [error, setError] = useState('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    setError('')
  }

  useEffect(() => {
    setName(initialName || '')
    setError('')
  }, [initialName, isOpen])

  useEffect(() => {
    if (previousPathname && pathname !== previousPathname) {
      close()
    }
  }, [close, previousPathname, pathname])

  const save = useCallback(async (newName: string) => {
    const response: Response = await httpPost(`${siteId}/robot/${robotId}`, {name: newName})
    const {status} = response
    if (status < 300) {
      close()
      addSnackbarMessage('Robot configuration robot name edit update success')
    } else {
      addSnackbarMessage('Robot configuration robot name edit update error')
    }
  }, [robotId])

  const confirm = useCallback((event: SyntheticEvent) => {
    event.preventDefault()
    const trimmedName = name.trim()
    const isEmpty = trimmedName.length === 0
    if (isEmpty) {
      setError('empty')
      return
    }

    if (trimmedName === initialName) {
      close()
      return
    }

    const letterCount = trimmedName.split('').reduce((count, character) => {
      return count + (isLatinCharacter(character) ? 1 : 2)
    }, 0)

    if (letterCount > MAX_ROBOT_LATIN_CHARACTERS) {
      setError('length')
      return
    }

    save(trimmedName)
  }, [save, name, initialName])

  return (
    <form onSubmit={confirm}>
      <DialogContent>
        <DialogFormTitle>
          {t('Nav_SiteConfiguration title site configuration robot name edit title')}
        </DialogFormTitle>
        <TextField
          autoFocus
          error={Boolean(error)}
          type="text"
          fullWidth
          helperText={error ?
            t(`Nav_SiteConfiguration content site configuration robot name edit error ${error}`) :
            t(`Nav_SiteConfiguration content site configuration robot name edit help text`)
          }
          onChange={handleChange}
          value={name}
        />
        <DialogActions $noHorizontalPadding>
          <Button onClick={close}>
            {t('Common button cancel')}
          </Button>
          <Button
            type="submit"
            $backgroundColor={blueButton}
            $textColor={white}
          >
            {t('Common button save')}
          </Button>
        </DialogActions>
      </DialogContent>
    </form>
  )
}

export default RobotNameEditModalContent
