import React, {useEffect} from 'react'
import styled from 'styled-components'
import {List, Loader} from 'components'
import {ListEmptyText} from '../NSPCommonStyled'
import SiteLocationItem from './SiteLocationItem'
import LocationEditModalContainer from './LocationEditModalContainer'
import {SettingsListItemConfig} from './types'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

export interface Props {
  isLoading: boolean
  settingsConfig: SettingsListItemConfig[]
  fetchLocations: () => void
  settingsGetItems: () => void
  startLocationEdit: (locationId: string) => void
}

export const StyledLoader = styled(Loader)`
  margin-top: 30px;
`

const SiteLocationsList = ({
  isLoading,
  fetchLocations,
  settingsConfig,
  startLocationEdit,
  settingsGetItems,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  useEffect(() => {
    fetchLocations()
    settingsGetItems()
  }, [])

  if (isLoading) {
    return <StyledLoader centered />
  }

  if (settingsConfig.length === 0) {
    return <ListEmptyText>{t('Nav_CommonSetting content Settings List Empty')}</ListEmptyText>
  }

  const renderSetting = (setting: SettingsListItemConfig) => (
    <React.Fragment key={setting.id}>
      <SiteLocationItem
        config={setting}
        startLocationEdit={startLocationEdit}
      />
      {setting.children.length > 0 && (
        <List isNested>
          {setting.children.map(renderSetting)}
        </List>
      )}
    </React.Fragment>
  )

  return (
    <>
      <List>
        {settingsConfig.map(renderSetting)}
      </List>
      <LocationEditModalContainer />
    </>
  )
}

export default SiteLocationsList
