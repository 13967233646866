import React, {useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import MaterialDialog, {DialogProps} from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import MaterialDialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import styled from 'styled-components'
import {useClickOutside, useAppToolbarHeight, usePrevious} from 'utils'
import {Store} from '../types'
import {PageTitle} from './BasicElementsStyled'
import {AnyAction, Dispatch} from 'redux'

interface DialogTitleProps {
  children: React.ReactNode
  onClose?: () => void
}

const DialogTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const DialogTitleContent = styled(PageTitle)`
  flex: 1;
  margin-top: 0;
`

export const DialogInnerContent = styled.div`
  margin-top: 16px;
`

const DialogActions = styled(MaterialDialogActions)`
  ${(props: {$noHorizontalPadding?: boolean, $centered?: boolean}) => props.$noHorizontalPadding ? 'padding: 24px 0 16px 0!important;' : ''}
  ${(props: {$noHorizontalPadding?: boolean, $centered?: boolean}) => props.$centered ? 'justify-content: center;' : ''}
`

const DialogFormTitle = styled(PageTitle)`
  flex: 1;
  text-align: left;
  margin: 0 0 24px 0;
  font-weight: 500;
`

const TitleIconButton = styled(IconButton)`
  padding: 0!important;
  top: -10px;
  right: -10px;
`

const DialogGlobalCloseButton = styled(IconButton)`&&& {
  padding: 0 !important;
  top: 10px;
  right: 10px;
  background-color: white;
  position: absolute;
  z-index: 100;
  
  .MuiSvgIcon-root {
    font-size: 1.75rem;
  }
}`

const IconPlaceholder = styled.div`
  width: 40px;
`

const DialogTitle = ({children, onClose, ...other}: DialogTitleProps) => {
  const closeIcon = onClose ?
    <TitleIconButton
      onClick={onClose}
    >
      <CloseIcon />
    </TitleIconButton> :
    null
  return (
    <DialogTitleWrapper {...other}>
      {closeIcon && <IconPlaceholder />}
      <DialogTitleContent>
        {children}
      </DialogTitleContent>
      {closeIcon}
    </DialogTitleWrapper>
  )
}

interface Props extends Omit<DialogProps, 'onClose'> {
  autoCloseSelector?: (state: Store) => boolean
  autoCloseKey?: (state: Store) => string
  onClose: () => void
}

interface DialogComponentProps extends Props {
  dispatch?: Dispatch<AnyAction>
  closeKey: string
  shouldClose: boolean
  withCloseButton?: boolean
}

const DialogStyled = styled(MaterialDialog)`
  ${(props: DialogProps & {$toolbarHeight: number}) => `
    top: ${props.$toolbarHeight}px!important;
    
    > .MuiBackdrop-root {
      top: ${props.$toolbarHeight}px!important;
    }
  `}
  
  .MuiDialog-paperFullWidth {
     width: calc(100% - 32px);
  }
  
  .MuiDialog-paper {
    margin: 16px;
  }
  
  .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 32px);
  }
`

const DialogComponent = (props: DialogComponentProps) => {
  // eslint-disable-next-line no-unused-vars
  const {shouldClose, closeKey, onClose, withCloseButton, dispatch, autoCloseKey, autoCloseSelector, ...dialogProps} = props
  const previousShouldAutoClose = usePrevious(shouldClose)
  const previousCloseKey = usePrevious(closeKey)
  const dialogRef = useRef<HTMLElement>(null)
  useClickOutside(dialogRef, onClose)
  const toolbarHeight = useAppToolbarHeight(props.open)

  useEffect(() => {
    const notClosedBefore = previousShouldAutoClose === false
    const closeKeyChanged = closeKey && previousCloseKey !== undefined && closeKey !== previousCloseKey
    if (shouldClose && (notClosedBefore || closeKeyChanged)) {
      onClose()
    }
  }, [onClose, shouldClose, previousShouldAutoClose, previousCloseKey, closeKey])

  return (
    <DialogStyled
      ref={dialogRef}
      $toolbarHeight={toolbarHeight}
      {...dialogProps}
    >
      {withCloseButton && onClose && (
        <DialogGlobalCloseButton
          onClick={onClose}
        >
          <CloseIcon />
        </DialogGlobalCloseButton>
      )}
      {props.children}
    </DialogStyled>
  )
}

const mapStateToProps = (state: Store, ownProps: Props) => {
  return {
    shouldClose: ownProps.autoCloseSelector ?
      ownProps.autoCloseSelector(state) :
      false,
    closeKey: ownProps.autoCloseKey ?
      ownProps.autoCloseKey(state) :
      '',
  }
}

const Dialog = connect(mapStateToProps)(DialogComponent)

export {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogFormTitle,
}
