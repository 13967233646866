import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useModuleAwareTranslation} from 'utils'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import {errorIcon, success} from '../colors'

interface Props {
  flex?: string
  conditions: Array<{isFulfilled: boolean, text?: string, textKey?: string, identifier: string}>
  marginBottom: string
  onLastRemoved?: () => void
  onEnter?: () => void
}

const animationDelayTime = 2000
const animationTime = 500
const hideTime = 200
const transitionName = 'preconditions'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90vw;
  max-width: 400px;
  align-self: center;
  transition: flex 0.5s linear;
  ${(props: {marginBottom: string, flex?: string}) => props.flex ? `flex: ${props.flex};` : ''};
  
  &:last-child {
    margin-bottom: ${(props: {marginBottom: string, flex?: string}) => props.marginBottom};
  }
`

const Content = styled(TransitionGroup)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  &:not(:empty) {
    padding: 8px 16px 0 16px;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  font-size: 1rem;
  font-weight: 500;
  
  transition: all 0.3s linear;
  
  svg {
    margin-right: 12px;
  }
  
  span {
    padding-top: 6px;
  }
  
   &.${transitionName}-appear {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
  }

  &.${transitionName}-appear-active {
      opacity: 1;
      max-height: 56px;
      transition: opacity ${animationTime}ms ease-out,
        max-height ${hideTime}ms linear;
  }
  
   &.${transitionName}-exit {
      opacity: 1;
      max-height: 56px;
      overflow: hidden;
  }

  &.${transitionName}-exit-active {
      opacity: 0;
      max-height: 0;
      transition: opacity ${animationTime}ms ease-out ${animationDelayTime}ms,
        max-height ${hideTime}ms linear ${animationDelayTime + animationTime - hideTime}ms;
  }
`

const Preconditions = ({
  conditions,
  marginBottom,
  onEnter,
  onLastRemoved,
  flex,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const [fulfilledPreconditionsIdentifiers, setFulfilledIdentifiers] = useState(
    conditions
      .filter(condition => condition.isFulfilled)
      .map(condition => condition.identifier)
  )
  const [removed, setRemoved] = useState(fulfilledPreconditionsIdentifiers)

  useEffect(() => {
    return function onUnmount() {
      if (onLastRemoved) {
        onLastRemoved()
      }
    }
  }, [])

  // check if precondition became fulfilled
  useEffect(() => {
    const fulfilled = conditions
      .filter(condition => condition.isFulfilled)
      .map(condition => condition.identifier)
    setFulfilledIdentifiers(alreadyFulfilled => Array.from(new Set([...alreadyFulfilled, ...fulfilled])))
  }, [conditions])

  // check if precondition is still fulfilled
  useEffect(() => {
    const noLongerFulfilled = fulfilledPreconditionsIdentifiers.filter(id => {
      const condition = conditions.find(condition => condition.identifier === id)
      return !condition || !condition.isFulfilled
    })

    if (noLongerFulfilled.length > 0) {
      setFulfilledIdentifiers(currentFulfilled => currentFulfilled.filter(id => !noLongerFulfilled.includes(id)))
      setRemoved(removed => removed.filter(id => !noLongerFulfilled.includes(id)))
    }
  }, [fulfilledPreconditionsIdentifiers, conditions])

  // check if there are active elements
  useEffect(() => {
    const filteredItems = conditions.filter(condition => !fulfilledPreconditionsIdentifiers.includes(condition.identifier))
    if (filteredItems.length > 0 && onEnter) {
      onEnter()
    }
  }, [conditions, fulfilledPreconditionsIdentifiers])

  // check if all elements were removed
  useEffect(() => {
    if (conditions.every(condition => removed.includes(condition.identifier)) && onLastRemoved) {
      onLastRemoved()
    }
  }, [conditions, removed])

  const preconditions = conditions.filter(condition => !fulfilledPreconditionsIdentifiers.includes(condition.identifier))

  return (
    <Wrapper
      marginBottom={marginBottom}
      flex={flex}
    >
      <Content>
        {preconditions
          .map(condition => {
            const Icon = condition.isFulfilled ? CheckIcon : CloseIcon
            const color = condition.isFulfilled ? success : errorIcon
            const isInConditions = conditions.some(({identifier}) => identifier === condition.identifier)
            return (
              <CSSTransition
                key={condition.identifier}
                timeout={condition.isFulfilled && isInConditions ? (animationDelayTime + animationTime) : 0}
                classNames={transitionName}
                onExited={() => setRemoved(removed => Array.from(new Set([...removed, condition.identifier])))}
                onEnter={onEnter}
              >
                <Item>
                  <Icon style={{color, fontSize: '32px'}} />
                  <span>{condition.text || t(condition.textKey || '')}</span>
                </Item>
              </CSSTransition>
            )
          })}
      </Content>
    </Wrapper>
  )
}

export default Preconditions
