import {createSelector} from 'reselect'
import {Store} from 'types'
import {sortObjectsByDateDesc} from 'utils'
import {OngoingPatrolEventType} from './types'
import {getDiagnosticEvents, getStateMachine} from '../selectors'
import {DiagnosticEvent, DiagnosticReason, stateGroupsForOngoingPatrolEvents} from '../types'

export const getOngoingEvents = createSelector(
  (state: Store) => state.nsp.patrolEvents.ongoingPatrolEventsType,
  (state: Store) => state.nsp.patrolEvents.currentEventsForPreview?.events,
  (type, events) => {
    if (!events) {
      return []
    }

    const eventsToUse = type === OngoingPatrolEventType.IMAGES ?
      events.filter(event => event.hasPhoto || event.reason === DiagnosticReason.POINT_SKIPPED) :
      events

    return eventsToUse
      .sort(sortObjectsByDateDesc)
  }
)

export const showOngoingPatrolEvents = createSelector(
  (state: Store) => getStateMachine(state)?.stateGroup,
  (state: Store) => state.nsp.patrolEvents.currentEventsForPreview?.events?.length,
  (state: Store) => state.app.selectedRobot?.siteId,
  (state: Store) => state.app.selectedRobot?.id,
  (stateGroup, eventsCount, siteId, robotId) => (
    Boolean(eventsCount) &&
    siteId !== undefined &&
    robotId !== undefined &&
    stateGroupsForOngoingPatrolEvents.includes(stateGroup)
  )
)

let checkedEvent: DiagnosticEvent | undefined
let checkedEventViewed: boolean | undefined
let checkedEventNotificationId: string | undefined
const getCachedCheckedEvent = (state: Store) => {
  const events = getDiagnosticEvents(state)
  const event = events.find(({reason}) => reason === DiagnosticReason.POINT_DONE)
  const isDifferentThanCached = (
    event?.notificationUid !== checkedEventNotificationId ||
    event?.viewed !== checkedEventViewed
  )
  if (isDifferentThanCached) {
    checkedEvent = event
    checkedEventViewed = event?.viewed
    checkedEventNotificationId = event?.notificationUid
  }

  return checkedEvent
}

export const getCurrentCheckedEvent = createSelector(
  getCachedCheckedEvent,
  (state: Store) => getStateMachine(state)?.stateGroup,
  (state: Store) => state.nsp.viewedEvents,
  (state: Store) => state.app.selectedRobot ?
    state.app.newestEventDateByRobot[state.app.selectedRobot.id] :
    undefined,
  (event, stateGroup, viewedEvents, date) => {
    if (
      stateGroupsForOngoingPatrolEvents.includes(stateGroup) &&
      event &&
      !viewedEvents.includes(event.notificationUid) &&
      event.reason === DiagnosticReason.POINT_DONE &&
      (new Date(event.date)).getTime() === date?.getTime()
    ) {
      return event
    }

    return undefined
  }
)
