import React, {useCallback} from 'react'
import {useModuleAwareTranslation} from 'utils'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import {TextButton} from 'components'
// eslint-disable-next-line no-unused-vars
import {DiagnosticEvent, DiagnosticReason} from '../types'
import {videoListUrl} from '../../../config'
import styled from 'styled-components'
import {useDispatch} from 'react-redux'
import {setVideoUrl} from '../actions'

const StyledTextButton = styled(TextButton)`
  margin: 12px auto;
  max-width: 75vw;
  
  > .MuiButton-label > span {
    margin-left: 12px;
    display: inline-block;
  }
`

type Props = {
  event?: DiagnosticEvent
}

// TODO: fill that const to have PCR-2615 ready
// mapping from assistance reason to YT video link, if not provided then button won't be displayed
const reasonToVideoUrl: {[key: string]: string | undefined} = {
  // [DiagnosticReason.CHECK_ENVIRONMENT]: 'https://youtu.be/13eja_RYimU',
  // [DiagnosticReason.PERSON_IN_HALLWAY]: 'https://www.youtube.com/watch?v=Unzc731iCUY',
}

// TODO: fill that const to have PCR-2615 ready
// mapping from assistance reason to title that will be displayed on the modal with the video
// required if there's a video for given reason
const reasonToModalTitle: {[key: string]: string | undefined} = {
  // [DiagnosticReason.CHECK_ENVIRONMENT]: 'Nav_Event video tutorial title 1',
  // [DiagnosticReason.PERSON_IN_HALLWAY]: 'Nav_Event video tutorial title 2',
}

// TODO: fill that const to have PCR-2615 ready
// custom button texts - optional
const reasonToCustomText: {[key: string]: string | undefined} = {
  // [DiagnosticReason.PERSON_IN_HALLWAY]: 'some translation key for custom button key',
}

const EventVideoTutorial: React.FC<Props> = ({
  event,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const dispatch = useDispatch()
  const videoLink = event ? reasonToVideoUrl[event.reason] : ''
  const modalTitle = event ? reasonToModalTitle[event.reason] : ''
  let text = event ? reasonToCustomText[event.reason] : ''
  if (!text) {
    text = 'Nav_Event link video text default'
  }

  const handleClick = useCallback(() => {
    dispatch(setVideoUrl(videoLink, modalTitle))
  }, [dispatch, videoLink, modalTitle])

  return event && videoListUrl && videoLink ? (
    <StyledTextButton onClick={handleClick}>
      <OndemandVideoIcon />
      <span>{t(text)}</span>
    </StyledTextButton>
  ) : null
}

export default EventVideoTutorial
