import React from 'react'
import * as Styled from './StepsStyled'
import {StepProps} from './types'
import StepTexts from './StepTexts'

const DoneStep: React.FC<StepProps> = ({step}) => {
  return (
    <Styled.Step data-testid="lf-step-content-done">
      <StepTexts step={step} />
      <Styled.StepImage src={`/calibration/done.gif`} />
    </Styled.Step>
  )
}

export default DoneStep
