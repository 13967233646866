import React, {useEffect, useState} from 'react'
import {getTimeDifferenceString, useModuleAwareTranslation} from 'utils'
import {NAV_HOOK_LABELS} from 'modules/navigation/constants'
import {DiagnosticEvent, ExtendEventTitleHookContext, Locations} from 'modules/navigation/types'
import {getLocationTranslation, hasFaceId} from 'modules/navigation/utils'
import {executeHooks} from 'framework'
import * as Styled from './StyledElements'
import {getSeverity} from './utils'

interface Props {
  event: DiagnosticEvent
  locations?: Locations
  noTimeAgo?: boolean
  noTitleColor?: boolean
}

const EventTitle: React.FC<Props> = ({
  event,
  noTimeAgo,
  noTitleColor,
  locations,
}: Props) => {
  const [, setRenderCounter] = useState(0)
  const [eventDate, setEventDate] = useState(event.date)
  const {t, i18n} = useModuleAwareTranslation()

  useEffect(() => {
    if (noTimeAgo) {
      return
    }

    const interval = setInterval(() => {
      setRenderCounter(counter => counter + 1)
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    setEventDate(event.date)
  }, [event.date])

  const key = `Nav_Event title ${event.reason}`
  let titleKey = i18n.exists(key) ? key : event.reason as string
  if (hasFaceId(event.faceId) && i18n.exists(`${key} face_id`)) {
    titleKey = `${key} face_id`
  }
  const time = getTimeDifferenceString(eventDate)
  const location = event.patrolPointConfig?.patrolPoint.uid || event.fromLocation
  const roomName = getLocationTranslation(locations, location) || location
  const titleOptions = {
    roomName,
    name: event.faceId,
  }


  let optionalSkipReason = undefined
  if (event.skip_reason) {
    const key = `Nav_Event title skip reason ${event.skip_reason}`
    optionalSkipReason = i18n.exists(key) ? t(key) : event.skip_reason as string
  }

  const hookContext: ExtendEventTitleHookContext = {
    diagnosticEvent: event,
    options: titleOptions,
    locations,
    t,
  }
  executeHooks(NAV_HOOK_LABELS.EVENTS.EXTEND_TITLE_OPTIONS, hookContext)

  return (
    <Styled.EventTitle
      severity={getSeverity(event.reason)}
      noTitleColor={noTitleColor}
    >
      {titleKey ? t(titleKey, titleOptions) : event.reason}
      {optionalSkipReason && <div>{optionalSkipReason}</div>}
      {!noTimeAgo && <div>{`(${t('Nav_Event content time ago', {time})})`}</div>}
    </Styled.EventTitle>
  )
}

export default EventTitle
