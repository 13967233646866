import React from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import {ListItem} from 'components'
import {SettingsListItemConfig} from './types'

const LocationName = styled.div`
    font-weight: 500;
`

export interface Props {
  config: SettingsListItemConfig
  startLocationEdit: (locationId: string) => void
}

const SiteLocationItem = ({
  config,
  startLocationEdit,
}: Props) => {
  return (
    <ListItem fullWidth>
      <LocationName>
        {config.translation}
      </LocationName>
      <IconButton onClick={() => startLocationEdit(config.id)}>
        <EditIcon color="primary" />
      </IconButton>
    </ListItem>
  )
}

export default SiteLocationItem
