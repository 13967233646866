import {createSelector} from 'reselect'
import {Store} from 'types'
import {EventRouteMatch} from './types'

export const getCurrentEvent = createSelector(
  (state: Store) => state.nsp.report.details,
  (_: Store, ownProps: {match: EventRouteMatch}) => ownProps.match.params.patrolId,
  (_: Store, ownProps: {match: EventRouteMatch}) => ownProps.match.params.eventId,
  (details, patrolId, eventId) => {
    if (!details || details.id !== patrolId) {
      return undefined
    }

    return details.events.find(event => event.notificationUid === eventId)
  }
)

const getCurrentEventIndex = createSelector(
  (state: Store) => state.nsp.report.details,
  getCurrentEvent,
  (details, currentEvent) => {
    if (!details || !currentEvent) {
      return -1
    }

    return details.events.findIndex(event => event.notificationUid === currentEvent.notificationUid)
  }
)

export const getNextEvent = createSelector(
  (state: Store) => state.nsp.report.details,
  getCurrentEventIndex,
  (details, currentEventIndex) => {
    if (!details || currentEventIndex < 0 || details.events.length < 2) {
      return undefined
    }

    const nextEventIndex = (currentEventIndex + 1) % details.events.length
    return details.events[nextEventIndex]
  }
)

export const getPreviousEvent = createSelector(
  (state: Store) => state.nsp.report.details,
  getCurrentEventIndex,
  (details, currentEventIndex) => {
    if (!details || currentEventIndex < 0 || details.events.length < 2) {
      return undefined
    }

    const nextEventIndex = currentEventIndex === 0 ?
      details.events.length - 1 :
      currentEventIndex - 1
    return details.events[nextEventIndex]
  }
)
