import {ThunkDispatch} from 'redux-thunk'
import {HOOK_LABELS, registerHook, types} from 'framework'
import {reducerRegistry, sagaMiddleware} from 'store'
import {isPathnameMatchingRoute} from 'utils'
import ScheduleIcon from '@material-ui/icons/Schedule'
import SettingsIcon from '@material-ui/icons/Settings'
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined'
import {RobotHeadIcon} from 'components'
import Routes from './Routes'
import reducer from './reducer'
import sagas from './sagas'
import {openPresetsModal, openSetPositionModal, PresetsModalContainer, RobotPositionModalContainer} from './dashboard'
import {registerEventListeners, onEventsInitCallback} from './serverEventsListener'
import AbstractModule from '../AbstractModule'
import {ROUTES as APP_ROUTES} from '../../constants'
import {NAV_HOOK_LABELS, ROUTES} from './constants'
import {schedulesDisabled} from '../../config'
import {ExtendScheduleSectionsCallback} from './types'
import {RemindersContainer} from './reminders'
import styled from 'styled-components'
import {GetStatisticsModal, toggleGetStatisticsModal} from './siteSettings'

const defaultHandlerLabel = 'navigation'

const homeRoutes = [ROUTES.INDEX, ROUTES.FINISHED_PATROL]
const scheduleRoutes = [ROUTES.SCHEDULE, ROUTES.SETTINGS, ROUTES.OBJECT_SETTINGS]
const historyRoutes = [ROUTES.REPORT, ROUTES.REPORTS, ROUTES.REPORT_CURRENT]
const deepPaths = [
  ROUTES.FINISHED_PATROL,
  ROUTES.SETTINGS,
  ROUTES.OBJECT_SETTINGS,
  ROUTES.SITE_SETTINGS_LOCATIONS,
  ROUTES.REPORT,
  ROUTES.REPORT_CURRENT,
  ROUTES.BUG_REPORT,
  ROUTES.SITE_SETTINGS_NOTIFICATIONS,
]

// we are using custom props and we don't want them to be added to HTML so use styled components to prevent it
const StyledListAltOutlinedIcon = styled(ListAltOutlinedIcon)``
const StyledSettingsIcon = styled(SettingsIcon)``
const StyledScheduleIcon = styled(ScheduleIcon)``

const registerRouteCallback: types.RegisterRouteCallback = (context) => context.baseRoute === APP_ROUTES.HOME ? ({
  navigation: Routes,
}) : undefined

const registerDeepPathsCallback: types.RegisterDeepPathsHook = () => ({
  navigation: deepPaths,
})

const setSelectedNavItemCallback: types.GetSelectedMenuItemHook = context => {
  if (homeRoutes.some(route => isPathnameMatchingRoute(context.pathname, route))) {
    context.selectedValue = 'home'
  } else if (scheduleRoutes.some(route => isPathnameMatchingRoute(context.pathname, route))) {
    context.selectedValue = 'schedule'
  } else if (historyRoutes.some(route => isPathnameMatchingRoute(context.pathname, route))) {
    context.selectedValue = 'history'
  }
}

const addMenuItemsCallback: types.AddMenuLinksHook = context => {
  context.links.push({
    value: 'home',
    link: ROUTES.INDEX,
    labelKey: 'Nav_Menu Patrol',
    icon: RobotHeadIcon,
    order: 0,
    hidden: () => false,
  })
  context.links.push({
    value: 'schedule',
    link: ROUTES.SCHEDULE,
    labelKey: schedulesDisabled ? 'Nav_Menu Settings' : 'Nav_Menu Schedule',
    icon: schedulesDisabled ? StyledSettingsIcon : StyledScheduleIcon,
    order: 5,
    hidden: (isPatrolConfigUp, _, canAccessSchedule) => {
      if (!canAccessSchedule) {
        return true
      }
      return !isPatrolConfigUp
    },
  })
  context.links.push({
    value: 'history',
    link: ROUTES.REPORTS,
    labelKey: 'Nav_Menu History',
    icon: StyledListAltOutlinedIcon,
    order: 10,
    hidden: (isPatrolConfigUp, selectedRobotId) => !selectedRobotId,
  })
}

const registerDashboardRightBlockCallback = () => ({
  uv: PresetsModalContainer,
  position: RobotPositionModalContainer,
  statistics: GetStatisticsModal,
})

const getExtraDashboardActions = () => ({
  nav: [
    {
      moduleAction: openSetPositionModal,
      localeKey: 'Dashboard robot position title',
      main: false,
      textButtonLocaleKey: 'Dashboard robot position button',
    },
    {
      moduleAction: openPresetsModal,
      localeKey: 'Nav_Dashboard use settings presets text',
      main: false,
      textButtonLocaleKey: 'Nav_Dashboard use settings presets button',
    },
    {
      moduleAction: toggleGetStatisticsModal,
      localeKey: 'Dashboard export operation history',
      main: false,
      textButtonLocaleKey: 'Dashboard dashboard get',
      isAvailableWhenRobotOffline: true,
    },
  ],
})

const extendScheduleSections: ExtendScheduleSectionsCallback = context => {
  context.sections.push({
    module: 'navigation',
    order: 50,
    Component: RemindersContainer,
  })
}

class NavigationModule extends AbstractModule {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  installModule(dispatch: ThunkDispatch<any, any, any>) {
    reducerRegistry.register('nsp', reducer)
    sagaMiddleware.run(sagas)
    registerHook(HOOK_LABELS.ROUTING.REGISTER_DEEP_PATHS, defaultHandlerLabel, registerDeepPathsCallback)
    registerHook(HOOK_LABELS.ROUTING.REGISTER_ROUTES, defaultHandlerLabel, registerRouteCallback)
    registerHook(HOOK_LABELS.ROUTING.SELECTED_NAV_ITEM, defaultHandlerLabel, setSelectedNavItemCallback)
    registerHook(HOOK_LABELS.ROUTING.ADD_MENU_LINKS, defaultHandlerLabel, addMenuItemsCallback)
    registerHook(HOOK_LABELS.EVENTS.REGISTER_SSE_EVENT_LISTENER, defaultHandlerLabel, registerEventListeners)
    registerHook(HOOK_LABELS.EVENTS.ON_EVENTS_INIT, defaultHandlerLabel, onEventsInitCallback)
    registerHook(HOOK_LABELS.DASHBOARD.EXTRA_ACTIONS, defaultHandlerLabel, getExtraDashboardActions)
    registerHook(HOOK_LABELS.DASHBOARD.EXTRA_RIGHT_BLOCKS, defaultHandlerLabel, registerDashboardRightBlockCallback)
    registerHook(NAV_HOOK_LABELS.SCHEDULE.EXTEND_SECTIONS, defaultHandlerLabel, extendScheduleSections)
  }
}

let mod: NavigationModule | undefined
const getModule = () => {
  if (!mod) {
    mod = new NavigationModule('navigation')
  }

  return mod
}

export default getModule
