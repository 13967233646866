import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import {Button, ButtonProps, Dialog, DialogContent, DialogTitle} from 'components'
import * as Styled from './LimitFindingWizardStyled'
import {useTranslation} from 'react-i18next'
import {STEPS} from './constants'
import {blueButton, primary, white} from '../colors'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  step: STEPS
  open: boolean
  closeModalForStep: () => {}
}

enum TEXT_TYPE {
  REGULAR,
  ORDERED_LIST,
  UNORDERED_LIST,
}

const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 0;
`

const WideButton = styled(Button)`
  width: calc(100% - 32px);
  max-width: 360px;
  min-height: 42px;
`

const ActionButton = styled(Button)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.25s linear;
    min-width: 32px;
    width: 36px;
    ${(props: ButtonProps) => props.disabled ? 'opacity: 0;' : ''}
`

const ActionButtonRight = styled(ActionButton)`
    right: 2px;
`

const ActionButtonLeft = styled(ActionButton)`
    left: 2px;
`

const getContent = (text: string, type: TEXT_TYPE, hasMultiplePages: boolean) => {
  if (type === TEXT_TYPE.ORDERED_LIST || type === TEXT_TYPE.UNORDERED_LIST) {
    const items = text.split('\n')
    const ListComponent = type === TEXT_TYPE.ORDERED_LIST ?
      Styled.ManualOrderedList :
      Styled.ManualUnorderedList
    return (
      <Styled.ManualContent withPadding={hasMultiplePages}>
        <ListComponent>
          {items.map((item, index) => (
            <Styled.ManualListItem key={index}>
              {item}
            </Styled.ManualListItem>
          ))}
        </ListComponent>
      </Styled.ManualContent>
    )
  }

  return (
    <Styled.ManualContent withPadding={hasMultiplePages}>
      {text}
    </Styled.ManualContent>
  )
}

const ModalForStep = ({
  step,
  open,
  closeModalForStep,
}: Props) => {
  const {i18n} = useTranslation()
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const location = useLocation()
  const history = useHistory()
  const [page, setPage] = useState(0)

  const onClose = () => {
    closeModalForStep()
    history.goBack()
  }

  useEffect(() => {
    if (!location.hash) {
      closeModalForStep()
    }
  }, [location.hash])

  useEffect(() => {
    if (location.hash) {
      history.goBack()
    }
  }, [])

  useEffect(() => {
    setPage(0)
  }, [open])

  const titleKey = `PowerOn_CalibrationWizard title power on calibration step ${step} manual title`
  const baseTextKey = `PowerOn_CalibrationWizard content power on calibration step ${step} manual text`
  const hasSecondPage = i18n.exists(`${baseTextKey} 2`)

  const pagePostfix = page > 0 ? ` ${page + 1}` : ''
  const textKey = `PowerOn_CalibrationWizard content power on calibration step ${step} manual text${pagePostfix}`
  const warningKey = `PowerOn_CalibrationWizard content power on calibration step ${step} manual warning${pagePostfix}`
  const typeKey = `PowerOn_CalibrationWizard content power on calibration step ${step} manual type${pagePostfix}`
  let type = TEXT_TYPE.REGULAR
  if (i18n.exists(typeKey)) {
    const typeValue = t(typeKey)
    if (typeValue === 'unordered list') {
      type = TEXT_TYPE.UNORDERED_LIST
    } else if (typeValue === 'ordered list') {
      type = TEXT_TYPE.ORDERED_LIST
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <DialogTitle data-testid={`lf-modal-for-step-${step}`}>
          {t(titleKey)}
        </DialogTitle>
        <Styled.ManualStepTitle />
        {i18n.exists(textKey) && getContent(t(textKey), type, hasSecondPage)}
        {i18n.exists(warningKey) && (
          <Styled.ManualWarning>
            {t(warningKey)}
          </Styled.ManualWarning>
        )}
        {step !== STEPS.STEP_1 && (
          <Styled.ManualImageWrapper>
            <Styled.ManualImage src={`/calibration/${step}-manual.gif`} />
          </Styled.ManualImageWrapper>
        )}

        {hasSecondPage && page > 0 && (
          <ActionButtonLeft
            $backgroundColor={blueButton}
            $textColor={white}
            onClick={() => setPage(page - 1)}
          >
            <ChevronLeftIcon />
          </ActionButtonLeft>
        )}
        {hasSecondPage && page === 0 && (
          <ActionButtonRight
            $backgroundColor={blueButton}
            $textColor={white}
            onClick={() => setPage(page + 1)}
          >
            <ChevronRightIcon />
          </ActionButtonRight>
        )}
        <ActionsWrapper>
          <WideButton
            data-testid="lf-modal-close"
            $backgroundColor={primary}
            $textColor={white}
            onClick={onClose}
          >
            {t('Common button close')}
          </WideButton>
        </ActionsWrapper>
      </DialogContent>
    </Dialog>
  )
}

export default ModalForStep
