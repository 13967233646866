import {RobotModulesContext} from './types'
import React from 'react'

interface Props {
  currentModule?: string
  children?: React.ReactNode
}

const ModuleContextProvider = ({
  currentModule,
  children,
}: Props) => {
  return (
    <RobotModulesContext.Provider value={{currentModule}}>
      {children}
    </RobotModulesContext.Provider>
  )
}

export default ModuleContextProvider
