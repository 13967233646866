import {createSelector} from 'reselect'
import {emptyArray} from 'utils'
import {filterOutSettingsForClients, getLocationParts, getLocationPath} from 'modules/navigation/utils'
import {eventStateGroups} from 'modules/navigation/types'
import {getCurrentConfigurationIdFromUrl, getStateMachine} from 'modules/navigation/selectors'
import {Store} from 'types'
import {defaultObjectType} from '../constants'
import {ObjectGroup, ObjectGroupType, ObjectSubGroup} from './types'
import {hasPermission} from 'components'
import {PERMISSIONS} from '../../../constants'
import {scenarioToModule} from '../../utils'

export const getCurrentSettings = (state: Store) => {
  const configurationUid = getCurrentConfigurationIdFromUrl()
  const allObjects = state.uv.settings.objects
  return allObjects.find(item => item.configurationUid === configurationUid) || allObjects[0]
}
export const getCurrentObjects = (state: Store) => {
  const canEditNavigateToActions = hasPermission(state, PERMISSIONS.PATROL.UPDATE_NAVIGATE_LOCATIONS_FOR_UV)
  const settings = getCurrentSettings(state)
  if (!settings) {
    return emptyArray
  }
  const objects = getCurrentSettings(state).objects
  const module = scenarioToModule(settings.scenario)
  return filterOutSettingsForClients(objects, module, canEditNavigateToActions)
}

export const getCurrentConfigurationIndex = (state: Store) => {
  const configurationUid = getCurrentConfigurationIdFromUrl()
  const allObjects = state.uv.settings.objects
  const itemIndex = allObjects
    .sort((itemA, itemB) => itemA.configurationUid.localeCompare(itemB.configurationUid))
    .findIndex(item => item.configurationUid === configurationUid)
  return itemIndex >= 0 ? itemIndex : 0
}

export const getObjectGroups = createSelector(
  (state: Store) => state.app.selectedRobot?.modules?.current,
  getCurrentObjects,
  (state: Store) => state.nsp.siteSettings.locations,
  (state: Store) => state.nsp.siteSettings.configuration,
  (currentModule, objects, locations, configuration) => {
    const objectsByLocation = {} as {[location: string]: ObjectGroup}
    const objectsByType = {} as {[type: string]: ObjectGroup}

    const groupingLevels = currentModule && typeof configuration[currentModule]?.groupingLevels === 'number' ?
      configuration[currentModule]?.groupingLevels :
      1

    for (const object of objects) {
      const locationParts = getLocationParts(locations, object.pointId)
      const locationPath = getLocationPath(locations, object.pointId)
      // subtract lowest location name from location parts
      const location = locationParts.length > 1 ? locationParts.slice(0, groupingLevels).join(', ') : ''
      const objectType = object.type || defaultObjectType
      if (!objectsByType[objectType] || !objectsByLocation[location]) {
        const baseObject = {
          total: 0,
          selected: 0,
          objectType,
          parentId: locationPath[groupingLevels - 1],
          translatedLocation: location,
        }

        if (!objectsByType[objectType]) {
          objectsByType[objectType] = {
            ...baseObject,
            subgroups: [],
            groupType: ObjectGroupType.OBJECTS,
          }
        }

        if (!objectsByLocation[location]) {
          objectsByLocation[location] = {
            ...baseObject,
            subgroups: [],
            groupType: ObjectGroupType.LOCATIONS,
          }
        }
      }

      const objectGroup = objectsByType[objectType]
      const locationGroup = objectsByLocation[location]
      const baseSubgroup = {
        objectType,
        translatedLocation: location,
        total: 0,
        parentId: locationPath[groupingLevels - 1],
        selected: 0,
      }

      if (!objectGroup.subgroups.some(subgroup => subgroup.translatedLocation === location)) {
        objectGroup.subgroups.push({
          ...baseSubgroup,
          objectIds: [],
          groupType: ObjectGroupType.LOCATIONS,
        })
      }

      if (!locationGroup.subgroups.some(subgroup => subgroup.objectType === objectType)) {
        locationGroup.subgroups.push({
          ...baseSubgroup,
          objectIds: [],
          groupType: ObjectGroupType.OBJECTS,
        })
      }

      const objectSubgroup = objectGroup.subgroups.find(group => group.translatedLocation === location) as ObjectSubGroup
      const locationSubgroup = locationGroup.subgroups.find(group => group.objectType === objectType) as ObjectSubGroup

      objectGroup.total++
      locationGroup.total++
      objectSubgroup.total++
      locationSubgroup.total++

      objectSubgroup.objectIds.push(object.pointId)
      locationSubgroup.objectIds.push(object.pointId)
    }

    return [
      ...Object.values(objectsByLocation),
      ...Object.values(objectsByType),
    ]
  }
)

export const willAutoRedirect = (state: Store) => {
  const stateGroup = getStateMachine(state)?.stateGroup
  return stateGroup !== undefined && eventStateGroups.includes(stateGroup)
}
