import {BackendScheduleItem, ScheduleItem} from './types'
import {adjustCronToLocal, adjustCronStartDateToLocal, adjustCronToServer, adjustCronStartDateToServer} from './schedule/utils'

export const backendScheduleItemToScheduleItem = (backendItem: BackendScheduleItem, index: number): ScheduleItem => ({
  ...backendItem,
  task: {
    ...backendItem.task,
    cronExpression: adjustCronToLocal(backendItem.task.cronExpression),
    rruleExpression: backendItem.task.rruleExpression,
    // when returned from API startDate will be JSON string
    startDate: adjustCronStartDateToLocal(
      typeof backendItem.task?.startDate === 'string' ?
        new Date(backendItem.task.startDate) :
        (backendItem.task?.startDate || new Date()),
      backendItem.task.rruleExpression ? backendItem.task.rruleExpression : backendItem.task.cronExpression
    ),
  },
  id: String(index),
})

export const scheduleItemToBackendItem = (scheduleItem: ScheduleItem): BackendScheduleItem => {
  // destructuring used to remove id from item for backend
  // eslint-disable-next-line no-unused-vars
  const {id, ...backendItem} = scheduleItem
  const expr = backendItem.task.rruleExpression ? backendItem.task.rruleExpression : backendItem.task.cronExpression
  return {
    ...backendItem,
    task: {
      ...backendItem.task,
      startDate: adjustCronStartDateToServer(backendItem.task.startDate,
        expr),
      cronExpression: adjustCronToServer(backendItem.task.cronExpression),
      rruleExpression: backendItem.task.rruleExpression,
    },
  }
}
