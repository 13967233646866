import styled from 'styled-components'
import {secondaryHeader, background, primary} from '../colors'

export const App = styled.div`
    display: flex;
    min-height: ${(props: {windowHeight?: number, withoutBottomBar?: boolean}) => props.windowHeight ? `${props.windowHeight}px` : '100vh'};
    padding-bottom: ${(props: {windowHeight?: number, withoutBottomBar?: boolean}) => props.withoutBottomBar ? `0` : '56px'};
    background-color: ${background};
    
    .MuiStepIcon-root.MuiStepIcon-completed,
    .MuiStepIcon-root.MuiStepIcon-active {
      color: ${primary};
    }
    
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
      font-size: 0.75rem;
      margin-top: 8px;
    }
    
    .MuiFormLabel-root.Mui-focused {
      color: ${primary};
    }
    
    .MuiInput-underline:after {
      border-color: ${primary};
    }
`

export const Content = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 16px;
    transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    
    > .toolbar-spacer {
      margin-top: -16px;
    }
`

export const DrawerSectionTitle = styled.div`
    color: ${secondaryHeader};
    padding: 8px 16px 0 16px;
    font-weight: 600;
    font-size: 0.75rem;
`
