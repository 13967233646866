import React from 'react'
import styled from 'styled-components'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {ErrorIconOutline, getSeverity, Image, ServerImage, SuccessIconOutline} from 'components'
import {alwaysSingleLocationEvents, DiagnosticEvent, DiagnosticReason, Locations} from '../types'
import {getFromLocation, getEventPhotoUrl, getLocationTranslation, getToLocation} from '../utils'
import {darkText, warning, white} from '../../../colors'

interface Props {
  event: DiagnosticEvent
  locations: Locations
  siteId: string
  robotId: string
  skippedImageUrl: string
  setSelectedEvent: (eventId: string) => void
}

const Event = styled.div`
  position: relative;
  background-color: #eeeeee;
  padding: 4px;
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
`

const StyledServerImage = styled(ServerImage)`
  height: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  user-select: none;
`

const StyledImage = styled(Image)`
  height: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  user-select: none;
  background: white;
`

const ToIcon = styled(ArrowBackIcon)`
  color: white;
  font-size: 1.25rem!important;
  transform: rotate(180deg);
  margin-left: 24px;
  margin-right: 3px;
  
  &:last-child {
    margin-left: 3px;
  }
`

interface LocationWrapperProps {
  isError: boolean
  isSkipped: boolean
  isTop?: boolean
}

const LocationWrapper = styled.div`
  position: absolute;
  font-weight: bolder;
  font-size: 0.75rem;
  background: ${(props: LocationWrapperProps) => {
    if (props.isSkipped) {
      return warning
    }

    return props.isError ? 'rgba(229,37,37, 0.5)' : 'rgba(76,175,80, 0.5)'
  }};
  color: ${(props: LocationWrapperProps) => props.isSkipped ? darkText : white};
  padding: ${(props: LocationWrapperProps) => props.isSkipped ? '2px 2px 2px 6px' : '2px'};
  left: 4px;
  right: 4px;
  pointer-events: none;
  user-select: none;
  display: flex;
  align-items: center;
  min-height: 28px;

  ${(props: LocationWrapperProps) => {
    return props.isTop ? 'top: 4px;' : 'bottom: 4px;'
  }}
`

const Location = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SuccessIconOutlineStyled = styled(SuccessIconOutline)`
  color: ${white};
  margin-right: 3px;
  font-size: 24px!important;
`

const ErrorIconOutlineStyled = styled(ErrorIconOutline)`
  color: ${white};
  margin-right: 3px;
  font-size: 24px!important;
`

const OngoingPatrolEventsImageDisplay = ({
  event,
  robotId,
  siteId,
  setSelectedEvent,
  locations,
  skippedImageUrl,
}: Props) => {
  const toLocation = getToLocation(event)
  const fromLocation = getFromLocation(event)
  const fromLocationTranslation = getLocationTranslation(locations, fromLocation) || fromLocation
  const toLocationTranslation = toLocation === fromLocationTranslation ?
    fromLocationTranslation :
    (getLocationTranslation(locations, toLocation) || toLocation)
  const hasBothLocations = Boolean(toLocation && fromLocation && fromLocation !== toLocation) &&
    !alwaysSingleLocationEvents.includes(event.reason)
  const isError = getSeverity(event.reason) === 'error'
  const isSkipped = event.reason === DiagnosticReason.POINT_SKIPPED
  const icon = isError ? <ErrorIconOutlineStyled /> : <SuccessIconOutlineStyled />
  return (
    <Event
      onClick={() => setSelectedEvent(event.notificationUid)}
    >
      {isSkipped && !event.hasPhoto ? (
        <StyledImage src={skippedImageUrl} />
      ) : (
        <StyledServerImage
          src={getEventPhotoUrl(siteId, robotId, event.notificationUid)}
        />
      )}
      {fromLocationTranslation && (
        <LocationWrapper
          isError={isError}
          isSkipped={isSkipped}
          isTop={hasBothLocations}
        >
          {!isSkipped && icon}
          <Location>
            {fromLocationTranslation}
          </Location>
          {hasBothLocations && <ToIcon />}
        </LocationWrapper>
      )}
      {hasBothLocations && (
        <LocationWrapper
          isError={isError}
          isSkipped={isSkipped}
        >
          <ToIcon />
          <Location>
            {toLocationTranslation}
          </Location>
        </LocationWrapper>
      )}
    </Event>
  )
}

export default OngoingPatrolEventsImageDisplay
