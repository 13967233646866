import {createSelector} from 'reselect'
import {emptyObject, REVERSE_ROOM_ORDER_KEY, REVERSE_ROOM_ORDER_VALUE} from 'utils'
import {i18n} from 'locale'
import {Store} from 'types'
import {getLocationInterface, getStateMachine} from '../navigation/selectors'
import {getParentIfSameForAllLocations, isSanitizingRoom} from './utils'
import {StateMachineGroup} from '../navigation/types'
import {moduleName} from './constants'
import {getLocationParts, getLocationTranslation} from '../navigation/utils'

export const getTitleOverrides = createSelector(
  (state: Store) => state.app.selectedRobot?.modules?.current,
  (state: Store) => state.nsp.siteSettings.locations,
  (state: Store) => getStateMachine(state)?.stateGroup,
  (state: Store) => getLocationInterface(state)?.nextLocationUid,
  (state: Store) => state.app.selectedRobot?.diagnosticData?.wauvRoomOperation?.locationsForCurrentPatrol?.join(';'),
  (module, locations, stateGroup, nextLocationUid, locationsForCurrentPatrol) => {
    const isSanitizing = isSanitizingRoom(module, stateGroup, nextLocationUid, locationsForCurrentPatrol)
    if (isSanitizing) {
      return {
        warningKey: 'wauv Nav_Status sanitization warning',
      }
    }

    const isReverseRoomOrder = i18n.t(REVERSE_ROOM_ORDER_KEY) === REVERSE_ROOM_ORDER_VALUE
    const parent = getParentIfSameForAllLocations(locations, (locationsForCurrentPatrol || '').split(';'))
    if (stateGroup === StateMachineGroup.PATROLLING && module === moduleName && parent) {
      let parentLocations = getLocationParts(locations, locations[parent]?.parentId)
      if (isReverseRoomOrder) {
        parentLocations = parentLocations.reverse()
      }

      return {
        titleKey: 'wauv Nav_Status title patrol status title navigating',
        titleParams: {
          roomName: getLocationTranslation(locations, parent),
        },
        descriptionKey: parentLocations.length > 0 ? 'wauv Nav_Status title patrol status description navigating' : ' ',
        descriptionParams: {
          location: parentLocations.join(', '),
        },
      }
    }

    return emptyObject
  }
)
