import RoomIcon from '@material-ui/icons/Room'
import {ThunkDispatch} from 'redux-thunk'
import AbstractModule from '../AbstractModule'
import getNavigationModule from '../navigation'
import {registerHook} from 'framework'
import {NAV_HOOK_LABELS} from 'modules/navigation/constants'
import {ExtendHistoryListTabsCallback, ExtendReportDetailsTabsCallback} from 'modules/navigation/types'
import {ReportDetailsRooms, NspHistoryListContainer} from './report'
import {moduleName} from './constants'

const defaultHandlerLabel = 'nsp'

const extendReportTabs: ExtendReportDetailsTabsCallback = context => {
  if (context.modules.includes(moduleName)) {
    context.tabs.push({
      titleKey: 'Nav_Report tab rooms',
      iconComponent: RoomIcon,
      component: ReportDetailsRooms,
    })
  }
}
const extendHistoryListTabs: ExtendHistoryListTabsCallback = context => {
  if (context.configModules.includes(moduleName)) {
    context.tabs.push({
      titleKey: `use-case ${moduleName}`,
      component: NspHistoryListContainer,
    })
  }
}

class NSPModule extends AbstractModule {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  installModule(dispatch: ThunkDispatch<any, any, any>) {
    getNavigationModule().run(dispatch)
    registerHook(NAV_HOOK_LABELS.REPORT.EXTEND_DETAILS_TABS, defaultHandlerLabel, extendReportTabs)
    registerHook(NAV_HOOK_LABELS.REPORT.GET_HISTORY_TABS, defaultHandlerLabel, extendHistoryListTabs)
  }
}

let mod: NSPModule | undefined
const getModule = () => {
  if (!mod) {
    mod = new NSPModule(moduleName)
  }

  return mod
}

export default getModule
