import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {ThunkDispatch} from 'redux-thunk'
import {initReactI18next} from 'react-i18next'
import Backend, {RequestCallback} from 'i18next-http-backend'
import moment from 'moment'
// load all available locales - ~250kB so it's quite a lot but then it's all cached on the device
import 'moment/min/locales.min'
import './customMomentLocales'
import {getResponseBody, httpGet} from 'utils'
import {Storage} from 'platform'
import {setSupportedLanguages} from '../app/actions'
import {SITE_ID_LOCAL_STORAGE_KEY} from '../app/constants'

const languagesStorageKey = 'supportedLanguages'
const browserLanguagesStorageKey = 'browserLanguages'
const i18nextSelectedLanguageLocalStorageKey = 'i18nextLng'

let supportedLanguages = ['en', 'ja']
export const fallbackLanguage = 'en'

let previousSiteId = ''
let latestSiteId = ''

const getLanguageCodes = (i18Language: string): string[] => {
  const result: string[] = []
  const lowerI18Language = i18Language
  let keyFromResources = supportedLanguages.find(key => key === lowerI18Language)
  if (keyFromResources) {
    result.push(keyFromResources)
  }

  // for example for `en-US` it'd be just `en`
  const simplifiedKey = lowerI18Language.split('-')[0].toLowerCase()
  keyFromResources = supportedLanguages.find(key => key === simplifiedKey)
  if (keyFromResources) {
    result.push(keyFromResources)
  }

  if (!result.includes(fallbackLanguage)) {
    result.push(fallbackLanguage)
  }

  return result
}

// @ts-ignore
window.isLoadingLanguage = true

const setMomentLanguage = (languageToSet: string) => {
  let language = languageToSet
  if (language === 'zh') {
    language = 'zh-Aeolus'
  }
  if (language === 'ja') {
    language = 'ja-Aeolus'
  }
  moment.locale(language)
}

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: fallbackLanguage,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: async (languages) => {
        let siteId = await Storage.getItem(SITE_ID_LOCAL_STORAGE_KEY)
        if (!siteId || siteId === 'null') {
          siteId = ''
        }

        previousSiteId = latestSiteId
        latestSiteId = siteId

        return `config/translations?lang=${languages[0]}&siteId=${siteId}`
      },
      parse: function(data: string) {
        // @ts-ignore
        window.languageLoaded = true
        return JSON.parse(data).translation
      },
      crossDomain: true,
      withCredentials: true,
      request: async function(options: any, url: string, payload: any, callback: RequestCallback) {
        try {
          const response = await httpGet(url, true)
          const data = await response.json()
          const dataAsString = JSON.stringify(data)
          if (url.includes(`lang=${getLanguageCodes(i18next.language)[0]}`)) {
            // save only current language to localStorage, without it we got both fallback language and "wanted" language
            //    and fallback could override "wanted" language
            await Storage.setItem('translations', dataAsString)
          }

          callback(null, {status: response.status, data: dataAsString})
        } catch (error) {
          const dataAsString = await Storage.getItem('translations') || ''
          callback(null, {status: dataAsString ? 200 : 400, data: dataAsString})
        }

        if (previousSiteId !== latestSiteId && i18next.language) {
          // refreshes UI if translations for different site were fetched
          await i18next.changeLanguage(i18next.language)
        }
        // @ts-ignore
        window.isLoadingLanguage = false
      },
    },
    react: {
      bindI18n: 'languageChanged',
      // @ts-ignore
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
      useSuspense: process.env.NODE_ENV !== 'test',
    },
  }, () => {
    const language = getLanguageCodes(i18next.language)[0]
    setMomentLanguage(language)
  })

i18next.services.pluralResolver.addRule('ja', {
  numbers: [
    1,
    2,
  ],
  plurals: function(n: number) {
    return Number(n > 1)
  },
})

i18next.services.pluralResolver.addRule('zh', {
  numbers: [
    1,
    2,
  ],
  plurals: function(n: number) {
    return Number(n > 1)
  },
})

i18next.on('languageChanged', newLanguage => {
  const language = getLanguageCodes(newLanguage)[0]
  setMomentLanguage(language)
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initLanguages = async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    let shouldCleari18nextLanguage = false
    const browserLanguages = JSON.stringify(window.navigator.languages)
    const [browserLanguagesBefore, availableLanguagesBefore] = await Promise.all([
      Storage.getItem(browserLanguagesStorageKey),
      Storage.getItem(languagesStorageKey),
    ])

    if (browserLanguagesBefore && browserLanguages !== browserLanguagesBefore) {
      shouldCleari18nextLanguage = true
    }

    const languagesResponse = await httpGet('config/languages')
    if (languagesResponse.status < 300) {
      const responseBody = await getResponseBody(languagesResponse)
      const languages = responseBody.languages || []
      if (languages.length > 0) {
        supportedLanguages = languages
      }

      const stringifiedLanguages = JSON.stringify(supportedLanguages)
      if (availableLanguagesBefore !== stringifiedLanguages) {
        shouldCleari18nextLanguage = true
      }

      await Storage.setItem(languagesStorageKey, stringifiedLanguages)
    } else {
      if (availableLanguagesBefore) {
        supportedLanguages = JSON.parse(availableLanguagesBefore)
      }
    }

    await Storage.setItem(browserLanguagesStorageKey, browserLanguages)
    dispatch(setSupportedLanguages(supportedLanguages))

    if (shouldCleari18nextLanguage) {
      await Storage.removeItem(i18nextSelectedLanguageLocalStorageKey)
      // we cannot reinitialize i18next and we want it to use it's own browser language detection
      // thus page reload is necessary
      window.location.reload()
    }
  } catch (error) {
    console.error(error)
  }

  // @ts-ignore
  window.modulesInitialized = true
}

export {
  getLanguageCodes,
  initLanguages,
}

export default i18next

// @ts-ignore
window.i18next = i18next
