/* eslint-disable max-len */
import React from 'react'
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon'

const ErrorIconOutline = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 14.1875V11.2708C15.5 9.48 14.5492 7.98083 12.875 7.58417V7.1875C12.875 6.70333 12.4842 6.3125 12 6.3125C11.5158 6.3125 11.125 6.70333 11.125 7.1875V7.58417C9.45667 7.98083 8.50001 9.47417 8.50001 11.2708V14.1875L7.33334 15.3542V15.9375H16.6667V15.3542L15.5 14.1875ZM12 17.6875C12.6417 17.6875 13.1667 17.1625 13.1667 16.5208H10.8333C10.8333 17.1625 11.3583 17.6875 12 17.6875ZM9.66667 14.7708H14.3333V11.2708C14.3333 9.82417 13.4525 8.64583 12 8.64583C10.5475 8.64583 9.66667 9.82417 9.66667 11.2708V14.7708ZM9.42167 7.23416L8.58751 6.39999C7.18751 7.46749 6.26584 9.11249 6.18417 10.9792H7.35084C7.43834 9.43332 8.23167 8.07999 9.42167 7.23416ZM17.8158 10.9792H16.6492C16.5617 9.43332 15.7625 8.07999 14.5842 7.23416L15.4125 6.39999C16.8067 7.46749 17.7283 9.11249 17.8158 10.9792Z"
    />
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
    />
  </SvgIcon>
)

export default ErrorIconOutline
