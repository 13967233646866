const ACTION_PREFIX = 'dashboard/'

export const FETCH_LOCKS = `${ACTION_PREFIX}FETCH_LOCKS`
export const FETCH_LOCKS_DONE = `${ACTION_PREFIX}FETCH_LOCKS_DONE`

export const OPEN_LOCKS_MODAL = `${ACTION_PREFIX}OPEN_LOCKS_MODAL`

export const SET_LOCK = `${ACTION_PREFIX}SET_LOCK`

export const OPEN_GRIPPER_AUTOCAL_MODAL = `${ACTION_PREFIX}OPEN_GRIPPER_AUTOCAL_MODAL`
