import React, {useCallback, useEffect} from 'react'
import styled from 'styled-components'
import {Dialog, DialogContent, DialogTitle, Button} from 'components'
import * as Styled from './StepsStyled'
import LimitFindingDetailedErrors from './LimitFindingDetailedErrors'
import {primary, white} from '../../colors'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'
interface Props {
  batteryTooLow: boolean
  canDebug?: boolean
  open: boolean
  isRerunnable: boolean
  runLimitFinding?: () => void
  close: () => void
}

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 100px;
  display: flex;
  margin: 0 auto;
`

const LimitFindingErrorModal: React.FC<Props> = ({
  open,
  canDebug,
  isRerunnable,
  runLimitFinding,
  close,
  batteryTooLow,
}) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)

  // if battery too low below calibration threshold -> redirect
  useEffect(() => {
    if (open && batteryTooLow) {
      close()
    }
  }, [open, batteryTooLow])

  const closeErrorModal = useCallback(() => {
    if (canDebug && close) {
      close()
    }
  }, [canDebug, close])

  return (
    <Dialog
      open={open}
      onClose={closeErrorModal}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <DialogTitle>
          <Styled.StepTitle>
            {t('PowerOn_CalibrationWizard title power on calibration failed title')}
          </Styled.StepTitle>
        </DialogTitle>
        <Styled.StepInfo withBottomMargin>
          {t(isRerunnable ?
            'PowerOn_CalibrationWizard content power on calibration failed rerunnable warning' :
            'PowerOn_CalibrationWizard content power on calibration failed warning')}
        </Styled.StepInfo>
        <LimitFindingDetailedErrors />
        {isRerunnable && runLimitFinding && (
          <StyledButton
            $backgroundColor={primary}
            $textColor={white}
            onClick={runLimitFinding}
          >
            {t('PowerOn_CalibrationWizard button power on calibration failed rerunnable button')}
          </StyledButton>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default LimitFindingErrorModal
