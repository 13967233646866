export const primary = '#00b6ff'
export const primaryLight = '#def6ff'
export const secondary = '#2196f3'
export const darkText = '#333333'
export const defaultText = '#545454'
export const lightText = '#9a9a9a'
export const info = '#2196f3'
export const error = '#993b04'
export const errorTitle = '#c00000'
export const errorIcon = '#e52525'
export const errorIconLight = '#f9b1b6'
export const errorBorder = '#e52525'
export const cautionBorder = '#f4c725'
export const secondaryHeader = '#868686'
export const success = '#4caf50'
export const warning = '#f4c725'
export const darkButton = '#545454'
export const disabledButton = '#bfbfbf'
export const background = '#ffffff'
export const black = '#000000'
export const white = '#ffffff'
export const lightestGray = '#f3f3f3'
export const lightGray = '#e6e6e6'
export const grayText = '#a0a0a0'
export const gray = '#c7c7c7'
export const lightButton = '#c7c7c7'
export const blueButton = '#00b6ff'
export const blueButtonSelected = '#0096D2'
export const grayBackground = '#D9D6D6'
