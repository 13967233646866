import React, {useState, useEffect} from 'react'
import useDimensions from 'react-use-dimensions'
import styled from 'styled-components'
import {refreshPageOnUpdateTimeout} from 'config'
import moment from 'moment'
import {white, darkButton} from '../colors'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  updateAvailable: boolean
}

const Content = styled.div`
  position: absolute;
  z-index: 999999;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px 16px;
  text-align: center;
  background-color: ${darkButton};
  color: ${white};
`

const AppUpdate = ({updateAvailable}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [showInfoStartTime, setShowInfoStartTime] = useState<Date | null>(null)
  const [useDimensionsRef, dimensions] = useDimensions()
  const [, setCounter] = useState(0)
  const appElement = document.getElementById('app')

  useEffect(() => {
    if (updateAvailable && !showInfoStartTime) {
      setShowInfoStartTime(new Date())
      setInterval(() => {
        setCounter(counter => counter + 1)
      }, 1000)
    }
  }, [showInfoStartTime, updateAvailable])

  if (!showInfoStartTime) {
    return null
  }

  const totalSeconds = Math.round(refreshPageOnUpdateTimeout / 1000)
  const dateDiffInSeconds = moment().diff(moment(showInfoStartTime), 'seconds')
  const secondsLeft = totalSeconds - dateDiffInSeconds
  if (secondsLeft < 0) {
    window.location.reload()
  }

  if (appElement && dimensions && dimensions.height) {
    appElement.style.paddingBottom = `${dimensions.height}px`
  }

  return (
    <Content ref={useDimensionsRef}>
      {t('Common page update available', {count: Math.max(secondsLeft, 0)})}
    </Content>
  )
}

export default AppUpdate
