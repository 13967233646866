const ACTION_PREFIX = 'app/'

export const SET_WINDOW_DIMENSIONS = `${ACTION_PREFIX}SET_WINDOW_DIMENSIONS`

export const NAVIGATION = `${ACTION_PREFIX}NAVIGATION`

export const SET_SUPPORTED_LANGUAGES = `${ACTION_PREFIX}SET_SUPPORTED_LANGUAGES`

export const UPDATE_AVAILABLE = `${ACTION_PREFIX}UPDATE_AVAILABLE`

export const SET_CHILD_MODULE = `${ACTION_PREFIX}SET_CHILD_MODULE`

export const APP_INITIALIZE = `${ACTION_PREFIX}APP_INITIALIZE`

export const FETCH_PERMISSIONS = `${ACTION_PREFIX}FETCH_PERMISSIONS`
export const SET_PERMISSIONS = `${ACTION_PREFIX}SET_PERMISSIONS`

export const SET_PAGE_OPTIONS = `${ACTION_PREFIX}SET_PAGE_OPTIONS`
export const SET_SELECTED_ROBOT_ID = `${ACTION_PREFIX}SET_SELECTED_ROBOT_ID`
export const SET_SELECTED_ROBOT = `${ACTION_PREFIX}SET_SELECTED_ROBOT`

export const SET_SERVER_CONNECTION_STATUS = `${ACTION_PREFIX}SET_SERVER_CONNECTION_STATUS`
export const SET_DEVICE_ONLINE_STATUS = `${ACTION_PREFIX}SET_DEVICE_ONLINE_STATUS`

export const FETCH_API_VERSION = `${ACTION_PREFIX}FETCH_API_VERSION`
export const SET_API_VERSION = `${ACTION_PREFIX}SET_API_VERSION`

export const FETCH_ROBOTS = `${ACTION_PREFIX}FETCH_ROBOTS`
export const FETCH_ROBOTS_SUCCESS = `${FETCH_ROBOTS}_SUCCESS`
export const FETCH_ROBOTS_ERROR = `${FETCH_ROBOTS}_ERROR`

export const ROBOT_UPDATED = `${ACTION_PREFIX}ROBOT_UPDATED`

export const INCREMENT_OFFLINE_COUNTER = `${ACTION_PREFIX}INCREMENT_OFFLINE_COUNTER`
export const SET_OFFLINE_ROBOTS = `${ACTION_PREFIX}SET_OFFLINE_ROBOTS`

export const ADD_SNACKBAR_MESSAGE = `${ACTION_PREFIX}ADD_SNACKBAR_MESSAGE`
export const REMOVE_SNACKBAR_MESSAGE = `${ACTION_PREFIX}REMOVE_SNACKBAR_MESSAGE`

export const DOWNLOAD_FILE = `${ACTION_PREFIX}DOWNLOAD_FILE`

export const OPEN_ROBOT_SELECTOR = `${ACTION_PREFIX}OPEN_ROBOT_SELECTOR`
export const CLOSE_ROBOT_SELECTOR = `${ACTION_PREFIX}CLOSE_ROBOT_SELECTOR`

export const OPEN_ROBOT_EDIT = `${ACTION_PREFIX}OPEN_ROBOT_EDIT`
export const CLOSE_ROBOT_EDIT = `${ACTION_PREFIX}CLOSE_ROBOT_EDIT`

export const SET_SITE_ID = `${ACTION_PREFIX}SET_SITE_ID`
export const INITIALIZE_SITE_ID = `${ACTION_PREFIX}INITIALIZE_SITE_ID`

export const SITE_ID_LOCAL_STORAGE_KEY = 'site_id'

export const TRIGGER_BUG_REPORT_DATA_COLLECTION = `${ACTION_PREFIX}TRIGGER_BUG_REPORT_DATA_COLLECTION`
export const CANCEL_BUG_REPORT_DATA_COLLECTION = `${ACTION_PREFIX}CANCEL_BUG_REPORT_DATA_COLLECTION`
export const SET_BUG_REPORT_UID = `${ACTION_PREFIX}SET_BUG_REPORT_UID`
export const SET_BUG_REPORT_FILES = `${ACTION_PREFIX}SET_BUG_REPORT_FILES`
export const SET_BUG_REPORT_DATA = `${ACTION_PREFIX}SET_BUG_REPORT_DATA`
export const SAVE_BUG_REPORT = `${ACTION_PREFIX}SAVE_BUG_REPORT`
export const BUG_REPORT_SERVER_DONE = `${ACTION_PREFIX}BUG_REPORT_SERVER_DONE`
export const SAVE_BUG_REPORT_FINISHED = `${ACTION_PREFIX}SAVE_BUG_REPORT_FINISHED`

export const ADD_ANNOUNCEMENT = `${ACTION_PREFIX}ADD_ANNOUNCEMENT`
export const REMOVE_ANNOUNCEMENT = `${ACTION_PREFIX}REMOVE_ANNOUNCEMENT`

export const OPEN_SECONDARY_NAV = `${ACTION_PREFIX}OPEN_SECONDARY_NAV`
export const CLOSE_SECONDARY_NAV = `${ACTION_PREFIX}CLOSE_SECONDARY_NAV`

export const SET_GUI_USE_CASE = `${ACTION_PREFIX}SET_GUI_USE_CASE`
export const AIRPORT_GUI_USE_CASE = `airport`
