/* eslint-disable quote-props */
import {connect} from 'react-redux'
import {Robot, Store} from 'types'
import {StatusImage} from '../NSPCommonStyled'
import {getStateMachine} from '../selectors'
import {StateMachineGroup} from '../types'
import {showStaticImageSize} from './utils'
import {executeHooks} from '../../../framework'
import {DEFAULT_STATE_MACHINE_GROUP_IMAGE, NAV_HOOK_LABELS, STATE_MACHINE_GROUP_TO_IMAGE} from '../constants'

const getImageForPatrol = (stateGroup?: StateMachineGroup, robot?: Robot | null) => {
  const hookResult = executeHooks(NAV_HOOK_LABELS.PATROL.IMAGE, {robot, stateGroup})
  if (hookResult.image) {
    return hookResult.image
  }

  const specificImage = stateGroup ?
    STATE_MACHINE_GROUP_TO_IMAGE[stateGroup] :
    undefined
  return specificImage || DEFAULT_STATE_MACHINE_GROUP_IMAGE
}

const mapStateToProps = (state: Store) => {
  const stateGroup = getStateMachine(state)?.stateGroup
  const shouldShowStaticImage = showStaticImageSize(stateGroup)
  const image = getImageForPatrol(stateGroup, state.app.selectedRobot)
  return {
    'data-testid': 'patrol-image',
    'data-testimage': image,
    image,
    staticImage: shouldShowStaticImage,
    forceFlex: shouldShowStaticImage && !state.nsp.isShowingPreconditions,
  }
}

export default connect(mapStateToProps)(StatusImage)
