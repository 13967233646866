import {AnyAction, combineReducers} from 'redux'
import {ReminderDisplayItem} from 'types'
import * as constants from './constants'

const isSaving = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.SAVE_REMINDER_SETTINGS:
      return true
    case constants.SAVE_REMINDER_SETTINGS_DONE:
      return false
    default:
      return state
  }
}

const remindersToShow = (state: ReminderDisplayItem[] = [], action: AnyAction): ReminderDisplayItem[] => {
  switch (action.type) {
    case constants.SHOW_PATROL_REMINDER:
      return [
        // remove old reminders with same data
        ...state.filter(item => !(
          item.siteId === action.siteId &&
          item.robotId === action.robotId &&
          item.reminderName === action.reminderName
        )),
        {
          siteId: action.siteId,
          robotId: action.robotId,
          reminderName: action.reminderName,
        },
      ]
    case constants.CLOSE_PATROL_REMINDER:
      return state.filter(item => (
        item.siteId !== action.siteId &&
        item.robotId !== action.robotId &&
        item.reminderName !== action.reminderName
      ))
    default:
      return state
  }
}

export default combineReducers({
  remindersToShow,
  isSaving,
})
