import {connect} from 'react-redux'
import {Store} from 'types'
import {addSnackbarMessage} from 'app/actions'
import PatrolEventModal from './PatrolEventModal'
import {markEventAsViewed} from '../actions'
import {getCurrentCheckedEvent} from './selectors'

const mapStateToProps = (state: Store) => ({
  event: getCurrentCheckedEvent(state),
  locations: state.nsp.siteSettings.locations,
  siteId: state.app.selectedRobot?.siteId,
  robotId: state.app.selectedRobot?.id,
})

const mapDispatchToProps = {
  markEventAsViewed,
  addSnackbarMessage,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: {
    markEventAsViewed: (siteId: string, robotId: string, eventId: string) => void
    addSnackbarMessage: (message: string, type?: string, autoHideDuration?: number, action?: any) => void
  }
) => ({
  ...stateProps,
  addSnackbarMessage: (message: string, action: any) => dispatchProps.addSnackbarMessage(message, undefined, undefined, action),
  close: () => {
    if (stateProps.siteId && stateProps.robotId && stateProps.event) {
      dispatchProps.markEventAsViewed(stateProps.siteId, stateProps.robotId, stateProps.event.notificationUid)
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PatrolEventModal)
