import React from 'react'
import styled from 'styled-components'
import {Loader, SuccessIcon, WarningIcon, ErrorIcon} from 'components'
import {warning} from '../../colors'
import {Severity} from './types'
import {PatrolEventLevel} from '../../modules/navigation/types'

interface Props {
    level?: PatrolEventLevel
    severity?: Severity
}

const SuccessIconStyled = styled(SuccessIcon)`
    font-size: 36px!important;
`

const WarningIconStyled = styled(WarningIcon)`
    color: ${warning};
    flex-shrink: 0;
    font-size: 36px!important;
`

const ErrorIconStyled = styled(ErrorIcon)`
    font-size: 36px!important;
`

const StatusIcon = ({
  level,
  severity,
}: Props) => {
  if (!severity && !level) {
    return <Loader size={30} />
  }

  if (severity === 'error' || level === PatrolEventLevel.URGENT) {
    return <ErrorIconStyled />
  }

  if (severity === 'warning' || level === PatrolEventLevel.CAUTION) {
    return <WarningIconStyled />
  }

  return <SuccessIconStyled />
}

export default StatusIcon
