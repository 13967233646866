import {connect} from 'react-redux'
import {Store} from 'types'
import {AnnouncementDisplay} from 'components'
import {getAnnouncements} from './selectors'

const mapStateToProps = (state: Store) => ({
  announcements: getAnnouncements(state),
})

export default connect(mapStateToProps)(AnnouncementDisplay)
