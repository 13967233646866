import React from 'react'
import {
  FeatureToggle,
  GoToElementIcon,
  ListItem,
  ListItemText,
} from 'components'
import {useDispatch} from 'react-redux'
import {useModuleAwareTranslation} from 'utils'
import {toggleGetStatisticsModal} from '../actions'
import {PERMISSIONS} from '../../../../constants'

const ExportHistorySetting = () => {
  const {t} = useModuleAwareTranslation()
  const dispatch = useDispatch()

  return (
    <FeatureToggle permissionName={PERMISSIONS.PATROL.GET_STATS}>
      <ListItem
        fullWidth
        bolder
        clickable
        onClick={() => dispatch(toggleGetStatisticsModal())}
      >
        <ListItemText>{t('Nav_SiteSettings other export history')}</ListItemText>
        <GoToElementIcon />
      </ListItem>
    </FeatureToggle>
  )
}

export default ExportHistorySetting
