import React from 'react'
import moment from 'moment'
import {GenericEventDisplay} from 'components'
import {DiagnosticEvent, DiagnosticReason, Locations} from '../types'
import {getDateAndTimeFormatForMoment, REVERSE_ROOM_ORDER_KEY, REVERSE_ROOM_ORDER_VALUE} from 'utils'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  event: DiagnosticEvent
  eventLocationAsString?: string
  nextLocationAsString?: string
  robotId: string
  userId?: string
  locations: Locations
}

const getLocationText = (isReverseOrder: boolean, locationAsString?: string) => {
  if (!locationAsString) {
    return ''
  }

  return isReverseOrder ?
    locationAsString.split(';').reverse().join(', ') :
    locationAsString.split(';').join(', ')
}

const MultipleEventsModalItem = ({
  event,
  eventLocationAsString,
  nextLocationAsString,
  robotId,
  locations,
  userId,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const hasLocation = nextLocationAsString || eventLocationAsString
  const dateAndTimeFormat = getDateAndTimeFormatForMoment(userId)
  const time = t('Nav_Event content patrol event time', {date: moment(event.date).format(dateAndTimeFormat)})
  let location = ''
  if (hasLocation) {
    const isReverseOrder = t(REVERSE_ROOM_ORDER_KEY) === REVERSE_ROOM_ORDER_VALUE
    const locationStringToUse = [DiagnosticReason.POINT_DONE, DiagnosticReason.POINT_SKIPPED].includes(event.reason) ?
      eventLocationAsString?.substring(0, eventLocationAsString?.lastIndexOf(';')) :
      eventLocationAsString
    const room = getLocationText(isReverseOrder, locationStringToUse)
    location = room
    if (nextLocationAsString) {
      const roomB = getLocationText(isReverseOrder, nextLocationAsString)
      location = t('Nav_Event content patrol event between', {
        room: room || locationStringToUse,
        roomB: roomB || nextLocationAsString,
      })
    }

    if (!location) {
      location = locationStringToUse || ''
    }
  }

  const customDescription = [
    location,
    time,
  ].filter(Boolean).join('\n')

  return (
    <GenericEventDisplay
      locations={locations}
      event={{
        ...event,
        robotId,
      }}
      options={{
        customDescription: customDescription,
        fullImage: true,
        noTimeAgo: true,
        noTitleColor: true,
      }}
    />
  )
}

export default MultipleEventsModalItem
