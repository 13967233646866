import {buildAction} from 'utils'
import * as constants from './constants'

export const saveReminderSettings = buildAction(constants.SAVE_REMINDER_SETTINGS, 'reminderName', 'reminderValue')
export const saveReminderSettingsDone = buildAction(constants.SAVE_REMINDER_SETTINGS_DONE)

export const showPatrolReminder = buildAction(constants.SHOW_PATROL_REMINDER, 'siteId', 'robotId', 'reminderName')
export const closePatrolReminder = buildAction(constants.CLOSE_PATROL_REMINDER, 'siteId', 'robotId', 'reminderName')

export const markReminderAsViewed = buildAction(constants.MARK_REMINDER_AS_VIEWED, 'siteId', 'robotId', 'reminderName')
export const patrolReminderViewed = buildAction(constants.PATROL_REMINDER_VIEWED, 'siteId', 'robotId', 'reminderName')
