import React, {SyntheticEvent, useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {Button, TextButton, FeatureToggle, Loader} from 'components'
import {useAppToolbarHeight, useModuleAwareTranslation} from 'utils'
import {blueButton, grayText, secondary, white} from '../../colors'
import {PERMISSIONS} from '../../constants'
import {BUG_REPORT_FORM, FEATURE_REQUEST_FORM} from './constants'
import BugReportFormItem from './BugReportFormItem'
import BugReportFilesContainer from './BugReportFilesContainer'
import {getItemKey} from './utils'

const Form = styled.form`
display: flex;
flex: 1;
flex-direction: column;
padding: 16px 0 0 0;
`

const FormContent = styled.div`
display: flex;
flex: 1;
flex-direction: column;
`

const StyledLoader = styled(Loader)`
margin-top: 32px;
`

const SavingOverlay = styled.div`
position: fixed;
width: 100vw;
left: 0;
bottom: 0;
background: rgba(255, 255, 255, 0.67);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 16px;
height: ${(props: {toolbarHeight: number}) => `calc(100vh - ${props.toolbarHeight}px)`};
`

const SavingText = styled.div`
  font-weight: 500;
`

const StyledRadioGroup = styled(RadioGroup)`
margin-bottom: 16px;

.MuiFormControlLabel-root {
  margin-right: 24px;
  
  &:last-child {
    margin-right: 0;
  }
}

.MuiFormControlLabel-label {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
}

.Mui-checked + .MuiFormControlLabel-label {
  color: ${secondary};
}
`

const FormActions = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
`

const Disclaimer = styled.div`
margin: 12px 0;
color: ${grayText};
`

enum TOGGLE_BUTTON_VALUES {
  BUG_REPORT = 'bugReport',
  NEW_FEATURE = 'newFeature'
}

interface Props {
  isSavingBugReport: boolean
  triedToFetchRobots: boolean
  robotId?: string
  siteId?: string
  bugReportData: {[field: string]: string | boolean}
  setBugReportData: (data: {}) => void
  saveBugReport: (siteId: string, robotId: string, onSuccess: Function) => void
  cancelBugReportDataCollection: (siteId: string, robotId: string) => void
  triggerBugReportDataCollection: (siteId: string, robotId: string) => void
}

const BugReport = ({
  isSavingBugReport,
  triedToFetchRobots,
  siteId,
  robotId,
  setBugReportData,
  bugReportData,
  saveBugReport,
  triggerBugReportDataCollection,
  cancelBugReportDataCollection,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const toolbarHeight = useAppToolbarHeight(true)
  const {goBack} = useHistory()
  const mountedRef = useRef<boolean>(false)
  const [hasError, setError] = useState(false)

  const {isNewFeature} = bugReportData
  const translationCategory = isNewFeature ? 'feature' : 'issue'
  const form = isNewFeature ? FEATURE_REQUEST_FORM : BUG_REPORT_FORM

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (siteId && robotId) {
      triggerBugReportDataCollection(siteId, robotId)

      return () => {
        cancelBugReportDataCollection(siteId, robotId)
      }
    }
  }, [siteId, robotId])

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (robotId && siteId) {
        cancelBugReportDataCollection(siteId, robotId)
      }
    }

    if (siteId && robotId) {
      window.addEventListener('beforeunload', onBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [siteId, robotId])

  useEffect(() => {
    const isBugReportDataEmpty = !bugReportData.robotId
    const hasRobotOrSiteChanged = bugReportData.robotId && (siteId !== bugReportData.siteId || robotId !== bugReportData.robotId)
    if (isBugReportDataEmpty || hasRobotOrSiteChanged) {
      setBugReportData({
        siteId,
        robotId,
        isNewFeature: false,
        text: '',
      })
    }
  }, [bugReportData.robotId, bugReportData.siteId, siteId, robotId, setBugReportData])

  const cancel = useCallback(() => {
    setBugReportData({})
    if (mountedRef.current) {
      goBack()
    }
  }, [setBugReportData, goBack])

  const onSuccess = useCallback(() => {
    if (mountedRef.current) {
      goBack()
    }
  }, [goBack])

  const save = useCallback((event: SyntheticEvent) => {
    event.preventDefault()
    if (isSavingBugReport) {
      return
    }

    const firstFormItemValue = bugReportData[getItemKey(form[0])]
    if (!(firstFormItemValue as string || '').trim()) {
      setError(true)
      return
    }

    saveBugReport(siteId as string, robotId as string, onSuccess)
  }, [siteId, robotId, onSuccess, isSavingBugReport, bugReportData, setError, saveBugReport, form])

  const handleRadioChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const isNewFeature = event.target.value === TOGGLE_BUTTON_VALUES.NEW_FEATURE
    setError(false)
    setBugReportData({
      ...bugReportData,
      isNewFeature,
    })
  }, [bugReportData, setBugReportData])

  const handleReportDataChange = useCallback((key: string, value: string) => {
    const firstFormItemKey = getItemKey(form[0])
    if (firstFormItemKey === key) {
      setError(false)
    }
    setBugReportData({...bugReportData, [key]: value})
  }, [bugReportData, setBugReportData, form])

  return (
    <FeatureToggle
      permissionName={PERMISSIONS.SEND_REPORT}
      onFailedPermission={cancel}
    >
      {triedToFetchRobots ? (
        <Form onSubmit={save}>
          <FormContent>
            <StyledRadioGroup
              row
              name="bugOrFeature"
              onChange={handleRadioChange}
              value={isNewFeature ? TOGGLE_BUTTON_VALUES.NEW_FEATURE : TOGGLE_BUTTON_VALUES.BUG_REPORT}
            >
              <FormControlLabel
                disabled={isSavingBugReport}
                value={TOGGLE_BUTTON_VALUES.BUG_REPORT}
                control={<Radio />}
                label={t('Robot configuration bug report category issue')}
              />
              <FormControlLabel
                disabled={isSavingBugReport}
                value={TOGGLE_BUTTON_VALUES.NEW_FEATURE}
                control={<Radio />}
                label={t('Robot configuration bug report category feature')}
              />
            </StyledRadioGroup>

            {form.map((item, index) => (
              <BugReportFormItem
                key={item.titleKey}
                hasError={index === 0 && hasError}
                item={item}
                setItem={handleReportDataChange}
                values={bugReportData}
              />
            ))}

            <BugReportFilesContainer form={translationCategory} />
          </FormContent>
          <Disclaimer>
            {t(`Robot configuration bug report disclaimer ${translationCategory}`)}
          </Disclaimer>
          <FormActions>
            <TextButton
              onClick={() => goBack()}
              disabled={isSavingBugReport}
            >
              {t('Common button cancel')}
            </TextButton>
            <Button
              type="submit"
              $backgroundColor={blueButton}
              $textColor={white}
              disabled={isSavingBugReport}
            >
              {t(`Robot configuration bug report submit ${translationCategory}`)}
            </Button>
          </FormActions>
          {isSavingBugReport && (
            <SavingOverlay toolbarHeight={toolbarHeight}>
              <SavingText>
                {t(`Robot configuration bug report save in progress ${translationCategory}`)}
              </SavingText>
              <StyledLoader centered />
            </SavingOverlay>
          )}
        </Form>
      ) : (
        <StyledLoader centered />
      )}
    </FeatureToggle>
  )
}

export default BugReport
