import {ThunkDispatch} from 'redux-thunk'
import {History} from 'history'
import {AnyAction} from 'redux'
import {httpPost, getResponseBody, logInfoToServer, httpGet, initCurrentUsers12HourFormat} from 'utils'
import {serverUrl} from 'config'
import {executeHooks, HOOK_LABELS} from 'framework'
import {Robot, User} from 'types'
import {actions as authActions} from 'auth'
import {
  changeServerConnectionStatus,
  robotUpdated,
  addSnackbarMessage,
  fetchPermissions,
  updateAvailable,
  bugReportServerDone,
  addAnnouncement,
  removeAnnouncement,
} from 'app'
import {PushNotifications} from 'platform'
import {EVENT_TYPES} from './constants'
import {Announcement} from '../components/types'
import {setGuiUseCase} from '../app/actions'

const handleRobotAdded = (dispatch: ThunkDispatch<{}, {}, AnyAction>, eventData: {robot: Robot, siteId: string}) => {
  dispatch(robotUpdated(eventData.robot))
}

const handleUserUpdated = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  eventData: User
) => {
  initCurrentUsers12HourFormat(eventData.userId)
  dispatch(authActions.setUser(eventData))
}

const handleAnnouncement = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  eventData: {announcement: Announcement}
) => {
  dispatch(addAnnouncement(eventData.announcement))
}

const handleClearAnnouncement = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  eventData: {robotId: string, siteId: string, announcementId: string}
) => {
  dispatch(removeAnnouncement(eventData.siteId, eventData.robotId, eventData.announcementId))
}

interface EventWithData extends Event {
    data: string
}

export const initEventListener = async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getHistory: () => History
) => {
  // sound iframe
  if (window.location.pathname.includes('.mp3')) {
    return
  }

  const eventListenerUrl = `${serverUrl}/api/events/listen`
  let checkRobotConnectedTimeout = -1
  let updateCount = 0
  let reconnectDelay = 1000
  const maxReconnectDelay = 3000

  const onUpdateAvailable = () => dispatch(updateAvailable())

  const run = async () => {
    try {
      const response = await httpPost(`events/init`)
      // special handling of unauthorized and forbidden
      if (response.status === 401 || response.status === 403) {
        return
      }

      dispatch(fetchPermissions())

      executeHooks(HOOK_LABELS.EVENTS.ON_EVENTS_INIT, {
        dispatch,
      })

      const initBody = await getResponseBody(response)
      if (initBody) {
        if (initBody.guiUseCase) {
          dispatch(setGuiUseCase(initBody.guiUseCase))
        }
        if (initBody.isRobotDeployed) {
          checkRobotConnectedTimeout = setTimeout(() => {
            dispatch(addSnackbarMessage(
              'Nav_Status content Robot is not sending health status, please check robot config',
              undefined,
              10 * 1000
            ))
          }, 1000 * 10)
        }
      }

      dispatch(changeServerConnectionStatus(true))
      // @ts-ignore
      if (!window.languageLoaded && !window.isLoadingLanguage) {
        window.location.reload()
      }

      logInfoToServer(`eventSource trying to connect`)

      const eventSource = new EventSource(eventListenerUrl, {withCredentials: true})
      eventSource.addEventListener(EVENT_TYPES.ROBOT.UPDATED, (event) => {
        handleRobotAdded(dispatch, JSON.parse((event as EventWithData).data))
        updateCount++
        // clear timeout only on 2nd update - 1st is for initial state
        if (updateCount > 1) {
          window.clearTimeout(checkRobotConnectedTimeout)
        }
      })
      eventSource.addEventListener(EVENT_TYPES.ROBOT.BUG_REPORT_RESPONSE, (event) => {
        const data = JSON.parse((event as EventWithData).data)
        dispatch(bugReportServerDone(data.reportId, data.isSuccess))
      })
      eventSource.addEventListener(EVENT_TYPES.USER.UPDATED, (event) => {
        handleUserUpdated(dispatch, JSON.parse((event as EventWithData).data))
      })
      eventSource.addEventListener(EVENT_TYPES.ROBOT.ANNOUNCEMENT, (event) => {
        handleAnnouncement(dispatch, JSON.parse((event as EventWithData).data))
      })
      eventSource.addEventListener(EVENT_TYPES.ROBOT.CLEAR_ANNOUNCEMENT, (event) => {
        handleClearAnnouncement(dispatch, JSON.parse((event as EventWithData).data))
      })

      executeHooks(HOOK_LABELS.EVENTS.REGISTER_SSE_EVENT_LISTENER, {
        eventSource,
        dispatch,
        getHistory,
      })

      eventSource.addEventListener('open', (event) => {
        httpGet(`events/connected`)
        reconnectDelay = 1000
      })

      eventSource.onerror = error => {
        logInfoToServer(`eventSource.onerror ${error && JSON.stringify(error)}`)
        eventSource.close()
        dispatch(changeServerConnectionStatus(false))
        setTimeout(() => {
          run()
          reconnectDelay = Math.max(maxReconnectDelay, reconnectDelay + 1000)
        }, reconnectDelay)
      }
    } catch (error) {
      logInfoToServer(`events/init catch ${error && JSON.stringify(error)}`)
      dispatch(changeServerConnectionStatus(false))
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(() => {
        run()
        reconnectDelay = Math.max(maxReconnectDelay, reconnectDelay + 1000)
      }, reconnectDelay)
    }
  }

  PushNotifications.initNotifications(onUpdateAvailable)
  await run()
}
