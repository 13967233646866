import {defaultMemoize} from 'reselect'
import {SettingsListItemConfig} from './types'

const getLocationIdsFromChildrenSelectors = {} as {[id: string]: (config: SettingsListItemConfig) => string[]}

const getLocationIdsFromChildrenCreator = () => (config: SettingsListItemConfig) => {
  const result: string[] = []
  const processConfig = (item: SettingsListItemConfig) => {
    if (item.children.length > 0) {
      item.children.forEach(processConfig)
    } else {
      result.push(item.id)
    }
  }

  processConfig(config)
  return result
}

export const getLocationIdsFromChildren = (config: SettingsListItemConfig): string[] => {
  if (!getLocationIdsFromChildrenSelectors[config.id]) {
    getLocationIdsFromChildrenSelectors[config.id] = defaultMemoize(getLocationIdsFromChildrenCreator())
  }

  return getLocationIdsFromChildrenSelectors[config.id](config)
}
