import React, {useCallback, useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Button, ConfirmationModal, TextButton} from 'components'
import {uniqueId} from 'utils'
import {LimitFindingWizardFooterProps} from './types'
import * as Styled from './LimitFindingWizardStyled'
import {blueButton, white} from '../colors'
import {ORDERED_STEPS, STEPS} from './constants'
import {exitLimitFinding} from './utils'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const LimitFindingWizardFooter = ({
  clear,
  goToNextStep,
  openModalForStep,
  isDone,
  currentStep,
  hasMoveJointPermission,
}: LimitFindingWizardFooterProps) => {
  const history = useHistory()
  const location = useLocation<{from?: string}>()
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [showConfirmation, setShowConfirmation] = useState(false)
  useEffect(() => {
    return function clean() {
      clear()
    }
  }, [])

  const onNoClick = () => {
    // just to push sth on browser stack without reload
    window.location.hash = uniqueId('modal')
    openModalForStep()
  }

  const hasNoButton = currentStep !== STEPS.CHECK_JOINTS &&
    ORDERED_STEPS.indexOf(currentStep) < ORDERED_STEPS.indexOf(STEPS.FINAL_STEP)
  const hasYesButton = ORDERED_STEPS.indexOf(currentStep) < ORDERED_STEPS.indexOf(STEPS.FINAL_STEP)

  const onYesClick = useCallback(() => {
    const stepsBeforeFinal = hasMoveJointPermission ? 1 : 2
    const isJustBeforeFinal = (ORDERED_STEPS.indexOf(currentStep) + stepsBeforeFinal) === ORDERED_STEPS.indexOf(STEPS.FINAL_STEP)
    if (isJustBeforeFinal) {
      setShowConfirmation(true)
    } else {
      goToNextStep()
    }
  }, [currentStep, hasMoveJointPermission, goToNextStep])

  return (
    <Styled.LimitFindingWizardFooter isDone={isDone}>
      {isDone ? (
        <Button
          $backgroundColor={blueButton}
          $textColor={white}
          onClick={() => exitLimitFinding(history, location.state?.from)}
          data-testid="limitFindingDashboardAction"
        >
          {t('PowerOn_CalibrationWizard button power on calibration done')}
        </Button>
      ) : (
        <>
          {hasNoButton ? (
            <TextButton
              onClick={onNoClick}
              data-testid="limitFindingPreviousAction"
            >
              {t('Common no')}
            </TextButton>
          ) : <span />}
          {hasYesButton && (
            <Button
              $backgroundColor={blueButton}
              $textColor={white}
              onClick={onYesClick}
              variant="contained"
              data-testid="limitFindingNextAction"
            >
              {currentStep === STEPS.CHECK_JOINTS ? t('Common next') : t('Common yes')}
            </Button>
          )}
        </>
      )}
      {showConfirmation && (
        <ConfirmationModal
          title={t('PowerOn_CalibrationWizard title power on calibration confirmation title')}
          description={(
            <Styled.ConfirmationContent>
              <div>{t('PowerOn_CalibrationWizard content power on calibration confirmation message')}</div>
              <Styled.StepsWarning>
                {t('PowerOn_CalibrationWizard content power on calibration confirmation warning')}
              </Styled.StepsWarning>
            </Styled.ConfirmationContent>
          )}
          confirmText={t('PowerOn_CalibrationWizard button power on calibration start')}
          cancel={() => setShowConfirmation(false)}
          confirm={() => {
            setShowConfirmation(false)
            goToNextStep()
          }}
        />
      )}
    </Styled.LimitFindingWizardFooter>
  )
}

export default LimitFindingWizardFooter
