import React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import {FeatureToggle} from 'components'
import {PERMISSIONS} from '../constants'
import RobotStatusCardContainer from './RobotStatusCardContainer'
import RobotActionsCardContainer from './RobotActionsCardContainer'
import SiteActionsCardContainer from './SiteActionsCardContainer'
import {DashboardProps} from './types'
import {executeHooks, HOOK_LABELS} from '../framework'

const Content = styled.div`
  margin: 16px 0;
`

const Dashboard = ({robot}: DashboardProps) => {
  const extraRightBlocksHookResult = executeHooks(HOOK_LABELS.DASHBOARD.EXTRA_RIGHT_BLOCKS, {})
  const extraRightBlocks: React.ComponentType[] = Object
    .values(extraRightBlocksHookResult)
    .filter(Boolean)
    .reduce((acc: React.ComponentType[], blocks) => [
      ...acc,
      ...(Array.isArray(blocks) ? blocks : [blocks]),
    ], [])

  return (
    <FeatureToggle permissionName={PERMISSIONS.ENGINEERING_DASHBOARD}>
      <Content>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <RobotStatusCardContainer robot={robot} />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <RobotActionsCardContainer robot={robot} />
            <SiteActionsCardContainer />
            {extraRightBlocks.map((Block, index: number) => <Block key={index} />)}
          </Grid>
        </Grid>
      </Content>
    </FeatureToggle>
  )
}

export default Dashboard
