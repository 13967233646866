import {
  all,
} from 'redux-saga/effects'
import {sagas as settings} from './settings'
import {sagas as report} from './report'

function* patrolSagas() {
  yield all([
    settings(),
    report(),
  ])
}

export default patrolSagas
