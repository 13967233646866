import {buildAction} from 'utils'
import * as constants from './constants'

export const setOngoingPatrolEventsType = buildAction(constants.SET_ONGOING_PATROL_EVENT_TYPE, 'eventType')

export const refetchOngoingEvents = buildAction(constants.REFETCH_ONGOING_EVENTS)
export const setCurrentPatrolEvents = buildAction(constants.SET_CURRENT_PATROL_EVENTS, 'siteId', 'robotId', 'patrolId', 'events')
export const setCurrentPatrolEventsAfterRefetch = buildAction(
  constants.SET_CURRENT_PATROL_EVENTS_AFTER_REFETCH,
  'siteId',
  'robotId',
  'patrolId',
  'events')
