import {connect} from 'react-redux'
import {Store} from 'types'
import {setLock, openLocksModal} from './actions'
import LocksModal from './LocksModal'

const mapStateToProps = (state: Store) => ({
  isModalOpen: state.dashboard.isLocksModalOpen,
  locks: state.dashboard.locks,
})

export default connect(mapStateToProps, {setLock, openLocksModal})(LocksModal)
