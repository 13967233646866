import React from 'react'
import {i18n, TFunction} from 'i18next'
import {useModuleAwareTranslation} from 'utils'
import {FeatureToggle, Preconditions} from 'components'
import {getAllPermutations} from 'utils'
import {PERMISSIONS} from '../../../constants'
import {OperationPreconditions} from '../types'

export interface Props {
  isBatteryRising: boolean
  isChargingCableConnected: boolean
  shouldDisplay: boolean
  preconditions: OperationPreconditions
  onEnter: () => void
  onLastRemoved: () => void
}

type PreconditionCheck = (preconditions: OperationPreconditions, isBatteryRising?: boolean, isChargingCableConnected?: boolean) =>
  {isFulfilled: boolean, translationKey: string, key: string}

const areItemsValid: PreconditionCheck = (preconditions) => ({
  isFulfilled: preconditions.itemsValid,
  translationKey: 'ASSISTANCE_NO_ROOMS_SELECTED',
  key: 'itemsValid',
})

const isChargerValid: PreconditionCheck = (preconditions) => ({
  isFulfilled: preconditions.chargerValid,
  translationKey: 'ASSISTANCE_UNPLUG_CHARGER',
  key: 'chargerValid',
})

const isBatteryValid: PreconditionCheck = (preconditions, isBatteryRising, isChargingCableConnected) => {
  let translationKey = 'ASSISTANCE_BATTERY_TOO_LOW_TO_START'
  if (preconditions.usingAC) {
    if (!preconditions.batteryValid && isChargingCableConnected && !isBatteryRising) {
      translationKey = 'ASSISTANCE_BATTERY_TOO_LOW_TO_START_AC_DOCKED_NOT_CHARGING'
    } else if (isBatteryRising && !preconditions.batteryValid) {
      translationKey = 'ASSISTANCE_BATTERY_TOO_LOW_TO_START_AC_CHARGING'
    } else {
      translationKey = 'ASSISTANCE_BATTERY_TOO_LOW_TO_START_AC'
    }
  }

  return {
    isFulfilled: preconditions.batteryValid,
    translationKey,
    key: 'batteryValid',
  }
}

const conditionsToCheck = [
  isBatteryValid,
  isChargerValid,
  areItemsValid,
]

const getTitleKey = (combinedReasons: string, isFulfilled: boolean) =>
  `Nav_Status content patrol precondition ${combinedReasons}${isFulfilled ? ' fulfilled' : ''}`

const getTranslationForConditionCombination = (
  currentCondition: ReturnType<PreconditionCheck>,
  otherConditions: Array<ReturnType<PreconditionCheck>>,
  isCurrentReasonFulfilled: boolean,
  i18next: i18n
): string[] => {
  if (otherConditions.length === 0) {
    return []
  }

  const allPermutationsWithCurrentReason = getAllPermutations([
    currentCondition.translationKey,
    ...otherConditions.map(condition => condition.translationKey),
  ])
  let titleKey: string | undefined

  while (!titleKey && allPermutationsWithCurrentReason.length > 0) {
    const currentReasons = allPermutationsWithCurrentReason.shift() as string[]
    const keyCandidate = getTitleKey(currentReasons.join(' '), isCurrentReasonFulfilled)
    if (i18next.exists(keyCandidate)) {
      return currentReasons
    }
  }

  let otherReasonsResults: string[] = []
  // check reasons with subset of otherReasons
  for (let i = 0; i < otherConditions.length; i++) {
    const otherConditionsWithoutOne = [...otherConditions.slice(0, i), ...otherConditions.slice(i + 1)]
    const result = getTranslationForConditionCombination(currentCondition, otherConditionsWithoutOne, isCurrentReasonFulfilled, i18next)
    // is it more detailed result?
    if (result.length > otherReasonsResults.length) {
      otherReasonsResults = result
    }
  }

  return otherReasonsResults
}

const getPreconditions = (
  preconditions: OperationPreconditions,
  isBatteryRising: boolean,
  isChargingCableConnected: boolean,
  t: TFunction,
  i18next: i18n
) => {
  const componentPreconditions = [] as Array<{
    isFulfilled: boolean
    text: string
    identifier: string
  }>
  let remainingConditionsToCheck = conditionsToCheck.map(check => check(preconditions, isBatteryRising, isChargingCableConnected))

  while (remainingConditionsToCheck.length > 0) {
    const condition = remainingConditionsToCheck.shift()
    if (!condition) {
      continue
    }

    let titleKey: string | undefined
    const {isFulfilled: isCurrentReasonFulfilled, key} = condition

    // take reasons that are as fulfilled as current reason
    const conditionsToCombineWithCurrent = remainingConditionsToCheck
      .filter(condition => condition.isFulfilled === isCurrentReasonFulfilled)

    const keyForReasons = getTranslationForConditionCombination(
      condition,
      conditionsToCombineWithCurrent,
      isCurrentReasonFulfilled,
      i18next)
    if (keyForReasons.length > 0) {
      titleKey = getTitleKey(keyForReasons.join(' '), isCurrentReasonFulfilled)
      remainingConditionsToCheck = remainingConditionsToCheck.filter(condition => !keyForReasons.includes(condition.translationKey))
    }

    if (!titleKey) {
      titleKey = getTitleKey(condition.translationKey, isCurrentReasonFulfilled)
    }

    if (titleKey) {
      componentPreconditions.push({
        isFulfilled: isCurrentReasonFulfilled,
        text: t(titleKey),
        identifier: key,
      })
    }
  }

  return componentPreconditions
}

const PatrolPreconditions = ({
  shouldDisplay,
  preconditions,
  onEnter,
  onLastRemoved,
  isBatteryRising,
  isChargingCableConnected,
}: Props) => {
  const {t, i18n} = useModuleAwareTranslation()

  return shouldDisplay ? (
    <FeatureToggle permissionName={PERMISSIONS.PATROL.VIEW_PRECONDITIONS}>
      <Preconditions
        marginBottom="0"
        conditions={getPreconditions(preconditions, isBatteryRising, isChargingCableConnected, t, i18n)}
        onEnter={onEnter}
        onLastRemoved={onLastRemoved}
      />
    </FeatureToggle>
  ) : null
}

export default PatrolPreconditions
