import {
  all,
  put,
  takeEvery,
  delay,
} from 'redux-saga/effects'
import {AnyAction} from 'redux'
import {addSnackbarMessage} from 'app'
import {fetchLocksDone} from './actions'
import {getResponseBody, getSelectedSiteAndRobotId, httpGet, httpPost} from 'utils'
import * as constants from './constants'

function* setLock() {
  yield takeEvery<AnyAction>(constants.SET_LOCK, function* (action) {
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    const {lockId, shouldLock} = action
    const response: Response = yield httpPost(`${siteId}/robot/${robotId}/lock/${lockId}`, {
      shouldLock,
    })
    const {status} = response
    if (status >= 400 ) {
      yield put(addSnackbarMessage('Dashboard dashboard set lock failed'))
    } else {
      yield put(addSnackbarMessage('Dashboard dashboard set lock success'))
    }
  })
}

function* fetchLocks() {
  yield takeEvery<AnyAction>(constants.FETCH_LOCKS, function* (action) {
    let siteAndRobotId = yield getSelectedSiteAndRobotId()
    while (!siteAndRobotId.robotId) {
      yield delay(300)
      siteAndRobotId = yield getSelectedSiteAndRobotId()
    }

    const {siteId, robotId} = siteAndRobotId
    const response: Response = yield httpGet(`${siteId}/robot/${robotId}/lock`)
    const {status} = response
    let locks = []
    if (status < 400) {
      locks = yield getResponseBody(response)
    }

    yield put(fetchLocksDone(locks))
  })
}

function* appSagas() {
  yield all([
    fetchLocks(),
    setLock(),
  ])
}

export default appSagas
