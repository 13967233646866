export const textFieldOptions = [
  'other',
  'issue after pressing - other',
  'robot behavior change',
  'robot performance improvement',
  'gui behavior change',
  'feature - other',
]

export const userTextKey = 'userText'

export const BUG_REPORT_FORM = [
  {
    titleKey: 'issue',
    options: [
      {
        titleKey: 'cannot configure operation',
        options: [
          {titleKey: 'webpage not available'},
          {titleKey: 'buttons not working'},
          {titleKey: 'other'},
        ],
      },
      {
        titleKey: 'operation didnt start',
      },
      {
        titleKey: 'cannot start operation',
        options: [
          {titleKey: 'no action after button pressed'},
          {titleKey: 'button disabled'},
          {titleKey: 'issue after pressing - command not sent'},
          {titleKey: 'issue after pressing - other'},
          {titleKey: 'other'},
        ],
      },
      {
        titleKey: 'robot stuck',
        options: [
          {
            titleKey: 'robot stuck position',
            options: [
              {titleKey: 'elevator door'},
              {titleKey: 'elevator inside'},
              {titleKey: 'elevator outside'},
              {titleKey: 'hallway'},
              {titleKey: 'near wing door'},
              {titleKey: 'near room door'},
              {titleKey: 'other'},
            ],
          },
        ],
      },
      {
        titleKey: 'object hit',
        options: [
          {
            titleKey: 'object type',
            options: [
              {titleKey: 'trolley cart wheel'},
              {titleKey: 'elevator panel'},
              {titleKey: 'wall'},
              {titleKey: 'sink handrail'},
              {titleKey: 'door handle'},
              {titleKey: 'door panel'},
              {titleKey: 'door frame'},
              {titleKey: 'other'},
            ],
          },
        ],
      },
      {
        titleKey: 'didnt recognize CA',
        options: [
          {titleKey: 'no alert'},
          {titleKey: 'alert sent but person was CA'},
          {titleKey: 'alert sent but no person'},
          {titleKey: 'other'},
        ],
      },
      {
        titleKey: 'didnt recognize risky posture',
        options: [
          {titleKey: 'no alert'},
          {titleKey: 'alert sent but person was CA'},
          {titleKey: 'alert sent but person in safe posture'},
          {titleKey: 'other'},
        ],
      },
      {
        titleKey: 'didnt check room',
        options: [
          {titleKey: 'room shown as skipped but was not locked'},
          {titleKey: 'skipped without reason'},
          {titleKey: 'other'},
        ],
      },
      {
        titleKey: 'cannot unlock nfc',
        options: [
          {titleKey: 'wing skipped'},
          {titleKey: 'kept trying to unlock'},
          {titleKey: 'other'},
        ],
      },
      {
        titleKey: 'cannot ride elevator',
        options: [
          {titleKey: 'cannot enter for no reason'},
          {titleKey: 'cannot press button for no reason'},
          {titleKey: 'missed button'},
          {titleKey: 'other'},
        ],
      },
      {
        titleKey: 'cannot control patrol',
        options: [
          {titleKey: 'button disabled'},
          {titleKey: 'button clicked but no reaction'},
          {titleKey: 'other'},
        ],
      },
      {
        titleKey: 'cannot review patrol',
        options: [
          {titleKey: 'cannot open zip file'},
          {titleKey: 'report not available'},
          {titleKey: 'report empty'},
          {titleKey: 'other'},
        ],
      },
      {
        titleKey: 'other',
      },
    ],
  },
  {
    titleKey: 'ear light status',
    options: [
      {titleKey: 'no light'},
      {titleKey: 'white steady'},
      {titleKey: 'white blinking'},
      {titleKey: 'white pulsating'},
      {titleKey: 'yellow steady'},
      {titleKey: 'yellow blinking'},
      {titleKey: 'red steady'},
      {titleKey: 'red blinking'},
      {titleKey: 'blue'},
      {titleKey: 'green blinking'},
    ],
  },
  {
    titleKey: 'power button status',
    options: [
      {titleKey: 'no light'},
      {titleKey: 'green steady'},
      {titleKey: 'green blinking'},
      {titleKey: 'green fast blinking'},
      {titleKey: 'green red'},
    ],
  },
  {
    titleKey: 'gui status',
    options: [
      {titleKey: 'not responsive'},
      {titleKey: 'showing assistance required'},
      {titleKey: 'showing alarm'},
      {titleKey: 'showing ongoing operation'},
      {titleKey: 'other'},
    ],
  },
]

export const FEATURE_REQUEST_FORM = [
  {
    titleKey: 'feature category',
    options: [
      {
        titleKey: 'robot behavior change',
      },
      {
        titleKey: 'robot performance improvement',
      },
      {
        titleKey: 'gui behavior change',
      },
      {
        titleKey: 'feature - other',
      },
    ],
  },
]
