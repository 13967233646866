import React from 'react'
import {useHistory} from 'react-router-dom'
import TranslateIcon from '@material-ui/icons/Translate'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import {GoToElementIcon, List, ListItem, ListItemText, PageTitle} from 'components'
import {useModuleAwareTranslation} from 'utils'
import {NotificationSettingsLinksContainer} from './notifications'
import {PageMainContent} from '../NSPCommonStyled'
import {RemindersContainer} from '../reminders'
import {ROUTES as NAV_ROUTES} from '../constants'
import {OtherSettings} from './otherSettings'
import {videoListUrl} from '../../../config'

const SiteLocations = () => {
  const {t} = useModuleAwareTranslation()
  const {push} = useHistory()
  return (
    <>
      <PageTitle>{t('Nav_SiteSettings page title')}</PageTitle>
      <PageMainContent withMarginTop>
        <List>
          <ListItem
            data-testid="link-location-settings"
            fullWidth
            clickable
            bolder
            onClick={() => push(NAV_ROUTES.SITE_SETTINGS_LOCATIONS)}
          >
            <ListItemText>
              <TranslateIcon />
              {t('Nav_SiteSettings content link locations')}
            </ListItemText>
            <GoToElementIcon />
          </ListItem>
          {videoListUrl && (
            <ListItem
              data-testid="link-location-settings"
              fullWidth
              clickable
              bolder
              onClick={() => window.open(videoListUrl, '_blank')}
            >
              <ListItemText>
                <OndemandVideoIcon />
                {t('Nav_Settings videos link')}
              </ListItemText>
              <GoToElementIcon />
            </ListItem>
          )}
        </List>
        <NotificationSettingsLinksContainer />
        <RemindersContainer />
        <OtherSettings />
      </PageMainContent>
    </>
  )
}

export default SiteLocations
