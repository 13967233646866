import {AnyAction, combineReducers} from 'redux'
import {SET_SELECTED_ROBOT} from 'app/constants'
import {sortObjectsByDateDesc} from 'utils'
import {SAVE_EVENT_NOTE_SUCCESS} from '../constants'
import * as constants from './constants'
import {PatrolDetails, SimplePatrol} from '../types'

const isLoadingDetails = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.FETCH_PATROL_DETAILS:
      return true
    case constants.PATROL_DETAILS_LOADED:
      return false
    default:
      return state
  }
}

const details = (state: PatrolDetails | null = null, action: AnyAction): PatrolDetails | null => {
  switch (action.type) {
    case constants.FETCH_PATROL_DETAILS:
      return action.patrolId === state?.id ? state : null
    case constants.SET_PATROL_DETAILS:
      return {
        ...action.patrolDetails,
        events: (action.patrolDetails as PatrolDetails).events
          .sort(sortObjectsByDateDesc),
      }
    case SAVE_EVENT_NOTE_SUCCESS:
      return state ? {
        ...state,
        events: state.events.map(event => event.notificationUid === action.event.notificationUid ? action.event : event),
      } : null
    default:
      return state
  }
}

const finishedReports = (state: SimplePatrol[] = [], action: AnyAction): SimplePatrol[] => {
  switch (action.type) {
    case constants.FETCH_PATROL_LIST_SUCCESS:
      return action.page === 0 ?
        action.reports :
        [
          ...state,
          ...action.reports.filter((report: SimplePatrol) => !state.some(prevReport => prevReport.id === report.id)),
        ].sort((reportA, reportB) => new Date(reportB.finishedAt).getTime() - new Date(reportA.finishedAt).getTime())
    case constants.CLEAR_PATROL_LIST:
    case SET_SELECTED_ROBOT:
      return []
    default:
      return state
  }
}

const hasMoreReports = (state = true, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.CLEAR_PATROL_LIST:
    case SET_SELECTED_ROBOT:
      return true
    case constants.FETCH_PATROL_LIST_ERROR:
      return false
    case constants.FETCH_PATROL_LIST_SUCCESS:
      return action.hasMore
    default:
      return state
  }
}

const historyTabIndex = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case constants.SET_HISTORY_TAB_INDEX:
      return action.tabIndex
    default:
      return state
  }
}

export default combineReducers({
  details,
  historyTabIndex,
  finishedReports,
  hasMoreReports,
  isLoadingDetails,
})
