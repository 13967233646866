import {combineReducers, AnyAction} from 'redux'
import * as constants from './constants'
import {STEPS} from './constants'

const currentStep = (state = constants.ORDERED_STEPS[0], action: AnyAction): STEPS => {
  switch (action.type) {
    case constants.NEXT_STEP: {
      if (action.step && constants.ORDERED_STEPS.includes(action.step)) {
        return action.step
      }

      const currentStepIndex = constants.ORDERED_STEPS.indexOf(state)
      const nextStepIndex = currentStepIndex + 1
      if (constants.ORDERED_STEPS.length > nextStepIndex) {
        return constants.ORDERED_STEPS[nextStepIndex]
      }

      return state
    }
    case constants.PREVIOUS_STEP: {
      const currentStepIndex = constants.ORDERED_STEPS.indexOf(state)
      const nextStepIndex = currentStepIndex - 1
      if (nextStepIndex >= 0) {
        return constants.ORDERED_STEPS[nextStepIndex]
      }

      return state
    }
    case constants.CLEAR:
      return constants.ORDERED_STEPS[0]
    default:
      return state
  }
}

const limitFindingError = (state: string | null = null, action: AnyAction): string | null => {
  switch (action.type) {
    case constants.RUN_LIMIT_FINDING_ERROR:
      return action.error ? (action.error as string) : 'Unknown error'
    case constants.RUN_LIMIT_FINDING:
    case constants.CLEAR:
      return null
    default:
      return state
  }
}

const limitFindingSuccessful = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.RUN_LIMIT_FINDING_SUCCESS:
      return true
    case constants.RUN_LIMIT_FINDING_ERROR:
    case constants.RUN_LIMIT_FINDING:
    case constants.CLEAR:
      return false
    default:
      return state
  }
}

const isModalForStepOpen = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.OPEN_MODAL_FOR_STEP:
      return true
    case constants.CLOSE_MODAL_FOR_STEP:
    case constants.CLEAR:
      return false
    default:
      return state
  }
}

const isRunningLimitFinding = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.RUN_LIMIT_FINDING:
      return true
    case constants.RUN_LIMIT_FINDING_ERROR:
    case constants.RUN_LIMIT_FINDING_SUCCESS:
    case constants.CANCEL_LIMIT_FINDING:
    case constants.CLEAR:
      return false
    default:
      return state
  }
}

export default combineReducers({
  currentStep,
  isModalForStepOpen,
  isRunningLimitFinding,
  limitFindingError,
  limitFindingSuccessful,
})
