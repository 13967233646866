const images: {[url: string]: object} = {}

export const prefetchImages = (urls: string[]) => {
  urls.forEach(url => {
    if (!images[url]) {
      const image = new Image()
      image.src = url
      images[url] = image
    }
  })
}
