import {connect} from 'react-redux'
import {Store} from 'types'
import BatteryIndicator, {Props} from 'components/BatteryIndicator'

const mapStateToProps = (state: Store): Props => {
  let batteryLevel: number | undefined = -1
  if (state.app.selectedRobot !== null) {
    batteryLevel = state.app.selectedRobot.healthStatus.batteryLevel
  }
  return {
    batteryLevel,
    segments: state.app.selectedRobot?.config?.battery?.segments,
    thresholds: state.app.selectedRobot?.config?.battery?.thresholds,
    isCharging: state.app.selectedRobot?.battery?.isRising,
    isLow: state.app.selectedRobot?.battery?.isLow,
  }
}

export default connect(mapStateToProps)(BatteryIndicator)
