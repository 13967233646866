import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import {i18n} from 'locale'
import {getDateAndTimeFormatForMoment} from 'utils'
import {PatrolDetails} from '../../types'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const TimeRange = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 12px;
  font-size: 0.75rem;
  
  @media (min-width: 400px) {
    font-size: 0.875rem;
  }
`

const Time = styled.div`
  text-align: center;
  flex: 1;
  margin: 0 8px;
  max-width: calc(50% - 16px);
`

interface Props {
  details: PatrolDetails
  userId?: string
}

const ReportSummaryTimeRange = ({
  details,
  userId,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const {
    startedAt,
    finishedAt,
    startReason,
    finishReason,
  } = details

  const showOnlyFinishedTime = startedAt && finishedAt && startedAt === finishedAt
  const dateAndTimeFormat = getDateAndTimeFormatForMoment(userId)
  const startedTime = startedAt ? moment(startedAt).format(dateAndTimeFormat) : '-'
  const finishedTime = finishedAt ? moment(finishedAt).format(dateAndTimeFormat) : t('Nav_Report content patrol in progress')
  const startReasonTranslation = startReason && i18n.exists(`Nav_Report content start reason ${startReason}`) ?
    t(`Nav_Report content start reason ${startReason}`) :
    undefined
  const finishReasonTranslation = startReason && i18n.exists(`Nav_Report content finish reason ${finishReason}`) ?
    t(`Nav_Report content finish reason ${finishReason}`) :
    undefined

  return startedTime ? (
    <TimeRange>
      {!showOnlyFinishedTime && (
        <Time>
          <div>{startedTime}</div>
          {startReasonTranslation && <div>({startReasonTranslation})</div>}
        </Time>
      )}
      <Time>
        <div>{finishedTime}</div>
        {finishedTime && finishReasonTranslation && <div>({finishReasonTranslation})</div>}
      </Time>
    </TimeRange>
  ) : null
}

export default ReportSummaryTimeRange
