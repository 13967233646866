import React from 'react'
import {DialogActions, DialogContent, Dialog} from './Dialog'
import {Button, TextButton} from './BasicElementsStyled'
import {blueButton, white} from '../colors'
import styled from 'styled-components'
import {Store} from '../types'

interface Props {
  autoCloseSelector?: (state: Store) => boolean
  autoCloseKey?: (state: Store) => string
  leftText?: boolean
  title: string
  description: string | React.ReactElement
  confirmText: string
  cancelText?: string
  cancel: () => void
  confirm: () => void
}

const Title = styled.div`
    font-size: 1.17rem;
    font-weight: bold;
    text-align: ${(props: {leftText?: boolean}) => props.leftText ? 'left' : 'center'};
    margin-bottom: 18px;
`

const Description = styled.div`
    text-align: ${(props: {leftText?: boolean}) => props.leftText ? 'left' : 'center'};
    margin-bottom: 12px;
    white-space: pre-line;
`

const ConfirmationModal: React.FC<Props> = ({
  autoCloseSelector,
  autoCloseKey,
  title,
  description,
  confirmText,
  cancelText,
  cancel,
  confirm,
  leftText,
}: Props) => {
  return (
    <Dialog
      autoCloseSelector={autoCloseSelector}
      autoCloseKey={autoCloseKey}
      open
      onClose={cancel}
    >
      <DialogContent>
        <Title leftText={leftText}>{title}</Title>
        <Description leftText={leftText}>{description}</Description>
      </DialogContent>
      <DialogActions>
        {cancelText && (
          <TextButton
            data-testid="confirmation-cancel"
            onClick={cancel}
          >
            {cancelText}
          </TextButton>
        )}
        <Button
          data-testid="confirmation-confirm"
          onClick={confirm}
          $backgroundColor={blueButton}
          $textColor={white}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
