import React, {useEffect} from 'react'
import styled from 'styled-components'
import {defaultText} from '../../colors'
import {prefetchImages, usePrevious} from 'utils'
import {IMAGES_TO_PREFETCH, ROUTES} from './constants'
import {RemindersDisplayContainer} from './reminders'
import {PatrolEventModalContainer} from './patrolEvents'
import {useHistory, useLocation} from 'react-router-dom'
import AnnouncementsContainer from './AnnouncementsContainer'

interface Props {
  currentModule?: string
  children?: React.ReactNode
  robotId?: string
  fetchSiteSettings: () => {}
}

const NSPContent = styled.div`
    color: ${defaultText};
    flex: 1;
    display: flex;
    flex-direction: column;
`

const NSPWrapper = ({
  children,
  fetchSiteSettings,
  robotId,
  currentModule,
}: Props) => {
  const history = useHistory()
  const location = useLocation()
  const previousRobotId = usePrevious(robotId)

  useEffect(() => {
    fetchSiteSettings()
    prefetchImages(IMAGES_TO_PREFETCH)
  }, [currentModule, robotId])

  useEffect(() => {
    if (
      robotId &&
      previousRobotId &&
      robotId !== previousRobotId &&
      location.pathname !== ROUTES.INDEX &&
      location.pathname !== '/'
    ) {
      history.push(ROUTES.INDEX)
    }
  }, [robotId, previousRobotId, location.pathname])

  return (
    <NSPContent>
      <AnnouncementsContainer />
      {children}
      <PatrolEventModalContainer />
      <RemindersDisplayContainer />
    </NSPContent>
  )
}

export default NSPWrapper
