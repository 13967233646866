import React from 'react'
import {useModuleAwareTranslation} from 'utils'
import styled from 'styled-components'

interface Props {
  translationKey: string
}

const List = styled.ol`
  margin: 5px auto;
  padding-left: 20px;
  transform: translateX(3px);
`

const EventDescriptionList: React.FC<Props> = ({
  translationKey,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const items: string[] = t(translationKey).split('\n')

  return (
    <List>
      {items.map(item => (
        <li key={item}>
          {item}
        </li>
      ))}
    </List>
  )
}

export default EventDescriptionList
