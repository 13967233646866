import React from 'react'
import {useHistory} from 'react-router-dom'
import {
  GoToElementIcon,
  List,
  ListHeader, ListImage,
  ListItem,
  ListItemText,
  SimpleSwitch,
} from 'components'
import {useModuleAwareTranslation} from 'utils'
import {ROUTES as NAV_ROUTES} from '../../constants'

interface Props {
  LINEInviteLink?: string
  enabled: boolean
  setEnabled: (enabled: boolean) => void
}

const NotificationSettingsLinks = ({LINEInviteLink, enabled, setEnabled}: Props) => {
  const {t} = useModuleAwareTranslation()
  const {push} = useHistory()
  return (
    <List>
      <ListHeader $withTopMargin>
        {t('Nav_NotificationSettings list title')}
      </ListHeader>
      {LINEInviteLink && (
        <ListItem
          fullWidth
          clickable
          bolder
          onClick={() => window.open(LINEInviteLink, '_blank')}
        >
          <ListItemText>
            <ListImage src="/LINE/LINE_SOCIAL_Basic.png" />
            <span>
              {t('Nav_Schedule content link join LINE group')}
            </span>
          </ListItemText>
          <GoToElementIcon />
        </ListItem>
      )}
      <ListItem
        fullWidth
        bolder
      >
        <span>{t('Nav_NotificationSettings enabled toggle')}</span>
        <SimpleSwitch
          checked={enabled}
          onChange={() => setEnabled(!enabled)}
        />
      </ListItem>
      <ListItem
        fullWidth
        clickable
        bolder
        disabled={!enabled}
        onClick={() => enabled ? push(`${NAV_ROUTES.SITE_SETTINGS_NOTIFICATIONS}`) : undefined}
      >
        <ListItemText>
          {t('Nav_NotificationSettings settings link')}
        </ListItemText>
        <GoToElementIcon />
      </ListItem>
    </List>
  )
}

export default NotificationSettingsLinks
