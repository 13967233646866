import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {History, Location} from 'history'
import {Loader, PageTitle} from 'components'
import {LimitFindingStatus, RobotState} from '../types'
import * as Styled from './LimitFindingWizardStyled'
import {StepContentContainer} from './steps'
import LimitFindingWizardFooterContainer from './LimitFindingWizardFooterContainer'
import ModalForStepContainer from './ModalForStepContainer'
import {exitLimitFinding} from './utils'
import {ROUTES} from '../constants'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  fromPage?: string
  defaultRedirectUrl: string
  isChangingManualMode: boolean
  limitFindingInProgress: boolean
  limitFindingStatus?: LimitFindingStatus
  limitFindingFinished: boolean
  location: Location<{autoManualMode?: boolean}>
  robotState?: RobotState
  setManualMode: (enabled: boolean, history: History<any>) => void
}

const LimitFindingWizard = ({
  fromPage,
  isChangingManualMode,
  location,
  limitFindingFinished,
  robotState,
  setManualMode,
  limitFindingStatus,
  limitFindingInProgress,
  defaultRedirectUrl,
}: Props) => {
  const history = useHistory()
  const {replace} = history
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const autoManualMode = location.state?.autoManualMode
  const [initialLfStatus, setInitialLfStatus] = useState(limitFindingStatus)
  const [hasTriedAutoManualMode, setHasTriedAutoManualMode] = useState(false)
  const [startedManualControlChange, setStartedManualControlChange] = useState(isChangingManualMode)
  const finishedManualControlChange = startedManualControlChange && !isChangingManualMode

  const lfWizardDisabled = initialLfStatus === LimitFindingStatus.OK && robotState !== RobotState.MANUAL_CONTROL

  // cannot run LF -> exit page
  useEffect(() => {
    if (lfWizardDisabled) {
      history.replace(ROUTES.HOME)
    }
  }, [lfWizardDisabled, history])

  useEffect(() => {
    if (limitFindingStatus && !initialLfStatus) {
      setInitialLfStatus(limitFindingStatus)
    }
  }, [limitFindingStatus, initialLfStatus, setInitialLfStatus])

  // check if process of changing manual control has started
  useEffect(() => {
    if (isChangingManualMode) {
      setStartedManualControlChange(true)
    }
  }, [isChangingManualMode])

  // attempt enabling manual mode if needed
  useEffect(() => {
    if (limitFindingStatus && !hasTriedAutoManualMode && autoManualMode) {
      setHasTriedAutoManualMode(true)
      setManualMode(true, history)
    }
  }, [limitFindingStatus, hasTriedAutoManualMode, autoManualMode, setManualMode])

  // if change of manual mode failed - go back to previous screen
  useEffect(() => {
    if (
      !limitFindingInProgress &&
      !limitFindingFinished &&
      hasTriedAutoManualMode &&
      finishedManualControlChange &&
      robotState !== RobotState.MANUAL_CONTROL
    ) {
      exitLimitFinding(history, fromPage)
    }
  }, [fromPage, hasTriedAutoManualMode, robotState, finishedManualControlChange, limitFindingFinished, limitFindingInProgress])

  useEffect(() => {
    if (robotState === RobotState.INITIALIZING) {
      replace(defaultRedirectUrl)
    }
  }, [replace, robotState, defaultRedirectUrl])

  // LF finished - disable manual mode if it was auto-enabled
  useEffect(() => {
    if (limitFindingFinished && autoManualMode && limitFindingStatus === LimitFindingStatus.OK) {
      setManualMode(false, history)
    }
  }, [limitFindingFinished, autoManualMode, limitFindingStatus])

  return (
    <Styled.LimitFindingWizard>
      {!initialLfStatus || (!limitFindingFinished && isChangingManualMode) ? (
        <Loader centered />
      ) : (
        <>
          <Styled.LimitFindingWizardContent>
            <PageTitle data-testid="lf-wizard-title">
              {t('PowerOn_CalibrationWizard title power on calibration title')}
            </PageTitle>
            <StepContentContainer />
          </Styled.LimitFindingWizardContent>
          <LimitFindingWizardFooterContainer />
          <ModalForStepContainer />
        </>
      )}
    </Styled.LimitFindingWizard>
  )
}

export default LimitFindingWizard
