import React, {MouseEventHandler} from 'react'
import styled from 'styled-components'
import {primary, primaryLight, white} from '../colors'
import {SuccessIcon} from './icons'
import Image from './Image'

const FlatGridItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props: {selected?: boolean, onClick?: MouseEventHandler<HTMLDivElement>}) => props.selected ? primaryLight : '#efefef'};
  cursor: ${(props: {selected?: boolean, onClick?: MouseEventHandler<HTMLDivElement>}) => props.onClick ? 'pointer' : 'default'};
  position: relative;
  min-width: 100px;
  transition: background-color 0.25s linear;
`

const FlatGridItemImage = styled(Image)`
  height: auto;
  width: 100%;
`

const FlatGridItemText = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  padding: 8px 6px;
`

const IconsWrapper = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
`

const SelectedIcon = styled(SuccessIcon)`
  color: ${(props: {disabled?: boolean}) => props.disabled ? '#6f6f6f': primary};
  position: relative;
  z-index: 10;
`

const EmptySelection = styled.div`
  border: 2px solid ${white};
  border-color: ${(props: {selected?: boolean, disabled?: boolean}) => {
    if (!props.selected && props.disabled) {
      return '#6f6f6f'
    }
    return white
  }};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 9;
  background: ${(props: {selected?: boolean}) => props.selected ? white : 'transparent'};
`

interface FlatGridItemProps {
  disabled?: boolean
  selected?: boolean
  selectable?: boolean
  children: React.ReactNode
  onClick?: MouseEventHandler<HTMLDivElement>
}

const FlatItem = ({
  children,
  disabled,
  onClick,
  selectable,
  selected,
}: FlatGridItemProps) => (
  <FlatGridItemWrapper
    onClick={disabled ? undefined : onClick}
    selected={selected}
  >
    {children}
    {selectable && (
      <IconsWrapper>
        {selected && <SelectedIcon disabled={disabled} />}
        <EmptySelection disabled={disabled} selected={selected} />
      </IconsWrapper>
    )}

  </FlatGridItemWrapper>
)

export {
  FlatItem,
  FlatGridItemImage,
  FlatGridItemText,
}
