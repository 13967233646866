import {connect} from 'react-redux'
import {Store} from 'types'
import SiteLocationsList from './SiteLocationsList'
import {fetchLocations, startLocationEdit} from './actions'
import {getSettingsConfig} from './selectors'
import {settingsGetItems} from '../actions'

const mapStateToProps = (state: Store) => ({
  isLoading: state.nsp.siteSettings.isLoadingLocations || state.nsp.isLoadingSettings,
  settingsConfig: getSettingsConfig(state),
})

export default connect(mapStateToProps, {
  fetchLocations,
  startLocationEdit,
  settingsGetItems,
})(SiteLocationsList)
