import React, {useState, useEffect} from 'react'
import {Storage} from 'platform'

import {Announcement, ANNOUNCEMENT_TYPE_TO_TRANSLATION_KEY} from './types'
import {useModuleAwareTranslation} from '../utils'
import {ConfirmationModal} from './index'

const DISMISSED_ANNOUNCEMENTS_STORAGE_KEY = 'dismissed_announcements'

interface Props {
  announcements: Announcement[]
}

export const AnnouncementDisplay = ({announcements}: Props) => {
  const {t} = useModuleAwareTranslation()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [dismissedAnnouncementsIds, setDismissedAnnouncementIds] = useState<string[]>([])
  const [initialized, setInitialized] = useState(false)
  const announcementsToUse = announcements.filter(announcement => !dismissedAnnouncementsIds.includes(announcement.id))

  useEffect(() => {
    Storage.getItem(DISMISSED_ANNOUNCEMENTS_STORAGE_KEY)
      .then(dismissedAnnouncementsFromStorage => {
        try {
          if (dismissedAnnouncementsFromStorage) {
            setDismissedAnnouncementIds(JSON.parse(dismissedAnnouncementsFromStorage))
          }
        } catch (error) {
          console.error(error)
          setDismissedAnnouncementIds([])
        }
        setInitialized(true)
      })
  }, [])

  const dismissAnnouncement = (id: string) => {
    setDismissedAnnouncementIds(oldIds => {
      const ids = [id, ...oldIds]
      Storage.setItem(DISMISSED_ANNOUNCEMENTS_STORAGE_KEY, JSON.stringify(ids))
      return ids
    })
  }

  // switch to next announcement
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (announcementsToUse.length > 1) {
        setCurrentIndex(prevIndex => (prevIndex + 1) % announcementsToUse.length)
      }
    }, 5000)
    return () => {
      clearInterval(intervalId)
    }
  }, [announcementsToUse])

  // if announcement is no longer available update index
  useEffect(() => {
    const isItemOutOfBounds = currentIndex >= announcementsToUse.length
    if (isItemOutOfBounds) {
      const nextIndex = Math.max(0, Math.min(currentIndex - 1, announcementsToUse.length - 1))
      setCurrentIndex(nextIndex)
    }
  }, [currentIndex, announcementsToUse])

  if (announcementsToUse.length === 0) {
    return null
  }

  return initialized && announcementsToUse[currentIndex] ? (
    <ConfirmationModal
      leftText
      title={t('Announcements title')}
      description={t(`Announcements ${ANNOUNCEMENT_TYPE_TO_TRANSLATION_KEY[announcementsToUse[currentIndex].type]}`)}
      confirmText={t('Common button close') }
      cancel={() => dismissAnnouncement(announcementsToUse[currentIndex].id)}
      confirm={() => dismissAnnouncement(announcementsToUse[currentIndex].id)}
    />
  ) : null
}

export default AnnouncementDisplay
