import React, {useCallback, useEffect, useState} from 'react'
import moment from 'moment'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import LoopIcon from '@material-ui/icons/Loop'
import styled from 'styled-components'
import {DatePicker} from '@material-ui/pickers'
import {TimePicker, List, ListItem, SimpleSwitch, ListItemText, GoToElementIcon, ListItemTextRight} from 'components'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {SCHEDULE_REPEAT_OPTIONS, SCHEDULE_TYPES_FOR_MODAL} from './constants'
import {
  getHoursAndMinutesFromScheduleExpression,
  getRepeatTypeBasedOnCronExpression,
  getScheduleTranslationKeyAndParams,
  setScheduleOptionsInRRule,
  setStartDateInRRule,
  setTimeInCron,
  setTimeInRRule,
} from './utils'
import {usePrevious} from '../../../utils'
import ScheduleTimeRepeatSettings from './ScheduleTimeRepeatSettings'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const ScheduleInfo = styled.div`
  word-break: normal;
  max-width: 200px;
  white-space: normal;
  padding: 5px 0;
  text-align: right;
  ${(props: {size: number}) => {
    if (props.size > 10) {
      return `font-size: 0.875rem;`
    }
  }}
`

const StyledDatePicker = styled(DatePicker)`
  display: none!important;
`

const StyledSelect = styled(Select)`
  &:before,
  &:after,
  .MuiSelect-nativeInput,
  svg {
    display: none!important;
  }
  
  .MuiSelect-root {
    padding: 0!important;
    width: auto;
  }

  .MuiSelect-root:focus {
    background-color: rgba(0, 0, 0, 0)!important;
  }
`

interface Props {
  className?: string
  date?: Date
  editDisabled?: boolean
  enabled?: boolean
  cronExpression: string
  rruleExpression: string
  weeklyRepetitions: number
  insideModal?: boolean
  setData: (date: Date, enabled: boolean, cronExpression: string, rruleExpression: string, weeklyRepetitions: number) => void
}

const ScheduleTimeSettings = ({
  className,
  editDisabled,
  enabled,
  date,
  setData,
  insideModal,
  weeklyRepetitions,
  cronExpression,
  rruleExpression,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [isDatePickerOpen, setDatePickerOpen] = useState(false)
  const [scheduleOption, setScheduleOption] = useState(getRepeatTypeBasedOnCronExpression(cronExpression, weeklyRepetitions))
  const previousCronExpression = usePrevious(cronExpression)
  const [isScheduleOptionOpen, setScheduleOptionOpen] = useState(false)
  const [showRepeatDetails, setShowRepeatDetails] = useState(false)
  const [cronExpressionForModal, setCronExpressionForModal] = useState('')
  const [rruleExpressionForModal, setRRuleExpressionForModal] = useState('')

  useEffect(() => {
    if ((!previousCronExpression && cronExpression) || (previousCronExpression !== cronExpression)) {
      setScheduleOption(getRepeatTypeBasedOnCronExpression(cronExpression, weeklyRepetitions))
    }
  }, [weeklyRepetitions, cronExpression, previousCronExpression])

  // schedule date used for tests
  const setHiddenDate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const maybeDateTimestamp = event.target.value
    if (maybeDateTimestamp) {
      setData(new Date(parseInt(maybeDateTimestamp, 10)), Boolean(enabled), cronExpression, rruleExpression, weeklyRepetitions)
    }
  }, [setData, enabled, cronExpression, rruleExpression, weeklyRepetitions])

  const setTime = useCallback((dateFromPicker: Date) => {
    const utcHours = dateFromPicker.getUTCHours()
    const utcMinutes = dateFromPicker.getUTCMinutes()
    const newCronExpression = setTimeInCron(cronExpression, utcHours, utcMinutes)
    const newRRuleExpression = setTimeInRRule(rruleExpression, utcHours, utcMinutes)
    setData(date || new Date(), Boolean(enabled), newCronExpression, newRRuleExpression, weeklyRepetitions)
  }, [setData, enabled, date, cronExpression, rruleExpression, weeklyRepetitions])

  const setDate = useCallback((dateFromPicker: MaterialUiPickersDate) => {
    if (!dateFromPicker) {
      return
    }
    const newDate = date ? new Date(date) : new Date()
    newDate.setUTCFullYear(
      dateFromPicker.toDate().getUTCFullYear(),
      dateFromPicker.toDate().getUTCMonth(),
      dateFromPicker.toDate().getUTCDate()
    )
    const newRRuleExpression = setStartDateInRRule(rruleExpression, newDate)
    setData(newDate, Boolean(enabled), cronExpression, newRRuleExpression, weeklyRepetitions)
  }, [setData, enabled, date, cronExpression, rruleExpression, weeklyRepetitions])

  const onChangeEnabled = useCallback((event: React.SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setData(date || new Date(), !enabled, cronExpression, rruleExpression, weeklyRepetitions)
  }, [setData, date, enabled, cronExpression, rruleExpression, weeklyRepetitions])

  const handleScheduleOptionChange = useCallback((event: React.ChangeEvent<{value: unknown}>) => {
    const repeatKey = event.target.value as string
    const {utcHours, utcMinutes} = getHoursAndMinutesFromScheduleExpression(cronExpression)
    let newCronExpression = SCHEDULE_REPEAT_OPTIONS.find(item => item.type === repeatKey)?.cronExpressionCreator() || cronExpression
    newCronExpression = setTimeInCron(newCronExpression, utcHours, utcMinutes)
    let newRRuleExpression = setScheduleOptionsInRRule(rruleExpression, repeatKey)
    newRRuleExpression = setTimeInRRule(newRRuleExpression, utcHours, utcMinutes)

    if (SCHEDULE_TYPES_FOR_MODAL.includes(repeatKey)) {
      if (repeatKey !== 'custom' && getRepeatTypeBasedOnCronExpression(cronExpression, weeklyRepetitions) !== repeatKey) {
        setCronExpressionForModal(newCronExpression)
        setRRuleExpressionForModal(newRRuleExpression)
      } else {
        setCronExpressionForModal(cronExpression)
        setRRuleExpressionForModal(cronExpression)
      }
      setShowRepeatDetails(true)
    } else {
      setScheduleOption(repeatKey)
      setData(date || new Date(), Boolean(enabled), newCronExpression, newRRuleExpression, weeklyRepetitions)
    }
  }, [setData, date, enabled, cronExpression, rruleExpression, weeklyRepetitions])

  const isTimestampZero = date?.getTime() === 0
  const momentDate = moment(isTimestampZero ? undefined : date)
  const dateLabel = momentDate.isSame(new Date(), 'day') ?
    t('Nav_Schedule settings today') :
    momentDate.format('L')
  const datePickerDate = isTimestampZero ?
    new Date() :
    date

  const disablePastDays = momentDate.isSameOrAfter(new Date(), 'day')

  const timePickerDate = new Date()
  const {hours, minutes} = getHoursAndMinutesFromScheduleExpression(cronExpression)
  timePickerDate.setHours(hours, minutes, 0, 0)

  return (
    <div className={className}>
      {(!showRepeatDetails || !insideModal) && (
        <>
          <TimePicker
            disabled={editDisabled}
            date={timePickerDate}
            onDateChange={setTime}
          />
          {/* input added for tests as updating TimePicker value from tests proved too time-consuming */}
          <input
            style={{display: 'none'}}
            data-testid="schedule-picker-date-input"
            type="text"
            onChange={setHiddenDate}
          />
          <List>
            <ListItem
              disabled={editDisabled}
              clickable={!editDisabled}
              fullWidth={!insideModal}
              fullWidthModal={insideModal}
              onClick={editDisabled ? undefined : () => setDatePickerOpen(true)}
            >
              <ListItemText>
                <CalendarTodayOutlinedIcon />
                {t('Nav_Schedule settings start date')}
              </ListItemText>
              <ListItemTextRight>
                {dateLabel}
                <GoToElementIcon />
              </ListItemTextRight>
            </ListItem>
            <ListItem
              disabled={editDisabled}
              clickable={!editDisabled}
              fullWidth={!insideModal}
              fullWidthModal={insideModal}
              onClick={isScheduleOptionOpen || editDisabled ? undefined : () => setScheduleOptionOpen(true)}
            >
              <ListItemText>
                <LoopIcon />
                {t('Nav_Schedule settings repeat')}
              </ListItemText>
              <ListItemTextRight>
                <StyledSelect
                  disabled={editDisabled}
                  onOpen={() => setScheduleOptionOpen(true)}
                  onClose={() => setScheduleOptionOpen(false)}
                  open={isScheduleOptionOpen}
                  value={scheduleOption}
                  onChange={handleScheduleOptionChange}
                  renderValue={() => {
                    const {title, params} = getScheduleTranslationKeyAndParams(rruleExpression ?
                      rruleExpression : cronExpression, weeklyRepetitions)
                    const text = t(title, params)
                    return (
                      <ScheduleInfo size={text.length}>
                        {text}
                      </ScheduleInfo>
                    )
                  }}
                >
                  {SCHEDULE_REPEAT_OPTIONS.map(option => (
                    <MenuItem
                      key={option.type}
                      value={option.type}
                      onClick={() => {
                        if (scheduleOption === option.type && SCHEDULE_TYPES_FOR_MODAL.includes(option.type)) {
                          setCronExpressionForModal(cronExpression)
                          setRRuleExpressionForModal(rruleExpression)
                          setShowRepeatDetails(true)
                        }
                      }}
                    >
                      {t(`Nav_Schedule settings repeat options ${option.type}`)}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <GoToElementIcon />
              </ListItemTextRight>
            </ListItem>
            <ListItem
              disabled={editDisabled}
              fullWidth={!insideModal}
              fullWidthModal={insideModal}
            >
              <ListItemText>
                {t('Nav_Schedule settings enabled')}
              </ListItemText>
              <SimpleSwitch
                disabled={editDisabled}
                checked={Boolean(enabled)}
                onClick={onChangeEnabled}
              />
            </ListItem>
          </List>
          <StyledDatePicker
            variant="dialog"
            disablePast={disablePastDays}
            open={isDatePickerOpen}
            value={datePickerDate}
            onClose={() => setDatePickerOpen(false)}
            onChange={(momentDate) => {
              setDate(momentDate)
              setDatePickerOpen(false)
            }}
            cancelLabel={t('Common button cancel')}
            okLabel={t('Common ok')}
            DialogProps={{
              onClose: () => setDatePickerOpen(false),
            }}
          />
        </>
      )}
      {showRepeatDetails && (
        <ScheduleTimeRepeatSettings
          date={datePickerDate || new Date()}
          cronExpression={cronExpressionForModal}
          rruleExpression={rruleExpressionForModal}
          weeklyRepetitions={weeklyRepetitions}
          save={(date, cronExpression, rruleExpression, weeklyRepetitions) => {
            setData(date, Boolean(enabled), cronExpression, rruleExpression, weeklyRepetitions)
            setShowRepeatDetails(false)
            setScheduleOption(getRepeatTypeBasedOnCronExpression(cronExpression, weeklyRepetitions))
            setCronExpressionForModal('')
            setRRuleExpressionForModal('')
          }}
          cancel={() => {
            setShowRepeatDetails(false)
            setCronExpressionForModal('')
            setRRuleExpressionForModal('')
          }}
        />
      )}
    </div>
  )
}

export default ScheduleTimeSettings
