import React, {useCallback, useContext, useEffect, useState} from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import moment from 'moment'
import {getDateAndTimeFormatForMoment, useModuleAwareTranslation} from 'utils'
import {List, Loader, EmptyList} from 'components'
import {DiagnosticEvent, Locations, SimplePatrol} from 'modules/navigation/types'
import {ReportListRow} from 'modules/navigation/report/shared'
import {ROUTES} from 'modules/navigation/constants'
import {getFromLocation, getLocationTranslation} from 'modules/navigation/utils'
import {getParentIfSameForAllLocations} from '../../utils'
import {RobotModulesContext} from 'app/types'
import {moduleName} from '../../constants'
import {MultipleEventsModal} from 'modules/navigation/patrolEvents'
import ReportEventSummary from 'modules/navigation/report/shared/ReportEventSummaryContainer'

interface Props {
  locations: Locations
  clearPatrolList: () => void
  fetchUvPatrols: (page: number, module: string) => void
  hasMoreReports: boolean
  historyType: string
  reports: SimplePatrol[]
  robotId?: string
  userId?: string
  setHistoryType: (historyType: string) => void
  showOngoingReportLink: boolean
}

const SanitizeOperationList = ({
  clearPatrolList,
  fetchUvPatrols,
  hasMoreReports,
  reports,
  robotId,
  showOngoingReportLink,
  historyType,
  setHistoryType,
  locations,
  userId,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const {currentModule} = useContext(RobotModulesContext)
  const [selectedEvent, setSelectedEvent] = useState<DiagnosticEvent[] | undefined>()
  const setCurrentEvent = useCallback((eventId: string) => {
    const event = reports.find(report => report?.standaloneEvent?.notificationUid === eventId)?.standaloneEvent
    setSelectedEvent(event ? [event] : undefined)
  }, [reports])

  useEffect(() => {
    return () => {
      clearPatrolList()
    }
  }, [])
  const fetchPatrols = useCallback((page: number) => {
    if (historyType === 'time') {
      fetchUvPatrols(page, moduleName)
    }
  }, [fetchUvPatrols, historyType])
  const setHistory = useCallback((type: string) => {
    clearPatrolList()
    setHistoryType(type)
    fetchPatrols(1)
  }, [setHistoryType, fetchPatrols, historyType])
  useEffect(() => {
    if (historyType !== 'time') {
      setHistory('time')
    }
  }, [historyType, setHistory])
  const dateAndTimeFormat = getDateAndTimeFormatForMoment(userId)
  const formatDate = (patrol: SimplePatrol) => moment(patrol.finishedAt).format(dateAndTimeFormat)
  const getRoomsText = (patrol: SimplePatrol) => t('uv_SanitizingHistory content sanitization history objects applied', {
    count: patrol.roomsChecked || 0,
  })
  const formatLocations = (patrol: SimplePatrol) => {
    const commonParentForAllLocations = getParentIfSameForAllLocations(locations, patrol.locations)
    if (!commonParentForAllLocations) {
      return ''
    }

    return `<strong>${getLocationTranslation(locations, commonParentForAllLocations)}</strong>\n`
  }

  const showOngoingOperationRowItem = showOngoingReportLink && currentModule === moduleName

  return (
    <>
      {robotId && !hasMoreReports && reports.length === 0 && (
        <EmptyList>
          {t('Nav_History content patrol history empty')}
        </EmptyList>
      )}
      {robotId && (
        <InfiniteScroll
          key={historyType}
          pageStart={0}
          loadMore={fetchPatrols}
          hasMore={hasMoreReports}
          loader={<Loader key={0} centered />}
        >
          <List>
            {showOngoingOperationRowItem && (
              <ReportListRow
                link={ROUTES.INDEX}
                text={t('Nav_History content Ongoing patrol')}
              />
            )}
            {reports.map(patrol => patrol.standaloneEvent ? (
              <ReportEventSummary
                key={patrol.standaloneEvent.notificationUid}
                event={patrol.standaloneEvent}
                eventLocation={getFromLocation(patrol.standaloneEvent)}
                locations={locations}
                setSelectedEvent={setCurrentEvent}
              />
            ): (
              <ReportListRow
                key={patrol.id}
                level={patrol.status}
                link={`/patrol/report/${patrol.id}`}
                text={`${formatDate(patrol)}\n${formatLocations(patrol)}${getRoomsText(patrol)}`}
              />
            ))}
          </List>
        </InfiniteScroll>
      )}
      {selectedEvent?.length && (
        <MultipleEventsModal
          close={() => setSelectedEvent(undefined)}
          events={selectedEvent}
          locations={locations}
          selectedEventId={selectedEvent[0].notificationUid}
          robotId={robotId as string}
          userId={userId}
        />
      )}
    </>
  )
}

export default SanitizeOperationList
