import React from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {GoToElementIcon, ListItem, Image, StatusIcon} from 'components'
import {useModuleAwareTranslation} from 'utils'
import {getLocationParts} from '../../../navigation/utils'
import {DiagnosticReason, Locations, PatrolEventLevel, SimplePatrol} from '../../../navigation/types'
import {SanitizationLevel, SanitizedObject} from '../../types'
import {serverUrl} from '../../../../config'
import moment from 'moment'

interface Props {
  locations: Locations
  sanitizedObject?: SanitizedObject
  patrol: SimplePatrol
}

const StyledListItem = styled(ListItem)`
    padding-top: 12px;
    padding-bottom: 12px;
`

const MainText = styled.div`
    flex: 1;
    margin: 0 12px;
    line-height: 1.2em;
    white-space: pre-line;
`

const ImageWrapper = styled.div`
    width: 50px;
    position: relative;
    
    svg {
      position: absolute;
      bottom: -6px;
      right: -6px;
      width: 30px;
      height: 30px;
      z-index: 6;
    }
      
    &:after {
      z-index: 4;
      content: ' ';
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      bottom: -4px;
      right: -4px;
      background: white;
      border-radius: 50%;
    }
`

const StyledImage = styled(Image)`
    width: 100%;
    height: auto;
`

const ReportObjectListRow = ({
  locations,
  sanitizedObject,
  patrol,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const history = useHistory()
  const {status, id: pointId} = patrol

  // @ts-ignore
  const level = patrol.details?.level || SanitizationLevel.LOW

  const [roomName, ...reversedLocationParts] = getLocationParts(locations, pointId).reverse()
  const locationParts = reversedLocationParts.reverse()
  const type = status === PatrolEventLevel.CAUTION ?
    DiagnosticReason.POINT_SKIPPED :
    DiagnosticReason.POINT_DONE

  return (
    <StyledListItem
      clickable
      fullWidth
      onClick={() => history.push(`/patrol/point/${pointId}`)}
    >
      <ImageWrapper>
        {sanitizedObject?.photo && (
          <StyledImage
            src={serverUrl + sanitizedObject.photo}
            placeholderSrc="/sanitization/no_photo.svg"
          />
        )}
        <StatusIcon level={status} />
      </ImageWrapper>
      <MainText>
        {t(`Nav_ReportEventView content ${type}`, {
          roomName: roomName || pointId,
          detailedLocation: locationParts.join(', ') || t('Nav_ReportEventView content unknown point').toLowerCase(),
          level: t(`uv_SanitizingObjects general ${level} uvc`),
        })} ({moment(patrol.finishedAt).fromNow()})
      </MainText>
      <GoToElementIcon />
    </StyledListItem>
  )
}

export default ReportObjectListRow
