import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import {useModuleAwareTranslation} from 'utils'
import {Button, Dialog, DialogActions, DialogContent, DialogFormTitle, List, ListItem} from 'components'
import {blueButton, white} from '../../../colors'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

interface Props {
  hasMultipleConfigurations: boolean
  currentConfigurationIndex: number
  originalName?: string
  isFullWidth?: boolean
  onSave: (name: string) => void
}

const StyledList = styled(List)`
  ${(props: {fullWidth?: boolean}) => !props.fullWidth ? 'margin: 16px 10px 0 10px;' : ''}
`

const SettingsNameEdit = ({
  hasMultipleConfigurations,
  currentConfigurationIndex,
  originalName,
  onSave,
  isFullWidth,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const [isEditing, setIsEditing] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [changedValue, setChangedValue] = useState('')

  useEffect(() => {
    setIsChanged(false)
  }, [originalName])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangedValue(event.target.value)
    setIsChanged(true)
  }

  const stopEditing = useCallback(() => {
    setIsEditing(false)
    setIsChanged(false)
    setChangedValue('')
  }, [])

  const confirm = useCallback((event: React.SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (changedValue.trim()) {
      onSave(changedValue.trim())
      stopEditing()
    }
  }, [onSave, stopEditing, changedValue])

  if (!hasMultipleConfigurations) {
    return null
  }

  const displayName: string = originalName ||
    t('Nav_Schedule subtitle configurations default name', {index: currentConfigurationIndex + 1})
  const editValue = isChanged ? changedValue : displayName
  return (
    <StyledList fullWidth={isFullWidth}>
      <ListItem fullWidth={isFullWidth}>
        <div>
          {t('Nav_Schedule subtitle configurations name label')}: <strong>{displayName}</strong>
        </div>
        <IconButton onClick={() => setIsEditing(true)}>
          <EditIcon color="primary" />
        </IconButton>
      </ListItem>
      <Dialog
        fullWidth
        open={isEditing}
        maxWidth="sm"
        onClose={stopEditing}
      >
        <form onSubmit={confirm}>
          <DialogContent>
            <DialogFormTitle>
              {t('Nav_Schedule subtitle configurations name label')}
            </DialogFormTitle>
            <TextField
              autoFocus
              placeholder={t('Nav_Schedule subtitle configurations name placeholder')}
              error={editValue.trim().length === 0}
              type="text"
              fullWidth
              onChange={handleChange}
              value={editValue}
            />
            <DialogActions $noHorizontalPadding>
              <Button onClick={stopEditing}>
                {t('Common button cancel')}
              </Button>
              <Button
                type="submit"
                $backgroundColor={blueButton}
                $textColor={white}
                disabled={editValue.trim().length === 0}
              >
                {t('Common button save')}
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </StyledList>
  )
}

export default SettingsNameEdit
