import React, {MouseEventHandler, useCallback} from 'react'
import {createStyles, Popover} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {useSelector} from 'react-redux'
import {FeatureToggle} from 'components'
import {PERMISSIONS} from '../../../constants'
import {Store} from '../../../types'

const useStyles = makeStyles(theme =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  })
)

interface Props {
  configUid: string
}

const ConfigTooltip = ({
  configUid,
}: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const robotId = useSelector((state: Store) => state.app.selectedRobot?.id)
  const settingsByRobot = useSelector((state: Store) => state.nsp.settingsByRobot)
  const settings = robotId && settingsByRobot[robotId]?.find(setting => setting.configurationUid === configUid)

  const handlePopoverOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handlePopoverClose: MouseEventHandler = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return settings ? (
    <FeatureToggle permissionName={PERMISSIONS.DEBUG_CONTENT}>
      <InfoOutlinedIcon
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>config uid: {settings.configurationUid}</div>
        <div>home uid: {settings.homeUid}</div>
        <div>scenario: {settings.scenario}</div>
        {settings.robotOnlyFields && Object.keys(settings.robotOnlyFields).map(field => (
          <div key={field}>{field}: {(settings.robotOnlyFields || {})[field]}</div>
        ))}
      </Popover>
    </FeatureToggle>
  ) : null
}

export default ConfigTooltip
