import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import Radio from '@material-ui/core/Radio'
import {
  List,
  ListItem,
  ListItemSubText,
  ListItemText,
  ListItemTextColumn,
  Loader,
  TextButton,
} from 'components'
import {useModuleAwareTranslation, usePrevious, useVariantModuleTranslation} from 'utils'
import {SettingsSubtitleContainer} from '../shared'
import {PageMainContent, PageTitle} from '../NSPCommonStyled'
import {ScheduleItem, SettingsConfigurationElement} from '../types'
import {Steps} from './types'
import ScheduleEditPageStepper from './ScheduleEditPageStepper'
import ScheduleTimeSettings from './ScheduleTimeSettings'
import {DEFAULT_CRON, DEFAULT_RRULE} from './constants'
import {scenarioToModule} from '../../utils'
import {getSettingsRouteForScenario} from '../utils'
import {ROUTES} from '../constants'
import {setInitialDateInRRule} from './utils'

interface Props {
  disabled: boolean
  step: Steps
  configurations: SettingsConfigurationElement[]
  editedScheduleItem: ScheduleItem | null
  scheduleItem?: ScheduleItem
  setStep: (step: Steps) => void
  setEditedItem: (item: ScheduleItem | null) => void
}

const ListItemTextColumnStyled = styled(ListItemTextColumn as any)`
  cursor: pointer;
  margin: 6px 0;
`

const getDateForPicker = (scheduleItem?: ScheduleItem | null) => {
  return scheduleItem ? scheduleItem.task.startDate : new Date()
}

const ScheduleEditPage = ({
  disabled,
  step,
  setStep,
  scheduleItem,
  editedScheduleItem,
  setEditedItem,
  configurations,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const {tCreator} = useVariantModuleTranslation()
  const history = useHistory()
  const previousStep = usePrevious(step)
  const [date, setDate] = useState(getDateForPicker(editedScheduleItem))
  const [editedWeeklyRepetitions, setWeeklyRepetitions] = useState(editedScheduleItem?.task.weeklyRepetitions)
  const [editedCronExpression, setCronExpression] = useState(editedScheduleItem?.task.cronExpression)
  const [editedRRuleExpression, setRRuleExpression] = useState(editedScheduleItem?.task.rruleExpression)
  const [editedScheduleEnabled, setScheduleEnabled] = useState(editedScheduleItem?.enabled)
  const itemId = scheduleItem?.id
  const editedItemId = editedScheduleItem?.id
  const hasDifferentScenarios = [...new Set(configurations.map(config => scenarioToModule(config.scenario)))].length > 1

  useEffect(() => {
    // if we pressed back or got redirected to the screen from configuration edit -> do not change step
    if (history.action !== 'POP') {
      setStep(Steps.TIME)
      setEditedItem(scheduleItem || null)
    }
  }, [])

  useEffect(() => {
    if (itemId && editedItemId && itemId !== editedItemId) {
      setEditedItem(scheduleItem || null)
    }
  }, [itemId, editedItemId])

  useEffect(() => {
    if (scheduleItem && !editedScheduleItem) {
      setEditedItem(scheduleItem)
    }
  }, [scheduleItem, editedScheduleItem])

  useEffect(() => {
    setDate(getDateForPicker(editedScheduleItem))
    setScheduleEnabled(editedScheduleItem?.enabled)
    setCronExpression(editedScheduleItem?.task.cronExpression)
    setRRuleExpression(setInitialDateInRRule(editedScheduleItem?.task.rruleExpression ?? ''))
    setWeeklyRepetitions(editedScheduleItem?.task.weeklyRepetitions)
  }, [editedScheduleItem])

  useEffect(() => {
    if (step !== previousStep && step === Steps.CONFIGURATION && editedScheduleItem && editedScheduleItem.id === itemId) {
      setEditedItem({
        ...editedScheduleItem,
        enabled: Boolean(editedScheduleEnabled),
        task: {
          startDate: date,
          cronExpression: editedCronExpression|| editedScheduleItem.task.cronExpression,
          rruleExpression: editedRRuleExpression || editedScheduleItem.task.rruleExpression,
          weeklyRepetitions: editedWeeklyRepetitions || editedScheduleItem.task.weeklyRepetitions,
        },
      })
    }
  }, [step, previousStep, editedScheduleItem, date, itemId,
    editedScheduleEnabled, editedCronExpression, editedRRuleExpression, editedWeeklyRepetitions])

  return (
    <>
      <PageTitle>{t('Nav_Schedule_Edit title')}</PageTitle>
      <SettingsSubtitleContainer
        localeKeys={{
          default: '',
          initializing: 'Nav_CommonSetting content patrol robot initializing',
          criticalState: 'Nav_CommonSetting content patrol robot critical',
          patrolConfigDown: 'Nav_CommonSetting content patrol robot limit finding not run',
          cannotSendNavCommand: 'Nav_Schedule content patrol no robot for schedule update',
          patrolling: 'Nav_Schedule content patrol schedule description patrol',
        }}
      />
      <PageMainContent>
        <ScheduleEditPageStepper
          step={step}
          setStep={setStep}
        />
        {!editedScheduleItem && (
          <Loader centered withTopMargin />
        )}
        {editedScheduleItem && step === Steps.TIME && (
          <ScheduleTimeSettings
            editDisabled={disabled}
            date={date}
            enabled={editedScheduleEnabled}
            cronExpression={editedCronExpression || DEFAULT_CRON}
            rruleExpression={editedRRuleExpression || DEFAULT_RRULE}
            weeklyRepetitions={editedWeeklyRepetitions || 1}
            setData={(date, enabled, cronExpression, rruleExpression, weeklyRepetitions) => {
              setDate(date)
              setScheduleEnabled(enabled)
              setCronExpression(cronExpression)
              setRRuleExpression(rruleExpression)
              setWeeklyRepetitions(weeklyRepetitions)
            }}
          />
        )}
        {editedScheduleItem && step === Steps.CONFIGURATION && (
          <List>
            {configurations.map(configuration => {
              const isConfigDisabled = configuration.selected === 0 || disabled
              const onClick = isConfigDisabled ?
                undefined :
                () => setEditedItem({...editedScheduleItem, configurationUid: configuration.id})
              const route = getSettingsRouteForScenario(configuration.scenario)
              const hideNavBar = route === ROUTES.SETTINGS ?
                '&hideNavBar=true' :
                ''
              return (
                <ListItem key={configuration.id}>
                  <ListItemText>
                    <Radio
                      disabled={isConfigDisabled}
                      checked={editedScheduleItem?.configurationUid === configuration.id}
                      onClick={onClick}
                    />
                    <ListItemTextColumnStyled
                      onClick={onClick}
                    >
                      {configuration.name || t('Nav_Schedule subtitle configurations default name', {
                        index: configuration.index + 1,
                      })}
                      <ListItemSubText>
                        {hasDifferentScenarios && (
                          <div>{t(`use-case ${scenarioToModule(configuration.scenario)}`).toUpperCase()}</div>
                        )}
                        {tCreator(scenarioToModule(configuration.scenario))('Nav_Schedule subtitle configurations selected', {
                          count: configuration.selected,
                          total: configuration.total,
                        })}
                      </ListItemSubText>
                    </ListItemTextColumnStyled>
                  </ListItemText>
                  <TextButton onClick={() => {
                    history.push(`${route}?id=${configuration.id}${hideNavBar}`)
                  }}>
                    {t('Common button edit')}
                  </TextButton>
                </ListItem>
              )
            })}
          </List>
        )}
      </PageMainContent>
    </>
  )
}

export default ScheduleEditPage
