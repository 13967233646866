import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {httpGet, REVERSE_ROOM_ORDER_KEY, REVERSE_ROOM_ORDER_VALUE, useModuleAwareTranslation} from 'utils'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogFormTitle,
  List,
  ListItem,
  ListItemSubText,
  ListItemText,
  ListItemTextColumn,
} from 'components'
import {blueButton, white} from '../../../colors'
import Radio from '@material-ui/core/Radio'
import {Locations} from '../types'
import {getLocationParts} from '../utils'

interface Props {
  originalHomeUid?: string
  isFullWidth?: boolean
  siteId?: string
  robotId?: string
  locations: Locations
  onSave: (name: string) => void
}

const StyledList = styled(List)`
  ${(props: {fullWidth?: boolean}) => !props.fullWidth ? 'margin: 16px 10px 0 10px;' : ''}
`

const SettingsHomeEdit = ({
  originalHomeUid,
  onSave,
  isFullWidth,
  robotId,
  siteId,
  locations,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const [homeLocations, setHomeLocations] = useState<string[]>([])
  const [isEditing, setIsEditing] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [changedValue, setChangedValue] = useState('')

  // fetch home locations on mount
  useEffect(() => {
    if (robotId && siteId) {
      httpGet(`v1/location/${siteId}/${robotId}/home`)
        .then(response => {
          if (response.status < 400) {
            return response.json()
          }

          throw new Error('error when fetching home locations')
        })
        .then((response: {locations: string[]}) => {
          setHomeLocations(response.locations || [])
        })
        .catch(error => console.log(error))
    } else {
      setHomeLocations([])
    }
  }, [robotId, siteId])

  useEffect(() => {
    setIsChanged(false)
  }, [originalHomeUid])

  const handleChange = (newHomeUid: string) => {
    setChangedValue(newHomeUid)
    setIsChanged(true)
  }

  const stopEditing = useCallback(() => {
    setIsEditing(false)
    setIsChanged(false)
    setChangedValue('')
  }, [])

  const confirm = useCallback((event: React.SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (changedValue.trim()) {
      onSave(changedValue.trim())
      stopEditing()
    }
  }, [onSave, stopEditing, changedValue])

  const editValue = isChanged ? changedValue : (originalHomeUid || '')

  const homesToUse = homeLocations.length > 0 ? homeLocations : [originalHomeUid]
  if (homesToUse.filter(home => home).length === 0) {
    // no home uids at all? do nothing
    return null
  }

  const isReverseLocationOrder = t(REVERSE_ROOM_ORDER_KEY) === REVERSE_ROOM_ORDER_VALUE
  const locationsToShow = (homeLocations).map(uid => {
    const [name, ...detailedLocationParts] = getLocationParts(locations, uid).reverse()
    return {
      uid,
      name: name || uid,
      detailedLocation: (isReverseLocationOrder ? detailedLocationParts.reverse() : detailedLocationParts).join(', '),
    }
  }).sort((itemA, itemB) => {
    const locationSort = itemA.detailedLocation.localeCompare(itemB.detailedLocation)
    if (locationSort !== 0) {
      return locationSort
    }
    return itemA.name.localeCompare(itemB.name)
  })

  const currentLocationData = locationsToShow.find(({uid}) => uid === originalHomeUid)
  const currentLocationText = currentLocationData ?
    `${currentLocationData.name}${currentLocationData?.detailedLocation ? `, ${currentLocationData.detailedLocation}` : ''}` :
    originalHomeUid

  return (
    <StyledList fullWidth={isFullWidth}>
      <ListItem fullWidth={isFullWidth}>
        <div>
          {t('Nav_Settings home location label')} <strong>{currentLocationText || <>&mdash;</>}</strong>
        </div>
        <IconButton onClick={() => setIsEditing(true)}>
          <EditIcon color="primary" />
        </IconButton>
      </ListItem>
      <Dialog
        fullWidth
        open={isEditing}
        maxWidth="sm"
        onClose={stopEditing}
      >
        <form onSubmit={confirm}>
          <DialogContent>
            <DialogFormTitle>
              {t('Nav_Settings home location edit title')}
            </DialogFormTitle>
            <List>
              {locationsToShow.map(({uid, name, detailedLocation}) => {
                const onClick = () => handleChange(uid)
                return (
                  <ListItem key={uid} onClick={onClick} clickable>
                    <ListItemText>
                      <Radio checked={editValue === uid} />
                      <ListItemTextColumn>
                        {name}
                        <ListItemSubText>
                          {detailedLocation}
                        </ListItemSubText>
                      </ListItemTextColumn>
                    </ListItemText>
                  </ListItem>
                )
              })}
            </List>
            <DialogActions $noHorizontalPadding>
              <Button onClick={stopEditing}>
                {t('Common button cancel')}
              </Button>
              <Button
                type="submit"
                $backgroundColor={blueButton}
                $textColor={white}
                disabled={editValue === originalHomeUid}
              >
                {t('Common button save')}
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </StyledList>
  )
}

export default SettingsHomeEdit
