import {connect} from 'react-redux'
import {Store} from 'types'
import {App} from './AppStyled'

const mapStateToProps = (state: Store) => ({
  withoutBottomBar: state.app.pageOptions.hideBottomNavBar,
  windowHeight: state.app.windowHeight,
  id: 'app',
})

export default connect(mapStateToProps)(App)
