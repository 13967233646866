import {useRef, useEffect, useState, RefObject, useContext} from 'react'
import {httpGetImage} from './requests'
import {useTranslation} from 'react-i18next'
import {TFunction} from 'i18next'
import {executeHooks, HOOK_LABELS, types} from '../framework'
import {RobotModulesContext} from '../app/types'

export function usePrevious<T>(value: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export function useClickOutside<T extends HTMLElement>(ref: RefObject<T>, onClickOutside?: CallableFunction) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (onClickOutside && ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside(event)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchend', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchend', handleClickOutside)
    }
  }, [ref, onClickOutside])
}

export function useAppToolbarHeight(trackHeight: boolean) {
  const [currentToolbarHeight, setToolbarHeight] = useState(0)

  function setHeight() {
    const toolbars =document.getElementsByClassName('dialog-aware-toolbar')
    if (toolbars.length > 0 && toolbars[0].parentElement) {
      setToolbarHeight(toolbars[0].parentElement.clientHeight)
    } else {
      setToolbarHeight(0)
    }
  }

  const mutationObserver = new MutationObserver(setHeight)

  useEffect(() => {
    if (trackHeight) {
      setHeight()
      window.addEventListener('resize', setHeight)
      const parentNode = document.getElementById('global-app-bar')
      if (parentNode) {
        mutationObserver.observe(parentNode, {childList: true, subtree: true})
      }
    }

    return () => {
      window.removeEventListener('resize', setHeight)
      mutationObserver.disconnect()
    }
  }, [trackHeight])

  return currentToolbarHeight
}

export function useServerImage(src?: string) {
  const [imageUrl, setImageUrl] = useState<string>('')

  useEffect(() => {
    let abortController: AbortController | undefined
    const fetchData = () => {
      if (src) {
        abortController = new AbortController()
        httpGetImage(src, abortController)
          .then(result => {
            if (result.status === 501) {
              throw new Error('network error happened')
            }
            return result.blob ? result.blob() : undefined
          })
          .then(blob => {
            if (blob) {
              // @ts-ignore
              const objectUrl = URL.createObjectURL(blob)
              setImageUrl(objectUrl)
            } else {
              setImageUrl(src)
            }
          })
          .catch(() => {
            // network error - retry
            if (abortController && !abortController.signal.aborted) {
              fetchData()
            }
          })
      } else {
        setImageUrl('')
      }
    }

    fetchData()

    return () => {
      if (abortController) {
        abortController.abort()
      }
    }
  }, [src])

  return imageUrl
}

export function useModuleAwareTranslation(modules?: string[]) {
  const {currentModule} = useContext(RobotModulesContext)
  const {t, i18n} = useTranslation()

  const moduleAwareTranslation: TFunction = (key: string, params?: any) => {
    const context: types.GenericKeyAdjustmentsParams = {
      defaultKey: key,
      key,
      i18n,
      currentModule: modules ? modules[0] : currentModule,
    }
    executeHooks(HOOK_LABELS.TRANSLATIONS.GENERIC_KEY_ADJUSTMENTS, context)
    return t(context.key, params)
  }

  return {t: moduleAwareTranslation, i18n}
}

export function useVariantModuleTranslation() {
  const {currentModule} = useContext(RobotModulesContext)
  const {t, i18n} = useTranslation()

  const tCreator = (module?: string): TFunction => (key: string, params?: any) => {
    const context: types.GenericKeyAdjustmentsParams = {
      defaultKey: key,
      key,
      i18n,
      currentModule: module || currentModule,
    }

    executeHooks(HOOK_LABELS.TRANSLATIONS.GENERIC_KEY_ADJUSTMENTS, context)
    return t(context.key, params)
  }

  return {
    tCreator,
    i18n,
  }
}

export function useNormalTranslation(guiUsecase: string) {
  const {currentModule} = useContext(RobotModulesContext)
  const {t, i18n} = useTranslation()

  const tCreator: TFunction = (key: string, params?: any) => {
    const context: types.GenericKeyAdjustmentsParams = {
      defaultKey: key,
      key,
      i18n,
      currentModule: currentModule,
    }

    if (guiUsecase === 'airport') {
      const airportKey = context.key + ' airport'
      if (i18n.exists(airportKey)) {
        executeHooks(HOOK_LABELS.TRANSLATIONS.GENERIC_KEY_ADJUSTMENTS, context)
        return t(airportKey, params)
      }
    }

    executeHooks(HOOK_LABELS.TRANSLATIONS.GENERIC_KEY_ADJUSTMENTS, context)
    return t(context.key, params)
  }

  return {
    t: tCreator,
    i18n,
  }
}
