import styled from 'styled-components'
import {primary} from '../colors'

interface ToolbarTitleProps {
    centered?: boolean
    color?: string
    withoutRightIcon?: boolean
    onClick?: () => void
}

export const ToolbarTitle = styled.div`
    flex-grow: 1;
    font-weight: 400;
    text-align: ${(props: ToolbarTitleProps) => props.centered ? 'center' : 'left'};
    margin-right: ${(props: ToolbarTitleProps) => props.withoutRightIcon ? '48px' : '0'};
    font-size: 1.25rem;
    ${(props: ToolbarTitleProps) => props.color ? `color: ${props.color};` : ''}
    ${(props: ToolbarTitleProps) => props.onClick ? `
        cursor: pointer;
        &:hover {
            color: ${primary};
        }
    ` : ''}
`

export const ToolbarTitleMainText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
