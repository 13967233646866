export const velocityCreator = ((minInterval = 30, maxInterval = 100) => {
  let previousTime = 0
  let previousY = 0
  let velocity = 0
  const recorder = {
    record: (y: number) => {
      const now = +new Date()
      velocity = (y - previousY) / (now - previousTime)
      if (now - previousTime >= minInterval) {
        velocity = now - previousTime <= maxInterval ? velocity : 0
        previousY = y
        previousTime = now
      }
    },
    getVelocity: (y: number) => {
      if (y !== previousY) {
        recorder.record(y)
      }
      return velocity
    },
    updatePreviousY: (diff: number) => {
      previousY += diff
    },
  }
  return recorder
})

export const computeChildIndex = (top: number, itemHeight: number, childrenLength: number, height?: number) => {
  let y = top
  if (height) {
    y = y % height
    if (y < 0) {
      y += height
    }
  }
  const index = Math.round(y / itemHeight)
  return Math.min(index, childrenLength - 1)
}
