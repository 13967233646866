import React, {useState} from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockIcon from '@material-ui/icons/Lock'
import {Dialog, DialogContent, DialogTitle, List, ListItem} from 'components'
import {Lock} from 'types'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const Content = styled.div`
  margin: 16px 0;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const StyledList = styled(List)`
  margin-top: 16px;
  max-height: 80vh;
`

interface Props {
  isModalOpen: boolean
  locks: Lock[]
  setLock: (lockId: string, shouldLock: boolean) => void
  openLocksModal: (shouldClose: boolean) => void
}

const LocksModal = ({
  isModalOpen,
  locks,
  setLock,
  openLocksModal,
}: Props) => {
  const [filter, setFilter] = useState('')
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const onClose = () => openLocksModal(true)
  const lowerFilter = filter.trim().toLowerCase()

  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <DialogTitle onClose={onClose}>
          {t('Dashboard dashboard door locks title')}
        </DialogTitle>
        <Content>
          <TextField
            autoFocus
            type="text"
            fullWidth
            helperText={t(`Dashboard dashboard door locks filter help`)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value)}
            value={filter}
          />
          <StyledList>
            {locks
              .filter(lock => !lowerFilter || lock.id.toLowerCase().includes(lowerFilter))
              .map(lock => (
                <ListItem
                  key={lock.id}
                  fullWidth
                  bolder
                >
                  {lock.id}
                  <Buttons>
                    <IconButton color="primary" onClick={() => setLock(lock.id, false)}>
                      <LockOpenIcon />
                    </IconButton>
                    <IconButton color="primary" onClick={() => setLock(lock.id, true)}>
                      <LockIcon />
                    </IconButton>
                  </Buttons>
                </ListItem>
              ))}
          </StyledList>
        </Content>
      </DialogContent>
    </Dialog>
  )
}

export default LocksModal
