import {connect} from 'react-redux'
import {match} from 'react-router'
import {Store} from '../../types'
import {
  addSnackbarMessage,
  setBugReportData,
  saveBugReport,
  triggerBugReportDataCollection,
  cancelBugReportDataCollection,
} from '../actions'
import BugReport from './BugReport'

type RoutMatch = match<{robotId?: string}>

const mapStateToProps = (state: Store, ownProps: {match: RoutMatch}) => ({
  triedToFetchRobots: state.app.triedToFetchRobots,
  siteId: state.app.selectedRobot?.siteId,
  bugReportData: state.app.bugReportData,
  isSavingBugReport: state.app.isSavingBugReport,
  robotId: ownProps.match.params.robotId,
})

export default connect(mapStateToProps, {
  setBugReportData,
  addSnackbarMessage,
  cancelBugReportDataCollection,
  triggerBugReportDataCollection,
  saveBugReport,
})(BugReport)
