import React from 'react'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {History} from 'history'
import {Robot, Store} from 'types'
import {addSnackbarMessage} from 'app'
import {cancelLimitFinding} from 'limitFindingWizard'
import {selectors} from 'shared'
import {ROUTES} from '../constants'
import {getActions} from './selectors'
import {fetchLocks, openGripperAutocalModal, openLocksModal} from './actions'
import {toggleGetStatisticsModal} from '../modules/navigation/siteSettings/actions'
import RobotActionsCard from './RobotActionsCard'

interface OwnProps {
  robot: Robot | null
}

const mapStateToProps = (state: Store) => ({
  actions: getActions(state),
  isRobotOnline: selectors.isCurrentRobotOnline(state),
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => ({
  availableActions: {
    cancelLimitFinding: () => {
      dispatch(cancelLimitFinding(ownProps.robot?.id))
      dispatch(addSnackbarMessage('Dashboard Cancel limit finding message sent to robot!'))
    },
    startLimitFinding: (history: History) => {
      history.push(ROUTES.LIMIT_FINDING_WIZARD, {from: ROUTES.HOME})
    },
    startGripperAutocal: () => dispatch(openGripperAutocalModal(false)),
    openLocksModal: () => dispatch(openLocksModal(false)),
    toggleGetStatisticsModal: () => dispatch(toggleGetStatisticsModal()),
  },
  fetchLocks: () => dispatch(fetchLocks()),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RobotActionsCard) as React.ComponentType<OwnProps>
