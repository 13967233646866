import styled from 'styled-components'
import {errorTitle} from '../colors'

export const PatrolStatus = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 -16px -16px -16px;

    > * {
        padding: 16px;
    }
`

export const StatusMainContent = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
`

interface StatusImageProps {
  image: string
  notReady?: boolean
  staticImage?: boolean
  forceFlex?: boolean
}

export const StatusImage = styled.div`
  min-height: 200px;
  margin-bottom: 12px;
  @media (min-height: 700px) {
    min-height: 250px;
    margin-bottom: 24px;
  }
  @media (min-height: 850px) {
    min-height: 320px;
    margin-bottom: 36px;
  }
      
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("${(props: StatusImageProps) => props.image}");
  display: flex;
  justify-content: center;
  align-items: center;
  transition: flex 0.3s linear;
  ${(props: StatusImageProps) => props.forceFlex || !props.staticImage ? 'flex: 1' : ''};
  ${(props: StatusImageProps) => props.staticImage ? `
      min-height: 250px;
      @media (min-height: 700px) {
          min-height: 350px;
      }
      
      @media (min-height: 780px) {
          min-height: 390px;
      }
  ` : ''}
`

export const StatusTextsWrapper = styled.div`
    padding: 8px 16px;
    flex: ${(props: {$justifyContent?: string, $flex?: string}) => props.$flex || '1'};
    display: flex;
    flex-direction: column;
    justify-content: ${(props: {$justifyContent?: string, $flex?: string}) => props.$justifyContent || 'flex-end'};
`

const StatusTexts = styled.div`
    text-align: center;
    line-height: 130%;
    width: 100%;
`

export const StatusTitle = styled(StatusTexts)`
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 4px;
    color: ${(props: {error?: boolean}) => props.error ? errorTitle : 'inherit'};
`

export const StatusDescription = styled(StatusTexts)`
    font-size: ${(props: {error?: boolean, $isLarge?: boolean}) => props.$isLarge ? '1.125rem' : '0.875rem'};
    font-weight: ${(props: {error?: boolean, $isLarge?: boolean}) => props.$isLarge ? 'bold' : 'inherit'};
    color: ${(props: {error?: boolean, $isLarge?: boolean}) => props.error ? errorTitle : 'inherit'};
    white-space: pre-line;
`

export const ListEmptyText = styled.div`
    font-size: 1.17rem;
    text-align: center;
     display: flex;
     flex: 1;
     align-items: center;
     justify-content: center;
`

export const PageMainContent = styled.div`
    flex: 1;

    ${(props: {isFullWidth?: boolean, withMarginTop?: boolean}) => props.isFullWidth ? `
        display: flex;
        flex-direction: column;
        margin: 0 -16px -16px -16px;

        > * {
            padding: 16px;
        }
    ` : ''}

    ${(props: {isFullWidth?: boolean, withMarginTop?: boolean}) => props.withMarginTop ? `
        margin-top: 32px;
    ` : ''}
`

export const PageActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    margin-bottom: -16px;
`
