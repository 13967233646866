import React, {useCallback, useEffect, useState} from 'react'
import moment from 'moment'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import styled from 'styled-components'
import {DatePicker} from '@material-ui/pickers'
import {
  List,
  ListItem,
  ListItemText,
  GoToElementIcon,
  ListItemTextRight,
  Dialog,
  DialogContent,
  DialogActions,
  TextButton,
  Button,
  DialogFormTitle,
} from 'components'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {MAX_COUNT_BY_OPTION, REPEAT_OPTIONS} from './constants'
import {black, blueButton, grayBackground, grayText, primary, white} from '../../../colors'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {
  getRadioSelectionFromExpression,
  getPositionOfWeekdayInMonth,
  isLastWeekdayOfMonth,
  getInitialIntervalType,
  prepareCronExpression,
  weekdaysOrder,
  prepareRRuleExpression,
  getHoursAndMinutesFromScheduleExpression,
  getDatesFromExpression,
  getIntervalFromExpression,
} from './utils'
import ScheduleRepeatInfo from './ScheduleRepeatInfo'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const StyledDatePicker = styled(DatePicker)`
  display: none!important;
`

const StyledSelect = styled(Select)`
  flex: 1;
  width: 100%;
`

const IntervalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  margin-bottom: 16px;
`

const Header = styled.div`
  text-transform: uppercase;
  color: ${grayText};
  margin-top: 12px;
`

const Weekdays = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0 16px 8px;
`

const Weekday = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-weight: 500;
  color: ${black};
  background-color: ${grayBackground};
  cursor: pointer;

  ${(props: {selected: boolean}) => {
    if (props.selected) {
      return `
        color: ${white};
        background-color: ${primary};
      `
    }
  }}
`

const Input = styled(TextField)`
  max-width: 70px;
  margin-right: 12px!important;

  .MuiInputBase-input {
    padding-top: 7px;
    padding-bottom: 7px;
  }
`

interface Props {
  date: Date
  cronExpression: string
  rruleExpression: string
  weeklyRepetitions: number
  save: (date: Date, cronExpression: string, rruleExpression: string, weeklyRepetitions: number) => void
  cancel: () => void
}

const ScheduleTimeRepeatSettings = ({
  date,
  save,
  cronExpression,
  rruleExpression,
  cancel,
  weeklyRepetitions,
}: Props) => {
  const expr = rruleExpression ? rruleExpression : cronExpression
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [pickerDate, setPickerDate] = useState(date)
  const [isDatePickerOpen, setDatePickerOpen] = useState(false)
  const [intervalType, setIntervalType] = useState(getInitialIntervalType(expr))
  const [selectedDays, setSelectedDays] = useState(getDatesFromExpression(expr))
  const [repeatInterval, setRepeatInterval] = useState(getIntervalFromExpression(expr, weeklyRepetitions))
  const [monthSelection, setMonthSelection] = useState(getRadioSelectionFromExpression(expr, 'day'))
  const [yearSelection, setYearSelection] = useState(getRadioSelectionFromExpression(expr, 'month'))

  const maxCount = MAX_COUNT_BY_OPTION[intervalType]

  useEffect(() => {
    const expr = rruleExpression ? rruleExpression : cronExpression
    setIntervalType(getInitialIntervalType(expr))
    setSelectedDays(getDatesFromExpression(expr))
    setRepeatInterval(getIntervalFromExpression(expr, weeklyRepetitions))
    setMonthSelection(getRadioSelectionFromExpression(expr, 'day'))
    setYearSelection(getRadioSelectionFromExpression(expr, 'month'))
  }, [cronExpression, rruleExpression, weeklyRepetitions])

  // check if current radio options are still available
  useEffect(() => {
    if (intervalType === 'month' && monthSelection !== 'day') {
      const showNthOption = getPositionOfWeekdayInMonth(pickerDate) !== 5
      const showLastOption = isLastWeekdayOfMonth(pickerDate)
      if (
        (!showNthOption && monthSelection === 'nth') ||
        (!showLastOption && monthSelection === 'last')
      ) {
        setMonthSelection('day')
      }
    } else if (intervalType === 'year' && yearSelection !== 'month') {
      const showNthOption = getPositionOfWeekdayInMonth(pickerDate) !== 5
      const showLastOption = isLastWeekdayOfMonth(pickerDate)
      if (
        (!showNthOption && yearSelection === 'nth') ||
        (!showLastOption && yearSelection === 'last')
      ) {
        setYearSelection('month')
      }
    }
  }, [pickerDate, intervalType, yearSelection, monthSelection])

  useEffect(() => {
    if (!maxCount) {
      setRepeatInterval(1)
    } else if (repeatInterval > maxCount) {
      setRepeatInterval(maxCount)
    }
  }, [intervalType, maxCount, repeatInterval])

  const setDate = useCallback((dateFromPicker: MaterialUiPickersDate) => {
    if (!dateFromPicker) {
      return
    }

    const newDate = date ? new Date(date) : new Date()
    newDate.setUTCFullYear(
      dateFromPicker.toDate().getUTCFullYear(),
      dateFromPicker.toDate().getUTCMonth(),
      dateFromPicker.toDate().getUTCDate()
    )
    setPickerDate(newDate)
  }, [date])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatInterval(parseInt(event.target.value, 10))
  }

  const handleRepeatOptionChange = (event: React.ChangeEvent<{value: unknown}>) => {
    const intervalType = event.target.value as string
    setIntervalType(intervalType)
    if (intervalType === 'day') {
      setSelectedDays([0, 1, 2, 3, 4, 5, 6])
    }
  }

  const selectDay = useCallback((index: number) => {
    setSelectedDays(days => {
      const shouldRemove = days.includes(index)
      if (shouldRemove && days.length === 1) {
        return days
      }

      return shouldRemove ? days.filter(day => day !== index) : [...days, index]
    })
    if (intervalType === 'day') {
      setIntervalType('week')
    }
  }, [intervalType])

  const handleMonthSettingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonthSelection((event.target as HTMLInputElement).value)
  }

  const handleYearSettingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYearSelection((event.target as HTMLInputElement).value)
  }

  const momentDate = moment(pickerDate)
  const dateLabel = momentDate.isSameOrBefore(new Date(), 'day') ?
    t('Nav_Schedule settings today') :
    momentDate.format('L')
  const weekdays = moment().localeData().weekdaysMin()

  const {utcHours, utcMinutes} = getHoursAndMinutesFromScheduleExpression(rruleExpression ? rruleExpression : cronExpression)
  const preparedExpression = prepareCronExpression(pickerDate, intervalType, repeatInterval,
    selectedDays, monthSelection, yearSelection, utcHours, utcMinutes)
  const preparedRRuleExpression = prepareRRuleExpression(pickerDate, intervalType, repeatInterval,
    selectedDays, monthSelection, yearSelection, utcHours, utcMinutes)

  const saveForm = useCallback(() => {
    const weeklyRepetitions = intervalType === 'week' ? repeatInterval : 0
    save(pickerDate, preparedExpression, preparedRRuleExpression, weeklyRepetitions)
  }, [preparedExpression, preparedRRuleExpression, save, pickerDate,
    intervalType, repeatInterval, selectedDays, monthSelection, yearSelection])

  return (
    <Dialog
      fullWidth
      open
      onClose={() => {}}
      maxWidth="xs"
    >
      <DialogContent>
        <DialogFormTitle>
          {t('Nav_Schedule settings repeat settings title')}
        </DialogFormTitle>
        <form>
          <List>
            <ListItem
              noBorder
              clickable
              fullWidthModal
              onClick={() => setDatePickerOpen(true)}
            >
              <ListItemText>
                <CalendarTodayOutlinedIcon />
                {t('Nav_Schedule settings start date')}
              </ListItemText>
              <ListItemTextRight>
                {dateLabel}
                <GoToElementIcon />
              </ListItemTextRight>
            </ListItem>
          </List>
          <StyledDatePicker
            variant="dialog"
            disablePast
            open={isDatePickerOpen}
            value={pickerDate}
            onClose={() => setDatePickerOpen(false)}
            onChange={(momentDate) => {
              setDate(momentDate)
              setDatePickerOpen(false)
            }}
            cancelLabel={t('Common button cancel')}
            okLabel={t('Common ok')}
            DialogProps={{
              onClose: () => setDatePickerOpen(false),
            }}
          />
          <Header>
            {t('Nav_Schedule settings repeat settings interval header')}
          </Header>
          <IntervalWrapper>
            <Input
              variant="outlined"
              type="number"
              disabled={!maxCount}
              fullWidth
              onChange={handleChange}
              value={repeatInterval}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: maxCount,
                },
              }}
            />
            <StyledSelect
              value={intervalType}
              onChange={handleRepeatOptionChange}
            >
              {REPEAT_OPTIONS.map(option => (
                <MenuItem
                  key={option}
                  value={option}
                >
                  {t(`Nav_Schedule settings repeat settings interval option ${option}`, {count: repeatInterval})}
                </MenuItem>
              ))}
            </StyledSelect>
          </IntervalWrapper>
          {intervalType !== 'hour' && !(intervalType === 'day' && repeatInterval > 1) && (
            <>
              <Header>
                {t('Nav_Schedule settings repeat settings interval settings header')}
              </Header>
              {((intervalType === 'day' && repeatInterval === 1) || intervalType === 'week') && (
                <Weekdays>
                  {weekdaysOrder.map(weekdayIndex => (
                    <Weekday
                      key={weekdayIndex}
                      selected={selectedDays.includes(weekdayIndex)}
                      onClick={() => selectDay(weekdayIndex)}
                    >
                      {weekdays[weekdayIndex]}
                    </Weekday>
                  ))}
                </Weekdays>
              )}
              {intervalType === 'month' && (
                <RadioGroup
                  name="schedule-month"
                  onChange={handleMonthSettingChange}
                  value={monthSelection}
                >
                  <FormControlLabel
                    value="day"
                    control={<Radio />}
                    label={t(
                      'Nav_Schedule settings repeat settings interval settings by month day option',
                      {day: pickerDate.getDate()})}
                  />
                  {getPositionOfWeekdayInMonth(pickerDate) !== 5 && (
                    <FormControlLabel
                      value="nth"
                      control={<Radio />}
                      label={t(
                        'Nav_Schedule settings repeat settings interval settings by month nth day option',
                        {
                          // @ts-ignore
                          nth: momentDate.localeData().ordinal(getPositionOfWeekdayInMonth(pickerDate), 'D'),
                          weekday: momentDate.format('dddd'),
                        }
                      )}
                    />
                  )}
                  {isLastWeekdayOfMonth(pickerDate) && (
                    <FormControlLabel
                      value="last"
                      control={<Radio />}
                      label={t(
                        'Nav_Schedule settings repeat settings interval settings by month last option',
                        {weekday: momentDate.format('dddd')}
                      )}
                    />
                  )}
                </RadioGroup>
              )}
              {intervalType === 'year' && (
                <RadioGroup
                  name="schedule-year"
                  onChange={handleYearSettingChange}
                  value={yearSelection}
                >
                  <FormControlLabel
                    value="month"
                    control={<Radio />}
                    label={t(
                      'Nav_Schedule settings repeat settings interval settings by year month option',
                      {month: momentDate.format('MMMM'), day: pickerDate.getDate()}
                    )}
                  />
                  {getPositionOfWeekdayInMonth(pickerDate) !== 5 && (
                    <FormControlLabel
                      value="nth"
                      control={<Radio />}
                      label={t(
                        'Nav_Schedule settings repeat settings interval settings by year nth day option',
                        {
                          month: momentDate.format('MMMM'),
                          weekday: momentDate.format('dddd'),
                          // @ts-ignore
                          nth: momentDate.localeData().ordinal(getPositionOfWeekdayInMonth(pickerDate), 'D'),
                        }
                      )}
                    />
                  )}
                  {isLastWeekdayOfMonth(pickerDate) && (
                    <FormControlLabel
                      value="last"
                      control={<Radio />}
                      label={t(
                        'Nav_Schedule settings repeat settings interval settings by year last option',
                        {
                          month: momentDate.format('MMMM'),
                          weekday: momentDate.format('dddd'),
                        }
                      )}
                    />
                  )}
                </RadioGroup>
              )}
            </>
          )}
          <ScheduleRepeatInfo
            cronExpression={preparedExpression}
            rruleExpression={preparedRRuleExpression}
            weeklyRepetitions={intervalType === 'week' ? repeatInterval : 1}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <TextButton
          data-testid="schedule-cancel-button"
          onClick={cancel}
        >
          {t('Common button cancel')}
        </TextButton>
        <Button
          data-testid="schedule-save-button"
          onClick={saveForm}
          $backgroundColor={blueButton}
          $textColor={white}
        >
          {t('Common button save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ScheduleTimeRepeatSettings
