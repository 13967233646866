import {buildAction} from 'utils'
import * as constants from './constants'

export const fetchObjects = buildAction(constants.FETCH_OBJECTS)
export const fetchObjectsError = buildAction(constants.FETCH_OBJECTS_ERROR, 'error')
export const fetchObjectsSuccess = buildAction(constants.FETCH_OBJECTS_SUCCESS, 'objects')

export const saveObjects = buildAction(constants.SAVE_OBJECT_SETTINGS, 'onSuccess')
export const saveObjectSuccess = buildAction(constants.SAVE_OBJECT_SETTINGS_SUCCESS, 'configurationUid', 'objects')

export const clearEditedObjects = buildAction(constants.CLEAR_EDITED_OBJECTS)
export const setEditedObjects = buildAction(constants.SET_EDITED_OBJECTS, 'objects')

export const setName = buildAction(constants.SET_NAME, 'name')
export const setHomeUid = buildAction(constants.SET_HOME_UID, 'homeUid')
