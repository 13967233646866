import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import {useModuleAwareTranslation} from 'utils'
import {PatrolDetails} from '../../types'

const Summary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 0;
`

const SummaryItem = styled.div`
  > :first-child {
    height: 48px;
  }
  
  > :last-child {
    margin-top: -4px;
  }
`

const RoomsCheckedWrapper = styled(SummaryItem)`
  text-align: left;
`

const TotalTime = styled(SummaryItem)`
  text-align: right;
`

const LargerText = styled.span`
  font-size: 24px;
`

const XLargeText = styled.span`
  font-size: 48px;
  line-height: 48px;
`

interface Props {
  details: PatrolDetails
}

const ReportSummaryTotals = ({
  details,
}: Props) => {
  const {t} = useModuleAwareTranslation(details.modules)
  const {
    startedAt,
    finishedAt,
    totalRooms,
    roomsChecked,
  } = details
  const hoursDiff = startedAt && finishedAt ?
    moment(finishedAt).diff(startedAt, 'hours') :
    0
  const minutesDiff = startedAt && finishedAt ?
    moment(finishedAt).diff(startedAt, 'minutes') - (hoursDiff * 60) :
    0

  return (
    <Summary>
      <RoomsCheckedWrapper>
        <div>
          {totalRooms ? (
            <>
              <XLargeText>{roomsChecked}</XLargeText>
              <LargerText>/</LargerText>
              <XLargeText>{totalRooms}</XLargeText>
            </>
          ) : <XLargeText>&mdash;</XLargeText>}
        </div>
        <div>{t('Nav_Report content checkedCount', {count: roomsChecked || 0})}</div>
      </RoomsCheckedWrapper>
      <TotalTime>
        <div>
          {startedAt && finishedAt && startedAt !== finishedAt ? (
            <>
              <XLargeText>{hoursDiff}</XLargeText>
              <LargerText>{t('Nav_Report content duration short hours')}</LargerText>
              <XLargeText>{minutesDiff}</XLargeText>
              <LargerText>{t('Nav_Report content duration short minutes')}</LargerText>
            </>
          ) : <XLargeText>&mdash;</XLargeText>}
        </div>
        <div>{t('Nav_Report content duration')}</div>
      </TotalTime>
    </Summary>
  )
}

export default ReportSummaryTotals
