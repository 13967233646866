import {
  all,
  takeEvery,
  takeLatest,
  put,
  select,
} from 'redux-saga/effects'
import {AnyAction} from 'redux'
import {httpGet, getResponseBody, getSelectedSiteAndRobotId} from 'utils'
import * as constants from './constants'
import {setPatrolDetails, patrolDetailsLoaded, fetchPatrolListFailed, fetchPatrolListSuccess} from './actions'
import {Store} from '../../../types'

function* fetchPatrolDetails() {
  yield takeLatest<AnyAction>(constants.FETCH_PATROL_DETAILS, function* (action) {
    const {patrolId} = action
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    if (!robotId) {
      return
    }

    const endpoint = patrolId ?
      `v1/nsp/robot/${siteId}/${robotId}/patrol/details/${patrolId}` :
      `v1/nsp/robot/${siteId}/${robotId}/patrol/details`
    const response: Response = yield httpGet(endpoint)
    const {status} = response
    if (status < 300) {
      const details = yield getResponseBody(response)
      yield put(setPatrolDetails(details))
    }

    yield put(patrolDetailsLoaded())
  })
}

const FETCH_REPORTS_LIMIT = 20

function* fetchReportList() {
  yield takeEvery<AnyAction>(constants.FETCH_PATROL_LIST, function* (action) {
    const {page, module} = action
    const pageToLoad = page - 1
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    if (!robotId) {
      return
    }

    const params = `skip=${pageToLoad*FETCH_REPORTS_LIMIT}&limit=${FETCH_REPORTS_LIMIT}&module=${module}`
    const endpoint = `v1/nsp/robot/${siteId}/${robotId}/patrol?${params}`
    const response: Response = yield httpGet(endpoint)
    const {status} = response
    const reports = yield getResponseBody(response)
    const historyType = yield select((state: Store) => state.uv.report.historyType)
    if (module === 'uv' && historyType === 'object') {
      // we got response with reports but we expected objects, ignore the response
      return
    }

    if (status < 300) {
      const hasMore = reports.length === FETCH_REPORTS_LIMIT
      yield put(fetchPatrolListSuccess(page, reports, hasMore))
    } else {
      yield put(fetchPatrolListFailed(reports))
    }
  })
}

function* reportSagas() {
  yield all([
    fetchPatrolDetails(),
    fetchReportList(),
  ])
}

export default reportSagas
