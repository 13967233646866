import React from 'react'
import styled from 'styled-components'
import LocationOnIcon from '@material-ui/icons/LocationOn'

interface Props {
   className?: string
}

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Icon = styled(LocationOnIcon)`
  position: relative;
  left: 0.01em;
`

const DotsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: 0.05em;
  height: 0.5em;
`

const Dot = styled.div`
  margin: -0.5em 0.05em 0 0.05em;
  &:before {
    font-size: 2em;
    content: '.';
  }
`

const RobotStoppedIcon = ({className}: Props) => (
  <IconWrapper className={className}>
    <Icon />
    <DotsWrapper>
      <Dot />
      <Dot />
      <Dot />
    </DotsWrapper>
  </IconWrapper>
)

export default RobotStoppedIcon
