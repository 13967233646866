import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import DialogContent from '@material-ui/core/DialogContent'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import {FullScreenModal, Loader} from 'components'
import {usePrevious} from 'utils'
import {EVENT_NOTE_OPTIONS} from './constants'
import {DiagnosticEvent} from '../types'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  isOpen: boolean
  isSaving: boolean
  event: DiagnosticEvent
  close: () => void
  saveEventNote: (eventId: string, note?: string, noteSelectedActions?: string[]) => void
  saveSuccessful: boolean
}

const StyledLoader = styled(Loader)`
  margin-top: 36px;
`

const EventNote: React.FC<Props> = ({
  event,
  isOpen,
  isSaving,
  close,
  saveEventNote,
  saveSuccessful,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const previousSaveSuccessful = usePrevious(saveSuccessful)
  const [note, setNote] = useState('')
  const [selectedItems, setSelectedItems] = useState([] as string[])

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value)
  }

  const handleSelectedItems = useCallback((value: string, checked: boolean) => {
    if (checked) {
      setSelectedItems([...selectedItems, value])
    } else {
      setSelectedItems(selectedItems.filter(item => item !== value))
    }
  }, [selectedItems])

  useEffect(() => {
    if (isOpen) {
      setSelectedItems(event.note?.noteSelectedActions || [])
      setNote(event.note?.note || '')
    }
  }, [isOpen])

  const submit = useCallback(() => {
    saveEventNote(event.notificationUid, note, selectedItems)
  }, [event, note, selectedItems])

  useEffect(() => {
    if (!previousSaveSuccessful && saveSuccessful) {
      close()
    }
  }, [previousSaveSuccessful, saveSuccessful])

  return (
    <FullScreenModal
      isOpen={isOpen}
      title={t('Nav_EventNote title Event note')}
      close={close}
      save={submit}
    >
      {isSaving ? (
        <StyledLoader
          centered
        />
      ) : (
        <DialogContent>
          <FormLabel component="legend">{t('Nav_EventNote content Performed actions')}</FormLabel>
          <FormGroup>
            {EVENT_NOTE_OPTIONS.map(option => (
              <FormControlLabel
                key={option.value}
                control={(
                  <Checkbox
                    checked={selectedItems.includes(option.value)}
                    color="primary"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                      handleSelectedItems(option.value, checked)
                    }
                    name={option.value}
                  />
                )}
                label={t(option.translationKey)}
              />
            ))}
          </FormGroup>
          <FormLabel component="legend" style={{marginTop: '24px'}}>{t('Nav_EventNote content Custom note')}</FormLabel>
          <FormGroup>
            <TextField
              type="text"
              fullWidth
              multiline
              onChange={handleNoteChange}
              value={note}
            />
          </FormGroup>
        </DialogContent>
      )}
    </FullScreenModal>
  )
}

export default EventNote
