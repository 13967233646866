/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  all,
  put,
  takeLatest,
  select,
  debounce,
  delay,
  takeEvery,
} from 'redux-saga/effects'
import {AnyAction} from 'redux'
import {Store} from 'types'
import {getSelectedSiteAndRobotId} from 'utils'
import * as constants from './constants'
import {FETCH_LOCATIONS_FINISHED} from '../constants'
import {APP_INITIALIZE, SET_SITE_ID} from '../../../../app/constants'
import {setNotificationsEnabled, setNotificationSettings} from './actions'
import {Locations} from '../../types'

const notificationStore = 'dataForNotifications'
const notificationStoreKey = 'id'
const siteSettingsStore = 'siteSettings'

let db: IDBDatabase | undefined

if (window.indexedDB) {
  const request = window.indexedDB.open('NotificationsDb', 3)
  request.onerror = function() {
    console.log('Why didn\'t you allow my web app to use IndexedDB?!')
  }
  request.onsuccess = function(event: any) {
    db = event.target.result as IDBDatabase
  }
  request.onupgradeneeded = function(event: any) {
    db = event.target.result as IDBDatabase
    if (event.newVersion > 2 && event.oldVersion < 2) {
      db.createObjectStore(notificationStore, {keyPath: notificationStoreKey})
    }

    if (event.newVersion === 3) {
      db.createObjectStore(siteSettingsStore)
    }
  }
}

function* loadNotificationSettings() {
  yield takeLatest<AnyAction>([APP_INITIALIZE, SET_SITE_ID], function* () {
    let siteId = (yield getSelectedSiteAndRobotId()).siteId
    while (!siteId) {
      yield delay(500)
      siteId = (yield getSelectedSiteAndRobotId()).siteId
    }

    if (!db) {
      return
    }

    const transaction = db.transaction([siteSettingsStore])
    const objectStore = transaction.objectStore(siteSettingsStore)
    const currentSiteData = yield new Promise((resolve) => {
      objectStore.get(siteId).onsuccess = function(event: any) {
        resolve(event.target.result)
      }
    })
    if (currentSiteData) {
      const enabled = typeof currentSiteData.enabled !== 'undefined' ? currentSiteData.enabled : true
      yield put(setNotificationsEnabled(enabled))
      yield put(setNotificationSettings(currentSiteData.settings || {}))
    }
  })
}

function* locationsFetchedSuccess() {
  yield takeLatest<AnyAction>(FETCH_LOCATIONS_FINISHED, function* (action) {
    const {siteId} = yield getSelectedSiteAndRobotId()
    if (!db) {
      return
    }

    const locations: Locations = action.locations
    const currentSettings: {[locationId: string]: boolean} = yield select(
      (state: Store) => state.nsp.siteSettings.notifications.notificationSettings)
    const transaction = db.transaction([siteSettingsStore], 'readwrite')
    const objectStore = transaction.objectStore(siteSettingsStore)
    objectStore.get(siteId).onsuccess = function(event: any) {
      const currentSiteData = event.target.result
      const newSettings = Object.keys(locations).reduce((result, locationId) => {
        let isLocationEnabled = true
        if (currentSiteData?.settings && typeof currentSiteData.settings[locationId] !== 'undefined') {
          isLocationEnabled = currentSiteData.settings[locationId]
        } else if (currentSettings && typeof currentSettings[locationId] !== 'undefined') {
          isLocationEnabled = currentSettings[locationId]
        }
        return {
          ...result,
          [locationId]: isLocationEnabled,
        }
      }, {})
      if (currentSiteData) {
        objectStore.put({
          ...currentSiteData,
          settings: newSettings,
        }, siteId)
      } else {
        objectStore.add({
          enabled: true,
          settings: newSettings,
        }, siteId)
      }
    }
  })
}

function* toggleItemSaga() {
  yield takeEvery<AnyAction>(constants.TOGGLE_SETTING_ITEM, function* (action) {
    const {enabled, locationId} = action
    const locations: Locations = yield select((state: Store) => state.nsp.siteSettings.locations)
    const currentSettings = yield select((state: Store) => state.nsp.siteSettings.notifications.notificationSettings)
    const updates: {[locationId: string]: boolean} = {
      ...(currentSettings || {}),
      [locationId]: enabled,
    }

    const processChildren = (locationId: string) => {
      const childrenIds = Object.entries(locations)
        .filter(([_, location]) => location.parentId === locationId)
        .map(([locationId]) => locationId)
      for (const childId of childrenIds) {
        updates[childId] = enabled
        processChildren(childId)
      }
    }

    processChildren(locationId)

    if (!enabled) {
      // toggle parents as disabled too
      let parentId = locations[locationId]?.parentId
      while (parentId) {
        updates[parentId] = enabled
        parentId = locations[parentId]?.parentId
      }
    } else {
      // if all siblings are enabled then set parent as enabled too
      let parentId = locations[locationId]?.parentId
      let siblingIds: string[] = []
      while (parentId) {
        siblingIds = Object.entries(locations)
          .filter(([siblingId, location]) => location.parentId === parentId && siblingId !== locationId)
          .map(([siblingId]) => siblingId)
        if (siblingIds.every(siblingId => updates[siblingId])) {
          // all are enabled, set parent as enabled too
          updates[parentId] = enabled
          parentId = locations[parentId]?.parentId
        } else {
          // siblings are in different states
          break
        }
      }
    }

    yield put(setNotificationSettings(updates))
  })
}

function* handleSettingsEnabledUpdate() {
  yield debounce(1000, constants.SET_NOTIFICATIONS_ENABLED, settingsEnabledUpdated)
}

function* settingsEnabledUpdated(action: AnyAction) {
  const {siteId} = yield getSelectedSiteAndRobotId()
  if (!siteId) {
    return
  }

  const {enabled} = action
  updateSiteDataInDb({enabled}, siteId)
}

const updateSiteDataInDb = (update: object, siteId: string) => {
  if (!db) {
    return
  }

  const transaction = db.transaction([siteSettingsStore], 'readwrite')
  const objectStore = transaction.objectStore(siteSettingsStore)
  objectStore.get(siteId).onsuccess = (event: any) => {
    const currentSiteData = event.target.result
    if (currentSiteData) {
      objectStore.put({
        ...currentSiteData,
        ...update,
      }, siteId)
    } else {
      objectStore.add(update, siteId)
    }
  }
}

function* handleNotificationSettingsUpdate() {
  yield debounce(1000, constants.SET_NOTIFICATION_SETTINGS, notificationSettingsUpdated)
}

function* notificationSettingsUpdated(action: AnyAction) {
  const {siteId} = yield getSelectedSiteAndRobotId()
  if (!siteId) {
    return
  }

  const {settings} = action
  updateSiteDataInDb({settings}, siteId)
}

function* appSagas() {
  yield all([
    loadNotificationSettings(),
    locationsFetchedSuccess(),
    toggleItemSaga(),
    handleSettingsEnabledUpdate(),
    handleNotificationSettingsUpdate(),
  ])
}

export default appSagas
