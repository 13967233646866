import React from 'react'
import {useModuleAwareTranslation} from 'utils'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import styled from 'styled-components'
import {background} from '../../../colors'
import {Steps} from './types'

const StyledStepper = styled.div`
    .MuiPaper-root {
        background: ${background};
    }
    .MuiSvgIcon-root {
        width: 2rem;
        height: 2rem;
    }
    .MuiStepIcon-text {
        font-size: 1rem;
        transform: translate(0px, 1px);
    }
    .MuiStepLabel-root {
      cursor: pointer!important;
    }
`

interface Props {
  step: Steps
  setStep: (step: Steps) => void
}

const ScheduleEditPageStepper = ({
  step,
  setStep,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  return (
    <StyledStepper>
      <Stepper activeStep={step === Steps.TIME ? 0 : 1} alternativeLabel>
        <Step key={Steps.TIME} onClick={() => setStep(Steps.TIME)}>
          <StepLabel>{t('Nav_Schedule_Edit time')}</StepLabel>
        </Step>
        <Step key={Steps.CONFIGURATION} onClick={() => setStep(Steps.CONFIGURATION)}>
          <StepLabel>{t('Nav_Schedule_Edit configuration')}</StepLabel>
        </Step>
      </Stepper>
    </StyledStepper>
  )
}

export default ScheduleEditPageStepper
