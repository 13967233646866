import React, {useCallback, useEffect, useState} from 'react'
import {
  ListItem,
  SimpleSwitch,
} from 'components'
import moment from 'moment'
import {useModuleAwareTranslation, shouldUse12HoursFormat, setUse12HoursFormat} from 'utils'

interface Props {
  userId?: string
}

const TimeFormatSetting = ({userId}: Props) => {
  const {t} = useModuleAwareTranslation()
  const momentLocale = moment.locale()
  const [use24Hour, set24HourFormat] = useState(!shouldUse12HoursFormat(momentLocale, userId))

  useEffect(() => {
    set24HourFormat(!shouldUse12HoursFormat(momentLocale, userId))
  }, [userId, momentLocale])

  const toggle24HourFormat = useCallback(() => {
    const shouldUse24HourFormat = !use24Hour
    set24HourFormat(shouldUse24HourFormat)
    setUse12HoursFormat(!shouldUse24HourFormat, userId)
  }, [use24Hour, set24HourFormat, userId])

  return (
    <ListItem
      fullWidth
      bolder
    >
      <span>{t('Nav_SiteSettings other section 24h format')}</span>
      <SimpleSwitch
        checked={use24Hour}
        onChange={toggle24HourFormat}
      />
    </ListItem>
  )
}

export default TimeFormatSetting
