import React from 'react'
import {useModuleAwareTranslation} from 'utils'
import {Button, Dialog, DialogContent, DialogActions, DialogTitle, DialogInnerContent, Image} from 'components'
import {blueButton, white} from '../../../colors'
import styled from 'styled-components'

const StyledImage = styled(Image)`
  height: auto;
  margin: 16px auto 12px auto;
  width: 100%;
  display: block;
`

interface Props {
  close: () => void
  isSettingsPage?: boolean
  isOpen: boolean
}

const ScheduleSortingInfoModal = ({
  close,
  isSettingsPage,
  isOpen,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={close}
      maxWidth="xs"
    >
      <DialogContent>
        <DialogTitle onClose={close}>
          {t('Nav_Schedule order modal title')}
        </DialogTitle>
        <DialogInnerContent>
          {t(isSettingsPage ?
            'Nav_Schedule order modal settings message' :
            'Nav_Schedule order modal message'
          )}
          <StyledImage
            src="/nsp/schedules_ordering.gif"
          />
        </DialogInnerContent>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          $backgroundColor={blueButton}
          $textColor={white}
        >
          {t('Common button close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ScheduleSortingInfoModal
