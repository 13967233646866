import {connect} from 'react-redux'
import {Store} from 'types'
import {selectors as authSelectors} from 'auth'
import TimeFormatSetting from './TimeFormatSetting'

const mapStateToProps = (state: Store) => ({
  userId: authSelectors.getUser(state)?.userId,
})

export default connect(mapStateToProps)(TimeFormatSetting)
