import React, {useEffect} from 'react'
import styled from 'styled-components'
import {FullScreenModal, List, ListItem} from 'components'
import {numberWithLeadingZero} from 'utils'
import {commitDate, commitId} from 'config'
import {version} from '../../package.json'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  apiData: {
    version: string
    hash?: string
    envs?: {[name: string]: string}
  } | null
  isConnectedToServer: boolean
  isOpen: boolean
  fetchApiVersion: () => void
  close: () => void
}

const ModalBody = styled.div`
  margin: 16px;
`

const EnvVariables = styled.div`
  font-family: monospace;
  margin: 4px 0;
  font-size: 1.15em;
`

const AppVersion = ({
  apiData,
  isConnectedToServer,
  fetchApiVersion,
  close,
  isOpen,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)

  useEffect(() => {
    if (isConnectedToServer && !apiData) {
      fetchApiVersion()
    }
  }, [apiData, isConnectedToServer])

  let uiVersion = version
  if (commitDate !== '#webCommitDate') {
    const date = new Date(commitDate)
    const year = date.getUTCFullYear()
    const month = numberWithLeadingZero(date.getUTCMonth() + 1)
    const day = numberWithLeadingZero(date.getUTCDate())
    uiVersion = `${year}-${month}-${day}`
  }

  const envs = apiData?.envs || {}

  const appVersion = apiData?.version && apiData.version !== '?' && !apiData.version.includes('-') ?
    apiData.version :
    uiVersion

  return (
    <FullScreenModal
      isOpen={isOpen}
      title="debug info"
      close={close}
    >
      <ModalBody>
        <List>
          <ListItem fullWidth>
            {t('Nav_Menu app version', {version: appVersion})}
          </ListItem>
          <ListItem fullWidth>
            UI: <a href={`https://github.com/aeolusbot/user-infra-web/tree/${commitId}`} target="_blank">#{commitId}</a>
          </ListItem>
          {apiData?.hash && (
            <ListItem fullWidth>
              API: <a href={`https://github.com/aeolusbot/user-infra-api/tree/${apiData.hash}`} target="_blank">#{apiData.hash}</a>
            </ListItem>
          )}
          {apiData?.envs && (
            <ListItem fullWidth>
              Env variables:
              <EnvVariables>
                {Object.keys(envs).map(name => (
                  <div key={name}>{name}: {envs[name]}</div>
                ))}
              </EnvVariables>
            </ListItem>
          )}
        </List>
      </ModalBody>
    </FullScreenModal>
  )
}

export default AppVersion
