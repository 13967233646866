import React from 'react'
import PatrolTitleContainer from './PatrolTitleContainer'
import {OngoingPatrolEventsContainer} from '../patrolEvents'
import * as Styled from '../NSPCommonStyled'
import PatrolImageContainer from './PatrolImageContainer'
import PatrolContentWrapperContainer from './PatrolContentWrapperContainer'
import PatrolPreconditionsContainer from './PatrolPreconditionsContainer'
import PatrolFinishedRedirectorContainer from './PatrolFinishedRedirectorContainer'
import PatrolCountersContainer from './PatrolCountersContainer'
import {TutorialVideo} from '../shared'

const Patrol = () => {
  return (
    <Styled.PatrolStatus>
      <PatrolContentWrapperContainer>
        <Styled.StatusMainContent>
          <PatrolCountersContainer />
          <PatrolImageContainer />
          <PatrolTitleContainer />
          <PatrolPreconditionsContainer />
          <OngoingPatrolEventsContainer />
        </Styled.StatusMainContent>
      </PatrolContentWrapperContainer>
      <PatrolFinishedRedirectorContainer />
      <TutorialVideo />
    </Styled.PatrolStatus>
  )
}

export default Patrol
