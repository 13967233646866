import React, {SyntheticEvent, useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import {Button} from 'components'
import {ROUTES} from '../constants'
import {blueButton, white} from '../colors'
import {getResponseBody, httpPost} from '../utils'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const StyledForm = styled.form`
  max-width: 500px;
  margin: 32px auto;
`

const Login = () => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [hasError, setHasError] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | string>(false)
  const redirectTo = new URL(window.location.href).searchParams.get('redirectTo')

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasError(false)
    setPassword(event.target.value)
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasError(false)
    setUsername(event.target.value)
  }

  const submit = useCallback(async (event: SyntheticEvent) => {
    event.preventDefault()

    if (isAuthenticating) {
      return
    }

    setIsAuthenticating(true)
    setHasError(false)
    try {
      const response = await httpPost('auth/login', {username, password})
      const {status} = response
      if (status >= 400) {
        setHasError(true)
        setIsAuthenticating(false)
      } else {
        const responseBody = await getResponseBody(response)
        setIsLoggedIn(responseBody.dashboardUrl || true)
      }
    } catch (error) {
      setHasError(true)
      setIsAuthenticating(false)
    }
  }, [isAuthenticating, username, password])

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    if (redirectTo) {
      window.location.href = redirectTo
    }

    const targetRoute = typeof isLoggedIn === 'string' && Object.values(ROUTES).includes(isLoggedIn) ?
      isLoggedIn :
      ROUTES.HOME
    window.location.href = `${window.location.origin}${window.location.pathname.replace(ROUTES.LOGIN, targetRoute)}`
  }, [isLoggedIn, redirectTo])

  return (
    <StyledForm
      noValidate
      autoComplete="off"
      onSubmit={submit}
    >
      <TextField
        id="login-username"
        label={t('Login title login username')}
        fullWidth
        style={{margin: '16px 0'}}
        value={username}
        onChange={handleUsernameChange}
      />
      <TextField
        id="login-password"
        label={t('Login title login password')}
        fullWidth
        type="password"
        style={{margin: '16px 0'}}
        error={hasError}
        helperText={hasError ? t('Login content login error') : undefined}
        value={password}
        onChange={handlePasswordChange}
      />
      <Button
        data-testid="login-button"
        $backgroundColor={blueButton}
        $textColor={white}
        fullWidth
        disabled={isAuthenticating}
        type="submit"
      >
        {t('Login button login button')}
      </Button>
    </StyledForm>
  )
}

export default Login
