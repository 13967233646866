import React, {useCallback, useEffect, useState} from 'react'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import EditIcon from '@material-ui/icons/Edit'
import styled from 'styled-components'
import {grayText, lightGray, defaultText} from '../colors'
import {useModuleAwareTranslation} from 'utils'
import {PERMISSIONS} from '../constants'
import BugReportIcon from '@material-ui/icons/BugReport'
import {useHistory, useLocation} from 'react-router-dom'
import {GoToElementIcon, FeatureToggle} from 'components'
import AppVersionContainer from './AppVersionContainer'

const Top = styled.div`
  min-height: 57px;
  border-bottom: 1px solid ${lightGray};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 16px;
  font-size: 1.125rem;
`

const ConfigurationIcon = styled(SettingsOutlinedIcon)`&&&{
  font-size: 30px;
  margin-right: 6px;
}`

const StyledList = styled(List)`
  min-width: 240px;

  .MuiListItemIcon-root {
    min-width: 0;
    color: ${defaultText};
    &:first-child {
      margin-right: 16px;
    }
    &:last-child {
      margin-left: 16px;
    }
  }
`

const MainContent = styled.div`
  flex: 1;
`

const Version = styled.div`
  font-size: 11px;
  padding: 16px;
  color: ${grayText};
`

interface Props {
  closeDrawer: () => void
  navigationTriggered: () => void
  openRobotEdit: () => void
  isOpen: boolean
  robotId?: string
  version?: string
}

function SecondaryNavigation({
  robotId,
  isOpen,
  closeDrawer,
  navigationTriggered,
  version,
  openRobotEdit,
}: Props) {
  const {t} = useModuleAwareTranslation()
  const history = useHistory()
  const location = useLocation()
  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false)
  const [clickCount, setClickCount] = useState(0)
  let clickTimeout = -1
  useEffect(() => {
    if (clickCount > 6) {
      setIsVersionModalOpen(true)
      setClickCount(0)
    }
  }, [clickCount])

  const clickVersion = useCallback(() => {
    setClickCount(prevValue => prevValue + 1)
    clearTimeout(clickTimeout)
    clickTimeout = setTimeout(() => {
      setClickCount(0)
    }, 1000)
  }, [clickTimeout])

  const goToPageCreator = useCallback((route: string) => () => {
    if (location.pathname !== route) {
      history.push(route)
      navigationTriggered()
    } else {
      closeDrawer()
    }
  }, [history, location.pathname, navigationTriggered, closeDrawer])

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={closeDrawer}
    >
      <Top>
        <ConfigurationIcon /> <span>{t('Nav_SecondaryMenu title')}</span>
      </Top>
      <MainContent>
        <StyledList>
          {robotId && (
            <FeatureToggle permissionName={PERMISSIONS.ROBOT.UPDATE}>
              <ListItem
                onClick={() => {
                  closeDrawer()
                  openRobotEdit()
                }}
              >
                <ListItemText primary={t('Nav_SecondaryMenu edit robot name')} />
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
              </ListItem>
            </FeatureToggle>
          )}
          <ListItem
            onClick={goToPageCreator('/patrol/site')}
          >
            <ListItemText primary={t('Nav_SecondaryMenu site settings')} />
            <ListItemIcon>
              <GoToElementIcon />
            </ListItemIcon>
          </ListItem>
        </StyledList>
        {robotId && (
          <FeatureToggle permissionName={PERMISSIONS.SEND_REPORT}>
            <Divider />
            <StyledList>
              <ListItem
                onClick={goToPageCreator(`/patrol/issue/${robotId}`)}
              >
                <ListItemIcon>
                  <BugReportIcon />
                </ListItemIcon>
                <ListItemText primary={t('Robot configuration bug report open')} />
              </ListItem>
            </StyledList>
          </FeatureToggle>
        )}
      </MainContent>
      {version && (
        <Version onClick={clickVersion}>
          {t('Nav_Menu app version', {version})}
        </Version>
      )}
      <AppVersionContainer
        isOpen={isVersionModalOpen}
        close={() => setIsVersionModalOpen(false)}
      />
    </Drawer>
  )
}

export default SecondaryNavigation
