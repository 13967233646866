import {connect} from 'react-redux'
import {Store} from 'types'
import {getSelectedRobot} from 'app'
import {selectors as authSelectors} from 'auth'
import {getStateMachine, getConfigurations, isResumingAbortedPatrol, getNextSchedule} from '../selectors'
import PatrolTitle from './PatrolTitle'
import {getPointCounts, getCurrentLocation, getPatrolTitleOverrides} from './selectors'

const mapStateToProps = (state: Store) => ({
  nextScheduleDate: getNextSchedule(state)?.date,
  nextScheduleConfigurationUid: getNextSchedule(state)?.item.configurationUid,
  stateGroup: getStateMachine(state)?.stateGroup,
  rawState: getStateMachine(state)?.rawState,
  resumingAbortedPatrol: isResumingAbortedPatrol(state),
  isLowBattery: getSelectedRobot(state)?.battery?.isLow,
  pointCounts: getPointCounts(state),
  location: getCurrentLocation(state),
  overrides: getPatrolTitleOverrides(state),
  configurations: getConfigurations(state),
  userId: authSelectors.getUser(state)?.userId,
})

export default connect(mapStateToProps)(PatrolTitle)
