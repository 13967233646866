import {types} from 'framework'
import {moduleName} from './constants'

export const adjustTranslationKeys: types.GenericKeyAdjustmentsCallback = (
  context
) => {
  const wauvDefaultKey = `wauv_${context.defaultKey}`
  const fallbackKey = `uv_${context.defaultKey}`
  const canTranslate = context.currentModule === moduleName && context.i18n
  if (!canTranslate) {
    return
  }

  if (context.i18n?.exists(wauvDefaultKey)) {
    context.key = wauvDefaultKey
  } else if (context.i18n?.exists(fallbackKey)) {
    context.key = fallbackKey
  }
}
