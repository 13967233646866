import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import {
  getShortDateAndTimeFormatForMoment,
  REVERSE_ROOM_ORDER_KEY,
  REVERSE_ROOM_ORDER_VALUE,
  useModuleAwareTranslation,
} from 'utils'
import {grayText} from '../../../colors'
import {PatrolPointCounts, SettingsConfigurationElement, StateMachineGroup} from '../types'
import {StatusDescription, StatusTextsWrapper, StatusTitle} from '../NSPCommonStyled'
// import {OngoingPatrolEventsToggleContainer} from '../patrolEvents'
import {isAfterStopped, isGoingHomeFromRawState} from '../utils'
import {schedulesDisabled} from '../../../config'
import {PatrolLocation} from '../location'
import {
  SPECIAL_DESCRIPTION_KEYS,
  SPECIAL_TITLE_KEYS,
  STATE_MACHINE_GROUP_TO_DESCRIPTION_KEY,
  STATE_MACHINE_GROUP_TO_TITLE_KEY,
} from '../constants'

const Configuration = styled.div`
  color: ${grayText};
  margin-top: 3px;
`

const StyledPatrolLocation = styled(PatrolLocation)`
  margin-bottom: 24px;
`

export interface Props {
  configurations?: SettingsConfigurationElement[]
  nextScheduleDate?: Date
  nextScheduleConfigurationUid?: string
  location?: string
  resumingAbortedPatrol?: boolean
  isLowBattery?: boolean
  isFinished?: boolean
  rawState?: string
  userId?: string
  stateGroup?: StateMachineGroup
  pointCounts: PatrolPointCounts
  overrides?: {
    warningKey?: string
    titleKey?: string
    descriptionKey?: string
    descriptionParams?: object
    titleParams?: object
  }
}

function getPatrolStatusTitleKey(
  stateGroup: StateMachineGroup | undefined,
  rawState: string | undefined,
  isFinished: boolean | undefined,
  isLowBattery: boolean | undefined
): string {
  if (isFinished) {
    return SPECIAL_TITLE_KEYS.PATROL_FINISHED
  }

  if (!stateGroup) {
    return SPECIAL_TITLE_KEYS.UNKNOWN
  }

  if (
    (stateGroup === StateMachineGroup.STOPPED || stateGroup === StateMachineGroup.STOPPED_RECOVERED) &&
    isGoingHomeFromRawState(rawState)
  ) {
    return SPECIAL_TITLE_KEYS.STOPPED_WHEN_GOING_HOME
  }

  if (stateGroup === StateMachineGroup.GOING_HOME && isLowBattery) {
    return SPECIAL_TITLE_KEYS.GOING_HOME_WITH_LOW_BATTERY
  }

  return STATE_MACHINE_GROUP_TO_TITLE_KEY[stateGroup] || SPECIAL_TITLE_KEYS.UNKNOWN
}

function getPatrolStatusDescriptionKey(
  stateGroup: StateMachineGroup | undefined,
  isFinished?: boolean
): string {
  if (isFinished) {
    return SPECIAL_DESCRIPTION_KEYS.PATROL_FINISHED
  }

  if (!stateGroup) {
    return ''
  }

  if (stateGroup === StateMachineGroup.IDLE && schedulesDisabled) {
    return ''
  }

  return STATE_MACHINE_GROUP_TO_DESCRIPTION_KEY[stateGroup] || ''
}

const getSingleDescription = (
  stateGroup?: StateMachineGroup,
  rawState?: string,
  resumingAbortedPatrol?: boolean
) => {
  if (resumingAbortedPatrol) {
    return `Nav_Event title RESUMED_ABORTED_PATROL`
  }

  if (isAfterStopped(rawState)) {
    return `Nav_Event title RECOVERED_FROM_STOP`
  }

  return ''
}

const shouldShowLocation = (stateGroup?: StateMachineGroup) => {
  return (
    stateGroup &&
    stateGroup !== StateMachineGroup.CHECKING_PRECONDITIONS &&
    stateGroup !== StateMachineGroup.INITIALIZING
  )
}

const PatrolTitle: React.FC<Props> = ({
  pointCounts,
  rawState,
  stateGroup,
  isFinished,
  isLowBattery,
  resumingAbortedPatrol,
  configurations,
  location,
  overrides,
  userId,
  nextScheduleConfigurationUid,
  nextScheduleDate,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const isReverseLocationOrder = t(REVERSE_ROOM_ORDER_KEY) === REVERSE_ROOM_ORDER_VALUE
  const isPatrolling = stateGroup === StateMachineGroup.PATROLLING
  const titleKey = overrides?.titleKey || getPatrolStatusTitleKey(stateGroup, rawState, isFinished, isLowBattery)
  let descriptionKey = getPatrolStatusDescriptionKey(stateGroup, isFinished)
  const singleDescriptionKey = getSingleDescription(stateGroup, rawState, resumingAbortedPatrol)
  let singleDescription = overrides?.descriptionKey ? t(overrides.descriptionKey, overrides?.descriptionParams) : ''
  if (!singleDescription) {
    singleDescription = singleDescriptionKey ? t(singleDescriptionKey) : ''
  }
  const shortDateFormat = getShortDateAndTimeFormatForMoment(userId)
  const descriptionData = {
    nextPatrolDateTime: nextScheduleDate ? moment(nextScheduleDate).format(shortDateFormat) : '—',
    roomCheckedCount: pointCounts.checkedPoints,
    count: pointCounts.totalPoints,
    location: isReverseLocationOrder ?
      location?.split(';').reverse().join(', ') :
      location?.split(';').join(', '),
  }

  const showOnlyReason = Boolean(singleDescription)
  if (!showOnlyReason && isPatrolling && pointCounts.totalPoints <= 0) {
    descriptionKey = ''
  }

  const canShowConfiguration = !isFinished && stateGroup === StateMachineGroup.IDLE
  const configurationToShow = canShowConfiguration && configurations && configurations.length > 1 && nextScheduleConfigurationUid ?
    configurations.find(config => config.id === nextScheduleConfigurationUid) :
    undefined

  return (
    <StatusTextsWrapper $flex="0 1 0%">
      {overrides?.warningKey && (
        <StatusTitle error>{t(overrides?.warningKey)}</StatusTitle>
      )}
      <StatusTitle>{t(titleKey, overrides?.titleParams)}</StatusTitle>
      {shouldShowLocation(stateGroup) && <StyledPatrolLocation />}
      {descriptionKey ? (
        <StatusDescription>
          <div>
            {showOnlyReason ? singleDescription : t(descriptionKey, descriptionData)}
            {!showOnlyReason && configurationToShow && (
              <Configuration>
                {configurationToShow.name || t('Nav_Schedule subtitle configurations default name', {
                  index: configurationToShow.index + 1,
                })}
              </Configuration>
            )}
          </div>
        </StatusDescription>
      ) : (
        <StatusDescription />
      )}
    </StatusTextsWrapper>
  )
}

export default PatrolTitle
