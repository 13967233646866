import {connect} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'
import {AnyAction} from 'redux'
import SiteActionsCard from './SiteActionsCard'
import {downloadFile} from '../app'
import {Store} from '../types'

const mapStateToProps = (state: Store) => ({
  languages: state.app.supportedLanguages,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => ({
  downloadSslCert: () => dispatch(downloadFile('IPCert.der.crt', `config/sslcert`, 'Dashboard cert download failed')),
})

export default connect(mapStateToProps, mapDispatchToProps)(SiteActionsCard)
