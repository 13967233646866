import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {Prompt} from 'react-router-dom'
import RoomIcon from '@material-ui/icons/Room'
import Fab from '@material-ui/core/Fab'
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined'
import {Grid, Loader, PageTitle, TileItem} from 'components'
import {SettingsSubtitleContainer} from '../../navigation/shared'
import {usePrevious} from 'utils'
import {SanitizationLevel, SanitizedObject} from '../types'
import {Locations} from '../../navigation/types'
import {primary} from '../../../colors'
import {ObjectGroup, ObjectGroupType, ObjectSubGroup} from './types'
import EditObjectGroupModal from './EditObjectGroupModal'
import SettingsNameEditContainer from './SettingsNameEditContainer'
import SettingsHomeEditContainer from './SettingsHomeEditContainer'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  canSendRobotCommand: boolean
  robotLoaded: boolean
  isFetchingObjects: boolean
  objects: SanitizedObject[]
  editedObjects: SanitizedObject[]
  clearEditedObjects: () => void
  saveObjects: (onSuccess?: Function) => void
  fetchObjects: () => void
  locations: Locations
  objectGroups: ObjectGroup[]
  setEditedObjects: (objects: SanitizedObject[]) => void
  willAutoRedirect: boolean
}

const Empty = styled.div`
    text-align: center;
    margin: 30px;
`

const StyledFab = styled(Fab)`
  position: fixed!important;
  bottom: 68px;
  right: 16px;
  z-index: 100;
`

const StyledGrid = styled(Grid)`
  margin-top: 16px;
`

const SettingsPage = ({
  clearEditedObjects,
  editedObjects,
  setEditedObjects,
  canSendRobotCommand,
  isFetchingObjects,
  objects,
  fetchObjects,
  locations,
  robotLoaded,
  objectGroups,
  willAutoRedirect,
  saveObjects,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const previousWillAutoRedirect = usePrevious(willAutoRedirect)
  const [groupType, setGroupType] = useState(ObjectGroupType.OBJECTS)
  const [groupForEdit, setGroupForEdit] = useState<ObjectGroup | undefined>(undefined)
  const changedToAutoRedirect = willAutoRedirect && !previousWillAutoRedirect

  const setLevelByObjectType = useCallback((objectType: string, level: SanitizationLevel) => {
    const translation = t(`uv_Object_Types ${objectType}`)
    const changedObjects = objects
      .filter(object => t(`uv_Object_Types ${object.type}`) === translation || (objectType === 'unknown' && !object.type))
      .map(originalObject => {
        const object = editedObjects.find(edited => edited.pointId === originalObject.pointId) || originalObject
        return {
          ...object,
          level,
        }
      })
    setEditedObjects(changedObjects)
  }, [t, setEditedObjects, objects, editedObjects])

  useEffect(() => {
    fetchObjects()
  }, [])

  useEffect(() => {
    return () => {
      clearEditedObjects()
    }
  }, [])

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      event.preventDefault()
      // Chrome requires returnValue to be set
      event.returnValue = ''
    }

    if ((editedObjects.length > 0) && !changedToAutoRedirect) {
      window.addEventListener('beforeunload', onBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [editedObjects.length, changedToAutoRedirect])

  const toggleView = () => setGroupType(groupType => groupType === ObjectGroupType.OBJECTS ?
    ObjectGroupType.LOCATIONS :
    ObjectGroupType.OBJECTS)

  const getGroupTitle = (group: ObjectGroup) => {
    return groupType === ObjectGroupType.OBJECTS ?
      t(`uv_Object_Types ${group.objectType}`) :
      (group.translatedLocation || t('uv_Object_Types unknown location') || '')
  }

  const getGroupObjects = (group: ObjectGroup) => {
    return objects
      .filter(object => group.subgroups.some(subgroup => subgroup.objectIds.includes(object.pointId)))
      .map(object => editedObjects.find(edited => edited.pointId === object.pointId) || object)
  }

  const getSubGroupKey = (subgroup: ObjectSubGroup) =>
    groupType === ObjectGroupType.OBJECTS ? subgroup.translatedLocation : t(`uv_Object_Types ${subgroup.objectType}`)

  const mergeSubgroups = (subgroups: ObjectSubGroup[]): ObjectSubGroup[] => {
    return subgroups.reduce((result, subgroup) => {
      const subgroupKey = getSubGroupKey(subgroup)
      const isAlreadyMerged = result.some(item => getSubGroupKey(item) === subgroupKey)
      if (isAlreadyMerged) {
        return result
      }

      const itemsToMerge = subgroups.filter(item => item !== subgroup && getSubGroupKey(item) === subgroupKey)
      const total = itemsToMerge.reduce((sum, item) => sum + item.total, subgroup.total)
      const selected = itemsToMerge.reduce((sum, item) => sum + item.selected, subgroup.selected)
      const objectIds = itemsToMerge.reduce((res, item) => [...res, ...item.objectIds], subgroup.objectIds)
      return [
        ...result,
        {
          ...subgroup,
          total,
          selected,
          objectIds,
        },
      ]
    }, [] as ObjectSubGroup[])
  }

  const currentGroups = objectGroups.filter(group => group.groupType === groupType)
  const tiles = currentGroups
    .reduce((result, group) => {
      const groupObjects = getGroupObjects(group)
      const title = getGroupTitle(group)
      if (result.some(element => element.title === title)) {
        // already grouped that item
        return result
      }
      const otherItemsWithSameTitle = currentGroups.filter(item => item !== group && title === getGroupTitle(item))
      const total = otherItemsWithSameTitle.reduce((sum, item) => sum + item.total, group.total)
      const enabled = otherItemsWithSameTitle.reduce(
        (sum, item) => sum + getGroupObjects(item).filter(object => object.enabled).length,
        groupObjects.filter(object => object.enabled).length)
      return [
        ...result,
        {
          title,
          total,
          enabled,
          level: groupType === ObjectGroupType.OBJECTS ?
            t('uv_SanitizingObjects power level', {
              level: t(`uv_SanitizingObjects general ${groupObjects[0]?.level || SanitizationLevel.LOW} uvc`),
            }).toUpperCase() :
            undefined,
          objectGroup: {
            ...group,
            subgroups: mergeSubgroups([
              ...group.subgroups,
              ...otherItemsWithSameTitle.flatMap(group => group.subgroups),
            ]),
          },
        },
      ]
    }, [] as Array<{title: string, total: number, enabled: number, level: string | undefined, objectGroup: ObjectGroup}>)
    .sort((tileA, tileB) => tileA.title.localeCompare(tileB.title))

  return (
    <>
      <PageTitle>{t('uv_SanitizingObjects title sanitization settings')}</PageTitle>
      <SettingsSubtitleContainer
        localeKeys={{
          default: '',
          initializing: 'Nav_CommonSetting content patrol robot initializing',
          criticalState: 'Nav_CommonSetting content patrol robot critical',
          patrolConfigDown: 'Nav_CommonSetting content patrol robot limit finding not run',
          cannotSendNavCommand: 'Nav_Rooms content patrol no robot for setting update',
          patrolling: 'Nav_Rooms content patrol settings description patrol',
        }}
      />
      {(isFetchingObjects || !robotLoaded) && <Loader centered size={75} withTopMargin />}
      {robotLoaded && !isFetchingObjects && objects.length === 0 && (
        <Empty>{t('uv_SanitizingObjects content sanitization no objects')}</Empty>
      )}
      {robotLoaded && !isFetchingObjects && objects.length > 0 && (
        <>
          <SettingsNameEditContainer />
          <SettingsHomeEditContainer />
          <StyledGrid>
            {tiles
              .map(tile => (
                <TileItem
                  key={tile.title}
                  text={tile.title}
                  help={tile.level}
                  summary={`${tile.enabled}/${tile.total}`}
                  onClick={() => setGroupForEdit(tile.objectGroup)}
                  summaryColor={tile.enabled === tile.total ? primary : undefined}
                />
              ))}
          </StyledGrid>
          <StyledFab
            color="primary"
            onClick={toggleView}
          >
            {groupType === ObjectGroupType.OBJECTS ?
              <RoomIcon fontSize="large" /> :
              <CategoryOutlinedIcon fontSize="large" />}
          </StyledFab>
        </>
      )}
      <EditObjectGroupModal
        disabled={!canSendRobotCommand}
        setLevelByObjectType={setLevelByObjectType}
        close={() => setGroupForEdit(undefined)}
        objectGroup={groupForEdit}
        groupType={groupType}
        editObjects={setEditedObjects}
        objects={objects}
        locations={locations}
        editedObjects={editedObjects}
        saveObjects={saveObjects}
        clearEditedObjects={clearEditedObjects}
      />
      <Prompt
        message={t('uv_SanitizingObjects leaving with unsaved data')}
        when={canSendRobotCommand && editedObjects.length > 0 && !changedToAutoRedirect}
      />
    </>
  )
}

export default SettingsPage
