import {createSelector, defaultMemoize} from 'reselect'
import moment from 'moment'
import {Robot, RobotState, Store} from 'types'
import {longDisconnectedTimeoutSeconds, robotRebootTimeoutSeconds} from 'config'
import {
  DiagnosticEvent,
  DiagnosticReason,
  eventStateGroups,
  LocationInterface,
  MissionMonitor,
  NavigationInfo,
  NextSchedule,
  PatrolEventLevel,
  PatrolMonitor,
  PatrolPointAction,
  personRelatedEvents,
  Schedule,
  Settings,
  SettingsConfigurationElement,
  StateMachine,
  StateMachineGroup,
} from './types'
import {emptyArray, getKeyForRobot, sortObjectsByDateDesc} from 'utils'
import {SanitizedObjects} from '../uv/types'
import {scenarioToModule} from '../utils'
import {filterOutSettingsForClients} from './utils'
import {hasPermission} from 'components'
import {PERMISSIONS} from '../../constants'

let lastEventReason: DiagnosticReason | undefined

export const getDiagnosticEvents = (state: Store): DiagnosticEvent[] => {
  const eventData = state.app.selectedRobot?.diagnosticData?.events
  if (!eventData) {
    return emptyArray
  }

  return eventData.flatMap((eventsData: {events: DiagnosticEvent[]}) => eventsData.events)
}

export const getClientEvents = (state: Store): DiagnosticEvent[] => {
  const events = state.app.selectedRobot?.diagnosticData?.clientEvents
  return events || emptyArray
}

export const getNextScheduleFromRobot = (robot?: Robot | null) =>
  robot?.diagnosticData?.nextSchedule as NextSchedule | undefined

export const getNextSchedule = (state: Store) => getNextScheduleFromRobot(state.app.selectedRobot)

export const getStateMachineFromRobot = (robot?: Robot | null) =>
  robot?.diagnosticData?.stateMachine as StateMachine | undefined

export const getStateMachine = (state: Store) => getStateMachineFromRobot(state.app.selectedRobot)

export const getMissionMonitor = (state: Store) =>
  state.app.selectedRobot?.diagnosticData?.missionMonitor as MissionMonitor | undefined

export const getPatrolMonitor = (state: Store) =>
  state.app.selectedRobot?.diagnosticData?.patrolMonitor as PatrolMonitor | undefined

export const isResumingAbortedPatrol = (state: Store) => {
  const stateMachine = getStateMachine(state)
  const navigationInfo = state.app.selectedRobot?.diagnosticData?.navigation as NavigationInfo | undefined
  return stateMachine?.rawState !== undefined &&
    !isPatrolOngoingBasedOnStateMachine(stateMachine) &&
    Boolean(navigationInfo?.isOperationOngoing)
}

export const getLocationInterfaceFromRobot = (robot?: Robot | null) =>
  robot?.diagnosticData?.location as LocationInterface | undefined

export const getLocationInterface = (state: Store) =>
  getLocationInterfaceFromRobot(state.app.selectedRobot)

const isPatrolOngoingBasedOnStateMachine = (stateMachine?: StateMachine) =>
  stateMachine?.rawState !== undefined && !stateMachine.rawState.toLowerCase().includes('_idle')

export const isPatrolOngoing = (state: Store): boolean => {
  const stateMachine = getStateMachine(state)
  const navigationInfo = state.app.selectedRobot?.diagnosticData?.navigation as NavigationInfo | undefined
  return isPatrolOngoingBasedOnStateMachine(stateMachine) || Boolean(navigationInfo?.isOperationOngoing)
}
export const isPatrolOngoingFromRobot = (robot: Robot | null): boolean => {
  const stateMachine = getStateMachineFromRobot(robot)
  const navigationInfo = robot?.diagnosticData?.navigation as NavigationInfo | undefined
  return isPatrolOngoingBasedOnStateMachine(stateMachine) || Boolean(navigationInfo?.isOperationOngoing)
}

export const getLastLocation = (state: Store) => {
  const location = getLocationInterface(state)
  return location?.lastLocationUid
}

export const getNextLocation = (state: Store) => {
  const location = getLocationInterface(state)
  return location?.nextLocationUid
}

export const getCurrentSchedule = (state: Store): Schedule | undefined => {
  const robotId = state.app.selectedRobot?.id
  return robotId ? state.nsp.scheduleByRobot[robotId] : undefined
}

export const getCurrentConfigurationIdFromUrl = () => {
  const query = new URLSearchParams(window.location.search)
  return query.get('id')
}

export const getCurrentSettings = (state: Store): Settings | undefined => {
  const robotId = state.app.selectedRobot?.id
  if (!robotId) {
    return undefined
  }
  const configurationUid = getCurrentConfigurationIdFromUrl()
  const settingsForRobot = state.nsp.settingsByRobot[robotId]
  if (!settingsForRobot) {
    return undefined
  }

  const currentSettings = settingsForRobot?.find(item => item.configurationUid === configurationUid)
  return currentSettings || settingsForRobot[0]
}

export const getCurrentSettingsIndex = (state: Store): number => {
  const robotId = state.app.selectedRobot?.id
  if (!robotId) {
    return 0
  }
  const configurationUid = getCurrentConfigurationIdFromUrl()
  const settingsForRobot = getConfigurations(state)
  if (!settingsForRobot) {
    return 0
  }

  const index = settingsForRobot?.findIndex(item => item.id === configurationUid)
  return index >= 0 ? index : 0
}

export const shouldAutoCloseModal = (state: Store): boolean => {
  const stateGroup = getStateMachine(state)?.stateGroup
  const events = getDiagnosticEvents(state)
  return events.some(event => event.isAssistance) && stateGroup !== undefined && eventStateGroups.includes(stateGroup)
}

// cache last event to return always the same object if it didn't change
let lastEvent: DiagnosticEvent | undefined
let lastEventViewed: boolean | undefined
let lastEventNotificationId: string | undefined
export const getCurrentEvent = (state: Store) => {
  const events = getDiagnosticEvents(state)
  const clientEvents = getClientEvents(state)
  const validEvents = events
    .sort(sortObjectsByDateDesc)
    .filter(({reason}) => reason !== DiagnosticReason.POINT_DONE &&
      reason !== DiagnosticReason.POINT_SKIPPED)
  let event = validEvents.find(ev => personRelatedEvents.includes(ev.reason)) || validEvents[0]
  if (!event) {
    event = clientEvents[0]
  }
  const isDifferentThanCached = (
    event?.notificationUid !== lastEventNotificationId ||
    event?.viewed !== lastEventViewed
  )
  if (isDifferentThanCached) {
    lastEvent = event
    lastEventViewed = event?.viewed
    lastEventNotificationId = event?.notificationUid
  }

  return lastEvent
}

export const shouldAutoCloseModalKey = (state: Store): string => {
  const shouldClose = shouldAutoCloseModal(state)
  if (shouldClose) {
    const event = getCurrentEvent(state)
    return event?.notificationUid || ''
  }

  return ''
}

let lastDisconnectedEvent: DiagnosticEvent | undefined
const getDisconnectedEvent = defaultMemoize((
  siteId: string,
  robotId: string,
  lastConnectionDate: Date,
  stateMachineGroup?: StateMachineGroup,
  offlineCounter?: number,
  lastLocation?: string,
  nextLocation?: string
) => {
  const dateDiffInSeconds = moment().diff(moment(lastConnectionDate), 'seconds')
  let longDisconnectionTimeSeconds = longDisconnectedTimeoutSeconds
  if (stateMachineGroup === StateMachineGroup.RESTARTING) {
    longDisconnectionTimeSeconds += robotRebootTimeoutSeconds
  }

  const reason = dateDiffInSeconds >= longDisconnectionTimeSeconds ?
    DiagnosticReason.DISCONNECTED_LONG_TIME :
    DiagnosticReason.DISCONNECTED

  if (reason !== lastEventReason || !lastDisconnectedEvent) {
    lastDisconnectedEvent = {
      date: lastConnectionDate,
      notificationUid: `web-generated_${moment(lastConnectionDate).toDate().toString()}`,
      level: PatrolEventLevel.CAUTION,
      fromLocation: lastLocation,
      toLocation: nextLocation,
      robotId,
      siteId,
      isAssistance: false,
      hasPhoto: false,
      reason,
    } as DiagnosticEvent
  }

  return lastDisconnectedEvent
})

const getDeviceOfflineEvent = defaultMemoize((
  siteId: string | undefined,
  robotId: string | undefined,
  deviceOfflineSince: Date | null,
  lastLocation?: string,
  nextLocation?: string
) => {
  if (!deviceOfflineSince) {
    return
  }

  return {
    date: deviceOfflineSince,
    notificationUid: `web-generated_${moment(deviceOfflineSince).toDate().toString()}`,
    isAssistance: false,
    fromLocation: lastLocation,
    toLocation: nextLocation,
    robotId: robotId || 'empty',
    hasPhoto: false,
    siteId: siteId || 'empty',
    reason: DiagnosticReason.INTERNET_CONNECTION_LOST,
  } as DiagnosticEvent
})

const getCriticalStateEvent = defaultMemoize((
  siteId: string,
  robotId: string,
  stateMachineGroup?: StateMachineGroup,
  robotState?: RobotState
) => {
  if (robotState !== RobotState.CRITICAL_ERROR && stateMachineGroup !== StateMachineGroup.CRITICAL_ERROR) {
    return
  }

  const date = new Date()

  return {
    date,
    notificationUid: `web-generated_${moment(date).toDate().toString()}`,
    robotId,
    siteId,
    hasPhoto: false,
    isAssistance: true,
    reason: DiagnosticReason.ROBOT_IN_CRITICAL_STATE,
  } as DiagnosticEvent
})

const getCriticalBatteryEvent = defaultMemoize((
  siteId: string,
  robotId: string,
  stateMachineGroup?: StateMachineGroup
) => {
  if (stateMachineGroup !== StateMachineGroup.CRITICAL_BATTERY) {
    return
  }

  const date = new Date()

  return {
    date,
    notificationUid: `web-generated_${moment(date).toDate().toString()}`,
    robotId,
    siteId,
    hasPhoto: false,
    isAssistance: true,
    reason: DiagnosticReason.CRITICAL_BATTERY,
  } as DiagnosticEvent
})

export const getLastConnectionDate = createSelector(
  (state: Store) => state.app.selectedRobot?.lastKeepAliveReceivedAt,
  (state: Store) => state.app.connectionToServerChangedAt,
  (
    lastKeepAliveReceivedAt,
    connectionToServerChangedAt
  ) => lastKeepAliveReceivedAt || connectionToServerChangedAt
)

export const getLocation = createSelector(
  getLastLocation,
  getNextLocation,
  (lastLocation, nextLocation) => ({
    lastLocation,
    nextLocation,
  })
)

export const getBaseRobotDataForEvent = createSelector(
  (state: Store) => state.app.selectedRobot?.siteId,
  (state: Store) => state.app.selectedRobot?.id,
  (state: Store) => state.app.selectedRobot?.healthStatus.robotState,
  (siteId, robotId, robotState) => ({
    siteId,
    robotId,
    robotState,
  })
)

export const getEvent = createSelector(
  getBaseRobotDataForEvent,
  getLocation,
  getLastConnectionDate,
  (state: Store) => state.app.isConnectedToServer,
  (state: Store) => state.app.deviceOfflineSince,
  (state: Store) => state.app.connectionToServerChangedAt,
  (state: Store) => state.app.offlineCounter,
  (state: Store) => state.app.offlineRobots,
  getCurrentEvent,
  (state: Store) => getStateMachine(state)?.stateGroup,
  (state: Store) => state.nsp.viewedEvents,
  (
    baseRobotData,
    location,
    lastConnectionDate,
    isConnectedToServer,
    deviceOfflineSince,
    connectionToServerChangedAt,
    offlineCounter,
    offlineRobots,
    currentEvent,
    stateMachineGroup,
    viewedEvents
  ): DiagnosticEvent | undefined => {
    const {siteId, robotId, robotState} = baseRobotData
    const getEventToReturn = () => {
      if (!siteId || !robotId) {
        return getDeviceOfflineEvent(siteId, robotId, deviceOfflineSince)
      }

      const deviceOfflineEvent = getDeviceOfflineEvent(siteId, robotId, deviceOfflineSince, location.lastLocation, location.nextLocation)
      if (deviceOfflineEvent) {
        return deviceOfflineEvent
      }

      const robotKey = getKeyForRobot({siteId, id: robotId})
      if (!isConnectedToServer || offlineRobots.includes(robotKey)) {
        return getDisconnectedEvent(siteId, robotId, lastConnectionDate, stateMachineGroup,
          offlineCounter, location.lastLocation, location.nextLocation)
      }

      // if we are in critical error from the notifications do not generate such error on the web part
      const criticalStateEvent = currentEvent?.reason !== DiagnosticReason.CRITICAL_ERROR ?
        getCriticalStateEvent(siteId, robotId, stateMachineGroup, robotState) :
        undefined
      if (criticalStateEvent) {
        return criticalStateEvent
      }

      const criticalBatteryEvent = getCriticalBatteryEvent(siteId, robotId, stateMachineGroup)
      if (criticalBatteryEvent) {
        return criticalBatteryEvent
      }

      return currentEvent
    }

    const event = getEventToReturn()
    lastEventReason = event?.reason
    if (event && !event.viewed && viewedEvents.includes(event.notificationUid)) {
      event.viewed = true
    }

    if (event?.reason !== DiagnosticReason.DISCONNECTED_LONG_TIME && event?.reason === DiagnosticReason.DISCONNECTED) {
      lastDisconnectedEvent = undefined
    }

    return event
  }
)

export const hasMultipleEvents = createSelector(
  getEvent,
  (state: Store) => state.app.selectedRobot?.diagnosticData?.events,
  (currentEvent, events) => {
    if (!currentEvent || currentEvent.reason === DiagnosticReason.INTERNET_CONNECTION_LOST) {
      return false
    }

    const assistanceEventsCount = (events || []).reduce(
      (result: number, eventsData: {events: DiagnosticEvent[]}) =>
        result + eventsData.events.filter(event => event.isAssistance).length,
      0)

    return assistanceEventsCount > 1
  }
)

export const getConfigurations = createSelector(
  (state: Store) => state.app.selectedRobot?.modules?.current,
  (state: Store) => state.nsp.settingsByRobot,
  (state: Store) => state.app.selectedRobot?.id,
  (state: Store) => state.uv.settings.objects,
  (state: Store) => hasPermission(state, PERMISSIONS.PATROL.UPDATE_NAVIGATE_LOCATIONS_FOR_UV),
  (currentModule, settingsByRobot, selectedRobotId, objects, canEditNavigateToActions): SettingsConfigurationElement[] => {
    if (!currentModule || !selectedRobotId) {
      return emptyArray
    }

    const nspSettings = settingsByRobot[selectedRobotId]
    const mainSettings: Settings[] | SanitizedObjects[] = currentModule === 'nsp' ? nspSettings : objects
    if (!mainSettings || mainSettings.length === 0) {
      return emptyArray
    }

    const sortedByConfigurationId: any[] = mainSettings.sort(
      (settingA: Settings | SanitizedObjects, settingB: Settings | SanitizedObjects) =>
        settingA.configurationUid.localeCompare(settingB.configurationUid))
    return sortedByConfigurationId.map((item: Settings | SanitizedObjects, index: number) => {
      const module = scenarioToModule(item.scenario)
      const itemSettings: any[] = module === 'nsp' ? nspSettings : objects
      const itemForModule: Settings | SanitizedObjects =
        itemSettings?.find((setting: Settings | SanitizedObjects) => setting.configurationUid === item.configurationUid) || item
      // @ts-ignore
      const itemsToCheck = (itemForModule.objects || itemForModule.rooms || []) as Array<{enabled: boolean, action?: PatrolPointAction}>
      const filteredItems = filterOutSettingsForClients(itemsToCheck, module, canEditNavigateToActions)
      return {
        id: itemForModule.configurationUid,
        name: itemForModule.name,
        scenario: itemForModule.scenario,
        index,
        selected: filteredItems.reduce((sum, {enabled}) => sum + (enabled ? 1: 0), 0),
        total: filteredItems.length,
      }
    }) as SettingsConfigurationElement[]
  }
)

export const hasDifferentScenarios = (state: Store) => {
  const configurations = getConfigurations(state)
  return [...new Set(configurations.map(config => config.scenario))].length > 1
}

export const getAnnouncements = createSelector(
  (state: Store) => state.app.announcements,
  (state: Store) => state.app.selectedRobot?.id,
  (state: Store) => state.app.selectedRobot?.siteId,
  (announcements, robotId, siteId) => {
    return announcements.filter(announcement => announcement.siteId === siteId && announcement.robotId === robotId)
  }
)
