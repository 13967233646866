import {
  all,
  takeLatest,
  put,
  select,
} from 'redux-saga/effects'
import {AnyAction} from 'redux'
import {httpPost, getResponseBody, getSelectedSiteAndRobotId, isRobotInManualMode} from 'utils'
import {addSnackbarMessage} from 'app'
import {Store} from 'types'
import {saveEditedItemFinished, setEditedItem} from './actions'
import * as constants from './constants'
import {backendScheduleItemToScheduleItem, scheduleItemToBackendItem} from '../dataMappers'
import {scheduleItemUpdateFinished} from '../actions'
import {Schedule, ScheduleItem} from '../types'
import {getCurrentSchedule} from '../selectors'

function* saveScheduleSettings() {
  yield takeLatest<AnyAction>(constants.SAVE_EDITED_ITEM, function* (action) {
    const {onSuccess} = action
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    if (!robotId) {
      return
    }
    const schedule: Schedule | undefined = yield select(getCurrentSchedule)
    const editedItem: ScheduleItem | undefined = yield select((store: Store) => store.nsp.schedule.editedItem)
    if (!schedule || !editedItem) {
      yield put(saveEditedItemFinished())
      return
    }

    const updatedItems = schedule.scheduleItems.map(item => {
      if (item.id !== editedItem.id) {
        return item
      }

      return {
        ...item,
        ...editedItem,
      }
    })


    const response: Response = yield httpPost(
      `v1/nsp/robot/${siteId}/${robotId}/schedule`,
      updatedItems.map(scheduleItemToBackendItem))
    const {status} = response

    function* showError() {
      const isInManualMode = yield isRobotInManualMode()
      const message = isInManualMode ?
        'Nav_CommonSetting content Error when updating item manual mode' :
        'Nav_CommonSetting content Error when updating item Please try again'
      yield put(addSnackbarMessage(message))
      yield put(saveEditedItemFinished())
    }
    if (status >= 300) {
      yield showError()
    } else {
      const items = yield getResponseBody(response)
      yield put(setEditedItem(null))
      if (!items || items.length === 0) {
        yield showError()
      } else {
        yield put(saveEditedItemFinished())
        yield put(addSnackbarMessage( 'Nav_Schedule_Edit saved'))
        yield put(scheduleItemUpdateFinished(
          robotId,
          {scheduleItems: items.map(backendScheduleItemToScheduleItem)}
        ))
        onSuccess()
      }
    }
  })
}

function* scheduleSagas() {
  yield all([
    saveScheduleSettings(),
  ])
}

export default scheduleSagas
