import {
  all,
  put,
  takeLatest,
  select,
  take, race,
} from 'redux-saga/effects'
import {AnyAction} from 'redux'
import {Store} from 'types'
import {getResponseBody, httpGet, httpPost, multiformPartData} from 'utils'
import * as constants from '../constants'
import {
  saveBugReportDone,
  setBugReportData,
  addSnackbarMessage,
  setBugReportUid, cancelBugReportDataCollection,
} from '../actions'
import {BUG_REPORT_FORM, FEATURE_REQUEST_FORM, textFieldOptions, userTextKey} from './constants'
import {getItemKey} from './utils'
import {FormItem} from './types'

function prepareForm(form: typeof BUG_REPORT_FORM, data: {[field: string]: string | boolean}) {
  return {
    isNewFeature: Boolean(data.isNewFeature),
    formRows: form.map(item => {
      let userText = ''
      const itemKey = getItemKey(item)
      const answerKey = data[itemKey]
      let subTitleKey = ''
      let subAnswerKey = ''
      let helpTitleKey = ''
      let helpAnswerKey = ''
      let isUserAnswer = textFieldOptions.includes(answerKey as string)
      // TODO in BRMP-17: generalize a bit more to support more structures than 3 levels
      if (isUserAnswer) {
        userText = (data[`${itemKey}_${userTextKey}`] || '') as string
      } else if ((item.options || []).find(opt => opt.titleKey === answerKey)) {
        const childOption = (item.options || []).find(opt => opt.titleKey === answerKey) as FormItem
        const childKey = getItemKey(childOption, itemKey)
        subTitleKey = childOption.titleKey
        subAnswerKey = (data[childKey] || '') as string
        isUserAnswer = textFieldOptions.includes(subAnswerKey)
        if (isUserAnswer) {
          userText = (data[`${childKey}_${userTextKey}`] || '') as string
        } else if ((childOption.options || []).find(opt => opt.titleKey === subAnswerKey)) {
          const helpOption = (childOption.options || []).find(opt => opt.titleKey === subAnswerKey) as FormItem
          const helpKey = getItemKey(helpOption, childKey)
          helpTitleKey = helpOption.titleKey
          helpAnswerKey = (data[helpKey] || '') as string
          isUserAnswer = textFieldOptions.includes(helpAnswerKey)
          if (isUserAnswer) {
            userText = (data[`${helpKey}_${userTextKey}`] || '') as string
          }
        }
      }

      return {
        titleKey: item.titleKey,
        answerKey: answerKey || '',
        subTitleKey,
        subAnswerKey,
        helpTitleKey,
        helpAnswerKey,
        userText,
      }
    }),
  }
}

function* saveBugReport() {
  yield takeLatest<AnyAction>(constants.SAVE_BUG_REPORT, function* (action) {
    const {siteId, robotId, onSuccess} = action
    const reportUid = yield select((state: Store) => state.app.bugReportUid)
    const bugReportData = yield select((state: Store) => state.app.bugReportData)
    const bugReportFiles = yield select((state: Store) => state.app.bugReportFiles)
    const translationCategory = bugReportData.isNewFeature ? 'feature' : 'issue'
    const form = bugReportData.isNewFeature ? FEATURE_REQUEST_FORM : BUG_REPORT_FORM
    const preparedForm = prepareForm(form, bugReportData)
    const files: File[] = bugReportFiles[translationCategory] || []

    const formData = new FormData()
    formData.append('reportUid', reportUid)
    formData.append('isNewFeature', String(preparedForm.isNewFeature))
    formData.append('formRows', JSON.stringify(preparedForm.formRows))
    for (const file of files) {
      formData.append('files', file, file.name)
    }

    const response: Response = yield multiformPartData(`${siteId}/robot/${robotId}/report`, formData)
    const {status} = response
    if (status > 300) {
      yield put(saveBugReportDone())
      yield put(addSnackbarMessage(`Robot configuration bug report save error ${translationCategory}`))
    } else {
      // request accepted, bug report uid not needed anymore
      yield put(setBugReportUid(''))
    }

    const responseBody = yield getResponseBody(response)
    let doneRequestId = ''
    let isSuccess = false
    while (responseBody.requestId !== doneRequestId) {
      const {doneAction, serverConnectedAction, onlineAction} = yield race({
        doneAction: take(constants.BUG_REPORT_SERVER_DONE),
        serverConnectedAction: take(constants.SET_SERVER_CONNECTION_STATUS),
        onlineAction: take(constants.SET_DEVICE_ONLINE_STATUS),
      })

      if (doneAction) {
        doneRequestId = doneAction.requestId
        isSuccess = doneAction.isSuccess
      } else if (
        (serverConnectedAction && !serverConnectedAction.isConnected) ||
        (onlineAction && !onlineAction.isOnline)
      ) {
        yield put(saveBugReportDone())
        yield put(addSnackbarMessage('Robot configuration bug report save error offline'))
      }
    }

    if (isSuccess) {
      yield put(setBugReportData({}))
      yield put(saveBugReportDone())
      yield put(addSnackbarMessage(`Robot configuration bug report save success ${translationCategory}`))
      onSuccess()
    } else {
      yield put(saveBugReportDone())
      yield put(addSnackbarMessage(`Robot configuration bug report save error ${translationCategory}`))
    }
  })
}

function* triggerDataCollection() {
  yield takeLatest<AnyAction>(constants.TRIGGER_BUG_REPORT_DATA_COLLECTION, function* (action) {
    const {siteId, robotId} = action
    const reportUid = yield select((state: Store) => state.app.bugReportUid)
    if (reportUid) {
      yield put(cancelBugReportDataCollection(siteId, robotId, reportUid))
    }

    const response: Response = yield httpGet(`${siteId}/robot/${robotId}/report/trigger`)
    const {status} = response
    if (status > 300) {
      yield put(addSnackbarMessage(`Robot configuration bug report data collection failed`))
      window.history.go(-1)
    } else {
      const {reportUid} = yield getResponseBody(response)
      yield put(setBugReportUid(reportUid))
    }
  })
}

function* cancelDataCollection() {
  yield takeLatest<AnyAction>(constants.CANCEL_BUG_REPORT_DATA_COLLECTION, function* (action) {
    const {siteId, robotId, reportUid: maybeReportUid} = action
    const reportUid = maybeReportUid || (yield select((state: Store) => state.app.bugReportUid))
    if (reportUid) {
      yield httpPost(`${siteId}/robot/${robotId}/report/cancel`, {reportUid})
      yield put(setBugReportUid(''))
    }
  })
}

function* appSagas() {
  yield all([
    saveBugReport(),
    triggerDataCollection(),
    cancelDataCollection(),
  ])
}

export default appSagas
