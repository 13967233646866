export const EVENT_TYPES = {
  USER: {
    UPDATED: 'CLIENT_USER_UPDATED',
  },
  PATROL: {
    EVENT: 'CLIENT_PATROL_EVENT',
    CLEAR_EVENT: 'CLIENT_PATROL_CLEAR_EVENT',
    SCHEDULE: 'CLIENT_PATROL_SCHEDULE_UPDATE',
    SETTINGS: 'CLIENT_PATROL_SETTINGS_UPDATE',
    STATUS: 'CLIENT_PATROL_STATUS',
  },
  ROBOT: {
    ANNOUNCEMENT: 'CLIENT_ROBOT_ANNOUNCEMENT',
    CLEAR_ANNOUNCEMENT: 'CLIENT_ROBOT_CLEAR_ANNOUNCEMENT',
    UPDATED: 'CLIENT_ROBOT_UPDATED',
    BUG_REPORT_RESPONSE: 'CLIENT_ROBOT_BUG_REPORT_RESPONSE',
  },
}
