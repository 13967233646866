import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {DebugContent, Dialog, DialogContent, DialogTitle, Loader} from 'components'
import {getTimeDifferenceString} from 'utils'
import * as Styled from '../NSPCommonStyled'
import {StatusDescription, StatusTextsWrapper, StatusTitle} from '../NSPCommonStyled'
import {ActionsWrapper} from '../shared'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'
import {useTranslation} from 'react-i18next'

const HelpHeader = styled.div`
  margin: 12px 0;
`

const Title = styled(StatusTitle)`
  margin-bottom: 24px;
`

const LoaderWrapper = styled.div`
  flex: 1;
`

const fourMinutesInMillis = 1000 * 60 * 4

interface Props {
  canDebug?: boolean
  initializationFailedClientMessage?: string
  initializationFailedMessage?: string
}

const InitializingScreen = ({canDebug, initializationFailedMessage, initializationFailedClientMessage}: Props) => {
  const {i18n} = useTranslation()
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [initialDate] = useState(new Date())
  const [counter, setRenderCounter] = useState(0)
  const [showError, setShowError] = useState(Boolean(initializationFailedMessage))

  useEffect(() => {
    const interval = setInterval(() => {
      setRenderCounter(counter => counter + 1)
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (initializationFailedMessage) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }, [initializationFailedMessage])

  useEffect(() => {
    const difference = new Date().getTime() - initialDate.getTime()
    if (difference > fourMinutesInMillis) {
      setShowError(true)
    }
  }, [initialDate, counter])

  const clientMessageKey = initializationFailedClientMessage &&
    `PowerOn_InitializingRobot content robot initialization ${initializationFailedClientMessage}`
  const description = clientMessageKey && i18n.exists(clientMessageKey) ?
    t(clientMessageKey) :
    t('PowerOn_InitializingRobot content robot initialization information')
  const failedMessage = clientMessageKey && i18n.exists(clientMessageKey) ?
    t(clientMessageKey) :
    t('PowerOn_InitializingRobot content robot initialization failed message')

  return (
    <Styled.StatusMainContent>
      <StatusTextsWrapper $justifyContent="center">
        <Title>{t('PowerOn_InitializingRobot title robot initialization')}</Title>
        <StatusDescription>
          {description}
        </StatusDescription>
      </StatusTextsWrapper>
      <LoaderWrapper>
        <Loader
          centered
          size={60}
        />
      </LoaderWrapper>
      <ActionsWrapper>
        <div>{getTimeDifferenceString(initialDate)}</div>
      </ActionsWrapper>
      <Dialog
        open={showError}
        onClose={() => (canDebug ? setShowError(false) : undefined)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DialogTitle>
            {t('PowerOn_InitializingRobot title robot initialization failed title')}
          </DialogTitle>
          <HelpHeader>
            {failedMessage}
          </HelpHeader>
          <DebugContent>
            {t('Post result')}: {initializationFailedMessage}
          </DebugContent>
        </DialogContent>
      </Dialog>
    </Styled.StatusMainContent>
  )
}

export default InitializingScreen
