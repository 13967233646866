import {connect} from 'react-redux'
import {emptyArray} from 'utils'
import {Store} from 'types'
import ReportDetailsObjectsTab from './ReportDetailsObjectsTab'

const mapStateToProps = (state: Store) => ({
  objects: state.uv.settings.objects[0]?.objects || emptyArray,
})

export default connect(mapStateToProps)(ReportDetailsObjectsTab)
