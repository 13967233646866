import React, {ReactElement} from 'react'
import {AnyAction} from 'redux'
import {LogMessage} from 'types'

export interface CardAction {
    action?: () => void
    actionKey?: string
    isAvailableWhenRobotOffline?: boolean
    isCancel?: boolean
    disabledReason?: string
    localeKey: string
    textButtonLocaleKey?: string
    main?: boolean
    conditions?: string[]
    icon?: ReactElement
    moduleAction?: () => AnyAction
}

export interface AvailableActions {
    [actionKey: string]: CallableFunction
}

export interface CardActionProps {
    action: CardAction
    availableActions: AvailableActions
}

export interface LogsControlProps {
    initLogListener: (robotId: string, onNewMessage: (message: LogMessage | LogMessage[]) => void) => EventSource
    robotId: string
}

export interface AudioContextType {
    audioContinuous?: HTMLAudioElement
    audioOnce?: HTMLAudioElement
}

export const AudioContext = React.createContext<AudioContextType>({})


export enum ANNOUNCEMENT_TYPE {
    CHARGER_AFTER_PATROL_FINISHED = 'CHARGER_AFTER_PATROL_FINISHED'
}

export type Announcement = {
    robotId: string
    siteId: string
    type: ANNOUNCEMENT_TYPE
    id: string
}

export const ANNOUNCEMENT_TYPE_TO_TRANSLATION_KEY = {
  [ANNOUNCEMENT_TYPE.CHARGER_AFTER_PATROL_FINISHED]: 'connect charging cable',
}
