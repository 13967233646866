import React from 'react'
import {DiagnosticEvent, Locations} from '../types'
import {ReportEventSummary} from '../report/shared'
import {getFromLocation} from '../utils'
import styled from 'styled-components'
import {List} from 'components'

const StyledList = styled(List)`
  padding: 0 16px;
  font-weight: 500;
  
  & > div {
    justify-content: center;
    
    & > div {
      flex: none;
      max-width: 75%;
    }
  }
`

interface Props {
  events: DiagnosticEvent[]
  locations: Locations
  modules?: string[]
  hide?: boolean
}

const MoreProblemsPreview = ({hide, events, locations, modules}: Props) => {
  return !hide && events.length > 0 ? (
    <StyledList>
      {events.map(event => (
        <ReportEventSummary
          key={event.notificationUid}
          eventLocation={getFromLocation(event)}
          event={event}
          locations={locations}
          modules={modules}
          hideDate
        />
      ))}
    </StyledList>
  ) : null
}

export default MoreProblemsPreview
