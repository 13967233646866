import React from 'react'
import {useModuleAwareTranslation} from 'utils'
import {errorTitle} from '../../../colors'
import {PageSubtitle} from '../NSPCommonStyled'

export interface Props {
  robotLoaded: boolean
  canSendRobotCommand: boolean
  patrolling: boolean
  isInitializing: boolean
  isInCriticalState: boolean
  isPatrolConfigUp: boolean
  localeKeys: {
    initializing: string
    criticalState: string
    patrolConfigDown: string
    cannotSendNavCommand: string
    patrolling: string
    default: string
  }
}

const SettingsSubtitle: React.FC<Props> = ({
  robotLoaded,
  canSendRobotCommand,
  patrolling,
  isInCriticalState,
  isInitializing,
  isPatrolConfigUp,
  localeKeys,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  let titleKey = localeKeys.default
  if (isInitializing) {
    titleKey = localeKeys.initializing
  } else if (isInCriticalState) {
    titleKey = localeKeys.criticalState
  } else if (!isPatrolConfigUp) {
    titleKey = localeKeys.patrolConfigDown
  } else if (!canSendRobotCommand) {
    titleKey = localeKeys.cannotSendNavCommand
  } else if (patrolling) {
    titleKey = localeKeys.patrolling
  }

  const title = t(titleKey)
  return robotLoaded && title ? (
    <PageSubtitle style={{color: patrolling || !canSendRobotCommand ? errorTitle : undefined}}>
      {title}
    </PageSubtitle>
  ) : null
}

export default SettingsSubtitle
