import {connect} from 'react-redux'
import {Store} from 'types'
import {SettingsHomeEdit} from 'modules/navigation/settings'
import {getCurrentSettings} from './selectors'
import {setHomeUid} from './actions'

const mapStateToProps = (state: Store) => ({
  robotId: state.app.selectedRobot?.id,
  siteId: state.app.selectedRobot?.siteId,
  locations: state.nsp.siteSettings.locations,
  originalHomeUid: getCurrentSettings(state)?.homeUid,
})

export default connect(mapStateToProps, {
  onSave: setHomeUid,
})(SettingsHomeEdit)
