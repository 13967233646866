import React from 'react'
import styled from 'styled-components'
import MaterialButton, {ButtonProps as MaterialButtonProps} from '@material-ui/core/Button'
import MaterialToggleButton from '@material-ui/lab/ToggleButton'
import {shadeColor} from 'utils'
import {secondaryHeader, disabledButton, blueButton, white, blueButtonSelected} from '../colors'
import {ExtendButtonBase} from '@material-ui/core/ButtonBase'
import {ToggleButtonTypeMap} from '@material-ui/lab/ToggleButton/ToggleButton'

export const Title = styled.h1`
    text-align: center;
`

export const PageTitle = styled.div`
    font-size: 1.17rem;
    font-weight: bold;
    text-align: center;
    padding-top: 12px;
`

export const PageSubtitle = styled.div`
    margin-bottom: 30px; 
    text-align: center;
    
    &:empty {
      display: none;
    }
`

export const EmptyList = styled.div`
    font-weight: 500;
    padding: 18px 0;
    text-align: center;
`

export const CardTitle = styled.h3`
    margin: 0 0 12px 0;
    font-weight: 500;
    color: ${secondaryHeader};
`

export const CardContentFullWidth = styled.div`
    margin-left: -16px;
    margin-right: -16px;
`

export interface ButtonProps extends MaterialButtonProps {
    $bold?: boolean
    $isRound?: boolean
    $border?: string
    $backgroundColor?: string
    $disabledBackgroundColor?: string
    $textColor?: string
    $disabledTextColor?: string
    $isFab?: boolean
    $hoverShade?: number
    $opacity?: number
}

export const Button: React.ComponentType<ButtonProps> = styled(MaterialButton)`
    ${(props: ButtonProps): string => props.$isRound || props.$isFab ? 'border-radius: 50%!important;' : ''}

    &.MuiButton-root {
        ${(props: ButtonProps): string => props.$backgroundColor ?
    `background-color: ${props.$backgroundColor};` :
    ''}
    
        ${(props: ButtonProps): string => props.$border ?
    `border: ${props.$border};` :
    ''}
    
        ${(props: ButtonProps): string => props.$opacity ?
    `opacity: ${props.$opacity};` :
    ''}

        ${(props: ButtonProps): string => props.$isFab ?
    `box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.1);
    height: 48px;
    width: 48px;
    min-width: 48px;
    padding: 0px;` :
    ''}

        ${(props: ButtonProps): string => props.$isFab && props.size === 'large' ?
    `
        height: 64px;
        width: 64px;
        min-width: 64px;
        .MuiSvgIcon-root {
            font-size: 2rem;
        }
    ` :
    ''}

        ${(props: ButtonProps): string => props.$textColor ?
    `color: ${props.$textColor};` :
    ''}

        ${(props: ButtonProps): string => props.$bold ?
    `font-weight: bold;` :
    ''}

        &:hover {
            ${(props: ButtonProps): string => props.$backgroundColor ?
    `background-color: ${shadeColor(props.$backgroundColor, props.$hoverShade || -30)};` :
    ''}
        }
        &:disabled {
            ${(props: ButtonProps): string => props.$backgroundColor ?
    `background-color: ${props.$disabledBackgroundColor || disabledButton};` :
    ''}
            ${(props: ButtonProps): string => props.$disabledTextColor ?
    `color: ${props.$disabledTextColor};` :
    ''}
        }
`

export const TextButton = (props: ButtonProps) => (
  <Button
    $textColor={blueButton}
    $bold
    {...props}
  />
)

export const ToggleButton: ExtendButtonBase<ToggleButtonTypeMap> = styled(MaterialToggleButton)`
&.MuiToggleButton-root {
    background-color: ${blueButton};
    border-color: ${blueButton};
    color: ${white};
    
    &.Mui-selected {
      background-color: ${blueButtonSelected};
      border-color: ${blueButtonSelected};
      color: ${white};
    
      &:hover {
        background-color: ${blueButtonSelected};
        color: ${white};
      }
    }
    
    &:hover {
      background-color: ${blueButtonSelected};
      color: ${white};
    }
}    
`
