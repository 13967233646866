export function getAllPermutations<T>(input: Array<T>): Array<Array<T>> {
  const result: Array<Array<T>> = []

  if (input.length === 0) {
    return result
  }

  if (input.length === 1) {
    result.push([input[0]])
    return result
  }

  for (let i = 0; i < input.length; i++) {
    const current = input[i]
    const rest = [...input.slice(0, i), ...input.slice(i + 1)]
    const restPermutations = getAllPermutations(rest)

    for (let j = 0; j < restPermutations.length; j++) {
      result.push([current, ...restPermutations[j]])
    }
  }

  return result
}

export const emptyArray: any[] = []
Object.freeze(emptyArray)
