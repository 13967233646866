import React from 'react'
import {connect} from 'react-redux'
import {HealthStatus, Store} from 'types'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import styled from 'styled-components'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'

interface Props {
  limitFindingError: string | null
  healthStatus?: HealthStatus
}

interface CheckOutcome {
  check: string[]
  failure: string[]
}

const getSuccessMessage = (
  success: boolean | CheckOutcome | undefined,
  successMessage = 'success',
  failedMessage = 'failed - ',
  unknownMessage = 'unknown'
) => {
  if (success && typeof success === 'object') {
    return success.failure.length === 0 ? successMessage : ''
  }

  if (success === true) {
    return successMessage
  }

  if (success === false) {
    return failedMessage
  }

  return unknownMessage
}

const LimitFindingError = styled.pre`
  text-align: center;
  margin: 0;
`

const CheckOutcomeTableCell = ({outcome}: {outcome?: CheckOutcome}) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  return (
    <TableCell>
      {t(getSuccessMessage(outcome))}
      {(outcome?.failure || []).length > 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {t('Dashboard check outcome errors', {count: outcome?.failure.length})}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {outcome?.failure.map((error, index) => {
              const check = outcome?.check[index]
              return (
                <div key={index}>
                  {check ? `${check}: ${error}` : error }
                </div>
              )
            })}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </TableCell>
  )
}

const LimitFindingDetailedErrorsTableRows = ({healthStatus, limitFindingError}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)

  if (!healthStatus) {
    return null
  }

  const lfRerunnableResultSuccess = healthStatus.limitFindingOutcomeSuccess
  const lfRerunnableResultRerunnable = healthStatus.limitFindingOutcomeRerunnable

  const postCheckResult: CheckOutcome | undefined = healthStatus.postOutcomeSuccess !== undefined ? {
    check: healthStatus.postOutcomeCheck || [],
    failure: healthStatus.postOutcomeFailure || [],
  } : undefined
  const lfCheckResult: CheckOutcome | undefined = healthStatus.limitFindingOutcomeSuccess !== undefined ? {
    check: healthStatus.limitFindingOutcomeCheck || [],
    failure: healthStatus.limitFindingOutcomeFailure || [],
  } : undefined
  const gripperAutocalCheckResult: CheckOutcome | undefined = healthStatus.gripperAutocalOutcomeSuccess !== undefined ? {
    check: healthStatus.gripperAutocalOutcomeCheck || [],
    failure: healthStatus.gripperAutocalOutcomeFailure || [],
  } : undefined

  return (
    <>
      <TableRow>
        <TableCell variant="head" align="right">{t('Dashboard Post Result')}</TableCell>
        <CheckOutcomeTableCell outcome={postCheckResult} />
      </TableRow>
      <TableRow>
        <TableCell variant="head" align="right">{t('Dashboard Gripper Autocal Result')}</TableCell>
        <CheckOutcomeTableCell outcome={gripperAutocalCheckResult} />
      </TableRow>
      <TableRow>
        <TableCell variant="head" align="right">{t('Dashboard LF low level result')}</TableCell>
        <CheckOutcomeTableCell outcome={lfCheckResult} />
      </TableRow>
      <TableRow>
        <TableCell variant="head" align="right">{t('Dashboard LF high level result')}</TableCell>
        <TableCell>
          {t(getSuccessMessage(lfRerunnableResultSuccess))}
          <span>&nbsp;-&nbsp;</span>
          {t(getSuccessMessage(lfRerunnableResultRerunnable, 'rerunnable', 'not rerunnable', ''))}
        </TableCell>
      </TableRow>
      {limitFindingError && (
        <TableRow>
          <TableCell colSpan={2}>
            <LimitFindingError>{limitFindingError}</LimitFindingError>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
const mapStateToProps = (state: Store) => ({
  healthStatus: state.app.selectedRobot?.healthStatus,
  limitFindingError: state.limitFindingWizard.limitFindingError,
})

export default connect(mapStateToProps)(LimitFindingDetailedErrorsTableRows)

