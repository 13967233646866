import React from 'react'
import {ControlJoints} from 'components/robotControls'
import * as Styled from './StepsStyled'
import {StepProps} from './types'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const CheckJointsStepSlider: React.FC<StepProps> = ({selectedRobot}) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  return (
    <ControlJoints
      selectedRobot={selectedRobot}
      info={t('PowerOn_LimitFindingWizard Ensure that all joints are in the desired position')}
      title={(
        <Styled.StepTitle>
          {t('PowerOn_LimitFindingWizard Check all robot joints')}
        </Styled.StepTitle>
      )}
      titleSelectorWrapper={Styled.StepTexts}
      wrapper={Styled.Step}
    />
  )
}

export default CheckJointsStepSlider
