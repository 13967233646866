import {connect} from 'react-redux'
import {Store} from 'types'
import {closeModalForStep} from './actions'
import {STEPS} from './constants'
import ModalForStep from './ModalForStep'

const mapStateToProps = (state: Store) => ({
  step: state.limitFindingWizard.currentStep as STEPS,
  open: state.limitFindingWizard.isModalForStepOpen,
})

const mapDispatchToProps = {
  closeModalForStep,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalForStep)
