import {createSelector} from 'reselect'
import {getConfigurations} from '../../selectors'
import {scenarioToModule} from '../../../utils'
import {Store} from '../../../../types'

export const getConfigModules = createSelector(
  (state: Store) => getConfigurations(state).map(config => scenarioToModule(config.scenario)).join(';'),
  (configurations) => {
    return [
      ...new Set(configurations
        .split(';')
        .filter(Boolean)
      ),
    ] as string[]
  }
)
