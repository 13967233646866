import React, {useCallback, useEffect, useState} from 'react'
import {getTimeFormatForMoment, useModuleAwareTranslation, usePrevious} from 'utils'
import styled from 'styled-components'
import {
  Alarm,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FeatureToggle,
} from 'components'
import {blueButton, grayText, white} from '../colors'
import {useSelector} from 'react-redux'
import {getAsyncNotifications} from './selectors'
import {Storage} from 'platform'
import moment from 'moment'
import {selectors as authSelectors} from '../auth'
import {Store} from '../types'
import {PERMISSIONS} from '../constants'

const Content = styled.div`
  margin: 16px 0;
  text-align: left;
`

const Item = styled.div`
  margin: 16px 0;
  font-weight: 500;
`

const ItemDate = styled.div`
  margin-bottom: 6px;
  font-weight: 400;
  color: ${grayText};
`

const StyledAlarm = styled(Alarm)`
  margin-top: 24px;
`

const DISMISSED_NOTIFICATIONS_STORAGE_KEY = 'dismissedAsyncNotifications'

const AsyncNotificationsModal = () => {
  const {t} = useModuleAwareTranslation()
  const [loadedDataFromStorage, setLoadedDataFromStorage] = useState(false)
  const [playSound, setPlaySound] = useState(false)
  const [alarmKey, setAlarmKey] = useState(0)
  const [dismissedNotifications, setDismissedNotifications] = useState<string[]>([])
  const asyncNotifications = useSelector(getAsyncNotifications)
  const userId = useSelector((state: Store) => authSelectors.getUser(state)?.userId)

  const notificationsToShow = asyncNotifications
    .filter(notification => !dismissedNotifications.includes(notification.uid))
    .sort((notificationA, notificationB) => notificationB.timestamp - notificationA.timestamp)

  const notificationsCount = notificationsToShow.length
  const previousNotificationsCount = usePrevious(notificationsCount)
  const showModal = loadedDataFromStorage && notificationsCount > 0
  const timeFormat = getTimeFormatForMoment(userId)

  useEffect(() => {
    Storage.getItem(DISMISSED_NOTIFICATIONS_STORAGE_KEY)
      .then(items => {
        if (items) {
          try {
            setDismissedNotifications(JSON.parse(items))
          } catch (e) {
            console.error(e)
          }
        }
      })
      .finally(() => setLoadedDataFromStorage(true))
    setLoadedDataFromStorage(true)
  }, [])

  useEffect(() => {
    if (showModal) {
      setPlaySound(true)
    }
  }, [showModal, notificationsCount, previousNotificationsCount])

  useEffect(() => {
    if (playSound) {
      setAlarmKey((new Date()).getTime())
    }
  }, [playSound])

  useEffect(() => {
    if (previousNotificationsCount !== undefined && notificationsCount > previousNotificationsCount) {
      setPlaySound(true)
    }
  }, [notificationsCount, previousNotificationsCount])

  const turnOffSound = useCallback(() => {
    setPlaySound(false)
  }, [])

  const close = useCallback(() => {
    turnOffSound()
    const uids = asyncNotifications.map(notification => notification.uid)
    Storage.setItem(DISMISSED_NOTIFICATIONS_STORAGE_KEY, JSON.stringify(uids))
    setDismissedNotifications(uids)
  }, [turnOffSound, asyncNotifications])

  return showModal ? (
    <Dialog
      open
      onClose={close}
      fullWidth
      maxWidth="sm"
      /* default z-index is 1300 but we want to show it above other modals */
      style={{zIndex: 2000}}
    >
      <DialogContent>
        <DialogTitle>
          {t('Common async notifications title')}
        </DialogTitle>
        <Content>
          {notificationsToShow.map(notification => (
            <Item key={notification.uid}>
              <ItemDate>
                {moment(new Date(notification.timestamp)).format(timeFormat)}
                <FeatureToggle permissionName={PERMISSIONS.DEBUG_CONTENT}>
                  <span>  / [timestamp [s]: {Math.floor(notification.timestamp / 1000)}]</span>
                </FeatureToggle>
              </ItemDate>
              <div>{notification.message}</div>
            </Item>
          ))}
          <StyledAlarm
            key={alarmKey}
            disabled={!playSound}
            onChange={turnOffSound}
            switchProps={playSound ? {
              $offColor: blueButton,
            } : undefined}
            alarmType='once'
          />
        </Content>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          $backgroundColor={blueButton}
          $textColor={white}
        >
          {t('Common button close')}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null
}

export default AsyncNotificationsModal
