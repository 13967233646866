import {buildAction, buildAsyncAction, httpGet} from 'utils'
import * as constants from './constants'

export const setChildModule = buildAction(constants.SET_CHILD_MODULE, 'module', 'child')

export const setPageOptions = buildAction(constants.SET_PAGE_OPTIONS, 'options')
export const setSelectedRobot = buildAction(constants.SET_SELECTED_ROBOT, 'robot')
export const setSelectedRobotId = buildAction(constants.SET_SELECTED_ROBOT_ID, 'robotId')

export const setSupportedLanguages = buildAction(constants.SET_SUPPORTED_LANGUAGES, 'languages')

export const updateAvailable = buildAction(constants.UPDATE_AVAILABLE)

export const appInitialize = buildAction(constants.APP_INITIALIZE)
export const fetchPermissions = buildAction(constants.FETCH_PERMISSIONS)
export const setPermissions = buildAction(constants.SET_PERMISSIONS, 'permissions')

export const robotUpdated = buildAction(constants.ROBOT_UPDATED, 'robot')

export const changeServerConnectionStatus = buildAction(constants.SET_SERVER_CONNECTION_STATUS, 'isConnected')

export const setDeviceOnlineStatus = buildAction(constants.SET_DEVICE_ONLINE_STATUS, 'isOnline')

export const setGuiUseCase = buildAction(constants.SET_GUI_USE_CASE, 'guiUseCase')

export const fetchRobots = buildAsyncAction({
  isHttpRequest: true,
  returnFieldName: 'robots',
  typeOnError: constants.FETCH_ROBOTS_ERROR,
  typeOnSuccess: constants.FETCH_ROBOTS_SUCCESS,
  typeStarted: constants.FETCH_ROBOTS,
}, () => httpGet(`all/robot`))

export const fetchApiVersion = buildAsyncAction({
  isHttpRequest: true,
  returnFieldName: 'data',
  typeOnError: `${constants.SET_API_VERSION}_FAILED`,
  typeOnSuccess: constants.SET_API_VERSION,
  typeStarted: constants.FETCH_API_VERSION,
}, () => httpGet('config/version'))

export const addSnackbarMessage = buildAction(constants.ADD_SNACKBAR_MESSAGE, 'message', 'params', 'autoHideDuration', 'action')
export const removeSnackbarMessage = buildAction(constants.REMOVE_SNACKBAR_MESSAGE)

export const increaseOfflineCounter = buildAction(constants.INCREMENT_OFFLINE_COUNTER)
export const setOfflineRobots = buildAction(constants.SET_OFFLINE_ROBOTS, 'offlineRobotsKeys')

export const downloadFile = buildAction(constants.DOWNLOAD_FILE, 'fileName', 'url', 'errorMessage', 'onSuccess', 'onError')

export const setWindowDimensions = buildAction(constants.SET_WINDOW_DIMENSIONS, 'width', 'height')

export const openRobotSelector = buildAction(constants.OPEN_ROBOT_SELECTOR)
export const closeRobotSelector = buildAction(constants.CLOSE_ROBOT_SELECTOR)

export const openRobotEdit = buildAction(constants.OPEN_ROBOT_EDIT)
export const closeRobotEdit = buildAction(constants.CLOSE_ROBOT_EDIT)

export const navigationTriggered = buildAction(constants.NAVIGATION)

export const setSiteId = buildAction(constants.SET_SITE_ID, 'siteId')
export const initializeSiteId = buildAction(constants.INITIALIZE_SITE_ID)

export const setBugReportData = buildAction(constants.SET_BUG_REPORT_DATA, 'data')
export const setBugReportFiles = buildAction(constants.SET_BUG_REPORT_FILES, 'form', 'files')

export const saveBugReport = buildAction(constants.SAVE_BUG_REPORT, 'siteId', 'robotId', 'onSuccess')

export const saveBugReportDone = buildAction(constants.SAVE_BUG_REPORT_FINISHED)

export const bugReportServerDone = buildAction(constants.BUG_REPORT_SERVER_DONE, 'reportId', 'isSuccess')

export const addAnnouncement = buildAction(constants.ADD_ANNOUNCEMENT, 'announcement')
export const removeAnnouncement = buildAction(constants.REMOVE_ANNOUNCEMENT, 'siteId', 'robotId', 'announcementId')

export const setBugReportUid = buildAction(constants.SET_BUG_REPORT_UID, 'reportUid')
export const triggerBugReportDataCollection = buildAction(constants.TRIGGER_BUG_REPORT_DATA_COLLECTION, 'siteId', 'robotId')
export const cancelBugReportDataCollection = buildAction(constants.CANCEL_BUG_REPORT_DATA_COLLECTION, 'siteId', 'robotId', 'reportUid')

export const openSecondaryNav = buildAction(constants.OPEN_SECONDARY_NAV)
export const closeSecondaryNav = buildAction(constants.CLOSE_SECONDARY_NAV)
