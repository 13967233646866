import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {error, errorIcon} from '../colors'
import Switch from './Switch'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

export interface Props {
    disabled?: boolean
    isEmergencyStopOn?: boolean
}

const SliderContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: ${errorIcon};

    > svg {
        margin-right: 8px;
    }
`

const SliderIconWrapper = styled.div`
    flex: 1;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid ${errorIcon};
    margin: -1px;
    color: ${error};
`

const ProtectiveStopIndicator = ({
  disabled,
  isEmergencyStopOn,
}: Props) => {
  const [previousIsOn, setPreviousOn] = useState(isEmergencyStopOn)
  const [isOn, setIsOn] = useState(isEmergencyStopOn)
  const updateState = (isChecked: boolean): void => {
    setIsOn(!isChecked)
  }
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  useEffect(() => {
    if (previousIsOn === isEmergencyStopOn) {
      return
    }

    if (isOn !== isEmergencyStopOn) {
      setIsOn(isEmergencyStopOn)
    }

    setPreviousOn(isEmergencyStopOn)
  }, [isOn, isEmergencyStopOn, previousIsOn])

  const icon = (
    <SliderIconWrapper>
      <CloseIcon />
    </SliderIconWrapper>
  )
  return (
    <Switch
      $borderColor="#545454"
      checked={!isOn}
      disabled={disabled}
      onChange={updateState}
      $onColor="#E6E6E6"
      $offColor={errorIcon}
      $onLabel={(
        <SliderContent>
          <ArrowBackIcon />
          {t('Stop').toUpperCase()}
        </SliderContent>
      )}
      $offLabel={t('Stopped').toUpperCase()}
      $onIcon={icon}
      $offIcon={icon}
      $height={28}
      $width={160}
    />
  )
}

export default ProtectiveStopIndicator
