import {createSelector} from 'reselect'
import {LimitFindingStatus, RobotState, Store} from 'types'
import {emptyArray} from 'utils'
import {selectors} from 'shared'
import {getCurrentSchedule, isPatrolOngoing, getConfigurations} from '../selectors'
import {ScheduleItem} from '../types'

export const getListElements = createSelector(
  getCurrentSchedule,
  getConfigurations,
  (schedule, configurations) => {
    const settingItems: ScheduleItem[] = schedule?.scheduleItems || emptyArray
    if (configurations.length <= 1) {
      return settingItems
    }

    return settingItems.map(item => ({
      ...item,
      configuration: configurations.find(configuration => configuration.id === item.configurationUid),
    }))
  }
)

export const canEdit = (state: Store) => {
  const isLimitFindingOk = state.app.selectedRobot?.healthStatus.limitFindingStatus === LimitFindingStatus.OK
  const isSaving = state.nsp.schedule.isSavingItem
  return !isSaving && (
    state.app.isConnectedToServer &&
    selectors.isCurrentRobotOnline(state) &&
    state.app.selectedRobot?.healthStatus.robotState !== RobotState.INITIALIZING &&
    state.app.selectedRobot?.healthStatus.robotState !== RobotState.CRITICAL_ERROR &&
    isLimitFindingOk
  ) && !isPatrolOngoing(state)
}
