import {withStyles, Theme} from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import {errorTitle, white} from '../colors'

export const WarningTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: white,
    color: errorTitle,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: `1px solid ${errorTitle}`,
    maxWidth: '40vw',
    margin: '0 8px',
  },
  arrow: {
    color: errorTitle,
  },
}))(Tooltip)
