import styled from 'styled-components'
import {background, error} from '../colors'

export const LimitFindingWizard = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 -16px -16px -16px;
    padding: 16px;
    background: ${background};
`

export const LimitFindingWizardContent = styled.div`
    flex-direction: column;
    display: flex;
    flex: 1;
    padding: 0 0 16px 0;
`

export const LimitFindingWizardFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${(props: {isDone: boolean}) => (props.isDone ? 'center' : 'space-between')};
`

export const ManualStepTitle = styled.div`
  font-weight: bolder;
  margin-bottom: 8px;
  text-align: center;
  margin-top: 16px;
`

export const ManualContent = styled.div`
  ${(props: {withPadding?: boolean}) => props.withPadding ? `
    padding-left: 16px;
    padding-right: 16px;
    ` : ''}
`

export const ManualWarning = styled.div`
  color: ${error};
`

export const ManualOrderedList = styled.ol`
`

export const ManualUnorderedList = styled.ul`
`

export const ManualListItem = styled.li`
`

export const ManualImageWrapper = styled.div`
  margin: 16px 0;
  text-align: center;
`

export const ManualImage = styled.img`
  max-width: 100%;
  max-height: 30vh;
`

export const StepsWarning = ManualWarning

export const ConfirmationContent = styled.div`
  text-align: left;
`
