import React from 'react'
import {List} from 'components'
import {Locations, PatrolDetails} from '../../types'
import ReportEventSummary from './ReportEventSummaryContainer'
import {groupSkippedEvents} from '../utils'

interface Props {
  details: PatrolDetails
  locations: Locations
  setSelectedEvent: (eventId: string) => void
}

const ReportDetailsEvents = ({
  details,
  locations,
  setSelectedEvent,
}: Props) => {
  return (
    <List>
      {groupSkippedEvents(details.events, locations).map(({event, location}) => (
        <ReportEventSummary
          key={event.notificationUid}
          event={event}
          eventLocation={location}
          locations={locations}
          setSelectedEvent={setSelectedEvent}
          modules={details.modules}
        />
      ))}
    </List>
  )
}

export default ReportDetailsEvents
