import {FC} from 'react'
import {connect} from 'react-redux'
import {Store} from 'types'
import {getCurrentModules} from 'app/selectors'
import {getEventsForMoreProblemsPreview} from './selectors'
import MoreProblemsPreview from './MoreProblemsPreview'

const mapStateToProps = (state: Store) => ({
  events: getEventsForMoreProblemsPreview(state),
  locations: state.nsp.siteSettings.locations,
  modules: getCurrentModules(state),
})

export default connect(mapStateToProps)(MoreProblemsPreview) as FC<{hide: boolean}>
