import React, {useEffect, useCallback} from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {makeStyles, ThemeProvider} from '@material-ui/core/styles'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import {useHistory} from 'react-router-dom'
import {throttle, debounce} from 'throttle-debounce'
import {AudioContextProvider} from 'components'
import {prefetchImages} from 'utils'
import {routeChangedEvent} from '../events'
import AppUpdateContainer from './AppUpdateContainer'
import AppToolbarContainer from './AppToolbarContainer'
import RobotSelectorContainer from './RobotSelectorContainer'
import RobotNameEditModalContainer from './RobotNameEditModalContainer'
import SnackbarContainer from './SnackbarContainer'
import BottomNavigationBarContainer from './BottomNavigationBarContainer'
import * as Styled from './AppStyled'
import appTheme from './appTheme'
import {AppProps} from './types'
import StyledAppContainer from './StyledAppContainer'
import ModuleContextProviderContainer from './ModuleContextProviderContainer'
import SecondaryNavigationContainer from './SecondaryNavigationContainer'
import AsyncNotificationsModal from './AsyncNotificationsModal'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(theme => ({
  contentSmall: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
}))

const App = ({
  children,
  initApp,
  setDeviceOnlineStatus,
  setWindowDimensions,
}: AppProps) => {
  const {i18n} = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const notifyAboutRouteChanges = throttle(1000 * 60, () => {
    window.dispatchEvent(routeChangedEvent)
  })
  history.listen((location, action) => {
    notifyAboutRouteChanges()
  })

  const getHistory = useCallback(() => history, [history])

  const setOnlineStatus = useCallback(() => setDeviceOnlineStatus(true), [])
  const setOfflineStatus = useCallback(() => setDeviceOnlineStatus(false), [])
  const setDimensions = () => setWindowDimensions(window.innerWidth, window.innerHeight)
  const debouncedChangeDimensions = debounce(200, setDimensions)
  const setWindowSize = useCallback(() => debouncedChangeDimensions(), [])

  useEffect(() => {
    initApp(getHistory)
    prefetchImages([
      'calibration/1.gif',
      'calibration/1-manual.gif',
      'calibration/2.gif',
      'calibration/2-manual.gif',
      'calibration/3.gif',
      'calibration/3-manual.gif',
      'calibration/done.gif',
      'calibration/single-gripper-left.png',
      'calibration/single-gripper-right.png',
    ])
    setDimensions()
  }, [])

  useEffect(() => {
    window.addEventListener('online', setOnlineStatus)
    window.addEventListener('offline', setOfflineStatus)
    window.addEventListener('resize', setWindowSize)

    return () => {
      window.removeEventListener('online', setOnlineStatus)
      window.removeEventListener('offline', setOfflineStatus)
      window.removeEventListener('resize', setWindowSize)
    }
  }, [setOnlineStatus, setOfflineStatus, setWindowSize])

  return (
    <AudioContextProvider>
      <ModuleContextProviderContainer>
        <ThemeProvider theme={appTheme}>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            locale={i18n.language}
          >
            <StyledAppContainer>
              <CssBaseline />
              <AppToolbarContainer />
              <RobotSelectorContainer />
              <RobotNameEditModalContainer />
              <Styled.Content
                className={classes.contentSmall}
              >
                <div className={`${classes.toolbar} toolbar-spacer`} />
                {children}
              </Styled.Content>
              <AsyncNotificationsModal />
              <SnackbarContainer />
              <AppUpdateContainer />
            </StyledAppContainer>
            <SecondaryNavigationContainer />
            <BottomNavigationBarContainer />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ModuleContextProviderContainer>
    </AudioContextProvider>
  )
}

export default App
