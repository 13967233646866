import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Loader} from 'components'
import {httpGet, httpPost} from 'utils'
import {blueButton, white} from '../../../colors'
import {Locations} from '../types'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const Content = styled.div`
  margin: 16px 0;
`

const StyledFormControl = styled(FormControl)`
    > div:first-child {
      margin-right: 14px;
    }
`

const StyledSelect = styled(Select)`
  min-width: 300px;
`

interface Props {
  addSnackbarMessage: (message: string) => void
  robotId?: string
  siteId?: string
  isModalOpen: boolean
  closeModal: () => void
  locations: Locations
}

const emptyOption = {value: '', name: 'none'}

const RobotPositionModal = ({
  isModalOpen,
  closeModal,
  robotId,
  siteId,
  addSnackbarMessage,
  locations,
}: Props) => {
  const {i18n} = useTranslation()
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [homeLocations, setHomeLocations] = useState<string[]>([])
  const [isFetchingData, setIsFetchingData] = useState(false)
  const [selectedHomeLocation, setSelectedHomeLocation] = useState('')

  const language = i18n.language

  const handleHomeChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSelectedHomeLocation(event.target.value as string)
    event.stopPropagation()
    event.preventDefault()
  }

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedHomeLocation('')
    }
  }, [isModalOpen])

  useEffect(() => {
    if (robotId && siteId) {
      setIsFetchingData(true)
      setHomeLocations([])
      httpGet(`v1/location/${siteId}/${robotId}/home`)
        .then(response => {
          if (response.status < 400) {
            return response.json()
          }

          throw new Error('error when fetching home locations')
        })
        .then((response: {locations: string[]}) => {
          setSelectedHomeLocation('')
          setHomeLocations(response.locations || [])
        })
        .catch(error => console.log(error))
        .finally(() => setIsFetchingData(false))
    } else {
      setHomeLocations([])
    }
  }, [robotId, siteId, locations, language])

  const submit = useCallback(() => {
    if (selectedHomeLocation) {
      httpPost(`v1/location/${siteId}/${robotId}/home`, {location: selectedHomeLocation})
        .then(response => {
          if (response.status < 400) {
            addSnackbarMessage(t('Dashboard robot position success'))
            closeModal()
          } else {
            addSnackbarMessage(t('Dashboard robot position error'))
          }
        })
        .catch(() => {
          addSnackbarMessage(t('Dashboard robot position error'))
        })
    }
  }, [t, addSnackbarMessage, selectedHomeLocation, siteId, robotId, closeModal])

  const locationsToShow = homeLocations.map(uid => {
    let translation = locations[uid] && locations[uid][language] ?
      locations[uid][language] :
      locations[uid]?.en
    translation = translation ? `${translation} (${uid})` : uid
    return {
      uid,
      translation,
    }
  }).sort((itemA, itemB) => itemA.translation.localeCompare(itemB.translation))

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => {}}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <DialogTitle onClose={closeModal}>
          {t('Dashboard robot position title')}
        </DialogTitle>
        <Content>
          {isFetchingData ? <Loader centered /> : (
            <StyledFormControl>
              <div>
                {t('Dashboard robot position select')}
              </div>
              <StyledSelect
                id="presets-robot-position-select"
                value={selectedHomeLocation}
                onChange={handleHomeChange}
              >
                <MenuItem value={emptyOption.value}>
                  {emptyOption.name}
                </MenuItem>
                {locationsToShow.map(({uid, translation}) => (
                  <MenuItem
                    key={uid}
                    value={uid}
                  >
                    {translation}
                  </MenuItem>
                ))}
              </StyledSelect>
            </StyledFormControl>
          )}
        </Content>
        <DialogActions $noHorizontalPadding>
          <Button onClick={closeModal}>
            {t('Common button close')}
          </Button>
          {!isFetchingData && (
            <Button
              type="submit"
              $backgroundColor={blueButton}
              $textColor={white}
              onClick={submit}
              disabled={!selectedHomeLocation}
            >
              {t('Common button apply')}
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default RobotPositionModal
