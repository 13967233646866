import {Robot} from 'types'
import {sortObjectsByDateDesc} from '../utils'

export const hasTooLowBatteryForLimitFinding = (robot: Robot | null) => {
  if (!robot) {
    return false
  }

  if (!robot.config?.lfBatteryThreshold) {
    // lf threshold not fetched yet
    return true
  }

  const lfBatteryThreshold = robot.config?.lfBatteryThreshold
  const batteryLevel = robot.healthStatus.batteryLevel || 0
  return batteryLevel < lfBatteryThreshold
}

export const shouldDisableLimitFinding = (robot: Robot | null) => {
  return hasTooLowBatteryForLimitFinding(robot)
}

export const getNewestEventDate = (robot: Robot, currentDate?: Date) => {
  const eventsSortedByDate = (robot.diagnosticData?.events || [])
    .flatMap((eventsData: {events: Array<{date: Date | string}>}) => eventsData.events)
    .sort(sortObjectsByDateDesc)
  const maybeDate: Date | string | undefined = eventsSortedByDate[0]?.date
  const date = maybeDate ? new Date(maybeDate) : undefined
  const isNewerThanCurrent = currentDate && date && date.getTime() > currentDate.getTime()

  if (!currentDate || isNewerThanCurrent) {
    return date
  }

  return currentDate
}

