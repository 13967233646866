import {matchPath} from 'react-router-dom'

export function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const numberWithLeadingZero = (value: number) => value >= 10 ? `${value}` : `0${value}`

export const REVERSE_ROOM_ORDER_KEY = 'System room order'
export const REVERSE_ROOM_ORDER_VALUE = 'reverse'

// based on https://www.ling.upenn.edu/courses/Spring_2003/ling538/UnicodeRanges.html
const latinCharacterRegex = /[\u{0000}-\u{024F}]/u
export function isLatinCharacter(character: string) {
  return latinCharacterRegex.test(character)
}

export function isPathnameMatchingRoute(pathname: string, route: string) {
  if (pathname === route) {
    return true
  }

  const match = matchPath(pathname, {
    path: route,
  })

  return Boolean(match?.isExact)
}
