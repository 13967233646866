import React from 'react'
import ListIcon from '@material-ui/icons/List'
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined'
import IconButton from '@material-ui/core/IconButton'
import {OngoingPatrolEventType} from './types'

interface Props {
  className?: string
  type: OngoingPatrolEventType
  shouldShow: boolean
  setType: (type: OngoingPatrolEventType) => void
}

const OngoingPatrolEventsToggle = ({
  shouldShow,
  type,
  className,
  setType,
}: Props) => {
  const Icon = type === OngoingPatrolEventType.IMAGES ? ListIcon : ImageOutlinedIcon
  return shouldShow ? (
    <IconButton
      className={className}
      onClick={() => setType(type === OngoingPatrolEventType.IMAGES ?
        OngoingPatrolEventType.LIST :
        OngoingPatrolEventType.IMAGES)}
    >
      <Icon color="primary" />
    </IconButton>
  ) : null
}

export default OngoingPatrolEventsToggle
