import {connect} from 'react-redux'
import {remindersEnabled} from 'config'
import {Store} from 'types'
import {getSelectedRobot} from 'app'
import {selectors as authSelectors} from 'auth'
import {saveReminderSettings} from './actions'
import Reminders from './Reminders'

const mapStateToProps = (state: Store) => {
  const selectedRobot = getSelectedRobot(state)
  const maybeUser = authSelectors.getUser(state)
  const userSettings = maybeUser?.reminderSettings || {}
  let reminderSettings = null
  if (selectedRobot && userSettings[selectedRobot.siteId] && userSettings[selectedRobot.siteId][selectedRobot.id]) {
    reminderSettings = userSettings[selectedRobot.siteId][selectedRobot.id]
  }
  return {
    showReminders: remindersEnabled,
    isSaving: state.nsp.reminders.isSaving,
    isConnectedToServer: state.app.isConnectedToServer,
    reminderSettings,
    userId: maybeUser?.userId,
  }
}

export default connect(mapStateToProps, {saveReminderSettings})(Reminders)
