import styled from 'styled-components'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import {error, success} from '../../colors'

export const Step = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-top: 16px;
`

export const StepTexts = styled.div`
  text-align: left;
  max-width: 500px;
  margin: 0 auto;
`

export const StepTitle = styled.div`
  font-weight: bolder;
  margin-bottom: 8px;
`

export const StepQuestion = styled.div`
  font-weight: bolder;
`

export const StepInfo = styled.div`
  ${(props: {withBottomMargin?: boolean}) => props.withBottomMargin ? 'margin-bottom: 16px;' : ''}
`

export const StepWarning = styled.div`
  color: ${error};
  ${(props: {withBottomMargin?: boolean}) => props.withBottomMargin ? 'margin-bottom: 16px;' : ''}
`

export const StepNote = styled.div`
`

export const LoaderWrapper = styled.div`
  margin: 32px 0;
`

export const StepTimer = styled.div`
  margin-top: 16px;
  text-align: center;
`

export const StepContent = styled.div`
    flex: 1;
    width: 700px;
    max-width: 80vw;
    text-align: ${(props: {isProgressWrapper?: boolean}) => (props.isProgressWrapper ? 'center' : 'left')};
    margin-top: ${(props: {isProgressWrapper?: boolean}) => (props.isProgressWrapper ? '36' : '0')}px;

    .MuiStepConnector-lineVertical {
        min-height: 8px;
    }
`

export const StepImage = styled.div`
    flex: 1;
    flex-shrink: 0;
    min-height: 180px;
    width: 100%;
    margin: 16px 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: ${(props: {src: string}) => `url(${props.src})`};
    
    @media (min-height: 640px) {
      min-height: 240px;
      margin: 24px 0;
    }
`

export const SuccessIcon = styled(CheckCircleOutlineIcon)`
    margin: 30px auto 0 auto;
    display: block!important;
    font-size: 6rem!important;
    color: ${success};
`
