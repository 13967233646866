import {buildAction} from 'utils'
import * as constants from './constants'

export const fetchPatrolDetails = buildAction(constants.FETCH_PATROL_DETAILS, 'patrolId')
export const patrolDetailsLoaded = buildAction(constants.PATROL_DETAILS_LOADED)
export const setPatrolDetails = buildAction(constants.SET_PATROL_DETAILS, 'patrolDetails')

export const clearPatrolList = buildAction(constants.CLEAR_PATROL_LIST, 'page')
export const fetchPatrolList = buildAction(constants.FETCH_PATROL_LIST, 'page', 'module')
export const fetchPatrolListSuccess = buildAction(constants.FETCH_PATROL_LIST_SUCCESS, 'page', 'reports', 'hasMore')
export const fetchPatrolListFailed = buildAction(constants.FETCH_PATROL_LIST_ERROR, 'error')

export const setHistoryTabIndex = buildAction(constants.SET_HISTORY_TAB_INDEX, 'tabIndex')
