import {withPageOptionsHOC} from 'app'
import React from 'react'
import {Route} from 'react-router-dom'
import {SettingsPageContainer} from './settings'
import {SanitizationObjectDetailsContainer} from './report'
import {ROUTES as NAV_ROUTES} from '../navigation/constants'
import {ROUTES} from './constants'

const pageOptions = {}

const Routes = [
  <Route
    key="uv-settings"
    component={withPageOptionsHOC(pageOptions)(SettingsPageContainer)}
    path={NAV_ROUTES.OBJECT_SETTINGS}
  />,
  <Route
    key="uv-point"
    component={withPageOptionsHOC(pageOptions)(SanitizationObjectDetailsContainer)}
    path={ROUTES.POINT_REPORT}
  />,
]

export default Routes
