import {createSelector, defaultMemoize} from 'reselect'
import {Store, AsyncNotification} from 'types'
import {emptyArray, getKeyForRobot} from '../utils'
import {hasPermission} from '../components'

export const getSelectedRobot = (state: Store) => state.app.selectedRobot

const getSimpleRobots = defaultMemoize(
  (simpleRobots: {id: string, siteId: string, name: string, isConnected: boolean, module?: string}[]) => simpleRobots,
  (simpleRobotA, simpleRobotB) => JSON.stringify(simpleRobotA) === JSON.stringify(simpleRobotB)
)

export const getRobotsForSelection = createSelector(
  (state: Store) => state.app.robots,
  (state: Store) => state.app.siteId,
  (state: Store) => state.app.isConnectedToServer,
  (state: Store) => state.app.offlineRobots,
  (robots, siteId, isConnectedToServer, offlineRobots) => {
    const simpleRobots = robots
      .filter(robot => robot.siteId === siteId)
      .map(robot => ({
        id: robot.id,
        siteId: robot.siteId,
        name: robot.name,
        isConnected: isConnectedToServer && !offlineRobots.includes(getKeyForRobot(robot)),
        module: robot.modules?.current,
      }))

    return getSimpleRobots(simpleRobots)
  }
)

export const canSelectRobot = (state: Store) => {
  const selectedRobot = state.app.selectedRobot
  return Boolean(selectedRobot) && state.app.robots
    .some(robot => robot.siteId === selectedRobot?.siteId && robot.id !== selectedRobot.id)
}

export const getCurrentModules = createSelector(
  (state: Store) => state.app.selectedRobot?.modules?.current,
  (currentModule) => currentModule ? [currentModule] : []
)

export const canRunForModule = createSelector(
  (state: Store) => state.app.selectedRobot?.modules?.current,
  (state: Store) => state.app.childModules,
  (_: Store, ownProps: {module: string}) => ownProps.module,
  (currentModule, childModules, desiredModule) => {
    return currentModule === desiredModule || Boolean(childModules[desiredModule]?.includes(currentModule || ''))
  }
)

export const getAsyncNotifications = (state: Store): AsyncNotification[] => {
  const notifications: AsyncNotification[] | undefined = state.app.selectedRobot?.diagnosticData?.asyncNotifications
  if (!notifications) {
    return emptyArray
  }

  const allowedNotifications = notifications.filter(notification => hasPermission(state, notification.requiredPermission))
  return allowedNotifications.length > 0 ? allowedNotifications : emptyArray
}
