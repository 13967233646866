import {connect} from 'react-redux'
import {Store} from 'types'
import {i18n, getLanguageCodes} from 'locale'
import {processForBestLanguage} from 'utils'
import {confirmLocationEdit, locationEditEnd} from './actions'
import LocationEditModal from './LocationEditModal'

const mapStateToProps = (state: Store) => {
  const languageCodes = getLanguageCodes(i18n.language)
  return {
    locationId: state.nsp.siteSettings.locationForEdit,
    isOpen: state.nsp.siteSettings.locationForEdit !== null,
    initialTranslation: (state.nsp.siteSettings.locationForEdit ?
      processForBestLanguage(
        languageCodes,
        language => state.nsp.siteSettings.locations[state.nsp.siteSettings.locationForEdit as string][language],
        ''
      ) :
      undefined
    ) as string | undefined,
  }
}

const mapDispatchToProps = {
  cancel: locationEditEnd,
  save: confirmLocationEdit,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: {
    cancel: () => void
    save: (locationId: string, translation: string) => void
  }
) => ({
  ...stateProps,
  ...dispatchProps,
  save: (translation: string) => {
    if (stateProps.locationId) {
      dispatchProps.save(stateProps.locationId, translation)
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LocationEditModal)
