import {all} from 'redux-saga/effects'
import {sagas as actions} from './robotOperations'
import {sagas as limitFindingWizard} from './limitFindingWizard'
import {sagas as app} from './app'
import {sagas as dashboard} from './dashboard'

function* rootSaga() {
  yield all([
    app(),
    dashboard(),
    limitFindingWizard(),
    actions(),
  ])
}

export default rootSaga
