import {connect} from 'react-redux'
import {Store} from 'types'
import SettingsNameEdit from './SettingsNameEdit'
import {setSettingName} from '../actions'
import {getCurrentSettings, getCurrentSettingsIndex} from '../selectors'

const mapStateToProps = (state: Store) => ({
  currentConfigurationIndex: getCurrentSettingsIndex(state),
  originalName: getCurrentSettings(state)?.name,
  hasMultipleConfigurations: Boolean(
    state.app.selectedRobot &&
    state.nsp.settingsByRobot[state.app.selectedRobot.id] &&
    state.nsp.settingsByRobot[state.app.selectedRobot.id].length > 1
  ),
})

export default connect(mapStateToProps, {
  onSave: setSettingName,
})(SettingsNameEdit)
