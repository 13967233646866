import {connect} from 'react-redux'
import {Store} from '../../types'
import {setBugReportFiles} from '../actions'
import BugReportFiles from './BugReportFiles'

const mapStateToProps = (state: Store, ownProps: {form: string}) => ({
  files: state.app.bugReportFiles[ownProps.form],
})

export default connect(mapStateToProps, {setBugReportFiles})(BugReportFiles)
