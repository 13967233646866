import {connect} from 'react-redux'
import {AnyAction, compose} from 'redux'
import {ThunkDispatch} from 'redux-thunk'
import {History} from 'history'
import {Store} from 'types'
import App from './App'
import {fetchRobots, fetchPermissions, setDeviceOnlineStatus, setWindowDimensions, appInitialize} from './actions'
import {initEventListener} from '../events'

const mapDispatchToProps = (dispatch: ThunkDispatch<Store, undefined, AnyAction>) => ({
  initApp: (
    getHistory: () => History
  ) => {
    dispatch(fetchPermissions())
    dispatch(fetchRobots())
    initEventListener(dispatch, getHistory)
    dispatch(appInitialize())
  },
  setDeviceOnlineStatus: compose(dispatch, setDeviceOnlineStatus),
  setWindowDimensions: compose(dispatch, setWindowDimensions),
})

export default connect(null, mapDispatchToProps)(App)
