import {connect} from 'react-redux'
import {addSnackbarMessage} from 'app'
import {Store} from 'types'
import {closePresetsModal} from './actions'
import PresetsModal from './PresetsModal'

const mapStateToProps = (state: Store) => ({
  isModalOpen: state.nsp.dashboard.isPresetsModalOpen,
  robotId: state.app.selectedRobot?.id,
  siteId: state.app.selectedRobot?.siteId,
  currentModule: state.app.selectedRobot?.modules?.current,
})

export default connect(mapStateToProps, {
  closeModal: closePresetsModal,
  addSnackbarMessage,
})(PresetsModal)
