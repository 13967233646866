import React, {useEffect, useState} from 'react'
import Image from './Image'
import {useServerImage} from '../utils'

export interface Props {
  className?: string
  placeholderSrc?: string
  src: string
}

const ServerImage = ({className, src, placeholderSrc}: Props) => {
  const [timeoutCounter, setTimeoutCounter] = useState(0)
  const [errorCounter, setErrorCounter] = useState(0)
  const imageSrcWithCounter = errorCounter && src.startsWith('http') ?
    `${src}?counter=${errorCounter}` :
    src
  const serverImage = useServerImage(imageSrcWithCounter)

  const increaseTimeoutCounter = () => {
    setTimeoutCounter(counter => counter + 1)
  }
  useEffect(() => {
    if (!timeoutCounter) {
      return
    }

    // increase error counter every second
    //   so we don't make too many request if there's no network connection
    const timeout = window.setTimeout(() => setErrorCounter(counter => counter + 1), 1000)
    return () => {
      window.clearTimeout(timeout)
    }
  }, [timeoutCounter])

  return (
    <Image
      className={className}
      src={serverImage || imageSrcWithCounter}
      placeholderSrc={placeholderSrc}
      onError={increaseTimeoutCounter}
    />
  )
}

export default ServerImage
