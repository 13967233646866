import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components'
import {networkErrors} from '../utils'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const Content = styled.pre`
  margin: 16px 0;
  white-space: pre-line;
`

interface Props {
  isModalOpen: boolean
  closeModal: () => void
}

const NetworkErrorsModal = ({
  isModalOpen,
  closeModal,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [errors, setErrors] = useState<typeof networkErrors>([])
  useEffect(() => {
    setErrors([...networkErrors])
  }, [isModalOpen])

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <DialogTitle onClose={closeModal}>
          {t('Dashboard network errors')}
        </DialogTitle>
        <Content>
          {errors.length > 0 ? errors.map(({date, path}) => `${date}: ${path}\n\n`) : 'No errors'}
        </Content>
        <DialogActions $noHorizontalPadding>
          <Button onClick={closeModal}>
            {t('Common button close')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default NetworkErrorsModal
