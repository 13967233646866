import {AnyAction, combineReducers} from 'redux'
import * as constants from './constants'
import {SanitizedObjectDetails} from '../types'

const isLoadingDetails = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.FETCH_POINT_DETAILS:
      return true
    case constants.POINT_DETAILS_LOADED:
      return false
    default:
      return state
  }
}

const pointDetails = (state: SanitizedObjectDetails | null = null, action: AnyAction): SanitizedObjectDetails | null => {
  switch (action.type) {
    case constants.SET_POINT_DETAILS:
      return {
        ...action.pointDetails,
        events: (action.pointDetails as SanitizedObjectDetails).events
          .sort((eventA, eventB) => new Date(eventB.date).getTime() - new Date(eventA.date).getTime()),
      }
    default:
      return state
  }
}

const historyType = (state = 'object', action: AnyAction): string => {
  switch (action.type) {
    case constants.SET_HISTORY_TYPE:
      return action.historyType
    default:
      return state
  }
}

export default combineReducers({
  isLoadingDetails,
  pointDetails,
  historyType,
})
