import React from 'react'
import {connect} from 'react-redux'
import {Store} from 'types'
import {selectors as authSelectors} from 'auth'
import ReportEventSummary, {Props} from './ReportEventSummary'

const mapStateToProps = (state: Store) => ({
  userId: authSelectors.getUser(state)?.userId,
})

export default connect(mapStateToProps)(ReportEventSummary) as React.FC<Props>
