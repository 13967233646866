import {connect} from 'react-redux'
import {Store} from 'types'
import {setSelectedRobotId, closeRobotSelector} from './actions'
import {getSelectedRobot, getRobotsForSelection} from './selectors'
import RobotSelector from './RobotSelector'

const mapStateToProps = (state: Store) => ({
  isOpen: state.app.isRobotSelectorOpen,
  robots: getRobotsForSelection(state),
  selectedRobotId: getSelectedRobot(state)?.id,
})

export default connect(mapStateToProps, {
  close: closeRobotSelector,
  setSelectedRobotId,
})(RobotSelector)
