import {connect} from 'react-redux'
import {Store} from 'types'
import SettingsListItem from './SettingsListItem'
import {SettingsListItemConfig} from './types'
import {getCurrentSettings} from '../selectors'
import {getLocationIdsFromChildren} from './utils'

const mapStateToProps = (state: Store, ownProps: {config: SettingsListItemConfig, isParent: boolean}) => {
  const settings = getCurrentSettings(state)
  const childrenIds = getLocationIdsFromChildren(ownProps.config)
  const enabled = settings !== undefined && ((
    childrenIds.length === 0 &&
        settings.rooms.some(room => room.roomId === ownProps.config.id && room.enabled)
  ) || (
    childrenIds.length > 0 &&
        childrenIds.every(roomId => settings.rooms.some(room => room.roomId === roomId && room.enabled))
  ))
  return {
    childrenIds: childrenIds.join(';'),
    enabled,
  }
}

export default connect(mapStateToProps)(SettingsListItem)
