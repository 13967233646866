import {connect} from 'react-redux'
import {Store} from 'types'
import {SettingsNameEdit} from 'modules/navigation/settings'
import {getCurrentConfigurationIndex, getCurrentSettings} from './selectors'
import {setName} from './actions'

const mapStateToProps = (state: Store) => ({
  currentConfigurationIndex: getCurrentConfigurationIndex(state),
  originalName: getCurrentSettings(state)?.name,
  hasMultipleConfigurations: state.uv.settings.objects.length > 1,
})

export default connect(mapStateToProps, {
  onSave: setName,
})(SettingsNameEdit)
