import React, {useEffect, useState} from 'react'
import {useRouteMatch, useLocation} from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'
import {List, Loader} from 'components'
import {getLanguageCodes, i18n} from 'locale'
import {getDateAndTimeFormatForMoment, processForBestLanguage} from 'utils'
import {PageMainContent, PageSubtitle, PageTitle} from '../../../navigation/NSPCommonStyled'
import {DiagnosticReason, Locations} from '../../../navigation/types'
import {getFromLocation, getLocationParts} from '../../../navigation/utils'
import {SanitizedObjectDetails, SanitizationLevel} from '../../types'
import MultipleEventsModal from '../../../navigation/patrolEvents/MultipleEventsModal'
import {ReportEventSummary} from '../../../navigation/report/shared'
import * as Styled from '../../../navigation/report/details/ReportDetailsStyled'
import {moduleName} from '../../constants'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  details: SanitizedObjectDetails | null
  isLoadingDetails: boolean
  locations: Locations
  robotId?: string
  userId?: string
  fetchPointDetails: (pointId?: string) => void
}

const getDetailedLocation = (location: string | undefined, locations: Locations, unknownPointTranslation: string) => {
  const parts = getLocationParts(locations, location)
  if (parts.length < 1) {
    return unknownPointTranslation
  }

  return parts.slice(0, parts.length - 1).join(', ')
}

const ObjectName = styled.div`
  margin-top: 12px;
  font-weight: 500;
`

const SanitizationObjectDetails = ({
  details,
  isLoadingDetails,
  locations,
  robotId,
  fetchPointDetails,
  userId,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const languageCodes = getLanguageCodes(i18n.language)
  const routeMatch = useRouteMatch<{pointId?: string}>()
  const [selectedEvent, setSelectedEvent] = useState('')
  const pointId = routeMatch?.params?.pointId
  const {pathname} = useLocation()
  const objectId = !pointId || pathname.endsWith(pointId) ? pointId : pathname.substring(pathname.indexOf(pointId))
  useEffect(() => {
    fetchPointDetails(objectId)
  }, [objectId])

  const unknownPointTranslation = t('Nav_ReportEventView content unknown point')
  const lastSanitizationTime = details?.lastOperationDate ?
    moment(details.lastOperationDate).fromNow() :
    '—'
  const objectName = objectId && locations[objectId] ?
    processForBestLanguage(languageCodes, languageCode => locations[objectId][languageCode], '') :
    objectId
  const dateAndTimeFormat = getDateAndTimeFormatForMoment(userId)
  return (
    <>
      <PageTitle>{t('uv_ObjectSanitizingHistory title sanitization report item')}</PageTitle>
      {details && !isLoadingDetails && (
        <PageSubtitle>
          <ObjectName>
            {objectName}
          </ObjectName>
          <div>
            {getDetailedLocation(objectId, locations, unknownPointTranslation)}
          </div>
          <div>
            {t('uv_ObjectSanitizingHistory content sanitization report item last disinfection', {time: lastSanitizationTime})}
          </div>
        </PageSubtitle>
      )}
      <PageMainContent>
        {isLoadingDetails && (
          <Styled.LoaderWrapper>
            <Loader />
          </Styled.LoaderWrapper>
        )}
        {!isLoadingDetails && details && (
          <List>
            {details.events.map(event => (
              <ReportEventSummary
                key={event.notificationUid}
                event={event}
                eventLocation={getFromLocation(event)}
                locations={locations}
                setSelectedEvent={setSelectedEvent}
                dateFormat={dateAndTimeFormat}
                modules={[moduleName]}
                extraTranslationOptions={{
                  title: event.reason === DiagnosticReason.POINT_DONE ?
                    t(
                      'uv_ObjectSanitizingHistory content sanitization report item power applied',
                      // @ts-ignore
                      {level: t(`uv_SanitizingObjects general ${event.patrolPointConfig?.uv_target_fluence || SanitizationLevel.LOW} uvc`)}
                    ) :
                    t('uv_ObjectSanitizingHistory content sanitization reason point skipped'),
                }}
              />
            ))}
          </List>
        )}
        {!isLoadingDetails && !details && (
          <Styled.LoaderWrapper>
            <div>{t('uv_ObjectSanitizingHistory content sanitization empty object details')}</div>
          </Styled.LoaderWrapper>
        )}
        {selectedEvent && details && (
          <MultipleEventsModal
            close={() => setSelectedEvent('')}
            events={details?.events}
            locations={locations}
            selectedEventId={selectedEvent}
            robotId={robotId as string}
            userId={userId}
          />
        )}
      </PageMainContent>
    </>
  )
}

export default SanitizationObjectDetails
