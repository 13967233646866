import {
  all,
  call,
  take,
  takeLatest,
  put,
  race, select,
} from 'redux-saga/effects'
import {AnyAction} from 'redux'
import {httpPost, getResponseBody, isLimitFindingInProgress, getSelectedSiteAndRobotId} from 'utils'
import {ActionWithRobot, LimitFindingStatus, Status, Store} from 'types'
import {constants as appConstants} from 'app'
import {runLimitFindingError, runLimitFindingSuccess} from './actions'
import * as constants from './constants'

function* runLimitFinding(action: ActionWithRobot) {
  const {robot} = action
  let currentLimitFindingStatus = robot.healthStatus.limitFindingStatus
  const response: Response = yield httpPost(`${robot.siteId}/robot/${robot.id}/runLimitFinding`)
  const {status} = response
  const responseBody = yield getResponseBody(response)
  if (status >= 400) {
    yield put(runLimitFindingError(responseBody && responseBody.error ? responseBody.error : undefined))
  } else {
    while (true) {
      const robotUpdatedAction = yield take<ActionWithRobot>(appConstants.ROBOT_UPDATED)
      const currentRobotId = yield select((state: Store) => state.app.selectedRobot?.id)
      if (robotUpdatedAction.robot.id !== currentRobotId) {
        continue
      }

      const nextLimitFindingStatus = robotUpdatedAction.robot.healthStatus.limitFindingStatus
      const isNextLimitFindingInProgress = isLimitFindingInProgress(nextLimitFindingStatus)
      if (nextLimitFindingStatus !== currentLimitFindingStatus && !isNextLimitFindingInProgress) {
        const actionToReturn = nextLimitFindingStatus === Status.OK ?
          runLimitFindingSuccess() :
          runLimitFindingError()
        yield put(actionToReturn)
        return
      }

      currentLimitFindingStatus = nextLimitFindingStatus
    }
  }
}

function* runLimitFindingSaga() {
  yield takeLatest<ActionWithRobot>(constants.RUN_LIMIT_FINDING, function* (action) {
    yield race({
      cancel: take(constants.CANCEL_LIMIT_FINDING),
      task: call(runLimitFinding, action),
    })
  })
}

function* cancelLimitFindingSage() {
  yield takeLatest<AnyAction>(constants.CANCEL_LIMIT_FINDING, function* (action) {
    const {siteId} = yield getSelectedSiteAndRobotId()
    yield httpPost(`${siteId}/robot/${action.robotId}/cancelLimitFinding`)
  })
}

function* checkLimitFindingSuccess() {
  yield takeLatest<ActionWithRobot>(appConstants.ROBOT_UPDATED, function* (action) {
    const isSuccess = action.robot.healthStatus.limitFindingStatus === LimitFindingStatus.OK
    if (!isSuccess) {
      return
    }

    const currentRobotId = yield select((state: Store) => state.app.selectedRobot?.id)
    if (currentRobotId !== action.robot.id) {
      return
    }

    const limitFindingError = yield select((state: Store) => state.limitFindingWizard.limitFindingError)
    if (limitFindingError) {
      yield put(runLimitFindingSuccess())
    }
  })
}

function* limitFindingWizardSagas() {
  yield all([
    runLimitFindingSaga(),
    checkLimitFindingSuccess(),
    cancelLimitFindingSage(),
  ])
}

export default limitFindingWizardSagas
