import React, {useState} from 'react'
import styled from 'styled-components'
import {Button, Dialog, DialogContent, DialogTitle} from 'components'
import * as Styled from '../NSPCommonStyled'
import {StatusDescription, StatusTextsWrapper, StatusTitle} from '../NSPCommonStyled'
import {ActionsWrapper} from '../shared'
import {blueButton, white} from '../../../colors'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const WideButton = styled(Button)`
  width: calc(100% - 32px);
  max-width: 360px;
  min-height: 42px;
`

const HelpHeader = styled.div`
  margin: 12px 0;
`

const HelpContent = styled.div`
  > div {
    margin: 8px 0;
  }
`

const HelpFooter = styled.div`
  margin: 12px 0;
  font-weight: 500;
`

const NoConnectionScreen = () => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [showHelp, setShowHelp] = useState(false)

  return (
    <Styled.StatusMainContent>
      <StatusTextsWrapper $justifyContent="center">
        <StatusTitle>{t('RobotNoConnection title robot no connection title')}</StatusTitle>
        <StatusDescription>
          {t('RobotNoConnection content robot no connection information')}
        </StatusDescription>
      </StatusTextsWrapper>
      <ActionsWrapper>
        <WideButton
          $textColor={white}
          $backgroundColor={blueButton}
          onClick={() => setShowHelp(true)}
        >
          {t('RobotNoConnection button robot no connection help button')}
        </WideButton>
      </ActionsWrapper>
      <Dialog
        open={showHelp}
        onClose={() => setShowHelp(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DialogTitle>
            {t('RobotNoConnection title robot no connection help title')}
          </DialogTitle>
          <HelpHeader>
            {t('RobotNoConnection content robot no connection help header')}
          </HelpHeader>
          <HelpContent>
            {t('RobotNoConnection content robot no connection help items').split('\n').map((text, index) => (
              <div key={index}>
                {index + 1}. {text}
              </div>
            ))}
          </HelpContent>
          <HelpFooter>
            {t('RobotNoConnection content robot no connection help footer')}
          </HelpFooter>
          <ActionsWrapper>
            <WideButton
              $textColor={white}
              $backgroundColor={blueButton}
              onClick={() => setShowHelp(false)}
            >
              {t('Common button close')}
            </WideButton>
          </ActionsWrapper>
        </DialogContent>
      </Dialog>
    </Styled.StatusMainContent>
  )
}

export default NoConnectionScreen
