import {connect} from 'react-redux'
import {Store} from 'types'
import {clearPatrolList, fetchPatrolList} from 'modules/navigation/report/actions'
import {isPatrolOngoing} from 'modules/navigation/selectors'
import HistoryListContainer from './HistoryListContainer'
import {selectors as authSelectors} from 'auth'

const mapStateToProps = (state: Store) => {
  return {
    robotId: state.app.selectedRobot?.id,
    hasMoreReports: state.nsp.report.hasMoreReports,
    reports: state.nsp.report.finishedReports,
    showOngoingReportLink: isPatrolOngoing(state),
    userId: authSelectors.getUser(state)?.userId,
    locations: state.nsp.siteSettings.locations,
  }
}

export default connect(
  mapStateToProps,
  {
    clearPatrolList,
    fetchPatrolList,
  }
)(HistoryListContainer)
