import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  getSeverity,
  GoToElementIcon,
  ListItem,
  StatusIcon,
} from 'components'
import {DiagnosticReason, PatrolEventLevel} from '../../types'
import {blueButton, white} from '../../../../colors'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  level?: PatrolEventLevel
  link?: (() => void) | string
  text: string
  extendedInformation?: {
    modalTitleKey: string
    content: React.ReactNode
  }
  reason?: DiagnosticReason
}

const StyledListItem = styled(ListItem)`
    padding-top: 12px;
    padding-bottom: 12px;
`

const StyledDialogActions = styled(DialogActions)`
  padding: 8px 24px!important;
`

const MainText = styled.div`
    flex: 1;
    margin: 0 12px;
    line-height: 1.2em;
    white-space: pre-line;
`

const ReportListRow = ({
  level,
  link,
  text,
  reason,
  extendedInformation,
}: Props) => {
  const history = useHistory()
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [showExtendedInfo, setShowExtendedInfo] = useState(false)
  let onClick = typeof link === 'string' ? () => history.push(link) : link as ((() => void) | undefined)
  if (reason === DiagnosticReason.SKIPPED_DUE_TO_ONGOING_PATROL) {
    onClick = undefined
  }

  if (extendedInformation) {
    onClick = () => setShowExtendedInfo(true)
  }

  return (
    <>
      <StyledListItem
        data-testid="report-list-item"
        data-testserverity={getSeverity(reason)}
        clickable={Boolean(onClick)}
        fullWidth
        onClick={onClick}
      >
        <StatusIcon severity={getSeverity(reason)} level={level} />
        <MainText dangerouslySetInnerHTML={{__html: text}} />
        {onClick && (
          <GoToElementIcon/>
        )}
      </StyledListItem>
      {showExtendedInfo && extendedInformation && (
        <Dialog
          open
          onClose={() => setShowExtendedInfo(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <DialogTitle>
              {extendedInformation.modalTitleKey}
            </DialogTitle>
            {extendedInformation.content}
          </DialogContent>
          <StyledDialogActions>
            <Button
              onClick={() => setShowExtendedInfo(false)}
              $backgroundColor={blueButton}
              $textColor={white}
            >
              {t('Common button close')}
            </Button>
          </StyledDialogActions>
        </Dialog>
      )}
    </>
  )
}

export default ReportListRow
