import React from 'react'
import {withStyles, Theme, createStyles} from '@material-ui/core/styles'
import Switch, {SwitchClassKey, SwitchProps} from '@material-ui/core/Switch'
import {blueButton, gray, lightGray} from '../colors'

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string
}

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    checked: {},
    focusVisible: {},
    root: {
      height: 24,
      margin: 0,
      padding: 0,
      width: 48,
    },
    switchBase: {
      '&$checked': {
        '& + $track': {
          backgroundColor: blueButton,
          border: 'none',
          opacity: 1,
        },
        'color': theme.palette.common.white,
        'transform': 'translateX(23px)',
      },
      '&$focusVisible $thumb': {
        border: '6px solid #fff',
        color: blueButton,
      },
      '&.Mui-disabled': {
        '& + $track': {
          backgroundColor: gray,
          opacity: '0.35!important',
        },
      },
      'padding': 1,
    },
    thumb: {
      height: 22,
      width: 22,
    },
    track: {
      backgroundColor: lightGray,
      border: `1px solid ${lightGray}`,
      borderRadius: 24 / 2,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
  })
)(({classes, ...props}: SwitchProps & {classes: Styles}) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        checked: classes.checked,
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
      }}
      {...props}
    />
  )
})

export default IOSSwitch
