import React, {useState, useEffect} from 'react'
import {Button, Dialog, DialogContent, DialogActions, TextButton} from 'components'
import ScheduleTimeSettings from './ScheduleTimeSettings'
import {blueButton, white} from '../../../colors'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  cancel: () => void
  initialDate?: Date
  initialEnabled?: boolean
  initialCronExpression?: string
  initialRRuleExpression?: string
  initialWeeklyRepetitions?: number
  isOpen: boolean
  save: (date: Date, enabled: boolean, cronExpression: string, rruleExpression: string, weeklyRepetitions: number) => void
}

const ScheduleTimePickerModal = ({
  cancel,
  initialDate,
  initialEnabled,
  initialCronExpression,
  initialRRuleExpression,
  initialWeeklyRepetitions,
  isOpen,
  save,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [date, setDate] = useState(initialDate || new Date())
  const [enabled, setEnabled] = useState(Boolean(initialEnabled))
  const [cronExpression, setCronExpression] = useState(initialCronExpression || '')
  const [rruleExpression, setRRuleExpression] = useState(initialRRuleExpression || '')
  const [weeklyRepetitions, setWeeklyRepetitions] = useState(initialWeeklyRepetitions || 1)
  useEffect(() => {
    setDate(initialDate || new Date())
  }, [initialDate])

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={() => {}}
      maxWidth="xs"
    >
      <DialogContent>
        <ScheduleTimeSettings
          date={date}
          enabled={enabled}
          cronExpression={cronExpression}
          rruleExpression={rruleExpression}
          weeklyRepetitions={weeklyRepetitions}
          insideModal
          setData={(date, enabled, cronExpression, rruleExpression, weeklyRepetitions) => {
            setDate(date)
            setEnabled(enabled)
            setCronExpression(cronExpression)
            setRRuleExpression(rruleExpression)
            setWeeklyRepetitions(weeklyRepetitions)
          }}
        />
      </DialogContent>
      <DialogActions>
        <TextButton
          data-testid="schedule-cancel-button"
          onClick={cancel}
        >
          {t('Common button cancel')}
        </TextButton>
        <Button
          data-testid="schedule-save-button"
          onClick={() => save(date, enabled, cronExpression, rruleExpression, weeklyRepetitions)}
          $backgroundColor={blueButton}
          $textColor={white}
        >
          {t('Common button save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ScheduleTimePickerModal
