import React, {useCallback, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  ListItem,
  SimpleSwitch,
} from 'components'
import {useModuleAwareTranslation} from 'utils'
import {getListElements, ScheduleSortingInfoModal} from '../../schedule'
import {Store} from '../../../../types'
import {scheduleOrderUpdate} from '../../actions'

const ScheduleOrderSetting = () => {
  const {t} = useModuleAwareTranslation()
  const [showSortingModal, setShowSortingModal] = useState(false)
  const dispatch = useDispatch()
  const scheduleItems = useSelector(getListElements)
  const isSavingSchedule = useSelector((store: Store) => store.nsp.isSavingSchedule)
  const isSortedByTime = scheduleItems.every(item => !item.uiOrder)

  const onSwitch = useCallback(() => {
    if (isSortedByTime) {
      setShowSortingModal(true)
    } else {
      const newOrder = scheduleItems.map((item) => ({id: item.id, newOrder: undefined}))
      dispatch(scheduleOrderUpdate(newOrder))
    }
  }, [dispatch, isSortedByTime, scheduleItems])

  return (
    <ListItem
      fullWidth
      bolder
    >
      <span>{t('Nav_Schedule order setting name')}</span>
      <SimpleSwitch
        disabled={isSavingSchedule}
        checked={isSortedByTime}
        onChange={onSwitch}
      />
      <ScheduleSortingInfoModal
        isOpen={showSortingModal}
        isSettingsPage
        close={() => setShowSortingModal(false)}
      />
    </ListItem>
  )
}

export default ScheduleOrderSetting
