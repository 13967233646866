import {AnyAction, combineReducers} from 'redux'
import * as constants from './constants'

const isPresetsModalOpen = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.OPEN_PRESETS_MODAL:
      return true
    case constants.CLOSE_PRESETS_MODAL:
      return false
    default:
      return state
  }
}

const isRobotPositionModalOpen = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.OPEN_SET_POSITION_MODAL:
      return true
    case constants.CLOSE_SET_POSITION_MODAL:
      return false
    default:
      return state
  }
}

export default combineReducers({
  isPresetsModalOpen,
  isRobotPositionModalOpen,
})
