import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
// ts file is missing for 'react-swipeable-views-utils'
// tslint:disable-next-line:ban-ts-ignore
// @ts-ignore
import {bindKeyboard, virtualize} from 'react-swipeable-views-utils'
import SwipeableViews from 'react-swipeable-views'
import {TextButton} from './BasicElementsStyled'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const BindKeyboardSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

export const ManualTitle = styled.div`
  font-size: 1.17rem;
  font-weight: bold;
  text-align: center;
  max-width: calc(100% - 50px);
  margin: 4px auto 24px auto;
`

export const ManualSectionTitle = styled.div`
    margin-bottom: 16px;
    text-align: center;
    font-weight: bold;
`

export const ManualImg = styled.img`
    width: 100%;
    max-width: 600px;
    height: auto;
`

export const Actions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const getPageIndex = (index: number, allPagesCount: number): number => {
  const mod = index % allPagesCount
  return mod < 0 ? mod + allPagesCount : mod
}

interface ListContentItem {
  text: string
  image?: string
}

interface Page {
  title: string
  content: React.ReactNode | ListContentItem[]
  startingNumber?: number
}

interface Props {
  currentPage?: number
  pages: Page[]
  title?: string
}

const Page = ({
  title,
  content,
  startingNumber,
}: Page) => (
  <div>
    <ManualSectionTitle>{title}</ManualSectionTitle>
    {Array.isArray(content) ? (
      <ol start={startingNumber}>
        {(content as ListContentItem[]).map((contentItem: ListContentItem, index: number) => (
          <li key={index}>
            <div>{contentItem.text}</div>
            {contentItem.image && (
              <ManualImg
                src={contentItem.image}
              />
            )}
          </li>
        ))}
      </ol>
    ) : content}
  </div>
)

const Manual = ({
  currentPage,
  pages,
  title,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [slideIndex, setSlideIndex] = useState(currentPage || 0)
  const pagesSize = pages.length

  useEffect(() => {
    if (currentPage) {
      setSlideIndex(currentPage)
    }
  }, [currentPage])

  return (
    <div>
      {title && <ManualTitle>{title}</ManualTitle>}
      <BindKeyboardSwipeableViews
        enableMouseEvents
        index={slideIndex}
        onChangeIndex={setSlideIndex}
        slideCount={pagesSize}
        slideRenderer={(params: {key: string, index: number}): JSX.Element => {
          const page = pages[getPageIndex(params.index, pagesSize)]
          return (
            <Page
              key={params.key}
              {...page}
            />
          )
        }}
      />
      {pagesSize > 1 && (
        <Actions>
          {slideIndex > 0 ? (
            <TextButton onClick={() => setSlideIndex(slideIndex - 1)}>
              {t('Common button previous')}
            </TextButton>
          ) : <span />}
          {slideIndex < (pagesSize - 1) && (
            <TextButton onClick={() => setSlideIndex(slideIndex + 1)}>
              {t('Common button next')}
            </TextButton>
          )}
        </Actions>
      )
      }
    </div>
  )
}

export default Manual
