import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FeatureToggle, TextButton} from 'components'
import {Robot, RobotState} from 'types'
import styled from 'styled-components'
import {ControlJoints} from 'components/robotControls'
import {PERMISSIONS} from '../constants'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const Content = styled.div`
  margin: 16px 0;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface Props {
  robot: Robot | null
}

const ManageJointsControl = ({
  robot,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => setIsModalOpen(false)

  return robot !== null ? (
    <FeatureToggle permissionName={PERMISSIONS.ROBOT.MOVE_JOINT}>
      <TextButton
        onClick={() => setIsModalOpen(true)}
        disabled={robot.healthStatus.robotState !== RobotState.MANUAL_CONTROL}
      >
        {t('Dashboard change joints')}
      </TextButton>
      <Dialog
        open={isModalOpen}
        onClose={() => {}}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DialogTitle onClose={closeModal}>
            {t('Dashboard change joints title')}
          </DialogTitle>
          <ControlJoints
            selectedRobot={robot}
            wrapper={Content}
          />
          <DialogActions $noHorizontalPadding>
            <Button onClick={closeModal}>
              {t('Common button close')}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </FeatureToggle>
  ) : null
}

export default ManageJointsControl
