import React, {useCallback, useState} from 'react'
import styled, {css, keyframes} from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import {Button, ButtonProps, Dialog} from 'components'
import {Locations, DiagnosticEvent} from '../types'
import {useModuleAwareTranslation, usePrevious} from 'utils'
import {blueButton, primary, white} from '../../../colors'
import MultipleEventsModalItem from './MultipleEventsModalItem'
import {getFromLocation, getLocationParts} from '../utils'
import {StatusMainContent} from '../NSPCommonStyled'
import {DialogActions} from '@material-ui/core'

interface Props {
  events: DiagnosticEvent[]
  locations: Locations
  selectedEventId: string
  robotId: string
  userId?: string
  close: () => void
  withCloseButton?: boolean
}

const CenteredActions = styled(DialogActions)`
  justify-content: center!important;
  margin-bottom: 6px;
  
  button {
    padding-left: 24px!important;
    padding-right: 24px!important;
  }
`

const ActionButton = styled(Button)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.25s linear;
    min-width: 32px;
    width: 36px;
    ${(props: ButtonProps) => props.disabled ? 'opacity: 0;' : ''}
`

const ActionButtonRight = styled(ActionButton)`
    right: 2px;
`

const ActionButtonLeft = styled(ActionButton)`
    left: 2px;
`

const Content = styled.div`
  padding: 0 0 12px 0!important;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-shrink: 0;
`

const slideBuilder = (initialTranslateX: string) => keyframes`
    0% {
      transform: translateX(${initialTranslateX});
    }
    100% {
       transform: translateX(-100%);
    }
`

const EventContent = styled(StatusMainContent)`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  max-width: 100vw;
  min-height: 250px;
  ${(props: {navigateTo: 'previous' | 'next' | 'none', hasArrows: boolean}) => {
    if (props.hasArrows) {
      return 'transform: translateX(-100%);'
    }
  }}

  ${(props: {navigateTo: 'previous' | 'next' | 'none', hasArrows: boolean}) => {
    if (!props.hasArrows) {
      return ''
    }

    if (props.navigateTo === 'previous') {
      return css`
            animation: ${css`${slideBuilder('-200%')} 0.35s linear`};
        `
    }

    if (props.navigateTo === 'next') {
      return css`
            animation: ${slideBuilder('0')} 0.35s linear;
        `
    }

    return ''
  }}
`

const EventContentElement = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 1;
    width: 100%;
`

const getEventIndex = (index: number, allEventsCount: number): number => {
  const mod = index % allEventsCount
  return mod < 0 ? mod + allEventsCount : mod
}

const MultipleEventsModal = ({
  events,
  selectedEventId,
  locations,
  close,
  robotId,
  withCloseButton,
  userId,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const initialIndex = events.findIndex(event => event.notificationUid === selectedEventId)
  const [slideIndex, setSlideIndex] = useState(initialIndex >= 0 ? initialIndex : 0)
  const previousSlideIndex = usePrevious(slideIndex)
  const getLocationAsString = (location?: string | string[]) =>
    getLocationParts(locations, location).join(';')

  const goToNext = useCallback(() => {
    setSlideIndex(slideIndex + 1)
  }, [slideIndex])

  const goToPrevious = useCallback(() => {
    setSlideIndex(slideIndex - 1)
  }, [slideIndex])

  const event = events[getEventIndex(slideIndex, events.length)]
  const previousEvent = events.length > 1 ? events[getEventIndex(slideIndex - 1, events.length)] : undefined
  const nextEvent = events.length > 1 ? events[getEventIndex(slideIndex + 1, events.length)] : undefined
  let navigateTo: 'previous' | 'next' | 'none' = 'none'
  if (previousSlideIndex !== undefined && previousSlideIndex !== slideIndex) {
    navigateTo = previousSlideIndex > slideIndex ? 'previous' : 'next'
  }

  const fromLocation = getFromLocation(event)
  const toLocation = event.toLocation === fromLocation ? undefined : event.toLocation

  return (
    <Dialog
      open
      onClose={close}
      fullWidth
      maxWidth="md"
      withCloseButton
    >
      <Content>
        <EventContent
          navigateTo={navigateTo}
          hasArrows={Boolean(previousEvent) || Boolean(nextEvent)}
          key={event.notificationUid}
        >
          {previousEvent && (
            <EventContentElement>
              <MultipleEventsModalItem
                locations={locations}
                event={previousEvent}
                eventLocationAsString={getLocationAsString(getFromLocation(previousEvent))}
                nextLocationAsString={getLocationAsString(previousEvent.toLocation)}
                robotId={robotId}
                userId={userId}
              />
            </EventContentElement>
          )}
          <EventContentElement>
            <MultipleEventsModalItem
              event={event}
              locations={locations}
              eventLocationAsString={getLocationAsString(fromLocation)}
              nextLocationAsString={getLocationAsString(toLocation)}
              robotId={robotId}
              userId={userId}
            />
          </EventContentElement>
          {nextEvent && (
            <EventContentElement>
              <MultipleEventsModalItem
                event={nextEvent}
                locations={locations}
                eventLocationAsString={getLocationAsString(getFromLocation(nextEvent))}
                nextLocationAsString={getLocationAsString(nextEvent.toLocation)}
                robotId={robotId}
                userId={userId}
              />
            </EventContentElement>
          )}
        </EventContent>
        <ActionButtonLeft
          $backgroundColor={blueButton}
          $textColor={white}
          onClick={goToPrevious}
          disabled={!previousEvent}
        >
          <ChevronLeftIcon />
        </ActionButtonLeft>
        <ActionButtonRight
          $backgroundColor={blueButton}
          $textColor={white}
          onClick={goToNext}
          disabled={!nextEvent}
        >
          <ChevronRightIcon />
        </ActionButtonRight>
      </Content>
      {withCloseButton && (
        <CenteredActions>
          <Button
            $backgroundColor={primary}
            $textColor={white}
            onClick={close}
          >
            {t('Common button close')}
          </Button>
        </CenteredActions>
      )}
    </Dialog>
  )
}

export default MultipleEventsModal
