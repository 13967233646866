import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Image,
  JointMoveControl,
  Loader,
  TextButton,
} from 'components'
import {LimitFindingStatus, Robot, Store} from 'types'
import {useDispatch, useSelector} from 'react-redux'
import {openGripperAutocalModal} from './actions'
import {blueButton, white} from '../colors'
import {hasLimitFindingFailed, httpPost, isLimitFindingInProgress, usePrevious} from '../utils'
import {addSnackbarMessage} from '../app'
import {useNormalTranslation} from 'utils'
import {selectors} from 'shared'

const Content = styled.div`
  margin: 16px 0;
`

const Info = styled.div`
  margin-bottom: 16px;
  white-space: pre-wrap;
`

const Warning = styled.div`
  margin-bottom: 16px;
  font-weight: bolder;
`

const InProgressMessage = styled.div`
  margin: 32px 0;
  text-align: center;
`

const Grippers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  justify-content: center;
`

const GripperContent = styled.div`
  margin: 0 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const GripperName = styled.div`
  font-weight: bolder;
  text-transform: uppercase;
`

const GripperImage = styled(Image)`
  max-width: 80%;
  margin-bottom: 12px;
`

const GripperButtons = styled(JointMoveControl || 'div')`
  flex-direction: row-reverse;
  .MuiButton-root {
    box-shadow: none!important;
    
    &:first-child {
      margin-left: 16px!important;
    }
    
    &:last-child {
      margin-left: 0!important;
    }
  }
`

const RIGHT_GRIPPER_NAME = 'right_arm/gripper_tf'
const LEFT_GRIPPER_NAME = 'left_arm/gripper_tf'

const Gripper = ({isLeft}: {isLeft?: boolean}) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const selectedRobot = useSelector<Store, Robot | null>(state => state.app.selectedRobot) as Robot
  const gripperName = isLeft ? LEFT_GRIPPER_NAME : RIGHT_GRIPPER_NAME
  const image = isLeft ? 'calibration/single-gripper-left.png' : 'calibration/single-gripper-right.png'
  const text = isLeft ? 'PowerOn_GripperAutocal gripper left label' : 'PowerOn_GripperAutocal gripper right label'
  return (
    <GripperContent>
      <GripperName>{t(text)}</GripperName>
      <GripperImage src={image} />
      <GripperButtons
        joint={gripperName}
        robot={selectedRobot}
        skipTextDisplay
      />
    </GripperContent>
  )
}

const GripperAutocalModal = () => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [currentTimeout, setCurrentTimeout] = useState<number | undefined>()
  const [isRequestInProgress, setIsRequestInProgress] = useState(false)
  const [isProcessTriggered, setIsProcessTriggered] = useState(false)
  const dispatch = useDispatch()
  const isStatsModalOpen = useSelector<Store, boolean>(state => state.dashboard.isGripperAutocalModalOpen)
  const robotId = useSelector<Store, string | undefined>(state => state.app.selectedRobot?.id)
  const siteId = useSelector<Store, string | undefined>(state => state.app.selectedRobot?.siteId)
  const joints = useSelector<Store, string[] | undefined>(state => state.app.selectedRobot?.joints)
  const lfStatus = useSelector<Store, LimitFindingStatus| undefined>(state => state.app.selectedRobot?.healthStatus.limitFindingStatus)
  const previousLfStatus = usePrevious(lfStatus)
  const hasLeftGripper = joints?.includes(LEFT_GRIPPER_NAME)
  const hasRightGripper = joints?.includes(RIGHT_GRIPPER_NAME)
  const isOpen = Boolean(isStatsModalOpen && robotId && siteId)

  const isInProgress = isRequestInProgress || isLimitFindingInProgress(lfStatus)
  const onClose = useCallback(() => {
    if (!isInProgress) {
      dispatch(openGripperAutocalModal(true))
    }
  }, [isInProgress, dispatch])

  useEffect(() => {
    const isFinished = isProcessTriggered && isLimitFindingInProgress(previousLfStatus) && !isLimitFindingInProgress(lfStatus)
    if (isFinished) {
      setIsProcessTriggered(false)
      dispatch(openGripperAutocalModal(true))
      if (hasLimitFindingFailed(lfStatus)) {
        dispatch(addSnackbarMessage('PowerOn_GripperAutocal failed'))
      } else {
        dispatch(addSnackbarMessage('PowerOn_GripperAutocal success'))
      }
    }
  }, [isProcessTriggered, lfStatus, previousLfStatus, dispatch])

  useEffect(() => {
    return () => {
      clearTimeout(currentTimeout)
    }
  }, [currentTimeout])

  const makeRequest = async (endpoint: string, showMessage = false) => {
    let hasError = false
    if (siteId && robotId) {
      try {
        setIsRequestInProgress(true)
        setIsProcessTriggered(true)
        const response: Response = await httpPost(`${siteId}/robot/${robotId}/${endpoint}`)
        const {status} = response
        if (status >= 400) {
          hasError = true
        }
      } catch (error) {
        hasError = true
      }
    }

    if (hasError && showMessage) {
      dispatch(addSnackbarMessage('PowerOn_GripperAutocal failed'))
    }

    if (hasError) {
      setIsProcessTriggered(false)
    }

    if (currentTimeout) {
      clearTimeout(currentTimeout)
    }
    setCurrentTimeout(setTimeout(() => setIsRequestInProgress(false), hasError ? 0 : 3000))
  }

  const startGripperAutocal = async () => {
    await makeRequest('runGripperAutocal', true)
  }

  const cancel = async () => {
    setIsProcessTriggered(false)
    await makeRequest('cancelLimitFinding')
  }

  return isOpen ? (
    <Dialog
      onClose={onClose}
      open
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <DialogTitle>
          {t('PowerOn_GripperAutocal title')}
        </DialogTitle>
        <Content>
          {isInProgress ? (
            <>
              <Loader
                centered
                withTopMargin
              />
              <InProgressMessage>{t('PowerOn_GripperAutocal in progress')}</InProgressMessage>
            </>
          ) : (
            <>
              <Info>{t('PowerOn_GripperAutocal message')}</Info>
              <Grippers>
                {hasLeftGripper && <Gripper isLeft />}
                {hasRightGripper && <Gripper />}
              </Grippers>
              <Warning>{t('PowerOn_GripperAutocal warning')}</Warning>
            </>
          )}
        </Content>
      </DialogContent>
      <DialogActions $centered={isInProgress}>
        {isInProgress ? (
          <Button
            onClick={cancel}
            disabled={isRequestInProgress}
            $backgroundColor={blueButton}
            $textColor={white}
          >
            {t('Common button cancel')}
          </Button>
        ) : (
          <>
            <TextButton
              disabled={isRequestInProgress}
              onClick={onClose}
            >
              {t('Common button cancel')}
            </TextButton>
            <Button
              onClick={startGripperAutocal}
              disabled={isRequestInProgress}
              $backgroundColor={blueButton}
              $textColor={white}
            >
              {t('PowerOn_GripperAutocal start button')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  ) : null
}

export default GripperAutocalModal
