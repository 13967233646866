import React, {useCallback} from 'react'
import styled from 'styled-components'
import PublishIcon from '@material-ui/icons/Publish'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {Grid} from 'components'
import {useModuleAwareTranslation} from 'utils'
import {error, lightText, primary} from '../../colors'
import Fab from '@material-ui/core/Fab'
import InputLabel from '@material-ui/core/InputLabel'

const SelectFile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #e9e9e9;
  color: ${primary};
  cursor: pointer;
  width: 100%;
  min-height: 200px;
  text-align: center;
  padding: 16px;
  position: relative;
`

const SelectFileText = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 12px;
`

const SelectFileInput = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  opacity: 0;
  z-index: 10;
`

const File = styled.div`
  position: relative;
  background: #e9e9e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  
  img, video {
    max-width: 100%;
  }
`

const FileName = styled.div`
  font-weight: 500;
  text-align: center;
  margin: 12px;
  word-break: break-all;
  max-width: 100%;
`

const StyledFab = styled(Fab)`
  position: absolute!important;
  top: -7px;
  right: -6px;
`

const StyledInputLabel = styled(InputLabel)`&&& {
  color: ${(props: {error?: boolean}) => props.error ? error : lightText};
  font-weight: 500;
  margin-bottom: 12px;
}`

interface Props {
  form: string
  files?: File[]
  setBugReportFiles: (form: string, files: File[]) => void
}

const BugReportFiles = ({
  form,
  files,
  setBugReportFiles,
}: Props) => {
  const {t} = useModuleAwareTranslation()

  const onFileInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files
    const newFiles = [...(files || []), ...uploadedFiles]
    setBugReportFiles(form, newFiles)
  }, [files, setBugReportFiles, form])

  const removeFile = useCallback((fileToRemove: File) => {
    const newFiles = (files || []).filter(file => file !== fileToRemove)
    setBugReportFiles(form, newFiles)
  }, [files, setBugReportFiles, form])

  return (
    <div>
      <StyledInputLabel>
        {t('Robot configuration bug report files')}
      </StyledInputLabel>
      <Grid gap={10}>
        {(files || []).map(file => (
          <File key={`${file.lastModified}_${file.name}`}>
            {file.type.includes('image') && (
              <img src={URL.createObjectURL(file)} alt={file.name} />
            )}
            {file.type.includes('video') && (
              <video>
                <source src={URL.createObjectURL(file)} />
              </video>
            )}
            {!file.type.includes('image') && !file.type.includes('video') && (
              <FileName>
                {file.name}
              </FileName>
            )}
            <StyledFab
              color="primary"
              size="small"
              onClick={() => removeFile(file)}
            >
              <DeleteOutlineIcon />
            </StyledFab>
          </File>
        ))}
        {(!files || files.length < 10) && (
          <SelectFile>
            <PublishIcon fontSize="large" />
            <SelectFileText>
              {t('Robot configuration bug report select file')}
            </SelectFileText>
            <SelectFileInput
              type="file"
              onChange={onFileInputChange}
              multiple
            />
          </SelectFile>
        )}
      </Grid>
    </div>
  )
}

export default BugReportFiles
