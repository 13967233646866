import {reducer as auth} from '../auth'
import {reducer as robotOperations} from '../robotOperations'
import {reducer as app} from '../app'
import {reducer as dashboard} from '../dashboard'
import {reducer as limitFindingWizard} from '../limitFindingWizard'

const reducers = {
  app,
  dashboard,
  auth,
  limitFindingWizard,
  robotOperations,
}

export {
  reducers,
}
