import React, {SyntheticEvent} from 'react'
import {useModuleAwareTranslation} from 'utils'
import {Slider} from 'components'
import {SanitizationLevel} from '../types'
import {SliderWrapper, SliderWrapperLabel} from './EditObjectGroupModalComponents'

interface Props {
  isInsideHeader?: boolean
  disabled?: boolean
  level: SanitizationLevel
  setLevel: (level: SanitizationLevel) => void
}

const ORDERED_LEVELS = [
  SanitizationLevel.LOW,
  SanitizationLevel.MEDIUM,
  SanitizationLevel.HIGH,
]

const EditUVCLevelSlider = ({
  setLevel,
  level,
  disabled,
  isInsideHeader,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  return (
    <SliderWrapper
      isInsideHeader={isInsideHeader}
      onClick={(event: SyntheticEvent) => {
        event.preventDefault()
        event.stopPropagation()
      }}
    >
      <SliderWrapperLabel>{t('uv_SanitizingObjects general uvc label')}:</SliderWrapperLabel>
      <Slider
        disabled={disabled}
        defaultValue={0}
        marks={ORDERED_LEVELS.map((level, index) => ({
          value: index,
          label: t(`uv_SanitizingObjects general ${level} uvc short`),
        }))}
        onChange={index => setLevel(ORDERED_LEVELS[index])}
        step={null}
        value={ORDERED_LEVELS.indexOf(level)}
      />
    </SliderWrapper>
  )
}

export default EditUVCLevelSlider
