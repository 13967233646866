import {
  all,
  takeLatest,
  takeEvery,
  put,
  delay,
} from 'redux-saga/effects'
import {AnyAction} from 'redux'
import {addSnackbarMessage} from 'app'
import {i18n, getLanguageCodes} from 'locale'
import {httpGet, getResponseBody, httpPost, getSelectedSiteAndRobotId} from 'utils'
import {
  fetchLocationsConfigurationSuccess,
  fetchLocationsFinished,
  locationEditEnd,
} from './actions'
import * as constants from './constants'
import {sagas as notificationSagas} from './notifications'
import {FETCH_SITE_SETTINGS} from '../../../constants'

function* fetchLocations() {
  yield takeLatest<AnyAction>([FETCH_SITE_SETTINGS, constants.FETCH_LOCATIONS], function* () {
    let {siteId} = yield getSelectedSiteAndRobotId()
    while (!siteId) {
      yield delay(150)
      siteId = (yield getSelectedSiteAndRobotId()).siteId
    }
    const response: Response = yield httpGet(`v1/nsp/locations/${siteId}`)
    const {status} = response
    if (status < 300) {
      const {configuration, ...locations} = yield getResponseBody(response)
      yield put(fetchLocationsFinished(locations))
      yield put(fetchLocationsConfigurationSuccess(configuration || {}))
    } else {
      yield put(fetchLocationsFinished({}))
    }
  })
}

function* saveLocationName() {
  yield takeEvery<AnyAction>(constants.LOCATION_EDIT_CONFIRM, function* (action) {
    const {siteId} = yield getSelectedSiteAndRobotId()
    const {
      locationId,
      translation,
    } = action

    const languageCode = getLanguageCodes(i18n.language)[0]
    const changeObject = {
      [languageCode]: translation,
    }
    const response: Response = yield httpPost(`v1/nsp/locations/${siteId}/?locationId=${locationId}`, changeObject)
    const {status} = response
    if (status < 300) {
      yield put(locationEditEnd(locationId, translation, languageCode))
      yield put(addSnackbarMessage('Nav_SiteConfiguration content site configuration location name edit update success'))
    } else {
      yield put(locationEditEnd())
      yield put(addSnackbarMessage('Nav_SiteConfiguration content site configuration location name edit update error'))
    }
  })
}

function* siteSettingsSagas() {
  yield all([
    fetchLocations(),
    saveLocationName(),
    notificationSagas(),
  ])
}
export default siteSettingsSagas
