import styled from 'styled-components'
import {
  DialogActions,
  Grid,
  TextButton,
} from 'components'
import {grayText, lightGray} from '../../../colors'

export const UVCLevelRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${lightGray};
  margin: 6px -24px;
  font-weight: 500;
  padding: 0 4px 0 24px;
  min-height: 38px;
`

export const Subtitle = styled.div`
  text-align: center;
  margin: 8px 0;
  font-weight: 500;
`

export const StyledGrid = styled(Grid)`
  margin: 0 -8px;
`

export const SliderWrapperLabel = styled.div`
  font-weight: bolder;
  margin-right: 20px;
  margin-top: -21px;
`

export const SliderWrapper = styled.div`
  margin: 8px 0 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  ${(props: {isInsideHeader?: boolean}) => {
    if (props.isInsideHeader) {
      return `
        margin: 0 12px 12px auto;
        position: relative;
        top: 16px;
        min-width: 225px;
        width: 300px;
        max-width: calc(100vw - 210px);
      `
    }
  }}
  
  .MuiSlider-markLabel[style*='0%'] {
    transform: none!important;
  }
  
  .MuiSlider-markLabel[style*='50%'] {
    transform: translateX(-50%)!important;
  }
  
  .MuiSlider-markLabel[style*='100%'] {
    transform: none!important;
    left: auto!important;
    right: 0;
  }
`

export const StyledActions = styled(DialogActions)`
  padding: 8px 0!important;
`

export const MoreItemsText = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 1.25rem;
  text-align: center;
  line-height: 1.125rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
`

export const MoreItemsSecondaryText = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  color: ${grayText};
  text-align: center;
`

export const MoreItemsButton = styled(TextButton)`&&&{
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: ${(props: {showAll: boolean}) => props.showAll ? 'center' : 'flex-start'};
  padding: 0;
  
  .MuiButton-label {
    text-align: center;
    display: block;
  }
}`
