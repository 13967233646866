import React, {useState, useEffect, useCallback} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import {useModuleAwareTranslation} from 'utils'
import {List, ListHeader, Loader, DragAndDropList} from 'components'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {DropResult} from 'react-beautiful-dnd'
import {ScheduleListElement} from './types'
import ScheduleListItem from './ScheduleListItem'
import ScheduleTimePickerModal from './ScheduleTimePickerModal'
import {ListEmptyText} from '../NSPCommonStyled'
import ScheduleSortingInfoModal from './ScheduleSortingInfoModal'
import {getHoursAndMinutesFromScheduleExpression, getNewScheduleSorting} from './utils'

const InfoIcon = styled(InfoOutlinedIcon)`
  margin-left: 8px;
  cursor: pointer;
`

export interface Props {
  canEdit: boolean
  hasMultipleConfigurations: boolean
  hasDifferentScenarios: boolean
  isLoading: boolean
  userId?: string
  items: ScheduleListElement[]
  robotId?: string
  canAccessSchedule: boolean
  getItems: () => void
  scheduleOrderUpdate: (newOrder: Array<{id: string, newIndex: number}>) => void
  scheduleItemUpdate: (scheduleItemId: string, enabled: boolean,
                       startDate?: Date, cronExpression?: string, rruleExpression?: string, weeklyRepetitions?: number) => void
}

const ScheduleList = ({
  canEdit,
  hasMultipleConfigurations,
  isLoading,
  getItems,
  items,
  scheduleItemUpdate,
  robotId,
  userId,
  hasDifferentScenarios,
  scheduleOrderUpdate,
  canAccessSchedule,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const {push} = useHistory()
  const [selectedItemId, setSelectedItemId] = useState()
  const [showSortingModal, setShowSortingModal] = useState(false)
  const {pathname} = useLocation()

  useEffect(() => {
    getItems()
  }, [robotId])

  useEffect(() => {
    if (!canAccessSchedule) {
      if (pathname.includes('patrol/schedule')) {
        push(`/patrol`)
      }
    }
  }, [pathname])

  const selectItem = useCallback((item: ScheduleListElement) => {
    if (!hasMultipleConfigurations) {
      setSelectedItemId(item.id)
    } else {
      push(`/patrol/schedule/${item.id}`)
    }
  }, [hasMultipleConfigurations, push, items])

  if (isLoading || !robotId) {
    return <Loader centered withTopMargin />
  }

  if (items.length === 0) {
    return <ListEmptyText>{t('Nav_Schedule content patrol schedule list empty')}</ListEmptyText>
  }

  const selectedItem = selectedItemId ?
    items.find(item => item.id === selectedItemId) :
    undefined

  const onPickerDataChange = selectedItem ?
    (date: Date, enabled: boolean, cronExpression: string, rruleExpression: string, weeklyRepetitions: number) =>
      scheduleItemUpdate(selectedItem.id, enabled, date, cronExpression, rruleExpression, weeklyRepetitions) :
    () => {}

  const isChangeDisabled = !canEdit

  const sortedItems = items
    .sort((itemA, itemB) => {
      if (typeof itemA.uiOrder === 'number' && typeof itemB.uiOrder === 'number') {
        return itemA.uiOrder - itemB.uiOrder
      }

      const expr = itemA.task.rruleExpression ? itemA.task.rruleExpression : itemA.task.cronExpression
      const itemATime = getHoursAndMinutesFromScheduleExpression(expr)
      const itemBTime = getHoursAndMinutesFromScheduleExpression(expr)
      const hoursDiff = itemATime.hours - itemBTime.hours
      if (hoursDiff !== 0) {
        return hoursDiff
      }

      return itemATime.minutes - itemBTime.minutes
    })

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const startIndex = result.source.index
    const endIndex = result.destination.index
    const newOrder = getNewScheduleSorting(sortedItems, startIndex, endIndex)

    scheduleOrderUpdate(newOrder)
  }

  const dragAndDropItems = sortedItems
    .map((scheduleItem: ScheduleListElement, index: number) => ({
      id: scheduleItem.id,
      component: (
        <ScheduleListItem
          hasDifferentScenarios={hasDifferentScenarios}
          scheduleItem={scheduleItem}
          disabled={isChangeDisabled}
          isSelected={selectedItemId === scheduleItem.id}
          userId={userId}
          selectItem={() => isChangeDisabled ? undefined : selectItem(scheduleItem)}
          onChangeEnabled={() => scheduleItemUpdate(
            scheduleItem.id,
            !scheduleItem.enabled
          )}
        />
      ),
    }))

  return (
    <List>
      <ListHeader $centered>
        {t('Nav_Schedule subtitle schedule')} {items.length > 1 && (
          <InfoIcon onClick={() => setShowSortingModal(true)} />
        )}
      </ListHeader>
      <DragAndDropList
        onDragEnd={onDragEnd}
        droppableId="schedules"
        items={dragAndDropItems}
      />
      {Boolean(selectedItem) && !isChangeDisabled && (
        <ScheduleTimePickerModal
          cancel={() => setSelectedItemId(undefined)}
          initialDate={selectedItem?.task.startDate}
          initialEnabled={selectedItem?.enabled}
          initialCronExpression={selectedItem?.task.cronExpression}
          initialRRuleExpression={selectedItem?.task.rruleExpression}
          initialWeeklyRepetitions={selectedItem?.task.weeklyRepetitions}
          isOpen
          save={(date: Date, enabled: boolean, cronExpression: string, rruleExpression: string, weeklyRepetitions: number) => {
            onPickerDataChange(date, enabled, cronExpression, rruleExpression, weeklyRepetitions)
            setSelectedItemId(undefined)
          }}
        />
      )}
      <ScheduleSortingInfoModal
        isOpen={showSortingModal}
        close={() => setShowSortingModal(false)}
      />
    </List>
  )
}

export default ScheduleList
