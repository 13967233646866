import {serverUrl} from 'config'
import {Storage} from 'platform'
import {ROUTES, TOKEN_REFRESH_INTERVAL} from '../constants'

const TOKEN_STORAGE_KEY = 'jwt_token'
const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token'
const REFRESH_TOKEN_PATH = 'auth/refresh'
const LOGIN_PATH = 'auth/login'

let requestId = 1

export const networkErrors: Array<{date: string, path: string}> = []

function getOptionsCreator(verb: string, data?: {}) {
  return async () => {
    const token = await Storage.getItem(TOKEN_STORAGE_KEY)
    const options = {
      credentials: 'include',
      dataType: 'json',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: verb,
    } as {[field: string]: string | {}}
    if (data) {
      options.body = JSON.stringify(data)
    }

    return options
  }
}

function getMultipartFormDataOptions(verb: string, formData: FormData) {
  return async () => {
    const token = await Storage.getItem(TOKEN_STORAGE_KEY)
    const options = {
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      method: verb,
      body: formData,
    } as {[field: string]: string | {}}

    return options
  }
}

export function getPath(endpoint: string) {
  return `${serverUrl}/api/${endpoint}`
}

// tslint:disable-next-line:no-any
export async function getResponseBody(response: Response): Promise<any> {
  try {
    const body = await response.json()
    return body
  } catch (error) {
    return {}
  }
}

interface RequestItem {
  abort: () => void
  run: () => void
  reject: () => void
}

const requestSettings = {
  refreshTokenIntervalId: -1,
  refreshTokenCheckFailed: false,
  refreshTokenCheckInProgress: false,
  pendingRequests: {} as {[requestId: number]: RequestItem},
}

async function handleTokens(path: string, options: RequestInit, response: Response) {
  if (path.endsWith(LOGIN_PATH) && options.method === 'POST') {
    const responseBody = await getResponseBody(response)
    await Promise.all([
      Storage.setItem(TOKEN_STORAGE_KEY, responseBody.token),
      Storage.setItem(REFRESH_TOKEN_STORAGE_KEY, responseBody.refreshToken || ''),
    ])
  }

  if (path.endsWith(REFRESH_TOKEN_PATH) && options.method === 'POST') {
    const responseBody = await getResponseBody(response)
    await Storage.setItem(TOKEN_STORAGE_KEY, responseBody.token)
  }

  if (requestSettings.refreshTokenIntervalId === -1) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    requestSettings.refreshTokenIntervalId = window.setInterval(async () => {
      await checkRefreshToken()
    }, TOKEN_REFRESH_INTERVAL)
  }
}

async function checkRefreshToken(afterTokenExpired?: boolean) {
  if (afterTokenExpired) {
    // abort all pending requests
    Object.values(requestSettings.pendingRequests).forEach(request => request.abort())
  }

  if (requestSettings.refreshTokenCheckInProgress || requestSettings.refreshTokenCheckFailed) {
    return
  }

  requestSettings.refreshTokenCheckInProgress = afterTokenExpired === true || requestSettings.refreshTokenCheckInProgress

  const refreshToken = await Storage.getItem(REFRESH_TOKEN_STORAGE_KEY)
  if (!refreshToken) {
    if (afterTokenExpired && window.location.pathname !== ROUTES.LOGIN) {
      window.location.assign(`${ROUTES.LOGIN}?redirectTo=${encodeURIComponent(window.location.href)}`)
    }

    return
  }

  let refreshResponse: Response | undefined
  try {
    refreshResponse = await httpPost(REFRESH_TOKEN_PATH, {refreshToken})
  } catch (error) {
    // network error happened!
    networkErrors.push({
      date: new Date().toISOString(),
      path: REFRESH_TOKEN_PATH,
    })
  }

  if (requestSettings.refreshTokenCheckInProgress) {
    requestSettings.refreshTokenCheckInProgress = false
  }

  if (!refreshResponse) {
    // try to requests in case of network errors, they will be added to the queue again
    Object.values(requestSettings.pendingRequests).forEach(request => request.run())
    return
  }

  requestSettings.refreshTokenCheckFailed = refreshResponse.status >= 400

  if (refreshResponse.status >= 400) {
    Object.values(requestSettings.pendingRequests).forEach(request => request.reject())
    if (window.location.pathname !== ROUTES.LOGIN) {
      window.location.assign(`${ROUTES.LOGIN}?redirectTo=${encodeURIComponent(window.location.href)}`)
    }
  } else {
    Object.values(requestSettings.pendingRequests).forEach(request => request.run())
  }
}

async function initiateRequest(path: string, optionsCreator: () => Promise<RequestInit>, customAbortController?: AbortController) {
  const isAuthRequest = path.endsWith(REFRESH_TOKEN_PATH) || path.endsWith(LOGIN_PATH)
  return new Promise<Response>((resolve, reject) => {
    let abortController: AbortController | undefined

    const id = requestId++
    let retryNetworkRequestsCountLeft = 5
    const run = async () => {
      if (customAbortController && customAbortController.signal.aborted) {
        // @ts-ignore
        return resolve({
          status: 501,
          json: () => Promise.resolve({error: 'aborted'}),
        })
      }

      try {
        abortController = customAbortController || new AbortController()
        const requestOptions = await optionsCreator()
        const response = await fetch(path, {
          ...requestOptions,
          signal: abortController.signal,
        })

        if (isAuthRequest || response.status !== 401) {
          delete requestSettings.pendingRequests[id]
          resolve(response)
        } else if (response.status === 401 && !path.endsWith(REFRESH_TOKEN_PATH)) {
          checkRefreshToken(true)
        }
      } catch (error) {
        if (error && error.name === 'AbortError') {
          // that's fine, we aborted error and it will be retriggered
        } else if (path.endsWith('events/init')) {
          // server error or server not available
          reject(error)
          delete requestSettings.pendingRequests[id]
        } else if (path.endsWith(REFRESH_TOKEN_PATH)) {
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          setTimeout(run, 2000)
        } else {
          // there was a network error (internet is available), let's try to retry the request
          if (retryNetworkRequestsCountLeft > 0) {
            retryNetworkRequestsCountLeft--
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(run, 1000)
          } else {
            // network error happened!
            networkErrors.push({
              date: new Date().toISOString(),
              path,
            })
            if (networkErrors.length > 50) {
              // remove 20 oldest items
              networkErrors.splice(0, 20)
            }
            // no more network retries left, abort
            // @ts-ignore
            resolve({
              status: 501,
              json: () => Promise.resolve({error}),
            })
          }
        }
      }
    }

    const abort = () => {
      if (abortController) {
        abortController.abort()
      }
    }

    // do not add auth path to queue
    if (!isAuthRequest) {
      requestSettings.pendingRequests[id] = {
        run,
        abort,
        reject,
      }
    }

    if (!requestSettings.refreshTokenCheckInProgress || isAuthRequest) {
      run()
    }
  })
}

async function makeRequest(path: string, optionsCreator: () => Promise<RequestInit>, abortController?: AbortController) {
  const response = await initiateRequest(path, optionsCreator, abortController)

  if (response.status < 400) {
    // clone response so we can read from it again
    const clonedResponse = response.clone()
    const options = await optionsCreator()
    await handleTokens(path, options, clonedResponse)
  }

  return response
}

export function httpGet(endpoint: string, rawRequest?: boolean, abortController?: AbortController) {
  if (rawRequest) {
    return getOptionsCreator('GET')()
      .then(requestOptions => fetch(getPath(endpoint), requestOptions))
  }

  return makeRequest(getPath(endpoint), getOptionsCreator('GET'), abortController)
}

export function httpGetImage(path: string, abortController?: AbortController) {
  return makeRequest(path, getOptionsCreator('GET'), abortController)
}

export function httpPost(endpoint: string, data?: {}) {
  return makeRequest(getPath(endpoint), getOptionsCreator('POST', data))
}

export function httpPut(endpoint: string, data?: {}) {
  return makeRequest(getPath(endpoint), getOptionsCreator('PUT', data))
}

export function httpDelete(endpoint: string) {
  return makeRequest(getPath(endpoint), getOptionsCreator('DELETE'))
}

export function multiformPartData(endpoint: string, formData: FormData) {
  return makeRequest(getPath(endpoint), getMultipartFormDataOptions('POST', formData))
}

export async function logInfoToServer(message: string) {
  try {
    const requestOptions = await getOptionsCreator('POST', {message})()
    await fetch(getPath(`events/log`), requestOptions)
  } catch (error) {
    // nothing
  }
}
