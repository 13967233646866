import {connect} from 'react-redux'
import {Store} from 'types'
import {selectors as authSelectors} from 'auth'
import {isPatrolOngoing} from 'modules/navigation/selectors'
import {clearPatrolList} from 'modules/navigation/report/actions'
import {setHistoryType, fetchUvPatrols} from 'modules/uv/report/actions'
import SanitizeOperationList from './SanitizeOperationList'

const mapStateToProps = (state: Store) => {
  return {
    robotId: state.app.selectedRobot?.id,
    locations: state.nsp.siteSettings.locations,
    hasMoreReports: state.nsp.report.hasMoreReports,
    historyType: state.uv.report.historyType,
    reports: state.nsp.report.finishedReports,
    showOngoingReportLink: isPatrolOngoing(state),
    userId: authSelectors.getUser(state)?.userId,
  }
}

export default connect(
  mapStateToProps,
  {
    clearPatrolList,
    fetchUvPatrols,
    setHistoryType,
  }
)(SanitizeOperationList)
