import {createSelector} from 'reselect'
import {Store} from 'types'
import {i18n, getLanguageCodes} from 'locale'
import {processForBestLanguage} from 'utils'
import {NotificationSettingListItemConfig} from './types'

const settingsItemSorter = (itemA: NotificationSettingListItemConfig, itemB: NotificationSettingListItemConfig): number => {
  return itemA.translation.localeCompare(itemB.translation)
}

export const getNotificationsConfig = createSelector(
  (state: Store) => state.nsp.siteSettings.notifications.notificationsEnabled,
  (state: Store) => state.nsp.siteSettings.notifications.notificationSettings,
  (state: Store) => state.nsp.siteSettings.locations,
  (state: Store) => state.nsp.siteSettings.configuration,
  (notificationsEnabled, notificationSettings, locations, configuration) => {
    const languageCodes = getLanguageCodes(i18n.language)
    const groupingLevels = typeof configuration.pushNotifications?.groupingLevels === 'number' ?
      configuration.pushNotifications?.groupingLevels :
      undefined

    const getChildrenForLocation = (locationLevel: number, locationId?: string): NotificationSettingListItemConfig[] => {
      if (groupingLevels && locationLevel > groupingLevels) {
        return []
      }

      return Object.entries(locations)
        .filter(([_, location]) => locationId ? location.parentId === locationId : !location.parentId)
        .map(([locationId, location]) => {
          let enabled = true
          if (!notificationsEnabled) {
            enabled = false
          } else if (typeof notificationSettings[locationId] !== 'undefined') {
            enabled = notificationSettings[locationId] as boolean
          }
          return {
            id: locationId,
            translation: processForBestLanguage(languageCodes, language => location[language]) as string,
            enabled,
            children: getChildrenForLocation(locationLevel + 1, locationId)
              .sort(settingsItemSorter),
          }
        })
    }

    return getChildrenForLocation(1)
      .sort(settingsItemSorter)
  }
)
