import React from 'react'
import {connect} from 'react-redux'
import {setManualControl, setSoundPreferences} from 'robotOperations'
import {Robot, SoundPreferences, Store} from 'types'
import {selectors as authSelectors} from 'auth'
import RobotStatusCard from './RobotStatusCard'

interface OwnProps {
  robot: Robot | null
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => ({
  isChangingManualMode: ownProps.robot ?
    state.robotOperations.runningManualControlOperations[ownProps.robot.id] :
    false,
  isChangingSound: state.robotOperations.isChangingSoundPreferences,
  userId: authSelectors.getUser(state)?.userId,
  isMuted: (state.app.selectedRobot?.diagnosticData?.soundPreferences as SoundPreferences | undefined)?.isMuted,
})

const mapDispatchToProps = {
  setManualControl,
  setSoundPreferences,
}

export default connect(mapStateToProps, mapDispatchToProps)(RobotStatusCard) as React.ComponentType<OwnProps>
