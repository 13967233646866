import React from 'react'
import {useHistory} from 'react-router-dom'
import {ActionBottomBar} from 'components'
import {useModuleAwareTranslation} from 'utils'
import {Steps} from './types'

interface Props {
  step: Steps
  disabled: boolean
  saveEditedItem: (onSuccess: Function) => void
  setStep: (step: Steps) => void
}

const ScheduleEditPageNavBar = ({
  step,
  setStep,
  disabled,
  saveEditedItem,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const history = useHistory()
  const goBack = () => history.goBack()
  return (
    <ActionBottomBar
      cancel={{
        text: t(step === Steps.CONFIGURATION ? 'Common button previous' : 'Common button cancel'),
        action: step === Steps.CONFIGURATION ? () => setStep(Steps.TIME) : goBack,
      }}
      confirm={{
        text: t(step === Steps.CONFIGURATION ? 'Common button save' : 'Common button next'),
        action: step === Steps.CONFIGURATION ? () => saveEditedItem(goBack) : () => setStep(Steps.CONFIGURATION),
        disabled,
      }}
    />
  )
}

export default ScheduleEditPageNavBar
