const ACTION_PREFIX = 'navigation/reminders/'

export const SAVE_REMINDER_SETTINGS = `${ACTION_PREFIX}SAVE_REMINDER`
export const SAVE_REMINDER_SETTINGS_DONE = `${ACTION_PREFIX}SAVE_REMINDER_DONE`

export const SHOW_PATROL_REMINDER = `${ACTION_PREFIX}SHOW_PATROL_REMINDER`
export const CLOSE_PATROL_REMINDER = `${ACTION_PREFIX}CLOSE_PATROL_REMINDER`

export const MARK_REMINDER_AS_VIEWED = `${ACTION_PREFIX}MARK_REMINDER_AS_VIEWED`
export const PATROL_REMINDER_VIEWED = `${ACTION_PREFIX}PATROL_REMINDER_VIEWED`
