import {connect} from 'react-redux'
import {Store} from 'types'
import EventNote from './EventNote'
import {saveEventNote} from '../actions'

const mapStateToProps = (state: Store) => ({
  saveSuccessful: state.nsp.eventSaveSuccess,
  isSaving: state.nsp.isSavingEventNote,
})

export default connect(mapStateToProps, {saveEventNote})(EventNote)
