import React, {useCallback, useEffect, useState} from 'react'
import {useModuleAwareTranslation} from 'utils'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FlatGridItemImage,
  FlatGridItemText,
  FlatItem,
  GridHeader,
  TextButton,
} from 'components'
import {getLocationTranslation} from 'modules/navigation/utils'
import {Locations} from 'modules/navigation/types'
import {SanitizationLevel, SanitizedObject} from '../types'
import {
  MoreItemsButton,
  MoreItemsSecondaryText,
  MoreItemsText,
  StyledActions,
  StyledGrid,
  Subtitle,
} from './EditObjectGroupModalComponents'
import {ObjectGroup, ObjectGroupType} from './types'
import EditUVCLeveLSlider from './EditUVCLeveLSlider'
import {serverUrl} from '../../../config'

interface Props {
  disabled: boolean
  close: () => void
  locations: Locations
  objectGroup?: ObjectGroup
  objects: SanitizedObject[]
  editedObjects: SanitizedObject[]
  groupType: ObjectGroupType
  setLevelByObjectType: (objectType: string, level: SanitizationLevel) => void
  editObjects: (objects: SanitizedObject[]) => void
  saveObjects: (onSuccess?: Function) => void
  clearEditedObjects: () => void
}

const EditObjectGroupModal = ({
  disabled,
  objectGroup,
  close,
  objects,
  editedObjects,
  groupType,
  setLevelByObjectType,
  locations,
  editObjects,
  saveObjects,
  clearEditedObjects,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const [showAllForGroups, setShowAllForGroups] = useState<string[]>([])
  useEffect(() => {
    if (!objectGroup) {
      setShowAllForGroups([])
    }
  }, [objectGroup])
  const onClose = useCallback(() => {
    clearEditedObjects()
    close()
  }, [clearEditedObjects, close])

  const save = useCallback(() => {
    saveObjects(onClose)
  }, [onClose, saveObjects])

  const findObjectPredicate = (object: SanitizedObject) => {
    return object.type === objectGroup?.objectType || (
      !object.type &&
      objectGroup?.objectType === 'unknown'
    )
  }
  const groupLevel = (
    editedObjects.find(findObjectPredicate) ||
    objects.find(findObjectPredicate)
  )?.level || SanitizationLevel.LOW

  const allItemsCount = objectGroup?.subgroups.reduce((count, group) => count + group.objectIds.length, 0)
  const selectedItemsCount = objectGroup?.subgroups.reduce((count, group) => {
    const enabledInGroup = group.objectIds.filter(objectId => {
      const editedObject = editedObjects.find(object => object.pointId === objectId)
      if (editedObject) {
        return editedObject.enabled
      }

      return objects.find(object => object.pointId === objectId)?.enabled
    }).length
    return count + enabledInGroup
  }, 0)

  const confirmSetLevel = useCallback((objectType: string, level: SanitizationLevel) => {
    setLevelByObjectType(objectType, level)
  }, [setLevelByObjectType])

  return (
    <Dialog
      open={objectGroup !== undefined}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <DialogTitle onClose={onClose}>
          {groupType === ObjectGroupType.LOCATIONS ?
            t('uv_SanitizingObjects location title', {location: objectGroup?.translatedLocation}) :
            t(`uv_Object_Types ${objectGroup?.objectType}`)
          }
        </DialogTitle>
        <Subtitle>
          {t('uv_SanitizingObjects selected', {
            number: `${selectedItemsCount}/${allItemsCount}`,
          })}
        </Subtitle>
        {groupType === ObjectGroupType.OBJECTS && (
          <EditUVCLeveLSlider
            disabled={disabled}
            level={groupLevel}
            setLevel={(level: SanitizationLevel) => confirmSetLevel(objectGroup?.objectType as string, level)}
          />
        )}
        <StyledGrid gap={6}>
          {objectGroup?.subgroups
            .map(subgroup => ({
              ...subgroup,
              header: groupType === ObjectGroupType.OBJECTS ?
                (subgroup.translatedLocation || t('uv_Object_Types unknown location') || '') :
                t(`uv_Object_Types ${subgroup.objectType}`),
            }))
            .sort(({header: headerA}, {header: headerB}) => headerA.localeCompare(headerB))
            .map(subgroup => {
              const {header} = subgroup
              const groupObjects = subgroup.objectIds
                .reduce((result, objectId) => {
                  const object = editedObjects.find(ob => ob.pointId === objectId) ||
                    objects.find(ob => ob.pointId === objectId)
                  if (!object) {
                    return result
                  }

                  const location = getLocationTranslation(locations, object.pointId)
                  const objectWithLocation = {
                    ...object,
                    location,
                  }
                  return [...result, objectWithLocation]
                }, [] as Array<SanitizedObject & {location: string}>)
                .sort(({location: locationA}, {location: locationB}) => locationA.localeCompare(locationB))
              const isGroupSelected = groupObjects.every(item => item.enabled)
              const showAll = showAllForGroups.includes(header)
              const objectsToShow = groupObjects.length <= 6 || showAll ?
                groupObjects :
                groupObjects.slice(0, 5)

              return (
                <>
                  <GridHeader
                    key={header}
                    disabled={disabled}
                    onClick={() => editObjects(groupObjects.map(object => ({
                      ...object,
                      enabled: !isGroupSelected,
                    })))}
                    selectable
                    selected={isGroupSelected}
                  >
                    {header} {groupType === ObjectGroupType.LOCATIONS && (
                      <EditUVCLeveLSlider
                        isInsideHeader
                        disabled={disabled}
                        level={groupObjects[0]?.level || SanitizationLevel.LOW}
                        setLevel={(level: SanitizationLevel) => confirmSetLevel(
                          subgroup?.objectType as string,
                          level)}
                      />
                    )}
                  </GridHeader>
                  {objectsToShow.map(object => (
                    <FlatItem
                      key={object.pointId}
                      disabled={disabled}
                      selectable
                      selected={object.enabled}
                      onClick={() => editObjects([{...object, enabled: !object.enabled}])}
                    >
                      <FlatGridItemImage
                        src={serverUrl + object.photo}
                        placeholderSrc="/sanitization/no_photo.svg"
                      />
                      <FlatGridItemText>
                        {object.location || object.pointId}
                      </FlatGridItemText>
                    </FlatItem>
                  ))}
                  {groupObjects.length > 6 && (
                    <FlatItem
                      onClick={() => setShowAllForGroups(groups => groups.includes(header) ?
                        groups.filter(group => group !== header) :
                        [...groups, header])
                      }
                    >
                      {!showAll && (
                        <>
                          <MoreItemsText>
                            <span>{t('uv_SanitizingObjects objects list show more number',
                              {count: groupObjects.length - objectsToShow.length})}</span>
                          </MoreItemsText>
                          <MoreItemsSecondaryText>
                            {t('uv_SanitizingObjects objects list show more selected',
                              {count: groupObjects.slice(6).filter(object => object.enabled).length})}
                          </MoreItemsSecondaryText>
                        </>
                      )}
                      <MoreItemsButton showAll={showAll}>
                        {t(`uv_SanitizingObjects objects list show ${showAll ? 'less': 'more'}`)}
                      </MoreItemsButton>
                    </FlatItem>
                  )}
                </>
              )
            })}
        </StyledGrid>
        <StyledActions>
          <TextButton
            onClick={onClose}
          >
            {t('Common button cancel')}
          </TextButton>
          <TextButton
            disabled={editedObjects.length === 0}
            onClick={save}
          >
            {t('Common button save')}
          </TextButton>
        </StyledActions>
      </DialogContent>
    </Dialog>
  )
}

export default EditObjectGroupModal
