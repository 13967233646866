import {connect} from 'react-redux'
import {Store} from 'types'
import {addSnackbarMessage, closeRobotEdit} from './actions'
import RobotNameEditModal from './RobotNameEditModal'

const mapStateToProps = (state: Store) => ({
  isOpen: state.app.isRobotEditOpen,
  robotId: state.app.selectedRobot?.id,
  siteId: state.app.selectedRobot?.siteId,
  initialName: state.app.selectedRobot?.name,
})

export default connect(mapStateToProps, {
  close: closeRobotEdit,
  addSnackbarMessage,
})(RobotNameEditModal)
