import {
  all,
  takeEvery,
  put,
  select,
} from 'redux-saga/effects'
import {AnyAction} from 'redux'
import {httpGet, getResponseBody, getSelectedSiteAndRobotId} from 'utils'
import {constants as appConstants} from 'app'
import {Store} from 'types'
import {CurrentEventsForPreview, MissionMonitor} from '../types'
import {setCurrentPatrolEvents, setCurrentPatrolEventsAfterRefetch} from './actions'
import {REFETCH_ONGOING_EVENTS} from './constants'
import {getMissionMonitor} from '../selectors'

function* refetchPreviews() {
  yield takeEvery<AnyAction>(REFETCH_ONGOING_EVENTS, function* (action) {
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    const missionMonitor: MissionMonitor | undefined = yield select(getMissionMonitor)
    if (!missionMonitor) {
      return
    }

    const endpoint = `v1/nsp/robot/${siteId}/${robotId}/patrol/previews`
    const response: Response = yield httpGet(endpoint)
    const {status} = response
    if (status < 300) {
      const events = yield getResponseBody(response)
      yield put(setCurrentPatrolEventsAfterRefetch(siteId, robotId, missionMonitor.missionUid, events))
    }
  })
}

function* fetchCurrentPatrolEvents() {
  yield takeEvery<AnyAction>(appConstants.ROBOT_UPDATED, function* () {
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    const missionMonitor: MissionMonitor | undefined = yield select(getMissionMonitor)
    const missionUid = missionMonitor?.missionUid
    const currentEventsForPreview: CurrentEventsForPreview | null = yield select(
      (state: Store) => state.nsp.patrolEvents.currentEventsForPreview
    )
    if (!missionUid || (
      currentEventsForPreview &&
      currentEventsForPreview.siteId === siteId &&
      currentEventsForPreview.robotId === robotId &&
      currentEventsForPreview.patrolId === missionUid
    )) {
      return
    }

    yield put(setCurrentPatrolEvents(siteId, robotId, missionUid, []))

    const endpoint = `v1/nsp/robot/${siteId}/${robotId}/patrol/previews`
    const response: Response = yield httpGet(endpoint)
    const {status} = response
    if (status < 300) {
      const events = yield getResponseBody(response)
      yield put(setCurrentPatrolEvents(siteId, robotId, missionUid, events))
    }
  })
}

function* eventSagas() {
  yield all([
    fetchCurrentPatrolEvents(),
    refetchPreviews(),
  ])
}

export default eventSagas
