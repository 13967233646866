import {LimitFindingStatus, Store} from 'types'
import {createSelector} from 'reselect'
import {RouteChildrenProps} from 'react-router'
import {ROUTES} from '../../../constants'
import {getDiagnosticEvents, getEvent, getPatrolMonitor, getStateMachine} from '../selectors'
import {
  DiagnosticEvent,
  OperationPreconditions,
  PATROL_COMMAND,
  PatrolPointCounts,
  StateMachineGroup,
} from '../types'
import {emptyArray, isLimitFindingInProgress} from 'utils'
import {selectors} from 'shared'
import {PausePatrolDispatchActions, PausePatrolStateInformation} from './types'
import {exitLimitFinding} from '../../../limitFindingWizard/utils'
import {getLocationParts} from '../utils'
import {executeHooks} from 'framework'
import {NAV_HOOK_LABELS} from '../constants'

export const checkIfHasEventWithAlarm = createSelector(
  getEvent,
  (state: Store) => state.nsp.siteSettings.notifications.notificationSettings,
  (state: Store) => state.nsp.siteSettings.notifications.notificationsEnabled,
  (event, notificationSettings, notificationsEnabled) => {
    if (!event || !notificationsEnabled) {
      return false
    }

    const hasLocation = event.toLocation || event.fromLocation
    if (!hasLocation) {
      return event.isAssistance
    }

    const isSingleLocation = !event.toLocation || !event.fromLocation
    if (isSingleLocation) {
      const location = event.toLocation || event.fromLocation
      const notificationsDisabled = notificationSettings[location as string] === false
      if (notificationsDisabled) {
        return false
      }
    } else {
      const hasNotificationDisabledForLocationTo = notificationSettings[event.toLocation as string] === false
      const hasNotificationDisabledForLocationFrom = notificationSettings[event.fromLocation as string] === false
      const hasDisabledNotifications = hasNotificationDisabledForLocationTo && hasNotificationDisabledForLocationFrom
      if (hasDisabledNotifications) {
        return false
      }
    }

    return event.isAssistance
  }
)

export const areActionsDisabled = createSelector(
  (state: Store) => state.app.selectedRobot,
  (state: Store) => state.app.isConnectedToServer,
  selectors.isCurrentRobotOnline,
  (state: Store) => getStateMachine(state)?.stateGroup,
  (state: Store) => getStateMachine(state)?.isTransitive,
  (state: Store) => state.nsp.robotsWithPendingChanges,
  (robot, isConnectedToServer, isCurrentRobotOnline, stateGroup, isTransitive, robotsWithPendingChanges) => {
    const canMakeRequest = (
      isConnectedToServer &&
      isCurrentRobotOnline &&
      !isTransitive &&
      stateGroup &&
      stateGroup !== StateMachineGroup.CRITICAL_ERROR &&
      stateGroup !== StateMachineGroup.CRITICAL_BATTERY &&
      stateGroup !== StateMachineGroup.CHECKING_PRECONDITIONS &&
      !robotsWithPendingChanges.includes(robot?.id as string)
    )

    return !canMakeRequest
  }
)

export const getPauseAction = createSelector(
  (pauseState: PausePatrolStateInformation, pauseActions: PausePatrolDispatchActions) => pauseActions,
  (pauseState: PausePatrolStateInformation, pauseActions: PausePatrolDispatchActions, ownProps: RouteChildrenProps) =>
    ownProps.location.pathname,
  (pauseState: PausePatrolStateInformation, pauseActions: PausePatrolDispatchActions, ownProps: RouteChildrenProps) =>
    ownProps.location.state?.from,
  (pauseState: PausePatrolStateInformation, pauseActions: PausePatrolDispatchActions, ownProps: RouteChildrenProps) =>
    ownProps.history,
  (pauseState: PausePatrolStateInformation) => pauseState.isRunningLimitFinding,
  (pauseState: PausePatrolStateInformation) => pauseState.isPatrolIdle,
  (pauseState: PausePatrolStateInformation) => pauseState.robot?.healthStatus.limitFindingStatus,
  (pauseState: PausePatrolStateInformation) => pauseState.robot?.id,
  (
    pauseActions,
    pathname,
    fromUrl,
    history,
    isRunningLimitFinding,
    isPatrolIdle,
    limitFindingStatus,
    robotId
  ) => {
    const isLimitFindingPage = pathname === ROUTES.LIMIT_FINDING_WIZARD

    const redirect = () => {
      if (pathname !== ROUTES.PATROL) {
        history.push(ROUTES.PATROL)
      }
    }

    const isLimitFindingRunning = isRunningLimitFinding || isLimitFindingInProgress(limitFindingStatus)
    if (isLimitFindingRunning || (isLimitFindingPage && isPatrolIdle)) {
      return () => {
        if (robotId && isLimitFindingRunning) {
          pauseActions.cancelLimitFinding(robotId)
        }

        if (pathname.startsWith(ROUTES.LIMIT_FINDING_WIZARD)) {
          exitLimitFinding(history, fromUrl)
        } else {
          redirect()
        }
      }
    }

    return () => {
      if (robotId) {
        pauseActions.changePatrolState(robotId, PATROL_COMMAND.PAUSE)
        redirect()
      }
    }
  }
)

export const getLimitFindingPreconditions = createSelector(
  (state: Store) => state.app.selectedRobot?.config?.lfBatteryThreshold,
  (state: Store) => state.app.selectedRobot?.healthStatus.batteryLevel,
  (state: Store) => state.app.selectedRobot?.healthStatus.limitFindingStatus,
  (state: Store) => state.app.selectedRobot?.healthStatus.chargingCableConnected,
  (lfBatteryThreshold, batteryLevel, limitFindingStatus, chargingCableConnected) => {
    if (!lfBatteryThreshold || limitFindingStatus === LimitFindingStatus.OK) {
      return []
    }

    const isBatteryOk = (batteryLevel || 0) >= (lfBatteryThreshold || 0)

    if (!isBatteryOk && chargingCableConnected) {
      return [
        {
          identifier: 'battery',
          isFulfilled: false,
          textKey: 'PowerOn_CalibrationWizard content power on calibration battery too low cable plugged',
        },
      ]
    }

    return [
      {
        identifier: 'battery',
        isFulfilled: isBatteryOk,
        textKey: isBatteryOk ?
          'PowerOn_CalibrationWizard content power on calibration battery ok' :
          'PowerOn_CalibrationWizard content power on calibration battery too low',
      },
    ]
  }
)

export const getPreconditions = createSelector(
  (state: Store) => state.app.selectedRobot?.diagnosticData?.preconditions?.itemsValid,
  (state: Store) => state.app.selectedRobot?.diagnosticData?.preconditions?.batteryValid,
  (state: Store) => state.app.selectedRobot?.diagnosticData?.preconditions?.chargerValid,
  (state: Store) => state.app.selectedRobot?.diagnosticData?.preconditions?.usingAC,
  (itemsValid, batteryValid, chargerValid, usingAC) => {
    const operationPreconditions: OperationPreconditions = {
      itemsValid: Boolean(itemsValid),
      batteryValid: Boolean(batteryValid),
      chargerValid: Boolean(chargerValid),
      usingAC: Boolean(usingAC),
    }

    return operationPreconditions
  }
)

export const getPointCounts = createSelector(
  (state: Store) => getPatrolMonitor(state)?.checkedPoints,
  (state: Store) => getPatrolMonitor(state)?.skippedPoints,
  (state: Store) => getPatrolMonitor(state)?.totalPoints,
  (checkedPoints, skippedPoints, totalPoints) => {
    const result: PatrolPointCounts = {
      checkedPoints: checkedPoints || 0,
      skippedPoints: skippedPoints || 0,
      totalPoints: totalPoints || 0,
    }
    return result
  }
)

export const getCurrentLocation = createSelector(
  (state: Store) => state.nsp.siteSettings.locations,
  (state: Store) => state.app.selectedRobot?.diagnosticData?.location?.lastLocationUid,
  (locations, fromLocation) => getLocationParts(locations, fromLocation).join(';')
)

export const getEventsForMoreProblemsPreview = createSelector(
  getEvent,
  getDiagnosticEvents,
  (event, events): DiagnosticEvent[] => {
    const assistanceEvents = events.filter(event => event.isAssistance)
    if (assistanceEvents.length === 0) {
      return emptyArray
    }

    if (assistanceEvents.length === 1 && assistanceEvents[0].notificationUid === event?.notificationUid) {
      return emptyArray
    }

    return assistanceEvents.filter(ev => ev.notificationUid !== event?.notificationUid)
  }
)

export const getPatrolTitleOverrides = (state: Store): {} => {
  const selectors: Array<(state: Store) => {}> = []
  executeHooks(NAV_HOOK_LABELS.PATROL.TITLE_OVERRIDES, {selectors})
  return selectors.reduce((result, selector) => ({
    ...result,
    ...selector(state),
  }), {})
}
