import {applyMiddleware, combineReducers, createStore} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootSaga from '../sagas'
import reducerRegistry from './reducerRegistry'
import {Reducers} from './types'

/*
TODO: uncomment this block section when
 https://github.com/abettadapur/redux-saga-devtools-extension/issues/6 is resolved
const monitor = (window as {__SAGA_MONITOR_EXTENSION__?: object}).__SAGA_MONITOR_EXTENSION__
const sagaMiddleware = createSagaMiddleware({sagaMonitor: monitor})
*/

const combine = (reducers: Reducers) => {
  const rootReducer = combineReducers(reducers)
  return (state: any, action: any) => {
    // might be useful later to clean some state (for example on logout)
    return rootReducer(state, action)
  }
}

const sagaMiddleware = createSagaMiddleware()
const store = createStore(
  combine(reducerRegistry.getReducers()),
  /* preloadedState, */
  composeWithDevTools(
    applyMiddleware(thunk, sagaMiddleware)
  )
)

sagaMiddleware.run(rootSaga)

reducerRegistry.setChangeListener(reducers => store.replaceReducer(combine(reducers)))

export {
  sagaMiddleware,
}

export default store
