import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {Props as PickerItemProps} from './PickerItem'
import {computeChildIndex, velocityCreator} from './utils'
import {Value} from './types'

const itemHeight = 34
const maxHeight = 250
// elements surrounding default content when loop is true
const backupSize = 2

const StyledSinglePicker = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  flex: 1;
  text-align: center;
  height: ${maxHeight}px;
`

const Mask = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  z-index: 3;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6)),
    linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
  background-position: top, bottom;
  background-size: 100% ${(maxHeight - itemHeight) / 2}px;
  background-repeat: no-repeat;
`

const Indicator = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${itemHeight}px;
  position: absolute;
  left: 0;
  top: 102px;
  z-index: 3;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
`

const ItemWrapper = styled.div`
  text-align: center;
  height: ${itemHeight}px;
  line-height: ${itemHeight}px;
  ${({selected}: {selected: boolean}) => selected ? `font-weight: bolder;` : ''}
`

interface Props {
  className?: string
  disabled?: boolean
  loop?: boolean
  children: Array<{props: PickerItemProps, children?: JSX.Element}>
  onValueChange?: (value: Value) => void
  onScrollChange?: (value: Value) => void
  value?: Value
}

/*
 * Inspired by https://github.com/react-component/m-date-picker
 */
const SinglePicker = (props: Props) => {
  const childrenArray = React.Children.toArray(props.children)
  const [selectedValue, setSelectedValue] = useState(props.value || childrenArray[0]?.props.value)
  const [height, setHeight] = React.useState<number>(0)

  const backupHeight = props.loop ? height * backupSize : 0

  const rootRef = useRef<HTMLDivElement>(null)
  const indicatorRef = useRef<HTMLDivElement>(null)
  const maskRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const innerChildrenRef = useRef<HTMLDivElement>(null)
  let scrollValue: number | undefined = undefined

  const scrollHandlers = (() => {
    let scrollY = -1
    let lastScrollY = 0
    let startY = 0
    let scrollDisabled = false
    let isMoving = false

    const setTransform = (nodeStyle: CSSStyleDeclaration, value: string) => {
      nodeStyle.transform = value
    }

    const setTransition = (nodeStyle: CSSStyleDeclaration, value: string) => {
      nodeStyle.transition = value
    }

    const scrollTo = (y: number, time = .3) => {
      if (scrollY !== y) {
        scrollY = y
        if (time && contentRef.current) {
          setTransition(contentRef.current.style, `cubic-bezier(0,0,0.2,1.15) ${time}s`)
        }
        if (contentRef.current) {
          setTransform(contentRef.current.style, `translate3d(0,${-y}px,0)`)
        }
        setTimeout(() => {
          scrollingComplete()
          if (contentRef.current) {
            setTransition(contentRef.current.style, '')
          }
        }, +time * 1000)
      }
    }

    const Velocity = velocityCreator()

    const onFinish = () => {
      isMoving = false
      let targetY = scrollY

      const height = (props.children.length - 1) * itemHeight

      let time = .3

      const velocity = Velocity.getVelocity(targetY) * 4
      if (velocity) {
        targetY = velocity * 40 + targetY
        time = Math.abs(velocity) * .1
      }

      if (targetY % itemHeight !== 0) {
        targetY = Math.round(targetY / itemHeight) * itemHeight
      }

      if (!props.loop) {
        if (targetY < 0) {
          targetY = 0
        } else if (targetY > height) {
          targetY = height
        }
      }

      scrollTo(targetY, time < .3 ? .3 : time)
      onScrollChange()
    }

    const onStart = (y: number) => {
      if (scrollDisabled) {
        return
      }

      isMoving = true
      startY = y
      lastScrollY = scrollY
    }

    const onMove = (y: number) => {
      if (scrollDisabled || !isMoving) {
        return
      }

      scrollY = lastScrollY - y + startY

      if (props.loop && contentRef.current && (scrollY < backupHeight || scrollY >= backupHeight + height)) {
        const newScrollY = backupHeight + (scrollY % height)
        const diff = newScrollY - scrollY
        startY += diff
        Velocity.updatePreviousY(diff)
        scrollY = newScrollY
      }

      Velocity.record(scrollY)
      onScrollChange()

      if (contentRef.current) {
        setTransform(contentRef.current.style, `translate3d(0,${-scrollY}px,0)`)
      }
    }

    return {
      touchstart: (evt: React.TouchEvent<HTMLDivElement>) => onStart(evt.touches[0].pageY),
      mousedown: (evt: React.MouseEvent<HTMLDivElement>) => onStart(evt.pageY),
      touchmove: (evt: React.TouchEvent<HTMLDivElement>) => {
        evt.preventDefault()
        onMove(evt.touches[0].pageY)
      },
      mousemove: (evt: React.MouseEvent<HTMLDivElement>) => {
        evt.preventDefault()
        onMove(evt.pageY)
      },
      touchend: () => onFinish(),
      touchcancel: () => onFinish(),
      mouseup: () => onFinish(),
      getValue: () => {
        return scrollY
      },
      scrollTo,
      setDisabled: (disabled = false) => {
        scrollDisabled = disabled
      },
    }
  })()

  const select = (value: Value | undefined, scrollTo: (y: number, time?: number) => void) => {
    const children = React.Children.toArray(props.children)
    const selectByIndex = (index: number) => {
      if (index < 0 || index >= children.length || !itemHeight) {
        return
      }
      scrollTo((index * itemHeight) + backupHeight, 0)
    }
    for (let i = 0, len = children.length; i < len; i++) {
      if (children[i].props.value === value) {
        selectByIndex(i)
        return
      }
    }
    selectByIndex(0)
  }

  const scrollingComplete = () => {
    const top = scrollHandlers.getValue()
    if (props.loop || top >= 0) {
      doScrollingComplete(top)
    }
  }

  const onScrollChange = () => {
    const top = scrollHandlers.getValue()
    if (props.loop || top >= 0) {
      const children = React.Children.toArray(props.children)
      const index = computeChildIndex(top, itemHeight, children.length, props.loop ? height : undefined)
      if (scrollValue !== index) {
        scrollValue = index
        const child = children[index]
        if (child && props.onScrollChange) {
          props.onScrollChange(child.props.value)
        } else if (!child && console.warn) {
          console.warn('child not found', children, index, child)
        }
      }
    }
  }

  const fireValueChange = (newValue: Value) => {
    if (newValue !== selectedValue) {
      if (!('value' in props)) {
        setSelectedValue(newValue)
      }
      if (props.onValueChange && props.value !== newValue) {
        props.onValueChange(newValue)
      }
    }
  }

  const doScrollingComplete = (top: number) => {
    const children = React.Children.toArray(props.children)
    const index = computeChildIndex(top, itemHeight, children.length, props.loop ? height : undefined)
    const child = children[index]
    if (child && child.props.value !== props.value) {
      fireValueChange(child.props.value)
    } else if (!child && console.warn) {
      console.warn('child not found', children, index)
    }
  }

  useEffect(() => {
    const root = rootRef.current
    if (root) {
      const rootHeight = root.getBoundingClientRect().height
      let num = Math.floor(rootHeight / itemHeight)
      if (num % 2 === 0) {
        num--
      }
      num--
      num /= 2
      if (contentRef.current) {
        contentRef.current.style.padding = `${itemHeight * num}px 0`
      }
      if (indicatorRef.current) {
        indicatorRef.current.style.top = `${itemHeight * num}px`
      }
      if (maskRef.current) {
        maskRef.current.style.backgroundSize = `100% ${itemHeight * num}px`
      }
      select(props.value, scrollHandlers.scrollTo)

      const willPreventDefault = {passive: false}
      const willNotPreventDefault = {passive: true}
      Object.keys(scrollHandlers).forEach(key => {
        if (key.startsWith('touch') || key.startsWith('mouse')) {
          const eventOptions = key.includes('move') ? willPreventDefault : willNotPreventDefault
          // @ts-ignore
          root.addEventListener(key, scrollHandlers[key], eventOptions)
        }
      })
    }

    return () => {
      Object.keys(scrollHandlers).forEach(key => {
        if ((key.startsWith('touch') || key.startsWith('mouse')) && root) {
          // @ts-ignore
          root.removeEventListener(key, scrollHandlers[key])
        }
      })
    }
  }, [props.value, scrollHandlers, select])

  // similar to component did update
  useEffect(() => {
    select(props.value, (y: number) => scrollHandlers.scrollTo(y, 0))
  })

  useEffect( () => {
    if (typeof props.value !== 'undefined') {
      setSelectedValue(previousValue => {
        if (previousValue !== props.value) {
          select(props.value, scrollHandlers.scrollTo)
        }

        return props.value as Value
      })
    }
  }, [select, scrollHandlers, props.value])

  useEffect( () => {
    scrollHandlers.setDisabled(props.disabled)
  }, [scrollHandlers, props.disabled])

  useLayoutEffect(() => {
    if (innerChildrenRef.current && contentRef.current) {
      setHeight(innerChildrenRef.current.offsetHeight)
      contentRef.current.scrollTop = innerChildrenRef.current.offsetHeight * backupSize
    }
  })

  const columns = React.Children.map(props.children, (child) => (
    <ItemWrapper
      key={child.props.value}
      selected={child.props.value === props.value}
    >
      {child.children || child.props.children}
    </ItemWrapper>
  ))

  return (
    <StyledSinglePicker className={props.className} ref={rootRef}>
      <Mask ref={maskRef} />
      <Indicator ref={indicatorRef} />
      <Content ref={contentRef}>
        {props.loop && Array(backupSize).fill(null).map((_, index) => (
          <div key={index}>{columns}</div>
        ))}
        <div ref={innerChildrenRef}>
          {columns}
        </div>
        {props.loop && Array(backupSize).fill(null).map((_, index) => (
          <div key={index}>{columns}</div>
        ))}
      </Content>
    </StyledSinglePicker>
  )
}

export default SinglePicker
