import {connect} from 'react-redux'
import {Store} from 'types'
import ScheduleEditPageNavBar from './ScheduleEditPageNavBar'
import {canEdit} from './selectors'
import {setEditedStep, saveEditedItem} from './actions'

const mapStateToProps = (state: Store) => {
  return {
    step: state.nsp.schedule.editStep,
    disabled: !canEdit(state),
  }
}

export default connect(mapStateToProps, {
  setStep: setEditedStep,
  saveEditedItem,
})(ScheduleEditPageNavBar)
