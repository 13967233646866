import React from 'react'
import {PageSubtitle, PageTitle} from 'components'
import {useModuleAwareTranslation} from 'utils'
import {PageMainContent} from '../../NSPCommonStyled'
import NotificationSettingsListContainer from './NotificationSettingsListContainer'

const NotificationSettings = () => {
  const {t} = useModuleAwareTranslation()
  return (
    <>
      <PageTitle>{t('Nav_Notification settings page title')}</PageTitle>
      <PageSubtitle>{t('Nav_Notification settings page subtitle')}</PageSubtitle>
      <PageMainContent>
        <NotificationSettingsListContainer />
      </PageMainContent>
    </>
  )
}

export default NotificationSettings
