const ACTION_PREFIX = 'nsp/patrolEvents/'

export const EVENT_NOTE_OPTIONS = [
  {value: 'point_checked', translationKey: 'Nav_EventNote content event note point checked'},
  {value: 'door_opened', translationKey: 'Nav_EventNote content event note door opened'},
  {value: 'robot_checked', translationKey: 'Nav_EventNote content event note robot checked'},
]

export const SET_ONGOING_PATROL_EVENT_TYPE = `${ACTION_PREFIX}SET_ONGOING_PATROL_EVENT_TYPE`

export const REFETCH_ONGOING_EVENTS = `${ACTION_PREFIX}REFETCH_ONGOING_EVENTS`
export const SET_CURRENT_PATROL_EVENTS_AFTER_REFETCH = `${ACTION_PREFIX}SET_CURRENT_PATROL_EVENTS_AFTER_REFETCH`
export const SET_CURRENT_PATROL_EVENTS = `${ACTION_PREFIX}SET_CURRENT_PATROL_EVENTS`
