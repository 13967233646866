import React from 'react'
import {useModuleAwareTranslation} from 'utils'
import {NotificationSettingListItemConfig} from './types'
import {List, ListItem, ListItemText, SimpleSwitch} from 'components'
import {ListEmptyText} from '../../NSPCommonStyled'
import {StyledLoader} from '../SiteLocationsList'

export interface Props {
  disabled: boolean
  isLoading: boolean
  notificationsConfig: NotificationSettingListItemConfig[]
  toggleSettingItem: (locationId: string, enabled: boolean) => void
}

const NotificationSettingsList = ({
  disabled,
  isLoading,
  notificationsConfig,
  toggleSettingItem,
}: Props) => {
  const {t} = useModuleAwareTranslation()

  if (isLoading) {
    return <StyledLoader centered />
  }

  if (notificationsConfig.length === 0) {
    return <ListEmptyText>{t('Nav_NotificationSettings content Settings List Empty')}</ListEmptyText>
  }

  const renderSetting = (config: NotificationSettingListItemConfig) => (
    <React.Fragment key={config.id}>
      <ListItem fullWidth>
        <ListItemText>
          {config.translation}
        </ListItemText>
        <SimpleSwitch
          data-testid="settings-list-item-switch"
          checked={config.enabled}
          disabled={disabled}
          onChange={() => toggleSettingItem(config.id, !config.enabled)}
        />
      </ListItem>
      {config.children.length > 0 && (
        <List isNested>
          {config.children.map(renderSetting)}
        </List>
      )}
    </React.Fragment>
  )

  return (
    <List>
      {notificationsConfig.map(renderSetting)}
    </List>
  )
}

export default NotificationSettingsList
