import {combineReducers, AnyAction} from 'redux'
import {Lock} from 'types'
import * as constants from './constants'

const isFetchingLocks = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.FETCH_LOCKS:
      return true
    case constants.FETCH_LOCKS_DONE:
      return false
    default:
      return state
  }
}

const isLocksModalOpen = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.OPEN_LOCKS_MODAL:
      return !action.shouldClose
    default:
      return state
  }
}

const locks = (state: Lock[] = [], action: AnyAction) => {
  switch (action.type) {
    case constants.FETCH_LOCKS_DONE:
      return action.locks
    default:
      return state
  }
}

const isGripperAutocalModalOpen = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.OPEN_GRIPPER_AUTOCAL_MODAL:
      return !action.shouldClose
    default:
      return state
  }
}

export default combineReducers({
  isFetchingLocks,
  isGripperAutocalModalOpen,
  isLocksModalOpen,
  locks,
})
