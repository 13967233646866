import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import moment from 'moment'
import {useRouteMatch} from 'react-router-dom'
import Fab from '@material-ui/core/Fab'
import GetAppIcon from '@material-ui/icons/GetApp'
import ListIcon from '@material-ui/icons/List'
import useDimensions from 'react-use-dimensions'
// @ts-ignore
import Plx from 'react-plx'
import {Loader, Tabs, FeatureToggle} from 'components'
import {executeHooks} from 'framework'
import {useAppToolbarHeight, useModuleAwareTranslation} from 'utils'
import {PageTitle, PageMainContent} from '../../NSPCommonStyled'
import {
  Locations,
  PatrolDetails,
  DiagnosticEvent,
  ExtendReportDetailsTabsParams,
  GetReportMainLocationParams,
} from '../../types'
import {getLocationTranslation, getPdfWithReportUrl} from '../../utils'
import {MultipleEventsModal} from '../..//patrolEvents'
import {ReportDetailsEvents} from '../shared'
import ReportSummaryTotals from './ReportSummaryTotals'
import ReportSummaryTimeRange from './ReportSummaryTimeRange'
import * as Styled from './ReportDetailsStyled'
import {PERMISSIONS} from '../../../../constants'
import {NAV_HOOK_LABELS} from '../../constants'
import {scenarioToModule} from '../../../utils'

interface Props {
  details: PatrolDetails | null
  isLoadingDetails: boolean
  locations: Locations
  siteId?: string
  robotId?: string
  userId?: string
  fetchPatrolDetails: (patrolId?: string) => void
  downloadFile: (fileName: string, url: string) => void
}

const StyledFab = styled(Fab)`
  position: fixed!important;
  bottom: 68px;
  right: 16px;
`

const StickyTitle = styled(PageTitle)`
  position: sticky;
  background: white;
  top: ${(props: {top: number}) => props.top}px;
  z-index: 10;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
`

const StickyTimeRange = styled.div`
  position: sticky;
  background: white;
  top: ${(props: {top: number}) => props.top}px;
  z-index: 10;
  margin-left: -16px;
  margin-right: -16px;
`

const StickyTabs = styled.div`
  position: sticky;
  background: white;
  top: ${(props: {top: number}) => props.top}px;
  margin-left: -16px;
  margin-right: -16px;
  padding-top: 8px;
  z-index: 9;
`

const TotalsMeasurementDiv = styled.div`
  position: absolute;
  visibility: hidden;
  z-index: -100;
`

const StyledParallax = styled(Plx)`
  transform-origin: center bottom;
`

const PatrolReport = ({
  details,
  isLoadingDetails,
  locations,
  siteId,
  robotId,
  userId,
  fetchPatrolDetails,
  downloadFile,
}: Props) => {
  const {t} = useModuleAwareTranslation(details?.modules)
  const toolbarHeight = useAppToolbarHeight(true)
  const [titleDimsRef, titleDimensions] = useDimensions()
  const [timeDimsRef, timeDimensions] = useDimensions()
  const [summaryTotalsDimsRef, summaryTotalsDimensions] = useDimensions()
  const routeMatch = useRouteMatch<{patrolId?: string}>()
  const [selectedEvent, setSelectedEvent] = useState('')
  const [modalEvents, setModalEvents] = useState<DiagnosticEvent[] | null>(null)
  const [tabIndex, setTabIndex] = useState(0)
  const patrolId = routeMatch?.params?.patrolId

  useEffect(() => {
    if (robotId) {
      fetchPatrolDetails(patrolId)
    }
  }, [robotId, patrolId])

  const setCurrentEvent = (eventId: string, events?: DiagnosticEvent[]) => {
    setSelectedEvent(eventId)
    setModalEvents(events || null)
  }

  const tabs = [
    {
      titleKey: 'Nav_Report tab events',
      iconComponent: ListIcon,
      component: ReportDetailsEvents,
    },
  ]
  const settingsModule = scenarioToModule(details?.roomSettings?.scenario)
  const modulesFromSettings = settingsModule ? [settingsModule] : undefined
  const hookContext: ExtendReportDetailsTabsParams = {
    tabs,
    modules: modulesFromSettings || details?.modules || [],
  }
  executeHooks(NAV_HOOK_LABELS.REPORT.EXTEND_DETAILS_TABS, hookContext)
  const getMainLocationHookContext: GetReportMainLocationParams = {
    details,
    locations,
    mainLocation: '',
  }
  executeHooks(NAV_HOOK_LABELS.REPORT.GET_MAIN_LOCATION, getMainLocationHookContext)

  const getPdfFile = () => {
    const url = getPdfWithReportUrl(siteId as string, robotId as string, patrolId as string)
    downloadFile(`report_${moment(details?.finishedAt).format('YYYYMMDD_HHmmss')}.zip`, url)
  }

  const tabToUse = tabs[tabIndex] || tabs[0]
  const showLoader = isLoadingDetails || !robotId
  return (
    <>
      <StickyTitle
        key={getMainLocationHookContext.mainLocation}
        ref={titleDimsRef}
        top={toolbarHeight}
      >
        {t('Nav_Report title Patrol Report title')}
        {getMainLocationHookContext.mainLocation && (
          <div>{getLocationTranslation(locations, getMainLocationHookContext.mainLocation)}</div>
        )}
      </StickyTitle>
      {details && !showLoader && (
        <>
          <StickyTimeRange ref={timeDimsRef} top={toolbarHeight + titleDimensions.height}>
            <ReportSummaryTimeRange details={details} userId={userId} />
          </StickyTimeRange>
          <StyledParallax
            parallaxData={[{
              start: 0,
              duration: 1, // not used anyway if end is provided
              end: summaryTotalsDimensions.height,
              properties: [
                {
                  startValue: 1,
                  endValue: 0,
                  property: 'opacity',
                },
                {
                  startValue: 1,
                  endValue: 0,
                  property: 'scale',
                },
              ],
            }]}
          >
            <ReportSummaryTotals details={details} />
          </StyledParallax>
          <TotalsMeasurementDiv ref={summaryTotalsDimsRef}>
            <ReportSummaryTotals details={details} />
          </TotalsMeasurementDiv>
        </>
      )}
      <PageMainContent>
        {(showLoader || !details) && (
          <Styled.LoaderWrapper>
            {showLoader ? <Loader /> : <div>{t('Nav_Report content no report to show')}</div>}
          </Styled.LoaderWrapper>
        )}
        {details && !showLoader && (
          <>
            <StickyTabs
              top={toolbarHeight + titleDimensions.height + timeDimensions.height}
            >
              <Tabs
                data-testid={`patrol-tab-${tabIndex}`}
                tabs={tabs.map(tab => ({
                  name: t(tab.titleKey),
                  icon: <tab.iconComponent />,
                }))}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
              />
            </StickyTabs>
            <tabToUse.component
              details={details}
              locations={locations}
              setSelectedEvent={setCurrentEvent}
            />
            {patrolId && details.finishedAt && details.finishedAt !== details.startedAt && (
              <FeatureToggle permissionName={PERMISSIONS.PATROL.PDF_REPORT}>
                <StyledFab
                  color="primary"
                  onClick={getPdfFile}
                >
                  <GetAppIcon />
                </StyledFab>
              </FeatureToggle>
            )}
          </>
        )}
        {selectedEvent && details && (
          <MultipleEventsModal
            close={() => setSelectedEvent('')}
            events={(modalEvents || details?.events)}
            locations={locations}
            selectedEventId={selectedEvent}
            robotId={robotId as string}
            userId={userId}
          />
        )}
      </PageMainContent>
    </>
  )
}

export default PatrolReport
