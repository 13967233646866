import {ROUTES as BASE_ROUTES} from '../../constants'
import {StateMachineGroup} from './types'

export const ROUTES = {
  INDEX: BASE_ROUTES.PATROL,
  BUG_REPORT: `${BASE_ROUTES.PATROL}/issue/:robotId`,
  MANUAL: `${BASE_ROUTES.PATROL}/manual`,
  FINISHED_PATROL: `${BASE_ROUTES.PATROL}/finished/:patrolId`,
  REPORT: `${BASE_ROUTES.PATROL}/report/:patrolId`,
  REPORTS: `${BASE_ROUTES.PATROL}/reports`,
  OBJECT_SETTINGS: `${BASE_ROUTES.PATROL}/object/settings`,
  REPORT_CURRENT: `${BASE_ROUTES.PATROL}/report`,
  SCHEDULE: `${BASE_ROUTES.PATROL}/schedule`,
  SCHEDULE_UPDATE: `${BASE_ROUTES.PATROL}/schedule/:scheduleId`,
  SETTINGS: `${BASE_ROUTES.PATROL}/settings`,
  SITE_SETTINGS: `${BASE_ROUTES.PATROL}/site`,
  SITE_SETTINGS_LOCATIONS: `${BASE_ROUTES.PATROL}/locations`,
  SITE_SETTINGS_NOTIFICATIONS: `${BASE_ROUTES.PATROL}/notifications/settings`,
}

export const REMINDERS = [
  {
    key: 'adjustLightning',
    translationKey: 'adjust lightning',
  },
  {
    key: 'removeCharger',
    translationKey: 'remove charger',
  },
]

export const EVENT_TYPES = {
  PATROL: {
    REMINDER: 'CLIENT_PATROL_REMINDER',
    REMINDER_VIEWED: 'CLIENT_PATROL_REMINDER_VIEWED',
    EVENT: 'CLIENT_PATROL_EVENT',
    SCHEDULE: 'CLIENT_PATROL_SCHEDULE_UPDATE',
    CONFIG_LIST_UPDATE: 'CLIENT_PATROL_CONFIG_LIST_UPDATE',
    SETTINGS: 'CLIENT_PATROL_SETTINGS_UPDATE',
    SKIPPED_PATROL: 'CLIENT_PATROL_SKIPPED_PATROL',
    PATROL_ABOUT_TO_START: 'CLIENT_PATROL_ABOUT_TO_START',
    PATROL_ABOUT_TO_START_NO_ROBOT: 'CLIENT_PATROL_ABOUT_TO_START_NO_ROBOT',
    PATROL_ABOUT_TO_START_PRECONDITIONS: 'CLIENT_PATROL_ABOUT_TO_START_PRECONDITIONS',
    PATROL_FINISHED: 'CLIENT_PATROL_FINISHED',
  },
}

const ACTION_PREFIX = 'nsp/'

export const ADD_EVENT = `${ACTION_PREFIX}ADD_EVENT`
export const MARK_EVENT_AS_VIEWED = `${ACTION_PREFIX}MARK_EVENT_AS_VIEWED`

export const SET_AUTO_OPEN_SELECT_CONFIGURATION = `${ACTION_PREFIX}SET_AUTO_OPEN_SELECT_CONFIGURATION`

export const PATROL_FINISHED = `${ACTION_PREFIX}PATROL_FINISHED`
export const SET_PATROL_FINISHED = `${ACTION_PREFIX}SET_PATROL_FINISHED`

export const CHANGE_STATE_START = `${ACTION_PREFIX}CHANGE_STATE_START`
export const CHANGE_STATE = `${ACTION_PREFIX}CHANGE_STATE`
export const CHANGE_STATE_FINISHED = `${ACTION_PREFIX}CHANGE_STATE_FINISHED`

export const CONFIG_LIST_UPDATED = `${ACTION_PREFIX}CONFIG_LIST_UPDATED`

export const SET_SETTINGS_LISTENER = `${ACTION_PREFIX}SET_SETTINGS_LISTENER`
export const SET_SETTINGS = `${ACTION_PREFIX}SET_SETTINGS`
export const SET_SETTING_NAME = `${ACTION_PREFIX}SET_SETTING_NAME`
export const SET_SETTING_HOME_UID = `${ACTION_PREFIX}SET_SETTING_HOME_UID`
export const SETTINGS_ITEM_TOGGLE = `${ACTION_PREFIX}SETTINGS_ITEM_TOGGLE`
export const SETTINGS_ITEM_TOGGLE_FINISHED = `${ACTION_PREFIX}SETTINGS_ITEM_TOGGLE_FINISHED`
export const SETTINGS_GET_ITEMS = `${ACTION_PREFIX}SETTINGS_GET_ITEMS`
export const SETTINGS_GET_ITEMS_FINISHED = `${ACTION_PREFIX}SETTINGS_GET_ITEMS_FINISHED`

export const SCHEDULE_UPDATE_REQUEST_START = `${ACTION_PREFIX}SCHEDULE_UPDATE_REQUEST_START`
export const SCHEDULE_UPDATE_REQUEST_FINISH = `${ACTION_PREFIX}SCHEDULE_UPDATE_REQUEST_FINISH`
export const SET_SCHEDULE = `${ACTION_PREFIX}SET_SCHEDULE`
export const SCHEDULE_ITEM_UPDATE = `${ACTION_PREFIX}SCHEDULE_ITEM_UPDATE`
export const SCHEDULE_ORDER_UPDATE = `${ACTION_PREFIX}SCHEDULE_ORDER_UPDATE`
export const SCHEDULE_ITEM_UPDATE_REQUEST = `${ACTION_PREFIX}SCHEDULE_ITEM_UPDATE_REQUEST`
export const SCHEDULE_ITEM_UPDATE_FINISHED = `${ACTION_PREFIX}SCHEDULE_ITEM_UPDATE_FINISHED`
export const SCHEDULE_GET_ITEMS = `${ACTION_PREFIX}SCHEDULE_GET_ITEMS`
export const SCHEDULE_GET_ITEMS_FINISHED = `${ACTION_PREFIX}SCHEDULE_GET_ITEMS_FINISHED`

export const REFETCH_SETTINGS = `${ACTION_PREFIX}REFETCH_SETTINGS`

export const SET_IS_SHOWING_PRECONDITIONS = `${ACTION_PREFIX}SET_IS_SHOWING_PRECONDITIONS`

export const SAVE_EVENT_NOTE = `${ACTION_PREFIX}SAVE_EVENT_NOTE`
export const SAVE_EVENT_NOTE_SUCCESS = `${ACTION_PREFIX}SAVE_EVENT_NOTE_SUCCESS`
export const SAVE_EVENT_NOTE_FAILED = `${ACTION_PREFIX}SAVE_EVENT_NOTE_FAILED`

export const PATROL_SKIPPED = `${ACTION_PREFIX}PATROL_SKIPPED`
export const PATROL_ABOUT_TO_START = `${ACTION_PREFIX}PATROL_ABOUT_TO_START`

export const SET_VIDEO_URL = `${ACTION_PREFIX}SET_VIDEO_URL`

export const IMAGES_TO_PREFETCH = [
  '/nsp/close_battery_door.png',
  '/nsp/close_fork.png',
  '/nsp/connect_charger.png',
  '/nsp/critical_error.png',
  '/nsp/emergency_pressed.png',
  '/nsp/internet_disconnected.gif',
  '/nsp/not_ready.png',
  '/nsp/patrol_cancelled.gif',
  '/nsp/patrol_finished.png',
  '/nsp/rebooting.gif',
  '/nsp/patrolling.gif',
  '/nsp/ready_to_patrol.gif',
  '/nsp/robot_check_required.png',
  '/nsp/robot_disconnected.gif',
  '/nsp/skipped.svg',
  '/nsp/waiting.gif',
  '/nsp/configuration.png',
  '/nsp/person_during_uv.png',
  '/nsp/schedules_ordering.gif',
  '/sanitization/uv_idle.png',
  '/sanitization/wauv_idle.png',
  '/sanitization/sanitizing.gif',
  '/sanitization/sanitizing_wide_area.gif',
  '/sanitization/no_photo.svg',
  '/sanitization/skipped.svg',
  '/sanitization/operation_finished.png',
  '/sanitization/wauv_wait_for_door.gif',
  '/sanitization/pick-up-lamp.gif',
]

export const DEFAULT_STATE_MACHINE_GROUP_IMAGE = '/nsp/waiting.gif'

export const STATE_MACHINE_GROUP_TO_IMAGE: {
  [group: string]: (string | undefined)
} = {
  [StateMachineGroup.CHECKING_PRECONDITIONS]: '/nsp/not_ready.png',
  [StateMachineGroup.IDLE]: '/nsp/ready_to_patrol.gif',
  [StateMachineGroup.IDLE_AWAY]: '/nsp/ready_to_patrol.gif',
  [StateMachineGroup.GOING_HOME]: '/nsp/patrol_cancelled.gif',
  [StateMachineGroup.PATROLLING]: '/nsp/patrolling.gif',
  [StateMachineGroup.STEALTH_MISSION]: '/nsp/patrolling.gif',
  [StateMachineGroup.RESTARTING]: '/nsp/rebooting.gif',
}

export const SPECIAL_TITLE_KEYS = {
  PATROL_FINISHED: 'Nav_Status title patrol status title finished',
  GOING_HOME_WITH_LOW_BATTERY: 'Nav_Status title patrol status title cancelled low battery',
  STOPPED_WHEN_GOING_HOME: 'Nav_Status title patrol status title stopped when going home',
  UNKNOWN: 'Nav_Status title patrol status title unknown',
}

export const STATE_MACHINE_GROUP_TO_TITLE_KEY: {
  [group: string]: (string | undefined)
} = {
  [StateMachineGroup.ASSISTANCE_PAUSED]: 'Nav_Status title patrol status title paused after assistance',
  [StateMachineGroup.CHECKING_PRECONDITIONS]: 'Nav_Status title patrol status title not ready',
  [StateMachineGroup.IDLE]: 'Nav_Status title patrol status title idle',
  [StateMachineGroup.IDLE_AWAY]: 'Nav_Status title patrol status title idle',
  [StateMachineGroup.STOPPED]: 'Nav_Status title patrol status title stopped',
  [StateMachineGroup.STOPPED_RECOVERED]: 'Nav_Status title patrol status title stopped',
  [StateMachineGroup.PATROLLING]: 'Nav_Status title patrol status title patrolling',
  [StateMachineGroup.GOING_HOME]: 'Nav_Status title patrol status title cancelled',
  [StateMachineGroup.PATROLLING_PAUSED]: 'Nav_Status title patrol status title paused',
  [StateMachineGroup.PATROLLING_ASSISTANCE]: 'Nav_Status title patrol status title paused',
  [StateMachineGroup.PATROLLING_PAUSED_PREEMPTING]: 'Nav_Status title patrol status title paused after cancelled',
  [StateMachineGroup.GOING_HOME_PAUSED]: 'Nav_Status title patrol status title paused after cancelled',
  [StateMachineGroup.GOING_HOME_ASSISTANCE]: 'Nav_Status title patrol status title paused after cancelled',
  [StateMachineGroup.STEALTH_MISSION]: 'Nav_Status title patrol status title hidden task',
  [StateMachineGroup.STEALTH_MISSION_PAUSED]: 'Nav_Status title patrol status title hidden task paused',
  [StateMachineGroup.STEALTH_MISSION_ASSISTANCE]: 'Nav_Status title patrol status title hidden task paused',
  [StateMachineGroup.RESTARTING]: 'Nav_Status title patrol status title rebooting',
}

export const SPECIAL_DESCRIPTION_KEYS = {
  PATROL_FINISHED: 'Nav_Status content patrol status description finished',
}

export const STATE_MACHINE_GROUP_TO_DESCRIPTION_KEY: {
  [group: string]: (string | undefined)
} = {
  [StateMachineGroup.CHECKING_PRECONDITIONS]: 'Nav_Status content patrol status description not ready',
  [StateMachineGroup.STOPPED]: 'Nav_Status content reason info robot stopped',
  [StateMachineGroup.STOPPED_RECOVERED]: 'Nav_Status content reason info robot stopped',
  [StateMachineGroup.IDLE]: 'Nav_Status content patrol status description idle',
  [StateMachineGroup.PATROLLING_PAUSED]: 'Nav_Status content patrol status description paused',
  [StateMachineGroup.STEALTH_MISSION_PAUSED]: 'Nav_Status content patrol status description paused',
  [StateMachineGroup.RESTARTING]: 'Nav_Status content patrol status description rebooting',
}

export const NAV_HOOK_LABELS = {
  EVENTS: {
    GET_SKIPPED_EVENT_IMAGE: 'NAVIGATION_GET_SKIPPED_EVENT_IMAGE',
    EXTEND_TITLE_OPTIONS: 'NAVIGATION_EVENTS_EXTEND_TITLE_OPTIONS',
    EXTEND_REPORT_SUMMARY_TEXT_OPTIONS: 'NAVIGATION_EVENTS_EXTEND_REPORT_SUMMARY_TEXT_OPTIONS',
  },
  REPORT: {
    GET_HISTORY_TABS: 'NAVIGATION_GET_HISTORY_TABS',
    EXTEND_DETAILS_TABS: 'NAVIGATION_REPORT_EXTEND_DETAILS_TABS',
    GET_MAIN_LOCATION: 'NAVIGATION_REPORT_GET_MAIN_LOCATION',
  },
  ROUTING: {
    REGISTER_ROUTES: 'NAVIGATION_ROUTING_REGISTER_ROUTES',
  },
  SCHEDULE: {
    EXTEND_SECTIONS: 'NAVIGATION_SCHEDULE_EXTEND_SECTIONS',
  },
  PATROL: {
    FINISHED_IMAGE: 'NAVIGATION_PATROL_FINISHED_IMAGE',
    IMAGE: 'NAVIGATION_PATROL_IMAGE',
    TITLE_OVERRIDES: 'NAVIGATION_PATROL_TITLE_OVERRIDES',
  },
}
