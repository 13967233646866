import moment from 'moment'
import {Storage} from 'platform'

export const getTimeDifferenceString = (date: Date): string => {
  const differenceInSeconds = Math.max(0, moment().diff(date, 'seconds'))
  const hours = Math.floor(differenceInSeconds / 3600)
  const secondsForMinutes = Math.max(0, differenceInSeconds - (hours * 3600))
  const minutes = Math.floor(secondsForMinutes / 60)
  const seconds = secondsForMinutes - (minutes * 60)

  return `${hours ? `${hours}:` : ''}${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

export const sortObjectsByDateDesc = (
  itemA: {date: Date | string},
  itemB: {date: Date | string}
) => new Date(itemB.date).getTime() - new Date(itemA.date).getTime()

export function getNextClosestItemByTime<T>(itemsSortedByTime: T[], itemToDate: (item: T) => Date): T | undefined {
  const now = new Date()
  const hourNow = now.getUTCHours()
  const minuteNow = now.getUTCMinutes()
  let result: T | undefined
  for (const item of itemsSortedByTime) {
    const date = itemToDate(item)
    const hour = date.getUTCHours()
    const minute = date.getUTCMinutes()
    // dates are sorted by hours + minutes so it's enough to find first that is after "now"
    if (hour > hourNow || (hour === hourNow && minute >= minuteNow)) {
      result = item
      break
    }
  }

  if (!result) {
    result = itemsSortedByTime[0]
  }

  return result
}

let currentUserId: string | undefined
let currentUsers12HourFormat: boolean | undefined

const get12HourFormatStorageKey = (userId?: string) => `12HourFormat_${userId || ''}`

export const initCurrentUsers12HourFormat = async (userId?: string): Promise<void> => {
  const storageKey = get12HourFormatStorageKey(userId)
  const stored12HourValue = await Storage.getItem(storageKey)
  if (typeof stored12HourValue === 'string') {
    currentUsers12HourFormat = JSON.parse(stored12HourValue)
  }

  currentUserId = userId
}

export const get12HourFormatFromStorage = (userId?: string): boolean | undefined => {
  if (currentUserId === userId) {
    return currentUsers12HourFormat
  } else if (userId) {
    initCurrentUsers12HourFormat(userId)
  }
}

export const setUse12HoursFormat = (use12HoursFormat: boolean, userId?: string) => {
  currentUserId = userId
  currentUsers12HourFormat = use12HoursFormat
  Storage.setItem(get12HourFormatStorageKey(userId), JSON.stringify(use12HoursFormat))
}

export const shouldUse12HoursFormat = (language: string, userId?: string): boolean => {
  const storedFormat = get12HourFormatFromStorage(userId)
  if (typeof storedFormat !== 'undefined') {
    return storedFormat
  }

  const date = new Date()
  date.setHours(1)
  const formattedDate = moment(date).format('LT')
  return formattedDate.split(' ').length > 1
}

const getMomentFormatBasedOnUserPreferences = (
  options: {defaultFormat: string, format12Hours: string, format24Hours: string},
  userId?: string
): string => {
  const stored12HourValue = get12HourFormatFromStorage(userId)
  if (typeof stored12HourValue === 'undefined') {
    return options.defaultFormat
  }

  return stored12HourValue ? options.format12Hours : options.format24Hours
}

// example: 8:30 PM
export const getTimeFormatForMoment = (userId?: string): string => {
  return getMomentFormatBasedOnUserPreferences({
    defaultFormat: 'LT',
    format24Hours: 'HH:mm',
    format12Hours: 'h:mm A',
  }, userId)
}

// example: September 4, 1986 8:30 PM
export const getDateAndTimeFormatForMoment = (userId?: string): string => {
  return getMomentFormatBasedOnUserPreferences({
    defaultFormat: 'LLL',
    format24Hours: 'LL HH:mm',
    format12Hours: 'LL h:mm A',
  }, userId)
}

// example: Sep 4, 1986 8:30 PM
export const getShortDateAndTimeFormatForMoment = (userId?: string): string => {
  return getMomentFormatBasedOnUserPreferences({
    defaultFormat: 'lll',
    format24Hours: 'll HH:mm',
    format12Hours: 'll h:mm A',
  }, userId)
}
