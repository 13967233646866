import {connect} from 'react-redux'
import {Store} from 'types'
import {isPatrolConfigRunning} from 'utils'
import {navigationTriggered} from './actions'
import {getSelectedRobot} from './selectors'
import BottomNavigationBar from './BottomNavigationBar'
import {hasPermission} from '../components'
import {PERMISSIONS} from '../constants'
import {AIRPORT_GUI_USE_CASE} from './constants'

const mapStateToProps = (state: Store) => {
  const robot = getSelectedRobot(state)
  return {
    pageOptions: state.app.pageOptions,
    selectedRobotId: robot?.id,
    isPatrolConfigUp: isPatrolConfigRunning(robot),
    canAccessEngineeringGUI: hasPermission(state, PERMISSIONS.ENGINEERING_DASHBOARD),
    canAccessSchedule: state.app.guiUseCase !== AIRPORT_GUI_USE_CASE,
  }
}

export default connect(mapStateToProps, {navigationTriggered})(BottomNavigationBar)
