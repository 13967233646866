import {connect} from 'react-redux'
import {Store} from 'types'
import {selectors as authSelectors} from 'auth'
import {downloadFile} from 'app'
import ReportDetails from './ReportDetails'
import {fetchPatrolDetails} from '../actions'

const mapStateToProps = (state: Store) => ({
  details: state.nsp.report.details,
  isLoadingDetails: state.nsp.report.isLoadingDetails,
  locations: state.nsp.siteSettings.locations,
  robotId: state.app.selectedRobot?.id,
  siteId: state.app.selectedRobot?.siteId,
  userId: authSelectors.getUser(state)?.userId,
})

export default connect(
  mapStateToProps,
  {fetchPatrolDetails, downloadFile}
)(ReportDetails)
