import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'
import {DialogActions} from '@material-ui/core'
import {REVERSE_ROOM_ORDER_KEY, REVERSE_ROOM_ORDER_VALUE, useModuleAwareTranslation} from 'utils'
import {executeHooks} from 'framework'
import {Button, Dialog, GenericEventDisplay} from 'components'
import {Locations, ExtendEventTitleHookContext, DiagnosticEvent, DiagnosticReason} from '../types'
import {getLocationParts, getLocationTranslation, hasFaceId} from '../utils'
import {primary, white} from '../../../colors'
import {NAV_HOOK_LABELS, ROUTES} from '../constants'

interface Props {
  event?: DiagnosticEvent
  locations: Locations
  addSnackbarMessage: (message: string, action: any) => void
  close: () => void
}

const CenteredActions = styled(DialogActions)`
  justify-content: center!important;
  margin-bottom: 6px;
  margin-top: 12px;
  
  button {
    padding-left: 24px!important;
    padding-right: 24px!important;
  }
`

const getLocationText = (isReverseOrder: boolean, locationAsString?: string) => {
  if (!locationAsString) {
    return ''
  }

  return isReverseOrder ?
    locationAsString.split(';').reverse().join(', ') :
    locationAsString.split(';').join(', ')
}

const PatrolEventModal = ({
  event,
  locations,
  close,
  addSnackbarMessage,
}: Props) => {
  const {t, i18n} = useModuleAwareTranslation()
  const {pathname} = useLocation()
  const [shouldAutoOpen, setShouldAutoOpen] = useState(pathname === ROUTES.INDEX)
  const [isOpen, setIsOpen] = useState(false)
  const hasPhoto = event?.hasPhoto
  const isModalOpen = shouldAutoOpen || isOpen
  const reason = event?.reason
  const faceId = event?.faceId

  const fromLocation = event?.patrolPointConfig?.patrolPoint?.uid || event?.fromLocation
  const toLocation = event?.toLocation === fromLocation ? undefined : event?.toLocation

  useEffect(() => {
    setShouldAutoOpen(pathname === ROUTES.INDEX)
  }, [pathname])

  useEffect(() => {
    if (hasPhoto && !isModalOpen) {
      let key = `Nav_Event title ${reason}`
      if (hasFaceId(faceId) && i18n.exists(`${key} face_id`)) {
        key = `${key} face_id`
      }
      const titleKey = i18n.exists(key) ? key : reason as string
      const roomName = getLocationTranslation(locations, fromLocation)
      const action = (
        <Button
          onClick={() => setIsOpen(true)}
          $textColor={primary}
          size="small"
        >
          {t('Nav_Event button patrol room checked snackbar action')}
        </Button>
      )

      const titleOptions = {
        roomName,
        name: faceId,
      }

      const hookContext: ExtendEventTitleHookContext = {
        diagnosticEvent: event,
        options: titleOptions,
        locations,
        t,
      }
      executeHooks(NAV_HOOK_LABELS.EVENTS.EXTEND_TITLE_OPTIONS, hookContext)

      addSnackbarMessage(t(titleKey, titleOptions), action)
    }
  }, [faceId, addSnackbarMessage, hasPhoto, isModalOpen, reason, fromLocation])

  useEffect(() => {
    if (isOpen && !hasPhoto) {
      setIsOpen(false)
    }
  }, [hasPhoto, isOpen])

  useEffect(() => {
    let timer = -1
    if (event && event.hasPhoto && shouldAutoOpen) {
      timer = setTimeout(() => {
        close()
      }, 1000 * 60)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [event, shouldAutoOpen])

  if (!event || !hasPhoto) {
    return null
  }

  const getLocationAsString = (location?: string | string[]) =>
    getLocationParts(locations, location).join(';')

  const nextLocationAsString = getLocationAsString(toLocation)
  const eventLocationAsString = getLocationAsString(fromLocation)

  const hasLocation = nextLocationAsString || eventLocationAsString
  let location = ''
  if (hasLocation) {
    const isReverseOrder = t(REVERSE_ROOM_ORDER_KEY) === REVERSE_ROOM_ORDER_VALUE
    const locationStringToUse = reason &&
      [DiagnosticReason.POINT_DONE, DiagnosticReason.POINT_SKIPPED].includes(reason) ?
      eventLocationAsString?.substring(0, eventLocationAsString?.lastIndexOf(';')) :
      eventLocationAsString
    const room = getLocationText(isReverseOrder, locationStringToUse)
    location = room
    if (nextLocationAsString) {
      const roomB = getLocationText(isReverseOrder, nextLocationAsString)
      location = t('Nav_Event content patrol event between', {room, roomB})
    }
  }

  return (
    <Dialog
      open={isModalOpen}
      onClose={close}
      fullWidth
      maxWidth="sm"
      withCloseButton
    >
      <GenericEventDisplay
        event={event}
        locations={locations}
        options={{
          customDescription: location,
          fullImage: true,
          noTimeAgo: false,
          noTitleColor: true,
        }}
      />
      <CenteredActions>
        <Button
          $backgroundColor={primary}
          $textColor={white}
          onClick={close}
        >
          {t('Common button close')}
        </Button>
      </CenteredActions>
    </Dialog>
  )
}

export default PatrolEventModal
