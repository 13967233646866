import {connect} from 'react-redux'
import {Store} from 'types'
import SettingsHomeEdit from './SettingsHomeEdit'
import {setSettingHomeUid} from '../actions'
import {getCurrentSettings} from '../selectors'

const mapStateToProps = (state: Store) => ({
  robotId: state.app.selectedRobot?.id,
  siteId: state.app.selectedRobot?.siteId,
  locations: state.nsp.siteSettings.locations,
  originalHomeUid: getCurrentSettings(state)?.homeUid,
})

export default connect(mapStateToProps, {
  onSave: setSettingHomeUid,
})(SettingsHomeEdit)
