import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {usePrevious} from 'utils'

interface Props {
  patrolId?: string
}

const PatrolFinishedRedirector = ({
  patrolId,
}: Props) => {
  const previousPatrolId = usePrevious(patrolId)
  const {push} = useHistory()

  useEffect(() => {
    if (patrolId && previousPatrolId) {
      push(`/patrol/finished/${previousPatrolId}`)
    }
  }, [patrolId, previousPatrolId])

  return <span style={{display: 'none'}} />
}

export default PatrolFinishedRedirector
