import React from 'react'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import styled from 'styled-components'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import {Robot} from 'types'
import {moveJoint as moveJointAction} from 'robotOperations'
import {Button} from '../BasicElementsStyled'
import {blueButton, white, lightButton} from '../../colors'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
    className?: string
    moveJoint: (isPositiveDirection: boolean) => void
    skipTextDisplay?: boolean
}

interface ContainerProps {
    className?: string
    robot: Robot
    joint: string
    skipTextDisplay?: boolean
}

const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const StyledButton = styled(Button)`
    margin-left: 30px!important;

    &:first-child {
        margin-left: 0!important;
    }
`

export const JointMoveControl: React.FC<Props> = ({className, moveJoint, skipTextDisplay}) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)

  return (
    <Content className={className}>
      {!skipTextDisplay && t('Adjust joint position')}
      <StyledButton
        $backgroundColor={lightButton}
        size="small"
        $isFab
        onClick={() => moveJoint(false)}
      >
        <RemoveIcon />
      </StyledButton>
      <StyledButton
        size="small"
        $backgroundColor={blueButton}
        $textColor={white}
        $isFab
        onClick={() => moveJoint(true)}
      >
        <AddIcon />
      </StyledButton>
    </Content>
  )
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ContainerProps) => ({
  moveJoint: (isPositiveDirection: boolean) =>
    dispatch(moveJointAction(ownProps.robot, ownProps.joint, isPositiveDirection)),
})

export default connect(null, mapDispatchToProps)(JointMoveControl) as React.ComponentType<ContainerProps>
