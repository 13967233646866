import {ThunkDispatch} from 'redux-thunk'
import {setChildModule} from 'app/actions'
import {Module} from './types'

abstract class AbstractModule implements Module {
  constructor(moduleName: string) {
    this.moduleName = moduleName
    this.childModules = []
    this.initialized = false
  }

  initialized: boolean
  moduleName: string
  childModules: string[]

  abstract installModule(dispatch: ThunkDispatch<any, any, any>): void

  public setChildModule(module: string, dispatch: ThunkDispatch<any, any, any>) {
    this.childModules.push(module)
    dispatch(setChildModule(this.moduleName, module))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public run(dispatch: ThunkDispatch<any, any, any>) {
    if (this.initialized) {
      return
    }

    this.initialized = true
    this.installModule(dispatch)
  }

  public shouldExecuteBasedOnModule(currentModules: string[]) {
    return currentModules.includes(this.moduleName) || this.childModules.some(module => currentModules.includes(module))
  }
}


export default AbstractModule
