import {AnyAction} from 'redux'
import {all, put, take, takeEvery, select} from 'redux-saga/effects'
import {getSelectedSiteAndRobotId, httpPost} from 'utils'
import {addSnackbarMessage} from '../app/actions'
import * as appConstants from '../app/constants'
import {ActionWithRobot, RobotState, Store} from 'types'
import {
  finishManualControl,
  setManualControlRaw,
  setSoundPreferencesFinished,
} from './robotActions'
import * as constants from './constants'
import {MoveJointAction, SetManualControlAction} from './types'
import {exitLimitFinding} from '../limitFindingWizard/utils'

function* setManualControlSage() {
  yield takeEvery<SetManualControlAction>(constants.SET_MANUAL_CONTROL, function* (action) {
    const {robot, enable, isFromLimitFinding, from, history} = action
    let currentRobotState = robot.healthStatus.robotState
    const isRunningManualOperation = yield select((store: Store) => store.robotOperations.runningManualControlOperations[robot.id])
    if (isRunningManualOperation) {
      return
    }

    yield put(setManualControlRaw(robot, enable, isFromLimitFinding))

    const expectedState = enable ? RobotState.MANUAL_CONTROL : RobotState.AUTONOMOUS_CONTROL
    let status = 0
    let stateChangeAttemptsLeft = 30

    try {
      const response = yield httpPost(`${robot.siteId}/robot/${robot.id}/setManualControl`, {
        enable,
      })
      status = response.status
    } catch (error) {
      status = 400
    }

    if (status >= 400) {
      yield put(finishManualControl(robot))
      yield put(addSnackbarMessage('Dashboard Couldnt set manual control Please check robot status'))
      if (isFromLimitFinding && history) {
        exitLimitFinding(history, from)
      } else if (isFromLimitFinding) {
        window.history.go(-1)
      }
    } else {
      while (true) {
        const robotUpdatedAction = yield take<ActionWithRobot>(appConstants.ROBOT_UPDATED)
        if (robotUpdatedAction.robot.id !== robot.id) {
          continue
        }

        stateChangeAttemptsLeft--
        const nextRobotState = robotUpdatedAction.robot.healthStatus.robotState
        const isSuccess = nextRobotState === expectedState

        if (isSuccess || (nextRobotState !== currentRobotState) || stateChangeAttemptsLeft < 0) {
          if (isSuccess) {
            if (!isFromLimitFinding) {
              const message = enable ?
                'Dashboard Manual control enabled!' :
                'Dashboard Manual control disabled!'
              yield put(addSnackbarMessage(message))
            }
          } else {
            yield put(addSnackbarMessage('Dashboard Couldnt set manual control Please check robot status'))
          }

          yield put(finishManualControl(robot))
          return
        }

        currentRobotState = nextRobotState
      }
    }
  })
}

function* moveJointSaga() {
  yield takeEvery<MoveJointAction>(constants.MOVE_JOINT, (action) => {
    const {robot, joint, isPositiveDirection} = action
    httpPost(`${robot.siteId}/robot/${robot.id}/moveJoint`, {
      isPositiveDirection,
      joint,
    })
  })
}

function* setSoundPreferences() {
  yield takeEvery<AnyAction>(constants.SET_SOUND, function* (action) {
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    const {shouldMute} = action
    const response: Response = yield httpPost(`${siteId}/robot/${robotId}/sound`, {
      shouldMute,
    })
    const {status} = response
    if (status >= 400 ) {
      yield put(addSnackbarMessage('Dashboard mute status update failed'))
      yield put(setSoundPreferencesFinished())
    } else {
      yield put(addSnackbarMessage('Dashboard mute status update success'))
      yield put(setSoundPreferencesFinished())
    }
  })
}


function* sharedActionsSagas() {
  yield all([
    moveJointSaga(),
    setManualControlSage(),
    setSoundPreferences(),
  ])
}

export default sharedActionsSagas
