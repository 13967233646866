import {connect} from 'react-redux'
import {openRobotSelector, navigationTriggered} from './actions'
import AppToolbar from './AppToolbar'
import {canSelectRobot, getSelectedRobot} from './selectors'
import {RobotState, Store} from '../types'
import {hasPermission} from 'components'
import {selectors} from 'shared'
import {PERMISSIONS} from '../constants'

const mapStateToProps = (state: Store) => ({
  pageOptions: state.app.pageOptions,
  robotName: getSelectedRobot(state)?.name,
  showRobotSelector: canSelectRobot(state),
  isMenuDisabled: (
    getSelectedRobot(state)?.healthStatus.robotState === RobotState.INITIALIZING ||
    Boolean(selectors.getInitializationFailedMessage(state))
  ) && !hasPermission(state, PERMISSIONS.DEBUG_CONTENT),
})

export default connect(mapStateToProps, {openRobotSelector, navigationTriggered})(AppToolbar)
