import React, {useEffect} from 'react'
import styled from 'styled-components'
import {FormItem} from './types'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import {useModuleAwareTranslation} from 'utils'
import {textFieldOptions, userTextKey} from './constants'
import TextField from '@material-ui/core/TextField'
import {lightText, grayText, error} from '../../colors'
import {getItemKey} from './utils'

const BugReportItem = styled.div`
  margin-bottom: 24px;
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-bottom: 18px!important;

  &:last-child {
    margin-bottom: 0!important;
  }
`

const StyledSelect = styled(Select)`
  min-width: 100%;
  ${(props: {value: string}) => !props.value ? `color: ${grayText}!important;` : ''}
`

const StyledInputLabel = styled(InputLabel)`&&& {
  color: ${(props: {error?: boolean}) => props.error ? error : lightText};
  font-weight: 500;
}`

const ErrorText = styled.div`
  color: ${error};
`

interface Props {
  hasError?: boolean
  item: FormItem
  parentKey?: string
  setItem: (key: string, value: string) => void
  values: {[key: string]: string | boolean}
}

const BugReportFormItem = ({
  hasError,
  item,
  parentKey,
  setItem,
  values,
}: Props) => {
  const {t, i18n} = useModuleAwareTranslation()
  const key = getItemKey(item, parentKey)
  const value = (values[key] || '') as string
  const handleDropdownChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setItem(key, event.target.value as string)
    event.stopPropagation()
    event.preventDefault()
  }
  const selectedOption = item.options?.find(option => option.titleKey === value)

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value
    setItem(`${key}_${userTextKey}`, text)
  }

  useEffect(() => {
    if (item.options?.length === 1 && value !== item.options[0].titleKey) {
      setItem(key, item.options[0].titleKey)
    }
  }, [item, value, setItem])

  const numberOfOptions = item.options?.length || 0

  return (
    <BugReportItem>
      <StyledFormControl>
        {!parentKey && (
          <StyledInputLabel shrink error={hasError}>
            {t(`Robot configuration bug report option ${item.titleKey}`)}
          </StyledInputLabel>
        )}
        {numberOfOptions > 1 && (
          <StyledSelect
            value={value}
            onChange={handleDropdownChange}
            displayEmpty
          >
            <MenuItem
              value=""
            >
              {t(`Robot configuration bug report empty selection`)}
            </MenuItem>
            {(item.options || []).map(option => (
              <MenuItem
                key={option.titleKey}
                value={option.titleKey}
              >
                {t(`Robot configuration bug report option ${option.titleKey}`)}
              </MenuItem>
            ))}
          </StyledSelect>
        )}
        {numberOfOptions === 1 && (
          (
            <StyledInputLabel shrink error={hasError}>
              {t(`Robot configuration bug report option ${(item.options as FormItem[])[0]?.titleKey}`)}
            </StyledInputLabel>
          )
        )}
        {hasError && i18n.exists(`Robot configuration bug report option ${item.titleKey} error`) && (
          <ErrorText>{t(`Robot configuration bug report option ${item.titleKey} error`)}</ErrorText>
        )}
      </StyledFormControl>
      {selectedOption?.options?.length && (
        <BugReportFormItem
          parentKey={key}
          item={selectedOption}
          setItem={setItem}
          values={values}
        />
      )}
      {textFieldOptions.includes(value) && (
        <TextField
          multiline
          rows={3}
          rowsMax={15}
          type="text"
          fullWidth
          placeholder={t(`Robot configuration bug report input placeholder`)}
          onChange={handleTextChange}
          value={values[`${key}_${userTextKey}`] || ''}
        />
      )}
    </BugReportItem>
  )
}

export default BugReportFormItem
