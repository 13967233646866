import React from 'react'
import {DiagnosticReason} from 'modules/navigation/types'
import * as Styled from './StyledElements'
import {getSeverity} from './utils'

interface Props {
  children?: React.ReactNode
  reason?: DiagnosticReason
}

const EventFrame: React.FC<Props> = ({
  children,
  reason,
}: Props) => {
  if (!reason) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <Styled.EventWrapper severity={getSeverity(reason)}>
      {children}
    </Styled.EventWrapper>
  )
}

export default EventFrame
