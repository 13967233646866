import {connect} from 'react-redux'
import {remindersEnabled} from 'config'
import {Store} from 'types'
import {closePatrolReminder, markReminderAsViewed} from './actions'
import {getRemindersToShow} from './selectors'
import ReminderModal from './ReminderModal'
import {getStateMachine} from '../selectors'

const mapStateToProps = (state: Store) => {
  return {
    showReminders: remindersEnabled,
    remindersToShow: getRemindersToShow(state),
    stateKey: getStateMachine(state)?.stateGroup,
  }
}

export default connect(mapStateToProps, {closePatrolReminder, markReminderAsViewed})(ReminderModal)
