import {connect} from 'react-redux'
import {LimitFindingStatus, Store} from 'types'
import {hasPermission} from 'components'
import {utils as appUtils} from 'app'
import LimitFindingErrorModal from './LimitFindingErrorModal'
import {PERMISSIONS} from '../../constants'

const mapStateToProps = (state: Store) => ({
  batteryTooLow: appUtils.hasTooLowBatteryForLimitFinding(state.app.selectedRobot),
  canDebug: hasPermission(state, PERMISSIONS.DEBUG_CONTENT),
  isRerunnable: (
    state.app.selectedRobot?.healthStatus?.limitFindingOutcomeRerunnable === true ||
    state.app.selectedRobot?.healthStatus.limitFindingStatus === LimitFindingStatus.NOT_RUN_YET
  ),
})

export default connect(mapStateToProps)(LimitFindingErrorModal)
