import {connect} from 'react-redux'
import {History} from 'history'
import {Robot, Store} from 'types'
import {actions} from 'robotOperations'
import PatrolContentWrapper from './PatrolContentWrapper'
import {hasPermission} from 'components'
import {selectors} from 'shared'
import {utils} from 'app'
import {getClientEvents, getEvent, getStateMachine} from '../selectors'
import {PERMISSIONS, ROUTES} from '../../../constants'
import {ROUTES as NAV_ROUTES} from '../constants'

const mapStateToProps = (state: Store) => ({
  event: getEvent(state),
  clientEventUid: getClientEvents(state)[0]?.notificationUid,
  isCurrentRobotOnline: selectors.isCurrentRobotOnline(state),
  stateGroup: getStateMachine(state)?.stateGroup,
  robot: state.app.selectedRobot,
  triedToFetchRobots: state.app.triedToFetchRobots,
  canDebug: hasPermission(state, PERMISSIONS.DEBUG_CONTENT),
  initializationFailedMessage: selectors.getInitializationFailedMessage(state),
  initializationFailedClientMessage: selectors.getInitializationFailedClientMessage(state),
  showIncompleteCalibration: selectors.shouldShowCalibrationIncomplete(state),
  locations: state.nsp.siteSettings.locations,
  runningManualControlOperations: state.app.selectedRobot ?
    state.robotOperations.runningManualControlOperations[state.app.selectedRobot.id] :
    false,
})

const mapDispatchToProps = {
  setManualControl: actions.setManualControl,
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: {setManualControl: (robot: Robot, enabled: boolean) => void},
  ownProps: {children: React.ReactNode}
) => ({
  ...ownProps,
  shouldDisableLimitFinding: utils.shouldDisableLimitFinding(stateProps.robot),
  showIncompleteCalibration: stateProps.showIncompleteCalibration,
  locations: stateProps.locations,
  canDebug: stateProps.canDebug,
  stateGroup: stateProps.stateGroup,
  triedToFetchRobots: stateProps.triedToFetchRobots,
  event: stateProps.event,
  isClientEvent: Boolean(stateProps.clientEventUid) && stateProps.event?.notificationUid === stateProps.clientEventUid,
  runningManualControlOperations: stateProps.runningManualControlOperations,
  initializationFailedMessage: stateProps.initializationFailedMessage,
  initializationFailedClientMessage: stateProps.initializationFailedClientMessage,
  robotState: stateProps.robot?.healthStatus.robotState,
  limitFinding: stateProps.robot?.healthStatus.limitFindingStatus,
  isRobotOnline: stateProps.isCurrentRobotOnline,
  disableManualControl: stateProps.robot ?
    () => dispatchProps.setManualControl(stateProps.robot as Robot, false) :
    () => {},
  runLimitFinding: (history: History) => {
    history.push(ROUTES.LIMIT_FINDING_WIZARD, {autoManualMode: true, from: NAV_ROUTES.INDEX})
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PatrolContentWrapper)
