import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import IconButton from '@material-ui/core/IconButton'
import {ToolbarTitle, ToolbarTitleMainText} from 'components/Toolbar'
import {executeHooks, HOOK_LABELS} from 'framework'
import {useModuleAwareTranslation, useAppToolbarHeight, isPathnameMatchingRoute} from 'utils'
import {StatusBar} from './statusBar'
import {PausePatrolAction} from '../modules/navigation/status'
import {defaultText, grayText} from '../colors'
import {ROUTES} from '../constants'

const ToolbarWrapper = styled.div`
  margin-left: -14px;
  margin-right: -16px;
  display: flex;
  flex: 1;
  flex-direction: column;
`

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 14px;
  padding-right: 16px;
`

const UseCase = styled.div`
  text-transform: uppercase;
  font-size: 0.75rem;
  color: ${grayText};
  margin-top: -4px;
`

const MenuIconButton = styled(IconButton)`&&&{
  margin-left: -8px;
  padding: 8px;
  
  svg {
    font-size: 30px;
  }
}`

export interface Props {
  backButton: JSX.Element
  robotSelectorButton?: JSX.Element
  robotSelectorAction?: () => void
  openDrawer: () => void
  robotName?: string
  currentModule?: string
}

const ToolbarContent: React.FC<Props> = ({
  robotName,
  backButton,
  currentModule,
  robotSelectorAction,
  robotSelectorButton,
  openDrawer,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const [deepPaths, setDeepPaths] = useState([ROUTES.LIMIT_FINDING_WIZARD])
  const {pathname} = useLocation()
  const height = useAppToolbarHeight(true)
  useEffect(() => {
    const toolbarSpacers = document.getElementsByClassName('toolbar-spacer')
    for (const toolbarSpacer of toolbarSpacers) {
      (toolbarSpacer as HTMLElement).style.height = `${height}px`
    }
    return () => {
      for (const toolbarSpacer of toolbarSpacers) {
        (toolbarSpacer as HTMLElement).style.height = 'inherit'
      }
    }
  }, [height])

  useEffect(() => {
    const deepPathsData = executeHooks(HOOK_LABELS.ROUTING.REGISTER_DEEP_PATHS, {})
    const paths = new Set<string>()
    for (const deepPaths of Object.values(deepPathsData)) {
      (deepPaths as string[]).forEach(path => paths.add(path))
    }
    setDeepPaths(defaultPaths => [...defaultPaths, ...Array.from(paths)])
  }, [])

  const showBackButton = deepPaths.some(deepPath => isPathnameMatchingRoute(pathname, deepPath))

  return (
    <ToolbarWrapper
      className="dialog-aware-toolbar"
      data-testid="nav-toolbar"
    >
      <Top>
        {showBackButton ? backButton : null}
        <MenuIconButton color="inherit" onClick={openDrawer}>
          <SettingsOutlinedIcon />
        </MenuIconButton>
        <ToolbarTitle
          color={defaultText}
          withoutRightIcon
          onClick={robotSelectorAction}
        >
          <ToolbarTitleMainText>{robotName} {robotSelectorButton}</ToolbarTitleMainText>
          {currentModule && (
            <UseCase>
              {t(`use-case ${currentModule}`)}
            </UseCase>
          )}
        </ToolbarTitle>
        <StatusBar />
        <PausePatrolAction />
      </Top>
    </ToolbarWrapper>
  )
}

export default ToolbarContent
