import {
  all,
  takeEvery,
  takeLatest,
  put,
  delay,
  select,
} from 'redux-saga/effects'
import {AnyAction} from 'redux'
import {httpGet, getResponseBody, getSelectedSiteAndRobotId} from 'utils'
import {fetchPatrolList, fetchPatrolListSuccess, fetchPatrolListFailed} from '../../navigation/report/actions'
import {Store} from 'types'
import * as constants from './constants'
import * as actions from './actions'

function* fetchPointDetails() {
  yield takeLatest<AnyAction>(constants.FETCH_POINT_DETAILS, function* (action) {
    const {pointId} = action
    let context = yield getSelectedSiteAndRobotId()
    while (!context.robotId && !context.siteId) {
      yield delay(300)
      context = yield getSelectedSiteAndRobotId()
    }

    const endpoint = `v1/sanitize/robot/${context.siteId}/${context.robotId}/point/details/${pointId}`
    const response: Response = yield httpGet(endpoint)
    const {status} = response
    if (status < 300) {
      const details = yield getResponseBody(response)
      yield put(actions.setPointDetails(details))
    }

    yield put(actions.pointDetailsLoaded())
  })
}

function* fetchReports() {
  yield takeEvery<AnyAction>(constants.FETCH_UV_PATROLS, function* (action) {
    const {siteId, robotId} = yield getSelectedSiteAndRobotId()
    let historyType = yield select((state: Store) => state.uv.report.historyType)
    if (!robotId || historyType !== 'object') {
      yield put(fetchPatrolList(action.page, action.module))
      return
    }

    const endpoint = `v1/sanitize/robot/${siteId}/${robotId}/report/objects`
    const response: Response = yield httpGet(endpoint)
    const {status} = response
    const reports = yield getResponseBody(response)
    historyType = yield select((state: Store) => state.uv.report.historyType)
    if (historyType !== 'object') {
      // we got response with objects when tab was already switched. Ignore it
      return
    }

    if (status < 300) {
      yield put(fetchPatrolListSuccess(0, reports, false))
    } else {
      yield put(fetchPatrolListFailed(reports))
    }
  })
}

function* settingsSagas() {
  yield all([
    fetchPointDetails(),
    fetchReports(),
  ])
}

export default settingsSagas
