import React from 'react'
import styled from 'styled-components'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  margin-top: -8px;
  
  .MuiFormControlLabel-label {
    font-size: inherit;
    margin-left: -4px;
  }
`

const Label = styled.div`
  margin-right: 12px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

interface Props {
  setHistoryType: (historyType: string) => void
  type: string
}

const OperationListItemsSelector = ({
  type,
  setHistoryType,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHistoryType((event.target as HTMLInputElement).value)
  }

  return (
    <Wrapper>
      <Label>
        {t('uv_SanitizingHistory content sanitization history order')}:
      </Label>
      <RadioGroup
        row
        name="history-type"
        onChange={handleChange}
        value={type}
      >
        <FormControlLabel
          value="object"
          control={<Radio />}
          label={t('uv_SanitizingHistory radiobox sanitization history order object')}
        />
        <FormControlLabel
          value="time"
          control={<Radio />}
          label={t('uv_SanitizingHistory radiobox sanitization history order time')}
        />
      </RadioGroup>
      <Label />
    </Wrapper>
  )
}

export default OperationListItemsSelector
