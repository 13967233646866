import React from 'react'
import MaterialTabs from '@material-ui/core/Tabs'
import MaterialTab from '@material-ui/core/Tab'
import styled from 'styled-components'
import {primary, lightestGray} from '../colors'

const StyledTabs = styled(MaterialTabs)`&.MuiTabs-root {
  border-bottom: 2px solid ${lightestGray};
  box-shadow: 0px 2px 2px rgba(0,0,0,0.1);
  
  .MuiTabs-indicator {
    background-color: ${primary};
  }
}`

const Tab = styled(MaterialTab)`&.MuiTab-root {
  font-weight: 500;
  
  &.Mui-selected {
    color: ${primary};
  }
  
  &.MuiTab-labelIcon {
    padding-top: 6px;
    min-height: auto;
    
    .MuiTab-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      
      svg {
        margin-bottom: 0;
        margin-right: 12px;
      }
    }
  }
 
}`

interface Props {
  className?: string
  tabs: Array<{name: string, icon?: React.ReactElement} | string>
  setTabIndex: (index: number) => void
  tabIndex: number
}

const Tabs = ({
  setTabIndex,
  tabIndex,
  tabs,
  className,
  ...otherProps
}: Props) => {
  const handleTabChange = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
    setTabIndex(newTabIndex)
  }

  return (
    <StyledTabs
      {...otherProps}
      className={className}
      onChange={handleTabChange}
      value={tabIndex}
      variant="fullWidth"
    >
      {tabs.map(tab => {
        const label = typeof tab === 'object' ? tab.name : tab
        return (
          <Tab
            key={label}
            label={label}
            icon={typeof tab === 'object' ? tab.icon : undefined}
          />
        )
      })}
    </StyledTabs>
  )
}

export {
  Tabs,
}
