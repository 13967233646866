import React, {useContext} from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import {useHistory} from 'react-router-dom'
import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  Button,
  TextButton,
  ListItem,
  ListItemTextColumn,
  ListItemSubText,
  List,
  ListItemRow,
  ListHeader,
} from 'components'
import {useModuleAwareTranslation, useVariantModuleTranslation} from 'utils'
import {SettingsConfigurationElement} from '../types'
import {blueButton, white} from '../../../colors'
import {scenarioToModule} from '../../utils'
import {RobotModulesContext} from '../../../app/types'
import {getSettingsRouteForScenario} from '../utils'
import {useSelector} from 'react-redux'
import {Store} from 'types'

interface Props {
  configurations: SettingsConfigurationElement[]
  close: () => void
  run: (configurationId: string) => void
  setAutoOpenSelectConfig: (shouldAutoOpen: boolean) => void
}

const StyledList = styled(List)`
  margin-top: 32px;
`

const StyledListItem = styled(ListItem)`
  padding-left: 24px;
  padding-right: 24px;
  margin-left: -24px;
  margin-right: -24px;
`

const StyledTextButton = styled(TextButton)`&&&{
  min-width: auto;
  margin-right: -8px;
}`

const StyledDialogActions = styled(DialogActions)`
  padding: 8px 24px!important;
`

const SelectConfigurationModal = ({
  configurations,
  close,
  run,
  setAutoOpenSelectConfig,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const {tCreator} = useVariantModuleTranslation()
  const {currentModule} = useContext(RobotModulesContext)
  const {push} = useHistory()
  const configurationsWithNames = configurations
    .map(configuration => ({
      ...configuration,
      name: configuration.name || t('Nav_Schedule subtitle configurations default name', {index: configuration.index + 1}) || '',
    }))
    .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))

  const allowedConfigs = configurationsWithNames
    .filter(config => !config.scenario || scenarioToModule(config.scenario) === currentModule)
  const notAllowedConfigs = configurationsWithNames
    .filter(config => config.scenario && scenarioToModule(config.scenario) !== currentModule)
  const showHeaders = notAllowedConfigs.length > 0

  const guiUseCase = useSelector((state: Store) => state.app.guiUseCase)

  return (
    <Dialog
      open
      onClose={close}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <DialogTitle>
          {t('Nav_Status button Start patrol select')}
        </DialogTitle>
        <StyledList>
          {showHeaders && (
            <ListHeader>
              {t('Nav_Status select config current scenario', {
                scenario: t(`use-case ${currentModule}`),
              })}
            </ListHeader>
          )}
          {allowedConfigs.map(configuration => (
            <StyledListItem
              key={configuration.id}
              bolder
            >
              <ListItemRow>
                {guiUseCase !== 'airport' && <IconButton
                  color="primary"
                  onClick={() => {
                    setAutoOpenSelectConfig(true)
                    push(`${getSettingsRouteForScenario(configuration.scenario)}?id=${configuration.id}`)
                  }}
                >
                  <EditIcon />
                </IconButton>}
                <ListItemTextColumn>
                  {configuration.name}
                  <ListItemSubText>
                    {tCreator(scenarioToModule(configuration.scenario))('Nav_Schedule subtitle configurations selected', {
                      count: configuration.selected,
                      total: configuration.total,
                    })}
                  </ListItemSubText>
                </ListItemTextColumn>
              </ListItemRow>
              <StyledTextButton
                disabled={!configuration.selected}
                onClick={() => run(configuration.id)}
                data-testid={`run-config-${configuration.selected > 0 ? 'enabled' : 'disabled'}-${configuration.id}`}
              >
                {t('Nav_Status button Run')}
              </StyledTextButton>
            </StyledListItem>
          ))}
          {showHeaders && (
            <ListHeader $withTopMargin>
              {t('Nav_Status select config other scenario')}
            </ListHeader>
          )}
          {notAllowedConfigs.map(configuration => (
            <StyledListItem
              key={configuration.id}
              bolder
            >
              <ListItemTextColumn>
                {configuration.name}
                <ListItemSubText>
                  <div>{t(`use-case ${scenarioToModule(configuration.scenario)}`).toUpperCase()}</div>
                  {tCreator(scenarioToModule(configuration.scenario))('Nav_Schedule subtitle configurations selected', {
                    count: configuration.selected,
                    total: configuration.total,
                  })}
                </ListItemSubText>
              </ListItemTextColumn>
              <StyledTextButton
                disabled
                data-testid={`run-config-${configuration.selected > 0 ? 'enabled' : 'disabled'}-${configuration.id}`}
              >
                {t('Nav_Status button Run')}
              </StyledTextButton>
            </StyledListItem>
          ))}
        </StyledList>
      </DialogContent>
      <StyledDialogActions>
        <Button
          onClick={close}
          $backgroundColor={blueButton}
          $textColor={white}
        >
          {t('Common button cancel')}
        </Button>
      </StyledDialogActions>
    </Dialog>
  )
}

export default SelectConfigurationModal
