import {connect} from 'react-redux'
import {Store} from 'types'
import {fetchApiVersion} from './actions'
import AppVersion from './AppVersion'

const mapStateToProps = (state: Store) => ({
  isConnectedToServer: state.app.isConnectedToServer,
  apiData: state.app.apiData,
})

export default connect(mapStateToProps, {fetchApiVersion})(AppVersion)
