import styled from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import {lightGray, lightestGray, grayText} from '../colors'

export const List = styled.section`
  ${(props: {isNested?: boolean}) => props.isNested ? 'padding-left: 32px;' : ''}
`

export const ListHeader = styled.header`
  text-transform: uppercase;
  color: ${grayText};
  font-size: 0.875rem;
  margin-bottom: 6px;
  ${(props: {$withTopMargin?: boolean, $centered?: boolean}) => props.$withTopMargin ? 'margin-top: 32px;' : ''}
  ${(props: {$withTopMargin?: boolean, $centered?: boolean}) => props.$centered ? `
    display: flex;
    flex-direction: row;
    align-items: center;
  ` : ''}
`

interface ListItemProps {
  clickable?: boolean
  bolder?: boolean
  noBorder?: boolean
  disabled?: boolean
  isSelected?: boolean
  fullWidth?: boolean
  fullWidthModal?: boolean
}

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  border-bottom: 1px solid ${lightGray};
  background-color: ${(props: ListItemProps) => props.isSelected ? lightestGray : 'inherit'};
  font-weight: ${(props: ListItemProps) => props.bolder ? '500' : 'inherit'};
  padding-top: 3px;
  padding-bottom: 3px;
    
  ${(props: ListItemProps) => {
    if (props.clickable && !props.disabled) {
      return `
        cursor: pointer;
        &:hover {
            background-color: ${lightestGray};
        }
      `
    }
  }}   
  
  ${(props: ListItemProps) => {
    if (props.disabled) {
      return `
        cursor: default;
        opacity: 0.7;
      `
    }
  }}
  
  ${(props: ListItemProps) => {
    if (props.noBorder) {
      return `
        border: none;
      `
    }
  }}
    
  ${(props: ListItemProps) => {
    if (props.fullWidth) {
      return `
        padding-left: 16px;
        padding-right: 16px;
        margin-left: -16px;
        margin-right: -16px;
      `
    }
  }}
    
  ${(props: ListItemProps) => {
    if (props.fullWidthModal) {
      return `
        padding-left: 24px;
        padding-right: 24px;
        margin-left: -24px;
        margin-right: -24px;
      `
    }
  }}
`

export const GoToElementIcon = styled(ChevronRightIcon)`
    flex-shrink: 0;
    font-size: 30px!important;
`

export const ListItemText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  .MuiSvgIcon-root:first-child {
    margin-right: 8px;
  }
`

export const ListItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex: 1;
  margin: 6px 8px 6px 0;
  
  > .MuiButtonBase-root:first-child {
    margin-right: 5px;
  }
`

export const ListItemTextRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  .MuiSvgIcon-root:last-child {
    margin-left: 8px;
  }
`

export const ListItemTextColumn = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

export const ListItemSubText = styled.div`
  font-size: 0.75rem;
  color: ${grayText};
  font-weight: 400;
  margin-top: 3px;
`

export const ListImage = styled.img`
  width: 36px;
  height: auto;
  margin-right: 8px;
`
