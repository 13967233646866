import React from 'react'
import BugReportIcon from '@material-ui/icons/BugReport'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'
import {PERMISSIONS} from '../constants'
import {useModuleAwareTranslation} from '../utils'
import FeatureToggle from './FeatureToggle'
import {Button} from './BasicElementsStyled'
import {darkButton} from '../colors'

export interface Props {
  robotId?: string
}

const ReportButton = styled(Button)`
.MuiSvgIcon-root {
    left: -5px;
    position: relative;
}
`

const OpenBugReportButton = ({robotId}: Props) => {
  const {t} = useModuleAwareTranslation()
  const {push} = useHistory()
  if (!robotId) {
    return null
  }

  return (
    <FeatureToggle permissionName={PERMISSIONS.SEND_REPORT}>
      <ReportButton
        onClick={() => {
          push(`/patrol/issue/${robotId}`)
        }}
        $bold
        $textColor={darkButton}
      >
        <BugReportIcon />
        {t('Robot configuration bug report open')}
      </ReportButton>
    </FeatureToggle>
  )
}

export default OpenBugReportButton
