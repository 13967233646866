import {AnyAction, combineReducers} from 'redux'
import {constants as appConstants} from 'app'
import * as constants from './constants'
import {
  ScheduleByRobot,
  SettingsByRobot,
} from './types'
import {reducer as report} from './report'
import {reducer as siteSettings} from './siteSettings'
import {reducer as patrolEvents} from './patrolEvents'
import {reducer as dashboard} from './dashboard'
import {reducer as reminders} from './reminders'
import {reducer as schedule} from './schedule'
import {ADD_EVENT} from './constants'

const finishedPatrolId = (state: string | null = null, action: AnyAction): string | null => {
  switch (action.type) {
    case constants.SET_PATROL_FINISHED:
      return action.patrolId
    case appConstants.NAVIGATION:
    case constants.CHANGE_STATE:
    case constants.CHANGE_STATE_START:
      return null
    default:
      return state
  }
}

const viewedEvents = (state: string[] = [], action: AnyAction): string[] => {
  switch (action.type) {
    case constants.MARK_EVENT_AS_VIEWED:
      return [...state, action.eventId]
    default:
      return state
  }
}

const isLoadingSchedule = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.SCHEDULE_GET_ITEMS:
      return true
    case constants.SCHEDULE_GET_ITEMS_FINISHED:
      return false
    default:
      return state
  }
}

const scheduleByRobot = (state: ScheduleByRobot = {}, action: AnyAction): ScheduleByRobot => {
  switch (action.type) {
    case constants.SCHEDULE_ITEM_UPDATE_FINISHED:
    case constants.SCHEDULE_GET_ITEMS_FINISHED:
    case constants.SET_SCHEDULE:
      return action.schedule ? {
        ...state,
        [action.robotId]: {
          ...action.schedule,
        },
      } : state
    case constants.SCHEDULE_ORDER_UPDATE:
      return state[action.robotId]?.scheduleItems ? {
        ...state,
        [action.robotId]: {
          ...state[action.robotId],
          scheduleItems: state[action.robotId]?.scheduleItems.map((item, index) => ({
            ...item,
            uiOrder: action.newOrder.find(({id}: {id: string}) => item.id === id)?.newIndex,
          })),
        },
      } : state
    default:
      return state
  }
}

const isSavingSchedule = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.SCHEDULE_UPDATE_REQUEST_START:
      return true
    case constants.SCHEDULE_UPDATE_REQUEST_FINISH:
      return false
    default:
      return state
  }
}

const isLoadingSettings = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.SETTINGS_GET_ITEMS:
      return true
    case constants.SETTINGS_GET_ITEMS_FINISHED:
      return false
    default:
      return state
  }
}

const settingsByRobot = (state: SettingsByRobot = {}, action: AnyAction): SettingsByRobot => {
  switch (action.type) {
    case constants.SETTINGS_GET_ITEMS_FINISHED:
      return action.settings ? {
        ...state,
        [action.robotId]: [
          ...action.settings,
        ],
      } : state
    case constants.CONFIG_LIST_UPDATED:
      return action.configurationUids? {
        ...state,
        [action.robotId]: (state[action.robotId] || [])
          .filter(item => action.configurationUids.includes(item.configurationUid)),
      } : state
    case constants.SETTINGS_ITEM_TOGGLE_FINISHED:
    case constants.SET_SETTINGS: {
      const currentItems = (state[action.robotId] || [])
      if (action.settings && currentItems.some(item => item.configurationUid === action.configurationUid)) {
        return {
          ...state,
          [action.robotId]: currentItems.map(item => item.configurationUid === action.configurationUid ?
            {
              ...item,
              name: action.name || item.name,
              homeUid: action.homeUid || action.fullSettings?.homeUid || item.homeUid,
              ...action.settings,
            } :
            item
          ),
        }
      }

      if (action.fullSettings && !currentItems.some(item => item.configurationUid === action.configurationUid)) {
        return {
          ...state,
          [action.robotId]: [...currentItems, {
            configurationUid: action.configurationUid,
            name: action.name,
            ...action.fullSettings,
          }],
        }
      }

      return state
    }
    default:
      return state
  }
}

const robotsWithPendingChanges = (state: string[] = [], action: AnyAction): string[] => {
  switch (action.type) {
    case constants.CHANGE_STATE:
    case constants.CHANGE_STATE_START:
      return [...state, action.robotId]
    case constants.CHANGE_STATE_FINISHED:
      return state.filter(robotId => robotId !== action.robotId)
    default:
      return state
  }
}

const isSavingEventNote = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.SAVE_EVENT_NOTE:
      return true
    case constants.SAVE_EVENT_NOTE_FAILED:
    case constants.SAVE_EVENT_NOTE_SUCCESS:
      return false
    default:
      return state
  }
}

const eventSaveSuccess = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.SAVE_EVENT_NOTE:
      return false
    case constants.SAVE_EVENT_NOTE_SUCCESS:
      return true
    default:
      return state
  }
}

const isShowingPreconditions = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.SET_IS_SHOWING_PRECONDITIONS:
      return action.isShowingPreconditions
    default:
      return state
  }
}

const autoOpenSelectConfiguration = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case ADD_EVENT:
      if (action.event.isAssistance) {
        return false
      } else {
        return state
      }
    case constants.SET_AUTO_OPEN_SELECT_CONFIGURATION:
      return action.autoOpenSelectConfiguration
    default:
      return state
  }
}

const videoData = (state: {url: string, title: string} | null = null, action: AnyAction): {url: string, title: string} | null => {
  switch (action.type) {
    case constants.SET_VIDEO_URL:
      return action.url ? {
        url: action.url,
        title: action.title,
      } : null
    default:
      return state
  }
}

export default combineReducers({
  autoOpenSelectConfiguration,
  isSavingSchedule,
  isLoadingSchedule,
  isLoadingSettings,
  isSavingEventNote,
  isShowingPreconditions,
  report,
  finishedPatrolId,
  robotsWithPendingChanges,
  scheduleByRobot,
  settingsByRobot,
  eventSaveSuccess,
  siteSettings,
  viewedEvents,
  dashboard,
  patrolEvents,
  reminders,
  schedule,
  videoData,
})
