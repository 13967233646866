import React, {useCallback} from 'react'
import {CardContent} from '@material-ui/core'
import moment from 'moment'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import CircularProgress from '@material-ui/core/CircularProgress'
import Switch from '@material-ui/core/Switch'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {getDateAndTimeFormatForMoment, getStatusLocalizationKey, isLimitFindingInProgress} from 'utils'
import {
  CardContentFullWidth,
  CardTitle,
  LimitFindingDetailedErrorsTableRows,
  EngineeringTableWrapper,
  FeatureToggle,
} from 'components'
import {LoaderWrapper} from './DashboardStyled'
import ManageJointsControlContainer from './ManageJointsControlContainer'
import {RobotStatusCardProps} from './types'
import {RobotState} from '../types'
import {PERMISSIONS} from '../constants'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const SoundRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 44px;
  
  .MuiCircularProgress-root {
    margin: 0 19px;
  }
`

const SoundTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  
  svg {
    margin-left: 8px;
  }
`

const RobotStatusCard = ({
  isChangingManualMode,
  robot,
  setManualControl,
  isMuted,
  isChangingSound,
  setSoundPreferences,
  userId,
}: RobotStatusCardProps) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const changeManualControl = useCallback(() => {
    if (robot) {
      setManualControl(robot, robot.healthStatus.robotState !== RobotState.MANUAL_CONTROL)
    }
  }, [robot, setManualControl])
  const changeAutonomousMode = useCallback(() => {
    if (robot) {
      setManualControl(robot, robot.healthStatus.robotState !== RobotState.AUTONOMOUS_CONTROL)
    }
  }, [robot, setManualControl])

  const manualModeChangeInProgress = isChangingManualMode

  const canChangeRobotState = !isLimitFindingInProgress(robot?.healthStatus.limitFindingStatus)
  const dateAndTimeFormat = getDateAndTimeFormatForMoment(userId)

  return (
    <Card>
      <CardContent>
        <CardTitle>{t('Dashboard Robot status')}</CardTitle>
        <CardContentFullWidth>
          {robot ? (
            <EngineeringTableWrapper>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell variant="head" align="right">{t('Dashboard Name')}</TableCell>
                    <TableCell>{robot?.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">{t('Dashboard Last update at')}</TableCell>
                    <TableCell>
                      {robot?.lastKeepAliveReceivedAt ?
                        moment(robot.lastKeepAliveReceivedAt).format(dateAndTimeFormat) :
                        null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">{t('Dashboard Joints')}</TableCell>
                    <TableCell>
                      {robot?.joints.length > 0 ? (
                        <>
                          <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                              {robot?.joints.slice(0, 2).join(', ')},
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              {robot?.joints.slice(2).join(', ')}
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <ManageJointsControlContainer />
                        </>
                      ) : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">{t('Dashboard Battery level')}</TableCell>
                    <TableCell>
                      {robot?.healthStatus.batteryLevel}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">{t('Dashboard Power on self test')}</TableCell>
                    <TableCell data-testid="statusCardPowerOnValue">
                      {t(getStatusLocalizationKey(robot?.healthStatus.powerOnSelfTestStatus))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">{t('Dashboard Limit finding status')}</TableCell>
                    <TableCell data-testid="statusCardLimitFindingValue">
                      {robot?.healthStatus.limitFindingStatus || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">{t('Dashboard Robot state')}</TableCell>
                    <TableCell>
                      {robot?.healthStatus.robotState}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">{t('Dashboard State machine')}</TableCell>
                    <TableCell>
                      {robot?.diagnosticData?.stateMachine?.rawState}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">{t('Dashboard Manual control')}</TableCell>
                    <TableCell>
                      {manualModeChangeInProgress ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Switch
                          checked={robot?.healthStatus.robotState === RobotState.MANUAL_CONTROL}
                          onChange={changeManualControl}
                          color="primary"
                          disabled={!canChangeRobotState}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">{t('Dashboard Autonomous mode enabled')}</TableCell>
                    <TableCell>
                      {manualModeChangeInProgress ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Switch
                          checked={robot?.healthStatus.robotState === RobotState.AUTONOMOUS_CONTROL}
                          onChange={changeAutonomousMode}
                          color="primary"
                          disabled={!canChangeRobotState}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <LimitFindingDetailedErrorsTableRows />
                  <FeatureToggle permissionName={PERMISSIONS.ROBOT.SET_SOUND}>
                    <TableRow>
                      <TableCell variant="head" align="right">
                        <SoundTitle>
                          {t('Dashboard mute status')}
                          {isMuted && <VolumeOffIcon />}
                          {isMuted === false && <VolumeUpIcon />}
                        </SoundTitle>
                      </TableCell>
                      <TableCell>
                        <SoundRow>
                          {isMuted === undefined ? (
                            <div>unknown</div>
                          ) : (
                            <>
                              {isChangingSound ? (
                                <CircularProgress size={20} />
                              ) : (
                                <Switch
                                  checked={!isMuted}
                                  onChange={() => setSoundPreferences(!isMuted)}
                                  color="primary"
                                  disabled={!canChangeRobotState || isChangingSound}
                                />
                              )}
                            </>
                          )}
                        </SoundRow>
                      </TableCell>
                    </TableRow>
                  </FeatureToggle>
                </TableBody>
              </Table>
            </EngineeringTableWrapper>
          ) : (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          )}
        </CardContentFullWidth>
      </CardContent>
    </Card>
  )
}

export default RobotStatusCard
