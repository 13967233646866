import {connect} from 'react-redux'
import {Store} from 'types'
import {getMissionMonitor} from '../selectors'
import PatrolFinishedRedirector from './PatrolFinishedRedirector'

const mapStateToProps = (state: Store) => {
  const missionMonitor = getMissionMonitor(state)
  return {
    patrolId: missionMonitor?.missionUid,
  }
}

export default connect(mapStateToProps)(PatrolFinishedRedirector)
