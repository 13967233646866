import React, {useState} from 'react'
import {getTimeFormatForMoment, useModuleAwareTranslation} from 'utils'
import styled from 'styled-components'
import moment from 'moment'
import {ReminderSettings} from 'types'
import {List, ListHeader, ListItem, SimpleSwitch, TimePickerModal} from 'components'
import {REMINDERS} from '../constants'

const Time = styled.div`
`

interface Props {
  isConnectedToServer: boolean
  isSaving: boolean
  showReminders: boolean
  userId?: string
  reminderSettings: ReminderSettings | null
  saveReminderSettings: (reminderName: string, reminderValue: boolean | Date) => void
}

const Reminders = ({
  isConnectedToServer,
  showReminders,
  reminderSettings,
  isSaving,
  saveReminderSettings,
  userId,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const [currentReminder, setCurrentReminder] = useState('')
  if (!showReminders) {
    return null
  }

  const userReminderSettings: ReminderSettings = reminderSettings || {enabled: false}
  const remindersEnabled = Boolean(userReminderSettings.enabled)
  const disabled = isSaving || !isConnectedToServer
  const timeFormat = getTimeFormatForMoment(userId)
  return (
    <>
      <List>
        <ListHeader $withTopMargin>
          {t('Nav_Settings reminders title')}
        </ListHeader>
        <ListItem
          fullWidth
          bolder
        >
          <span>{t('Nav_Settings reminders toggle')}</span>
          <SimpleSwitch
            disabled={disabled}
            checked={remindersEnabled}
            onChange={() => saveReminderSettings('enabled', !remindersEnabled)}
          />
        </ListItem>
        {REMINDERS.map(reminder => (
          <ListItem
            key={reminder.key}
            fullWidth
            bolder
            clickable={!disabled}
            disabled={disabled || !remindersEnabled}
            onClick={disabled || !remindersEnabled ? undefined : () => setCurrentReminder(reminder.key)}
          >
            <span>{t(`Nav_Settings reminders ${reminder.translationKey} reminder`)}</span>
            <Time>
              {userReminderSettings[reminder.key] ?
                moment(userReminderSettings[reminder.key] as Date).format(timeFormat) :
                <>&mdash;</>
              }
            </Time>
          </ListItem>
        ))}
      </List>
      <TimePickerModal
        cancel={() => setCurrentReminder('')}
        initialDate={currentReminder && userReminderSettings[currentReminder] ?
          new Date(userReminderSettings[currentReminder] as string) :
          new Date()
        }
        isOpen={Boolean(currentReminder)}
        save={(date: Date) => {
          if (currentReminder) {
            saveReminderSettings(currentReminder, date)
          }
          setCurrentReminder('')
        }}
      />
    </>
  )
}

export default Reminders
