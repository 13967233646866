/* eslint-disable @typescript-eslint/no-explicit-any */
export const emptyObject = {}
Object.freeze(emptyObject)

function isObject(object: any) {
  return object != null && typeof object === 'object'
}

function deepEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false
    }
  }

  return true
}

export const areObjectsTheSame = (object1: any, object2: any) => {
  if (object1 === object2) {
    return true
  } else if (Array.isArray(object1) && Array.isArray(object2)) {
    return object1.length === object2.length && object1.every((item, index) => item === object2[index])
  } else if (isObject(object1) && isObject(object2)) {
    return deepEqual(object1, object2)
  }

  return false
}
