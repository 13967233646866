import React from 'react'
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon'
import {white} from '../../colors'

const LightningIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 10 23" {...props}>
    <path
      d="M3.66667 22V13.1154H1L6.33333 1V9.88461H9L3.66667 22Z"
      stroke={white}
      strokeWidth={0.6}
    />
  </SvgIcon>
)

export default LightningIcon
