import React from 'react'
import {Title} from 'components'

const Tools = () => {
  return (
    <Title>TODO</Title>
  )
}

export default Tools
