import React, {useState, useEffect, useCallback, SyntheticEvent} from 'react'
import TextField from '@material-ui/core/TextField'
import {Button, Dialog, DialogContent, DialogActions, DialogFormTitle} from 'components'
import {isLatinCharacter} from 'utils'
import {blueButton, white} from '../../../colors'
import {MAX_LOCATION_NAME_LATIN_CHARACTERS} from '../../../constants'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  cancel: () => void
  initialTranslation?: string
  isOpen: boolean
  save: (translation: string) => void
}

const LocationEditModal = ({
  cancel,
  initialTranslation,
  isOpen,
  save,
}: Props) => {
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const [translation, setTranslation] = useState(initialTranslation || '')
  const [error, setError] = useState('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTranslation(event.target.value)
    setError('')
  }

  useEffect(() => {
    setTranslation(initialTranslation || '')
    setError('')
  }, [isOpen, initialTranslation])

  const confirm = useCallback((event: SyntheticEvent) => {
    event.preventDefault()
    const trimmedTranslation = translation.trim()
    const isEmpty = trimmedTranslation.length === 0
    if (isEmpty) {
      setError('empty')
      return
    }

    if (trimmedTranslation === initialTranslation) {
      cancel()
      return
    }

    const letterCount = trimmedTranslation.split('').reduce((count, character) => {
      return count + (isLatinCharacter(character) ? 1 : 2)
    }, 0)

    if (letterCount > MAX_LOCATION_NAME_LATIN_CHARACTERS) {
      setError('length')
      return
    }

    save(trimmedTranslation)
  }, [save, translation, initialTranslation])

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={cancel}
    >
      <form onSubmit={confirm}>
        <DialogContent>
          <DialogFormTitle>
            {t('Nav_SiteConfiguration title site configuration locations edit title')}
          </DialogFormTitle>
          <TextField
            autoFocus
            error={Boolean(error)}
            type="text"
            fullWidth
            helperText={error ?
              t(`Nav_SiteConfiguration content site configuration locations edit error ${error}`) :
              t('Nav_SiteConfiguration content site configuration locations edit help text')
            }
            onChange={handleChange}
            value={translation}
          />
          <DialogActions $noHorizontalPadding>
            <Button onClick={cancel}>
              {t('Common button cancel')}
            </Button>
            <Button
              type="submit"
              $backgroundColor={blueButton}
              $textColor={white}
            >
              {t('Common button save')}
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  )
}

export default LocationEditModal
