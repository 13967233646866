import {combineReducers, AnyAction} from 'redux'
import {Robot, SnackbarMessage, Permission} from 'types'
import {uniqueId} from 'utils'
import * as constants from './constants'
import {getNewestEventDate} from './utils'
import {Announcement} from '../components/types'
import {NAVIGATION} from './constants'

const permissions = (state: Permission[] | null = null, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_PERMISSIONS:
      return action.permissions
    default:
      return state
  }
}

const pageOptions = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_PAGE_OPTIONS:
      return action.options
    default:
      return state
  }
}

const siteId = (state: string | null = null, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_SITE_ID:
      return action.siteId
    default:
      return state
  }
}

const apiData = (state: {} | null = null, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_API_VERSION:
      return action.data
    default:
      return state
  }
}

const isConnectedToServer = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_SERVER_CONNECTION_STATUS:
      return action.isConnected
    default:
      return state
  }
}

const deviceOfflineSince = (state = window.navigator.onLine ? null : new Date(), action: AnyAction) => {
  switch (action.type) {
    case constants.SET_DEVICE_ONLINE_STATUS:
      return action.isOnline ? null : (state || new Date())
    default:
      return state
  }
}

const connectionToServerChangedAt = (state = new Date(), action: AnyAction) => {
  switch (action.type) {
    case constants.SET_SERVER_CONNECTION_STATUS:
      return new Date()
    default:
      return state
  }
}

const robots = (state = [], action: AnyAction) => {
  switch (action.type) {
    case constants.FETCH_ROBOTS_SUCCESS:
      return action.robots
    case constants.ROBOT_UPDATED:
      return state.some((robot: Robot) => robot.id === action.robot.id && robot.siteId === action.robot.siteId) ?
        state.map((robot: Robot) => robot.id === action.robot.id && robot.siteId === action.robot.siteId ? action.robot : robot) :
        [...state, action.robot]
    default:
      return state
  }
}

const offlineCounter = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_OFFLINE_ROBOTS:
      return 0
    case constants.INCREMENT_OFFLINE_COUNTER:
      return state + 1
    default:
      return state
  }
}

const offlineRobots = (state: string[] = [], action: AnyAction) => {
  switch (action.type) {
    case constants.SET_OFFLINE_ROBOTS:
      return action.offlineRobotsKeys
    default:
      return state
  }
}

const triedToFetchRobots = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.FETCH_ROBOTS_SUCCESS:
    case constants.FETCH_ROBOTS_ERROR:
    case constants.ROBOT_UPDATED:
      return true
    default:
      return state
  }
}

const selectedRobot = (state: Robot | null = null, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_SELECTED_ROBOT:
      return action.robot
    case constants.ROBOT_UPDATED:
      return (state && state.id === action.robot.id) ? action.robot : state
    default:
      return state
  }
}

const newestEventDateByRobot = (state: {[robotId: string]: Date | undefined} = {}, action: AnyAction) => {
  switch (action.type) {
    case constants.FETCH_ROBOTS_SUCCESS:
      return (action.robots || []).reduce((result: {[robotId: string]: Date}, robot: Robot) => ({
        ...result,
        [robot.id]: new Date(),
      }), {})
    case constants.ROBOT_UPDATED:
      return {
        ...state,
        [action.robot.id]: getNewestEventDate(action.robot, state[action.robot.id]),
      }
    default:
      return state
  }
}

const messages = (state: SnackbarMessage[] = [], action: AnyAction) => {
  switch (action.type) {
    case constants.ADD_SNACKBAR_MESSAGE:
      return [...state, {
        id: uniqueId('snackbar'),
        message: action.message,
        params: action.params,
        autoHideDuration: action.autoHideDuration,
        action: action.action,
      }]
    case constants.REMOVE_SNACKBAR_MESSAGE: {
      const newArray = [...state]
      newArray.shift()
      return newArray
    }
    default:
      return state
  }
}

const updateAvailable = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.UPDATE_AVAILABLE:
      return true
    default:
      return state
  }
}

const windowHeight = (state = window.innerHeight, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_WINDOW_DIMENSIONS:
      return action.height
    default:
      return state
  }
}

const windowWidth = (state = window.innerWidth, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_WINDOW_DIMENSIONS:
      return action.width
    default:
      return state
  }
}

const isRobotSelectorOpen = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.OPEN_ROBOT_SELECTOR:
      return true
    case constants.CLOSE_ROBOT_SELECTOR:
      return false
    default:
      return state
  }
}

const bugReportUid = (state = '', action: AnyAction) => {
  switch (action.type) {
    case constants.SET_BUG_REPORT_UID:
      return action.reportUid || ''
    default:
      return state
  }
}

const isRobotEditOpen = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.OPEN_ROBOT_EDIT:
      return true
    case constants.CLOSE_ROBOT_EDIT:
      return false
    default:
      return state
  }
}

const bugReportData = (state: {[field: string]: string | boolean} = {}, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_BUG_REPORT_DATA:
      return action.data
    default:
      return state
  }
}

const bugReportFiles = (state: {[form: string]: File[]} = {}, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_BUG_REPORT_FILES:
      return {
        ...state,
        [action.form]: action.files,
      }
    case constants.SET_BUG_REPORT_DATA:
      // clear files when empty form is initialized
      return Object.keys(action.data).length === 0 ? {} : state
    default:
      return state
  }
}

const isSavingBugReport = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.SAVE_BUG_REPORT:
      return true
    case constants.SAVE_BUG_REPORT_FINISHED:
      return false
    default:
      return state
  }
}

const childModules = (state: {[module: string]: string[]} = {}, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_CHILD_MODULE:
      return {
        ...state,
        [action.module]: [
          ...(state[action.module] || []),
          action.child,
        ],
      }
    default:
      return state
  }
}

const supportedLanguages = (state: string[] = [], action: AnyAction) => {
  switch (action.type) {
    case constants.SET_SUPPORTED_LANGUAGES:
      return action.languages
    default:
      return state
  }
}

const announcements = (state: Announcement[] = [], action: AnyAction) => {
  switch (action.type) {
    case constants.ADD_ANNOUNCEMENT:
      return state.some(announcement => (
        announcement.id === action.announcement.id &&
        announcement.siteId === action.announcement.siteId &&
        announcement.robotId === action.announcement.robotId
      )) ?
        state :
        [...state, action.announcement]
    case constants.REMOVE_ANNOUNCEMENT:
      return state.filter(announcement => (
        announcement.id !== action.announcementId &&
        announcement.siteId !== action.siteId &&
        announcement.robotId !== action.robotId
      ))
    default:
      return state
  }
}

const isSecondaryNavOpen = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case constants.OPEN_SECONDARY_NAV:
      return true
    case constants.CLOSE_SECONDARY_NAV:
    case NAVIGATION:
      return false
    default:
      return state
  }
}

const guiUseCase = (state= '', action: AnyAction) => {
  switch (action.type) {
    case constants.SET_GUI_USE_CASE:
      return action.guiUseCase
    default:
      return state
  }
}

export default combineReducers({
  announcements,
  apiData,
  isSecondaryNavOpen,
  isSavingBugReport,
  bugReportUid,
  bugReportData,
  bugReportFiles,
  connectionToServerChangedAt,
  isConnectedToServer,
  isRobotEditOpen,
  isRobotSelectorOpen,
  deviceOfflineSince,
  messages,
  pageOptions,
  permissions,
  robots,
  selectedRobot,
  childModules,
  siteId,
  newestEventDateByRobot,
  supportedLanguages,
  triedToFetchRobots,
  updateAvailable,
  offlineCounter,
  offlineRobots,
  windowHeight,
  windowWidth,
  guiUseCase,
})
