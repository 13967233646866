const ACTION_PREFIX = 'nsp/report/'

export const FETCH_PATROL_DETAILS = `${ACTION_PREFIX}FETCH_PATROL_DETAILS`
export const PATROL_DETAILS_LOADED = `${ACTION_PREFIX}PATROL_DETAILS_LOADED`
export const SET_PATROL_DETAILS = `${ACTION_PREFIX}SET_PATROL_DETAILS`

export const CLEAR_PATROL_LIST = `${ACTION_PREFIX}CLEAR_PATROL_LIST`
export const FETCH_PATROL_LIST = `${ACTION_PREFIX}FETCH_PATROL_LIST`
export const FETCH_PATROL_LIST_SUCCESS = `${FETCH_PATROL_LIST}_SUCCESS`
export const FETCH_PATROL_LIST_ERROR = `${FETCH_PATROL_LIST}_ERROR`

export const SET_HISTORY_TAB_INDEX = `${ACTION_PREFIX}SET_HISTORY_TAB_INDEX`
