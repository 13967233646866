import React, {useContext, useEffect, useState} from 'react'
import {RobotModulesContext} from 'app/types'
import {executeHooks} from 'framework'
import {Loader, Tabs} from 'components'
import styled from 'styled-components'
import {PageSubtitle, PageTitle} from '../../NSPCommonStyled'
import {HistoryListTabsParams} from '../../types'
import {NAV_HOOK_LABELS} from '../../constants'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const StyledTabs = styled(Tabs)`
  margin: 0 -16px;
  
  + .report-details {
    margin-top: 24px;
  }
`

interface Props {
  tabIndex: number
  setHistoryTabIndex: (index: number) => void
  hasConfigs: boolean
  configModules: string[]
  userId?: string
}

const ReportList = ({
  tabIndex,
  setHistoryTabIndex,
  hasConfigs,
  configModules,
}: Props) => {
  const {currentModule} = useContext(RobotModulesContext)
  const [historyListTabData, setHistoryListTabData] = useState<HistoryListTabsParams | undefined>()
  useEffect(() => {
    const allModules = !currentModule || configModules.includes(currentModule) ?
      configModules :
      [...configModules, currentModule]

    const hookContext: HistoryListTabsParams = {
      tabs: [],
      configModules: allModules,
    }
    executeHooks(NAV_HOOK_LABELS.REPORT.GET_HISTORY_TABS, hookContext)
    setHistoryListTabData(hookContext)
  }, [currentModule, configModules])
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const numberOfTabs = historyListTabData?.tabs.length || 0
  useEffect(() => {
    if (setHistoryTabIndex && (numberOfTabs - 1) < tabIndex) {
      setHistoryTabIndex(0)
    }
  }, [setHistoryTabIndex, numberOfTabs, tabIndex, hasConfigs])

  const ComponentToShow = historyListTabData?.tabs[tabIndex]?.component

  return (
    <>
      <PageTitle>{t('Nav_History title patrol history title')}</PageTitle>
      <PageSubtitle>
        {t('Nav_History content patrol history description')}
      </PageSubtitle>
      {hasConfigs && historyListTabData ? (
        <>
          {numberOfTabs > 1 && (
            <StyledTabs
              tabs={historyListTabData.tabs.map(tab => ({
                name: t(tab.titleKey),
              }))}
              tabIndex={tabIndex}
              setTabIndex={setHistoryTabIndex}
            />
          )}
          {ComponentToShow && <ComponentToShow />}
        </>
      ) : <Loader withTopMargin centered />}
    </>
  )
}

export default ReportList
