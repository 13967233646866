import React from 'react'
import {lightestGray, lightText, white} from '../colors'
import styled from 'styled-components'

const TileGridItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  background: ${(props: {disabled?: boolean}) => props.disabled ? lightestGray : white};
  border-radius: 3px;
  text-align: center;
  min-width: 100px;
  cursor: ${(props: {disabled?: boolean}) => props.disabled ? 'default' : 'pointer'};
`

const TileGridItemTexts = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70px;
`

const TileGridItemText = styled.div`
  font-size: 1.125rem;
`

const TileGridItemHelp = styled.div`
  color: ${lightText};
  font-size: 0.625rem;
  font-weight: 500;
  margin-top: 3px;
`

const TileGridItemSummary= styled.div`
  color: ${lightText};
  font-size: 1.125rem;
  margin-top: 10px;
  font-weight: 500;
`

interface TileItemProps {
  disabled?: boolean
  text: string
  help?: string
  summary?: string
  summaryColor?: string
  onClick?: () => void
}

const TileItem = ({
  disabled,
  text,
  help,
  summary,
  summaryColor,
  onClick,
}: TileItemProps) => {
  return (
    <TileGridItemWrapper
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
    >
      <TileGridItemTexts>
        <TileGridItemText>{text}</TileGridItemText>
        {help && (
          <TileGridItemHelp>{help}</TileGridItemHelp>
        )}
      </TileGridItemTexts>
      {summary && (
        <TileGridItemSummary style={{color: summaryColor}}>
          {summary}
        </TileGridItemSummary>
      )}
    </TileGridItemWrapper>
  )
}

export {
  TileItem,
}
