import {buildAction} from 'utils'
import * as constants from './constants'

export const fetchPointDetails = buildAction(constants.FETCH_POINT_DETAILS, 'pointId')
export const pointDetailsLoaded = buildAction(constants.POINT_DETAILS_LOADED)
export const setPointDetails = buildAction(constants.SET_POINT_DETAILS, 'pointDetails')

export const setHistoryType = buildAction(constants.SET_HISTORY_TYPE, 'historyType')

export const fetchUvPatrols = buildAction(constants.FETCH_UV_PATROLS, 'page', 'module')
