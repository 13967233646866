import React, {useState} from 'react'
import {History} from 'history'
import {useHistory} from 'react-router-dom'
import {LimitFindingStatus, RobotState} from 'types'
import styled from 'styled-components'
import {Button, EventFrame, GenericEventDisplay, isClientSideEvent, Loader, showEventsBasedOnState} from 'components'
import {isLimitFindingInProgress} from 'utils'
import * as Styled from '../NSPCommonStyled'
import {LimitFindingErrorModalContainer} from 'limitFindingWizard'
import {ActionsWrapper} from '../shared'
import {blueButton, white} from '../../../colors'
import {DiagnosticEvent, Locations, StateMachineGroup} from '../types'
import PatrolActionContainer from './PatrolActionContainer'
import PrepatrolImageContainer from './PrepatrolImageContainer'
import LimitFindingPreconditions from './LimitFindingPreconditions'
import MoreProblemsPreviewContainer from './MoreProblemsPreviewContainer'
import NoConnectionScreen from './NoConnectionScreen'
import InitializingScreen from './InitializingScreen'
import {PatrolLocation} from '../location'
import {EventVideoTutorial} from '../shared'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

interface Props {
  isClientEvent: boolean
  stateGroup?: StateMachineGroup
  children: React.ReactNode
  canDebug?: boolean
  event?: DiagnosticEvent
  disableManualControl: () => void
  isRobotOnline: boolean
  runningManualControlOperations: boolean
  limitFinding?: LimitFindingStatus
  robotState?: RobotState
  triedToFetchRobots: boolean
  initializationFailedClientMessage?: string
  initializationFailedMessage?: string
  showIncompleteCalibration: boolean
  shouldDisableLimitFinding: boolean
  locations?: Locations
  runLimitFinding: (history: History) => void
}

const EventLocation = styled(PatrolLocation)`
  margin: 12px 0;
`

// TODO: change to some proper images once we have them!
const limitFindingImage = '/nsp/waiting.gif'
const manualControlImage = '/nsp/waiting.gif'
const robotInitializingImage = '/nsp/waiting.gif'

const WideButton = styled(Button)`
  width: calc(100% - 32px);
  max-width: 360px;
  min-height: 42px;
`

const getDescriptionKey = (
  limitFinding: LimitFindingStatus,
  robotState: RobotState,
  runningManualControlOperation: boolean
): string => {
  if (isLimitFindingInProgress(limitFinding)) {
    return 'PowerOn_CalibrationWizard content power on calibration in progress'
  }

  if (limitFinding !== LimitFindingStatus.OK) {
    return ''
  }

  if (runningManualControlOperation) {
    return 'PowerOn_CalibrationWizard content Disabling Manual Control is in progress Please wait'
  }

  if (robotState === RobotState.MANUAL_CONTROL) {
    return 'PowerOn_CalibrationWizard content Tap the button below to disable Manual Control'
  }

  return ''
}

const getButtonConfig = (props: Props, history: History) => {
  const {
    limitFinding,
    disableManualControl,
    runLimitFinding,
    robotState,
    shouldDisableLimitFinding,
  } = props
  if (
    isLimitFindingInProgress(limitFinding) ||
    robotState === RobotState.INITIALIZING
  ) {
    return
  }

  if (limitFinding !== LimitFindingStatus.OK) {
    return shouldDisableLimitFinding ? undefined : {
      action: () => runLimitFinding(history),
      text: 'PowerOn_CalibrationWizard button power on calibration start',
    }
  }

  if (robotState === RobotState.MANUAL_CONTROL) {
    return {
      action: disableManualControl,
      text: 'PowerOn_CalibrationWizard button Disable manual control',
    }
  }

  return
}

const getImage = (
  robotState?: RobotState,
  limitFinding?: LimitFindingStatus
) => {
  if (robotState === RobotState.INITIALIZING) {
    return robotInitializingImage
  }

  if (limitFinding !== LimitFindingStatus.OK) {
    return limitFindingImage
  }

  return manualControlImage
}

const getTitleKey = (
  shouldDisableLimitFinding: boolean,
  robotState?: RobotState,
  limitFinding?: LimitFindingStatus
) => {
  if (robotState === RobotState.INITIALIZING) {
    return 'PowerOn_InitializingRobot title Robot powering up'
  }

  if (limitFinding !== LimitFindingStatus.OK) {
    return shouldDisableLimitFinding ?
      'PowerOn_CalibrationWizard content power on calibration cannot start' :
      'PowerOn_CalibrationWizard title power on calibration required'
  }

  return 'PowerOn_CalibrationWizard content Manual Control not disabled'
}

const PatrolContentWrapper: React.FC<Props> = (props: Props) => {
  const {
    isClientEvent,
    canDebug,
    children,
    event,
    limitFinding,
    isRobotOnline,
    robotState,
    runningManualControlOperations,
    locations,
    triedToFetchRobots,
    initializationFailedMessage,
    initializationFailedClientMessage,
    showIncompleteCalibration,
    shouldDisableLimitFinding,
    stateGroup,
  } = props
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const history = useHistory()
  const [incompleteCalibrationClosed, setIncompleteCalibrationClosed] = useState(false)

  if (!triedToFetchRobots) {
    return (
      <Styled.StatusMainContent>
        <ActionsWrapper>
          <Loader
            centered
            size={60}
          />
        </ActionsWrapper>
      </Styled.StatusMainContent>
    )
  }

  const displayInitializing = !stateGroup || stateGroup === StateMachineGroup.INITIALIZING || initializationFailedMessage
  const displayLfInfo = stateGroup === StateMachineGroup.IDLE && !(
    limitFinding === LimitFindingStatus.OK &&
    robotState !== RobotState.MANUAL_CONTROL
  )
  const shouldDisplayContent = !displayInitializing && !displayLfInfo

  const forceShowEvent = !event?.viewed && (isClientEvent || isClientSideEvent(event?.reason, initializationFailedMessage))

  if (shouldDisplayContent || forceShowEvent) {
    const shouldShowEvents = forceShowEvent || showEventsBasedOnState(stateGroup)
    const hasFrame = shouldShowEvents && stateGroup !== StateMachineGroup.STOPPED_RECOVERED
    return (
      <EventFrame reason={hasFrame ? event?.reason : undefined}>
        <GenericEventDisplay
          event={event}
          locations={locations}
          hideEvents={!shouldShowEvents}
          location={<EventLocation />}
        >
          {children}
        </GenericEventDisplay>
        <EventVideoTutorial event={shouldShowEvents ? event : undefined} />
        <PatrolActionContainer
          isClientEvent={isClientEvent}
          loadingEvent={shouldShowEvents && !event}
        />
        <MoreProblemsPreviewContainer hide={!shouldShowEvents} />
      </EventFrame>
    )
  }

  if (robotState === undefined && limitFinding === undefined) {
    return <NoConnectionScreen />
  }

  if (robotState === RobotState.INITIALIZING || initializationFailedMessage) {
    return (
      <InitializingScreen
        initializationFailedClientMessage={initializationFailedClientMessage}
        initializationFailedMessage={initializationFailedMessage}
        canDebug={canDebug}
      />
    )
  }

  const image = getImage(robotState, limitFinding)
  const titleKey = getTitleKey(shouldDisableLimitFinding, robotState, limitFinding)
  const showLoader = (
    isLimitFindingInProgress(limitFinding) ||
    runningManualControlOperations
  )

  const buttonConfig = getButtonConfig(props, history)
  return (
    <Styled.StatusMainContent data-testid="lf-required">
      <PrepatrolImageContainer image={image} />
      <Styled.StatusTextsWrapper $flex="0 1 0%">
        <Styled.StatusTitle>{t(titleKey)}</Styled.StatusTitle>
        <Styled.StatusDescription>
          {t(getDescriptionKey(limitFinding as LimitFindingStatus, robotState as RobotState, runningManualControlOperations))}
        </Styled.StatusDescription>
      </Styled.StatusTextsWrapper>
      <LimitFindingPreconditions />
      {(showLoader || buttonConfig) && (
        <ActionsWrapper>
          {showLoader && (
            <Loader
              centered
              size={60}
            />
          )}
          {!showLoader && buttonConfig && (
            <WideButton
              data-testid="patrol-button-lf"
              disabled={!isRobotOnline}
              onClick={buttonConfig.action}
              $backgroundColor={blueButton}
              $textColor={white}
            >
              {t(buttonConfig.text)}
            </WideButton>
          )}
        </ActionsWrapper>
      )}
      <LimitFindingErrorModalContainer
        open={showIncompleteCalibration && !incompleteCalibrationClosed}
        close={() => setIncompleteCalibrationClosed(true)}
      />
    </Styled.StatusMainContent>
  )
}

export default PatrolContentWrapper
