import React from 'react'
import {useTranslation} from 'react-i18next'
import * as Styled from './StepsStyled'
import {ORDERED_STEPS, STEPS} from '../constants'
import {useNormalTranslation} from 'utils'
import {useSelector} from 'react-redux'
import {selectors} from 'shared'
import {Store} from 'types'

const StepTexts = ({
  step,
}: {step: STEPS}) => {
  const {i18n} = useTranslation()
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useNormalTranslation(guiUsecase)
  const titleKey = `PowerOn_CalibrationWizard title power on calibration step ${step} title`
  const questionKey = `PowerOn_CalibrationWizard content power on calibration step ${step} question`
  const infoKey = `PowerOn_CalibrationWizard content power on calibration step ${step} info`
  const warningKey = `PowerOn_CalibrationWizard content power on calibration step ${step} warning`
  const noteKey = `PowerOn_CalibrationWizard content power on calibration step ${step} note`
  const stepIndex = ORDERED_STEPS.indexOf(step)
  return (
    <Styled.StepTexts>
      <Styled.StepTitle>
        {stepIndex + 1}. {t(titleKey)}
      </Styled.StepTitle>
      {i18n.exists(infoKey) && (
        <Styled.StepInfo>
          {t(infoKey)}
        </Styled.StepInfo>
      )}
      {i18n.exists(warningKey) && (
        <Styled.StepWarning>
          {t(warningKey)}
        </Styled.StepWarning>
      )}
      {i18n.exists(noteKey) && (
        <Styled.StepNote>
          {t(noteKey)}
        </Styled.StepNote>
      )}
      {i18n.exists(questionKey) && (
        <Styled.StepQuestion>
          {t(questionKey)}
        </Styled.StepQuestion>
      )}
    </Styled.StepTexts>
  )
}

export default StepTexts
