import {connect} from 'react-redux'
import {Store} from 'types'
import NotificationSettingsList from './NotificationSettingsList'
import {getNotificationsConfig} from './selectors'
import {toggleSettingItem} from './actions'

const mapStateToProps = (state: Store) => ({
  isLoading: state.nsp.siteSettings.isLoadingLocations || state.nsp.isLoadingSettings,
  notificationsConfig: getNotificationsConfig(state),
  disabled: !state.nsp.siteSettings.notifications.notificationsEnabled,
})

export default connect(mapStateToProps, {
  toggleSettingItem,
})(NotificationSettingsList)
