import {SanitizationLevel} from '../types'

export enum ObjectGroupType {
  OBJECTS = 'objects',
  LOCATIONS = 'locations',
}

export interface ObjectGroup {
  level?: SanitizationLevel
  parentId?: string
  total: number
  selected: number
  groupType: ObjectGroupType
  objectType?: string
  translatedLocation?: string
  subgroups: ObjectSubGroup[]
}

export interface ObjectSubGroup extends Omit<ObjectGroup, 'subgroups'> {
  objectIds: string[]
}
