/* tslint:disable:no-any */
import React, {useEffect, useState, useCallback} from 'react'
import styled from 'styled-components'
import {getBackgroundColor} from './utils'
import {blueButton} from '../colors'

// colors only as HEX with 3 or 6 colors, ie. #faf, #12a23b
export interface Props {
    $borderColor?: string
    checked: boolean
    className?: string
    disabled?: boolean
    $height?: number
    onChange: (isChecked: boolean) => void
    $onColor?: string
    $offColor?: string
    $onHandleColor?: string
    $offHandleColor?: string
    $onIcon?: React.ReactElement
    $offIcon?: React.ReactElement
    $onLabel?: string | React.ReactElement
    $offLabel?: string | React.ReactElement
    $width?: number
}

const defaultProps: Props = {
  checked: false,
  disabled: false,
  $height: 28,
  $offColor: '#a6a6a6',
  $offHandleColor: '#fff',
  // tslint:disable-next-line:no-empty
  onChange: (isChecked: boolean): void => {},
  $onColor: blueButton,
  $onHandleColor: '#fff',
  $width: 56,
}

interface PropsWithState extends Props {
    $isDragging: boolean
    $hasOutline: boolean
    $position: number
    $checkedPosition: number
    $uncheckedPosition: number
    // other fields, like onClick etc
    [field: string]: any
}

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 56px;
    position: relative;
    border: 1px solid ${(props: PropsWithState) => props.$borderColor || getBackgroundColor(
    props.$position,
    props.$checkedPosition,
    props.$uncheckedPosition,
    props.$offColor,
    props.$onColor
  )};
    border-radius: ${(props: PropsWithState) => ((props.$height || defaultProps.$height) as number) / 2}px;
    height: ${(props: PropsWithState) => props.$height || defaultProps.$height}px;
    width: ${(props: PropsWithState) => props.$width || defaultProps.$width}px;
    opacity: ${(props: PropsWithState) => props.disabled ? 0.5 : 1};
    cursor: ${(props: PropsWithState) => props.disabled ? 'default' : 'pointer'};
    background: ${(props: PropsWithState) => getBackgroundColor(
    props.$position,
    props.$checkedPosition,
    props.$uncheckedPosition,
    props.$offColor,
    props.$onColor
  )};
    transition: ${(props: PropsWithState) => props.$isDragging ? 'none' : 'background 0.25s'};
    user-select: none;
    outline: none;
    touch-action: none;
`

export const SwitchHandle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props: PropsWithState) => ((props.$height || defaultProps.$height) as number) - 2}px;
    width: ${(props: PropsWithState) => ((props.$height || defaultProps.$height) as number) - 2}px;
    background: ${(props: PropsWithState) => getBackgroundColor(
    props.$position,
    props.$checkedPosition,
    props.$uncheckedPosition,
    props.$offHandleColor,
    props.$onHandleColor
  )};
    border-radius: 50%;
    cursor: ${(props: PropsWithState) => props.disabled ? 'default' : 'pointer'};
    outline: none;
    position: absolute;
    top: 0;
    transform: translateX(${(props: PropsWithState) => props.$position}px);
    box-shadow: ${(props: PropsWithState) => props.$hasOutline ? '0 0 6px 3px #3bf' : 'none'};
    transition: ${(props: PropsWithState) => props.$isDragging ? 'none' : 'background-color 0.25s, transform 0.25s, box-shadow 0.15s'};
`

export const Label = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 500;
`

const Switch = (props: Props) => {
  const {
    checked,
    disabled,
    onChange,
    $onLabel,
    $offLabel,
    $offIcon,
    $onIcon,
  } = props

  const $checkedPosition = ((props.$width || defaultProps.$width) as number) -
        ((props.$height || defaultProps.$height) as number)
  const $uncheckedPosition = 1
  const halfwayCheckpoint = ($checkedPosition + $uncheckedPosition) / 2

  const [$position, setPosition] = useState(props.checked ? $checkedPosition : $uncheckedPosition)
  const [$isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState()
  const [$hasOutline, setHasOutline] = useState()
  const [dragStartingTime, setDragStateTime] = useState()

  const propsWithState = {
    ...defaultProps,
    ...props,
    $checkedPosition,
    $hasOutline,
    $isDragging,
    $position,
    $uncheckedPosition,
  }

  // ---- common drag events start -----
  const onDragStart = (clientX: number) => {
    setStartX(clientX)
    setHasOutline(true)
    setDragStateTime(new Date())
  }

  const onDrag = useCallback((clientX: number) => {
    const startPos = checked ? $checkedPosition : $uncheckedPosition
    const mousePos = startPos + clientX - startX
    // We need this check to fix a windows glitch where onDrag is triggered onMouseDown in some cases
    if (!$isDragging && clientX !== startX) {
      setIsDragging(true)
    }
    const newPos = Math.min($checkedPosition, Math.max($uncheckedPosition, mousePos))
    if (newPos !== $position) {
      setPosition(newPos)
    }
  }, [$checkedPosition, checked, startX, $position, $isDragging])

  const onDragStop = useCallback(() => {
    // Simulate clicking the handle
    const timeSinceStart = Date.now() - dragStartingTime
    if (!$isDragging || timeSinceStart < 250) {
      onChange(!checked)
      // Handle dragging from checked position
    } else if (checked) {
      if ($position > halfwayCheckpoint) {
        setPosition($checkedPosition)
      } else {
        onChange(!checked)
      }
      // Handle dragging from unchecked position
    } else if ($position < halfwayCheckpoint) {
      setPosition($uncheckedPosition)
    } else {
      onChange(!checked)
    }

    setIsDragging(false)
    setHasOutline(false)
  }, [halfwayCheckpoint, $checkedPosition, onChange, checked, $position, $isDragging, dragStartingTime])
  // ---- common drag events end -----

  // ---- mouse events start -----
  const onMouseMove = (event: MouseEvent) => {
    event.preventDefault()
    onDrag(event.clientX)
  }

  const onMouseUp = (event: MouseEvent) => {
    event.preventDefault()
    onDragStop()
    window.removeEventListener('mousemove', onMouseMove)
    window.removeEventListener('mouseup', onMouseUp)
  }

  const onMouseDown = (event: MouseEvent) => {
    onDragStart(event.clientX)
    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)
  }

  const onClick = useCallback((event: MouseEvent) => {
    if ($isDragging) {
      return
    }

    event.preventDefault()
    onChange(!checked)
    setHasOutline(false)
  }, [checked, onChange, $isDragging])
  // ---- mouse events end -----

  // ---- touch events start -----
  const onTouchStart = (event: TouchEvent) => {
    onDragStart(event.touches[0].clientX)
  }

  const onTouchMove = (event: TouchEvent) => {
    onDrag(event.touches[0].clientX)
  }

  const onTouchEnd = (event: TouchEvent) => {
    event.preventDefault()
    onDragStop()
  }
  // ---- touch events end -----

  useEffect(() => {
    setPosition(checked ? $checkedPosition : $uncheckedPosition)
  }, [checked, $checkedPosition, $uncheckedPosition])

  return (
    <Wrapper
      {...propsWithState}
      onClick={disabled ? undefined : onClick}
    >
      <Label>
        {$position > halfwayCheckpoint ? $onLabel : $offLabel}
      </Label>
      <SwitchHandle
        {...propsWithState}
        onClick={(event: MouseEvent) => event.preventDefault()}
        onMouseDown={disabled ? undefined : onMouseDown}
        onTouchStart={disabled ? undefined : onTouchStart}
        onTouchMove={disabled ? undefined : onTouchMove}
        onTouchEnd={disabled ? undefined : onTouchEnd}
        onTouchCancel={disabled ? undefined : () => setHasOutline(false)}
      >
        {checked ? $onIcon : $offIcon}
      </SwitchHandle>
    </Wrapper>
  )
}

export default Switch
