import {connect} from 'react-redux'
import {Store} from 'types'
import {selectors as authSelectors} from 'auth'
import ScheduleList, {Props} from './ScheduleList'
import {scheduleGetItems, scheduleItemUpdate, scheduleOrderUpdate} from '../actions'
import {getConfigurations, hasDifferentScenarios} from '../selectors'
import {canEdit, getListElements} from './selectors'
import {AIRPORT_GUI_USE_CASE} from 'app/constants'

type MapStateType = Omit<Props, 'scheduleItemUpdate' | 'getItems' | 'scheduleOrderUpdate'>

const mapStateToProps = (state: Store): MapStateType => {
  return {
    canEdit: canEdit(state),
    isLoading: state.nsp.isLoadingSchedule,
    robotId: state.app.selectedRobot?.id,
    hasMultipleConfigurations: getConfigurations(state).length > 1,
    hasDifferentScenarios: hasDifferentScenarios(state),
    items: getListElements(state),
    userId: authSelectors.getUser(state)?.userId,
    canAccessSchedule: state.app.guiUseCase !== AIRPORT_GUI_USE_CASE,
  }
}

const mergeProps = (
  stateProps: MapStateType,
  dispatchProps: {
        scheduleGetItems: (robotId: string) => void
        scheduleOrderUpdate: (newOrder: Array<{id: string, newIndex: number}>) => void
        scheduleItemUpdate: (
            robotId: string,
            scheduleItemId: string,
            enabled: boolean,
            startDate?: Date,
            cronExpression?: string,
            rruleExpression?: string,
            weeklyRepetitions?: number
        ) => void
    }
): Props => ({
  ...stateProps,
  scheduleOrderUpdate: dispatchProps.scheduleOrderUpdate,
  getItems: () => {
    const {robotId} = stateProps
    if (robotId) {
      dispatchProps.scheduleGetItems(robotId)
    }
  },
  scheduleItemUpdate: (scheduleItemId: string, enabled: boolean, startDate?: Date, cronExpression?: string, rruleExpression?: string,
    weeklyRepetitions?: number) => {
    const {robotId} = stateProps
    if (robotId) {
      dispatchProps.scheduleItemUpdate(robotId, scheduleItemId, enabled, startDate, cronExpression, rruleExpression, weeklyRepetitions)
    }
  },
})

export default connect(
  mapStateToProps,
  {
    scheduleGetItems,
    scheduleItemUpdate,
    scheduleOrderUpdate,
  },
  mergeProps
)(ScheduleList)
