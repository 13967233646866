import React from 'react'
import styled from 'styled-components'
import {BottomNavigation} from './BottomNavigationBar'
import {Button, TextButton} from './BasicElementsStyled'
import {blueButton, white} from '../colors'

interface Action {
  action: () => void
  disabled?: boolean
  text: string
}

interface Props {
  cancel: Action
  confirm: Action
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
`

const ActionBottomBar = ({
  cancel,
  confirm,
}: Props) => {
  return (
    <BottomNavigation>
      <ButtonWrapper>
        <TextButton
          data-testid="action-bottom-bar-cancel"
          disabled={cancel.disabled}
          onClick={cancel.action}
        >
          {cancel.text}
        </TextButton>
        <Button
          data-testid="action-bottom-bar-confirm"
          disabled={confirm.disabled}
          $backgroundColor={blueButton}
          $textColor={white}
          onClick={confirm.action}
        >
          {confirm.text}
        </Button>
      </ButtonWrapper>
    </BottomNavigation>
  )
}

export default ActionBottomBar
