import {getLanguageCodes, i18n} from 'locale'
import {processForBestLanguage} from 'utils'
import {getSeverity} from 'components'
import {
  Locations,
  DiagnosticEvent,
  PatrolPointAction,
  Room,
  DiagnosticReason,
} from '../../types'

export const groupRooms = (locations: Locations, rooms: Room[]) => {
  const result = {} as {[parentName: string]: string[]}
  const languageCodes = getLanguageCodes(i18n.language)

  const getParentLocation = (roomId: string, result = ''): string => {
    if (!locations[roomId] || !locations[roomId].parentId) {
      return result
    }

    const parentId = locations[roomId].parentId as string
    const parentLocation = locations[parentId]
    if (!parentLocation) {
      return result
    }

    const parentTranslation = processForBestLanguage(languageCodes, language => parentLocation[language], '')
    if (!parentTranslation) {
      return result
    }

    const nextResult = `${parentTranslation}${result ? ' - ' : ''}${result}`

    return getParentLocation(parentId, nextResult)
  }

  rooms.forEach(room => {
    const parentLocation = getParentLocation(room.roomId)
    if (!result[parentLocation]) {
      result[parentLocation] = []
    }

    result[parentLocation].push(room.roomId)
  })

  return Object.keys(result).sort().reduce((config, parentLocation) => [...config, {
    location: parentLocation,
    locationId: locations[result[parentLocation][0]]?.parentId || '',
    rooms: result[parentLocation]
      .map(roomId => ({
        id: roomId,
        translation: locations[roomId] ?
          ((processForBestLanguage(languageCodes, language => locations[roomId][language]) || '') as string) :
          '',
      }))
      .sort((roomA, roomB) => roomA.translation.localeCompare(roomB.translation)),
  }], [] as Array<{location: string, locationId: string, rooms: Array<{id: string, translation: string}>}>)
}

export const getEventsForLocation = (locationId: string, events: DiagnosticEvent[], action: PatrolPointAction | PatrolPointAction[]) => {
  const actions = Array.isArray(action) ? action : [action]
  const result = [] as DiagnosticEvent[]
  // take events that happen in room only
  const roomEvents = events.filter(event => (
    (!event.patrolPointConfig && event.fromLocation === event.toLocation && event.fromLocation === locationId) ||
    (
      event.patrolPointConfig &&
      event.patrolPointConfig?.patrolPoint?.uid === locationId &&
      actions.includes(event.patrolPointConfig.patrolPoint.action)
    )
  ))
  const urgentEvents = roomEvents.filter(event => getSeverity(event.reason) === 'error')
  result.push(...urgentEvents)
  if (urgentEvents.length === 0) {
    const checkedOrSkippedEvent = roomEvents.find(event =>
      event.reason === DiagnosticReason.POINT_DONE ||
      event.reason === DiagnosticReason.POINT_SKIPPED
    )

    if (checkedOrSkippedEvent) {
      result.push(checkedOrSkippedEvent)
    }
  }

  return result
}
