import {connect} from 'react-redux'
import {Store} from 'types'
import {fetchSiteSettings} from './siteSettings'
import NSPWrapper from './NSPWrapper'

const mapStateToProps = (state: Store) => ({
  robotId: state.app.selectedRobot?.id,
  currentModule: state.app.selectedRobot?.modules?.current,
})

export default connect(mapStateToProps, {
  fetchSiteSettings,
})(NSPWrapper)
