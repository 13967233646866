import styled from 'styled-components'
import TableContainer from '@material-ui/core/TableContainer'

export const EngineeringTableWrapper = styled(TableContainer)`
    td,
    th {
        width: 50%;
    }

    .MuiExpansionPanel-root {
        box-shadow: none;
        margin-top: -4px;
        
        &.Mui-expanded {
          margin-top: -4px!important;
        }

        .MuiCollapse-container {
            margin-top: -12px;
        }
        
        .MuiExpansionPanelSummary-content,
        .MuiExpansionPanelSummary-content.Mui-expanded {
          margin-top: 3px;
          margin-bottom: 12px;
        }
        
        .MuiExpansionPanelSummary-expandIcon {
        margin-top: -10px;
        }
    }

    .MuiExpansionPanelDetails-root {
      flex-direction: column;
    }

    .MuiExpansionPanelDetails-root,
    .MuiExpansionPanelSummary-root {
        padding: 0;
    }
    
    .MuiExpansionPanelSummary-root.Mui-expanded {
        min-height: 48px;
    }

    .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: 0;
    }
`
